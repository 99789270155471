import React, { useMemo } from 'react';

import { useAddress } from '../../../../providers/AddressProvider/AddressProvider';
import { useTrustCategory } from '../TrustCategoryProvider';
import { useReputationWithAddress } from '../../../../providers/AddressProvider/useReputationWithAddress';
import { useWhere } from '../../../../providers/WhereProvider';

import { Address } from '../../Address/Address';

export const AnalysisSummaryAddress: React.FC = () => {
  const { address } = useAddress();
  const { data, isLoading } = useReputationWithAddress();
  const { trust } = useTrustCategory();
  const where = useWhere();

  const { isContract, address: addresFromResponse } = data || {};

  const displayAddress = useMemo(() => {
    if (!address) return undefined;
    return address.split('.').length === 2 ? addresFromResponse : address;
  }, [addresFromResponse, address]);

  return (
    <Address
      address={displayAddress || ''}
      trust={trust}
      where={where}
      isContract={!!isContract}
      shortenAtWidth={where === 'list' ? 640 : 525}
      isLoading={isLoading || !displayAddress}
    />
  );
};
