import React from 'react';
import Styled from './ImageSquare.styles';

interface Props {
  onClick?: () => void;
  size?: number;
  url: string;
  selected?: boolean;
  position?: 'top' | 'center' | 'bottom';
  children?: React.ReactNode;
}

export const ImageSquare: React.FC<Props> = ({
  onClick,
  size,
  url,
  position = 'center',
  children,
  ...otherProps
}) => {
  return (
    <Styled.Wrapper
      url={url}
      onClick={onClick}
      size={size}
      position={position}
      {...otherProps}
    >
      <div />
      {children}
    </Styled.Wrapper>
  );
};
