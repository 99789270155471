import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useOutsideClick } from '../../../../../hooks/useOutsideClick';
import { useProfileByParamId } from '../../../../../react-query/profile/useProfile';
import { useProfileUtils } from '../../../hooks/useProfileUtils';

import { shortenEthAddress } from '../../../../../utils/address-utils';

import { Icon } from '../../../../../assets';
import { ClipboardButton } from '../../../../../components/ui/buttons/ClipboardButton/ClipboardButton';

export const ConnectedAddresses: React.FC = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const { data: profile } = useProfileByParamId();
  const { isClaimedAddress } = useProfileUtils();

  useOutsideClick([ref, menuRef], () => setOpen(false));

  if (!isClaimedAddress) return null;

  return (
    <Wrapper ref={ref}>
      <CtaWrapper open={open} onClick={() => setOpen(prev => !prev)}>
        <span>{t('common.connectedAddresses')}</span>
        <Icon.ChevronDownWhite />
      </CtaWrapper>
      {open && (
        <Menu ref={menuRef}>
          {[...profile?.addresses!].map(address => (
            <MenuItem key={address}>
              <span>
                {shortenEthAddress(address, {
                  startChars: 4,
                  placeholderChar: '.',
                  placeholderCount: 2,
                })}
              </span>
              <Clipboard value={address} onClick={() => setOpen(false)} />
            </MenuItem>
          ))}
        </Menu>
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  overflow: 'visible',
  marginBottom: 14,
  [theme.min(theme.breakpoints.md)]: {
    marginBottom: 18,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    marginBottom: theme.pxToVw(32),
  },
  [theme.min(1920)]: {
    marginBottom: 32,
  },
}));

const CtaWrapper = styled('div')<{ open: boolean }>(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  fontSize: 14,
  fontWeight: 700,
  opacity: 1,
  transition: 'all .3s',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.85,
  },
  '& svg': {
    transition: 'all .3s',
    transform: `rotate(${open ? 180 : 0}deg)`,
  },
  [theme.min(theme.breakpoints.md)]: {
    fontSize: 16,
  },
  [theme.min(theme.breakpoints.ul)]: {
    fontSize: theme.pxToVw(20),
    gap: theme.pxToVw(12),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 20,
    gap: 12,
  },
}));

const Menu = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#3D4859',
  borderRadius: 10,
  overflow: 'auto',
  maxHeight: 380,
  minWidth: 236,
  position: 'absolute',
  zIndex: theme.zIndex.ul,
  bottom: -12,
  transform: 'translateY(100%)',
  [theme.min(theme.breakpoints.md)]: {
    left: -10,
  },
  [theme.betweenDM]: {
    borderRadius: theme.pxToVw(10),
    maxHeight: theme.pxToVw(380),
    minWidth: theme.pxToVw(236),
    bottom: theme.pxToVw(-10),
  },
}));

const MenuItem = styled('div')(({ theme }) => ({
  height: 48,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  fontSize: 14,
  transition: 'all .3s',
  backgroundColor: 'transparent',
  padding: '0 12px',
  position: 'relative',
  '& svg': {
    height: 15.5,
    width: 'auto',
    padding: 0,
    marginTop: 4,
    [theme.betweenDM]: {
      height: theme.pxToVw(15.5),
      marginTop: theme.pxToVw(4),
    },
  },
  '&:not(:last-child):after': {
    display: 'block',
    content: '" "',
    height: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    position: 'absolute',
    bottom: 0,
    left: 12,
    right: 12,
    transform: 'translateY(50%)',
    [theme.min(theme.breakpoints.md)]: {
      left: 14,
      right: 14,
    },
    [theme.min(theme.breakpoints.desktop)]: {
      left: theme.pxToVw(16),
      right: theme.pxToVw(16),
    },
    [theme.min(theme.breakpoints.max)]: {
      left: 16,
      right: 16,
    },
  },
  '&:first-child': {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  '&:last-child': {
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  [theme.min(theme.breakpoints.md)]: {
    fontSize: 16,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(20),
    height: theme.pxToVw(48),
    padding: `0 ${theme.pxToVw(12)}`,
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 20,
    height: 48,
    padding: '0 12px',
  },
}));

const Clipboard = styled(ClipboardButton)({
  padding: 0,
});
