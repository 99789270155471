import React from 'react';

import { FooterLogoSection } from '../components/LogoSection/FooterLogoSection';
import { FooterSocialSection } from '../components/SocialSection/FooterSocialSection';
import { FooterGetInTouchSection } from '../components/GetInTouchSection/FooterGetInTouchSection';
import { FooterLinksSection } from '../components/LinksSection/FooterLinksSection';
import { FooterPrivacyAndTermsSection } from '../components/PrivacyAndTermsSection/FooterPrivacyAndTermsSection';

export const MobileFooter: React.FC = () => {
  return (
    <>
      <FooterLogoSection />
      <FooterSocialSection />
      <FooterGetInTouchSection />
      <FooterLinksSection />
      <FooterPrivacyAndTermsSection />
    </>
  );
};
