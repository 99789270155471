import styled from 'styled-components';
import { FormFieldWrapper } from '../../ui/forms/FormFieldWrapper/FormFieldWrapper';
import { InfoPopup as InfoPopupCompnent } from '../../ui/modals/InfoPopup/InfoPopup';

const Wrapper = styled(FormFieldWrapper)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
}));

const SmallText = styled('div')(({ theme }) => ({
  fontSize: 11,
  marginLeft: 24,
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  '& span': {
    padding: 4,
  },
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(11),
    marginLeft: theme.pxToVw(24),
  },
}));

const InfoPopup = styled(InfoPopupCompnent)(({ theme }) => ({
  position: 'relative',
  zIndex: theme.zIndex.sm,
  '& .info-popup_content': {
    left: 0,
    width: 300,
    [theme.max(509)]: {
      transform: 'translateX(-40%)',
    },
    [theme.betweenDM]: {
      width: theme.pxToVw(300),
    },
  },
}));

const Styled = { Wrapper, SmallText, InfoPopup };

export default Styled;
