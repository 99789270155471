import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRequest } from '../../../../../hooks/useRequest';
import { useReportAddressForm } from './useReportAddressForm';

import { AddressInput } from '../../../../../components/common/AddressInput/AddressInput';
import { ButtonPrimary } from '../../../../../components/ui/buttons';
import { SupportedDomains } from '../../../../../components/common/Address/SupportedDomains';

import { UserApi } from '../../../../../api/UserApi/UserApi';

import Styled from './ReportAddress.styles';
import { AddressReportedText } from './AddressReportedText';
import { isValidEthAddress } from '../../../../../utils/address-utils';

export const ReportAddress: React.FC = () => {
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    watch,
    getValues,
    register,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useReportAddressForm();

  const scamOptions = useMemo(
    () =>
      (t('homePage.scamOptions', { returnObjects: true }) as string[]).map(label => ({
        label,
        value: label,
        onClick: () => {
          setValue('type', label, { shouldDirty: true });
        },
      })),
    [t, setValue]
  );

  const onSubmit = async () => {
    const payload = getValues();
    setErrorMessage(null);
    await UserApi.reportAddress(payload);
  };

  const {
    call,
    isLoading,
    isSuccess,
    isError,
    reset: resetUi,
  } = useRequest(onSubmit, {
    onError: error => {
      const { address } = getValues();
      const msg = error?.response?.data?.message;
      const apiErrors = [
        'INCORRECT_ADDRESS_LENGTH',
        'INTERNAL_SERVER_ERROR',
        'INCORRECT_ETH_NAME',
      ];
      const errorMessage =
        msg && apiErrors.includes(msg)
          ? t(`errors.${msg}`, {
              type: address.toLowerCase().endsWith('.bit') ? 'BIT' : 'ETH',
            })
          : t('errors.default');
      setErrorMessage(errorMessage);
    },
  });

  const ReasonEl = (
    <Styled.TextArea
      label=''
      labelProps={{ style: { flex: 1 } }}
      placeholder={t('homePage.describeWhyReportPlaceholder')}
      error={errors.reason?.message}
      {...register('reason')}
    />
  );

  const SelectTypeEl = (
    <Styled.AddressTypeWrapper>
      <Styled.ScamTypeSelect
        options={scamOptions}
        placeholder={t('homePage.chooseScamType')}
        value={watch('type')}
        error={errors.type?.message}
      />
    </Styled.AddressTypeWrapper>
  );

  const SubmitButton = (
    <ButtonPrimary
      onClick={
        isSuccess
          ? () => {
              reset();
              resetUi();
            }
          : handleSubmit(call)
      }
      isLoading={isLoading}
    >
      {t(`homePage.${isSuccess ? 'reportAnotherAddress' : 'submitReport'}`)}
    </ButtonPrimary>
  );

  const showDetails = isValidEthAddress(watch('address'));

  return (
    <>
      <AddressInput
        {...register('address')}
        id='homepage_address-report'
        isLoading={isLoading}
        error={(isError && errorMessage) || errors.address?.message}
        placeholder={t('homePage.reportAddressPlaceholder')}
        isSuccess={isSuccess}
      />

      <Styled.MobileFlex>
        {!isSuccess && showDetails && ReasonEl}
        {!isSuccess && showDetails && SelectTypeEl}
        {isSuccess && <AddressReportedText address={getValues('address')} />}
        {SubmitButton}
        <SupportedDomains />
      </Styled.MobileFlex>
      {showDetails && (
        <Styled.DesktopFlex>
          {!isSuccess && ReasonEl}
          {!isSuccess && SelectTypeEl}
          {isSuccess && <AddressReportedText address={getValues('address')} />}
        </Styled.DesktopFlex>
      )}
      <Styled.DesktopFlex spaceBetween>
        {SubmitButton}
        <SupportedDomains />
      </Styled.DesktopFlex>
    </>
  );
};
