import React from 'react';

import { useProfileByParamId } from '../../../../../react-query/profile/useProfile';
import { useProfileUtils } from '../../../hooks/useProfileUtils';

import { SocialIcons } from '../../../../../components/profile/SocialIcons/SocialIcons';

import { envs } from '../../../../../config/envs';

export const ProfileInfoSocialIcons: React.FC = () => {
  const { data: profile } = useProfileByParamId();
  const { isClaimedAddress } = useProfileUtils();

  if (!profile) return null;

  const { username, twitterUrl, openseaUrl, discordUrl } = profile;

  return (
    <SocialIcons
      twitterUrl={twitterUrl}
      openseaUrl={openseaUrl}
      discordUrl={discordUrl}
      isClaimedAddress={isClaimedAddress}
      shareUrl={`${envs.blockemWebsiteUrl}/u/${username}`}
    />
  );
};
