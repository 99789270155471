import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ResetPasswordForm } from '../../forms/reset-password/ResetPasswordForm';
import { BaseModal } from '../../ui/modals/BaseModal/BaseModal';

const FormWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '75vw',
  maxWidth: 464,
  gap: 8,
  '& input[name="oldPassword"]': {
    marginBottom: 12,
  },
  '& button': {
    marginTop: 12,
    width: '100%',
  },
  [theme.betweenDM]: {
    maxWidth: theme.pxToVw(464),
    gap: theme.pxToVw(8),
    '& input[name="oldPassword"]': {
      marginBottom: theme.pxToVw(12),
    },
    '& button': {
      marginTop: theme.pxToVw(12),
    },
  },
}));

interface Props {
  onClose: () => void;
}

export const ChangePasswordModal: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <BaseModal
      title={t('auth.changePassword')}
      isOpen={true}
      onClose={onClose}
    >
      <FormWrapper>
        <ResetPasswordForm onSuccess={onClose} />
      </FormWrapper>
    </BaseModal>
  );
};
