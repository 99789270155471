import React from 'react';
import styled from 'styled-components';
import { LoadingBackground } from '../../ui/loaders/LoadingBackground';

export const ProjectCardLoader: React.FC = () => {
  return (
    <Wrapper>
      <ImgLoader />
      <TextLoader />
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  [theme.min(theme.breakpoints.md)]: {
    gap: 16,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(20),
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 20,
  },
}));

const ImgLoader = styled(LoadingBackground)({
  width: '100%',
  aspectRatio: '1',
});

const TextLoader = styled(LoadingBackground)(({ theme }) => ({
  width: '100%',
  height: 20,
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    height: theme.pxToVw(20),
  },
}));
