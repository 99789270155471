import React from 'react';
import styled from 'styled-components';

export enum ActionTextVariant {
  Regular = 'Regular',
  Inverted = 'Inverted',
}

interface ElProps {
  variant?: ActionTextVariant;
  underline?: boolean;
  disabled?: boolean;
}

const Text = styled('span')<ElProps>(
  ({ theme, variant = ActionTextVariant.Regular, underline = true, disabled }) => ({
    fontWeight: 700,
    textDecoration: underline ? 'underline' : 'none',
    color:
      variant === ActionTextVariant.Regular
        ? theme.colors.text.primary
        : theme.colors.primary.main,
    cursor: disabled ? 'not-allowed' : 'pointer',
    opacity: disabled ? 0.8 : 1,
    transition: 'color 0.4s',
    '&:hover': {
      color:
        variant === ActionTextVariant.Inverted
          ? theme.colors.text.primary
          : theme.colors.primary.main,
    },
  })
);

interface Props extends ElProps {
  text?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  onClick: () => void;
}

export const ActionText: React.FC<Props> = ({
  variant = ActionTextVariant.Regular,
  text = '',
  underline = true,
  children,
  style,
  disabled,
  onClick,
  ...otherProps
}) => {
  return (
    <Text
      variant={variant}
      onClick={() => !disabled && onClick()}
      underline={underline}
      style={style}
      disabled={disabled}
      {...otherProps}
    >
      {text}
      {children}
    </Text>
  );
};
