import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ActionText as ActionTextEl } from '../../ui/text/ActionText';

interface Props {
  currentItemsCount: number;
  totalCount: number;
  hasNextPage: boolean;
  isFetchingNextPage?: boolean;
  hideCounters?: boolean;
  onFetchNextPage: () => void;
}

export const Pagination: React.FC<Props> = ({
  currentItemsCount,
  totalCount,
  hasNextPage,
  isFetchingNextPage,
  hideCounters = false,
  onFetchNextPage,
  ...otherProps
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper {...otherProps}>
      {!hideCounters && (
        <Text>
          {currentItemsCount} {t('common.of')} {totalCount}
        </Text>
      )}
      {hasNextPage && (
        <ActionText
          onClick={() => !isFetchingNextPage && onFetchNextPage()}
          isLoading={!!isFetchingNextPage}
        >
          {t('common.showMore')}
        </ActionText>
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'max-content',
  gap: 8.4,
  [theme.max(608)]: {
    marginBottom: 16,
  },
}));

const Text = styled('span')(({ theme }) => ({
  color: '#565656',
  fontSize: 12,
  fontWeight: 700,
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(12),
  },
}));

const ActionText = styled(ActionTextEl)<{ isLoading: boolean }>(
  ({ theme, isLoading }) => ({
    fontSize: 16,
    cursor: isLoading ? 'wait' : 'pointer',
    [theme.betweenDM]: {
      fontSize: theme.pxToVw(16),
    },
  })
);
