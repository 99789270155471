import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { PopoverProps } from 'react-tiny-popover';

import { useProjectUtils } from '../../../pages/project/profile/hooks/useProjectUtils';
import { useRequest } from '../../../hooks/useRequest';

import { routes } from '../../../router/routes';

import { ProjectListItem } from '../../../types';
import { DotsDropdownMenu } from '../../ui/menus/DopdownMenu/DotsDropdownMenu/DotsDropdownMenu';
import { ConfirmationModal } from '../../ui/modals/ConfirmationModal/ConfirmationModal';

import { UserApi } from '../../../api/UserApi/UserApi';
import { QueryKey } from '../../../react-query/query-keys';

interface Props extends Omit<PopoverProps, 'children' | 'content'> {
  project: ProjectListItem;
  children?: React.ReactNode;
  onToggle: () => void;
  setIsDeleting: Dispatch<SetStateAction<boolean>>;
}

export const ProjectCardMenu = React.forwardRef<HTMLElement, Props>(
  ({ project, setIsDeleting, ...otherProps }, ref) => {
    const { t } = useTranslation();
    const { isProjectOwner } = useProjectUtils();
    const navigate = useNavigate();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const queryClient = useQueryClient();

    const onDelete = async (projectUid: string) => {
      setIsDeleting(true);
      await UserApi.deleteProject(projectUid);
      await Promise.all([
        queryClient.invalidateQueries(QueryKey.UserProjects()),
        queryClient.invalidateQueries(QueryKey.Project(project.contractAddress)),
        queryClient.invalidateQueries(QueryKey.ProjectDetails(project.contractAddress)),
      ]);
    };

    const { call } = useRequest(onDelete, { onError: () => setIsDeleting(false) });

    if (!isProjectOwner(project.contractAddress)) return null;

    return (
      <Wrapper className='project-menu'>
        <DotsDropdownMenu
          ref={ref}
          menuItems={[
            {
              label: t('common.open'),
              onClick: () => navigate(routes.project.byId(project.contractAddress)),
            },
            {
              label: t('common.projectDetails'),
              onClick: () => navigate(routes.project.editById(project.uid)),
            },
            {
              label: t('common.deleteProject'),
              onClick: () => {
                setConfirmOpen(true);
              },
            },
          ]}
          {...otherProps}
        />
        {confirmOpen && (
          <ConfirmationModal
            onConfirm={() => call(project.uid)}
            onCancel={() => {
              setConfirmOpen(false);
            }}
            title={t('confirm.removeProject.title')}
            description={t('confirm.removeProject.text', { name: `"${project.title}"` })}
          />
        )}
      </Wrapper>
    );
  }
);

const Wrapper = styled('div')(({ theme }) => ({
  display: 'none',
  position: 'absolute',
  bottom: 16,
  right: 16,
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    right: theme.pxToVw(16),
    bottom: theme.pxToVw(16),
  },
}));
