import { axiosInstance } from '../config/axios';
import { ProjectAnalyticsTransactionItem } from '../types';
import { apiRoutes } from './routes';

interface GetProjectFrequencyOfTransactionsResponseApi {
  contract_address: string;
  execution_date: number;
  in_tx_count: number;
  out_tx_count: number;
}

class ProjectAnalyticsApiConfig {
  private readonly apiMapper = {
    getTransactionFrequency: (
      response: GetProjectFrequencyOfTransactionsResponseApi[]
    ): ProjectAnalyticsTransactionItem[] => {
      return response.map(
        ({ contract_address, execution_date, in_tx_count, out_tx_count }) => ({
          contractAddress: contract_address,
          executionDate: execution_date,
          inTxCount: in_tx_count,
          outTxCount: out_tx_count,
        })
      );
    },
  };

  getTransactionFrequency = async (address: string, params: { interval: string }) => {
    const { data } = await axiosInstance.get<
      GetProjectFrequencyOfTransactionsResponseApi[]
    >(apiRoutes.analytics.transactionsByAddress(address, params));

    return this.apiMapper.getTransactionFrequency(data);
  };
}

export const ProjectAnalyticsApi = new ProjectAnalyticsApiConfig();
