import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { ReputationApi } from '../../api/ReputationApi';
import { ReportData } from '../../types';
import { isValidEthAddress } from '../../utils/address-utils';
import { QueryKey } from '../query-keys';

export const useReputation = (
  address?: string
): UseQueryResult<ReportData, AxiosError<{ message: string }>> => {
  return useQuery(
    QueryKey.Reputation(address!),
    () => ReputationApi.getFullReport(address!),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: !!address && !!isValidEthAddress(address),
    }
  );
};
