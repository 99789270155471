import React from 'react';
import styled from 'styled-components';
import { Icon } from '../../../../assets';
import { ReactComponent as ClipBlueIcon } from './ClipboardIconBlue.svg';

interface Props {
  value: string | number;
  onClick?: () => void;
}

const Wrapper = styled('span')({
  padding: 12,
  transition: 'all .3s',
  opacity: 1,
  cursor: 'pointer',
  '& path': {
    opacity: 1,
    transition: 'all .3s',
  },
  '&:hover': {
    opacity: 0.8,
    '& path': {
      opacity: 0.8,
    },
  },
});

export const ClipboardButton: React.FC<Props> = ({ value, onClick, ...otherProps }) => {
  return (
    <Wrapper
      className='clipboard-button'
      onClick={e => {
        e.stopPropagation();
        navigator.clipboard.writeText(String(value));
        typeof onClick === 'function' && onClick();
      }}
      {...otherProps}
    >
      <Icon.Clipboard />
    </Wrapper>
  );
};

export const ClipboardButtonBlue: React.FC<Props> = ({
  value,
  onClick,
  ...otherProps
}) => (
  <ClipBlue
    className='clipboard-button'
    onClick={e => {
      e.stopPropagation();
      navigator.clipboard.writeText(String(value));
      typeof onClick === 'function' && onClick();
    }}
    {...otherProps}
  />
);

const ClipBlue = styled(ClipBlueIcon)(({ theme }) => ({
  width: 24,
  height: 24,
  cursor: 'pointer',
  opacity: 1,
  transition: 'all .3s',
  '&:hover': {
    opacity: 0.85,
  },
  [theme.min(theme.breakpoints.md)]: {
    width: 32,
    height: 32,
  },
}));
