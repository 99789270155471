import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClientProvider } from 'react-query';
import { hotjar } from 'react-hotjar';

import './i18n';

import { GlobalCss } from './global-css';
import { getTheme } from './config/theme';
import { Web3OnboardStyle } from './config/web3-onboard';
import { queryClient } from './config/react-query';

import { PublicRouter } from './router/PublicRouter';
import { AuthenticatedRouter } from './router/AuthenticatedRouter';
import { NotificationsProvider } from './providers/NotificationsProvider';
import { ErrorBoundary } from './components/errors/ErrorBoundary';

import { UrlService } from './services/UrlService';
import { isDev } from './utils/environment';

import {
  AnalyticsCategory,
  AnalyticsEvent,
  AnalyticsService,
} from './services/AnalyticsService';

import { useAuth } from './providers/AuthProvider';
import { StorageKeys, StorageService } from './services/StorageService';

export const App: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    AnalyticsService.pageview(pathname);
    hotjar.initialized() && hotjar.event('pageview');
  }, [pathname]);

  useEffect(() => {
    // track visits from external sites with registration
    const proj = UrlService.getSearchParam('proj');
    if (proj) {
      StorageService.save(StorageKeys.ProjectRedirect, proj);
    }
  }, []);

  useEffect(() => {
    const addressParam = UrlService.getSearchParam('address');
    // will be handled in home/main
    if (addressParam) return;

    const fromParam = UrlService.getSearchParam('from');
    if (fromParam) {
      AnalyticsService.trackEvent({
        action: AnalyticsEvent.VisitViaRedirect,
        category: AnalyticsCategory.Redirect,
      });
      navigate(pathname);
    }
  }, [navigate, pathname]);

  useEffect(() => {
    if (isDev()) return;

    const script = document.createElement('script');

    script.src =
      'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9904701906473153';
    script.async = true;
    script.crossOrigin = 'anonymous';

    document.body.appendChild(script);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={getTheme()}>
        <GlobalCss />
        <Web3OnboardStyle />
        <ErrorBoundary>
          {isAuthenticated ? (
            <NotificationsProvider>
              <AuthenticatedRouter />
            </NotificationsProvider>
          ) : (
            <PublicRouter />
          )}
        </ErrorBoundary>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} position='bottom-left' />
    </QueryClientProvider>
  );
};
