import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useWindowWidth } from '../../../../../hooks/useWindowWidth';
import { useProjectUtils } from '../../hooks/useProjectUtils';
import { useProjectByParamId } from '../../../../../react-query/project/useProject';
import { LoadingBackground } from '../../../../../components/ui/loaders/LoadingBackground';
import { Container } from '../../../../../components/layouts/Container';
import { Heading4 } from '../../../../../components/ui/text/Heading';

export const ChartTitle = styled(Heading4)(({ theme }) => ({
  fontWeight: 700,
}));

interface P {
  mobileImg: string;
  desktopImg: string;
  centered?: boolean;
  isLoading?: boolean;
}

export const BlurredPlaceholder: React.FC<P> = ({
  mobileImg,
  desktopImg,
  centered,
  isLoading,
}) => {
  const width = useWindowWidth();
  const theme = useTheme();
  const { t } = useTranslation();
  const { isClaimedProject, isOwner } = useProjectUtils();
  const { isLoading: projectIsLoading } = useProjectByParamId();

  const src = width < theme.breakpoints.xs ? mobileImg : desktopImg;

  const text =
    isOwner || isClaimedProject
      ? t('common.comingSoon')
      : t('projectPage.registerProjectToUnlock');

  const WrapperEl = isLoading ? LoadingBg : Wrapper;

  const loading = !!isLoading || projectIsLoading;

  return (
    <WrapperEl>
      <Image src={src} isLoading={loading} />
      {!loading && <ImageText centered={centered}>{text}</ImageText>}
    </WrapperEl>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
}));

const Image = styled('img')<{ isLoading: boolean }>(({ theme, isLoading }) => ({
  width: '100%',
  height: 'auto',
  opacity: 1,
}));

const ImageText = styled(ChartTitle)<{ centered?: boolean }>(({ theme, centered }) => ({
  position: 'absolute',
  top: centered ? 'auto' : '25%',
}));

const LoadingBg = styled(LoadingBackground)({
  opacity: 0.2,
});

export const ChartCardBase = styled(Container)(({ theme }) => ({
  borderRadius: 10,
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  boxShadow: '0px 0px 3px rgba(53, 133, 254, 0.39)',
  padding: '24px 20px',
  [theme.min(theme.breakpoints.desktop)]: {
    padding: `${theme.pxToVw(32)} ${theme.pxToVw(20)}`,
    borderRadius: theme.pxToVw(15),
  },
  [theme.min(theme.breakpoints.max)]: {
    padding: '32px 20px',
    borderRadius: 15,
  },
}));
