import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ButtonPrimary } from '../ButtonPrimary';

import { ReactComponent as TwitterIcon } from './twitter.svg';
import { AnalyticsEvent, AnalyticsService } from '../../../../services/AnalyticsService';

interface Props {
  url: string;
  trustPercentage?: number;
  transactionAddress?: string;
}

const Button = styled(ButtonPrimary)(({ theme }) => ({
  width: 221,
  '& span': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    width: theme.pxToVw(221),
    '& svg': {
      width: theme.pxToVw(27),
      height: 'auto',
    },
  },
}));

export const TwitterButton: React.FC<Props> = ({
  url,
  trustPercentage,
  transactionAddress,
}) => {
  const { t } = useTranslation();

  const text =
    trustPercentage === -1
      ? t('homePage.shareLinkTextNewWallet', { address: transactionAddress })
      : t('homePage.shareLinkText', {
          address: transactionAddress,
          trustPercentage,
        });

  const hashtags = ['CryptoScam', 'CryptoScamBlocker', 'NFT'];

  return (
    <>
      <Button
        onClick={() => {
          window.open(
            `https://twitter.com/intent/tweet?text=${encodeURIComponent(
              text
            )}&url=${encodeURIComponent(url)}&hashtags=${encodeURIComponent(
              hashtags.join(',')
            )}`
          );
          AnalyticsService.userEvent({
            action: AnalyticsEvent.ShareTwitterClick,
            label: transactionAddress,
            value: trustPercentage,
          });
        }}
      >
        <TwitterIcon />

        {t('common.tweetIt')}
      </Button>
    </>
  );
};
