import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { useProjectDetailsForm } from '../../../components/project/project-form-components/useProjectDetailsForm';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import { useRequest } from '../../../hooks/useRequest';

import { AddressProvider } from '../../../providers/AddressProvider/AddressProvider';
import { Form } from '../../../components/forms/Form';
import { CenteredLayoutWithTitle } from '../../../components/layouts/CenteredLayoutWithTitle/CenteredLayoutWithTitle';
import { ProjectDetailsForm } from '../../../components/project/project-form-components/ProjectDetailsForm';
import { EnterContractAddress } from './components/EnterContractAddress';
import { ModeProvider } from '../../../providers/ModeProvider';
import { ButtonPrimary } from '../../../components/ui/buttons';
import { CancelButton } from '../../../components/common/ActionButtons/CancelButton';

import { routes } from '../../../router/routes';
import { QueryKey } from '../../../react-query/query-keys';
import { UserApi } from '../../../api/UserApi/UserApi';
import { UrlService } from '../../../services/UrlService';

export const ProjectCreatePage: React.FC = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const navigate = useNavigate();

  const methods = useProjectDetailsForm();
  const queryClient = useQueryClient();

  useDocumentTitle(t('projectCreatePage.pageTitle'));

  const onSubmit = async () => {
    const payload = methods.getValues();
    await UserApi.postPoject(payload);
    await queryClient.invalidateQueries(QueryKey.UserProjects(), {
      refetchActive: true,
      refetchInactive: true,
    });

    navigate(routes.myProjects.root());
  };

  const { call, isLoading } = useRequest(onSubmit, {
    onError: err => {
      const msg = err?.response?.data?.message || err?.message;

      if (msg === 'INVALID_SIGNATURE') {
        methods.setError('signature', { message: t(`errors.${msg}`) });
      }
    },
  });

  const { setValue } = methods;

  useEffect(() => {
    const contractAddress = UrlService.getSearchParam('contractAddress');
    if (contractAddress) setValue('contractAddress', contractAddress);
  }, [setValue]);

  return (
    <CenteredLayoutWithTitle
      title={t('projectCreatePage.title')}
      maxWidth={step === 0 ? 494 : 600}
    >
      <ModeProvider mode='create'>
        <AddressProvider>
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(call)}>
              {step === 0 ? (
                <EnterContractAddress onNext={() => setStep(1)} />
              ) : (
                <>
                  <ProjectDetailsForm />
                  <ActionButtons>
                    <CancelButton
                      onClick={() => {
                        methods.clearErrors();
                        methods.reset();
                        setStep(0);
                      }}
                      disabled={isLoading}
                    />
                    <ButtonPrimary type='submit' isLoading={isLoading}>
                      {t('projectCreatePage.addProject')}
                    </ButtonPrimary>
                  </ActionButtons>
                </>
              )}
            </Form>
          </FormProvider>
        </AddressProvider>
      </ModeProvider>
    </CenteredLayoutWithTitle>
  );
};

const ActionButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 19,
  marginTop: 10,
  '& button': {
    '&:last-child': {
      flex: 1,
      minWidth: 180,
    },
    '&:first-child': {
      flexShrink: 1,
      width: '50%',
    },
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(19),
    marginTop: theme.pxToVw(10),
    '& button': {
      '&:last-child': {
        minWidth: theme.pxToVw(180),
      },
    },
  },
}));
