import styled from 'styled-components';
import {
  SelectExpandible,
  classNames,
} from '../../../../../components/ui/forms/Select/SelectExpandible';
import { TextareaField } from '../../../../../components/ui/forms/Textarea/TextareaField';

const Col = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
}));

const MobileFlex = styled(Col)(({ theme }) => ({
  '& button': {
    width: '100%',
    fontSize: 16,
    height: 56,
  },
  [theme.min(theme.breakpoints.sm)]: {
    display: 'none',
  },
}));

const DesktopFlex = styled(Col)<{ spaceBetween?: boolean }>(
  ({ theme, spaceBetween }) => ({
    display: 'none',
    [theme.min(theme.breakpoints.sm)]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: spaceBetween ? 'space-between' : 'flex-start',
      gap: 20,
      '& button': {
        width: '100%',
        fontSize: 16,
        height: 56,
        maxWidth: 360,
      },
      '&:last-child': {
        alignItems: 'center',
      },
    },
    [theme.min(theme.breakpoints.desktop)]: {
      gap: theme.pxToVw(20),
      '& button': {
        maxWidth: theme.pxToVw(457),
        fontSize: theme.pxToVw(24),
        height: theme.pxToVw(80),
      },
    },
    [theme.min(theme.breakpoints.max)]: {
      gap: 20,
      '& button': {
        maxWidth: 457,
        fontSize: 24,
        height: 80,
      },
    },
  })
);

const AddressTypeWrapper = styled('div')(({ theme }) => ({
  height: 56,
  overflow: 'visible',
  [theme.min(theme.breakpoints.sm)]: {
    width: '40%',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    maxWidth: theme.pxToVw(507),
    height: theme.pxToVw(64),
  },
  [theme.min(theme.breakpoints.max)]: {
    maxWidth: 507,
    height: 64,
  },
}));

const ScamTypeSelect = styled(SelectExpandible)(({ theme }) => ({
  fontSize: 16,
  width: '100%',
  flexShrink: 0,
  backgroundColor: 'rgba(8, 10, 19, 1) !important',
  position: 'relative',
  zIndex: theme.zIndex.xs,
  [`& .${classNames.label}`]: {
    color: theme.colors.primary.main,
  },
  '&:hover': {
    backgroundColor: 'rgba(8, 10, 19, 1) !important',
  },
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: 18,
    [`& .${classNames.menuItem}`]: {
      '&:not(:last-child)': {
        marginBottom: 24,
      },
    },
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(20),
    maxWidth: theme.pxToVw(507),
    [`& .${classNames.topContent}`]: {
      height: theme.pxToVw(64),
    },
    [`& .${classNames.menuItem}`]: {
      '&:not(:last-child)': {
        marginBottom: theme.pxToVw(30),
      },
    },
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 20,
    maxWidth: 507,
    [`& .${classNames.topContent}`]: {
      height: 64,
    },
    [`& .${classNames.menuItem}`]: {
      '&:not(:last-child)': {
        marginBottom: 30,
      },
    },
  },
}));

const TextArea = styled(TextareaField)(({ theme }) => ({
  flex: 1,
  minHeight: 100,
  '&::placeholder': {
    color: theme.colors.primary.main,
    fontSize: 16,
  },
  [theme.min(theme.breakpoints.sm)]: {
    paddingTop: 16,
    paddingLeft: 16,
    minHeight: 112,
    '&::placeholder': {
      fontSize: 18,
    },
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(20),
    '&::placeholder': {
      fontSize: theme.pxToVw(20),
    },
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 20,
    '&::placeholder': {
      fontSize: 20,
    },
  },
}));

const Styled = {
  MobileFlex,
  DesktopFlex,
  AddressTypeWrapper,
  ScamTypeSelect,
  TextArea,
};

export default Styled;
