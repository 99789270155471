import React, { useEffect } from 'react';

import { useReputationWithAddress } from '../../../providers/AddressProvider/useReputationWithAddress';

import { TrustCategoryProvider, useTrustCategory } from './TrustCategoryProvider';
import { useWhere, WhereProvider } from '../../../providers/WhereProvider';

import { AnalysisDetails } from './AnalysisDetails/AnalysisDetails';
import { AnalysisSummary } from './AnalysisSummary/AnalysisSummary';
import { ProfileBadge } from './ProfileBadge/ProfileBadge';

import Styled from './AnalysisResult.styles';

interface Props {
  where?: 'main' | 'list';
}

export const AnalysisResult: React.FC<Props> = ({ where = 'main' }) => {
  return (
    <TrustCategoryProvider>
      <WhereProvider where={where}>
        <Content />
      </WhereProvider>
    </TrustCategoryProvider>
  );
};

const Content: React.FC = () => {
  const { data } = useReputationWithAddress();
  const { setTrustByTrustPercentage } = useTrustCategory();
  const where = useWhere();

  useEffect(() => {
    if (!data) return;
    setTrustByTrustPercentage(data.trustPercentage);
  }, [data, setTrustByTrustPercentage]);

  return (
    <Styled.Wrapper>
      <AnalysisSummary />
      {where === 'main' && <ProfileBadge />}
      <AnalysisDetails />
    </Styled.Wrapper>
  );
};
