import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from '../../../../../components/layouts/Container';
import { ButtonWhite } from '../../../../../components/ui/buttons/ButtonWhite';
import { useProjectByParamId } from '../../../../../react-query/project/useProject';
import { routes } from '../../../../../router/routes';
import banner_png from './banner.png';

export default function ProjectRoadmapPlaceholder() {
  const navigate = useNavigate();
  const { data: project } = useProjectByParamId();

  if (!project || !project.uid) return null;

  return (
    <Cont>
      <Img src={banner_png} />
      <Content>
        <Heading>Show your Roadmap</Heading>
        <Text>Establish goals for your team's success with Blockem.</Text>
        <Button
          onClick={() => navigate(routes.project.roadmap.root(project.contractAddress))}
        >
          Create your roadmap
        </Button>
      </Content>
    </Cont>
  );
}

const Cont = styled(Container)(({ theme }) => ({
  position: 'relative',
  [theme.min(theme.breakpoints.desktop)]: {
    marginTop: theme.pxToVw(100),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginTop: 100,
  },
}));

const Content = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 22,
  bottom: 22,
  width: '100%',
  zIndex: theme.zIndex.xs,
  [theme.min(theme.breakpoints.desktop)]: {
    bottom: 'auto',
    top: theme.pxToVw(122),
    left: theme.pxToVw(104),
    maxWidth: theme.pxToVw(602),
  },
  [theme.min(theme.breakpoints.max)]: {
    top: 122,
    left: 104,
    maxWidth: 602,
  },
}));

const Img = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
}));

const Heading = styled('h1')(({ theme }) => ({
  lineHeight: 1.1,
  fontWeight: 700,
  fontSize: 24,
  marginBottom: 8,
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: 48,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(80),
    marginBottom: theme.pxToVw(20),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 80,
    marginBottom: 20,
  },
}));

const Text = styled('p')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  marginBottom: 12,
  [theme.min(theme.breakpoints.md)]: {
    fontSize: 16,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(20),
    marginBottom: theme.pxToVw(20),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 20,
    marginBottom: 20,
  },
}));

const Button = styled(ButtonWhite)(({ theme }) => ({
  width: 225,
  [theme.betweenDM]: {
    width: theme.pxToVw(225),
  },
}));
