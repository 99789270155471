import React, { useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import PhoneInputComponent from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import styled from 'styled-components';

import { FormFieldWrapper } from '../FormFieldWrapper/FormFieldWrapper';
import { getInputBaseStyles } from '../Input/InputBase';

interface Props {
  country?: string;
  value?: string;
  error?: string | FieldError | Error;
  onChange: (val: string) => void;
}

export const PhoneInput = React.forwardRef<HTMLInputElement, Props>(
  ({ country = 'us', value = '', error, onChange }, ref) => {
    const { t } = useTranslation('auth');
    const [val, setVal] = useState('');

    return (
      <Wrapper
        label={t('phoneNumber')}
        fieldHasValue={!!val}
        error={
          typeof error === 'string' || typeof error === 'boolean' ? error : error?.message
        }
        $error={!!error}
      >
        <PhoneInputComponent
          // inputProps={{ ref }}
          country={country}
          placeholder={t('phoneNumber')}
          value={value}
          onChange={(value: any, data: any) => {
            onChange && onChange(value);
            setVal(value);
          }}
          enableSearch
          buttonStyle={{
            background: 'transparent',
            color: '#fff',
            border: 'none',
            paddingLeft: 0,
          }}
          searchStyle={{ height: 28, width: '90%' }}
          dropdownStyle={{ background: 'rgba(8,10,19,1)' }}
        />
      </Wrapper>
    );
  }
);

const Wrapper = styled(FormFieldWrapper)<{ $error: boolean }>(
  ({ theme, $error, error, fieldHasValue }) => ({
    position: 'relative',
    '& .react-tel-input': {
      width: '100%',
      '& .special-label': {
        display: 'none',
      },
      '& input': {
        width: '100%',
        ...getInputBaseStyles(theme, !!$error),
        paddingLeft: 76,
        paddingBottom: 0,
        [theme.betweenDM]: {
          height: theme.pxToVw(56),
          fontSize: theme.pxToVw(14),
          borderRadius: theme.pxToVw(10),
          '&:placeholder': {
            fontSize: theme.pxToVw(14),
          },
          padding: fieldHasValue
            ? `${theme.pxToVw(16)} ${theme.pxToVw(20)} ${theme.pxToVw(6)} ${theme.pxToVw(
                76
              )}`
            : `${theme.pxToVw(6)} ${theme.pxToVw(20)} ${theme.pxToVw(6)} ${theme.pxToVw(
                76
              )}`,
        },
      },

      '& .selected-flag': {
        paddingLeft: 20,
        width: 64,
        '&::before': { border: 'none', boxShadow: 'none' },
        [theme.betweenDM]: {
          paddingLeft: theme.pxToVw(20),
          width: theme.pxToVw(64),
        },
      },
      '& .search-emoji': {
        display: 'none',
      },

      '& .flag': {
        transform: 'translateY(4px)',
        [theme.betweenDM]: {
          // backgroundSize: 50,
        },
        '& .arrow': {
          borderTop: '6px solid #fff',
          borderLeft: '6px solid transparent',
          borderRight: '6px solid transparent',
          marginLeft: 4,
          transform: 'translateY(1px)',
          [theme.betweenDM]: {
            borderWidth: theme.pxToVw(6),
            marginLeft: theme.pxToVw(4),
          },
          '&.up': {
            borderBottom: '6px solid #fff',
            borderLeft: '6px solid transparent',
            borderRight: '6px solid transparent',
            borderTop: 'none',
            [theme.betweenDM]: {
              borderWidth: theme.pxToVw(6),
            },
          },
        },
      },
    },
    '& .custom-arrow': {
      position: 'absolute',
      top: 27,
      left: 36,
      [theme.betweenDM]: {
        top: theme.pxToVw(27),
        left: theme.pxToVw(36),
      },
    },
    '& .country-list': {
      minHeight: 220,
      fontFamily: theme.fontFamily,
      [theme.betweenDM]: {
        minHeight: theme.pxToVw(220),
      },
      '& .highlight': {
        backgroundColor: `${theme.colors.primary.main} !important`,
      },
      '& li:first-child': {
        backgroundColor: 'rgba(8,10,19,1)',
      },
      '& li:not(:first-child)': {
        '&:hover': {
          backgroundColor: `${theme.colors.primary.main} !important`,
        },
      },
      '& .country-name': {
        [theme.betweenDM]: {
          fontSize: theme.pxToVw(15),
        },
      },
      '& .dial-code': {
        [theme.betweenDM]: {
          fontSize: theme.pxToVw(15),
        },
      },
    },
  })
);
