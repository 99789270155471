import { axiosInstance } from '../../config/axios';
import {
  NFT,
  PaginatedResponse,
  User,
  Follower,
  Following,
  ProjectMetadata,
  ProjectAdditionalDetails,
} from '../../types';
import { apiRoutes, IRequestParams } from '../routes';
import { SharedApiMapper } from '../SharedApiMapper';
import {
  NFTResponseApi,
  PatchProfileDetailsPayload,
  PatchProfileDetailsPayloadApi,
  ProfileResponseApi,
  ProjectAdditionalDetailsApi,
  ProjectListResponseApiItem,
  ProjectMetadataResponseApi,
  ProjectResponseApi,
} from '../types';
interface PatchProfilePayloadApi {
  email?: string;
  phone_number?: string;
}

export interface PatchProfilePayload {
  email?: string | null;
  phoneNumber?: string | null;
}

export interface ConnectAddressPayload {
  address: string;
  signature: string;
}

export interface ConnectAddressResponse {
  alreadyAddedAddresses: string;
}

interface ConnectAddressResponseApi {
  already_added_addresses: string;
}

export interface DisconnectAddressPayload {
  address: string;
}

export interface PatchProfilePhotoPayload {
  contractAddress: string;
  tokenId: string;
}

interface PatchProfilePhotoPayloadApi {
  contract_address: string;
  token_id: string;
}

interface UserNFTsResponseApi {
  num_of_results: number;
  nfts: NFTResponseApi[];
}

export type UserNFTsResponse = PaginatedResponse<NFT>;

interface UserFollowersResponseApi {
  user_uid: string;
  photo_url: string | null;
  username: string;
}

interface CommonFollowingApi {
  photo_url: string | null;
  address: string;
}
interface UserFollowingsResponseApi extends CommonFollowingApi {
  user_uid: string | null;
  username: string | null;
}

interface ProjectFollowingsResponseApi extends CommonFollowingApi {
  project_uid: string | null;
  title: string | null;
}

interface PostProjectRequestPayloadApi extends ProjectMetadataResponseApi {
  signature: string;
}
export interface PostProjectRequestPayload extends ProjectMetadata {
  signature: string;
}

interface CommonPopularUser {
  uid: string;
  username: string;
}
interface PopularUserItemResponseApi extends CommonPopularUser {
  photo_url: string;
  followers_count: number;
}

interface PopularUser extends CommonPopularUser {
  photoUrl: string | null;
  followersCount: number;
}

export interface ReportAddressPayload {
  address: string;
  reason: string;
  type: string;
}

class UserApiConfig {
  private readonly apiMapper = {
    profile: (
      response: ProfileResponseApi & { notifications_enabled: boolean }
    ): User => {
      const {
        phone_number,
        photo_url,
        cover_photo,
        twitter_url,
        discord_url,
        opensea_url,
        notifications_enabled,
        ...rest
      } = response;

      return {
        phoneNumber: phone_number,
        photoUrl: photo_url,
        coverPhoto: cover_photo,
        twitterUrl: twitter_url,
        openseaUrl: opensea_url,
        discordUrl: discord_url,
        notificationsEnabled: notifications_enabled,
        ...rest,
      };
    },

    patchProfileToApi: (payload: PatchProfilePayload): PatchProfilePayloadApi => {
      const { email, phoneNumber } = payload;

      const _pl: PatchProfilePayloadApi = {};
      if (email) _pl.email = email;
      if (phoneNumber) _pl.phone_number = phoneNumber;
      return _pl;
    },

    patchProfileDetailsToApi: (
      payload: PatchProfileDetailsPayload
    ): PatchProfileDetailsPayloadApi => {
      const { description, twitterUrl, discordUrl, openseaUrl } = payload;

      return {
        description,
        twitter_url: twitterUrl,
        discord_url: discordUrl,
        opensea_url: openseaUrl,
      };
    },

    patchProfilePhotoToApi: (
      payload: PatchProfilePhotoPayload
    ): PatchProfilePhotoPayloadApi => {
      const { contractAddress, tokenId } = payload;
      return {
        contract_address: contractAddress,
        token_id: tokenId,
      };
    },

    connectAddress(payload: ConnectAddressResponseApi): ConnectAddressResponse {
      return {
        alreadyAddedAddresses: payload.already_added_addresses,
      };
    },

    userNfts(payload: UserNFTsResponseApi, params: IRequestParams): UserNFTsResponse {
      const { num_of_results, nfts } = payload;
      return {
        totalCount: num_of_results,
        nextPage: params.page! + 1,
        prevPage: params.page! - 1,
        results: nfts.map(
          ({
            contract_address,
            token_id,
            photo_url,
            is_profile,
            user_uid,
            user_address,
            price_currency,
            additional_urls = [],
            ...rest
          }) => ({
            contractAddress: contract_address,
            tokenId: token_id,
            photoUrls: [photo_url],
            // photoUrls: !additional_urls
            //   ? [photo_url]
            //   : [photo_url, ...additional_urls.filter(url => url !== photo_url)],
            isProfile: is_profile,
            userUid: user_uid,
            userAddress: user_address,
            priceCurrency: price_currency,
            ...rest,
          })
        ),
      };
    },

    followers: (response: UserFollowersResponseApi[]): Follower[] => {
      return response.map(({ photo_url, user_uid, username }) => ({
        photoUrl: photo_url,
        userUid: user_uid,
        username,
      }));
    },

    followings: (response: {
      following_projects: ProjectFollowingsResponseApi[] | null;
      following_addresses: UserFollowingsResponseApi[] | null;
    }): Following[] => {
      const { following_projects = [], following_addresses } = response;

      const projArr = following_projects || [];

      const usersArr = following_addresses || [];

      const projects: Following[] = projArr.map(
        ({ project_uid, title, photo_url, address }) => ({
          entityId: project_uid,
          photoUrl: photo_url,
          address,
          title,
          isContract: true,
        })
      );

      const users: Following[] = usersArr.map(
        ({ user_uid, username, photo_url, address }) => ({
          entityId: user_uid,
          photoUrl: photo_url,
          address,
          title: username,
          isContract: false,
        })
      );

      return [...projects, ...users];
    },

    postProjectToApi: (
      payload: PostProjectRequestPayload
    ): PostProjectRequestPayloadApi => {
      const {
        contractAddress,
        externalUrl,
        twitterUrl,
        discordUrl,
        openseaUrl,
        photoUrl,
        description,
        title,
        signature,
      } = payload;

      return {
        contract_address: contractAddress,
        external_url: externalUrl,
        discord_url: discordUrl,
        opensea_url: openseaUrl,
        profile_photo_url: photoUrl,
        twitter_url: twitterUrl,
        description,
        title,
        signature,
      };
    },

    patchProjectAdditionalDetailsToApi: (
      payload: ProjectAdditionalDetails
    ): ProjectAdditionalDetailsApi => {
      const { twitterUrl, discordUrl, openseaUrl, externalUrl, description } = payload;

      return {
        twitter_url: twitterUrl,
        discord_url: discordUrl,
        opensea_url: openseaUrl,
        external_url: externalUrl,
        description,
      };
    },

    popularUsers: (response: PopularUserItemResponseApi[]): PopularUser[] => {
      return response.map(({ photo_url, followers_count, ...rest }) => ({
        ...rest,
        photoUrl: photo_url,
        followersCount: followers_count,
      }));
    },
  };

  getProfile = async (): Promise<User> => {
    const { data } = await axiosInstance.get<
      ProfileResponseApi & { notifications_enabled: boolean }
    >(apiRoutes.user.profile.root());

    return this.apiMapper.profile(data);
  };

  patchProfile = async (payload: PatchProfilePayload): Promise<void> => {
    await axiosInstance.patch(
      apiRoutes.user.profile.basic(),
      this.apiMapper.patchProfileToApi(payload)
    );
  };

  patchProfileDetails = async (payload: PatchProfileDetailsPayload): Promise<void> => {
    await axiosInstance.patch(
      apiRoutes.user.profile.additional(),
      this.apiMapper.patchProfileDetailsToApi(payload)
    );
  };

  patchProfilePhoto = async (payload: PatchProfilePhotoPayload): Promise<void> => {
    await axiosInstance.patch(
      apiRoutes.user.profilePhoto(),
      this.apiMapper.patchProfilePhotoToApi(payload)
    );
  };

  connectAddress = async (
    payload: ConnectAddressPayload
  ): Promise<ConnectAddressResponse> => {
    const { data } = await axiosInstance.post<ConnectAddressResponseApi>(
      apiRoutes.user.connectAddress(),
      { addresses: [payload] }
    );

    return this.apiMapper.connectAddress(data);
  };

  setCoverPhoto = async (formData: FormData) => {
    await axiosInstance.post(apiRoutes.user.coverPhoto(), formData, {
      headers: {
        'Content-Type': 'multipart/form-data content',
      },
    });
  };

  disconnectAddress = async (payload: DisconnectAddressPayload): Promise<void> => {
    await axiosInstance.post(apiRoutes.user.disconnectAddress(), payload);
  };

  getUserNfts = async (payload: IRequestParams): Promise<UserNFTsResponse> => {
    const { data } = await axiosInstance.get<UserNFTsResponseApi>(
      apiRoutes.user.nfts(payload)
    );
    return this.apiMapper.userNfts(data, payload);
  };

  follow = async (address: string) => {
    await axiosInstance.post(apiRoutes.user.follow(address));
  };

  unfollow = async (address: string) => {
    await axiosInstance.post(apiRoutes.user.unfollow(address));
  };

  getFollowers = async (uidOraddress: string) => {
    const { data } = await axiosInstance.get<{
      followers: UserFollowersResponseApi[] | null;
    }>(apiRoutes.user.followers(uidOraddress));
    return this.apiMapper.followers(data?.followers || []);
  };

  getFollowings = async (uid: string) => {
    const { data } = await axiosInstance.get<{
      following_addresses: UserFollowingsResponseApi[] | null;
      following_projects: ProjectFollowingsResponseApi[] | null;
    }>(apiRoutes.user.following(uid));
    return this.apiMapper.followings(data);
  };

  getProjects = async () => {
    const { data } = await axiosInstance.get<ProjectListResponseApiItem[]>(
      apiRoutes.user.projects.root()
    );

    return SharedApiMapper.projects(data || []);
  };

  getProject = async (uid: string) => {
    const { data } = await axiosInstance.get<ProjectResponseApi>(
      apiRoutes.user.project.byId(uid)
    );

    return SharedApiMapper.project(data);
  };

  postPoject = async (payload: PostProjectRequestPayload) => {
    const { data } = await axiosInstance.post<ProjectResponseApi>(
      apiRoutes.user.project.root(),
      this.apiMapper.postProjectToApi(payload)
    );

    return SharedApiMapper.project(data);
  };

  getProjectMetadata = async (address: string) => {
    const { data } = await axiosInstance.get<ProjectMetadataResponseApi>(
      apiRoutes.user.project.metadata(address)
    );

    return SharedApiMapper.projectMetadata(data);
  };

  patchProjectAdditionalDetails = async (
    projectUid: string,
    payload: ProjectAdditionalDetails
  ) => {
    if (!projectUid) throw new Error('Project UID missing');
    const { data } = await axiosInstance.patch(
      apiRoutes.user.project.additionalDetails(projectUid),
      this.apiMapper.patchProjectAdditionalDetailsToApi(payload)
    );

    return SharedApiMapper.project(data);
  };

  setProjectCoverPhoto = async (projectId: string, formData: FormData) => {
    await axiosInstance.post(apiRoutes.user.project.byId(projectId), formData, {
      headers: {
        'Content-Type': 'multipart/form-data content',
      },
    });
  };

  deleteProject = async (projectId: string) => {
    await axiosInstance.delete(apiRoutes.user.project.byId(projectId));
  };

  getProjectEndorsments = async (projectUid: string) => {
    const { data } = await axiosInstance.get<{
      endorsements: UserFollowersResponseApi[];
    }>(apiRoutes.user.project.endorsments(projectUid));
    return this.apiMapper.followers(data.endorsements || []);
  };

  getProjectFollowers = async (projectUid: string) => {
    const { data } = await axiosInstance.get<{
      followers: UserFollowersResponseApi[] | null;
    }>(apiRoutes.user.project.followers(projectUid));

    return this.apiMapper.followers(data?.followers || []);
  };

  addEndorsement = async (projectId: string) => {
    await axiosInstance.post(apiRoutes.user.project.addEndorsement(projectId));
  };

  removeEndorsement = async (projectId: string) => {
    await axiosInstance.post(apiRoutes.user.project.removeEndorsement(projectId));
  };

  followProject = async (address: string) => {
    await axiosInstance.post(apiRoutes.user.project.follow(address));
  };

  unfollowProject = async (address: string) => {
    await axiosInstance.post(apiRoutes.user.project.unfollow(address));
  };

  getPopularUsers = async () => {
    const { data } = await axiosInstance.get<PopularUserItemResponseApi[]>(
      apiRoutes.user.popular()
    );

    return this.apiMapper.popularUsers(data);
  };

  getRecommendedUsers = async (params?: IRequestParams) => {
    const { data } = await axiosInstance.get<PopularUserItemResponseApi[]>(
      apiRoutes.user.recommended(params)
    );

    return this.apiMapper.popularUsers(data || []);
  };

  reportAddress = async (payload: ReportAddressPayload) => {
    await axiosInstance.post(apiRoutes.user.report(), payload);
  };

  deleteProfile = async () => {
    await axiosInstance.delete(apiRoutes.user.profile.root());
  };

  uploadProjectDocument = async (formData: FormData) => {
    const { data } = await axiosInstance.post<{ document_url: string }>(
      apiRoutes.user.project.document(),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data content',
        },
      }
    );
    return data?.document_url;
  };
}

export const UserApi = new UserApiConfig();
