import React from 'react';
import { useTranslation } from 'react-i18next';

import { useReputationWithAddress } from '../../../../../providers/AddressProvider/useReputationWithAddress';

import { LastTransactionSvg } from '../../../../../assets/svgs/reputation-svgs';
import { AnalysisResultSecondaryCardBase } from './AnalysisResultSecondaryCardBase';

export const LastTransaction: React.FC = () => {
  const { t } = useTranslation('analysisResult');
  const { data } = useReputationWithAddress();

  const { lastTransactionTime } = data || {};

  const timeCount =
    !lastTransactionTime || lastTransactionTime === 'missing'
      ? ''
      : lastTransactionTime.split(' ')[0];

  const suffix =
    lastTransactionTime === 'today' || lastTransactionTime === 'missing' || !timeCount
      ? ''
      : `${String(lastTransactionTime).split(' ').slice(1, -1).join(' ')} ago`;

  return (
    <AnalysisResultSecondaryCardBase
      label={t('lastTransaction.title')}
      value={timeCount}
      valueSuffix={suffix}
      icon={<LastTransactionSvg />}
    />
  );
};
