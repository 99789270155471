import React from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PostProjectRequestPayload } from '../../../api/UserApi/UserApi';

import { InputStyle } from '../../ui/forms/Input/InputBase';
import { InputWithIcons } from '../../ui/forms/Input/InputWithIcons';
import { FieldWrapper } from './FieldWrapper';

import { ReactComponent as TwitterIcon } from '../../../assets/svgs/social/TwitterWhite.svg';
import { ReactComponent as OpenseaIcon } from '../../../assets/svgs/social/OpenseaWhite.svg';
import { ReactComponent as DiscordIcon } from '../../../assets/svgs/social/DiscordWhite.svg';
import { ReactComponent as WWWIcon } from '../../../assets/svgs/social/WWWWhite.svg';

export const ProjectSocialNetworks: React.FC = () => {
  const { t } = useTranslation();
  const {
    watch,
    register,
    setValue,
    formState: { errors },
  } = useFormContext<PostProjectRequestPayload>();

  return (
    <InputsWrapper label={t('common.socialNetworks')}>
      <Input
        iconLeft={<WWWIcon />}
        styling={InputStyle.Dark}
        placeholder='Website'
        error={errors.twitterUrl?.message}
        hasValue={!!watch('externalUrl')}
        iconSize={24}
        {...register('externalUrl')}
        onClear={() => setValue('externalUrl', null, { shouldDirty: true })}
      />
      <Input
        iconLeft={<TwitterIcon />}
        styling={InputStyle.Dark}
        placeholder='Twitter'
        error={errors.twitterUrl?.message}
        hasValue={!!watch('twitterUrl')}
        iconSize={24}
        {...register('twitterUrl')}
        onClear={() => setValue('twitterUrl', null, { shouldDirty: true })}
      />
      <Input
        iconLeft={<OpenseaIcon />}
        styling={InputStyle.Dark}
        placeholder='Opensea'
        error={errors.openseaUrl?.message}
        hasValue={!!watch('openseaUrl')}
        iconSize={24}
        {...register('openseaUrl')}
        onClear={() => setValue('openseaUrl', null, { shouldDirty: true })}
      />
      <Input
        iconLeft={<DiscordIcon />}
        styling={InputStyle.Dark}
        placeholder='Discord'
        error={errors.discordUrl?.message}
        hasValue={!!watch('discordUrl')}
        iconSize={24}
        {...register('discordUrl')}
        onClear={() => setValue('discordUrl', null, { shouldDirty: true })}
      />
    </InputsWrapper>
  );
};

const InputsWrapper = styled(FieldWrapper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 8,
}));

const Input = styled(InputWithIcons)(({ theme }) => ({
  height: 48,
  [theme.betweenDM]: {
    height: theme.pxToVw(48),
  },
}));
