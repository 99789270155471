import React from 'react';
import { useTranslation } from 'react-i18next';

import { AssetDetailCardBase } from './AssetDetailCardBase';
import { useProfileTotalAssets } from '../../../../../react-query/profile/useProfileTotalAssets';
import { getDisplayValue } from '../../../../../utils/data-utils';

export const AssetDetailBalance: React.FC = () => {
  const { t } = useTranslation('analysisResult');
  const { data: totalAssets } = useProfileTotalAssets();

  return (
    <AssetDetailCardBase
      label={t('balance')}
      value={totalAssets ? getDisplayValue(totalAssets.balanceUsd, { prefix: '$' }) : ''}
      additionalValue={
        totalAssets ? getDisplayValue(totalAssets.balanceEth, { suffix: 'ETH' }) : ''
      }
      badge=''
    />
  );
};
