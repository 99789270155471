import { Following } from '../../../../../types';
import { FollowerGrouped } from './FollowerItem';

export const groupFollowingsByUsername = (
  followings: Following[]
): Omit<FollowerGrouped, 'onClick'>[] => {
  const mappedFollowings: Partial<Record<string, Omit<FollowerGrouped, 'onClick'>>> = {};
  
  followings.forEach(following => {
    const key = following.title || following.address;

    if (!mappedFollowings[key]) {
      mappedFollowings[key] = {
        username: following.title,
        photoUrl: following.photoUrl,
        address: following.isContract
          ? following.address
          : following.title
          ? undefined
          : following.address,
        addresses: [],
        userUid: following.entityId,
        type: 'following',
        isContract: following.isContract,
      };
    }

    if (following.title) {
      mappedFollowings[key]?.addresses?.push(following.address);
    }
  });

  return Object.values(mappedFollowings).filter(Boolean) as FollowerGrouped[];
};
