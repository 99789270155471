import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useProjectDetailsForm } from '../../../components/project/project-form-components/useProjectDetailsForm';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import {
  AddressProvider,
  useAddress,
} from '../../../providers/AddressProvider/AddressProvider';
import {
  useUserProjectByParamId,
  useUserProjectEdit,
} from '../../../react-query/user/useUserProjects';

import { Form } from '../../../components/forms/Form';
import { CenteredLayoutWithTitle } from '../../../components/layouts/CenteredLayoutWithTitle/CenteredLayoutWithTitle';
import { ProjectDetailsForm } from '../../../components/project/project-form-components/ProjectDetailsForm';
import { ModeProvider } from '../../../providers/ModeProvider';

import { ButtonPrimary } from '../../../components/ui/buttons';
import { CancelButton } from '../../../components/common/ActionButtons/CancelButton';

export const ProjectEditDetailsPage: React.FC = () => (
  <AddressProvider>
    <Content />
  </AddressProvider>
);

const Content: React.FC = () => {
  const { t } = useTranslation();

  const { data: project, isLoading: projectIsLoading } = useUserProjectByParamId();

  const methods = useProjectDetailsForm(undefined, true);
  const { setAddress } = useAddress();
  const { reset } = methods;
  const { mutate: editProfile, isLoading: isEditing } = useUserProjectEdit(project?.uid);

  useDocumentTitle(t('projectEditPage.pageTitle'));

  useEffect(() => {
    if (!project) return;
    const {
      contractAddress,
      title,
      description,
      twitterUrl,
      discordUrl,
      openseaUrl,
      externalUrl,
      ownerAddress,
    } = project;

    setAddress(ownerAddress);

    reset({
      contractAddress,
      title,
      description,
      twitterUrl,
      discordUrl,
      openseaUrl,
      externalUrl,
    });
  }, [project, reset, setAddress]);

  return (
    <CenteredLayoutWithTitle title={t('projectEditDetailsPage.title')} maxWidth={494}>
      <ModeProvider mode='edit'>
        <FormProvider {...methods}>
          <Form
            isLoading={projectIsLoading}
            onSubmit={methods.handleSubmit(data => {
              const { description, twitterUrl, discordUrl, openseaUrl, externalUrl } =
                data;

              const payload = {
                description,
                twitterUrl,
                discordUrl,
                openseaUrl,
                externalUrl,
              };

              editProfile(payload);
            })}
          >
            <ProjectDetailsForm />
            <ActionButtons>
              <ButtonPrimary
                type='submit'
                isLoading={isEditing}
                disabled={!methods.formState.isDirty}
              >
                {t('common.saveChanges')}
              </ButtonPrimary>
              <CancelButton onClick={() => methods.reset()} disabled={isEditing} />
            </ActionButtons>
          </Form>
        </FormProvider>
      </ModeProvider>
    </CenteredLayoutWithTitle>
  );
};

const ActionButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 19,
  marginTop: 10,
  '& button': {
    flex: 1,
    '&:first-child': {
      minWidth: 210,
    },
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(19),
    marginTop: theme.pxToVw(10),
    '& button': {
      '&:first-child': {
        minWidth: theme.pxToVw(210),
      },
    },
  },
}));
