import styled from 'styled-components';
import { Container as ContainerEl } from '../../../../components/layouts/Container';
import { LogoSpinner } from '../../../../components/ui/loaders/LogoSpinner';
import { Heading3 } from '../../../../components/ui/text/Heading';
import { Text as TextEl } from '../../../../components/ui/text/Text';

const Container = styled(ContainerEl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  flexShrink: 0,
  padding: `24px 0 64px 0`,
  '& .scroll-to': {
    position: 'absolute',
    top: -120,
    pointerEvents: 'none',
  },

  [theme.min(theme.breakpoints.desktop)]: {
    padding: `${theme.pxToVw(36)} 0 ${theme.pxToVw(92)} 0`,
  },
  [theme.min(theme.breakpoints.max)]: {
    padding: `36px 0 92px 0`,
  },
}));

const Heading = styled(Heading3)(({ theme }) => ({
  marginBottom: 20,
  [theme.min(theme.breakpoints.sm)]: {
    marginBottom: 16,
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    marginBottom: theme.pxToVw(16),
  },
}));

const ShareResultWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  margin: '0 auto',
  gap: 18,
  marginTop: 20,
  [theme.min(theme.breakpoints.sm)]: {
    margin: '28px 0 0 auto',
    flexDirection: 'row',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(24),
    marginTop: theme.pxToVw(40),
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 24,
    marginTop: 40,
  },
}));

const Spinner = styled(LogoSpinner)(({ theme }) => ({
  margin: '0 auto',
}));

const ShareText = styled(TextEl.MD)(({ theme }) => ({}));

const Styled = {
  Container,
  Heading,
  ShareResultWrapper,
  ShareText,
  LogoSpinner: Spinner,
};

export default Styled;
