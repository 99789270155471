import React, { useEffect, memo, useRef, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';

import Styled from './RootModal.styles';

interface ProtalComponentProps {
  children: React.ReactNode;
  isOpen?: boolean;
}

const rootModalEl = document.getElementById('modal-root');

export const Portal: React.NamedExoticComponent<ProtalComponentProps> = memo(
  ({ children }) => {
    const el = useRef<HTMLDivElement>(document.createElement('div'));
    const { current } = el;

    useEffect(() => {
      if (!current || !rootModalEl) return;
      rootModalEl.append(current);
      return () => {
        rootModalEl.removeChild(current);
      };
    }, [current]);

    return createPortal(children, current);
  }
);

export interface RootModalComponentProps {
  isOpen?: boolean;
  closeOnOutsideClick?: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
}

export const RootModalComponent: React.FC<RootModalComponentProps> = ({
  onClose,
  isOpen,
  closeOnOutsideClick = true,
  children,
}) => {
  const [open, setOpen] = useState(!!isOpen);

  const el = useRef<HTMLDivElement>(null);

  const handleClose = useCallback(() => {
    typeof onClose === 'function' && onClose();
    setOpen(false);
  }, [onClose]);

  useEffect(() => {
    setOpen(!!isOpen);
  }, [isOpen]);

  if (!open) return null;

  return (
    <Portal>
      <Styled.BackdropEl
        ref={el}
        className='modal-backdrop'
        onClick={e => closeOnOutsideClick && e.target === el.current && handleClose()}
      >
        {children}
      </Styled.BackdropEl>
    </Portal>
  );
};
