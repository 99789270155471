import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAddress } from '../../../../../../../providers/AddressProvider/AddressProvider';

import { Transaction, TransactionType } from '../../../../../../../types';
import { ActionText } from '../../../../../../../components/ui/text/ActionText';
import { LoadingBackground } from '../../../../../../../components/ui/loaders/LoadingBackground';

import { ReactComponent as SendSvg } from './Send.svg';
import { ReactComponent as ReceivedSvg } from './Received.svg';

import {
  shortenEthAddress,
  isEmptyAddress,
} from '../../../../../../../utils/address-utils';
import { formatDateTimeToAmPm } from '../../../../../../../utils/date-utils';
import { routes } from '../../../../../../../router/routes';

interface Props {
  transaction: Transaction;
  isLoading?: boolean;
}

export const TransactionItem: React.FC<Props> = ({ transaction }) => {
  const { t } = useTranslation();
  const { address } = useAddress();
  const navigate = useNavigate();

  const isSent = address === transaction.from;

  const value = parseFloat((+transaction.value / 1000000000000000000).toFixed(4));

  const finalValue = isSent ? -value : value;

  const displayValue = value < 0.01 ? `< ${isSent ? '-' : ''}0.01` : finalValue;

  const gasFee = parseFloat(
    ((+transaction.gasPrice * +transaction.gasUsed) / 1000000000000000000).toFixed(4)
  );

  const displayGasFee = gasFee < 0.01 ? '< 0.01' : gasFee;

  const displayAddress = isSent ? transaction.to : transaction.from;

  const onAddressClick = isEmptyAddress(displayAddress)
    ? undefined
    : () => navigate(routes.profile.byId(displayAddress));

  const getSuffix = () => {
    switch (transaction.txType) {
      case TransactionType.Normal:
        return 'ETH';
      case TransactionType.Token:
        if (isEmptyAddress(displayAddress) && +transaction.value === 0) {
          return t('common.tokenMint');
        }
        return t('common.tokenTransfer');
      default:
        if (isEmptyAddress(displayAddress) && +transaction.value === 0) {
          return t('common.nftMint');
        }
        return t('common.nftTransfer');
    }
  };

  return (
    <Wrapper>
      <Group>
        <DataItem
          circleColor='primary'
          label={t(isSent ? 'common.send' : 'common.received')}
          value={formatDateTimeToAmPm(transaction.timeStamp)}
          icon={isSent ? 'send' : 'received'}
        />
        <DataItem
          circleColor='green'
          label={t(isSent ? 'common.to' : 'common.from')}
          onAddressClick={onAddressClick}
          value={shortenEthAddress(displayAddress, {
            startChars: 4,
            placeholderChar: '.',
            placeholderCount: 3,
          })}
        />
      </Group>

      <Group>
        <DataItem label={t('common.gasFee')} value={String(displayGasFee)} />
        <DataItem
          circleColor='white'
          label={!+transaction.value ? '0' : String(displayValue)}
          value={getSuffix()}
        />
      </Group>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: 'rgba(61, 72, 89, 0.4)',
  borderRadius: 15,
  gap: 16,
  padding: '14px 16px',
  '&:not(:last-child)': {
    marginBottom: 8,
  },
  [theme.min(theme.breakpoints.xs)]: {
    gap: 32,
    padding: '8px 16px',
  },
  [theme.betweenDM]: {
    borderRadius: theme.pxToVw(15),
    gap: theme.pxToVw(32),
    padding: `${theme.pxToVw(8)} ${theme.pxToVw(16)}`,
    '&:not(:last-child)': {
      marginBottom: theme.pxToVw(8),
    },
  },
}));

const Group = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  '&:last-child': {
    alignItems: 'flex-end',
    '& p': {
      textAlign: 'right',
    },
  },
  [theme.min(theme.breakpoints.sm)]: {
    flexDirection: 'row',
    '&:first-child': {
      width: '35%',
      minWidth: 300,
      justifyContent: 'space-between',
    },
    '&:last-child': {
      flex: 1,
      flexDirection: 'row-reverse',
      alignItems: 'center',
      '& div:last-child p': {
        textAlign: 'left',
      },
    },
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(12),
    '&:first-child': {
      minWidth: theme.pxToVw(300),
    },
  },
}));

interface DataItemProps {
  circleColor?: 'primary' | 'white' | 'green';
  label: string;
  value: string;
  icon?: 'send' | 'received';
  isLoading?: boolean;
  onAddressClick?: () => void;
}

const DataItem: React.FC<DataItemProps> = ({
  circleColor,
  label,
  value,
  icon,
  onAddressClick,
}) => (
  <DataItemWrapper hasCircle={!!circleColor}>
    {circleColor && (
      <Circle type={circleColor}>
        {icon === 'send' ? <SendSvg /> : icon === 'received' ? <ReceivedSvg /> : null}
      </Circle>
    )}
    <DataText>
      <span>{label}</span>
      <br />
      {!onAddressClick ? (
        value
      ) : (
        <ActionText onClick={onAddressClick} underline={false}>
          {value}
        </ActionText>
      )}
    </DataText>
  </DataItemWrapper>
);

const DataItemWrapper = styled('div')<{ hasCircle?: boolean }>(
  ({ theme, hasCircle }) => ({
    display: 'flex',
    gap: 14,
    alignItems: 'center',
    [theme.min(theme.breakpoints.sm)]: {
      flex: 1,
      justifyContent: hasCircle ? 'flex-start' : 'flex-end',
    },
    [theme.betweenDM]: {
      gap: theme.pxToVw(14),
    },
  })
);

const Circle = styled('div')<{ type: 'primary' | 'white' | 'green' }>(
  ({ theme, type }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    width: 40,
    borderRadius: '50%',
    backgroundColor:
      type === 'primary'
        ? theme.colors.primary.main
        : type === 'white'
        ? '#fff'
        : '#8DD174',
    [theme.betweenDM]: {
      height: theme.pxToVw(40),
      width: theme.pxToVw(40),
    },
  })
);

const DataText = styled('p')(({ theme }) => ({
  fontSize: 14,
  color: '#fff',
  lineHeight: 1.31,
  '& span:first-child': {
    color: theme.colors.text.whiteTransparent70,
  },
  '& span': {
    fontWeight: '600 !important',
  },
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(14),
  },
}));

export const TransactionItemLoader: React.FC = () => {
  return <Loader />;
};

const Loader = styled(LoadingBackground)(({ theme }) => ({
  height: 120,
  [theme.min(theme.breakpoints.sm)]: {
    height: 56,
  },
  [theme.betweenDM]: {
    height: theme.pxToVw(56),
  },
}));
