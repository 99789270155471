import React from 'react';
import styled from 'styled-components';
import { useStep } from '../../../../providers/StepProvider';
import { StepCircle, DottedLine, StepLabel } from './components';

export const Steps: React.FC = props => {
  const { step: currentStep } = useStep();
  return (
    <Wrapper {...props}>
      <IconsWrapper>
        {[0, 'dots', 1, 'dots', 2].map((step, i) =>
          typeof step === 'number' ? (
            <StepCircle key={step} isCompleted={step < currentStep} />
          ) : (
            <DottedLine key={i + 10} isCompleted={i <= currentStep} />
          )
        )}
      </IconsWrapper>
      <LabelsWrapper>
        {[0, 1, 2].map(step => (
          <StepLabel key={step} step={step} isCompleted={step < currentStep} />
        ))}
      </LabelsWrapper>
    </Wrapper>
  );
};

const Flex = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const IconsWrapper = styled(Flex)(({ theme }) => ({
  gap: 8,
}));

const LabelsWrapper = styled(Flex)(({ theme }) => ({
  justifyContent: 'space-between',
}));

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  marginBottom: 18,
}));
