import React from 'react';
import { useTranslation } from 'react-i18next';

import { useReputationWithAddress } from '../../../../../providers/AddressProvider/useReputationWithAddress';

import { AnalysisResultSecondaryCardBase } from './AnalysisResultSecondaryCardBase';

export const TotalValueSent: React.FC = () => {
  const { t } = useTranslation('analysisResult');
  const { data } = useReputationWithAddress();

  const { totalValueSentEth, totalValueSentUsd, numberOfTransactions } = data || {};

  const valueEth =
    typeof totalValueSentEth === 'number' && totalValueSentEth > -1
      ? `${totalValueSentEth.toLocaleString()} ETH`
      : '';

  const valueUsd =
    typeof totalValueSentUsd === 'number' && totalValueSentUsd > -1
      ? `$${Math.round(totalValueSentUsd).toLocaleString()}`
      : '';

  const info =
    typeof numberOfTransactions === 'number' && numberOfTransactions > 1999
      ? t('homePage.results.totalValueSent.info')
      : undefined;

  return (
    <AnalysisResultSecondaryCardBase
      label={t('totalValueSent.title')}
      value={valueEth}
      additionalValue={valueUsd}
      info={info}
    />
  );
};
