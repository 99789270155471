import { useLayoutEffect, useState } from 'react';

export const useWindowHeight = (): number => {
  const [windowHeight, setWindowInnerWidth] = useState(0);

  useLayoutEffect(() => {
    function updateWindowHeight() {
      setWindowInnerWidth(window.innerHeight);
    }

    window.addEventListener('resize', updateWindowHeight);
    updateWindowHeight();

    return () => window.removeEventListener('resize', updateWindowHeight);
  }, []);

  return windowHeight;
};
