import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../../../providers/AuthProvider';
import { useProjectFollowersCount } from '../../../../react-query/project/useProjectFollowersCount';
import { useCurrentUserFollowings } from '../../../../react-query/user/useFollowings';
import { useUser } from '../../../../react-query/user/useUser';
import { useProject } from '../../../../react-query/project/useProject';
import { useAddress } from '../../../../providers/AddressProvider/AddressProvider';
import { useEndorsmentsTotalCount } from '../../../../react-query/project/useEndorsmentsCount';
import { useFollowProject } from '../../../../hooks/useFollowProject';
import { useProjectUtils } from '../../../../pages/project/profile/hooks/useProjectUtils';
import { useWindowWidth } from '../../../../hooks/useWindowWidth';
import { useReputationWithAddress } from '../../../../providers/AddressProvider/useReputationWithAddress';
import { useTrustCategory } from '../TrustCategoryProvider';

import { routes } from '../../../../router/routes';
import { envs } from '../../../../config/envs';

import { ShareProfileButton } from '../../ActionButtons/ShareProfileButton';
import { ProfileBadgeImage } from './components/ProfileBadgeImage';
import {
  AddThisToMyProfileButton,
  ClaimAndCreateProfileButton,
  DetailsWrapper,
  EndorsmentsCount,
  FlexWrapper,
  FollowersCount,
  ProfileBadgeUsername,
  ReportProfile,
  SeeThisProject,
} from './components/shared';
import { ActionTextVariant } from '../../../ui/text/ActionText';
import { FollowButtonBase } from '../../../ui/buttons/FollowButtonBase';
import { isValidEthAddress, shortenEthAddress } from '../../../../utils/address-utils';
import { Trust } from '../../../../types';

export const ProfileBadgeContract: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: reputation } = useReputationWithAddress();
  const { address } = useAddress();
  const { trust } = useTrustCategory();
  const { isLoading: useFollowingsIsLoading } = useCurrentUserFollowings();
  const { isLoading: userIsLoading } = useUser();
  const { isFollowedProject, isProjectOwner } = useProjectUtils();
  const { isAuthenticated } = useAuth();
  const { data: project, isLoading: projectIsLoading } = useProject(address);
  const { call, isLoading: followingIsLoading } = useFollowProject(project);
  const width = useWindowWidth();

  const { data: followersCount, isLoading: followersIsLoading } =
    useProjectFollowersCount(project?.uid);

  const { data: endorsmentsCount, isLoading: endorsmentsIsLoading } =
    useEndorsmentsTotalCount(project?.uid);

  const theIsLoading =
    userIsLoading ||
    projectIsLoading ||
    followersIsLoading ||
    endorsmentsIsLoading ||
    useFollowingsIsLoading;

  const isOwner = project && isProjectOwner(project.contractAddress);

  const onClaim = () => {
    if (!project) return;

    if (!isAuthenticated) {
      return navigate(
        routes.auth.register() + `?contractAddress=${project.contractAddress}`
      );
    }

    navigate(routes.project.root() + `?contractAddress=${project.contractAddress}`);
  };

  const renderClaimButton = () => {
    if (project?.uid) return null;
    if (isAuthenticated) return <AddThisToMyProfileButton onClick={onClaim} isContract />;
    return <ClaimAndCreateProfileButton onClick={onClaim} isContract />;
  };

  const renderFollowButton = () => {
    if (isOwner || !project?.uid) return null;
    const isFollowed = isFollowedProject(project.contractAddress!);
    return (
      <FollowButtonBase
        onClick={() => call(true)}
        isLoading={followingIsLoading}
        isFollowed={isFollowed}
      />
    );
  };

  const renderReportProfile1 = () => {
    if (
      trust !== Trust.Untrusted ||
      !reputation ||
      width < 580 ||
      (width < 1300 && width >= 580 && !reputation.ownerUsername)
    ) {
      return null;
    }

    return <ReportProfile address={reputation.address} />;
  };

  const renderReportProfile2 = () => {
    if (
      trust !== Trust.Untrusted ||
      !reputation ||
      width > 1300 ||
      (width >= 580 && reputation.ownerUsername)
    ) {
      return null;
    }

    return <ReportProfile address={reputation.address} style={{ marginTop: 12 }} />;
  };

  const renderContent = () => {
    if (!address) return null;

    let displayValue = project?.uid ? project?.title : address;

    displayValue =
      width < 400 && isValidEthAddress(displayValue)
        ? shortenEthAddress(displayValue, { startChars: 32 })
        : displayValue;

    return (
      <>
        <ProfileBadgeUsername
          onClick={() => navigate(routes.project.byId(address))}
          $disabled={!project?.uid}
          underline={false}
        >
          {displayValue}
        </ProfileBadgeUsername>
        <FlexWrapper>
          {project?.uid ? (
            <>
              <FollowersCount count={followersCount} />
              <EndorsmentsCount count={endorsmentsCount} />{' '}
            </>
          ) : (
            <span style={{ marginBottom: 20 }}>
              <SeeThisProject
                onClick={() => navigate(routes.project.byId(address))}
                variant={ActionTextVariant.Inverted}
              >
                {t('common.seeThisProjectProfilePreview')}
              </SeeThisProject>
            </span>
          )}
        </FlexWrapper>
        <FlexWrapper>
          {renderClaimButton()}
          {renderFollowButton()}
          <ShareProfileButton
            shareUrl={`${envs.blockemWebsiteUrl}/p/${address}`}
            menuXPosition='left'
          />
          {renderReportProfile1()}
        </FlexWrapper>
        {renderReportProfile2()}
      </>
    );
  };

  return (
    <>
      {(!projectIsLoading && !project) || (project && !project.uid) ? null : (
        <ProfileBadgeImage
          imageUrl={project?.photoUrl}
          isLoading={theIsLoading}
          onClick={() => project && navigate(routes.project.byId(address!))}
        />
      )}
      <DetailsWrapper isLoading={theIsLoading}>{renderContent()}</DetailsWrapper>
    </>
  );
};
