import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';

import { ReportAddressPayload } from '../../../../../api/UserApi/UserApi';
import i18n from '../../../../../i18n';
import { joiLib } from '../../../../../joi';

const schema = Joi.object({
  address: joiLib.ethAddress.required(),
  reason: Joi.string(),
  type: Joi.string()
    .required()
    .messages({
      'any.required': i18n.t('errors.fieldRequired'),
      'string.required': i18n.t('errors.fieldRequired'),
      'string.empty': i18n.t('errors.fieldRequired'),
    }),
});

export const useReportAddressForm = () => {
  return useForm<ReportAddressPayload>({
    defaultValues: { address: '', type: '', reason: '' },
    resolver: joiResolver(schema),
  });
};
