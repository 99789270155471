import styled from 'styled-components';
import { Container } from '../../../../../components/layouts/Container';
import { LoadingBackground } from '../../../../../components/ui/loaders/LoadingBackground';
import { useProjectByParamId } from '../../../../../react-query/project/useProject';
import { useProjectMilestonesByParamId } from '../../../../../react-query/user/project/useProjectMilestones';
import { useProjectUtils } from '../../hooks/useProjectUtils';
import ProjectRoadmapPlaceholder from './ProjectRoadmapPlaceholder';
import Roadmap from './Roadmap/Roadmap';

export default function ProjectRoadmap() {
  const { data: project } = useProjectByParamId();
  const { isOwner } = useProjectUtils();
  const { data: milestones, isLoading } = useProjectMilestonesByParamId();

  if (isLoading) return null;

  const renderContent = () => {
    if (milestones === undefined || isLoading || !project) {
      return (
        <Cont>
          <LoadingBg />
        </Cont>
      );
    }

    if (milestones?.length) {
      return <Roadmap />;
    }

    return isOwner ? <ProjectRoadmapPlaceholder /> : null;
  };

  return <Wrapper>{renderContent()}</Wrapper>;
}

const Wrapper = styled('section')(({ theme }) => ({
  marginBottom: 32,
  [theme.min(theme.breakpoints.md)]: {
    marginBottom: 64,
  },
  [theme.betweenDM]: {
    marginBottom: theme.pxToVw(64),
  },
}));

const Cont = styled(Container)({});

const LoadingBg = styled(LoadingBackground)({
  height: '30vh',
  maxHeight: 535,
});
