import styled, { CSSObject } from 'styled-components';
import { Theme } from '../../../../config/theme';

export enum InputStyle {
  Regular,
  Dark,
}

export enum InputSize {
  Sm,
  Md,
  Lg,
}
export interface InputBaseProps {
  error?: boolean | string;
  width?: string | number;
  styling?: InputStyle;
  size?: InputSize;
  hasValue?: boolean;
  editable?: boolean;
  activeBackground?: boolean;
}

export const getDarkModeStyles = (theme: Theme, hasValue: boolean): CSSObject => ({
  background: hasValue ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.4)',
  color: theme.colors.primary.main,
  '&:focus': {
    background: 'rgba(0, 0, 0, 0.8)',
    color: '#fff',
  },
});

export const getRegularModeStyles = (
  theme: Theme,
  hasValue: boolean,
  activeBackground: boolean
): CSSObject => ({
  border: '2px solid transparent',
  background: activeBackground ? 'rgba(0, 101, 252, 0.2)' : 'rgba(8, 10, 19, 0.6)',
  padding: hasValue ? '16px 20px 6px 20px' : '6px 20px 6px 20px',
  '&:hover': {
    backgroundColor: 'rgba(0, 101, 252, 0.1)',
  },
  '&:focus': {
    backgroundColor: 'rgba(0, 101, 252, 0.2)',
    border: `2px solid ${theme.colors.primary.main}`,
  },
});

export const getInputStyling = (
  styling: InputStyle,
  theme: Theme,
  hasValue: boolean,
  activeBackground: boolean
) => {
  switch (styling) {
    case InputStyle.Dark:
      return getDarkModeStyles(theme, hasValue);
    case InputStyle.Regular:
    default:
      return getRegularModeStyles(theme, hasValue, activeBackground);
  }
};

export const getInputBaseStyle = (theme: Theme, error?: boolean): CSSObject => ({
  outline: 'none',
  borderRadius: 10,
  boxShadow: error
    ? '0px 0px 3px 2px rgba(252, 87, 87, 0.25)'
    : '0px 0px 3px 2px rgba(53, 133, 254, 0.39)',
  transition: 'all .4s',
  color: '#fff',
  fontSize: 14,
  fontWeight: 600,
  height: 56,
  WebkitAppearance: 'none',
  border: 'none',
  '&:placeholder': {
    color: theme.colors.text.whiteTransparent70,
    fontSize: 14,
    fontFamily: 'inherit',
    fontWeight: 600,
  },
});

export const autoFillStyle = {
  backgroundColor: 'transparent',
  // WebkitBoxShadow: '0 0 0 30px transparent inset !important',
  WebkitTextFillColor: '#fff !important',
  transition: 'background-color 5000s ease-in-out 0s',
};

// TODO: this was used for some buttons...
export const getInputBaseStyles = (
  theme: Theme,
  error?: boolean,
  hasValue?: boolean,
  activeBackground?: boolean
): CSSObject => ({
  ...getInputBaseStyle(theme, error),
  ...getRegularModeStyles(theme, !!hasValue, !!activeBackground),
});

const InputBase = styled('input')<InputBaseProps>(
  ({
    theme,
    error,
    width = '100%',
    hasValue,
    styling = InputStyle.Regular,
    size = InputSize.Md,
    activeBackground,
    editable = true,
  }) => ({
    ...getInputBaseStyle(theme, !!error),
    ...getInputStyling(styling, theme, !!hasValue, !!activeBackground),
    width,
    pointerEvents: editable ? 'auto' : 'none',
    '&:-webkit-autofill': autoFillStyle,
    '&:-webkit-autofill:hover': autoFillStyle,
    '&:-webkit-autofill:focus': autoFillStyle,
    '&:-webkit-autofill:active': autoFillStyle,
    '&:-internal-autofill-previewed': autoFillStyle,
    [theme.betweenDM]: {
      height: theme.pxToVw(56),
      fontSize: theme.pxToVw(14),
      borderRadius: theme.pxToVw(10),
      '&:placeholder': {
        fontSize: theme.pxToVw(14),
      },
      padding: hasValue
        ? `${theme.pxToVw(16)} ${theme.pxToVw(20)} ${theme.pxToVw(6)} ${theme.pxToVw(20)}`
        : `${theme.pxToVw(6)} ${theme.pxToVw(20)} ${theme.pxToVw(6)} ${theme.pxToVw(20)}`,
    },
  })
);

InputBase.defaultProps = {
  spellCheck: 'false',
  autoCapitalize: 'false',
  autoComplete: 'off',
};

export { InputBase };
