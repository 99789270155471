import React from 'react';
import styled from 'styled-components';
import { AddressProvider } from '../../../providers/AddressProvider/AddressProvider';
import { Container } from '../../layouts/Container';
import { GenerateProfileInput } from './GenerateProfileInput';

export const GenerateProfileSection: React.FC = () => {
  return (
    <AddressProvider>
      <Wrapper>
        <GenerateProfileInput withTitle />
      </Wrapper>
    </AddressProvider>
  );
};

const Wrapper = styled(Container)(({ theme }) => ({
  marginTop: 32,
  marginBottom: '10vh',
  [theme.min(theme.breakpoints.desktop)]: {
    marginTop: theme.pxToVw(64),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginTop: 64,
  },
}));
