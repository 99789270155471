import { useMutation } from 'react-query';
import { UserApi } from '../../api/UserApi/UserApi';
import { useInvalidateCurrentUserProfile } from '../../pages/profile/hooks/useInvalidateCurrentUserProfile';
import { QueryKey } from '../query-keys';

export const useUserEdit = () => {
  const invalidate = useInvalidateCurrentUserProfile();
  return useMutation(QueryKey.User(), UserApi.patchProfile, {
    onSuccess: () => {
      invalidate();
    },
  });
};
