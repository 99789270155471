import styled, { CSSObject } from 'styled-components';
import { ButtonVariant } from '.';
import { Theme } from '../../../config/theme';
import { ButtonBase, ButtonSquareBase } from './ButtonBase';

const getButtonStyles = (
  theme: Theme,
  variant: ButtonVariant,
  disabled?: boolean
): CSSObject => ({
  backgroundColor:
    variant === ButtonVariant.Outlined ? 'transparent' : theme.colors.primary.main,
  color: theme.colors.text.primary,
  // opacity: disabled ? 0.6 : 1,
  border:
    variant === ButtonVariant.Filled ? 'none' : `2px solid ${theme.colors.text.primary}`,
  '&:hover': disabled
    ? {}
    : {
        background:
          variant === ButtonVariant.Filled
            ? 'rgba(0, 101, 252, 0.6)'
            : 'rgba(0, 101, 252, 0.1)',
        border:
          variant === ButtonVariant.Filled
            ? 'none'
            : `2px solid ${theme.colors.primary.main}`,
      },
});

export const ButtonPrimary = styled(ButtonBase)<{ variant?: ButtonVariant }>(
  ({ theme, disabled, variant = ButtonVariant.Filled, isLoading }) => ({
    ...getButtonStyles(theme, variant, disabled || isLoading),
  })
);

export const ButtonSquarePrimary = styled(ButtonSquareBase)<{ variant?: ButtonVariant }>(
  ({ theme, disabled, variant = ButtonVariant.Filled, isLoading }) => ({
    ...getButtonStyles(theme, variant, disabled || isLoading),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      position: 'relative',
      overflow: 'visible',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .spinner': {
        position: 'initial',
        height: '2.4rem',
        width: 'auto',
        fill: theme.colors.text.primary,
        color: theme.colors.text.primary,
        animation: 'spin 1.1s infinite ease',
        ...theme.keyframes.spin,
      },
    },
  })
);
