import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '../../../../providers/AuthProvider';
import { useUser } from '../../../../react-query/user/useUser';

import { routes } from '../../../../router/routes';

import { ExpandableMenu } from './ExpandableMenu';
import { useWindowWidth } from '../../../../hooks/useWindowWidth';
import { defaultProfileImage } from '../../../../assets';

const Menu = styled(ExpandableMenu)(({ theme }) => ({
  flexShirnk: 0,
}));

interface Props {
  absolutePositionMenu?: boolean;
  centered?: boolean;
  transparentMenu?: boolean;
  activeOnOpen?: boolean;
}

export const UserMenu: React.FC<Props> = ({
  absolutePositionMenu = true,
  centered = false,
  transparentMenu = false,
  activeOnOpen = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const width = useWindowWidth();
  const { breakpoints } = useTheme();

  const { setIsAuthenticated } = useAuth();
  const { data: user } = useUser();

  if (!user) return null;

  return (
    <Menu
      title={`${t('common.welcome')}, ${user.username}`}
      imageUrl={width < breakpoints.md ? undefined : user.photoUrl || defaultProfileImage}
      absolutePositionMenu={absolutePositionMenu}
      centered={centered}
      transparentMenu={transparentMenu}
      activeOnOpen={activeOnOpen}
      menuItems={[
        {
          text: t('common.myWeb3Profile'),
          onClick: () => {
            if (pathname === routes.profile.root()) return;
            navigate(routes.profile.root());
          },
          active: pathname === routes.profile.root(),
        },
        {
          text: t('accountDetailsPage.title'),
          onClick: () => {
            if (pathname === routes.accountDetails.root()) return;
            navigate(routes.accountDetails.root());
          },
          active: pathname === routes.accountDetails.root(),
        },
        {
          text: t('myProjectsPage.pageTitle'),
          onClick: () => {
            if (pathname === routes.myProjects.root()) return;
            navigate(routes.myProjects.root());
          },
          active: pathname === routes.myProjects.root(),
        },
        {
          text: t('auth.logout'),
          onClick: () => {
            setIsAuthenticated(false);
            localStorage.clear();
            window.location.href = '/';
          },
        },
      ]}
    />
  );
};
