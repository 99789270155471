import styled from 'styled-components';

const Container = styled('div')<{ error?: boolean | string | Error }>(
  ({ theme, error }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    position: 'relative',
    [theme.betweenDM]: {
      gap: theme.pxToVw(4),
    },
  })
);

const Label = styled('label')(({ theme }) => ({
  color: theme.colors.primary.main,
  fontSize: 11,
  position: 'absolute',
  left: 20,
  top: 10,
  zIndex: theme.zIndex.sm,
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(11),
    left: theme.pxToVw(20),
    top: theme.pxToVw(10),
  },
}));

const Styled = { Container, Label };

export default Styled;
