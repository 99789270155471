import React, { Fragment, useRef } from 'react';

export enum FilePickerAccept {
  Image = 'image/*',
  Video = 'video/*',
}

export interface FilePickerCommonProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'accept' | 'ref'> {
  accept: FilePickerAccept;
}

interface FilePickerComponentProps extends FilePickerCommonProps {
  children: React.ReactNode;
}

export const FilePickerWrapper: React.FC<FilePickerComponentProps> = ({
  accept,
  children,
  ...inputProps
}) => {
  const inputElRef = useRef<HTMLInputElement>(null);

  const handleClick = (e: React.MouseEvent): void => {
    if (!inputElRef.current) return;
    inputElRef.current.click();
  };

  const childrenWithProps = React.Children.map(children, child => {
    const props = { onClick: handleClick };
    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }
    return child;
  });

  return (
    <Fragment>
      {childrenWithProps}
      <input
        accept={accept}
        id='contained-button-file'
        name='document'
        type='file'
        style={{ display: 'none' }}
        ref={inputElRef}
        {...inputProps}
      />
    </Fragment>
  );
};
