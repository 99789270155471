// @ts-ignore
import { init } from '@web3-onboard/react';
import { createGlobalStyle } from 'styled-components';

// @ts-ignore
import injectedModule from '@web3-onboard/injected-wallets';

// @ts-ignore
import walletConnectModule from '@web3-onboard/walletconnect';
import coinbaseModule from '@web3-onboard/coinbase';
import Logo from '../assets/svgs/BlockemWhite.svg';
import { envs } from './envs';

const injected = injectedModule();
const walletConnect = walletConnectModule();
const coinbase = coinbaseModule();

export const web3Onboard = init({
  wallets: [injected, walletConnect, coinbase],
  chains: [
    {
      id: '0x1',
      token: 'ETH',
      label: 'Ethereum Mainnet',
      rpcUrl: `https://mainnet.infura.io/v3/${envs.infuraApiKey}`,
    },
    {
      id: '0x3',
      token: 'tROP',
      label: 'Ethereum Ropsten Testnet',
      rpcUrl: `https://ropsten.infura.io/v3/${envs.infuraApiKey}`,
    },
    {
      id: '0x4',
      token: 'rETH',
      label: 'Ethereum Rinkeby Testnet',
      rpcUrl: `https://rinkeby.infura.io/v3/${envs.infuraApiKey}`,
    },
    {
      id: '0x38',
      token: 'BNB',
      label: 'Binance Smart Chain',
      rpcUrl: 'https://bsc-dataseed.binance.org/',
    },
    {
      id: '0x89',
      token: 'MATIC',
      label: 'Matic Mainnet',
      rpcUrl: 'https://matic-mainnet.chainstacklabs.com',
    },
    {
      id: '0xfa',
      token: 'FTM',
      label: 'Fantom Mainnet',
      rpcUrl: 'https://rpc.ftm.tools/',
    },
  ],
  appMetadata: {
    name: 'Blockem Onboard',
    icon: Logo,
    logo: Logo,
    description: 'Some description',
    recommendedInjectedWallets: [
      { name: 'Coinbase', url: 'https://wallet.coinbase.com/' },
      { name: 'MetaMask', url: 'https://metamask.io' },
    ],
  },
});

// https://github.com/blocknative/web3-onboard/blob/v2-web3-onboard-develop/packages/core/README.md#custom-styling
export const Web3OnboardStyle = createGlobalStyle`
:root {
  --onboard-connect-sidebar-background: #3D4859;
  --onboard-connect-sidebar-color: #fff;
  --onboard-connect-header-color: #3D4859;
  --onboard-close-button-background: transparent;

  --onboard-font-size-3: 18px;
  --onboard-font-size-5: 12px;
  --onboard-font-size-6: 12px;

  --onboard-spacing-3: 20px;
  --onboard-spacing-4: 16px;
  --onboard-spacing-5: 10px;
  --onboard-font-family-normal: 'Red Hat Display', sans-serif;
  --onboard-font-family-semibold: 'Red Hat Display', sans-serif;
  --onboard-font-family-light: 'Red Hat Display', sans-serif;
  --account-center-position-top: -1000px;
}

`;
