import styled from 'styled-components';
import { Container as ContainerEl } from '../../components/layouts/Container';

const Container = styled(ContainerEl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  zIndex: theme.zIndex.sm,
}));

const MainDetails = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  [theme.min(theme.breakpoints.sm)]: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    flexWrap: 'nowrap',
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(20),
  },
}));

const AssetDetailsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 20,
  flex: 1,
  flexDirection: 'column',
  [theme.betweenDM]: {
    gap: theme.pxToVw(20),
  },
}));

const Styled = {
  Container,
  MainDetails,
  AssetDetailsWrapper,
};

export default Styled;
