import { useCallback, useState } from 'react';

export interface ApiHookActions<T = any, E = any> {
  onStart?: () => void;
  onSuccess?: (data: T) => void;
  onError?: (error?: E) => void;
  onSettled?: () => void;
}

export const useRequest = <T = any | void, Args = any, Err = any>(
  requestFn: (arg: Args) => Promise<T | void>,
  actions?: ApiHookActions<T, Err>
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState<null | string>(null);
  const [error, setError] = useState<any>();
  const [isSuccess, setIsSuccess] = useState(false);
  const [data, setData] = useState<T | undefined | null>();

  const reset = useCallback(() => {
    setError(undefined);
    setIsLoading(false);
    setIsError(null);
    setIsSuccess(false);
    setData(undefined);
  }, []);

  const call = useCallback(
    async (arg?: Args) => {
      try {
        if (isLoading) return;
        if (typeof actions?.onStart === 'function') actions.onStart();
        reset();
        setIsLoading(true);
        // @ts-ignore
        const res = await requestFn(arg);
        if (res) setData(res);
        setIsLoading(false);
        setIsSuccess(true);
        // @ts-ignore
        if (typeof actions?.onSuccess === 'function') actions.onSuccess(res);
      } catch (err: any) {
        setError(err);
        setIsError(err?.response?.data?.message || err.message);
        setIsLoading(false);
        if (typeof actions?.onError === 'function') actions.onError(err);
      } finally {
        if (typeof actions?.onSettled === 'function') actions.onSettled();
      }
    },
    [requestFn, reset, actions, isLoading]
  );

  return {
    isLoading,
    isError,
    error,
    isSuccess,
    data,
    call,
    reset,
  };
};
