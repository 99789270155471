import { Project, ProjectListItem, ProjectMetadata } from '../types';
import {
  ProjectListResponseApiItem,
  ProjectMetadataResponseApi,
  ProjectResponseApi,
} from './types';

export class SharedApiMapper {
  static project = (response: ProjectResponseApi): Project => {
    const {
      profile_photo_url,
      cover_photo_url,
      twitter_url,
      discord_url,
      opensea_url,
      contract_address,
      contract_created_at,
      owner_address,
      is_verified,
      external_url,
      ...rest
    } = response;

    return {
      photoUrl: profile_photo_url,
      coverPhotoUrl: cover_photo_url,
      twitterUrl: twitter_url,
      openseaUrl: opensea_url,
      discordUrl: discord_url,
      contractCreatedAt: contract_created_at,
      ownerAddress: owner_address,
      isVerified: is_verified,
      contractAddress: contract_address,
      externalUrl: external_url,
      ...rest,
    };
  };

  static projectMetadata = (response: ProjectMetadataResponseApi): ProjectMetadata => {
    const {
      title,
      contract_address,
      description,
      external_url,
      twitter_url,
      discord_url,
      opensea_url,
      profile_photo_url,
    } = response;

    return {
      title,
      contractAddress: contract_address.toLowerCase(),
      description,
      externalUrl: external_url,
      twitterUrl: twitter_url,
      discordUrl: discord_url,
      openseaUrl: opensea_url,
      photoUrl: profile_photo_url,
    };
  };

  static projects = (response: ProjectListResponseApiItem[]): ProjectListItem[] => {
    return response.map(({ contract_address, profile_photo_url, ...rest }) => ({
      contractAddress: contract_address,
      photoUrl: profile_photo_url,
      ...rest,
    }));
  };
}
