import { useMutation } from 'react-query';
import { ProjectMilestonesApi } from '../../../api/ProjectApi/ProjectMilestonesApi';
import { useInvalidateProjectMilestones } from './useProjectMilestones';

export const useProjectMilestoneCreate = () => {
  const invalidateMilestones = useInvalidateProjectMilestones();
  return useMutation(ProjectMilestonesApi.postMilestone, {
    onSuccess: (_, input) => {
      invalidateMilestones(input.projectUid);
    },
  });
};
