import React from 'react';

import { RootModalComponent, RootModalComponentProps } from '../RootModal/RootModal';

import Styled from './BaseModal.styles';

export interface BaseModalProps extends RootModalComponentProps {
  title?: string;
  showX?: boolean;
  containerProps?: {
    style?: React.CSSProperties;
  };
}

export const BaseModal: React.FC<BaseModalProps> = ({
  children,
  title,
  showX = true,
  containerProps,
  ...otherProps
}) => {
  const { onClose } = otherProps;

  const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    typeof onClose === 'function' && onClose();
  };

  return (
    <RootModalComponent {...otherProps}>
      <Styled.Container style={containerProps?.style}>
        <Styled.Header>
          {title && <Styled.Title>{title}</Styled.Title>}
          {showX && (
            <Styled.CloseIconWrapper onClick={handleClose}>
              <Styled.CloseIcon />
            </Styled.CloseIconWrapper>
          )}
        </Styled.Header>
        <Styled.Content>{children} </Styled.Content>
      </Styled.Container>
    </RootModalComponent>
  );
};
