import styled from 'styled-components';

export default function DocumentSvg() {
  return (
    <Svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.95873 0H19.6657L28.729 9.45593V27.8372C28.729 30.1364 26.8655 32 24.5662 32H8.95873C6.65945 32 4.7959 30.1364 4.7959 27.8372V4.16283C4.7959 1.86359 6.65945 0 8.95873 0Z'
        fill='#0065FC'
      />
      <path
        opacity='0.302'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.8896 0V9.37826H27.966L18.8896 0Z'
        fill='white'
      />
      <path
        d='M15.4681 23.6758C15.4949 23.6974 15.5263 23.7081 15.5555 23.7243C15.5798 23.738 15.6013 23.7542 15.6275 23.7643C15.6966 23.7911 15.7686 23.8075 15.8418 23.8075C15.915 23.8075 15.987 23.791 16.0563 23.7642C16.0827 23.754 16.1042 23.7377 16.1286 23.7239C16.1582 23.7074 16.19 23.6965 16.2171 23.6746L19.1913 21.2677C19.4478 21.0601 19.4874 20.6844 19.2799 20.4282C19.073 20.1726 18.6964 20.1318 18.4405 20.3397L16.4387 21.9597V16.5969C16.4388 16.2673 16.1717 16 15.8418 16C15.5119 16 15.2449 16.2673 15.2449 16.5969V21.9651L13.2204 20.3385C12.9633 20.1318 12.5879 20.1732 12.3816 20.4297C12.1752 20.6868 12.216 21.0624 12.4731 21.2688L15.4681 23.6758Z'
        fill='white'
      />
      <path
        d='M21.2024 22.5938C20.8724 22.5938 20.6055 22.861 20.6055 23.1907V24.4682C20.6055 25.0911 20.0855 25.5979 19.4466 25.5979H12.1984C11.5595 25.5979 11.0395 25.0911 11.0395 24.4682V23.1907C11.0395 22.861 10.7725 22.5938 10.4426 22.5938C10.1127 22.5938 9.8457 22.861 9.8457 23.1907V24.4682C9.84563 25.7495 10.9014 26.7918 12.1984 26.7918H19.4466C20.7436 26.7918 21.7994 25.7495 21.7994 24.4682V23.1907C21.7994 22.861 21.5323 22.5938 21.2024 22.5938Z'
        fill='white'
      />
    </Svg>
  );
}

const Svg = styled('svg')(({ theme }) => ({
  cursor: 'pointer',
  transition: 'all .3s',
  [theme.betweenDM]: {
    height: theme.pxToVw(32),
    width: 'auto',
  },
}));
