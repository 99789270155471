import { useFormContext, UseFormSetValue } from 'react-hook-form';
import { useProjectByParamId } from '../../../../../../../react-query/project/useProject';
import { useMilestoneGoalEdit } from '../../../../../../../react-query/user/project/useMilestoneGoalEdit';
import { Milestone, MilestoneGoal } from '../../../../../../../types';

export const useFulfilledChange = (
  goal: MilestoneGoal,
  isEditing: boolean,
  setGoalValue?: UseFormSetValue<MilestoneGoal>
) => {
  const { data: project } = useProjectByParamId();
  const { watch, setValue } = useFormContext<Milestone>();
  const { mutateAsync: editGoal, isLoading: isGoalEditing } = useMilestoneGoalEdit();

  const milestoneUid = watch('uid');
  const projectUid = project?.uid;
  const goalUid = goal.uid;

  const onIsFulfilledChange = async () => {
    const isFulfilled = !goal.isFulfilled;

    if (typeof setGoalValue === 'function') {
      setGoalValue('isFulfilled', isFulfilled, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }

    if (!projectUid || isGoalEditing) return;

    setValue(
      'goals',
      watch('goals').map(g => (g.uid === goalUid ? { ...goal, isFulfilled } : g)),
      {
        shouldDirty: !milestoneUid,
      }
    );

    if (milestoneUid && !isEditing) {
      editGoal({
        projectUid,
        milestoneUid,
        goalUid: goalUid!,
        payload: { ...goal, isFulfilled },
      });
    }
  };

  return { onIsFulfilledChange, isLoading: isGoalEditing };
};
