import { useEffect } from 'react';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { useUser } from '../../react-query/user/useUser';

import { PatchProfilePayload } from '../../api/UserApi/UserApi';
import { joiLib } from '../../joi';

const schema = Joi.object({
  email: joiLib.email.allow(null, ''),
  phoneNumber: joiLib.phone.allow('', null),
});

export const useEditAccountForm = () => {
  const { data: user } = useUser();

  const form = useForm<PatchProfilePayload>({
    defaultValues: {
      email: user?.email || '',
      phoneNumber: user?.phoneNumber || '',
    },
    resolver: joiResolver(schema),
  });

  const { reset } = form;

  useEffect(() => {
    if (user) {
      reset({
        email: user.email,
        phoneNumber: user.phoneNumber,
      });
    }
  }, [user, reset]);

  return form;
};
