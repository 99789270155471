import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useProjectByParamId } from '../../../../../../react-query/project/useProject';
import { useProjectFrequencyOfTransactionsByParamId } from '../../../../../../react-query/project/useProjectFrequencyOfTransactions';

import { Address } from '../../../../../../components/common/Address/Address';
import { BlurredPlaceholder, ChartCardBase, ChartTitle } from '../shared';
import { ChartFilters } from '../ChartFilters';
import { Container } from '../../../../../../components/layouts/Container';
import { NoItemsText } from '../../../../../profile/components/ProfileData/components';
import { FrequencyOfTransactionsChart } from './FrequencyOfTransactionsChart';

import { getDateDaysAgo, toValidDate } from '../../../../../../utils/date-utils';

import mobile_img from './placeholder-mobile.png';
import desktop_img from './placeholder-desktop.png';

export const FrequencyOfTransactions: React.FC = () => {
  const { t } = useTranslation();
  const [interval, setInterval] = useState('all');

  const { project: projectParam } = useParams();

  const { data: project } = useProjectByParamId();

  const {
    data,
    isLoading,
    isError: isAnalyticsError,
  } = useProjectFrequencyOfTransactionsByParamId({
    interval,
  });

  const filteredData = useMemo(() => {
    if (!data) return [];
    if (interval === 'all') return data;

    const yearDays = 365;
    const monthDays = 30;
    const weekDays = 7;

    const daysOffset =
      interval === 'year' ? yearDays : interval === 'month' ? monthDays : weekDays;

    const dateDaysAgo = getDateDaysAgo(new Date(), daysOffset);

    return data.filter(
      item => toValidDate(item.executionDate).getTime() >= dateDaysAgo.getTime()
    );
  }, [data, interval]);

  const renderContent = () => {
    if (isLoading || !data)
      return (
        <Container>
          <BlurredPlaceholder
            mobileImg={mobile_img}
            desktopImg={desktop_img}
            centered
            isLoading={isLoading}
          />
        </Container>
      );

    if (!data.length || isAnalyticsError) {
      return (
        <Container>
          <NoItemsText isError={isAnalyticsError}>
            {isAnalyticsError
              ? t('errors.default')
              : t('projectPage.transactionsNotAvailable')}
          </NoItemsText>
        </Container>
      );
    }

    return (
      <ChartCardBase>
        <ChartFilters interval={interval} onChange={setInterval} />
        <FrequencyOfTransactionsChart data={filteredData} />
      </ChartCardBase>
    );
  };

  return (
    <Wrapper>
      <Container>
        <ChartTitle style={{ marginBottom: 16 }}>
          {t('projectPage.frequencyOfTransactions.title')}
        </ChartTitle>
        <Address
          address={projectParam?.toLowerCase() || project?.contractAddress || ''}
          where='list'
          isContract
        />
      </Container>
      {renderContent()}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  [theme.betweenDM]: {
    gap: theme.pxToVw(24),
  },
}));
