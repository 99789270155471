import React, { useState } from 'react';
import styled, { CSSObject } from 'styled-components';
import { Theme } from '../../../../config/theme';
import { FormFieldWrapper } from '../FormFieldWrapper/FormFieldWrapper';
import { InputBase, InputBaseProps, InputSize } from './InputBase';

interface Props
  extends React.InputHTMLAttributes<HTMLInputElement>,
    Omit<InputBaseProps, 'error' | 'size'> {
  iconLeft?: JSX.Element;
  onClear?: () => void;
  label?: string;
  error?: boolean | string | Error;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  width?: string | number;
  size?: InputSize;
  iconSize?: number;
}

export const InputWithIcons = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      iconLeft,
      onClear,
      label,
      error,
      labelProps,
      onFocus,
      onBlur,
      onChange,
      iconSize = 16,
      ...inputProps
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false);

    return (
      <FormFieldWrapper error={error}>
        <Wrapper>
          {iconLeft && (
            <IconWrapper iconLeft={iconLeft} iconSize={iconSize}>
              {iconLeft}
            </IconWrapper>
          )}
          <Input
            {...inputProps}
            ref={ref}
            error={!!error}
            iconLeft={iconLeft}
            removeButton={!!onClear}
            onChange={onChange}
            onFocus={e => {
              setFocused(true);
              if (onFocus) onFocus(e);
            }}
            onBlur={e => {
              setFocused(false);
              if (onBlur) onBlur(e);
            }}
          />
          {!!onClear && (
            <IconWrapper removeButton={!!onClear}>
              <DeleteIcon
                $focused={focused}
                width='13'
                height='16'
                viewBox='0 0 13 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                onClick={() => {
                  onClear();
                  setFocused(false);
                }}
                hasValue={!!inputProps.hasValue}
              >
                <path
                  d='M11.75 2H9V1.5C9 0.672906 8.32709 0 7.5 0H5.5C4.67291 0 4 0.672906 4 1.5V2H1.25C0.56075 2 0 2.56075 0 3.25V5C0 5.27612 0.223875 5.5 0.5 5.5H0.77325L1.20522 14.5713C1.24338 15.3725 1.9015 16 2.7035 16H10.2965C11.0985 16 11.7567 15.3725 11.7948 14.5713L12.2268 5.5H12.5C12.7761 5.5 13 5.27612 13 5V3.25C13 2.56075 12.4392 2 11.75 2ZM5 1.5C5 1.22431 5.22431 1 5.5 1H7.5C7.77569 1 8 1.22431 8 1.5V2H5V1.5ZM1 3.25C1 3.11216 1.11216 3 1.25 3H11.75C11.8878 3 12 3.11216 12 3.25V4.5C11.8459 4.5 1.63853 4.5 1 4.5V3.25ZM10.7959 14.5238C10.7832 14.7908 10.5638 15 10.2965 15H2.7035C2.43616 15 2.21678 14.7908 2.20409 14.5238L1.77437 5.5H11.2256L10.7959 14.5238Z'
                  fill='white'
                />
                <path
                  d='M6.5 14C6.77613 14 7 13.7761 7 13.5V7C7 6.72387 6.77613 6.5 6.5 6.5C6.22387 6.5 6 6.72387 6 7V13.5C6 13.7761 6.22384 14 6.5 14Z'
                  fill='white'
                />
                <path
                  d='M9 14C9.27613 14 9.5 13.7761 9.5 13.5V7C9.5 6.72387 9.27613 6.5 9 6.5C8.72387 6.5 8.5 6.72387 8.5 7V13.5C8.5 13.7761 8.72384 14 9 14Z'
                  fill='white'
                />
                <path
                  d='M4 14C4.27613 14 4.5 13.7761 4.5 13.5V7C4.5 6.72387 4.27613 6.5 4 6.5C3.72387 6.5 3.5 6.72387 3.5 7V13.5C3.5 13.7761 3.72384 14 4 14Z'
                  fill='white'
                />
              </DeleteIcon>
            </IconWrapper>
          )}
        </Wrapper>
      </FormFieldWrapper>
    );
  }
);

const IconWrapper = styled('div')<Props & { removeButton?: boolean }>(
  ({ theme, iconLeft, removeButton, iconSize = 16 }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 52,
    height: 25,
    borderRight: iconLeft ? '1px solid #fff' : 'none',
    borderLeft: removeButton ? '1px solid #fff' : 'none',
    position: 'absolute',
    left: iconLeft ? 0 : 'auto',
    right: removeButton ? 0 : 'auto',
    top: '50%',
    transform: 'translateY(-50%)',
    '& svg': {
      height: 24,
      width: 24,
      position: 'absolute',
      left: 14,
    },
    [theme.betweenDM]: {
      width: theme.pxToVw(52),
      height: theme.pxToVw(25),
      '& svg': {
        height: theme.pxToVw(24),
        width: theme.pxToVw(24),
        left: theme.pxToVw(14),
      },
    },
  })
);

const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  flex: 1,
}));

const getStyle = (theme: Theme, iconLeft: boolean, iconRight: boolean): CSSObject => {
  const style: CSSObject = {};
  const responsiveSm: CSSObject = {};
  const responsiveD: CSSObject = {};

  if (iconLeft) {
    style.paddingLeft = 64;
    responsiveSm.paddingLeft = 66;
    responsiveD.paddingLeft = theme.pxToVw(66);
  }

  if (iconRight) {
    style.paddingRight = 66;
    responsiveSm.paddingRight = 66;
    responsiveD.paddingRight = theme.pxToVw(66);
  }

  return {
    ...style,
    [theme.min(theme.breakpoints.sm)]: responsiveSm,
    [theme.betweenDM]: responsiveD,
  };
};

const Input = styled(InputBase)<Props & { removeButton?: boolean }>(
  ({ theme, iconLeft, removeButton, hasValue }) => ({
    width: '100%',
    ...getStyle(theme, !!iconLeft, !!removeButton),
  })
);

const DeleteIcon = styled('svg')<{ $focused?: boolean; hasValue?: boolean }>(
  ({ theme, $focused, hasValue }) => ({
    opacity: hasValue ? 1 : 0.4,
    transition: 'all .3s',
    cursor: 'pointer',
    height: '20px !important',
    width: '16.25px !important',
    pointerEvents: hasValue ? 'auto' : 'none',
    '&:hover': {
      opacity: 0.8,
      '& path': {
        opacity: 0.8,
      },
    },
    '& path': {
      transition: 'all .3s',
      fill: hasValue && !$focused ? theme.colors.primary.main : '#fff',
    },
    [theme.betweenDM]: {
      height: `${theme.pxToVw(20)} !important`,
      width: `${theme.pxToVw(16.25)} !important`,
    },
  })
);
