import React from 'react';
import { useTranslation } from 'react-i18next';

import { useReputationWithAddress } from '../../../../../providers/AddressProvider/useReputationWithAddress';

import { AnalysisResultPrimaryCardBase } from './AnalysisResultPrimaryCardBase';

export const AnalysisResultTransactionsCard: React.FC = () => {
  const { t } = useTranslation('analysisResult');
  const { data } = useReputationWithAddress();

  const { numberOfTransactions } = data || {};

  const value =
    typeof numberOfTransactions === 'number' && numberOfTransactions > -1
      ? `${numberOfTransactions.toLocaleString()}${
          numberOfTransactions > 1999 ? '+' : ''
        }`
      : '';

  return (
    <AnalysisResultPrimaryCardBase label={t('totalTransactions.title')} value={value} />
  );
};
