export class UrlService {
  static getSearchParam(param: string, search?: string) {
    let theUrl = window.location.href;
    if (search) {
      theUrl = 'https://dummy.com' + search;
    }
    const url = new URL(theUrl);
    return url.searchParams.get(param);
  }

  static isUrl(url: string) {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // validate fragment locator
    return !!urlPattern.test(url);
  }

  static toHttpsUrl(url: string) {
    if (url.startsWith('www')) {
      return String(url).replace('www.', 'https://');
    }

    const isUrl = UrlService.isUrl(url);

    if (!isUrl) {
      console.error('Invalid Url Provided');
      return url;
    }

    if (url.startsWith('http://')) {
      return String(url).replace('http://', 'https://');
    }

    if (!url.startsWith('https://')) {
      return `https://${url}`;
    }

    return url;
  }
}
