import styled from 'styled-components';
import { BaseCard } from '../../cards/BaseCard';

interface Props {
  size?: number;
  sizeMobile?: number | string;
  url: string;
  selected?: boolean;
  position: 'top' | 'center' | 'bottom';
}

const Wrapper = styled(BaseCard)<Props>(
  ({ theme, size = 196, sizeMobile = 120, url, selected, position }) => ({
    width: size,
    height: size,
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    content: '" "',
    transition: 'all .3s',
    overflow: 'hidden',
    cursor: 'pointer',
    position: 'relative',
    boxShadow: selected
      ? '0px 0px 4px 3px rgba(53, 133, 254, 0.69)'
      : '0px 0px 3px 2px rgba(53, 133, 254, 0.39)',
    [theme.max(theme.breakpoints.xs)]: {
      width: sizeMobile,
      height: sizeMobile,
    },
    '&:hover': {
      boxShadow: '0px 0px 4px 3px rgba(53, 133, 254, 0.69)',
    },
    '& div': {
      display: 'block',
      content: '" "',
      backgroundImage: `url('${url}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: position,
      backgroundSize: 'cover',
      width: '100%',
      height: '100%',
    },
    [theme.betweenDM]: {
      width: theme.pxToVw(size),
      height: theme.pxToVw(size),
    },
  })
);

const Styled = { Wrapper };

export default Styled;
