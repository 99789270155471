import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useFollowersBadges } from './useFollowersBadges';

import { Follower } from '../../../types';

import { ProfileImage } from '../ProfileImage/ProfileImage';
import { LoadingBackground } from '../../ui/loaders/LoadingBackground';
import { ActionText, ActionTextVariant } from '../../ui/text/ActionText';
import { ReactComponent as HeartIcon } from './HeartIcon.svg';
import { Text } from '../../ui/text/Text';

interface Props {
  followers: Follower[] | undefined;
  totalCount: number | undefined;
  isLoading: boolean;
  showTotalCountAs: 'badge' | 'text';
  onClick: () => void;
}

export const ProfileBadges: React.FC<Props> = ({
  followers,
  totalCount,
  isLoading,
  showTotalCountAs,
  onClick,
}) => {
  const { t } = useTranslation();

  const { displayTotalCount, displayUsers } = useFollowersBadges(
    followers,
    totalCount,
    isLoading
  );

  return (
    <>
      <TrustedByWrapper>
        {(!displayUsers || displayUsers.length > 0) && (
          <HeartIcon style={{ marginRight: 8 }} />
        )}
        {showTotalCountAs === 'text' && (
          <TextEl as='span'>{t('projectPage.trustedBy')}</TextEl>
        )}
        {!!displayUsers?.length && (
          <Profiles>
            {displayUsers.map((user, i) => (
              <ImageWrapper key={i} showTotalCountAs={showTotalCountAs}>
                {isLoading ? (
                  <LoadingBackground>L</LoadingBackground>
                ) : (
                  <ProfileImage
                    photoUrl={user.photoUrl}
                    onClick={onClick}
                    isLoading={isLoading}
                  />
                )}
              </ImageWrapper>
            ))}
            {typeof displayTotalCount === 'number' && displayTotalCount > 0 && (
              <ImageWrapper showTotalCountAs={showTotalCountAs}>
                +{displayTotalCount}
              </ImageWrapper>
            )}
          </Profiles>
        )}
        {isLoading && showTotalCountAs === 'text' ? (
          <LoaderBg>
            <TextEl as='span'>L</TextEl>
          </LoaderBg>
        ) : (
          !!displayTotalCount &&
          showTotalCountAs === 'text' && (
            <TextEl as='span'>
              {t('projectPage.and')}{' '}
              <Action onClick={onClick} variant={ActionTextVariant.Inverted}>
                {displayTotalCount} {t('projectPage.others')}.
              </Action>
            </TextEl>
          )
        )}
      </TrustedByWrapper>
    </>
  );
};

const TrustedByWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

const Profiles = styled('div')(({ theme }) => ({
  margin: '0 8px',
  display: 'flex',
  cursor: 'pointer',
  [theme.min(theme.breakpoints.desktop)]: {
    margin: `0 ${theme.pxToVw(10)} 0 ${theme.pxToVw(9)}`,
  },
  [theme.min(theme.breakpoints.max)]: {
    margin: '0 10px 0 9px',
  },
}));

const ImageWrapper = styled('div')<{ showTotalCountAs: Props['showTotalCountAs'] }>(
  ({ theme, showTotalCountAs }) => ({
    width: showTotalCountAs === 'text' ? 36 : 30,
    height: showTotalCountAs === 'text' ? 36 : 30,
    borderRadius: '50%',
    position: 'relative',
    cursor: 'pointer',
    padding: 2,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.primary.main,
    fontSize: 10,
    fontWeight: 700,
    '&:nth-child(2)': {
      marginLeft: -14,
      zIndex: theme.zIndex.sm,
    },
    '&:nth-child(3)': {
      marginLeft: -14,
      zIndex: theme.zIndex.md,
      boxShadow: showTotalCountAs === 'text' ? '0px 0px 5px 4px #000000' : 'none',
    },
    '&:nth-child(4)': {
      marginLeft: -14,
      zIndex: theme.zIndex.lg,
    },
    [theme.min(theme.breakpoints.desktop)]: {
      width: theme.pxToVw(40),
      height: theme.pxToVw(40),
      fontSize: theme.pxToVw(12),
      '&:nth-child(2)': {
        marginLeft: theme.pxToVw(-14),
      },
      '&:nth-child(3)': {
        marginLeft: theme.pxToVw(-14),
      },
      '&:nth-child(4)': {
        marginLeft: theme.pxToVw(-14),
      },
    },
    [theme.min(theme.breakpoints.max)]: {
      width: 40,
      height: 40,
      fontSize: 12,
      '&:nth-child(2)': {
        marginLeft: -14,
      },
      '&:nth-child(3)': {
        marginLeft: -14,
      },
      '&:nth-child(4)': {
        marginLeft: -14,
      },
    },
    '& > *': {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
    },
  })
);

const Action = styled(ActionText)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 14,
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(20),
  },
  [theme.min(theme.breakpoints.ul)]: {
    fontSize: 20,
  },
}));

const TextEl = styled(Text.MD)(({ theme }) => ({
  fontWeight: 700,
}));

const LoaderBg = styled(LoadingBackground)(({ theme }) => ({
  width: 136,
  [theme.betweenDM]: {
    width: theme.pxToVw(136),
  },
}));
