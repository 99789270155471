import React from 'react';
import styled from 'styled-components';
import {
  ClipboardButton,
  ClipboardButtonBlue,
} from '../../../../../components/ui/buttons/ClipboardButton/ClipboardButton';
import { envs } from '../../../../../config/envs';

import { useProfileByParamId } from '../../../../../react-query/profile/useProfile';
import { isValidEthAddress, shortenEthAddress } from '../../../../../utils/address-utils';

import { ClaimProfileButton } from './ClaimProfileButton';

export const ProfileInfoUsername: React.FC = () => {
  const { data } = useProfileByParamId();

  if (!data) return null;

  return (
    <Wrapper>
      <h4>
        {isValidEthAddress(data.username)
          ? shortenEthAddress(data.username, {
              startChars: 3,
              placeholderChar: '.',
              placeholderCount: 3,
            })
          : data?.username}
        {isValidEthAddress(data.username) ? (
          <Clipboard value={data.username} />
        ) : (
          <ClipBlue value={`${envs.blockemWebsiteUrl}/u/${data.username}`} />
        )}
      </h4>
      <ClaimProfileButton />
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  marginBottom: 20,
  '& h4': {
    fontSize: 18,
    fontWeight: 900,
    lineHeight: 1.2,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: 'max-content',
  },
  [theme.min(theme.breakpoints.xs)]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: 20,
    },
  },
  [theme.min(theme.breakpoints.desktop)]: {
    '& h4': {
      fontSize: theme.pxToVw(38),
    },
    gap: theme.pxToVw(12),
    marginBottom: theme.pxToVw(20),
  },
  [theme.min(theme.breakpoints.max)]: {
    '& h4': {
      fontSize: 38,
    },
    gap: 12,
    marginBottom: 20,
  },
}));

const Clipboard = styled(ClipboardButton)(({ theme }) => ({
  position: 'absolute',
  right: -40,
  '& svg': {
    height: 16,
    width: 'auto',
    '& path': {
      fill: '#fff',
    },
  },
  [theme.min(theme.breakpoints.xs)]: {
    right: -45,
    '& svg': {
      height: 18,
    },
  },
  [theme.min(theme.breakpoints.md)]: {
    right: -50,
    '& svg': {
      height: 21,
    },
  },
  [theme.betweenDM]: {
    right: theme.pxToVw(-50),
    '& svg': {
      height: theme.pxToVw(21),
    },
  },
}));

const ClipBlue = styled(ClipboardButtonBlue)(({ theme }) => ({
  marginLeft: 8,
  [theme.betweenDM]: {
    marginLeft: theme.pxToVw(8),
  },
}));
