import React from 'react';
import styled, { CSSObject } from 'styled-components';

import { useProfileUtils } from '../../hooks/useProfileUtils';

import { AssetDetailBalance } from './DetailCard/AssetDetailBalance';
import { AssetDetailErcTokens } from './DetailCard/AssetDetailErcTokens';
import { AssetDetailNfts } from './DetailCard/ProfileDetailNfts';

export const ProfileAssetsDetails: React.FC = () => {
  return (
    <Wrapper>
      <AssetDetailBalance />
      <AssetDetailNfts />
      <AssetDetailErcTokens />
    </Wrapper>
  );
};

const getOwnerBasedStyles = (isOwner: boolean): CSSObject => {
  if (!isOwner) {
    return {
      width: '65%',
    };
  } else {
    return {
      with: 'auto',
      flexDirection: 'column',
      flex: 1,
    };
  }
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  [theme.min(theme.breakpoints.sm)]: {
    flexDirection: 'row',
    width: '100%',
    gap: 20,
  },
  [theme.min(1050)]: {
    ...getOwnerBasedStyles(!useProfileUtils().isClaimedAddress),
  },
  [theme.min(theme.breakpoints.desktop)]: {
    flexDirection: 'row',
    width: '100%',
    flex: 0,
    gap: theme.pxToVw(20),
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 20,
  },
}));
