import React from 'react';
import { useTranslation } from 'react-i18next';

import { useProfileFollowers } from '../../../../../react-query/user/useFollowers';

import { BaseModal } from '../../../../../components/ui/modals/BaseModal/BaseModal';
import { FollowerItem } from './FollowerItem';
import { LogoSpinner } from '../../../../../components/ui/loaders/LogoSpinner';

import { routes } from '../../../../../router/routes';
import Styled from './components.styles';

interface Props {
  onClose: () => void;
}

export const FollowersModal: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation('common');
  const { data: followers } = useProfileFollowers();

  return (
    <BaseModal title={t('followers')} onClose={onClose} isOpen>
      <Styled.Content>
        {!followers && (
          <Styled.SpinnerWrapper>
            <LogoSpinner size={68} />
          </Styled.SpinnerWrapper>
        )}
        {followers?.map(follower => (
          <FollowerItem
            key={follower.userUid}
            onClick={addressOrUsername => {
              onClose();
              window.open(routes.profile.byId(addressOrUsername), '_blank');
            }}
            {...follower}
            type='follower'
          />
        ))}
      </Styled.Content>
    </BaseModal>
  );
};
