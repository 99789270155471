import styled from 'styled-components';

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  width: '80vw',
  maxWidth: 670,
  minHeight: 300,
  maxHeight: '80vh',
  overflow: 'auto',
  [theme.min(theme.breakpoints.sm)]: {
    minHeight: 460,
    maxHeight: '60vh',
  },
  [theme.betweenDM]: {
    maxWidth: theme.pxToVw(670),
    minHeight: theme.pxToVw(460),
  },
}));

const SpinnerWrapper = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
});

const Styled = { Content, SpinnerWrapper };

export default Styled;
