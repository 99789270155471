import { Trust } from '../types';

const text = {
  primary: '#FFFFFF',
  secondary: '#FFFFFF',
  whiteTransparent70: 'rgba(255, 255, 255, 0.7)',
  whiteTransparent50: 'rgba(255, 255, 255, 0.5)',
};

const colors = {
  primary: {
    main: '#0065FC',
  },
  status: {
    error: '#F24747',
    success: '#5cb85c',
  },
  text,
};

const trustColors = {
  [Trust.High]: '#71A55F',
  [Trust.Medium]: '#E5BC4F',
  [Trust.Low]: '#FF8C4B',
  [Trust.Untrusted]: '#FC5757',
  [Trust.NewWallet]: '#0065FC',
};

export const getTheme = () => ({
  colors: { ...colors, trust: trustColors },
  backgrounds: {
    app: `#000000`,
  },
  zIndex: {
    xs: 2,
    sm: 3,
    md: 4,
    lg: 5,
    xl: 6,
    ul: 7,
  },
  fontFamily: "'Roboto', sans-serif",
  keyframes: {
    spin: {
      '@keyframes spin': {
        '0%': {
          transform: 'rotate(0deg)',
        },
        '100%': {
          transform: 'rotate(360deg)',
        },
      },
    },
  },
  breakpoints: {
    ul: 1536,
    xl: 1200,
    lg: 1024,
    md: 992,
    sm: 768,
    xs: 480,
    desktop: 1240,
    max: 1920,
  },
  min: (breakpoint: number, vertical = false) =>
    `@media (min-${vertical ? 'height' : 'width'}: calc(${breakpoint}px + 0.02px))`,
  minDesktop: `@media (min-width: calc(1240px + 0.02px))`,
  minMax: `@media (min-width: calc(1920px + 0.02px))`,
  betweenDM: `@media (max-width: 1920px) and (min-width: calc(1240px + 0.02px))`,
  max: (breakpoint: number, vertical = false) =>
    `@media all and (max-${vertical ? 'height' : 'width'}: ${breakpoint}px)`,
  between: (breakpointMin: number, breakpointMax: number, vertical = false) =>
    `@media (max-${vertical ? 'height' : 'width'}: ${breakpointMax}px) and (min-${
      vertical ? 'height' : 'width'
    }: calc(${breakpointMin}px + 0.02px))`,
  pxToVw: (px: number, relToWidth = 1920) => {
    if (window.innerWidth >= relToWidth) return px;
    return `${(100 / relToWidth) * px}vw`;
  },
});

export type Theme = ReturnType<typeof getTheme>;
