import React from 'react';
import styled from 'styled-components';
import { DropdownMenuBase, DropdownMenuBaseProps } from '../DropdownMenuBase';
import { ReactComponent as DotsSvg } from './Dots.svg';

type Props = Omit<DropdownMenuBaseProps, 'Trigger'> & { noCircle?: boolean };

export const DotsDropdownMenu = React.forwardRef<HTMLElement, Props>(
  ({ noCircle, ...props }, ref) => {
    return (
      <DropdownMenuBase
        Trigger={<Trigger noCircle={noCircle} isOpen={props.isOpen} />}
        ref={ref}
        align='start'
        {...props}
      />
    );
  }
);

interface TriggerProps {
  noCircle?: boolean;
  isOpen?: boolean;
}

const Trigger: React.FC<TriggerProps> = props => (
  <TriggerWrapper {...props}>
    <DotsSvg className='dropdown-dots-svg' />
  </TriggerWrapper>
);

const TriggerWrapper = styled('div')<TriggerProps>(({ theme, noCircle, isOpen }) =>
  noCircle
    ? {
        '& svg': {
          height: theme.pxToVw(5),
          width: 'auto',
        },
        '& path': {
          fill: isOpen ? theme.colors.primary.main : '#fff',
        },
      }
    : {
        backgroundColor: theme.colors.primary.main,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        width: 40,
        [theme.betweenDM]: {
          height: theme.pxToVw(40),
          width: theme.pxToVw(40),
          '& svg': {
            height: theme.pxToVw(5),
            width: 'auto',
          },
        },
      }
);
