import { axiosInstance } from '../../config/axios';
import {
  Notification,
  NotificationType,
  EndorseNotification,
  NewTxNotification,
} from '../../types';
import { shortenEthAddress } from '../../utils/address-utils';

import { apiRoutes, IRequestParams } from '../routes';

interface NotificationBase {
  id: number;
  user_uid: string;
  created_at: number;
  is_read: boolean;
}

interface EndorseNotificationContent {
  endorsing_user_uid: string;
  endorsing_username: string;
  endorsing_photo_url: string | null;
  endorsed_project_uid: string;
  endorsed_project_address: string;
  endorsed_title: string;
  endorsed_photo_url: string | null;
}

interface EndorseNotificationApi extends NotificationBase {
  type: NotificationType.Endorse;
  content: EndorseNotificationContent;
}

interface NewTxNotificationContent {
  user_uid: string;
  username: string;
  photo_url: string | null;
  address: string;
}

interface NewTxNotificationApi extends NotificationBase {
  type: NotificationType.NewTx;
  content: NewTxNotificationContent;
}

type NotificationApiItemResponse = EndorseNotificationApi | NewTxNotificationApi;

const notificationContentMapper = (
  notification: NotificationApiItemResponse
): Notification['content'] => {
  switch (notification.type) {
    case NotificationType.Endorse:
      const {
        endorsing_user_uid,
        endorsing_username,
        endorsing_photo_url,
        endorsed_project_uid,
        endorsed_title,
        endorsed_photo_url,
        endorsed_project_address,
      } = notification.content;

      return {
        type: NotificationType.Endorse,
        userUid: endorsing_user_uid,
        username: endorsing_username,
        userPhotoUrl: endorsing_photo_url,
        projectUid: endorsed_project_uid,
        projectTitle: endorsed_title,
        projectPhotoUrl: endorsed_photo_url,
        projectAddress: endorsed_project_address,
      };
    case NotificationType.NewTx:
      const { user_uid, username, photo_url, address } = notification.content;
      return {
        type: NotificationType.NewTx,
        userUid: user_uid,
        username: username || shortenEthAddress(address),
        userPhotoUrl: photo_url,
        address,
      };
  }
};

interface NotificationsCountReponse {
  notifications_total_count: number;
  notifications_unread_count: number;
}

export interface NotificationsCount {
  totalCount: number;
  undreadCount: number;
}

class NotificationApiConfig {
  private readonly apiMapper = {
    notification: (apiNotification: NotificationApiItemResponse) => {
      const { id, user_uid, created_at, is_read, type } = apiNotification;

      return {
        id,
        userUid: user_uid,
        createdAt: created_at,
        isRead: is_read,
        type,
        content:
          type === NotificationType.Endorse
            ? (notificationContentMapper(
                apiNotification
              ) as EndorseNotification['content'])
            : (notificationContentMapper(
                apiNotification
              ) as NewTxNotification['content']),
      };
    },

    notificationCount: (response: NotificationsCountReponse): NotificationsCount => {
      const { notifications_total_count, notifications_unread_count } = response;

      return {
        totalCount: notifications_total_count,
        undreadCount: notifications_unread_count,
      };
    },
  };

  getNotifications = async (options: IRequestParams) => {
    const { data } = await axiosInstance.get<NotificationApiItemResponse[]>(
      apiRoutes.user.notifications.root(options)
    );
    return (data || []).map(this.apiMapper.notification);
  };

  getNotificationsCount = async () => {
    const { data } = await axiosInstance.get<NotificationsCountReponse>(
      apiRoutes.user.notifications.count()
    );
    return this.apiMapper.notificationCount(data);
  };

  putEnabled = async (isEnabled: boolean) => {
    await axiosInstance.put(apiRoutes.user.notifications.root(), {
      notifications_enabled: isEnabled,
    });
  };

  markAllAsRead = async () => {
    await axiosInstance.post(apiRoutes.user.notifications.markAllAsRead());
  };

  patchRead = async (notificationId: number, isRead: boolean) => {
    await axiosInstance.patch(apiRoutes.user.notifications.markAsRead(notificationId), {
      read: isRead,
    });
  };
}

export const NotificationApi = new NotificationApiConfig();
