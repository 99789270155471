import React from 'react';
import styled from 'styled-components';

import { useProfileUtils } from '../../hooks/useProfileUtils';

import { UserApi } from '../../../../api/UserApi/UserApi';

import { FollowAllButton } from './FollowAllButton';
import { EditCoverPhotoButton } from '../../../../components/profile/EditCoverPhotoButton';

export const FollowAllAndEditCover: React.FC = () => {
  const { isOwner } = useProfileUtils();

  return (
    <ButtonWrapper>
      {isOwner ? (
        <EditCoverPhotoButton onChange={UserApi.setCoverPhoto} />
      ) : (
        <FollowAllButton />
      )}
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled('div')(({ theme }) => ({
  [theme.min(theme.breakpoints.sm)]: {
    position: 'absolute',
    right: 0,
    transform: 'translateY(-65px)',
  },
  [theme.betweenDM]: {
    transform: `translateY(${theme.pxToVw(-65)})`,
  },
}));
