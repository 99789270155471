import { useQuery, useQueryClient } from 'react-query';
import { ProjectMilestonesApi } from '../../../api/ProjectApi/ProjectMilestonesApi';
import { useProjectByParamId } from '../../project/useProject';
import { QueryKey } from '../../query-keys';

export const useProjectMilestone = (projectId: string | null | undefined) => {
  return useQuery(
    QueryKey.ProjectMilestones(projectId!),
    () => ProjectMilestonesApi.getMilestone(projectId!),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: !!projectId,
    }
  );
};

export const useProjectMilestonesByParamId = () => {
  const { data: project } = useProjectByParamId();

  return useProjectMilestone(project?.uid);
};

export const useInvalidateProjectMilestones = () => {
  const queryClient = useQueryClient();

  return (projId?: string) =>
    projId
      ? queryClient.invalidateQueries(QueryKey.ProjectMilestones(projId))
      : queryClient.invalidateQueries('ProjectMilestones');
};
