import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';

import { useUser } from '../useUser';

import { usePreviousNotificationsCount } from '../../../providers/NotificationsProvider';

import { NotificationApi } from '../../../api/UserApi/NotificationApi';

import { QueryKey } from '../../query-keys';

interface Props {
  page: number;
}

export const NOTIFICATIONS_OFFSET_PARAM_VALUE = 10;

// const getFetchOffset = (
//   notificationsCount: NotificationsCount | undefined,
//   page: number
// ) => {
//   const min = 10;
//   const { undreadCount } = notificationsCount || {};

//   if (page === 1 && typeof undreadCount === 'number' && undreadCount < min)
//     // return undreadCount + 3;
//     return min;

//   return min;
// };

export const useInfiniteNotifications = () => {
  const { data: user } = useUser();
  const { data: notificationsCount } = useNotificationsCount();

  const { data, ...rest } = useInfiniteQuery(
    QueryKey.Notifications(),
    ({ pageParam = 1 }) =>
      NotificationApi.getNotifications({
        page: pageParam,
        // @ts-ignore
        offset: NOTIFICATIONS_OFFSET_PARAM_VALUE,
        // offset: getFetchOffset(notificationsCount, pageParam),
      }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!user?.notificationsEnabled && !!notificationsCount?.totalCount,
      retry: false,
      getNextPageParam: (lastPage, allPages) => {
        const itemsFetched = allPages.flat().length;

        if (itemsFetched === notificationsCount?.totalCount) return undefined;

        return allPages.length + 1;
      },
    }
  );

  return {
    data,
    formattedData: data ? data?.pages.flat() : undefined,
    totalCount: notificationsCount?.totalCount,
    ...rest,
  };
};

export const useNotificationsWithPagination = (opts: Props) => {
  const { data: user } = useUser();
  const { data: notificationsCount } = useNotificationsCount();

  const { data, ...rest } = useQuery(
    QueryKey.Notifications(opts),
    () =>
      NotificationApi.getNotifications({
        page: opts.page,
        // @ts-ignore
        offset: NOTIFICATIONS_OFFSET_PARAM_VALUE,
        // offset: getFetchOffset(notificationsCount, pageParam),
      }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!user?.notificationsEnabled && !!notificationsCount?.totalCount,
      retry: false,
    }
  );

  return {
    data,
    totalCount: notificationsCount?.totalCount,
    ...rest,
  };
};

export const useNotificationsCount = () => {
  const queryClient = useQueryClient();
  const { data: user } = useUser();
  const { currentCounts, setCurrentCounts } = usePreviousNotificationsCount();

  return useQuery(QueryKey.NotificationsCount(), NotificationApi.getNotificationsCount, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: 10000,
    enabled: user?.notificationsEnabled,
    onSuccess(data) {
      if (
        data.totalCount !== currentCounts?.totalCount ||
        data.undreadCount !== currentCounts?.undreadCount
      ) {
        setCurrentCounts(data);
        queryClient.invalidateQueries('Notifications');
      }
    },
  });
};
