import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const SupportedDomains: React.FC = props => {
  const { t } = useTranslation();
  return <Text {...props}>{t('common.supportedDomains')}</Text>;
};

const Text = styled('span')(({ theme }) => ({
  fontSize: 14,
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: 16,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(20),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 20,
  },
}));
