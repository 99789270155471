import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useProjectDetailsByAddress } from '../../../../../../react-query/project/useProjectDetails';
import { useProjectFollowersCount } from '../../../../../../react-query/project/useProjectFollowersCount';

import { ProjectDetail } from './ProjectDetail';

import ProjectIcons from './svgs';
import { useProjectByParamId } from '../../../../../../react-query/project/useProject';

const { TokenSupplyIcon, FloorPriceIcon, HoldersIcon, FollowersIcon } = ProjectIcons;

export const ProjectDetails: React.FC = () => {
  const { t } = useTranslation();
  const { data: project, isLoading: projectIsLoading } = useProjectByParamId();
  const { data: projectDetails, isLoading } = useProjectDetailsByAddress();

  return (
    <Wrapper>
      <ProjectDetail
        label={t('projectPage.tokenSupply')}
        value={projectDetails?.tokenSupply}
        icon={<TokenSupplyIcon />}
        isLoading={isLoading || projectIsLoading || !project}
      />

      <ProjectDetail
        label={t('projectPage.floorPrice')}
        value={projectDetails?.floorPrice}
        icon={<FloorPriceIcon />}
        isLoading={isLoading || projectIsLoading || !project}
      />

      <ProjectDetail
        label={t('projectPage.holders')}
        value={projectDetails?.holdersCount}
        icon={<HoldersIcon />}
        isLoading={isLoading || projectIsLoading || !project}
      />
      <Followers />
    </Wrapper>
  );
};

const Followers: React.FC = () => {
  const { t } = useTranslation();

  const { data: project, isLoading: projectIsLoading } = useProjectByParamId();
  const { data: followersCount, isLoading } = useProjectFollowersCount(project?.uid);

  return (
    <ProjectDetail
      label={t('projectPage.followers')}
      value={followersCount || 0}
      icon={<FollowersIcon />}
      isLoading={isLoading || projectIsLoading || !project}
    />
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  maxWidth: 500,
  [theme.min(theme.breakpoints.md)]: {
    background: '#000',
    borderRadius: 15,
    maxWidth: 400,
  },
  [theme.min(theme.breakpoints.xl)]: {
    padding: 20,
    maxWidth: 520,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    padding: `${theme.pxToVw(24)} ${theme.pxToVw(32)}`,
    maxWidth: theme.pxToVw(720),
  },
  [theme.min(theme.breakpoints.max)]: {
    padding: '24px 32px',
    maxWidth: 720,
  },
}));
