import React from 'react';
import styled from 'styled-components';
import {
  autoFillStyle,
  getInputBaseStyle,
  getInputStyling,
  InputBaseProps,
  InputStyle,
} from '../Input/InputBase';

export const TextareaBaseEl = styled('textarea')<InputBaseProps>(
  ({ theme, error, width = '100%', hasValue, styling = InputStyle.Regular, activeBackground }) => ({
    padding: '12px 16px',
    transition: 'all .4s',
    resize: 'none',
    ...getInputBaseStyle(theme, !!error),
    ...getInputStyling(styling, theme, !!hasValue, !!activeBackground),
    width,
    '&:-webkit-autofill': autoFillStyle,
    '&:-webkit-autofill:hover': autoFillStyle,
    '&:-webkit-autofill:focus': autoFillStyle,
    '&:-webkit-autofill:active': autoFillStyle,
    [theme.betweenDM]: {
      fontSize: theme.pxToVw(14),
      borderRadius: theme.pxToVw(10),
      '&:placeholder': {
        fontSize: theme.pxToVw(14),
      },
      padding: hasValue
        ? `${theme.pxToVw(16)} ${theme.pxToVw(20)} ${theme.pxToVw(6)} ${theme.pxToVw(20)}`
        : `${theme.pxToVw(6)} ${theme.pxToVw(20)} ${theme.pxToVw(6)} ${theme.pxToVw(20)}`,
    },
  })
);

TextareaBaseEl.defaultProps = {
  spellCheck: false,
  autoCapitalize: 'false',
  autoComplete: 'off',
};

export const TextareaBase = React.forwardRef<
  HTMLTextAreaElement,
  InputBaseProps &
    React.TextareaHTMLAttributes<HTMLTextAreaElement> & { autoHeight?: boolean }
>((props, ref) => {
  return <TextareaBaseEl hasValue={!!props.value} {...props} ref={ref} />;
});
