import React from 'react';

export const withProviders =
  <T extends object>(...providers: any) =>
  (WrappedComponent: React.ComponentType<T>) => {
    return (props: T) => {
      return providers.reduceRight((acc: any, prov: any) => {
        let Provider = prov;

        if (Array.isArray(prov)) {
          Provider = prov[0];
          return <Provider {...prov[1]}>{acc}</Provider>;
        }

        return <Provider>{acc} </Provider>;
      }, <WrappedComponent {...props} />);
    };
  };
