import React from 'react';
import { useTranslation } from 'react-i18next';

import { envs } from '../../../config/envs';
import { useBlockemExtension } from '../../../providers/BlockemExtensionProvider';
import { AnalyticsService } from '../../../services/AnalyticsService';

import { ButtonPrimary } from '../../ui/buttons/ButtonPrimary';
import { ActionButtonProps } from './types';

interface Props {
  showArrow?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const InstallExtensionButton: React.FC<ActionButtonProps & Props> = ({
  analyticsEvent,
  text,
  showArrow = true,
  onClick,
  ...props
}) => {
  const { t } = useTranslation('common');
  const { isInstalled } = useBlockemExtension();

  if (isInstalled) return null;

  return (
    <ButtonPrimary
      onClick={() => {
        window.open(envs.extensionUrl, '_blank');
        if (analyticsEvent) {
          AnalyticsService.userEvent({
            action: analyticsEvent,
          });
        }
        typeof onClick === 'function' && onClick();
      }}
      {...props}
    >
      {text || t('installExtension')}{' '}
    </ButtonPrimary>
  );
};
