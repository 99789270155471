import React from 'react';
import styled from 'styled-components';
import { LogoSpinner } from './LogoSpinner';

export const PageLoader: React.FC = () => {
  return <Spinner />;
};

const Spinner = styled(LogoSpinner)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '100%',
  minHeight: '100%',
  marginTop: '15vh',
  [theme.min(theme.breakpoints.sm)]: {
    marginTop: '25vh',
  },
  [theme.min(theme.breakpoints.ul)]: {
    marginTop: '30vh',
  },
}));
