import { axiosInstance } from '../config/axios';
import { ReportData } from '../types';
import { apiRoutes } from './routes';

interface TrustPercentageResponse {
  trust_percentage: ReportData['trustPercentage'];
}

interface FullReportApiResponse extends TrustPercentageResponse {
  address: string;
  first_transaction_time: ReportData['firstTransactionTime'];
  last_transaction_time: ReportData['lastTransactionTime'];
  number_of_transactions: ReportData['numberOfTransactions'];
  nft_count: ReportData['nftCount'];
  balance_eth: ReportData['balanceEth'];
  is_contract: ReportData['isContract'];
  total_value_received: ReportData['totalValueReceivedEth'];
  total_value_sent: ReportData['totalValueSentEth'];
  eth_to_usd_conversion_rate: number;
  is_project_verified: boolean;
  owner_username: string;
  erc20_count: number;
  erc20_balance: number;
}

class ReputationApiConfig {
  private readonly apiMapper = {
    getFullReport: (response: FullReportApiResponse): ReportData => {
      const {
        first_transaction_time,
        last_transaction_time,
        number_of_transactions,
        nft_count,
        trust_percentage,
        balance_eth,
        is_contract,
        total_value_received,
        total_value_sent,
        eth_to_usd_conversion_rate,
        is_project_verified,
        owner_username,
        erc20_balance,
        erc20_count,
        address,
      } = response;

      return {
        address,
        firstTransactionTime: first_transaction_time,
        lastTransactionTime: last_transaction_time,
        numberOfTransactions: number_of_transactions,
        nftCount: nft_count,
        trustPercentage: trust_percentage,
        balanceEth: balance_eth,
        balanceUsd: Math.round(balance_eth * eth_to_usd_conversion_rate),
        isContract: is_contract,
        totalValueReceivedEth: total_value_received,
        totalValueReceivedUsd: Math.round(
          total_value_received * eth_to_usd_conversion_rate
        ),
        totalValueSentEth: total_value_sent,
        totalValueSentUsd: Math.round(total_value_sent * eth_to_usd_conversion_rate),
        isProjectVerified: is_project_verified,
        ownerUsername: owner_username || null,
        erc20Balance: erc20_balance,
        erc20Count: erc20_count,
        erc20BalanceUsd: Math.round(erc20_balance * eth_to_usd_conversion_rate),
        ethToUsdConversionRate: eth_to_usd_conversion_rate,
      };
    },

    getTrustPercentage: (
      response: TrustPercentageResponse
    ): ReportData['trustPercentage'] => {
      return response.trust_percentage;
    },
  };

  async getFullReport(contractAddress: string): Promise<ReportData> {
    const { data } = await axiosInstance.get<FullReportApiResponse>(
      apiRoutes.reputation.byAddress(contractAddress.trim().toLowerCase())
    );
    return this.apiMapper.getFullReport(data);
  }

  async getTrustPercentage(
    contractAddress: string
  ): Promise<ReportData['trustPercentage']> {
    const { data } = await axiosInstance.get<FullReportApiResponse>(
      apiRoutes.trust.byAddress(contractAddress.trim())
    );
    return this.apiMapper.getTrustPercentage(data);
  }
}

export const ReputationApi = new ReputationApiConfig();
