import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useStep } from '../../../providers/StepProvider';
import { useUser } from '../../../react-query/user/useUser';

import { CancelButton } from '../../../components/common/ActionButtons/CancelButton';
import { ButtonPrimary } from '../../../components/ui/buttons';
import { PostProjectRequestPayload, UserApi } from '../../../api/UserApi/UserApi';
import { routes } from '../../../router/routes';
import { useQueryClient } from 'react-query';
import { QueryKey } from '../../../react-query/query-keys';
import { useRequest } from '../../../hooks/useRequest';

export const ProjectOnboardActionButtons: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { step, setStep } = useStep();
  const { data: user } = useUser();

  const { handleSubmit, getValues, trigger } =
    useFormContext<PostProjectRequestPayload>();

  const onContinue = async () => {
    const isValid = await trigger(['signature', 'title', 'contractAddress']);

    if (!isValid || !user) return;

    if (step === 1) return setStep(2);

    const payload = getValues();
    await UserApi.postPoject(payload);
    await queryClient.invalidateQueries(QueryKey.UserProjects(), {
      refetchActive: true,
      refetchInactive: true,
    });

    navigate(routes.profile.byId(user?.username!));
  };

  const { call, isLoading } = useRequest(onContinue);

  return (
    <ActionButtons>
      <CancelButton
        onClick={() => navigate(routes.project.byId(user?.username!))}
        disabled={isLoading}
      />
      <ButtonPrimary type='button' disabled={isLoading} onClick={handleSubmit(call)}>
        {t(`projectOnboard.cta${step}`)}
      </ButtonPrimary>
    </ActionButtons>
  );
};

const ActionButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 19,
  marginTop: 10,
  '& button': {
    flex: 1,
    '&:last-child': {
      minWidth: 210,
    },
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(19),
    marginTop: theme.pxToVw(10),
    '& button': {
      '&:last-child': {
        minWidth: theme.pxToVw(210),
      },
    },
  },
}));
