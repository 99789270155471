import React from 'react';
import styled from 'styled-components';
import { useAuth } from '../../../../providers/AuthProvider';

import { PopularProjectsSection } from './PopularProjects/PopularProjectsSection';
import { PopularUsersSection } from './PopularUsers/PopularUsersSection';
import { RecommendedUsersSection } from './RecommendedUsers/RecommendedUsersSection';

export const NewsFeedSection: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Wrapper>
      <PopularProjectsSection />
      <PopularUsersSection />
      {isAuthenticated && <RecommendedUsersSection />}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  marginBottom: 12,
  [theme.min(theme.breakpoints.sm)]: {
    gap: 24,
    marginBottom: 48,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(32),
    marginBottom: theme.pxToVw(64),
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 32,
    marginBottom: 64,
  },
}));
