import React, { useState } from 'react';
import styled from 'styled-components';

import { ButtonSwitch } from '../../../ui/buttons/ButtonSwitch';
import { TextBase } from '../shared-components';

interface Props {
  label: string;
  on: boolean;
  description?: string;
  isLoading?: boolean;
  onChange: (val: boolean) => void;
}

export const SettingItem: React.FC<Props> = ({
  label,
  on,
  description,
  isLoading,
  onChange,
  ...otherProps
}) => {
  const [isOn, setIsOn] = useState(on);

  return (
    <Wrapper {...otherProps}>
      <Row>
        <TextBase>{label}</TextBase>{' '}
        <ButtonSwitch
          on={isOn}
          onChange={() => {
            if (isLoading) return;
            setIsOn(prev => !prev);
            onChange(!isOn);
          }}
          isLoading={isLoading}
        />
      </Row>
      {description && <Description light>{description}</Description>}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 10,
  [theme.betweenDM]: {
    gap: theme.pxToVw(10),
  },
}));

const Description = styled(TextBase)(({ theme }) => ({}));
