import React, { useContext } from 'react';

export type IWhereContext = 'main' | 'list' | 'project' | 'popup';

const WhereContext = React.createContext<IWhereContext | undefined>(undefined);

export const useWhere = (): IWhereContext => {
  const context = useContext(WhereContext);

  if (!context) {
    throw new Error('useWhere cannot be used without WhereContext');
  }

  return context;
};

interface Props {
  children: React.ReactNode;
  where: IWhereContext;
}

export const WhereProvider: React.FC<Props> = ({ children, where }) => {
  return <WhereContext.Provider value={where}>{children}</WhereContext.Provider>;
};
