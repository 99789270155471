import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useWindowWidth } from '../../../../../hooks/useWindowWidth';
import { useProfileUtils } from '../../../hooks/useProfileUtils';
import { useUser } from '../../../../../react-query/user/useUser';
import { useProfileByParamId } from '../../../../../react-query/profile/useProfile';
import { useCurrentUserFollowings } from '../../../../../react-query/user/useFollowings';
import { useClaimProfile } from '../../../../../hooks/useClaimProfile';

import { ButtonPrimary } from '../../../../../components/ui/buttons';
import { ReactComponent as ClaimSvg } from './Claim.svg';
import { Spinner } from '../../../../../components/ui/loaders/Spinner';
import { ErrorMessage } from '../../../../../components/ui/forms/FormFieldWrapper/ErrorMessage';

export const ClaimProfileButton: React.FC = () => {
  const { t } = useTranslation();
  const width = useWindowWidth();
  const theme = useTheme();
  const { isOwner, isClaimedAddress } = useProfileUtils();
  const { data: profile } = useProfileByParamId();
  const { data: user } = useUser();
  const { data: followings } = useCurrentUserFollowings();

  const text = t(
    `profilePage.claimCta.${width < theme.breakpoints.xl ? 'long' : 'short'}`
  );

  const { call, isLoading, isError } = useClaimProfile(profile);

  if (isOwner || isClaimedAddress || (!isClaimedAddress && user && !followings))
    return null;

  return (
    <ButtonWrapper>
      <ButtonPrimary width={'max-content'} disabled={isLoading} onClick={call}>
        {text}
        {isLoading ? <Spinner size={18} style={{ marginLeft: 13 }} /> : <Icon />}
      </ButtonPrimary>
      {isError && <ErrMsg error={isError} />}
    </ButtonWrapper>
  );
};

const Icon = styled(ClaimSvg)(({ theme }) => ({
  marginLeft: 12,
  [theme.betweenDM]: {
    marginLeft: theme.pxToVw(12),
  },
}));

const ButtonWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
}));

const ErrMsg = styled(ErrorMessage)(({ theme }) => ({
  position: 'absolute',
  bottom: -12,
  left: 0,
  maxWidth: 200,
  transform: 'translateY(100%)',
  [theme.betweenDM]: {
    bottom: theme.pxToVw(-12),
    left: 0,
    maxWidth: theme.pxToVw(200),
  },
}));
