import React from 'react';
import styled from 'styled-components';
import { useWhere } from '../../../../providers/WhereProvider';

import { AnalysisSummaryAddress } from './AnalysisSummaryAddress';
import { AnalysisSummaryText } from './AnalysisSummaryText';
import { AnalysisSummaryTrustTitle } from './AnalysisSummaryTrustTitle';

export const AnalysisSummary: React.FC = () => {
  const where = useWhere();

  return (
    <Wrapper>
      {where === 'main' && (
        <TitleWrapper className='first'>
          <AnalysisSummaryTrustTitle />
        </TitleWrapper>
      )}
      <AddressWrapper>
        <AnalysisSummaryAddress />
      </AddressWrapper>
      <TitleWrapper className='second'>
        <AnalysisSummaryTrustTitle />
      </TitleWrapper>
      <AnalysisSummaryText />
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 40,
  [theme.min(theme.breakpoints.sm)]: {
    marginBottom: useWhere() === 'main' ? 28 : 32,
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    marginBottom: useWhere() === 'main' ? theme.pxToVw(28) : theme.pxToVw(32),
  },
}));

const TitleWrapper = styled('div')(({ theme }) => ({
  marginBottom: 8,
  '&.first': {
    display: 'none',
  },
  '& .second': {},
  [theme.min(theme.breakpoints.sm)]: {
    '&.first': {
      display: 'block',
      marginBottom: 12,
    },
    '&.second': {
      display: useWhere() === 'main' ? 'none' : 'block',
      marginBottom: 8,
    },
  },
  [theme.min(theme.breakpoints.desktop)]: {},
}));

const AddressWrapper = styled('div')(({ theme }) => ({
  marginBottom: 14,
  [theme.min(theme.breakpoints.sm)]: {
    marginBottom: 12,
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    marginBottom: theme.pxToVw(12),
  },
}));
