import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useReputationWithAddress } from '../../../../../providers/AddressProvider/useReputationWithAddress';
import { useTrustCategory } from '../../TrustCategoryProvider';

import { Trust } from '../../../../../types';

import { AnalysisCardComponents } from '../card-components';
import { Shields } from './Shields/Shields';
import { Spinner } from '../../../../ui/loaders/Spinner';

const { BaseCard, TopContentWrapper, BottomContentWrapper } = AnalysisCardComponents;

export const TrustPercentageCard: React.FC = () => {
  const { data } = useReputationWithAddress();
  const { trust } = useTrustCategory();
  const { t } = useTranslation('analysisResult');

  const { trustPercentage } = data || {};

  return (
    <Wrapper>
      {trust && <TrustBackground trust={trust} />}
      <TopContent>
        <TrustPercentageTitle>{t('trustPercentage.title')}</TrustPercentageTitle>
      </TopContent>
      <BottomContent value={trustPercentage || 0}>
        {typeof trustPercentage === 'number' ? (
          <span>
            {trustPercentage === -1
              ? t('trustPercentage.notEnoughInfo')
              : `${trustPercentage}%`}
          </span>
        ) : (
          <Spinner size={32} />
        )}
        <ShieldsWrapper>
          <Shields
            trust={trust || Trust.High}
            trustPercentage={trustPercentage}
            isLoading={!trust}
          />
        </ShieldsWrapper>
      </BottomContent>
    </Wrapper>
  );
};

const Wrapper = styled(BaseCard)(({ theme }) => ({
  flex: 1,
  [theme.min(theme.breakpoints.sm)]: {
    padding: 24,
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    padding: theme.pxToVw(24),
  },
}));

const trustBackgrounds = {
  [Trust.High]:
    'radial-gradient(78.79% 104.4% at 50% 104.4%, #83AD61 11.98%, rgba(0, 0, 0, 0.11) 67.71%)',
  [Trust.Medium]:
    'radial-gradient(78.79% 104.4% at 50% 104.4%, #E5BC4F 11.98%, rgba(0, 0, 0, 0.11) 67.71%)',
  [Trust.Low]:
    'radial-gradient(78.79% 104.4% at 50% 104.4%, #FF8C4B 11.98%, rgba(0, 0, 0, 0.11) 67.71%)',
  [Trust.Untrusted]:
    'radial-gradient(78.79% 104.4% at 50% 104.4%, #FC5757 11.98%, rgba(0, 0, 0, 0.11) 67.71%)',
  [Trust.NewWallet]:
    'radial-gradient(78.79% 104.4% at 50% 104.4%, #0065FC 11.98%, rgba(0, 0, 0, 0.11) 67.71%)',
};

const TrustBackground = styled('div')<{ trust: Trust }>(({ trust }) => ({
  backdropFilter: 'blur(7px)',
  borderRadius: 15,
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: trustBackgrounds[trust],
  transform: 'rotate(180deg)',
  zIndex: 0,
}));

const TrustPercentageTitle = styled('h5')(({ theme }) => ({
  fontSize: 20,
  fontWeight: 900,
  marginBottom: 13,
  textTransform: 'uppercase',
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    fontSize: theme.pxToVw(20),
  },
}));

const TopContent = styled(TopContentWrapper)(({ theme }) => ({
  zIndex: theme.zIndex.sm,
  position: 'relative',
}));

const BottomContent = styled(BottomContentWrapper)<{ value: number }>(
  ({ theme, value }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: theme.zIndex.sm,
    position: 'relative',
    paddingTop: 30,
    '& span': {
      fontSize: value === -1 ? 24 : 36,
      fontWeight: 700,
      [theme.min(theme.breakpoints.lg)]: {
        fontSize: 18,
      },
      [theme.min(theme.breakpoints.desktop)]: {
        fontSize: theme.pxToVw(24),
      },
      [theme.min(theme.breakpoints.max)]: {
        fontSize: 24,
      },
    },
    [theme.min(theme.breakpoints.lg)]: {
      paddingTop: 15,
    },
    [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
      paddingTop: theme.pxToVw(15),
    },
  })
);

const ShieldsWrapper = styled('div')(({ theme }) => ({
  [theme.min(theme.breakpoints.lg)]: {
    transform: 'scale(0.8) translateX(20%)',
    transformOrigin: '0 0',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(24),
    transform: 'scale(0.6) translate(60%, 30%)',
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 24,
  },
}));
