import Web3 from 'web3';
import { SignMessage } from '../constants/sign-messages';

const web3 = new Web3((window as any).ethereum);

export class Web3Service {
  static async connectAccount() {
    await web3.eth.requestAccounts();
  }

  static async getAccounts() {
    return await web3.eth.getAccounts();
  }

  static async signMessage(selectedWallet: any, message: SignMessage) {
    // @ts-ignore
    const _web3 = new Web3(selectedWallet.provider);

    const res = await _web3.eth.personal.sign(
      message,
      selectedWallet.accounts[0].address,
      ''
    );

    return res;
  }
}
