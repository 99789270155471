import React from 'react';
import styled from 'styled-components';
import { Container } from '../Container';

interface Props {
  children: React.ReactNode;
  title: string;
  maxWidth: number;
  onSubmit?: React.FormEventHandler;
}

export const CenteredLayoutWithTitle: React.FC<Props> = ({
  children,
  title,
  maxWidth,
}) => {
  return (
    <Wrapper maxWidth={maxWidth}>
      <Title>{title}</Title>
      <Container>{children}</Container>
    </Wrapper>
  );
};

const Wrapper = styled('div')<{ maxWidth: number }>(({ theme, maxWidth }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  margin: '0 auto',
  padding: '14vh 0 10vh 0',
  maxWidth,
  [theme.betweenDM]: {
    maxWidth: maxWidth > 800 ? theme.pxToVw(maxWidth) : maxWidth,
    padding: '12vh 0 10vh 0',
  },
}));

const Title = styled('h1')(({ theme }) => ({
  textAlign: 'center',
  marginBottom: 18,
  fontWeight: 900,
  fontSize: 30,
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: 36,
  },
  [theme.min(theme.breakpoints.md)]: {
    marginBottom: 20,
    fontSize: 42,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(64),
    marginBottom: theme.pxToVw(24),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 64,
    marginBottom: 24,
  },
}));
