import React, { useState } from 'react';
import styled from 'styled-components';

import { useUser } from '../../../../../react-query/user/useUser';

import { shortenEthAddress } from '../../../../../utils/address-utils';

import { Icon } from '../../../../../assets';
import { ProfileImage } from '../../../../../components/common/ProfileImage/ProfileImage';
import { FollowUserProfileButton } from '../../../../../components/common/ActionButtons/FollowUserProfileButton';
import { FollowProjectProfileButton } from '../../../../../components/common/ActionButtons/FollowProjectProfileButton';

export interface FollowerGrouped {
  username: string | null;
  photoUrl: string | null;
  address?: string;
  userUid: string | null;
  addresses?: string[];
  type: 'follower' | 'following';
  isContract?: boolean;
  onClick: (addressOrUsername: string) => void;
}

export const FollowerItem: React.FC<FollowerGrouped> = ({
  username,
  userUid,
  photoUrl,
  addresses,
  address,
  type,
  isContract,
  onClick,
}) => {
  const [open, setOpen] = useState(false);
  const { data: user } = useUser();

  const isCurrentUser = user?.uid && user.uid === userUid;

  return (
    <Wrapper menuHeight={(addresses?.length || 0) * 56 + 12} open={open}>
      <RowItem open={open}>
        <ProfileInfo
          onClick={() => onClick(isContract ? address! : username || address || '')}
          username={
            username ||
            (address &&
              shortenEthAddress(address, {
                placeholderChar: '.',
                placeholderCount: 2,
                startChars: 4,
              })) ||
            ''
          }
          photoUrl={photoUrl}
        />
        {addresses && addresses.length > 1 ? (
          <IconWrapper onClick={() => setOpen(prev => !prev)} open={open}>
            <Icon.ChevronDownWhite />
          </IconWrapper>
        ) : !isCurrentUser ? (
          isContract && address && userUid ? (
            <FollowProjectProfileButton projectUid={userUid} contractAddress={address} />
          ) : (
            <FollowUserProfileButton address={address} userUid={userUid || undefined} />
          )
        ) : null}
      </RowItem>
      {open && type === 'following' && userUid && addresses && addresses.length > 1 && (
        <SubMenu itemsCount={addresses.length}>
          {addresses.map(_address => (
            <RowItem key={_address}>
              <ProfileInfo
                onClick={() => onClick(_address)}
                photoUrl={null}
                username={shortenEthAddress(_address, {
                  placeholderChar: '.',
                  placeholderCount: 2,
                  startChars: 4,
                })}
              />
              {!isCurrentUser && <FollowUserProfileButton address={_address} />}
            </RowItem>
          ))}
        </SubMenu>
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')<{ menuHeight: number; open: boolean }>(
  ({ theme, open, menuHeight }) => ({
    display: 'flex',
    flexDirection: 'column',
    transition: 'all .3s',
    height: !open ? 56 : 56 + menuHeight,
    overflow: 'hidden',
    width: '100%',
    backgroundColor: open ? 'rgba(16, 20, 24, 0.8)' : 'rgba(16, 20, 24, 0.4)',
    borderRadius: 15,
    flexShrink: 0,
    [theme.betweenDM]: {
      height: !open ? theme.pxToVw(56) : theme.pxToVw(56 + menuHeight),
      borderRadius: theme.pxToVw(15),
    },
  })
);

const IconWrapper = styled('div')<{ open: boolean }>(({ theme, open }) => ({
  height: 20,
  width: 20,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 4,
  '& svg': {
    transition: 'all .3s',
    cursor: 'pointer',
    transform: `rotate(${open ? 180 : 0}deg)`,
    width: 10,
    height: 'auto',
  },

  [theme.betweenDM]: {
    height: theme.pxToVw(20),
    width: theme.pxToVw(20),
    '& svg': {
      width: theme.pxToVw(10),
    },
  },
}));

const RowItem = styled('div')<{ open?: boolean }>(({ theme, open }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: typeof open === 'boolean' ? 56 : 52,
  flexShrink: 0,
  padding: '0 12px 0 24px',

  [theme.betweenDM]: {
    height: typeof open === 'boolean' ? theme.pxToVw(56) : theme.pxToVw(52),
    padding: `0 ${theme.pxToVw(12)} 0 ${theme.pxToVw(24)}`,
  },
}));

const SubMenu = styled('div')<{ itemsCount: number }>(({ theme, itemsCount }) => ({
  paddingLeft: '5%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  minHeight: 'max-content',
}));

interface Props2 {
  username: string | null;
  photoUrl: string | null;
  onClick: () => void;
}

const ProfileInfo: React.FC<Props2> = ({ username, photoUrl, onClick }) => {
  return (
    <ProfileInfoWrapper onClick={onClick}>
      <ProfileImage photoUrl={photoUrl} />
      <Username>{username}</Username>
    </ProfileInfoWrapper>
  );
};

const ProfileInfoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 14,
  cursor: 'pointer',
  minWidth: 'max-content',
  width: '80%',
  maxWidth: '90%',
  '&:hover span': {
    opacity: 0.85,
    color: theme.colors.primary.main,
  },
  '& div': {
    cursor: 'pointer',
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(14),
  },
}));

const Username = styled('span')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  transition: 'all .3s',
  opacity: 1,
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(16),
  },
}));
