import { axiosInstance } from '../config/axios';
import { NFT, PaginatedResponse, Profile } from '../types';
import { isValidEthAddress } from '../utils/address-utils';
import { apiRoutes } from './routes';
import { ProfileResponseApi, TotalAssets } from './types';

export type UserNFTsResponse = PaginatedResponse<NFT>;

interface TotalAssetsResponseApi {
  balance: number;
  erc20_count: number;
  erc20_balance: number;
  nft_count: number;
  nft_balance: number;
  eth_to_usd_conversion_rate: number;
}

class ProfileApiConfig {
  private readonly apiMapper = {
    profile: (response: ProfileResponseApi): Profile => {
      const {
        phone_number,
        photo_url,
        cover_photo,
        twitter_url,
        discord_url,
        opensea_url,
        ...rest
      } = response;
      return {
        phoneNumber: phone_number,
        photoUrl: photo_url,
        coverPhoto: cover_photo,
        twitterUrl: twitter_url,
        openseaUrl: opensea_url,
        discordUrl: discord_url,
        ...rest,
      };
    },

    totalAssets: (response: TotalAssetsResponseApi): TotalAssets => {
      const {
        balance,
        nft_balance,
        nft_count,
        erc20_balance,
        erc20_count,
        eth_to_usd_conversion_rate,
      } = response;

      return {
        balanceEth: parseFloat(balance.toFixed(4)),
        balanceUsd: parseFloat((balance * eth_to_usd_conversion_rate).toFixed(2)),
        nftCount: nft_count,
        nftBalanceEth: parseFloat(nft_balance.toFixed(4)),
        nftBalanceUsd: parseFloat((nft_balance * eth_to_usd_conversion_rate).toFixed(2)),
        erc20Count: erc20_count,
        erc20BalanceEth: parseFloat(erc20_balance.toFixed(4)),
        erc20BalanceUsd: parseFloat(
          (erc20_balance * eth_to_usd_conversion_rate).toFixed(2)
        ),
      };
    },
  };

  getProfileByUsername = async (id: string): Promise<Profile> => {
    try {
      const { data } = await axiosInstance.get<ProfileResponseApi>(
        apiRoutes.profile.byId(id)
      );
      return this.apiMapper.profile(data);
    } catch (err: any) {
      if (isValidEthAddress(id) && err.response.status === 404) {
        return {
          uid: '',
          username: id,
          email: null,
          phoneNumber: null,
          photoUrl: '',
          addresses: [id],
          coverPhoto: null,
          description: null,
          twitterUrl: null,
          discordUrl: null,
          openseaUrl: null,
        };
      }
      throw err;
    }
  };

  getProfileTotalAssets = async (usernameOrAddress: string): Promise<TotalAssets> => {
    const { data } = await axiosInstance.get<TotalAssetsResponseApi>(
      apiRoutes.profile.values(usernameOrAddress)
    );
    return this.apiMapper.totalAssets(data);
  };

  getFollowersCount = async (uidOrAddress: string): Promise<number> => {
    const { data } = await axiosInstance.get(
      apiRoutes.profile.followersCount(uidOrAddress)
    );
    return data.followers_count;
  };

  getFollowingsCount = async (userUid: string): Promise<number> => {
    const { data } = await axiosInstance.get(apiRoutes.profile.followingCount(userUid));
    return data.followers_count;
  };
}

export const ProfileApi = new ProfileApiConfig();
