import styled from 'styled-components';
import { Container as ContainerEl } from '../../layouts/Container';
import { ButtonPrimary } from '../buttons';

const Container = styled(ContainerEl)(({ theme }) => ({
  paddingTop: 120,
  paddingBottom: 120,
  height: '100%',
  maxWidth: 900,
  '& a': {
    fontSize: 'inherit',
    fontFamily: 'inherit',
    textDecoration: 'underline',
    color: 'inherit',
    '&:hover': {
      color: theme.colors.primary.main,
    },
  },
  [theme.min(theme.breakpoints.desktop)]: {
    paddingBottom: theme.pxToVw(120),
  },
  [theme.min(theme.breakpoints.max)]: {
    paddingBottom: 120,
  },
}));

const Title = styled('h2')(({ theme }) => ({
  marginBottom: 20,
  lineHeight: 1.32,
  fontSize: 32,
  [theme.max(theme.breakpoints.sm)]: {
    marginBottom: 16,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(64),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 64,
  },
}));

const SectionTitle = styled('h3')(({ theme }) => ({
  fontSize: 24,
  fontWeight: 700,
  lineHeight: 1.32,
  marginBottom: '3rem',
  [theme.max(theme.breakpoints.sm)]: {
    marginBottom: 30,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(48),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 48,
  },
}));

const LastUpdated = styled('h1')(({ theme }) => ({
  fontSize: 20,
  fontWeight: 900,
  lineHeight: 1.165,
  color: theme.colors.primary.main,
  marginBottom: 30,
  [theme.min(theme.breakpoints.sm)]: {
    marginBottom: 48,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(24),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 24,
  },
}));

const Section = styled('section')(({ theme }) => ({
  '& > *:last-child': {
    marginBottom: 0,
  },
  marginBottom: '6rem',
  [theme.max(theme.breakpoints.sm)]: {
    marginBottom: '3.6rem',
  },
}));

const InnerSection = styled('div')(({ theme }) => ({
  marginBottom: '4.2rem',
  [theme.max(theme.breakpoints.sm)]: {
    marginBottom: '2.8rem',
  },
}));

const InnerSectionTitle = styled('h5')(({ theme }) => ({
  fontWeight: 700,
  marginBottom: 8,
  fontSize: 18,
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(32),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 32,
  },
}));

const Text = styled('p')(({ theme }) => ({
  marginBottom: 20,
  fontSize: 16,
  lineHeight: 1.56,
  [theme.min(theme.breakpoints.sm)]: {
    marginBottom: 30,
  },
}));

const OL = styled('ol')(({ theme, type }) => ({
  marginBottom: '3rem',
  fontSize: '2rem',
  counterReset: 'list',
  marginLeft: type === 'i' ? '3.2rem' : 0,
  marginTop: type === 'i' ? '2rem' : 0,
  [theme.max(theme.breakpoints.sm)]: {
    marginLeft: type === 'i' ? '2.4rem' : 0,
    marginTop: type === 'i' ? '1.6rem' : 0,
  },
  '& li': {
    listStyle: 'none',
    marginBottom: '1.2rem',
    lineHeight: 1.32,
    [theme.max(theme.breakpoints.lg)]: {
      fontSize: '1.8rem',
    },
    [theme.max(theme.breakpoints.sm)]: {
      fontSize: '1.6rem',
    },
    '&:before': {
      content: `"(" counter(list, lower-${type === 'i' ? 'roman' : 'alpha'}) ") "`,
      counterIncrement: 'list',
      marginRight: 2,
      [theme.max(theme.breakpoints.sm)]: {
        marginRight: 1,
      },
    },
  },
}));

const DownloadButton = styled(ButtonPrimary)(({ theme }) => ({
  padding: '0 5.4rem',
  '& svg': {
    marginLeft: '1.4rem',
    transform: 'scale(0.9) translate(10%, 20%)',
    transformOrigin: '0 0',
    [theme.max(theme.breakpoints.sm)]: {
      transform: 'scale(0.8) translate(20%, 30%)',
    },
  },
}));

const Styled = {
  Container,
  Title,
  Text,
  Section,
  OL,
  LastUpdated,
  InnerSectionTitle,
  InnerSection,
  SectionTitle,
  DownloadButton,
};

export default Styled;
