import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useEditProfileDetailsForm } from './useEditProfileDetailsForm';
import { useRequest } from '../../../hooks/useRequest';

import { TextareaBase } from '../../ui/forms/Textarea/TextareaBase';
import { BaseModal } from '../../ui/modals/BaseModal/BaseModal';
import { InputStyle } from '../../ui/forms/Input/InputBase';
import { InputWithIcons } from '../../ui/forms/Input/InputWithIcons';
import { ButtonPrimary, ButtonSize } from '../../ui/buttons';

import { ReactComponent as TwitterIcon } from '../../../assets/svgs/social/TwitterWhite.svg';
import { ReactComponent as OpenseaIcon } from '../../../assets/svgs/social/OpenseaWhite.svg';
import { ReactComponent as DiscordIcon } from '../../../assets/svgs/social/DiscordWhite.svg';
import { CancelButton } from '../../common/ActionButtons/CancelButton';
import { Text } from '../../ui/text/Text';

export interface EditProfileModalOnSavePayload {
  description: string | null;
  twitterUrl: string | null;
  discordUrl: string | null;
  openseaUrl: string | null;
  externalUrl?: string | null;
}
interface Props {
  onClose: () => void;
  onSave: (payload: EditProfileModalOnSavePayload) => Promise<void>;
  defaultValues: EditProfileModalOnSavePayload;
}

export const EditProfileModal: React.FC<Props> = ({ onClose, onSave, defaultValues }) => {
  const { t } = useTranslation();

  const {
    setValue,
    handleSubmit,
    register,
    getValues,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useEditProfileDetailsForm();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = async () => {
    const { description, twitterUrl, discordUrl, openseaUrl } = getValues();

    const payload: EditProfileModalOnSavePayload = {
      description: description || null,
      twitterUrl: twitterUrl || null,
      discordUrl: discordUrl || null,
      openseaUrl: openseaUrl || null,
    };

    await onSave(payload);
  };

  const { call, isLoading } = useRequest(onSubmit, {
    onSuccess: () => {
      onClose();
    },
  });

  return (
    <BaseModal title={t('profilePage.editProfileModal.title')} isOpen onClose={onClose}>
      <Wrapper>
        <Content onSubmit={handleSubmit(call)}>
          <TextareaBase
            placeholder={t('profilePage.editProfileModal.descriptionPlaceholder')}
            styling={InputStyle.Dark}
            {...register('description')}
          />
          <SocialTitle>{t('profilePage.editProfileModal.socialTitle')}</SocialTitle>
          <InputsWrapper>
            <InputWithIcons
              iconLeft={<TwitterIcon />}
              styling={InputStyle.Dark}
              placeholder='Twitter'
              error={errors.twitterUrl?.message}
              hasValue={!!watch('twitterUrl')}
              {...register('twitterUrl')}
              onClear={() => setValue('twitterUrl', null, { shouldDirty: true })}
            />
            <InputWithIcons
              iconLeft={<OpenseaIcon />}
              styling={InputStyle.Dark}
              placeholder='Opensea'
              error={errors.openseaUrl?.message}
              hasValue={!!watch('openseaUrl')}
              {...register('openseaUrl')}
              onClear={() => setValue('openseaUrl', null, { shouldDirty: true })}
            />
            <InputWithIcons
              iconLeft={<DiscordIcon />}
              styling={InputStyle.Dark}
              placeholder='Discord'
              error={errors.discordUrl?.message}
              hasValue={!!watch('discordUrl')}
              {...register('discordUrl')}
              onClear={() => setValue('discordUrl', null, { shouldDirty: true })}
            />
          </InputsWrapper>
          <Footer>
            <ButtonPrimary
              size={ButtonSize.Sm}
              type='submit'
              isLoading={isLoading}
              disabled={!isDirty}
            >
              {t('common.saveChanges')}
            </ButtonPrimary>
            <CancelButton
              onClick={() => onClose()}
              size={ButtonSize.Sm}
              disabled={isLoading}
            />
          </Footer>
        </Content>
      </Wrapper>
    </BaseModal>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  maxWidth: 670,
  width: '80vw',
  [theme.betweenDM]: {
    maxWidth: theme.pxToVw(670),
  },
}));

const Content = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  flex: 1,
  '& textarea': {
    height: 88,
    paddingTop: 12,
  },
  [theme.betweenDM]: {
    '& textarea': {
      paddingTop: theme.pxToVw(12),
    },
  },
}));

const SocialTitle = styled(Text.MD)(({ theme }) => ({
  marginBottom: 12,
  marginTop: 20,
  fontWeight: 900,
  [theme.min(theme.breakpoints.sm)]: {
    fontWeight: 700,
    marginTop: 16,
  },
  [theme.betweenDM]: {
    marginTop: theme.pxToVw(16),
    marginBottom: theme.pxToVw(12),
  },
}));

const InputsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 8,
}));

const Footer = styled('div')(({ theme }) => ({
  marginTop: 18,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  '& button': {
    width: '100%',
  },
  [theme.min(theme.breakpoints.sm)]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& button': {
      '&:first-child': {
        width: 180,
      },
      '&:last-child': {
        width: 140,
      },
    },
  },
  [theme.betweenDM]: {
    marginTop: theme.pxToVw(18),
    '& button': {
      '&:first-child': {
        width: theme.pxToVw(180),
      },
      '&:last-child': {
        width: theme.pxToVw(140),
      },
    },
  },
}));
