import styled from 'styled-components';

const OuterWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: theme.zIndex.ul,
}));

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
}));

const Wrapper = styled('div')<{
  width?: string | number;
  primary?: boolean;
  menuOffsetLeft?: number | string;
  menuOffsetTop?: number | string;
}>(
  ({
    theme,
    primary,
    width = '19.8rem',
    menuOffsetLeft = '2rem',
    menuOffsetTop = 20,
  }) => ({
    padding: 14,
    width,
    borderRadius: 5,
    backgroundColor: primary ? theme.colors.primary.main : '#474747',
    color: theme.colors.text.primary,
    fontSize: 12,
    position: 'absolute',
    top: menuOffsetTop,
    left: menuOffsetLeft,
    fontWeight: 600,
    boxShadow: '8px 4px 6px rgba(0, 0, 0, 0.25)',
    maxWidth: '90vw',
    lineHeight: 1.31,
    [theme.betweenDM]: {
      padding: theme.pxToVw(14),
      borderRadius: theme.pxToVw(5),
      fontSize: theme.pxToVw(12),
    },
  })
);

const IconWrapper = styled('div')<{ open: boolean }>(({ theme, open }) => ({
  padding: 2,
  borderRadius: '50%',
  cursor: 'pointer',
  '& path': {
    fill: open ? `${theme.colors.primary.main} !important` : 'auto',
  },
}));

const Styled = { Container, Wrapper, IconWrapper, OuterWrapper };

export default Styled;
