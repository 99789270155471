import { axiosInstance } from '../config/axios';
import { apiRoutes } from './routes';

interface RegisterPayloadApi extends Omit<RegisterPayload, 'phoneNumber'> {
  phone_number?: string;
}

export interface RegisterPayload {
  username: string;
  password: string;
  addresses: { address: string; signature: string }[];
  email?: string;
  phoneNumber?: string;
}

export interface RegisterResponse {
  token: string;
  addresses: string[];
}

interface LoginPayloadApi {
  Username: string;
  Password: string;
}

export interface LoginPayload {
  username: string;
  password: string;
}

export interface LoginResponse {
  token: string;
}

export interface ResetPasswordPayload {
  newPassword: string;
  address: string;
  signature: string;
}

class AuthApiConfig {
  private readonly apiMapper = {
    registerToApi: (payload: RegisterPayload): RegisterPayloadApi => {
      const { phoneNumber, ...other } = payload;
      return {
        ...other,
        phone_number: phoneNumber,
      };
    },
    resetPasswordToApi: (payload: ResetPasswordPayload) => {
      const { newPassword } = payload;
      return {
        ...payload,
        new_password: newPassword,
      };
    },
    loginToApi: (payload: LoginPayload): LoginPayloadApi => {
      const { username, password } = payload;
      return {
        Username: username,
        Password: password,
      };
    },
  };

  async register(payload: RegisterPayload): Promise<RegisterResponse> {
    const { data } = await axiosInstance.post<RegisterResponse>(
      apiRoutes.auth.register(),
      this.apiMapper.registerToApi(payload)
    );
    return data;
  }

  async login(payload: LoginPayload): Promise<LoginResponse> {
    const { data } = await axiosInstance.post<LoginResponse>(
      apiRoutes.auth.login(),
      payload
    );
    return data;
  }

  resetPassword = async (payload: ResetPasswordPayload): Promise<void> => {
    await axiosInstance.post(
      apiRoutes.auth.resetPassword(),
      this.apiMapper.resetPasswordToApi(payload)
    );
  };
}

export const AuthApi = new AuthApiConfig();
