import styled from 'styled-components';

const Card = styled('div')(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  borderRadius: 15,
  padding: 20,
  width: '100%',
  position: 'relative',
  height: 'max-content',
  [theme.min(theme.breakpoints.sm)]: {
    padding: 32,
  },
  [theme.min(1050)]: {
    width: '65%',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    borderRadius: theme.pxToVw(15),
    padding: theme.pxToVw(32),
    maxWidth: theme.pxToVw(750),
    minWidth: theme.pxToVw(500),
  },
  [theme.min(theme.breakpoints.max)]: {
    borderRadius: 15,
    padding: 32,
    maxWidth: 750,
    minWidth: 500,
  },
}));

const FollowersWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
}));

const SocialIconsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
}));

const Styled = { Card, FollowersWrapper, SocialIconsWrapper };

export default Styled;
