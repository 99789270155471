import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { isValidEthAddress } from '../../../utils/address-utils';
import { routes } from '../../../router/routes';

import { useReputationWithAddress } from '../../../providers/AddressProvider/useReputationWithAddress';
import { useAddress } from '../../../providers/AddressProvider/AddressProvider';

import { AddressInput } from '../AddressInput/AddressInput';
import { SupportedDomains } from '../Address/SupportedDomains';
import { SocialIcons } from '../SocialIcons/SocialIcons';
import { Heading4 } from '../../ui/text/Heading';

interface Props {
  withTitle?: boolean;
  showSocial?: boolean;
  withTextButton?: boolean;
}

export const GenerateProfileInput: React.FC<Props> = ({
  withTitle,
  showSocial,
  withTextButton,
}) => {
  const { isLoading, error, data: reputation } = useReputationWithAddress();
  const { setAddress, address } = useAddress();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { t } = useTranslation();

  const onSubmit = async (value: string) => {
    if (!isValidEthAddress(value)) {
      return setErrorMessage(t('errors.invalidEthAddress'));
    }
    setErrorMessage(null);
    setAddress(value);
  };

  useEffect(() => {
    if (!error || !address) return;
    const msg = error?.response?.data?.message;
    const apiErrors = [
      'INCORRECT_ADDRESS_LENGTH',
      'INTERNAL_SERVER_ERROR',
      'INCORRECT_ETH_NAME',
    ];

    const errorMessage =
      msg && apiErrors.includes(msg)
        ? t(`errors.${msg}`, {
            type: address.toLowerCase().endsWith('.bit') ? 'BIT' : 'ETH',
          })
        : t('errors.default');
    setErrorMessage(errorMessage);
  }, [address, error, setErrorMessage, t]);

  useEffect(() => {
    if (!reputation) return;

    const { isContract, ownerUsername, address } = reputation;

    setAddress('');
    if (isContract) {
      navigate(routes.project.byId(address));
    } else {
      navigate(routes.profile.byId(ownerUsername || address));
    }
  }, [navigate, setAddress, reputation]);

  return (
    <>
      {withTitle && <Title>{t('common.generateOtherProfiles')}</Title>}
      <AddressInput
        id='generate-profile-input'
        onSubmit={onSubmit}
        isLoading={isLoading}
        error={errorMessage}
        placeholder={t('common.searchPlaceholder')}
        withTextButton={withTextButton}
      />
      <DomainsAndSocialWrapper>
        {showSocial && <Social />} <DomainsText showSocial={!!showSocial} />
      </DomainsAndSocialWrapper>
    </>
  );
};

const Title = styled(Heading4)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: 20,
  [theme.min(theme.breakpoints.desktop)]: {
    marginBottom: theme.pxToVw(24),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginBottom: 24,
  },
}));

const DomainsAndSocialWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  marginTop: 20,
  [theme.min(theme.breakpoints.xs)]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    gap: theme.pxToVw(20),
    marginTop: theme.pxToVw(32),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginTop: 32,
  },
}));

const DomainsText = styled(SupportedDomains)<{ showSocial: boolean }>(
  ({ theme, showSocial }) => ({
    marginTop: showSocial ? 0 : 14,
    [theme.min(theme.breakpoints.xs)]: {
      marginLeft: 'auto',
    },
    [theme.min(theme.breakpoints.md)]: {
      marginTop: showSocial ? 0 : 18,
    },
    [theme.min(theme.breakpoints.desktop)]: {
      marginTop: showSocial ? 0 : theme.pxToVw(24),
    },
    [theme.min(theme.breakpoints.max)]: {
      marginTop: showSocial ? 0 : 24,
    },
  })
);

const Social = styled(SocialIcons)(({ theme }) => ({
  width: 'max-content',
  gap: 26,
  '& svg': {
    height: 28,
    width: 'auto',
  },
  [theme.min(theme.breakpoints.sm)]: {
    marginLeft: '1.5vw',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(40),
    '& svg': {
      height: theme.pxToVw(42),
    },
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 40,
    '& svg': {
      height: 42,
    },
  },
}));
