import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useProjectByParamId } from '../../../../../../react-query/project/useProject';

import { ClipboardButton } from '../../../../../../components/ui/buttons/ClipboardButton/ClipboardButton';
import { shortenEthAddress } from '../../../../../../utils/address-utils';
import { routes } from '../../../../../../router/routes';

export const ProjectOwner: React.FC = () => {
  const { data: project } = useProjectByParamId();
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!project) return null;
  return (
    <Wrapper>
      <Span primary>{`${t('common.by')} `}</Span>
      <Span $action onClick={() => navigate(routes.profile.byId(project.ownerAddress))}>
        {shortenEthAddress(project.ownerAddress, { startChars: 8 })}
      </Span>
      <Clip value={project.ownerAddress} />
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  marginBottom: 4,
  marginTop: 4,
}));

const Span = styled('span')<{ primary?: boolean; $action?: boolean }>(
  ({ theme, primary, $action }) => ({
    fontSize: 14,
    color: primary ? theme.colors.primary.main : '#fff',
    [theme.min(theme.breakpoints.md)]: {
      fontSize: 16,
    },
    [theme.min(theme.breakpoints.desktop)]: {
      fontSize: theme.pxToVw(20),
    },
    [theme.min(theme.breakpoints.max)]: {
      fontSize: 20,
    },
    cursor: $action ? 'pointer' : 'auto',
    transition: 'all .3s',
    opacity: 1,
    '&:hover': {
      opacity: $action ? 0.85 : 1,
    },
  })
);

const Clip = styled(ClipboardButton)(({ theme }) => ({
  marginLeft: 4,
}));
