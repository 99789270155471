import React from 'react';
import styled from 'styled-components';
import { FOOTER_MOBILE_BREAKPOINT_WIDTH } from '../../Footer';

const Wrapper = styled('div')(({ theme }) => ({
  '& svg': {
    height: '7.2rem',
    width: '7.2rem',
    [theme.betweenDM]: {
      height: theme.pxToVw(72),
      width: theme.pxToVw(72),
    },
    [theme.max(FOOTER_MOBILE_BREAKPOINT_WIDTH)]: {
      height: 63,
      width: 63,
    },
  },
}));

export const FooterLogo: React.FC = () => (
  <Wrapper>
    <svg
      width='72'
      height='72'
      viewBox='0 0 72 72'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='72' height='72' fill='#0065FC' />
      <path
        d='M30.9112 46.5776L44.022 33.7344L44.022 33.7345L44.0249 33.7315C44.5919 33.1646 44.9178 32.4274 44.9178 31.7148C44.9178 29.276 43.9131 26.9812 42.1462 25.3098C40.3741 23.6336 38.0289 22.8221 35.5965 22.9652C33.9385 23.0628 32.6228 23.7484 31.5637 24.7112L31.5566 24.7176L31.5499 24.7244L31.3237 24.9506L22.9319 33.1143C22.2133 33.7934 21.631 34.57 21.1561 35.413C20.9263 34.0025 20.7005 32.1424 20.7338 31.1362C21.0457 23.3115 27.4082 16.7692 35.2808 16.2348C39.5932 15.9684 43.6832 17.4784 46.8443 20.4159C49.9137 23.353 51.6923 27.4461 51.6923 31.7148C51.6923 33.5528 51.1679 35.3033 50.2035 36.8382C49.7896 37.4821 49.4011 38.0833 48.8893 38.5951L45.239 42.2455C44.7813 42.7031 44.3155 43.2094 43.8428 43.7232C43.6839 43.8959 43.5242 44.0694 43.3638 44.2423C42.7212 44.9346 42.0593 45.624 41.3563 46.2434C39.9481 47.4839 38.4109 48.4107 36.6005 48.5172C34.5262 48.6392 32.4856 47.9229 30.9112 46.5776Z'
        fill='white'
        stroke='#0065FC'
        strokeWidth='0.8'
      />
      <path
        d='M41.4212 25.6605L28.2661 38.5034L28.2661 38.5034L28.2627 38.5068C27.6957 39.0738 27.3698 39.811 27.3698 40.5236C27.3698 42.9623 28.3746 45.2571 30.1415 46.9285C31.9135 48.6048 34.2588 49.4162 36.6911 49.2731L36.6911 49.2731L36.6961 49.2728C38.0439 49.1765 39.2559 48.7421 40.2767 48.0129L40.2856 48.0066L40.2941 47.9998C40.3479 47.9567 40.4121 47.9065 40.4841 47.8502C40.7001 47.6813 40.9855 47.458 41.2658 47.2127L41.2759 47.204L41.2853 47.1946L49.3557 39.1242C49.9328 38.5937 50.4536 37.9283 50.8755 37.272L50.8859 37.2559L50.8946 37.2389L51.133 36.7761C51.3621 38.1872 51.5871 40.087 51.5538 41.0566C51.2418 48.929 44.8775 55.4687 37.0536 55.9578C32.7404 56.2246 28.6495 54.7142 25.488 51.7759C22.374 48.8393 20.5953 44.7468 20.5953 40.4779C20.5953 39.0466 20.9854 37.6024 21.6477 36.2318C22.1366 35.2996 22.7501 34.3844 23.5271 33.6505L23.5272 33.6506L23.5322 33.6457L31.8824 25.4778L31.8825 25.4778L31.8856 25.4747L32.1069 25.2533C33.0425 24.4055 34.2767 23.8503 35.7329 23.7646C37.8113 23.6423 39.8503 24.3234 41.4212 25.6605Z'
        fill='white'
        stroke='#0065FC'
        strokeWidth='0.8'
      />
    </svg>
  </Wrapper>
);
