import React, { useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useConnectWallet } from '@web3-onboard/react';
import { useWalletUtils } from '../../../hooks/useWalletUtils';

import { SignMessage } from '../../../constants/sign-messages';

import { InputAlikeButton } from '../../ui/buttons/InputAlikeButton';
import { ConnectedWalletItem } from './ConnectedWalletItem/ConnectedWalletItem';
import { Icon } from '../../../assets';
import { ConfirmRemoveWalletModal } from './ConfirmRemoveWalletModal';

import Styled from './ConnectWalletButton.styles';
import { useRequest } from '../../../hooks/useRequest';

export interface WalletData {
  address: string;
  signature: string;
}
interface Props {
  buttonText?: string;
  currentWallet?: boolean;
  addresses: string[];
  error?: string | boolean | FieldError | null;
  signMessage?: SignMessage;
  isLoading?: boolean;
  disableRemoveLastWallet?: boolean;
  onWalletConnect?: (walletData: WalletData) => void;
  onRemove?: (address: string) => void;
}

export const ConnectWalletButton: React.FC<Props> = ({
  onWalletConnect,
  onRemove,
  error,
  currentWallet,
  addresses,
  signMessage,
  isLoading,
  disableRemoveLastWallet = true,
  buttonText,
}) => {
  const { t } = useTranslation();

  const [{ wallet, connecting }] = useConnectWallet();
  const [showInfo, setShowInfo] = useState<boolean>();
  const [removing, setRemoving] = useState<string | null>(null);
  const [addressToRemove, setAddressToRemove] = useState<string | null>(null);
  const { signMessage: signMessageFn } = useWalletUtils();

  useEffect(() => {
    if (!isLoading) setRemoving(null);
  }, [isLoading]);

  const handleConnect = async () => {
    const { signature, address } = await signMessageFn(
      signMessage ||
        SignMessage.VerifyAccountOwnership ||
        SignMessage.VerifyProjectOwnership
    );

    typeof onWalletConnect === 'function' && onWalletConnect({ address, signature });
  };

  useEffect(() => {
    if (removing && connecting) setRemoving(null);
  }, [connecting, removing]);

  useEffect(() => {
    if (typeof showInfo === 'boolean') return;
    if (connecting) {
      setShowInfo(true);
    }
    setTimeout(() => {
      setShowInfo(false);
    }, 12000);
  }, [connecting, showInfo]);

  const handleRemove = (address: string) => {
    typeof onRemove === 'function' && onRemove(address);
    setRemoving(address);
    setAddressToRemove(null);
  };

  const { call: callConnect, isLoading: isProcessing } = useRequest(handleConnect);

  return (
    <>
      <Styled.Wrapper
        error={
          typeof error === 'boolean' || typeof error === 'string' ? error : error?.message
        }
      >
        {(!!addresses.length || (currentWallet && !!wallet)) && (
          <Styled.SmallText>
            {t(currentWallet ? 'auth.connectedWallet' : 'auth.connectedWallets')}{' '}
            <Styled.InfoPopup
              isOpen={showInfo}
              icon={
                <span>
                  <Icon.QuestionMarkCirclePrimary />
                </span>
              }
              text={t('auth.connectWalletInfo')}
              primary
              menuOffsetTop={-80}
            />
          </Styled.SmallText>
        )}
        {!currentWallet &&
          addresses.map(address => (
            <ConnectedWalletItem
              key={address}
              address={address}
              isRemoving={removing === address}
              onRemove={() => setAddressToRemove(address)}
            />
          ))}

        {currentWallet && wallet && (
          <ConnectedWalletItem address={wallet.accounts[0].address} />
        )}

        <InputAlikeButton onClick={callConnect} type='button' isLoading={isProcessing}>
          {buttonText || t('auth.connectWallet')}
        </InputAlikeButton>
      </Styled.Wrapper>
      {addressToRemove && (
        <ConfirmRemoveWalletModal
          onConfirm={() => {
            handleRemove(addressToRemove);
          }}
          onCancel={() => setAddressToRemove(null)}
          address={addressToRemove}
        />
      )}
    </>
  );
};
