import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ButtonPrimary, ButtonVariant } from '../../ui/buttons';

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmButtons: React.FC<Props> = ({
  onConfirm,
  onCancel,
  ...otherProps
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper {...otherProps}>
      <ButtonPrimary
        onClick={e => {
          e.stopPropagation();
          onCancel();
        }}
      >
        {t('confirm.noCancel')}
      </ButtonPrimary>
      <ButtonPrimary
        variant={ButtonVariant.Outlined}
        onClick={e => {
          e.stopPropagation();
          onConfirm();
        }}
      >
        {t('confirm.yesDelete')}
      </ButtonPrimary>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  gap: 12,
}));
