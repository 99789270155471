import React from 'react';
import { FormFieldWrapper } from '../FormFieldWrapper/FormFieldWrapper';
import { TextareaBase } from './TextareaBase';

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  error?: boolean | string | Error;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  width?: string | number;
}

export const TextareaField = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ label, error, labelProps, width = '100%', ...textareaProps }, ref) => {
    return (
      <FormFieldWrapper label={label} error={error} {...labelProps}>
        <TextareaBase ref={ref} width={width} error={!!error} {...textareaProps} />
      </FormFieldWrapper>
    );
  }
);
