import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useReputationWithAddress } from '../../../providers/AddressProvider/useReputationWithAddress';

import { CheckSvg } from './CheckSvg';
import { InfoPopup } from '../../ui/modals/InfoPopup/InfoPopup';

export const IsVerified: React.FC = () => {
  const { t } = useTranslation('analysisResult');
  const { data } = useReputationWithAddress();

  if (!data) return null;

  return (
    <Popup
      icon={
        <IconWrapper>
          <CheckSvg />
          <span>{t('verified')}</span>
        </IconWrapper>
      }
      text={t('isVerified', { type: data?.isContract ? 'contract ' : '' })}
      primary
      width={220}
      menuOffsetLeft={-20}
      menuOffsetTop={36}
    />
  );
};

const Popup = styled(InfoPopup)(({ theme }) => ({
  position: 'absolute',
  top: 6,
  right: -108,
  color: theme.colors.primary.main,
  [theme.max(1400)]: {
    top: 5,
  },
  [theme.max(theme.breakpoints.lg)]: {
    top: 2,
  },
  [theme.max(theme.breakpoints.sm)]: {
    top: 1,
    right: -96,
  },
  [theme.max(theme.breakpoints.xs)]: {
    bottom: -28,
    top: 'auto',
    right: '50%',
    transform: 'translateX(48%)',
  },
}));

const IconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  '& span': {
    color: theme.colors.primary.main,
    fontSize: 16,
  },
  '& svg path': {
    fill: `${theme.colors.primary.main} !important`,
  },
  '&:hover': {
    '& svg path': {
      fill: '#fff !important',
    },
  },
  [theme.max(theme.breakpoints.sm)]: {
    '& svg': {
      height: 20,
    },
    '& span': {
      fontSize: 14,
    },
    gap: 6,
  },
  [theme.betweenDM]: {
    '& svg': {
      height: theme.pxToVw(24),
      width: 'auto',
    },
    '& span': {
      fontSize: theme.pxToVw(16),
    },
    gap: theme.pxToVw(6),
  },
}));
