import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { FormProvider } from 'react-hook-form';

import { ModeProvider } from '../../providers/ModeProvider';
import { StepProvider, useStep } from '../../providers/StepProvider';
import { useProjectDetailsForm } from '../../components/project/project-form-components/useProjectDetailsForm';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';

import { AuthLayout } from '../../components/layouts/AuthLayout/AuthLayout';
import { ProjectOnboardWizzardProgress } from '../../components/project/ProjectOnboardWizzardProgress/ProjectOnboardWizzardProgress';
import { Form } from '../../components/forms/Form';
import { EditProjectDetailsForm } from './components/EditProjectDetailsForm';
import { NewProjectForm } from './components/NewProjectForm';
import { ProjectOnboardError } from './components/ProjectOnboardError';
import { ProjectOnboardActionButtons } from './components/ProjectOnboardActionButtons';

import { UrlService } from '../../services/UrlService';
import { UserApi } from '../../api/UserApi/UserApi';

export const ProjectOnboardPage: React.FC = () => {
  const { t } = useTranslation();

  useDocumentTitle(t('projectOnboard.pageTitle'));

  return (
    <StepProvider initialStep={1}>
      <ModeProvider mode='create'>
        <Content />
      </ModeProvider>
    </StepProvider>
  );
};

const Content = () => {
  const { t } = useTranslation();
  const { step } = useStep();
  const [error, setError] = useState<string>();
  const methods = useProjectDetailsForm();
  const contractAddress = UrlService.getSearchParam('contractAddress');

  const { data: metadata, isLoading } = useQuery(
    ['ContractMetadata', contractAddress],
    () => UserApi.getProjectMetadata(contractAddress!!),
    {
      enabled: !!contractAddress,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
      onError: (err: any) => {
        const msg = err?.response?.data?.message;

        const errTypes = [
          'INCORRECT_ADDRESS_LENGTH',
          'ADDRESS_IS_NOT_CONTRACT',
          'PROJECT_ALREADY_CONNECTED',
          'INTERNAL_SERVER_ERROR',
        ];

        const finalMsg = errTypes.includes(msg) ? msg : 'default';
        const errText = t(`errors.${finalMsg}`);

        setError(errText);
      },
    }
  );

  const renderContent = () => {
    if (error) return <ProjectOnboardError message={error} />;

    switch (step) {
      case 1:
        return <NewProjectForm />;
      case 2:
        return <EditProjectDetailsForm />;
      default:
        return null;
    }
  };

  const { reset } = methods;

  useEffect(() => {
    if (!metadata) return;
    reset(metadata);
  }, [metadata, reset]);

  return (
    <AuthLayout
      title={t('projectOnboard.newProject')}
      Wizzard={<ProjectOnboardWizzardProgress />}
    >
      <FormProvider {...methods}>
        <Form isLoading={isLoading}>
          {renderContent()}
          {metadata && !error && <ProjectOnboardActionButtons />}
        </Form>
      </FormProvider>
    </AuthLayout>
  );
};
