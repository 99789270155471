import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { useRequest } from '../../../../hooks/useRequest';

import { NotificationApi } from '../../../../api/UserApi/NotificationApi';
import { QueryKey } from '../../../../react-query/query-keys';

import {
  DropdownMenuBase,
  DropdownMenuVariant,
} from '../../../ui/menus/DopdownMenu/DropdownMenuBase';

import { ReactComponent as DotsSvg } from '../svgs/Dots.svg';

interface Props {
  notificationId: number;
  isRead: boolean;
}

export const NotificationItemOptions: React.FC<Props> = ({ isRead, notificationId }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const onChange = async () => {
    await NotificationApi.patchRead(notificationId, !isRead);
    await Promise.all([
      queryClient.invalidateQueries('Notifications'),
      queryClient.invalidateQueries(QueryKey.NotificationsCount()),
    ]);
  };

  const { call, isLoading } = useRequest(onChange);

  return (
    <DropdownMenuBase
      isOpen={open}
      onToggle={() => setOpen(prev => !prev)}
      menuVariant={DropdownMenuVariant.Secondary}
      Trigger={<DotsSvg style={{ cursor: isLoading ? 'wait' : 'pointer' }} />}
      menuItems={[
        {
          label: t(`notifications.markAs${isRead ? 'Undread' : 'Read'}`),
          value: 1,
          onClick: () => !isLoading && call(!isRead),
        },
      ]}
      menuStyle={{ transform: 'translate(-40px, -90px)' }}
    />
  );
};
