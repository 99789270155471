import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ActionText } from '../../../../ui/text/ActionText';

import { envs } from '../../../../../config/envs';
import { FOOTER_MOBILE_BREAKPOINT_WIDTH } from '../../Footer';

export const FooterLinksSection: React.FC = () => {
  const { t } = useTranslation('common');
  return (
    <Wrapper>
      <ActionText onClick={() => window.open(envs.extensionUrl, '_blank')}>
        {t('installExtension')}
      </ActionText>
      <ActionText onClick={() => window.open(envs.blockemWebsiteUrl, '_blank')}>
        {t('moreAboutBlockem')}
      </ActionText>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 20,
  fontSize: '1.6rem',
  [theme.betweenDM]: {
    gap: theme.pxToVw(20),
    marginBottom: 0,
    fontSize: theme.pxToVw(16),
  },
  [theme.max(FOOTER_MOBILE_BREAKPOINT_WIDTH)]: {
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 16,
    gap: 12,
    marginBottom: 28,
  },
}));
