import styled from 'styled-components';
import { BaseCard } from '../../cards/BaseCard';
import { Heading4 } from '../../text/Heading';
import { ReactComponent as CloseSvg } from './XMark.svg';

interface P {
  background?: string;
}

const Container = styled(BaseCard)<P>(({ theme, background = '#3D4859' }) => ({
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  padding: '48px 40px 40px 40px',
  position: 'relative',
  maxHeight: '90vh',
  maxWidth: '90vw',
  background,
  [theme.betweenDM]: {
    padding: `${theme.pxToVw(48)} ${theme.pxToVw(40)} ${theme.pxToVw(40)} ${theme.pxToVw(
      40
    )}`,
  },
  [theme.max(theme.breakpoints.sm)]: {
    padding: '32px 24px',
  },
  [theme.max(theme.breakpoints.xs)]: {
    padding: '24px 16px',
  },
}));

const Content = styled('div')<P>(({ theme }) => ({
  width: '100%',
}));

const Header = styled('header')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 20,
  [theme.betweenDM]: {
    marginBottom: theme.pxToVw(20),
  },
}));

const Title = styled(Heading4)(({ theme }) => ({
  fontWeight: 700,
  width: '100%',
  color: theme.colors.text.primary,
}));

const CloseIconWrapper = styled('div')(({ theme }) => ({
  marginLeft: 'auto',
  transition: 'all .3s',
  opacity: 1,
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.85,
  },
}));

const CloseIcon = styled(CloseSvg)(({ theme }) => ({
  height: 16,
  width: 16,
  pointerEvents: 'none',
  [theme.betweenDM]: {
    height: theme.pxToVw(16),
    width: theme.pxToVw(16),
  },
}));

const Styled = { Container, Title, Header, Content, CloseIcon, CloseIconWrapper };

export default Styled;
