import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import {
  AddressProvider,
  useAddress,
} from '../../providers/AddressProvider/AddressProvider';
import { useReputationWithAddress } from '../../providers/AddressProvider/useReputationWithAddress';

import { UrlService } from '../../services/UrlService';

import { MainSection } from './sections/Main/MainSection';
import { ReputationResultSection } from './sections/ReputationResult/ReputationResultSection';
import { DigitalOwnershipSection } from './sections/DigitalOwnership/DigitalOwnershipSection';
import { AnalyticsEvent, AnalyticsService } from '../../services/AnalyticsService';
import { NewsFeedSection } from './sections/NewsFeed/NewsFeedSection';
import { BannerSection } from './sections/Banner/BannerSection';

export const HomePageComponent: React.FC = () => {
  const { t } = useTranslation('homePage');
  const { setAddress } = useAddress();
  const { data } = useReputationWithAddress();

  useDocumentTitle(t('pageTitles.root'));

  useEffect(() => {
    const address = UrlService.getSearchParam('address')?.trim()?.toLowerCase();
    if (address) setAddress(decodeURIComponent(address));
  }, [setAddress]);

  useEffect(() => {
    if (!data) return;
    const address = decodeURIComponent(
      UrlService.getSearchParam('address')?.trim()?.toLowerCase() || ''
    );
    const fromParam = decodeURIComponent(UrlService.getSearchParam('from') || '');

    const action = fromParam
      ? `${AnalyticsEvent.RedirectAnalysis}${fromParam ? '-' + fromParam : ''}`
      : AnalyticsEvent.ManualAnalysis;

    AnalyticsService.userEvent({
      action,
      label: address,
      value: data.trustPercentage,
    });
  }, [data]);

  return (
    <>
      <MainSection />
      <ReputationResultSection />
      <BannerSection />
      <NewsFeedSection />
      <DigitalOwnershipSection />
    </>
  );
};

export const HomePage: React.FC = () => (
  <AddressProvider>
    <HomePageComponent />
  </AddressProvider>
);
