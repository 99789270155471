import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { useProfileUtils } from '../../hooks/useProfileUtils';
import { useRequest } from '../../../../hooks/useRequest';
import { useProfileByParamId } from '../../../../react-query/profile/useProfile';
import { useFollowUtils } from '../../../../react-query/user/useFollowUtils';
import { useAuth } from '../../../../providers/AuthProvider';
import { useAppMessage } from '../../../../providers/AppMessageProvider/AppMessageProvider';

import { LoadingKey, useLoading } from '../../../../providers/LoadingProvider';

import { UserApi } from '../../../../api/UserApi/UserApi';

import {
  ButtonPrimary,
  ButtonSize,
  ButtonVariant,
} from '../../../../components/ui/buttons';
import { Spinner } from '../../../../components/ui/loaders/Spinner';
import { Icon } from '../../../../assets';
import { AppMessage } from '../../../../types';
import { QueryKey } from '../../../../react-query/query-keys';

export const FollowAllButton: React.FC = ({ ...otherProps }) => {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { setQueriesOnUnfollow, setQueriesOnFollow } = useFollowUtils();

  const { isOwner, followedProfileAddresses, isClaimedAddress } = useProfileUtils();
  const { data: profile } = useProfileByParamId();
  const { isAuthenticated } = useAuth();
  const { setLoading } = useLoading();
  const { toast } = useAppMessage();

  const areAllFollwed =
    profile &&
    followedProfileAddresses &&
    profile.addresses.every(address =>
      followedProfileAddresses.some(add => add === address)
    );

  const onClick = async () => {
    if (!profile) return;

    if (!isAuthenticated) return toast(AppMessage.RegisterToFollow);

    const param = profile.uid || profile.addresses[0];

    if (!param) return;

    if (areAllFollwed) {
      await UserApi.unfollow(param);
    } else {
      await UserApi.follow(param);
    }

    const invalidate = areAllFollwed ? setQueriesOnUnfollow : setQueriesOnFollow;
    // TODO
    if (areAllFollwed) {
      await setQueriesOnUnfollow({
        userUid: profile.uid || undefined,
        address: !profile.uid ? profile.addresses[0] : undefined,
      });
    } else {
      await setQueriesOnFollow({
        userUid: profile.uid || undefined,
        address: !profile.uid ? profile.addresses[0] : undefined,
      });
    }

    await Promise.all([
      invalidate({
        userUid: profile.uid || undefined,
        address: !profile.uid ? profile.addresses[0] : undefined,
      }),
      queryClient.invalidateQueries(QueryKey.ProfileFollowers(profile.uid), {
        refetchInactive: true,
      }),
    ]);
  };

  const { call, isLoading } = useRequest(onClick, {
    onStart: () => {
      setLoading(LoadingKey.FollowAll, true);
    },
    onSettled: () => {
      setLoading(LoadingKey.FollowAll, false);
    },
  });

  if (isOwner) return null;

  const icon = isLoading ? <Spinner size={12} /> : <Icon.Plus />;

  return (
    <Button
      onClick={call}
      disabled={isLoading}
      isClaimed={!!isClaimedAddress}
      variant={areAllFollwed ? ButtonVariant.Outlined : ButtonVariant.Filled}
      size={ButtonSize.Sm}
      isLoading={!!areAllFollwed && !!isLoading}
      {...otherProps}
    >
      {areAllFollwed ? (
        <>{[t(isClaimedAddress ? 'unfollowAllAddresses' : 'unfollow')]}</>
      ) : (
        <>
          {icon}
          {t(isClaimedAddress ? 'followAllAddresses' : 'follow')}
        </>
      )}
    </Button>
  );
};

const Button = styled(ButtonPrimary)<{ isClaimed?: boolean }>(({ theme, isClaimed }) => ({
  fontSize: 14,
  width: isClaimed ? 212 : 120,
  '& svg': {
    marginRight: 8,
  },
  [theme.min(theme.breakpoints.sm)]: {
    width: isClaimed ? 220 : 136,
  },
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(14),
    '& svg': {
      marginRight: theme.pxToVw(8),
    },
    width: isClaimed ? theme.pxToVw(220) : theme.pxToVw(136),
  },
}));
