import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { StorageKeys, StorageService } from '../services/StorageService';

export interface IAuthContext {
  isAuthenticated: boolean;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
}

const AuthContext = React.createContext<IAuthContext | undefined>(undefined);

export const useAuth = (): IAuthContext => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth cannot be used without AuthContext');
  }

  return context;
};

interface Props {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    StorageService.has(StorageKeys.Token)
  );

  return (
    <AuthContext.Provider value={{ setIsAuthenticated, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
