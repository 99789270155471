import React from 'react';
import styled from 'styled-components';

import {
  isNonNftProjectError,
  useProjectByParamId,
} from '../../../../../react-query/project/useProject';
import { useWindowSizeUtils } from '../../../../../hooks/useWindowSizeUtils';

import { ProfileImageComponent } from '../../../../../components/profile/ProfileImage';
import { ProjectNameAndDetails } from './ProjectNameAndDetails/ProjectNameAndDetails';
import { ProjectDetails } from './ProjectDetails/ProjectDetails';
import { ProjectTrustedBy } from './TrustedBy/ProjectTrustedBy';
import { ProfileDescription } from '../../../../../components/profile/ProfileDescription';
import { EditProjectActions } from './EditAndFollowProject/EditProjectActions';
import { NonNftProjectContent } from './NonNftProjectContent/NonNftProjectContent';

export const ProjectInfo: React.FC = () => {
  const { data: project, isLoading, error } = useProjectByParamId();
  const { isSm, isXs, isMd } = useWindowSizeUtils();

  const isNonNft = isNonNftProjectError(error);

  return (
    <Wrapper>
      <Wrapper2 isNonNft={isNonNft}>
        <ProfileImageComponent photoUrl={isNonNft ? null : project?.photoUrl} />
        <ProjectNameAndDetails />
      </Wrapper2>
      {!isNonNft ? (
        <>
          <ActionsWrapper>
            <EditProjectActions />
          </ActionsWrapper>
          <ProjectDetails />
          <ProjectTrustedBy />
          <Description text={project?.description || ''} isLoading={isLoading || !project} />
        </>
      ) : isXs || isSm || isMd ? null : (
        <NonNftProjectContent />
      )}
    </Wrapper>
  );
};

const Col = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [theme.min(theme.breakpoints.md)]: {
    maxWidth: 600,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    maxWidth: theme.pxToVw(750),
  },
  [theme.min(theme.breakpoints.max)]: {
    maxWidth: 750,
  },
}));

const Wrapper2 = styled(Col)<{ isNonNft: boolean }>(({ theme, isNonNft }) => ({
  gap: 20,
  [theme.min(theme.breakpoints.sm)]: {
    flexDirection: 'row',
    alignItems: isNonNft ? 'center' : 'flex-end',
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(20),
  },
}));

const Wrapper = styled(Col)(({ theme }) => ({
  gap: 20,
  [theme.betweenDM]: {
    gap: theme.pxToVw(20),
  },
}));

const ActionsWrapper = styled('div')(({ theme }) => ({
  [theme.min(theme.breakpoints.md)]: {
    display: 'none',
  },
}));

const Description = styled(ProfileDescription)(({ theme }) => ({
  maxWidth: 460,
  [theme.min(theme.breakpoints.md)]: {
    maxWidth: 400,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    maxWidth: theme.pxToVw(750),
  },

  [theme.min(1920)]: {
    maxWidth: 750,
  },
}));
