import { useQuery } from 'react-query';
import { ProjectApi } from '../../api/ProjectApi/ProjectApi';

import { QueryKey } from '../query-keys';

export const useProjectFollowersCount = (projectId: string | null | undefined) => {
  return useQuery(
    QueryKey.ProjectFollowersCount(projectId!),
    () => ProjectApi.getFollowersCount(projectId!),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: !!projectId,
    }
  );
};
