import React from 'react';
import styled from 'styled-components';
import { ActionText, ActionTextVariant } from '../../../components/ui/text/ActionText';

const Text = styled('p')<{ align?: 'left' | 'center' | 'right' }>(
  ({ theme, align = 'left' }) => ({
    textAlign: align,
    fontSize: 14,
    fontWeight: 600,
    padding: '5px 0',
    [theme.betweenDM]: {
      fontSize: theme.pxToVw(14),
      padding: `${theme.pxToVw(5)} 0`,
    },
  })
);

interface Props {
  align?: 'left' | 'center' | 'right';
  text: string;
  actionText?: string;
  underline?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export const TextWithActionConcated: React.FC<Props> = ({
  text,
  actionText,
  onClick,
  underline,
  ...textProps
}) => (
  <Text {...textProps}>
    {text}{' '}
    {onClick && actionText && (
      <ActionText
        variant={ActionTextVariant.Inverted}
        onClick={onClick}
        underline={!!underline}
      >
        {actionText}
      </ActionText>
    )}
  </Text>
);
