import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useProfileUtils } from '../../../hooks/useProfileUtils';
import { useProfileFollowersCount } from '../../../../../react-query/profile/useFollowersTotalCount';
import { useProfileFollowingsCount } from '../../../../../react-query/profile/useFollowingsTotalCount';
import { useAuth } from '../../../../../providers/AuthProvider';
import { useAppMessage } from '../../../../../providers/AppMessageProvider/AppMessageProvider';

import { AppMessage } from '../../../../../types';

import { ReactComponent as FollowIcon } from './FollowIcon.svg';
import { FollowersModal } from './FollowersModal';
import { FollowingModal } from './FollowingModal';
import { Spinner } from '../../../../../components/ui/loaders/Spinner';

export const ProfileInfoFollowers: React.FC = () => {
  const { t } = useTranslation('common');

  const { data: followersCount } = useProfileFollowersCount();
  const { data: followingCount } = useProfileFollowingsCount();
  const { isAuthenticated } = useAuth();
  const { toast } = useAppMessage();

  const { isClaimedAddress } = useProfileUtils();

  const [followersOpen, setFollowersOpen] = useState(false);
  const [followingsOpen, setFollowingsOpen] = useState(false);

  const onOpenFollowers = () => {
    if (!isAuthenticated) return toast(AppMessage.RegisterToSeeFollowers);
    if (!followersCount) return;
    setFollowersOpen(true);
  };

  const onOpenFollowings = () => {
    if (!isAuthenticated) return toast(AppMessage.RegisterToSeeFollowers);
    if (!followingCount) return;
    setFollowingsOpen(true);
  };

  const isLoading = isClaimedAddress
    ? typeof followersCount === 'undefined' || typeof followingCount === 'undefined'
    : typeof followersCount === 'undefined';

  return (
    <>
      <Wrapper>
        {isLoading ? (
          <>
            <FollowIcon />
            <Spinner size={12} />
          </>
        ) : (
          <>
            <Item onClick={onOpenFollowers}>
              <FollowIcon />
              <span>{`${followersCount} ${t('followers')}`}</span>
            </Item>
            {isClaimedAddress && (
              <Item onClick={onOpenFollowings}>
                <span>{`${followingCount} ${t('following')}`}</span>
              </Item>
            )}
          </>
        )}
      </Wrapper>
      {followersOpen && <FollowersModal onClose={() => setFollowersOpen(false)} />}
      {followingsOpen && <FollowingModal onClose={() => setFollowingsOpen(false)} />}
    </>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 24,
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 14,
  [theme.min(theme.breakpoints.md)]: {
    fontSize: 16,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(20),
    gap: theme.pxToVw(24),
    marginBottom: theme.pxToVw(14),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 20,
    gap: 24,
    marginBottom: 14,
  },
}));

const Item = styled('span')(({ theme }) => ({
  color: theme.colors.text.whiteTransparent70,
  transition: 'all .3s',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  gap: 8,
  wordBreak: 'keep-all',
  '& span': {
    minWidth: 'max-content',
  },
  '& svg': {
    height: 14,
    width: 'auto',
    [theme.min(theme.breakpoints.xl)]: {
      height: 16,
    },
    [theme.min(theme.breakpoints.desktop)]: {
      height: theme.pxToVw(18),
    },
    [theme.min(theme.breakpoints.max)]: {
      height: 18,
    },
  },
  '&:hover': {
    color: '#fff',
  },
}));
