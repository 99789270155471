import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useReputationWithAddress } from '../../../../../../providers/AddressProvider/useReputationWithAddress';
import { useTrustCategory } from '../../../../../../components/common/AnalysisResult/TrustCategoryProvider';

import { splitStringNewLineBreak } from '../../../../../../utils/string-utils';
import { LoadingBackground } from '../../../../../../components/ui/loaders/LoadingBackground';
import { Text } from '../../../../../../components/ui/text/Text';

export const ProjectReputationText: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useReputationWithAddress();
  const { trust } = useTrustCategory();

  if (!data || !trust)
    return (
      <Col>
        <LoadingBg>
          <TextEl>l</TextEl>
        </LoadingBg>
        <LoadingBg>
          <TextEl>l</TextEl>
        </LoadingBg>
        <LoadingBg>
          <TextEl>l</TextEl>
        </LoadingBg>
      </Col>
    );

  return (
    <TextEl>
      {splitStringNewLineBreak(
        t(`analysisResult.summary.${trust}.text`, {
          type: t(`common.${data.isContract ? 'contract' : 'address'}`).toLowerCase(),
        })
      )}
    </TextEl>
  );
};

const TextEl = styled(Text.MD)(({ theme }) => ({
  fontWeight: 600,
  linHeight: 1.6,
  color: theme.colors.text.secondary,
  marginTop: 12,
  marginBottom: 18,
  [theme.min(theme.breakpoints.desktop)]: {
    marginTop: theme.pxToVw(12),
    marginBottom: theme.pxToVw(48),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginTop: 12,
    marginBottom: 48,
  },
}));

const LoadingBg = styled(LoadingBackground)(({ theme }) => ({
  '&:nth-child(1)': {
    width: '100%',
  },
  '&:nth-child(2)': {
    width: '80%',
  },
  '&:nth-child(3)': {
    width: '92%',
  },
  '&:not(:last-child)': {
    marginBottom: 6,
    [theme.betweenDM]: {
      marginBottom: theme.pxToVw(6),
    },
  },
  '& > p': {
    margin: '0 !important',
    color: 'transparent !important',
  },
}));

const Col = styled('div')(({ theme }) => ({
  marginTop: 12,
  marginBottom: 18,
  [theme.min(theme.breakpoints.max)]: {
    marginTop: theme.pxToVw(12),
    marginBottom: theme.pxToVw(48),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginTop: 12,
    marginBottom: 48,
  },
}));
