import styled from 'styled-components';

import { useWhere } from '../../../providers/WhereProvider';

interface TextProps {
  isPopupView?: boolean;
  light?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
}

export const TextBase = styled('span')<TextProps>(
  ({ theme, light, onClick, isLoading, disabled }) => ({
    fontSize: 14,
    fontWeight: light ? 600 : 700,
    lineHeight: 1.3,
    color: '#fff',
    opacity: light || isLoading || disabled ? 0.7 : 1,
    transition: 'all .3s',
    cursor: isLoading ? 'wait' : typeof onClick === 'function' ? 'pointer' : 'auto',
    [theme.min(theme.breakpoints.sm)]: {
      fontSize: useWhere() === 'popup' ? 14 : 16,
    },
    [theme.betweenDM]: {
      fontSize: useWhere() === 'popup' ? theme.pxToVw(14) : theme.pxToVw(16),
    },
    '&:hover': {
      color: typeof onClick === 'function' ? theme.colors.primary.main : '#fff',
    },
  })
);

export const NotificationErrMsg = styled(TextBase)(({ theme }) => ({
  color: theme.colors.status.error,
}));
