import { axiosInstance } from '../../config/axios';
import { Milestone, MilestoneGoal } from '../../types';
import { apiRoutes } from '../routes';

interface GoalApi {
  uid: string;
  title: string;
  description: string;
  is_fulfilled: boolean;
  related_url: string | null;
  document_urls: string[] | null;
}

interface MilestoneApi {
  uid: string;
  order_number: number;
  order_string: string;
  title: string;
  goals: GoalApi[];
}

type GetProjectMilestonesResponseApi = MilestoneApi[];

export interface PostProjectMilestonePayload {
  projectUid: string;
  payload: { title: string; prevOrderString: string; goals: Omit<MilestoneGoal, 'id'>[] };
}

export interface PostMilestoneGoal {
  milestoneUid: string;
  payload: Omit<MilestoneGoal, 'uid'>;
}

type PostMilestoneGoalPayloadApi = Omit<GoalApi, 'uid'>;

interface PostProjectMilestonePayloadApi {
  projectUid: string;
  payload: { title: string; prev_order_string: string; goals: Omit<GoalApi, 'uid'>[] };
}

export interface PatchProjectMilestone {
  projectUid: string;
  milestoneUid: string;
  payload: { title: string };
}

export interface PatchReorderProjectMilestone {
  projectUid: string;
  milestoneUid: string;
  payload: { prevOrderString: string; nextOrderString: string };
}

export interface PatchReorderProjectMilestoneGoal {
  projectUid: string;
  milestoneUid: string;
  goalUid: string;
  payload: Partial<MilestoneGoal>;
}

class ProjectMilestonesApiConfig {
  apiMapper = {
    goal: (goal: GoalApi): MilestoneGoal => {
      const { is_fulfilled, related_url, document_urls, ...rest } = goal;
      return {
        isFulfilled: is_fulfilled,
        relatedUrl: related_url,
        documentUrls: document_urls,
        ...rest,
      };
    },

    milestone: (milestoneApi: MilestoneApi): Milestone => {
      const { uid, order_number, order_string, title, goals } = milestoneApi;
      return {
        uid,
        orderNumber: order_number,
        orderString: order_string,
        title,
        goals: goals.map(this.apiMapper.goal),
      };
    },

    goalToApi: (goal: PostMilestoneGoal['payload']): PostMilestoneGoalPayloadApi => {
      const { isFulfilled, documentUrls, relatedUrl, ...rest } = goal;

      return {
        is_fulfilled: isFulfilled,
        document_urls: documentUrls,
        related_url: relatedUrl,
        ...rest,
      };
    },

    postMilestoneToApi: (
      payload: PostProjectMilestonePayload['payload']
    ): PostProjectMilestonePayloadApi['payload'] => {
      const { title, prevOrderString, goals } = payload;

      payload.goals.forEach(g => {
        delete g.uid;
      });

      return {
        title,
        prev_order_string: prevOrderString,
        goals: goals.map(this.apiMapper.goalToApi),
      };
    },
  };

  async getMilestone(projectUid: string) {
    const { data } = await axiosInstance.get<GetProjectMilestonesResponseApi>(
      apiRoutes.user.project.milestone(projectUid)
    );

    return (data || []).map(this.apiMapper.milestone);
  }

  postMilestone = async ({ projectUid, payload }: PostProjectMilestonePayload) => {
    const { data } = await axiosInstance.post(
      '/user' + apiRoutes.user.project.milestone(projectUid),
      this.apiMapper.postMilestoneToApi(payload)
    );

    return this.apiMapper.milestone(data);
  };

  postMilestoneGoal = async ({ milestoneUid, payload }: PostMilestoneGoal) => {
    const { data } = await axiosInstance.post(
      apiRoutes.user.project.milestoneGoal(milestoneUid),
      this.apiMapper.goalToApi(payload)
    );

    return this.apiMapper.goal(data);
  };

  async patchProjectMilestone({
    projectUid,
    milestoneUid,
    payload,
  }: PatchProjectMilestone) {
    const { title } = payload;
    await axiosInstance.patch(
      apiRoutes.user.project.projectMilestoneById(projectUid, milestoneUid),
      {
        title,
      }
    );
  }

  async patchMilestoneReorder({
    projectUid,
    milestoneUid,
    payload,
  }: PatchReorderProjectMilestone) {
    const { prevOrderString, nextOrderString } = payload;
    await axiosInstance.patch(
      apiRoutes.user.project.milestoneReorder(projectUid, milestoneUid),
      {
        prev_order_string: prevOrderString,
        next_order_string: nextOrderString,
      }
    );
  }

  async patchProjectMilestoneGoal({
    projectUid,
    milestoneUid,
    goalUid,
    payload,
  }: PatchReorderProjectMilestoneGoal) {
    const { title, description, isFulfilled, relatedUrl, documentUrls } = payload;

    await axiosInstance.patch(
      apiRoutes.user.project.projectMilestoneGoalById(projectUid, milestoneUid, goalUid),
      {
        title,
        description,
        is_fulfilled: isFulfilled,
        related_url: relatedUrl,
        document_urls: documentUrls,
      }
    );
  }

  async deleteProjectMilestone({
    projectUid,
    milestoneUid,
  }: {
    projectUid: string;
    milestoneUid: string;
  }) {
    await axiosInstance.delete(
      apiRoutes.user.project.projectMilestoneById(projectUid, milestoneUid)
    );
  }

  async deleteProjectMilestoneGoal({
    projectUid,
    milestoneUid,
    goalUid,
  }: {
    projectUid: string;
    milestoneUid: string;
    goalUid: string;
  }) {
    await axiosInstance.delete(
      apiRoutes.user.project.projectMilestoneGoalById(projectUid, milestoneUid, goalUid)
    );
  }
}

export const ProjectMilestonesApi = new ProjectMilestonesApiConfig();
