import React, { useCallback, useContext, useState } from 'react';

export enum LoadingKey {
  FollowAll,
}

export interface ILoadingContext {
  loading: Partial<Record<LoadingKey, boolean>>;
  setLoading: (key: LoadingKey, isLoading: boolean) => void;
}

const LoadingContext = React.createContext<ILoadingContext | undefined>(undefined);

export const useLoading = (): ILoadingContext => {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error('useLoading cannot be used without LoadingContext');
  }

  return context;
};

interface Props {
  children: React.ReactNode;
}

export const LoadingProvider: React.FC<Props> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<ILoadingContext['loading']>({});

  const setLoading = useCallback((key: LoadingKey, loading: boolean) => {
    setIsLoading(prev => ({ ...prev, [key]: loading }));
  }, []);

  return (
    <LoadingContext.Provider value={{ loading: isLoading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};
