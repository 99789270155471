import { useLayoutEffect, useState } from 'react';

export const useWindowWidth = (): number => {
  const [windowWidth, setWindowWidth] = useState(0);

  useLayoutEffect(() => {
    function updateWindowWidth() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', updateWindowWidth);
    updateWindowWidth();

    return () => window.removeEventListener('resize', updateWindowWidth);
  }, []);

  return windowWidth;
};
