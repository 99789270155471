import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Container } from '../../../../../../components/layouts/Container';
import { Heading4 } from '../../../../../../components/ui/text/Heading';
import { useProjectMilestonesByParamId } from '../../../../../../react-query/user/project/useProjectMilestones';
import EditIcon from './EditIcon';
import RoadmapMilestone from './RoadmapMilestone/RoadmapMilestone';

export default function Roadmap() {
  const { data: milestones } = useProjectMilestonesByParamId();
  const [scrollX, setScrollX] = useState(0);

  const isPerfect = useMemo(() => {
    if (!milestones) return false;
    let isComplete = false;
    let result = true;

    for (let i = milestones.length - 1; i > 0; i--) {
      const milestone = milestones[i];

      if (milestone.goals.every(g => g.isFulfilled) && !isComplete) {
        isComplete = true;
      } else if (isComplete && milestone.goals.some(g => !g.isFulfilled)) {
        result = false;
        // this means that the milestones completion state is not ordered
        break;
      }
    }
    return result;
  }, [milestones]);

  if (!milestones) return null;

  return (
    <>
      <HeadingWrapper>
        <Heading4>Roadmap</Heading4>
        <EditIcon />
      </HeadingWrapper>
      <div style={{ position: 'relative' }}>
        <FadeInOnScroll scrollX={scrollX} />
        <Wrapper
          onScroll={e => {
            setScrollX(e.currentTarget.scrollLeft);
          }}
        >
          {milestones.map(milestone => (
            <RoadmapMilestone
              key={milestone.uid}
              milestone={milestone}
              isPerfect={isPerfect}
            />
          ))}
        </Wrapper>
      </div>
    </>
  );
}

const HeadingWrapper = styled(Container)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 23,
  gap: 16,
  position: 'relative',
  [theme.min(theme.breakpoints.desktop)]: {
    marginBottom: theme.pxToVw(43),
    gap: theme.pxToVw(20),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginBottom: 43,
    gap: 20,
  },
}));

const FadeInOnScroll = styled('div')<{ scrollX: number }>(({ theme, scrollX }) => ({
  background: 'linear-gradient(90deg, #000000 81.77%, rgba(0, 0, 0, 0) 100%)',
  position: 'absolute',
  top: 0,
  left: 10,
  bottom: 0,
  width: 200,
  display: 'block',
  content: '" "',
  zIndex: 4,
  transform: 'translateX(-82.5%)',
  transition: 'all .3s',
  opacity: scrollX > 10 ? 1 : 0,
  [theme.min(theme.breakpoints.xs)]: {
    left: '5%',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    left: '10.42%',
  },
  [theme.min(theme.breakpoints.max)]: {
    left: 'calc((100vw - 1520px) / 2)',
  },
}));

const Wrapper = styled('div')(({ theme, property }) => ({
  display: 'flex',
  marginLeft: 10,
  maxWidth: 'calc(100vw - 10px)',
  overflow: 'auto',
  marginBottom: 72,
  position: 'relative',
  paddingBottom: 16,
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  [theme.min(theme.breakpoints.xs)]: {
    marginLeft: '5%',
    maxWidth: '90vw',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    marginLeft: '10.42%',
    maxWidth: '89.48vw',
    marginBottom: theme.pxToVw(150),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginBottom: 150,
    marginLeft: 'calc((100vw - 1520px) / 2)',
  },
}));
