import React from 'react';
import { useTranslation } from 'react-i18next';

import { shortenEthAddress } from '../../../utils/address-utils';

import { ConfirmationModal } from '../../ui/modals/ConfirmationModal/ConfirmationModal';

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  address: string;
}

export const ConfirmRemoveWalletModal: React.FC<Props> = ({
  onConfirm,
  onCancel,
  address,
}) => {
  const { t } = useTranslation('confirm');
  const text = t('removeAddress.text', { returnObjects: true }) as string[];

  return (
    <ConfirmationModal
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={t('removeAddress.title')}
      description={
        <>
          {text[0]}{' '}
          <span>
            {shortenEthAddress(address, {
              startChars: 6,
              placeholderChar: '.',
              placeholderCount: 3,
            })}
          </span>{' '}
          {text[1]}
        </>
      }
    />
  );
};
