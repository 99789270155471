export const toValidDate = (dateValue: string | number | Date) => {
  const multiplier =
    typeof dateValue === 'number' && String(dateValue).length > 10 ? 1 : 1000;
  return typeof dateValue === 'string' || typeof dateValue === 'number'
    ? new Date(typeof dateValue === 'number' ? dateValue * multiplier : dateValue)
    : dateValue;
};

export const formatDateTimeToAmPm = (dateValue: string | number | Date) => {
  const date = toValidDate(dateValue);

  let hours = date.getHours();
  const minutes = date.getMinutes();

  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${formattedMinutes} ${ampm}`;
};

interface ToLocaleConfig {
  month?: boolean;
  year?: boolean;
  day?: boolean;
}

export const formatDateToLocale = (
  dateValue: string | number | Date,
  config?: ToLocaleConfig
): string => {
  const date = toValidDate(dateValue);

  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    year: 'numeric',
    day: '2-digit',
  };

  if (config?.day === false) delete options.day;

  return date.toLocaleDateString('en-US', options);
};

export const formatDate = (
  dateValue: string | number | Date,
  options?: Intl.DateTimeFormatOptions
) => {
  const date = toValidDate(dateValue);
  return date.toLocaleDateString('en-US', options);
};

export const calcDaysBetween = (
  fromDate: string | number | Date,
  toDate: string | number | Date
) => {
  const from = toValidDate(fromDate);
  const to = toValidDate(toDate);

  const difference = to.getTime() - from.getTime();
  const dayInMs = 1000 * 60 * 60 * 24;

  const differenceInDays = Math.round(difference / dayInMs);
  const absoluteNumber = Math.abs(differenceInDays);

  return absoluteNumber;
};

export const getDateBlocksByDayCount = (
  dates: (string | number | Date)[],
  daysCount: number
) => {
  const _dates = dates.map(toValidDate);
  const day1 = _dates[0];

  return _dates.reduce((mappedValues, currDate) => {
    const week = Math.ceil(calcDaysBetween(day1, currDate) / daysCount) || 1;

    if (!mappedValues[week]) mappedValues[week] = [];
    mappedValues[week].push(currDate);

    return mappedValues;
  }, {} as { [key: number]: Date[] });
};

export const getDateDaysAgo = (
  date: string | number | Date = new Date(),
  numOfDays: number
) => {
  const theDate = toValidDate(date);
  const daysAgo = new Date(theDate.getTime());

  daysAgo.setDate(theDate.getDate() - numOfDays);

  return daysAgo;
};

export const timeSinceFromNow = (date: string | number | Date) => {
  const seconds = Math.floor((new Date().getTime() - toValidDate(date).getTime()) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) return Math.floor(interval) + ' y';

  interval = seconds / 2592000;

  if (interval > 1) return Math.floor(interval) + ' mo';

  interval = seconds / 86400;

  if (interval > 1) return Math.floor(interval) + ' d';

  interval = seconds / 3600;

  if (interval > 1) return Math.floor(interval) + ' h';

  interval = seconds / 60;

  if (interval > 1) return Math.floor(interval) + ' min';

  return Math.floor(seconds) + ' s';
};
