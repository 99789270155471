import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../react-query/user/useUser';
import { useEditAccountForm } from './useEditAccountForm';
import { useUserEdit } from '../../react-query/user/useUserEdit';
import { useRequest } from '../../hooks/useRequest';
import { useInvalidateCurrentUserProfile } from '../profile/hooks/useInvalidateCurrentUserProfile';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';

import {
  ConnectAddressResponse,
  DisconnectAddressPayload,
  UserApi,
} from '../../api/UserApi/UserApi';

import { Icon } from '../../assets';
import {
  ConnectWalletButton,
  WalletData,
} from '../../components/common/ConnectWalletButton/ConnectWalletButton';
import { ButtonPrimary } from '../../components/ui/buttons';
import { ButtonSecondary } from '../../components/ui/buttons/ButtonSecondary';
import { InputField } from '../../components/ui/forms/Input/InputField';
import { PhoneInput } from '../../components/ui/forms/PhoneInput/PhoneInput';
import { ChangePasswordModal } from '../../components/common/ChangePasswordModal/ChangePasswordModal';
import { Spinner } from '../../components/ui/loaders/Spinner';
import { NotEditableInputPlaceholder } from '../../components/ui/forms/NotEditableInputPlaceholder/NotEditableInputPlaceholder';
import { DeleteAccount } from './DeleteAccount';

import Styled from './AccountDetailsPage.styles';

export const AccountDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const invalidate = useInvalidateCurrentUserProfile();
  const { data: user, isLoading: userIsLoading } = useUser();
  const { mutate: editUser, isLoading: isEditing } = useUserEdit();
  const [newWallets, setNewWallets] = useState<WalletData[]>([]);
  const [removedWallets, setRemovedWallets] = useState<string[]>([]);

  useDocumentTitle(t('accountDetailsPage.title'));

  const {
    formState: { errors, isDirty },
    register,
    setValue,
    handleSubmit,
    getValues,
    reset,
  } = useEditAccountForm();

  const [open, setOpen] = useState(false);

  const getDisplayWallets = () => {
    const allWallets = [...(user?.addresses || []), ...newWallets.map(w => w.address)];
    return [...new Set(allWallets.filter(address => !removedWallets.includes(address)))];
  };

  const displayWallets = getDisplayWallets();

  const getWalletError = (errMsg: string | null) => {
    if (!displayWallets.length) return t('errors.walletRequired');

    if (!errMsg) return;
    const messages = [
      'ADDRESS_ALREADY_CONNECTED',
      'INVALID_SIGNATURE',
      'INTERNAL_SERVER_ERROR',
    ];

    if (messages.includes(errMsg)) {
      const errText = t(`errors.${errMsg}`);
      return errText;
    }
  };

  const {
    call: connectAddress,
    isLoading: isConnecting,
    isError: isConnectingError,
  } = useRequest<ConnectAddressResponse, WalletData>(UserApi.connectAddress, {});

  const {
    call: disconnectAddress,
    isLoading: isDisconnecting,
    isError: isDisconnectingError,
  } = useRequest<void, DisconnectAddressPayload>(UserApi.disconnectAddress, {});

  const handleEditUser = async () => {
    if (!user || !displayWallets.length) return;
    let shouldInvalidate = false;

    if (removedWallets.length) {
      await Promise.all(removedWallets.map(address => disconnectAddress({ address })));
      setRemovedWallets([]);
      shouldInvalidate = true;
    }

    if (newWallets.length) {
      await Promise.all(newWallets.map(walletData => connectAddress(walletData)));
      setNewWallets([]);
      shouldInvalidate = true;
    }

    if (isDirty) {
      editUser(getValues(), {
        onSuccess: () => {
          reset();
          invalidate();
        },
      });
    } else if (shouldInvalidate) {
      await invalidate();
    }
  };

  const { call: onSave, isLoading } = useRequest(handleEditUser);

  const btnDisabled = !isDirty && !newWallets.length && !removedWallets.length;

  return (
    <Styled.Container>
      {userIsLoading ? (
        <Spinner size={40} style={{ margin: '20vh auto 0 auto' }} />
      ) : (
        <>
          <Styled.Title>{t('accountDetailsPage.title')}</Styled.Title>
          <Styled.Content>
            <Styled.FormWrapper onSubmit={handleSubmit(onSave)}>
              <NotEditableInputPlaceholder
                label={t('auth.username')}
                value={user?.username}
              />

              <InputField
                required
                inputMode='email'
                placeholder={t('auth.emailAddress')}
                label={t('auth.emailAddress')}
                error={errors.email}
                {...register('email')}
              />
              <PhoneInput
                error={errors.phoneNumber ? 'Invalid Phone Number' : ''}
                value={getValues('phoneNumber') ?? ''}
                onChange={val => {
                  setValue('phoneNumber', `+${val}`, {
                    shouldDirty: `+${val}` !== user?.phoneNumber,
                  });
                }}
              />

              <ConnectWalletButton
                addresses={displayWallets}
                onWalletConnect={walletData =>
                  setNewWallets(prev => {
                    const filteredOut = prev.filter(
                      w => w.address !== walletData.address
                    );

                    return [...filteredOut, walletData];
                  })
                }
                onRemove={address => {
                  if (user?.addresses.some(addr => addr === address)) {
                    setRemovedWallets(prev => [...new Set([...prev, address])]);
                  }

                  setNewWallets(prev =>
                    prev.filter(wallet => wallet.address !== address)
                  );
                }}
                isLoading={isConnecting || isDisconnecting || isLoading}
                error={getWalletError(isConnectingError || isDisconnectingError)}
              />
              <Styled.InputAlikeButton onClick={() => setOpen(true)}>
                <Styled.PasswordButtonContent>
                  {t('auth.password')}
                </Styled.PasswordButtonContent>
                <Icon.ChevronRightPrimary />
              </Styled.InputAlikeButton>
              <Styled.ButtonsWrapper disabled={btnDisabled}>
                <ButtonPrimary
                  disabled={btnDisabled || !displayWallets.length}
                  isLoading={isEditing}
                >
                  {t('common.saveChanges')}
                </ButtonPrimary>
                <ButtonSecondary
                  type='button'
                  disabled={btnDisabled}
                  onClick={() => {
                    reset();
                    setNewWallets([]);
                    setRemovedWallets([]);
                  }}
                >
                  {t('common.cancel')}
                </ButtonSecondary>
              </Styled.ButtonsWrapper>
            </Styled.FormWrapper>
          </Styled.Content>
          <DeleteAccount />
          {open && <ChangePasswordModal onClose={() => setOpen(false)} />}
        </>
      )}
    </Styled.Container>
  );
};
