import React from 'react';
import styled from 'styled-components';

import { useReputationWithAddress } from '../../../../../providers/AddressProvider/useReputationWithAddress';
import { Spinner } from '../../../../ui/loaders/Spinner';

import { InfoPopup as InfoPopupComponent } from '../../../../ui/modals/InfoPopup/InfoPopup';
import { AnalysisCardComponents } from '../card-components';

const { BaseCard, TopContentWrapper, BottomContentWrapper } = AnalysisCardComponents;

interface Props {
  label: string;
  value: string;
  additionalValue?: string;
  valueSuffix?: string | boolean;
  icon?: JSX.Element;
  info?: string;
}

export const AnalysisResultSecondaryCardBase: React.FC<Props> = ({
  label,
  value,
  additionalValue,
  valueSuffix,
  icon = null,
  info,
}) => {
  const { data } = useReputationWithAddress();

  const renderContent = () => {
    if (!data) return <Loader size={20} />;

    return (
      <>
        {
          <span className={valueSuffix ? 'large' : ''}>
            {value !== '' ? value : <>&mdash;</>}
          </span>
        }
        {typeof valueSuffix === 'string' && (
          <span className='suffix'>{` ${valueSuffix}`}</span>
        )}
      </>
    );
  };

  return (
    <Wrapper>
      <TopContent>
        <Title>{label}</Title>
      </TopContent>
      <BottomContent>
        <DetailTextWrapper>
          <DetailText>{renderContent()}</DetailText>
          {data && additionalValue && <DetailText>{additionalValue}</DetailText>}
        </DetailTextWrapper>
        {icon && <IconWrapper transparent={!!info}>{icon}</IconWrapper>}
        {info && (
          <InfoPopup text={info} menuOffsetLeft={'-200px'} menuOffsetTop={'-50px'} />
        )}
      </BottomContent>
    </Wrapper>
  );
};

const Wrapper = styled(BaseCard)(({ theme }) => ({
  flex: 1,
  minHeight: 145,
  [theme.min(theme.breakpoints.sm)]: {
    padding: 24,
    height: 'auto',
    minHeight: 154,
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    padding: theme.pxToVw(24),
    minHeight: theme.pxToVw(154),
  },
}));

const TopContent = styled(TopContentWrapper)(({ theme }) => ({
  height: 48,
  flexShrink: 0,
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    height: theme.pxToVw(48),
  },
}));

const BottomContent = styled(BottomContentWrapper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: 12,
  position: 'relative',
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    paddingTop: theme.pxToVw(12),
  },
}));

const Title = styled('h6')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  textTransform: 'uppercase',
  lineHeight: 0.96,
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    fontSize: theme.pxToVw(14),
  },
}));

const DetailTextWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const DetailText = styled('p')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  '&:not(:last-child)': {
    marginBottom: 6,
  },
  '& span.suffix': {
    fontWeight: 400,
    [theme.min(theme.breakpoints.sm)]: {
      display: 'block',
    },
  },
  '& span.large': {
    fontSize: 14,
    [theme.min(theme.breakpoints.sm)]: {
      fontSize: 16,
    },
    [theme.min(theme.breakpoints.desktop)]: {
      fontSize: theme.pxToVw(20),
    },
    [theme.min(theme.breakpoints.max)]: {
      fontSize: 20,
    },
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    fontSize: theme.pxToVw(14),
  },
}));

const IconWrapper = styled('div')<{ transparent?: boolean }>(
  ({ theme, transparent }) => ({
    width: transparent ? 'auto' : 30,
    height: transparent ? 'auto' : 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: transparent ? 'transparent' : 'rgba(255, 255, 255, 0.08)',
    borderRadius: '50%',
    position: 'absolute',
    right: 0,
    top: 10,
    padding: 4,
    '& svg': {
      width: '100%',
      height: '100%',
    },
    [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
      width: transparent ? 'auto' : theme.pxToVw(30),
      height: transparent ? 'auto' : theme.pxToVw(30),
    },
  })
);

const InfoPopup = styled(InfoPopupComponent)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 10,
}));

const Loader = styled(Spinner)({
  margin: '0 auto',
});
