import React from 'react';

import { shortenEthAddress } from '../../../../utils/address-utils';

import { useWindowWidth } from '../../../../hooks/useWindowWidth';

import { Spinner } from '../../../ui/loaders/Spinner';

import Styled from './ConnectedWalletItem.styles';

interface Props {
  isRemoving?: boolean;
  address: string;
  onRemove?: () => void;
}

export const ConnectedWalletItem: React.FC<Props> = ({
  address,
  isRemoving,
  onRemove,
}) => {
  const width = useWindowWidth();
  return (
    <Styled.Wrapper isLoading={!!isRemoving}>
      <Styled.WalletIcon />{' '}
      <span>{width < 481 ? shortenEthAddress(address) : address}</span>
      {onRemove && (
        <div
          className='xMark'
          onClick={e => {
            if (isRemoving) return;
            e.stopPropagation();
            onRemove();
          }}
        >
          {isRemoving ? <Spinner size={14} /> : <Styled.XMarkIcon />}
        </div>
      )}
    </Styled.Wrapper>
  );
};
