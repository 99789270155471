import React from 'react';
import styled from 'styled-components';

import { usePlaceholderItems } from '../../../hooks/usePlaceholderItems';

import { ProjectCardLoader } from './ProjectCardLoader';

interface Props {
  children: React.ReactNode;
  isLoading?: boolean;
}

export const ProjectCardsGrid: React.FC<Props> = ({
  children,
  isLoading,
  ...otherProps
}) => {
  const loadingItems = usePlaceholderItems(4, ProjectCardLoader);
  return (
    <Wrapper {...otherProps}>
      {children}
      {isLoading && loadingItems}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(100px, 1fr))',
  maxHeight: '80vh',
  overflowY: 'auto',
  rowGap: 20,
  columnGap: 16,
  position: 'relative',
  padding: 2,
  [theme.min(theme.breakpoints.xs)]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))',
  },
  [theme.min(theme.breakpoints.md)]: {},
  [theme.min(theme.breakpoints.xl)]: {
    gridTemplateColumns: 'repeat(6, 1fr)',
    rowGap: 20,
    columnGap: 20,
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    rowGap: theme.pxToVw(20),
    columnGap: theme.pxToVw(20),
  },
}));
