import React from 'react';
import styled from 'styled-components';
import { LoadingBackground } from '../../../../../../components/ui/loaders/LoadingBackground';

interface Props {
  label: string;
  value: string | number | undefined;
  icon: React.ReactNode;
  isLoading?: boolean;
}

export const ProjectDetail: React.FC<Props> = ({ label, value, icon, isLoading }) => {
  const renderContent = () => {
    if (isLoading)
      return (
        <Value>
          {icon}
          <LoadingBg>
            <span>L</span>
          </LoadingBg>
        </Value>
      );

    return (
      <Value>
        {icon}
        {typeof value !== 'undefined' ? (
          <span>{value === -1 ? <>&mdash;</> : value}</span>
        ) : null}
      </Value>
    );
  };

  return (
    <Wrapper>
      {renderContent()}
      <Label>{label}</Label>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '33.33%',
  gap: 6,
  marginBottom: 27,
  [theme.min(theme.breakpoints.xs)]: {
    marginBottom: 0,
    width: 'max-content',
  },
  [theme.between(theme.breakpoints.md, theme.breakpoints.desktop)]: {
    marginBottom: 27,
    '&:last-child': {
      width: '50.33%',
    },
  },
}));

const Label = styled('span')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  [theme.min(theme.breakpoints.md)]: {
    fontSize: 16,
    '&.spinner': {
      height: 18,
      width: 'auto',
    },
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(20),
    '&.spinner': {
      height: theme.pxToVw(16),
      width: 'auto',
    },
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 20,
    '&.spinner': {
      height: 16,
      width: 'auto',
    },
  },
}));

const Value = styled('div')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 900,
  display: 'flex',
  alignItems: 'center',
  height: 20,
  gap: 8,
  lineHeight: 1,
  '& svg:not(.spinner)': {
    height: '95%',
    width: 'auto',
  },
  '&.spinner': {
    height: 18,
    width: 'auto',
  },
  [theme.min(theme.breakpoints.md)]: {
    fontSize: 20,
    '&.spinner': {
      height: 22,
    },
  },
  [theme.min(theme.breakpoints.desktop)]: {
    height: theme.pxToVw(20),
    fontSize: theme.pxToVw(24),
    '&.spinner': {
      height: theme.pxToVw(22),
    },
  },
  [theme.min(theme.breakpoints.max)]: {
    height: 20,
    fontSize: 24,
    '&.spinner': {
      height: 22,
    },
  },
}));

const LoadingBg = styled(LoadingBackground)({
  color: 'transparent !important',
  flex: 1,
});
