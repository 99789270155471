import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { useRequest } from '../../../../../hooks/useRequest';
import { useWalletUtils } from '../../../../../hooks/useWalletUtils';

import { PostProjectRequestPayload } from '../../../../../api/UserApi/UserApi';
import { SignMessage } from '../../../../../constants/sign-messages';

import { ReactComponent as WalletSvg } from '../Wallet.svg';
import { Web3Service } from '../../../../../services/Web3Service';
import { SelectExpandible } from '../../../../ui/forms/Select/SelectExpandible';

interface Props {
  addresses: string[];
  defaultValue?: string | null;
  onChange?: (address: string) => void;
}

export const SelectAddressExpandible: React.FC<Props> = ({
  addresses,
  defaultValue,
  onChange,
}) => {
  const { t } = useTranslation();

  const [selectedAddress, setSelectedAddress] = useState(defaultValue?.toLowerCase());

  const { signMessage, getCurrentWallet } = useWalletUtils();
  const {
    setError,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext<PostProjectRequestPayload>();

  const signature = watch('signature');

  const onAddressSelect = async (address: string) => {
    const wallet = await getCurrentWallet();

    if (wallet.accounts[0].address !== address) {
      return setError('signature', { message: t('errors.walletMismatch') });
    }

    setSelectedAddress(address);
    const { signature } = await signMessage(SignMessage.VerifyProjectOwnership);

    clearErrors('signature');
    setValue('signature', signature, { shouldDirty: true, shouldTouch: true });
    typeof onChange === 'function' && onChange(address);
  };

  const { call, isLoading, isError } = useRequest(onAddressSelect);

  const onSignatureChange = useCallback(async () => {
    const accounts = await Web3Service.getAccounts();
    const addr = accounts[0];
    if (!addr) return setError('signature', { message: 'Bad wallet' });
    setSelectedAddress(addr.toLowerCase());
  }, [setError]);

  useEffect(() => {
    if (!signature) return;
    onSignatureChange();
  }, [onSignatureChange, signature]);

  const options = useMemo(
    () =>
      addresses.map(addr => {
        const _address = addr.toLowerCase();
        return { label: _address, value: _address, onClick: () => call(_address) };
      }),
    [addresses, call]
  );

  return (
    <SelectExpandible
      defaultValue={defaultValue}
      value={selectedAddress}
      options={options}
      onChange={val => {
        if (typeof onChange === 'function') onChange(val as string);
      }}
      placeholder={t('common.selectConnectedAddress')}
      Icon={<WalletSvg />}
      isLoading={isLoading}
      error={isError || errors.signature?.message}
      clearError={() => clearErrors('signature')}
    />
  );
};
