import { useQuery, useQueryClient } from 'react-query';
import { UserApi } from '../../api/UserApi/UserApi';
import { useProfileByParamId } from '../profile/useProfile';
import { QueryKey } from '../query-keys';
import { useUser } from '../user/useUser';

export const useFollowings = (uid: string | undefined) => {
  const queryClient = useQueryClient();
  return useQuery(QueryKey.ProfileFollowings(uid!), () => UserApi.getFollowings(uid!), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: !!uid,
    onSuccess: data => {
      queryClient.setQueryData(QueryKey.ProfileFollowings(uid!), data);
    },
  });
};

export const useProfileFollowings = () => {
  const { data } = useProfileByParamId();
  return useFollowings(data?.uid);
};

export const useCurrentUserFollowings = () => {
  const { data: user } = useUser();
  return useFollowings(user?.uid);
};
