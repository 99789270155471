import styled from 'styled-components';

const H1 = styled('h1')(({ theme }) => ({
  fontSize: '10rem',
  fontWeight: 900,
  lineHeight: 1.165,
  [theme.max(theme.breakpoints.lg)]: {
    fontSize: '8rem',
  },
  [theme.max(theme.breakpoints.sm)]: {
    fontSize: '6rem',
  },
}));

const H2 = styled('h2')(({ theme }) => ({
  fontSize: '6.4rem',
  fontWeight: 600,
  lineHeight: 1.31,
  [theme.max(theme.breakpoints.lg)]: {
    fontSize: '5rem',
  },
  [theme.max(theme.breakpoints.md)]: {
    fontSize: '4.2rem',
  },
  [theme.max(theme.breakpoints.sm)]: {
    fontSize: 30,
  },
}));

const H3 = styled('h3')(({ theme }) => ({
  fontSize: 24,
  fontWeight: 600,
  lineHeight: 1.32,
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: 32,
  },
  [theme.min(theme.breakpoints.lg)]: {
    fontSize: 40,
  },
  [theme.min(theme.breakpoints.ul)]: {
    fontSize: 48,
  },
}));

const H4 = styled('h4')(({ theme }) => ({
  fontSize: '4rem',
  fontWeight: 600,
  lineHeight: 1.32,
  [theme.max(theme.breakpoints.lg)]: {
    fontSize: '3rem',
  },
  [theme.max(theme.breakpoints.sm)]: {
    fontSize: '2.6rem',
  },
  [theme.max(theme.breakpoints.sm)]: {
    fontSize: '2.4rem',
  },
}));

const H5 = styled('h5')(({ theme }) => ({
  fontSize: '3.2rem',
  fontWeight: 600,
  lineHeight: 1.32,
  [theme.max(theme.breakpoints.lg)]: {
    fontSize: '2.6rem',
  },
  [theme.max(theme.breakpoints.sm)]: {
    fontSize: '2.2rem',
  },
  [theme.max(theme.breakpoints.sm)]: {
    fontSize: '2rem',
  },
}));

export const Heading = {
  H1,
  H2,
  H3,
  H4,
  H5,
};

export const MainHeading = styled('h1')(({ theme }) => ({
  lineHeight: 1.31,
  fontWeight: 700,
  fontSize: 38,
  '& span': { color: theme.colors.primary.main },
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: 48,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(80),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 80,
  },
}));

export const Heading2 = styled('h2')(({ theme, color = '#fff' }) => ({
  lineHeight: 1.33,
  fontWeight: 700,
  fontSize: 28,
  color,
  '& span': {
    color: theme.colors.primary.main,
  },
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: 40,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(60),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 60,
  },
}));

export const Heading3 = styled('h3')(({ theme }) => ({
  fontSize: 24,
  fontWeight: 700,
  lineHeight: 1.32,
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: 32,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(48),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 48,
  },
}));

export const Heading4 = styled('h4')(({ theme }) => ({
  fontSize: 20,
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: 26,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(32),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 32,
  },
}));

export const Heading5 = styled('h5')(({ theme }) => ({
  fontSize: 16,
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: 20,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(24),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 24,
  },
}));
