import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { InstallExtensionButton } from '../../common/ActionButtons/InstallExtensionButton';
import { useNavigationItems } from './useNavigationItems';
import { NavItem } from './NavItem/NavItem';
import { useAuth } from '../../../providers/AuthProvider';
import { AnalyticsEvent } from '../../../services/AnalyticsService';
import { NotificationIcon } from '../../common/Notifications/NotificationIcon';
import { UserMenu } from './UserMenu/UserMenu';
import { ButtonPrimary, ButtonVariant } from '../../ui/buttons';
import { routes } from '../../../router/routes';

export const DesktopNavigation: React.FC = () => {
  const { t } = useTranslation();
  const { navItems } = useNavigationItems();
  const ref = useRef<HTMLDivElement>(null);

  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const [notificationOpen, setNotificationOpen] = useState(false);

  return (
    <NavItems>
      <LinksWrapper ref={ref}>
        {navItems.map(item => (
          <NavItem key={`${item.label}-${String(item.url)}`} navItem={item} />
        ))}
      </LinksWrapper>

      {isAuthenticated ? (
        <IconAndUserMenuWrapper>
          <InstallExtensionButton analyticsEvent={AnalyticsEvent.HeaderButtonClick} />
          <NotificationIcon open={notificationOpen} setOpen={setNotificationOpen} />
          <UserMenu />
        </IconAndUserMenuWrapper>
      ) : (
        <ButtonsWrapper>
          <ButtonPrimary
            onClick={() => navigate(routes.auth.login())}
            variant={ButtonVariant.Outlined}
          >
            {t('auth.signin')}
          </ButtonPrimary>
          <InstallExtensionButton analyticsEvent={AnalyticsEvent.HeaderButtonClick} />
        </ButtonsWrapper>
      )}
    </NavItems>
  );
};

const IconAndUserMenuWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 24,
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(32),
  },
}));

const NavItems = styled('nav')(({ theme }) => ({
  display: 'none',
  [theme.min(theme.breakpoints.md)]: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
}));

const LinksWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.pxToVw(36),
  marginLeft: theme.pxToVw(40),
  marginRight: 'auto',
}));

const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.pxToVw(20),
  [theme.min(theme.breakpoints.desktop)]: {
    gap: 20,
  },
}));
