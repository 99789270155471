import styled from 'styled-components';

const Wrapper = styled('div')<{ iconSize: number | string; iconGap: number | string }>(
  ({ theme, iconSize, iconGap }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: iconGap,
    '& svg': {
      height: iconSize,
      width: 'auto',
      cursor: 'pointer',
      transition: 'all .2s',
      opacity: 0.9,
      '& path': {
        fill: `${theme.colors.text.secondary} !important`,
      },

      '&:hover': {
        opacity: 1,
      },
    },
  })
);

const Styled = {
  Wrapper,
};

export default Styled;
