import { useTranslation } from 'react-i18next';
import { useConnectWallet } from '@web3-onboard/react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { useUser } from '../react-query/user/useUser';
import { useCurrentUserFollowings } from '../react-query/user/useFollowings';
import { useRequest } from './useRequest';

import { Profile } from '../types';
import { Web3Service } from '../services/Web3Service';
import { SignMessage } from '../constants/sign-messages';
import { UserApi } from '../api/UserApi/UserApi';
import { QueryKey } from '../react-query/query-keys';
import { routes } from '../router/routes';
import { WalletData } from '../components/common/ConnectWalletButton/ConnectWalletButton';
import { delay } from '../utils/timer-utils';

export const useClaimProfile = (profile: Profile | null | undefined) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: user } = useUser();
  const { data: followings } = useCurrentUserFollowings();

  const [, connect] = useConnectWallet();

  const handleConnect = async () => {
    if (!profile) return;
    const connectedWallet = await connect();

    const theWallet = connectedWallet[0];

    if (!theWallet) return;

    const address = theWallet.accounts[0]?.address.toLowerCase().trim();

    if (!address) return;
    if (address !== profile.addresses[0].toLowerCase().trim()) {
      throw new Error(t('errors.walletMismatch'));
    }
    const signature = await Web3Service.signMessage(
      theWallet,
      SignMessage.VerifyAccountOwnership
    );

    if (user && followings) {
      const followsAccount = followings.find(
        follow => follow.address.toLowerCase().trim() === address
      );
      let retryCount = 0;

      if (followsAccount) {
        try {
          await UserApi.unfollow(theWallet.accounts[0]?.address);
        } catch (err) {
          if (retryCount < 3) {
            await delay(500);
            await UserApi.unfollow(theWallet.accounts[0]?.address);
            retryCount++;
          } else {
            throw err;
          }
        }
      }
      await UserApi.connectAddress({ address, signature });
      const keys = [
        'User',
        'Followers',
        'FollowersCount',
        'Followings',
        'FollowingsCount',
        'Profile',
        QueryKey.Reputation(address),
      ];
      await Promise.all(keys.map(key => queryClient.invalidateQueries(key)));
      navigate(routes.profile.byId(user.username), { replace: true });
    } else {
      return { address, signature };
    }
  };

  return useRequest<WalletData>(handleConnect, {
    onSuccess: walletData => {
      if (walletData) navigate(routes.auth.register(walletData));
    },
  });
};
