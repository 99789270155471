import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useProfileByParamId } from '../../../../../react-query/profile/useProfile';

import { shortenEthAddress } from '../../../../../utils/address-utils';

import { SelectBase } from '../../../../../components/ui/forms/Select/SelectBase';

interface Props {
  onChange: (val: string) => void;
}

export const AddressesSelect: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation('common');
  const { data: profile } = useProfileByParamId();

  if (!profile || profile.addresses.length < 2) return null;

  const options = [
    {
      label: t('allAddresses'),
      value: 'all',
    },
    ...profile?.addresses.map(address => ({
      label: shortenEthAddress(address, {
        placeholderChar: '.',
        placeholderCount: 2,
        startChars: 4,
      }),
      value: address,
    })),
  ];

  return (
    <Wrapper>
      <SelectBase
        value={options[0].value}
        options={options}
        showArrows={false}
        onChange={onChange}
      />
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  flex: 1,
  width: '50%',
  maxWidth: 180,
  flexShrink: 0,
  [theme.betweenDM]: {
    maxWidth: theme.pxToVw(180),
  },
}));
