const PINATA_URL = 'https://airlius.mypinata.cloud/ipfs';

export const getNftImageFromJson = async (jsonUrl: string) => {
  const image: string = await fetch(jsonUrl)
    .then(res => res.json())
    .then(res => res.image);
  if (!image) throw new Error('No image');

  if (image.search('/ipfs') > -1) {
    return `${PINATA_URL}${image.split('/ipfs')[1]}`;
  }

  if (image.search('ipfs://') > -1) {
    return `${PINATA_URL}/${image.split('ipfs://')[1]}`;
  }

  throw new Error('No Img!')
};
