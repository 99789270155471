import styled from 'styled-components';
import { ReactComponent as WalletSvg } from './Wallet.svg';
import { ReactComponent as XMarkSvg } from './XMark.svg';

const Wrapper = styled('div')<{ isLoading: boolean }>(({ theme, isLoading }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 11,
  height: 48,
  padding: '0 20px',
  background: 'rgba(0, 101, 252, 0.2)',
  color: theme.colors.primary.main,
  fontSize: 12,
  borderRadius: 10,
  opacity: isLoading ? 0.8 : 1,
  '& .xMark': {
    display: 'block',
    cursor: 'pointer',
    marginLeft: 'auto',
    background: 'transparent',
    border: 'none',
    outline: 'none',
    '& path': {
      transition: 'all .2s',
    },
    '&:hover': {
      '& path': {
        fill: theme.colors.primary.main,
      },
    },
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(11),
    height: theme.pxToVw(48),
    padding: `0 ${theme.pxToVw(20)}`,
    fontSize: theme.pxToVw(12),
    borderRadius: theme.pxToVw(10),
  },
}));

const XMarkIcon = styled(XMarkSvg)(({ theme }) => ({
  [theme.betweenDM]: {
    height: theme.pxToVw(10),
    width: 'auto',
  },
}));
const WalletIcon = styled(WalletSvg)(({ theme }) => ({
  [theme.betweenDM]: {
    height: theme.pxToVw(21),
    width: 'auto',
  },
}));

const Styled = { Wrapper, XMarkIcon, WalletIcon };

export default Styled;
