import { ReactComponent as TokenSupplyIcon } from './TokenSupply.svg';
import { ReactComponent as FloorPriceIcon } from './FloorPrice.svg';
import { ReactComponent as HoldersIcon } from './Holders.svg';
import { ReactComponent as FollowersIcon } from './Followers.svg';

const ProjectIcons = {
  TokenSupplyIcon,
  FloorPriceIcon,
  HoldersIcon,
  FollowersIcon,
};

export default ProjectIcons;
