import React from 'react';
import styled from 'styled-components';

interface Props {
  on: boolean;
  isLoading?: boolean;
  onChange: (val: boolean) => void;
}

const Wrapper = styled('div')<{ isLoading?: Props['isLoading'] }>(
  ({ theme, isLoading }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    cursor: isLoading ? 'wait' : 'pointer',
    transition: 'all .3s',
    opacity: isLoading ? 0.7 : 1,
    height: 16,
  })
);

const Background = styled('div')<{ $on: boolean }>(({ $on, theme }) => ({
  opacity: 0.38,
  display: 'block',
  content: '" "',
  borderRadius: 7,
  width: 27,
  height: 11,
  transition: 'all .2s',
  backgroundColor: `rgba(103, 117, 139, 0.${$on ? 9 : 6})`,
}));

const Dot = styled('div')<{ $on: boolean }>(({ theme, $on }) => ({
  display: 'block',
  content: '" "',
  borderRadius: '50%',
  width: 16,
  height: 16,
  background: $on ? theme.colors.primary.main : '#67758B',
  transition: 'all .3s',
  position: 'absolute',
  left: $on ? 'auto' : -2,
  right: !$on ? 'auto' : -2,
}));

export const ButtonSwitch: React.FC<Props> = ({ onChange, on, isLoading }) => {
  return (
    <Wrapper onClick={() => onChange(!on)} isLoading={isLoading}>
      <Background $on={on} />
      <Dot $on={on} />
    </Wrapper>
  );
};
