import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

export type IStepContext = {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
};

const StepContext = React.createContext<IStepContext | undefined>(undefined);

export const useStep = (): IStepContext => {
  const context = useContext(StepContext);

  if (!context) {
    throw new Error('useStep cannot be used without StepContext');
  }

  return context;
};

interface Props {
  children: React.ReactNode;
  initialStep?: number;
}

export const StepProvider: React.FC<Props> = ({ children, initialStep }) => {
  const [step, setStep] = useState(initialStep || 0);

  return (
    <StepContext.Provider value={{ step, setStep }}>{children}</StepContext.Provider>
  );
};
