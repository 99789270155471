import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useWindowWidth } from '../../../hooks/useWindowWidth';

import { SearchInput } from '../../ui/forms/Input/SearchInput';
import { SelectBase } from '../../ui/forms/Select/SelectBase';

interface Props {
  setSortBy: Dispatch<SetStateAction<string>>;
  setSearch: Dispatch<SetStateAction<string>>;
  search: string;
  sortBy: string;
  breakpointWidth?: number;
}

export const SearchAndSort: React.FC<Props> = ({
  setSearch,
  setSortBy,
  search,
  sortBy,
  breakpointWidth = 768,
}) => {
  const width = useWindowWidth();
  const { t } = useTranslation();

  const sortOptions = [
    { value: 'date', label: 'Date' },
    { value: 'a-z', label: 'A-Z' },
  ];

  return (
    <Wrapper breakpointWidth={breakpointWidth}>
      {width < breakpointWidth ? (
        <SearchInput
          onDebounced={setSearch}
          defaultValue={search}
          menu={{
            items: sortOptions.map(opt => ({
              ...opt,
              onClick: val => setSortBy(val as string),
            })),
            selected: sortBy,
          }}
        />
      ) : (
        <>
          <SearchInput onDebounced={setSearch} defaultValue={search} />
          <SelectWrapper>
            <StyledSelect
              label='Sort By'
              name='sort'
              onChange={setSortBy}
              placeholder={t('common.sortBy')}
              showArrows
              width={170}
              value={null}
              options={sortOptions}
            />
          </SelectWrapper>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')<{ breakpointWidth: number }>(
  ({ theme, breakpointWidth }) => ({
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginRight: 20,
    [theme.min(breakpointWidth)]: {
      justifyContent: 'space-between',
    },
    [theme.betweenDM]: {
      marginRight: theme.pxToVw(20),
    },
  })
);

const SelectWrapper = styled('div')(({ theme }) => ({
  width: 170,
  marginLeft: '3.6%',
  [theme.betweenDM]: {
    width: 'max-content',
  },
}));

const StyledSelect = styled(SelectBase)(({ theme }) => ({
  width: 170,
  [theme.betweenDM]: {
    width: 'max-content',
  },
}));
