import styled from 'styled-components';
import { SocialIcons as SocialIconsComponent } from '../../common/SocialIcons/SocialIcons';
import { ButtonPrimary as ButtonPrimaryComponent } from '../../ui/buttons/ButtonPrimary';
import { Container as ContainerEl } from '../Container';
import { BlockemLogoHeader } from './svgs/BlockemLogoHeader';

const Header = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
}));

const Container = styled(ContainerEl)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const SocialIcons = styled(SocialIconsComponent)(({ theme }) => ({
  flexShrink: 0,
}));

const ButtonPrimary = styled(ButtonPrimaryComponent)(({ theme }) => ({}));

const Logo = styled(BlockemLogoHeader)(({ theme }) => ({
  cursor: 'pointer',
  zIndex: theme.zIndex.ul,
  height: 48,
  width: 48,
  [theme.min(theme.breakpoints.desktop)]: {
    height: theme.pxToVw(56),
    width: theme.pxToVw(56),
  },
  [theme.min(theme.breakpoints.max)]: {
    height: 56,
    width: 56,
  },
}));

const Styled = {
  Header,
  Container,
  SocialIcons,
  ButtonPrimary,
  Logo,
};

export default Styled;
