import React from 'react';
import styled, { useTheme } from 'styled-components';

import { useReputationWithAddress } from '../../../../providers/AddressProvider/useReputationWithAddress';

import { ProfileBadgeImage } from './components/ProfileBadgeImage';
import { DetailsWrapper } from './components/shared';
import { ProfileBadgeContract } from './ProfileBadgeContract';
import { ProfileBadgeAddress } from './ProfileBadgeAddress';
import { useWindowWidth } from '../../../../hooks/useWindowWidth';

export const ProfileBadge: React.FC = () => {
  const { data: reputation } = useReputationWithAddress();

  if (!reputation) return <ProfileBadgeLoader />;

  return (
    <ProfileBadgeWrapper>
      {reputation.isContract ? <ProfileBadgeContract /> : <ProfileBadgeAddress />}
    </ProfileBadgeWrapper>
  );
};

const ProfileBadgeLoader = () => {
  const width = useWindowWidth();
  const theme = useTheme();

  const maxHeight =
    width < theme.breakpoints.sm
      ? 153
      : width < theme.breakpoints.desktop
      ? 163
      : theme.pxToVw(163);

  return (
    <ProfileBadgeWrapper style={{ maxHeight }}>
      <ProfileBadgeImage imageUrl={null} isLoading={true} onClick={() => {}} />
      <DetailsWrapper isLoading={true}>{'   '}</DetailsWrapper>
    </ProfileBadgeWrapper>
  );
};

const ProfileBadgeWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignSelf: 'flex-end',
  gap: 12,
  padding: '16px 20px',
  borderRadius: 15,
  border: '1px solid rgba(255, 255, 255, 0.1)',
  width: '100%',
  backgroundColor: '#101418',
  marginTop: -20,
  marginBottom: 20,
  [theme.min(theme.breakpoints.xs)]: {
    gap: 26,
  },
  [theme.min(theme.breakpoints.sm)]: {
    position: 'initial',
    maxWidth: 478,
    margin: '-10px auto 20px auto',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    margin: `${theme.pxToVw(-10)} auto ${theme.pxToVw(20)} 0`,
    maxWidth: theme.pxToVw(504),
    padding: `${theme.pxToVw(20)} ${theme.pxToVw(24)}`,
    gap: theme.pxToVw(26),
    borderRadius: theme.pxToVw(15),
    position: 'absolute',
    top: 0,
    right: 0,
  },
  [theme.min(theme.breakpoints.max)]: {
    padding: '20px 24px',
    margin: `-10px auto 20px 0`,
    maxWidth: 504,
    gap: 26,
    borderRadius: 15,
  },
}));
