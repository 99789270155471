import React, { useState } from 'react';
import styled, { CSSObject } from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PostProjectRequestPayload } from '../../../api/UserApi/UserApi';
import { defaultNftImageUrl } from '../../../constants/external-urls';

import { NotEditableInputPlaceholder } from '../../ui/forms/NotEditableInputPlaceholder/NotEditableInputPlaceholder';
import { LoadingBackground } from '../../ui/loaders/LoadingBackground';

export const ProjectTitle: React.FC = () => {
  const { t } = useTranslation();
  const { getValues } = useFormContext<PostProjectRequestPayload>();

  const [imgLoaded, setImgLoaded] = useState(false);
  const [imgError, setImgError] = useState(false);

  const [imgSrc, setImgSrc] = useState(getValues('photoUrl') || defaultNftImageUrl);

  return (
    <NotEditableInputPlaceholder label={t('common.title')} value={getValues('title')}>
      <NftImage
        src={imgSrc}
        onLoad={() => setImgLoaded(true)}
        onError={() => {
          if (!imgError) {
            setImgSrc(defaultNftImageUrl);
            setImgError(true);
          }
        }}
      />
      {!imgLoaded && <Loader />}
    </NotEditableInputPlaceholder>
  );
};

const common: CSSObject = {
  height: 44,
  width: 44,
  borderRadius: 10,
  position: 'absolute',
  right: 6,
  top: 6,
};

const NftImage = styled('img')(({ theme }) => ({
  objectFit: 'cover',
  ...common,
}));

const Loader = styled(LoadingBackground)(({ theme }) => ({
  ...common,
  zIndex: theme.zIndex.sm,
}));
