import { Fragment } from 'react';

export const splitStringNewLineBreak = (string: string) =>
  string.split('\n').map((s, i, arr) =>
    i === arr.length - 1 ? (
      <Fragment key={s}>{s}</Fragment>
    ) : (
      <Fragment key={s}>
        {s}
        <br />
      </Fragment>
    )
  );

export const limitLongString = (
  str: string,
  chars: number,
  concatDots?: boolean
): string => {
  if (str.length < chars) return str;
  return str.slice(0, chars - 3) + (concatDots ? '...' : '');
};
