import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { splitStringNewLineBreak } from '../../../../utils/string-utils';
import { envs } from '../../../../config/envs';
import { AnalyticsEvent, AnalyticsService } from '../../../../services/AnalyticsService';

import {
  ButtonPrimary,
  ButtonSize,
  ButtonVariant,
} from '../../../../components/ui/buttons';
import { DigitalOwnershipBenefit } from '../../../../components/common/DigitalOwnershipBenefit/DigitalOwnershipBenefit';

import Styled from './BottomSection.styles';

import img_1 from './img_1.png';
import img_2 from './img_2.png';
import img_mobile from './img_mobile.png';

export const BottomSection: React.FC = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const benefits = t('generateProfile.benefits', {
    returnObjects: true,
  }) as { title: string; text: string }[];

  return (
    <>
      <Styled.Wrapper ref={ref} id='ownership-section'>
        <Styled.OwnershipWrapper>
          <Styled.Heading>
            {splitStringNewLineBreak(t('generateProfile.bottomSectionHeading'))}
          </Styled.Heading>
        </Styled.OwnershipWrapper>
        <Styled.ImagesWrapper>
          <Styled.Image src={img_1} className='img_1' />
          <Styled.Image src={img_2} className='img_2' />
          <Styled.Image src={img_mobile} className='img_mobile' />
        </Styled.ImagesWrapper>
        <Styled.Container>
          <Styled.DigitalOwnershipsWrapper>
            {benefits.map(({ text, title }, i) => (
              <DigitalOwnershipBenefit key={i} text={text} title={title} index={i} />
            ))}
          </Styled.DigitalOwnershipsWrapper>
          <Styled.IllustrationWrapper>
            <Styled.Illustration />
          </Styled.IllustrationWrapper>
        </Styled.Container>
      </Styled.Wrapper>
      <Styled.TellTheWorld>
        <Styled.RealTimeContainer>
          <Styled.Heading className='h-2-x'>
            {splitStringNewLineBreak(t('generateProfile.bottomTitle'))}
          </Styled.Heading>
          <Styled.ActionButtons>
            <ButtonPrimary
              size={ButtonSize.Lg}
              onClick={() => {
                const el = document.getElementById('main-gen-prof');
                el?.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              {t('common.generateProfile')}
            </ButtonPrimary>
            <ButtonPrimary
              size={ButtonSize.Lg}
              variant={ButtonVariant.Outlined}
              onClick={() => {
                AnalyticsService.userEvent({
                  action: AnalyticsEvent.FindOutMoreButtonClick,
                });

                window.open(envs.blockemWebsiteUrl, '_blank');
              }}
            >
              {t('common.findOutMore')}
            </ButtonPrimary>
          </Styled.ActionButtons>
        </Styled.RealTimeContainer>
      </Styled.TellTheWorld>
    </>
  );
};
