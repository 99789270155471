import styled from 'styled-components';

export const LoadingBackground = styled('div')(({ theme }) => ({
  backgroundColor: '#eee',
  background:
    'linear-gradient(110deg, rgba(20,20,20, 1) 8%, rgba(25,25,25, 1) 18%, rgba(20,20,20, 1) 44%)',
  borderRadius: 15,
  backgroundSize: '200% 100%',
  animation: '1.5s shine linear infinite',
  color: 'transparent !important',
  '@keyframes shine': {
    to: {
      backgroundPositionX: '-200%',
    },
  },
  '& > *': {
    color: 'transparent !important',
  },
}));
