import React from 'react';

import { ErrorMessage } from './ErrorMessage';

import Styled from './FormFieldWrapper.styles';

interface Props {
  label?: string;
  error?: boolean | string | Error;
  required?: boolean;
  fieldHasValue?: boolean;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  children?: React.ReactNode;
}

export const FormFieldWrapper: React.FC<Props> = ({
  children,
  error,
  label,
  required,
  fieldHasValue,
  labelProps,
  ...props
}) => (
  <>
    <Styled.Container {...props} error={error}>
      {label && fieldHasValue && (
        <Styled.Label {...labelProps}>{required ? `${label}*` : label}</Styled.Label>
      )}
      {children}
    </Styled.Container>
    {error && <ErrorMessage error={error} />}
  </>
);
