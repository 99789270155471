export const getDisplayValue = (
  value: number | null | undefined,
  options?: { prefix?: string; suffix?: string }
) => {
  if (typeof value !== 'number') return '';
  if (value === -1) return '-';

  const { prefix, suffix } = options || {};

  return `${prefix ? prefix : ''}${value.toLocaleString()} ${suffix ? suffix : ''}`;
};
