import { ReactComponent as LogoWhite } from './svgs/LogoWhite.svg';
import { ReactComponent as BlockemWhite } from './svgs/BlockemWhite.svg';
import { ReactComponent as LogoBlack } from './svgs/LogoBlack.svg';
import { ReactComponent as Spinner } from './svgs/Spinner.svg';
import { ReactComponent as ArrowLongRight } from './svgs/ArrowLongRight.svg';
import { ReactComponent as Clipboard } from './svgs/Clipboard.svg';
import { ReactComponent as Download } from './svgs/Download.svg';
import { ReactComponent as Info } from './svgs/Info.svg';
import { ReactComponent as ChevronRightPrimary } from './svgs/ChevronRightPrimary.svg';
import { ReactComponent as QuestionMarkCirclePrimary } from './svgs/QuestionMarkCircle.svg';
import { ReactComponent as Followers } from './svgs/Followers.svg';
import { ReactComponent as ChevronDownWhite } from './svgs/ChevronDownWhite.svg';
import { ReactComponent as Plus } from './svgs/Plus.svg';
import { ReactComponent as Share } from './svgs/Share.svg';
import { ReactComponent as ChevronDownWhiteFilled } from './svgs/ChevronDownWhiteFilled.svg';

import { ReactComponent as EditUnderlined } from './svgs/EditProfileIcon.svg';
import BlockemLogoBgPng from './backgrounds/blockem-logo.png';
import defaultProfileImage from './default-profile.png';

export const Icon = {
  LogoWhite,
  LogoBlack,
  BlockemWhite,
  Spinner,
  ArrowLongRight,
  Clipboard,
  Download,
  Info,
  ChevronRightPrimary,
  QuestionMarkCirclePrimary,
  Followers,
  ChevronDownWhite,
  Plus,
  EditUnderlined,
  Share,
  ChevronDownWhiteFilled,
};

export const BackgroundImage = {
  BlockemLogoBgPng,
};

export { defaultProfileImage };
