import React, { useState } from 'react';
import styled from 'styled-components';

import { useEndorsmentsTotalCount } from '../../../../../../react-query/project/useEndorsmentsCount';
import { useProjectByParamId } from '../../../../../../react-query/project/useProject';
import { useEndorsmentsByProjectId } from '../../../../../../react-query/user/useEndorsments';
import { useAuth } from '../../../../../../providers/AuthProvider';
import { useAppMessage } from '../../../../../../providers/AppMessageProvider/AppMessageProvider';

import { EndorseButton } from './EndorseButton';
import { AppMessage } from '../../../../../../types';
import { EndorsmentsModal } from '../../../../../../components/project/EndorsmentsModal';
import { ProfileBadges } from '../../../../../../components/common/ProfileBadges/ProfileBadges';

export const ProjectTrustedBy: React.FC = () => {
  const { data: project, isLoading: projectIsLoading } = useProjectByParamId();

  const { data: endorsmentsCount, isLoading: endorsmentsCountIsLoading } =
    useEndorsmentsTotalCount(project?.uid);

  const { data: endorsments, isLoading: endorsmentsLoading } =
    useEndorsmentsByProjectId();

  const { isAuthenticated } = useAuth();
  const { toast } = useAppMessage();

  const [open, setOpen] = useState(false);

  const isLoading = endorsmentsCountIsLoading || endorsmentsLoading || projectIsLoading;

  if (project && !project.uid) return null;

  const onClick = () =>
    isAuthenticated ? setOpen(true) : toast(AppMessage.RegisterToSeeEndorsments);

  const showUi =
    isLoading || (typeof endorsmentsCount === 'number' && endorsmentsCount > 0);

  return (
    <>
      <Wrapper>
        <EndorseButton />
        {showUi && (
          <ProfileBadges
            followers={endorsments}
            totalCount={endorsmentsCount}
            onClick={onClick}
            isLoading={isLoading}
            showTotalCountAs='text'
          />
        )}
      </Wrapper>
      {open && (
        <EndorsmentsModal onClose={() => setOpen(false)} projectId={project?.uid} />
      )}
    </>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  [theme.min(theme.breakpoints.sm)]: {
    flexDirection: 'row',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(44),
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 44,
  },
}));
