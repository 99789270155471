import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useWindowWidth } from './useWindowWidth';

export const useWindowSizeUtils = () => {
  const width = useWindowWidth();
  const theme = useTheme();

  return useMemo(
    () => ({
      isXs: width <= theme.breakpoints.xs,
      isSm: width <= theme.breakpoints.sm && width > theme.breakpoints.xs,
      isMd: width <= theme.breakpoints.md && width > theme.breakpoints.sm,
      isLg: width <= theme.breakpoints.lg && width > theme.breakpoints.md,
      isXl: width <= theme.breakpoints.xl && width > theme.breakpoints.lg,
      isUl: width <= theme.breakpoints.ul && width > theme.breakpoints.xl,
      isMega: width <= 1920 && width > theme.breakpoints.ul,
      width,
    }),
    [width, theme]
  );
};
