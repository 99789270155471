import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useReputationWithAddress } from '../../../../../../providers/AddressProvider/useReputationWithAddress';
import { useTrustCategory } from '../../../../../../components/common/AnalysisResult/TrustCategoryProvider';

import { Trust } from '../../../../../../types';
import { LoadingBackground } from '../../../../../../components/ui/loaders/LoadingBackground';

export const ProjectReputationTrustTitle: React.FC = () => {
  const { t } = useTranslation('analysisResult');
  const { data } = useReputationWithAddress();
  const { trust } = useTrustCategory();

  if (!data || !trust)
    return (
      <LoadingBg>
        <Title trust={Trust.High}>L</Title>
      </LoadingBg>
    );

  return <Title trust={trust}>{t(`summary.${trust}.title`)}</Title>;
};

const Title = styled('h4')<{ trust: Trust }>(({ theme, trust }) => ({
  fontWeight: 900,
  fontSize: 20,
  color: theme.colors.trust[trust],
  lineHeight: 1.322,
  position: 'relative',
  marginBottom: 9,
  [theme.min(theme.breakpoints.md)]: {
    fontSize: 22,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(24),
    marginBottom: theme.pxToVw(12),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 24,
    marginBottom: 12,
  },
}));

const LoadingBg = styled(LoadingBackground)(({ theme }) => ({
  width: 200,
  [theme.betweenDM]: {
    width: theme.pxToVw(200),
  },
}));
