import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useProjectByParamId } from '../../../../../../react-query/project/useProject';
import { routes } from '../../../../../../router/routes';
import { useProjectUtils } from '../../../hooks/useProjectUtils';

export default function EditIcon() {
  const navigate = useNavigate();
  const { data: project } = useProjectByParamId();
  const { isOwner } = useProjectUtils();

  if (!project || !isOwner) return null;

  return (
    <Svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={() => navigate(routes.project.roadmap.root(project?.contractAddress))}
    >
      <g clipPath='url(#clip0_3314_9539)'>
        <path
          d='M3.60322 16.8391C3.80322 16.8391 3.84322 16.8191 4.02322 16.7791L7.62322 16.0591C8.00322 15.9591 8.38322 15.7791 8.68322 15.4791L17.4032 6.75914C18.7432 5.41914 18.7432 3.11914 17.4032 1.77914L16.6632 0.999141C15.3232 -0.340859 13.0032 -0.340859 11.6632 0.999141L2.94322 9.73914C2.66322 10.0191 2.46322 10.4191 2.36322 10.7991L1.60322 14.4391C1.50322 15.1191 1.70322 15.7791 2.18322 16.2591C2.56322 16.6391 3.12322 16.8391 3.60322 16.8391ZM4.28322 11.1791L13.0032 2.43914C13.5832 1.85914 14.6432 1.85914 15.2032 2.43914L15.9632 3.19914C16.6432 3.87914 16.6432 4.83914 15.9632 5.49914L7.26322 14.2391L3.56322 14.8591L4.28322 11.1791Z'
          fill='white'
        />
        <path
          d='M17.3216 18.0801H2.56156C1.98156 18.0801 1.60156 18.4601 1.60156 19.0401C1.60156 19.6201 2.08156 20.0001 2.56156 20.0001H17.2416C17.8216 20.0001 18.3016 19.6201 18.3016 19.0401C18.2816 18.4601 17.8016 18.0801 17.3216 18.0801Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_3314_9539'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </Svg>
  );
}

const Svg = styled('svg')(({ theme }) => ({
  cursor: 'pointer',
  transition: 'all .3s',
  opacity: 1,
  '&:hover': {
    opacity: 0.7,
  },
  [theme.betweenDM]: {
    height: theme.pxToVw(20),
    width: 'auto',
  },
}));
