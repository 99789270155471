import React from 'react';
import { useTranslation } from 'react-i18next';

import { splitStringNewLineBreak } from '../../../../utils/string-utils';

import { GenerateProfileInput } from '../../../../components/common/GenerateProfile/GenerateProfileInput';

import Styled from './MainSection.styles';
import { AddressInputBackgroundLogo } from '../../../../components/common/AddressInput/AddressInputBackgroundLogo';

export const MainSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Styled.Wrapper id='main-gen-prof'>
      <AddressInputBackgroundLogo elId='generate-profile-input' />
      <Styled.Container>
        <Styled.Heading>
          {splitStringNewLineBreak(t('generateProfile.heading'))}
        </Styled.Heading>
        <Styled.SearchInfo>{t('generateProfile.searchInfo')}</Styled.SearchInfo>
        <GenerateProfileInput showSocial withTextButton />
      </Styled.Container>
    </Styled.Wrapper>
  );
};
