import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Web3OnboardProvider } from '@web3-onboard/react';
import { hotjar } from 'react-hotjar';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { AnalyticsService } from './services/AnalyticsService';
import { web3Onboard } from './config/web3-onboard';

import { AuthProvider } from './providers/AuthProvider';
import { BlockemExtensionProvider } from './providers/BlockemExtensionProvider';

import { App } from './App';

import { Buffer } from 'buffer';

import reportWebVitals from './reportWebVitals';
import { envs } from './config/envs';

// TODO: Init only on prod
AnalyticsService.init();

if (envs.hotjar.id) {
  hotjar.initialize(+envs.hotjar.id, 6);
}

if (envs.sentryDsn) {
  Sentry.init({
    dsn: envs.sentryDsn,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

window.Buffer = window.Buffer || Buffer;

const root = createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Web3OnboardProvider web3Onboard={web3Onboard}>
        <AuthProvider>
          <BlockemExtensionProvider>
            <App />
          </BlockemExtensionProvider>
        </AuthProvider>
      </Web3OnboardProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
