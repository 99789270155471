import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { joiLib } from '../../../joi';
import { RegisterPayload } from '../../../api/AuthApi';
import i18n from '../../../i18n';

const schema = Joi.object({
  username: Joi.string()
    .min(4)
    .required()
    .custom((value, helper) => {
      return value.includes('.') ? helper.message(i18n.t('errors.usernameDot')) : true;
    })
    .messages({
      'any.required': i18n.t('errors.fieldRequired'),
      'string.empty': i18n.t('errors.fieldRequired'),
      'string.min': i18n.t('errors.usernameCharsCount'),
    }),
  password: joiLib.password.required(),
  confirmPassword: joiLib.confirmPassword().required(),
  terms: Joi.bool()
    .equal(true)
    .required()
    .messages({
      'any.only': i18n.t('errors.terms'),
    }),
  addresses: Joi.array()
    .items(
      Joi.object({ address: Joi.string().required(), signature: Joi.string().required() })
    )
    .min(1)
    .messages({
      'any.required': i18n.t('errors.walletRequired'),
      'array.min': i18n.t('errors.walletRequired'),
    }),
});

export const useRegisterForm = () => {
  const form = useForm<RegisterPayload & { confirmPassword: string; terms: boolean }>({
    defaultValues: {
      username: '',
      password: '',
      confirmPassword: '',
      terms: false,
    },
    resolver: joiResolver(schema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  return form;
};
