import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartArea,
  ChartData,
  registerables,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ProjectAnalyticsTransactionItem } from '../../../../../../types';

import { calcDaysBetween, toValidDate } from '../../../../../../utils/date-utils';
import { useWindowWidth } from '../../../../../../hooks/useWindowWidth';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ...registerables
);

interface Props {
  data: ProjectAnalyticsTransactionItem[];
}

interface MappedData {
  [key: string]: {
    inTxCount: number;
    outTxCount: number;
  };
}

function createPrimaryGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
  const gradient = ctx.createConicGradient(180, area.bottom, area.top);

  gradient.addColorStop(0, '#0065FC');
  gradient.addColorStop(0.2, '#0065FC');
  gradient.addColorStop(1, 'rgba(0, 101, 252, 0.4)');

  return gradient;
}

// function createSecondaryGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
//   const gradient = ctx.createConicGradient(180, area.bottom, area.top);

//   gradient.addColorStop(0, 'rgba(255, 255, 255, 0.38)');
//   gradient.addColorStop(0.2, '#FFFFFF');
//   gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

//   return gradient;
// }

export const FrequencyOfTransactionsChart: React.FC<Props> = ({ data }) => {
  const theme = useTheme();
  const ref = useRef<ChartJS>(null);

  const width = useWindowWidth();

  const isMobile = width < 480;

  const [chartData, setChartData] = useState<ChartData<'bar'>>({
    labels: [],
    datasets: [],
  });

  const mappedData: MappedData = useMemo(() => {
    const { length: itemsCount } = data;
    if (itemsCount === 0) return {};

    const groupBy = itemsCount < 35 ? 'days' : itemsCount > 120 ? 'months' : 'weeks';

    const day1Date = toValidDate(data[0].executionDate);

    return data.reduce((mappedValues, item) => {
      const { inTxCount, outTxCount, executionDate } = item;

      if (inTxCount + outTxCount === 0) return mappedValues;

      const date = toValidDate(executionDate);

      const month = date.getMonth();
      const year = String(date.getFullYear()).split('').slice(-2).join('');
      const week = Math.ceil(calcDaysBetween(day1Date, date) / 7) || 1;

      const label =
        groupBy === 'months'
          ? `${month + 1}/${year}`
          : groupBy === 'days'
          ? date.toLocaleDateString('en-US', {
              month: 'numeric',
              year: '2-digit',
              day: '2-digit',
            })
          : `Week ${week}`;

      if (!mappedValues[label]) {
        mappedValues[label] = { inTxCount, outTxCount };
      } else {
        mappedValues[label].inTxCount += inTxCount;
        mappedValues[label].outTxCount += outTxCount;
      }
      return mappedValues;
    }, {} as MappedData);
  }, [data]);

  const { current: chart } = ref;

  useEffect(() => {
    if (!chart) return;

    const labels = Object.keys(mappedData).filter(
      label => mappedData[label].inTxCount + mappedData[label].outTxCount > 0
    );

    const inTx = {
      label: 'Txn In',
      data: labels.map(dateLabel => mappedData[dateLabel].inTxCount),
      backgroundColor: createPrimaryGradient(chart.ctx, chart.chartArea),
      minBarLength: 6,
      maxBarThickness: 32,
    };

    const outTx = {
      label: 'Txn Out',
      data: labels.map(dateLabel => mappedData[dateLabel].outTxCount),
      backgroundColor: theme.colors.primary.main,
      minBarLength: 6,
      maxBarThickness: 32,
    };

    const nextChartData: ChartData<'bar'> = {
      labels,
      datasets: [],
    };

    if (labels.length < 3) {
      nextChartData.labels?.push('', '', '', '', '', '');
    }

    if (Math.max(...inTx.data) > 0) {
      nextChartData.datasets.push(inTx);
    }

    if (Math.max(...outTx.data) > 0) {
      nextChartData.datasets.push(outTx);
    }

    setChartData(nextChartData);
  }, [chart, mappedData, theme.colors.primary.main]);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    borderRadius: 8,
    plugins: {
      legend: {
        position: 'bottom' as const,
        display: true,
        align: 'end' as const,
        labels: {
          font: {
            size: isMobile ? 12 : 16,
            weight: '700',
            family: theme.fontFamily,
          },
          color: '#fff',
          boxWidth: 8,
          boxHeight: 8,
          useBorderRadius: true,
          borderRadius: 4,
          padding: 10,
        },
      },
      title: {
        display: false,
        text: 'Chart.js Bar Chart',
      },
      tooltip: {
        backgroundColor: 'rgba(30, 30, 30, 0.8)',
        titleColor: theme.colors.primary.main,
        titleFont: {
          size: 10,
          weight: '700',
          family: theme.fontFamily,
        },
        bodyColor: '#fff',
        bodyFont: {
          size: 10,
          weight: '700',
          family: theme.fontFamily,
        },
        titleMarginBottom: 4,
        boxPadding: 6,
        boxWidth: 10,
        boxHeight: 10,
        boxBorderRadius: 3,
      },
    },
    scales: {
      x: {
        display: true,
        // type: 'time',
        time: {
          unit: 'day',
          unitStepSize: 1000,
          displayFormats: {
            millisecond: 'MMM DD',
            second: 'MMM DD',
            minute: 'MMM DD',
            hour: 'MMM DD',
            day: 'MMM DD',
            week: 'MMM DD',
            month: 'MMM DD',
            quarter: 'MMM DD',
            year: 'MMM DD',
          },
        },
        distribution: 'series',
        ticks: {
          autoSkip: true,
          maxTicksLimit: isMobile ? 3 : 7,
          maxRotation: 0,
          minRotation: 0,
          color: 'rgba(255, 255, 255, 0.4)',
          font: {
            weight: '700',
          },
        },
        border: {
          color: 'rgba(255, 255, 255, 0.17)',
        },
      },
      y: {
        scaleLabel: {
          display: true,
          labelString: 'value',
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 3,
          color: 'rgba(255, 255, 255, 0.4)',
          font: {
            weight: '700',
          },
        },
        border: {
          color: 'rgba(255, 255, 255, 0.17)',
        },
      },
    },
  };

  return (
    <div style={{ height: 360 }}>
      <Bar ref={ref} options={options} data={chartData} />
    </div>
  );
};
