import React from 'react';
import { useTranslation } from 'react-i18next';

import { useProfileTotalAssets } from '../../../../../react-query/profile/useProfileTotalAssets';

import { AssetDetailCardBase } from './AssetDetailCardBase';
import { Erc20TokensSvg } from '../../../../../assets/svgs/reputation-svgs';
import { getDisplayValue } from '../../../../../utils/data-utils';

export const AssetDetailErcTokens: React.FC = () => {
  const { t } = useTranslation('analysisResult');

  const { data: totalAssets } = useProfileTotalAssets();

  return (
    <AssetDetailCardBase
      label={t('erc20Tokens.title')}
      value={
        totalAssets ? getDisplayValue(totalAssets.erc20BalanceUsd, { prefix: '$' }) : ''
      }
      additionalValue={
        totalAssets ? getDisplayValue(totalAssets.erc20BalanceEth, { suffix: 'ETH' }) : ''
      }
      badge={`${getDisplayValue(totalAssets?.erc20Count)}` ?? ''}
      icon={<Erc20TokensSvg />}
    />
  );
};
