import { createGlobalStyle } from 'styled-components';

const GlobalCss = createGlobalStyle(({ theme }) => ({
  '*': {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
    fontFamily: "'Red Hat Display', sans-serif",
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
  },
  html: {
    color: '#fff',
    fontSize: '62.5%',
    '@media (max-width: 1400px)': {
      fontSize: '55%',
    },
    '@media (max-width: 768px)': {
      fontSize: '62.5%',
    },
  },
  a: {
    textDecoration: 'none',
  },
  body: {
    fontFamily: "'Red Hat Display', sans-serif",
    backgroundColor: theme.backgrounds.app,
    overflow: 'hidden',
  },
  '#root': {
    minHeight: '100vh',
    minWidth: '100vw',
    maxHeight: '100vh',
    maxWidth: '100vw',
    position: 'relative',
    overflow: 'hidden',
  },
  '#modal-root': {
    postion: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 20,
    maxHeight: '100vh',
    maxWidth: '100vw',
    overflow: 'hidden',
  },
}));

export { GlobalCss };
