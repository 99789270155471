import React, { useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AppMessagesList } from '../../providers/AppMessageProvider/AppMessagesList';
import { AppMessagesProvider } from '../../providers/AppMessageProvider/AppMessageProvider';

import { PageLoader } from '../ui/loaders/PageLoader';
import { Footer } from './Footer/Footer';
import { Header } from './HeaderV2/Header';

import Styled from './Layout.styles';

interface Props {
  children?: React.ReactNode;
  isLoading?: boolean;
}

export const Layout: React.FC<Props> = React.memo(function PageComponent({
  children,
  isLoading,
}) {
  const { pathname } = useLocation();
  const [activeHeader, setActiveHeader] = useState(false);
  const [currentPathname, setCurrentPathname] = useState(pathname);

  const ref = useRef<HTMLDivElement>(null);
  const { current } = ref;

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const { scrollTop } = e.target as HTMLDivElement;

    if (scrollTop > 120 && !activeHeader) setActiveHeader(true);
    if (activeHeader && scrollTop <= 120) setActiveHeader(false);
  };

  useLayoutEffect(() => {
    if (!current || pathname === currentPathname) return;
    current.scrollTo({ top: 0 });
    setCurrentPathname(pathname);
  }, [pathname, current, currentPathname]);

  return (
    <AppMessagesProvider>
      <Styled.HeaderWrapper activeHeader={activeHeader}>
        <Header />
      </Styled.HeaderWrapper>
      <Styled.Container>
        <Styled.ContentWrapper ref={ref} onScroll={onScroll}>
          {isLoading ? (
            <div style={{ height: '80vh', paddingTop: '10vh' }}>
              <PageLoader />
            </div>
          ) : (
            <div>{children}</div>
          )}
          <Footer />
        </Styled.ContentWrapper>
        <AppMessagesList />
      </Styled.Container>
      <AppMessagesList />
    </AppMessagesProvider>
  );
});
