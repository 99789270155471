import styled from 'styled-components';
import { InputAlikeButton as InputAlikeButtonEl } from '../../components/ui/buttons/InputAlikeButton';
import { Heading2 } from '../../components/ui/text/Heading';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '100px 60px 120px 60px',
  margin: '0 auto',
  width: 'max-content',
  gap: 20,
  [theme.max(theme.breakpoints.sm)]: {
    padding: '100px 20px 80px 20px',
    width: '100%',
  },
}));

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 16,
  [theme.max(theme.breakpoints.sm)]: {
    flexDirection: 'column',
    width: '100%',
  },
}));

const ImageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  cursor: 'pointer',
  '& p': {
    textAlign: 'center',
    fontSize: 11,
  },
  [theme.max(theme.breakpoints.sm)]: {
    margin: '0 auto',
  },
}));

const FormWrapper = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  width: '100vw',
  maxWidth: 494,
  [theme.betweenDM]: {
    maxWidth: theme.pxToVw(494),
  },
  [theme.max(theme.breakpoints.sm)]: {
    width: '100%',
    margin: '0 auto',
  },
}));

const Title = styled(Heading2)(({ theme }) => ({
  fontWeight: 900,
  lineHeight: 1.2,
  [theme.max(theme.breakpoints.sm)]: {
    margin: '0 auto',
  },
}));

const ButtonsWrapper = styled('div')<{ disabled?: boolean }>(({ theme, disabled }) => ({
  display: 'flex',
  gap: 10,
  marginTop: 16,
  '& button': {
    flex: 1,
    '&:first-child': {
      opacity: disabled ? '0.2 !important' : 1,
    },
  },
  [theme.max(400)]: {
    flexDirection: 'column',
    '& button': {
      flex: 'auto',
    },
  },
}));

const PasswordButtonContent = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  marginRight: 'auto',
  fontWeight: 600,
  fontSize: 14,
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(14),
  },
}));

export const InputAlikeButton = styled(InputAlikeButtonEl)({
  '& span': {
    width: '100% !important',
  },
});

const Styled = {
  Container,
  Title,
  Content,
  ImageWrapper,
  FormWrapper,
  ButtonsWrapper,
  PasswordButtonContent,
  InputAlikeButton,
};

export default Styled;
