import styled from 'styled-components';

const BackdropEl = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  display: 'flex',
  justifyContent: 'center',
  zIndex: theme.zIndex.ul,
  maxHeight: '100vh',
  maxWidth: '100vw',
  overflow: 'hidden',
}));

const Styled = { BackdropEl };

export default Styled;
