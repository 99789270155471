import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useRequest } from '../../../../../../hooks/useRequest';

import { ButtonPrimary } from '../../../../../../components/ui/buttons';
import { InputBase } from '../../../../../../components/ui/forms/Input/InputBase';
import { ErrorMessage } from '../../../../../../components/ui/forms/FormFieldWrapper/ErrorMessage';

import { ProjectApi } from '../../../../../../api/ProjectApi/ProjectApi';
import { joiLib } from '../../../../../../joi';
import { Heading4 } from '../../../../../../components/ui/text/Heading';
import { Text as TextEl } from '../../../../../../components/ui/text/Text';

export const NonNftProjectContent: React.FC = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  const handleSubmit = async () => {
    const { error } = joiLib.email.required().validate(email);

    if (error) throw new Error(t('errors.invalidEmail'));

    await ProjectApi.subscribe(email);
  };

  const { call, isLoading, isError, isSuccess } = useRequest(handleSubmit);

  const errMsg =
    isError === 'ALREADY_SUBSCRIBED' ? t('errors.ALREADY_SUBSCRIBED') : isError;

  return (
    <Wrapper>
      <Title>{t('projectPage.nonNftDetails.title')}</Title>
      <Text>{t('projectPage.nonNftDetails.description')}</Text>
      <FormWrapper>
        <Col>
          <Input
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder={t('common.enterEmailAddress')}
            error={!!isError || false}
            width='100%'
          />
          {errMsg && <ErrorMessage error={errMsg} />}
          {isSuccess && <SuccessMsg>{t('common.subscribeSuccessMessage')}</SuccessMsg>}
        </Col>
        <ButtonPrimary isLoading={isLoading} onClick={call} disabled={isSuccess}>
          {t('common.subscribe')}
        </ButtonPrimary>
      </FormWrapper>
    </Wrapper>
  );
};

const Col = styled('div')({ display: 'flex', flexDirection: 'column', gap: 4 });

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [theme.min(theme.breakpoints.md)]: {
    maxWidth: 400,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    maxWidth: theme.pxToVw(720),
  },
  [theme.min(1920)]: {
    maxWidth: 720,
  },
}));

const Title = styled(Heading4)(({ theme }) => ({
  lineHeight: 1.32,
  fontWeight: 700,
  color: theme.colors.primary.main,
  marginBottom: 12,
  [theme.betweenDM]: {
    marginBottom: theme.pxToVw(12),
  },
}));

const Text = styled(TextEl.MD)(({ theme }) => ({
  lineHeight: 1.6,
  fontWeight: 600,
  transition: 'all .3s',
  marginBottom: 14,
  [theme.min(theme.breakpoints.md)]: {
    marginBottom: 24,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    marginBottom: theme.pxToVw(38),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginBottom: 38,
  },
}));

const Input = styled(InputBase)({
  flex: 1,
});

const FormWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  width: '100%',
  '& > *:first-child': {
    flex: 1,
  },
  '& input': {
    minHeight: 56,
  },
  '& button': {
    width: '100%',
    minHeight: 56,
  },
  [theme.min(theme.breakpoints.sm)]: {
    flexDirection: 'row',
    '& button': {
      width: '30%',
      maxWidth: 248,
    },
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(8),
    '& input': {
      minHeight: theme.pxToVw(56),
    },
    '& button': {
      maxWidth: theme.pxToVw(248),
    },
  },
}));

const SuccessMsg = styled('p')(({ theme }) => ({
  fontSize: 11,
  color: theme.colors.status.success,
  lineHeight: 1.32,
  fontWeight: 700,
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(11),
  },
}));
