import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  isNonNftProjectError,
  useProjectByParamId,
} from '../../../../../../react-query/project/useProject';

import { ClipboardButton } from '../../../../../../components/ui/buttons/ClipboardButton/ClipboardButton';
import { LoadingBackground } from '../../../../../../components/ui/loaders/LoadingBackground';

import { formatDateToLocale } from '../../../../../../utils/date-utils';
import { shortenEthAddress } from '../../../../../../utils/address-utils';

export const ProjectCreatedAtAndContractAddress: React.FC = () => {
  const { t } = useTranslation();
  const { data: project, isLoading, error } = useProjectByParamId();
  const { project: projectParam } = useParams();

  const renderContent = () => {
    if (isLoading || !project)
      return (
        <>
          <Loader>
            <Text>L</Text>
          </Loader>
          <Loader>
            <Text>L</Text>
          </Loader>
        </>
      );

    const isNonNft = isNonNftProjectError(error);

    if (isNonNft && projectParam)
      return (
        <Text>
          {shortenEthAddress(projectParam.toLowerCase())} <Clip value={projectParam} />
        </Text>
      );

    return (
      <>
        <Text>
          <span>{t('common.created')}:</span>{' '}
          {project && formatDateToLocale(project.contractCreatedAt, { day: false })}
        </Text>
        {project && (
          <Text>
            {shortenEthAddress(project.contractAddress.toLowerCase())}{' '}
            <Clip value={project.contractAddress} />
          </Text>
        )}
      </>
    );
  };

  return (
    <Col>
      <DetailsWrapper>{renderContent()}</DetailsWrapper>
    </Col>
  );
};

const Loader = styled(LoadingBackground)(({ theme }) => ({
  '& p': {
    color: 'transparent !important',
  },
  width: '45%',
  maxWidth: 250,
  [theme.betweenDM]: {
    maxWidth: theme.pxToVw(250),
  },
}));

const Col = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 14,
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(12),
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 12,
  },
}));

const Flex = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const DetailsWrapper = styled(Flex)(({ theme }) => ({
  justifyContent: 'space-between',
  [theme.min(theme.breakpoints.xs)]: {
    justifyContent: 'flex-start',
    gap: 32,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(36),
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 36,
  },
}));

const Text = styled('p')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: 14,
  fontWeight: 700,
  lineHeight: 1.3,
  whiteSpace: 'nowrap',
  '& span': {
    color: 'rgba(255, 255, 255, 0.6)',
    marginRight: 2,
  },
  [theme.min(theme.breakpoints.md)]: {
    fontSize: 16,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(20),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 20,
  },
}));

const Clip = styled(ClipboardButton)(({ theme }) => ({
  marginLeft: 0,
  padding: '0 6px',
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(14),
    padding: `0 ${theme.pxToVw(6)}`,
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 14,
    padding: '0 6px',
  },
}));
