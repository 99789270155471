import styled from 'styled-components';

interface Props {
  onEdit: () => void;
  onDelete: () => void;
  isLoading: boolean;
  isEditable: boolean;
}

export default function ActionIcons({ onEdit, onDelete, isLoading, isEditable }: Props) {
  return (
    <Wrapper>
      <Svg
        width='13'
        height='16'
        viewBox='0 0 13 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        isLoading={isLoading}
        onClick={onDelete}
      >
        <path
          d='M11.75 2H9V1.5C9 0.672906 8.32709 0 7.5 0H5.5C4.67291 0 4 0.672906 4 1.5V2H1.25C0.56075 2 0 2.56075 0 3.25V5C0 5.27612 0.223875 5.5 0.5 5.5H0.77325L1.20522 14.5713C1.24338 15.3725 1.9015 16 2.7035 16H10.2965C11.0985 16 11.7567 15.3725 11.7948 14.5713L12.2268 5.5H12.5C12.7761 5.5 13 5.27612 13 5V3.25C13 2.56075 12.4392 2 11.75 2ZM5 1.5C5 1.22431 5.22431 1 5.5 1H7.5C7.77569 1 8 1.22431 8 1.5V2H5V1.5ZM1 3.25C1 3.11216 1.11216 3 1.25 3H11.75C11.8878 3 12 3.11216 12 3.25V4.5C11.8459 4.5 1.63853 4.5 1 4.5V3.25ZM10.7959 14.5238C10.7832 14.7908 10.5638 15 10.2965 15H2.7035C2.43616 15 2.21678 14.7908 2.20409 14.5238L1.77437 5.5H11.2256L10.7959 14.5238Z'
          fill='#FC5757'
        />
        <path
          d='M6.5 14C6.77613 14 7 13.7761 7 13.5V7C7 6.72387 6.77613 6.5 6.5 6.5C6.22387 6.5 6 6.72387 6 7V13.5C6 13.7761 6.22384 14 6.5 14Z'
          fill='#FC5757'
        />
        <path
          d='M9 14C9.27613 14 9.5 13.7761 9.5 13.5V7C9.5 6.72387 9.27613 6.5 9 6.5C8.72387 6.5 8.5 6.72387 8.5 7V13.5C8.5 13.7761 8.72384 14 9 14Z'
          fill='#FC5757'
        />
        <path
          d='M4 14C4.27613 14 4.5 13.7761 4.5 13.5V7C4.5 6.72387 4.27613 6.5 4 6.5C3.72387 6.5 3.5 6.72387 3.5 7V13.5C3.5 13.7761 3.72384 14 4 14Z'
          fill='#FC5757'
        />
      </Svg>
      {!isEditable && (
        <Svg
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          isLoading={isLoading}
          onClick={onEdit}
        >
          <g clipPath='url(#clip0_3755_4600)'>
            <path
              d='M3.59931 16.8401C3.79931 16.8401 3.83931 16.8201 4.01931 16.7801L7.61931 16.0601C7.99931 15.9601 8.37931 15.7801 8.67931 15.4801L17.3993 6.76012C18.7393 5.42012 18.7393 3.12012 17.3993 1.78012L16.6593 1.00012C15.3193 -0.339883 12.9993 -0.339883 11.6593 1.00012L2.93931 9.74012C2.65931 10.0201 2.45931 10.4201 2.35931 10.8001L1.59931 14.4401C1.49931 15.1201 1.69931 15.7801 2.17931 16.2601C2.55931 16.6401 3.11931 16.8401 3.59931 16.8401V16.8401ZM4.27931 11.1801L12.9993 2.44012C13.5793 1.86012 14.6393 1.86012 15.1993 2.44012L15.9593 3.20012C16.6393 3.88012 16.6393 4.84012 15.9593 5.50012L7.25931 14.2401L3.55931 14.8601L4.27931 11.1801Z'
              fill='white'
            />
            <path
              d='M17.3196 18.0801H2.55961C1.97961 18.0801 1.59961 18.4601 1.59961 19.0401C1.59961 19.6201 2.07961 20.0001 2.55961 20.0001H17.2396C17.8196 20.0001 18.2996 19.6201 18.2996 19.0401C18.2796 18.4601 17.7996 18.0801 17.3196 18.0801Z'
              fill='white'
            />
          </g>
          <defs>
            <clipPath id='clip0_3755_4600'>
              <rect width='20' height='20' fill='white' />
            </clipPath>
          </defs>
        </Svg>
      )}
    </Wrapper>
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  top: 16,
  right: 18,
  gap: 16,
  [theme.min(theme.breakpoints.desktop)]: {
    top: theme.pxToVw(32),
    right: theme.pxToVw(32),
    gap: theme.pxToVw(18),
  },
  [theme.min(theme.breakpoints.max)]: {
    top: 32,
    right: 32,
    gap: 18,
  },
}));

const Svg = styled('svg')<{ isLoading: boolean }>(({ theme, isLoading }) => ({
  cursor: 'pointer',
  transition: 'all .3s',
  pointerEvents: isLoading ? 'none' : 'auto',
  '&:hover': {
    opacity: 0.75,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    height: theme.pxToVw(20),
    width: 'auto',
    top: theme.pxToVw(32),
    right: theme.pxToVw(32),
  },
  [theme.min(theme.breakpoints.max)]: {
    height: 20,
    top: 32,
    right: 32,
  },
}));
