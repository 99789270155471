import { useQuery } from 'react-query';
import { UserApi } from '../../api/UserApi/UserApi';
import { useAuth } from '../../providers/AuthProvider';
import { useProjectByParamId } from '../project/useProject';
import { QueryKey } from '../query-keys';

export const useEndorsments = (projectId: string | null | undefined) => {
  const { isAuthenticated } = useAuth();

  return useQuery(
    QueryKey.ProjectEndorsments(projectId!),
    () => UserApi.getProjectEndorsments(projectId!),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: !!projectId && isAuthenticated,
    }
  );
};

export const useEndorsmentsByProjectId = () => {
  const { data: project } = useProjectByParamId();

  return useEndorsments(project?.uid);
};
