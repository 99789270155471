import React from 'react';

import { BackgroundBlockemLogo } from '../../common/Backgrounds/BackgroundBlockemLogo';
import { Form } from '../../forms/Form';

import Styled from './AuthLayout.styles';

interface Props {
  children: React.ReactNode;
  title: string;
  Wizzard?: React.ReactNode;
  bgLogoPosition?: 'left' | 'right' | 'center';
  onSubmit?: React.FormEventHandler;
}

export const AuthLayout: React.FC<Props> = ({
  children,
  title,
  bgLogoPosition,
  Wizzard = null,
  onSubmit,
}) => {
  return (
    <Styled.ContainerEl>
      {bgLogoPosition && <BackgroundBlockemLogo position={bgLogoPosition}/>}
      <Styled.Wrapper>
        <Styled.Title>{title}</Styled.Title>
        {Wizzard}
        <Form as={onSubmit ? 'form' : 'div'} onSubmit={onSubmit}>
          {children}
        </Form>
      </Styled.Wrapper>
    </Styled.ContainerEl>
  );
};
