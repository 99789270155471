import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { LoginPayload } from '../../../api/AuthApi';
import { joiLib } from '../../../joi';
import i18n from '../../../i18n';

const schema = Joi.object({
  username: Joi.string()
    .min(4)
    .required()
    .messages({
      'any.required': i18n.t('errors.fieldRequired'),
      'string.empty': i18n.t('errors.fieldRequired'),
      'string.min': i18n.t('errors.usernameCharsCount'),
    }),
  password: joiLib.password.required(),
});

export const useLoginForm = () => {
  const form = useForm<LoginPayload>({
    defaultValues: {
      username: '',
      password: '',
    },
    resolver: joiResolver(schema),
  });

  return form;
};
