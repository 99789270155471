import React, { useState } from 'react';
import { FieldError } from 'react-hook-form';

import { FormFieldWrapper } from '../FormFieldWrapper/FormFieldWrapper';

import { InputBase } from './InputBase';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: boolean | string | Error | FieldError;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  width?: string | number;
  editable?: boolean;
  activeBackground?: boolean;
}

export const InputField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      labelProps,
      error,
      width = '100%',
      required,
      onChange,
      editable = true,
      activeBackground = false,
      ...inputProps
    },
    ref
  ) => {
    const [val, setVal] = useState(inputProps.value);

    return (
      <FormFieldWrapper
        error={
          typeof error === 'string' || typeof error === 'boolean' ? error : error?.message
        }
        label={label}
        required={required}
        fieldHasValue={!!inputProps.value || !!val}
        {...labelProps}
      >
        <InputBase
          error={!!error}
          ref={ref}
          autoComplete='off'
          spellCheck={false}
          width={width}
          hasValue={!!val}
          editable={editable}
          activeBackground={activeBackground}
          onChange={e => {
            setVal(e.target.value);
            typeof onChange === 'function' && onChange(e);
          }}
          {...inputProps}
        />
      </FormFieldWrapper>
    );
  }
);
