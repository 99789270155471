import React from 'react';
import styled from 'styled-components';

import { FooterGetInTouchSection } from '../components/GetInTouchSection/FooterGetInTouchSection';
import { FooterLinksSection } from '../components/LinksSection/FooterLinksSection';
import { FooterLogoSection } from '../components/LogoSection/FooterLogoSection';
import { FooterPrivacyAndTermsSection } from '../components/PrivacyAndTermsSection/FooterPrivacyAndTermsSection';
import { FooterSocialSection } from '../components/SocialSection/FooterSocialSection';

export const DesktopFooter: React.FC = () => {
  return (
    <>
      <Group1>
        <FooterLogoSection />
        <FooterLinksSection />
      </Group1>
      <Group2>
        <FooterSocialSection />
        <FooterPrivacyAndTermsSection />
      </Group2>
      <FooterGetInTouchSection />
    </>
  );
};

const Col = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

const Group1 = styled(Col)(({ theme }) => ({
  gap: '4rem',
  [theme.betweenDM]: {
    gap: theme.pxToVw(40),
  },
}));

const Group2 = styled(Col)(({ theme }) => ({
  gap: '5rem',
  [theme.betweenDM]: {
    gap: theme.pxToVw(50),
  },
}));
