import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { axiosInstance } from '../../../../../config/axios';
import { useRequest } from '../../../../../hooks/useRequest';

import { ButtonPrimary, ButtonSize, ButtonVariant } from '../../../../ui/buttons';
import { ContentLoader } from '../../../../ui/loaders/ContentLoader';
import { ActionText } from '../../../../ui/text/ActionText';
import { ReactComponent as ClaimSvg } from './Claim.svg';
import { ReactComponent as CheckSvg } from './Check.svg';
import { useWindowWidth } from '../../../../../hooks/useWindowWidth';

export const ProfileBadgeUsername = styled(ActionText)<{ $disabled?: boolean }>(
  ({ theme, $disabled }) => ({
    fontSize: 14,
    padding: '4px 0',
    pointerEvents: $disabled ? 'none' : 'auto',
    [theme.min(theme.breakpoints.xs)]: {
      fontSize: 16,
      padding: '8px 0',
    },
    [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
      fontSize: theme.pxToVw(16),
    },
  })
);

export const DetailText = styled('span')(({ theme }) => ({
  fontSize: 12,
  color: 'rgba(255, 255, 255, 0.6)',
  lineHeight: 1.3,
  fontWeight: 700,
  marginBottom: 12,
  [theme.min(theme.breakpoints.xs)]: {
    fontSize: 16,
    marginBottom: 20,
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    marginBottom: theme.pxToVw(20),
    fontSize: theme.pxToVw(16),
  },
}));

export const FlexWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    gap: theme.pxToVw(16),
  },
}));

const ActionTextBase = styled(ActionText)(({ theme }) => ({
  fontSize: 12,
  width: 'max-content',
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    fontSize: theme.pxToVw(12),
  },
}));

export const SeeThisProject = styled(ActionTextBase)(({ theme }) => ({
  margin: '0 0 12px auto',
  marginRight: '0 !important',
  marginLeft: 'auto !important',
  [theme.min(theme.breakpoints.xs)]: {
    marginBottom: 20,
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    marginBottom: theme.pxToVw(20),
  },
}));

const ReportProfileText = styled(ActionTextBase)(({ theme, disabled }) => ({
  color: theme.colors.trust.Untrusted,
  opacity: disabled ? 0.5 : 1,
  '&:hover': {
    opacity: 0.85,
    color: theme.colors.trust.Untrusted,
  },
}));

export const ReportProfile: React.FC<{
  address: string;
  style?: React.CSSProperties;
}> = ({ address, style }) => {
  const { t } = useTranslation();

  const { call, isLoading, isSuccess } = useRequest(async () => {
    await axiosInstance.post(`/report?address=${address}`);
  });

  return (
    <ReportProfileText
      onClick={() => {
        if (!isSuccess && !isLoading) call();
      }}
      disabled={isLoading || isSuccess}
      underline={!isSuccess}
      style={style}
    >
      {t(`analysisResult.${isSuccess ? 'addressReported' : 'reportThisAddress'}`)}{' '}
      {isSuccess && <CheckSvg style={{ marginLeft: 2 }} />}
    </ReportProfileText>
  );
};

interface Props {
  count: number | null | undefined;
}

export const FollowersCount: React.FC<Props> = ({ count }) => {
  const { t } = useTranslation('common');
  return (
    <DetailText>{`${typeof count === 'number' ? count : ' '} ${t(
      'followers'
    )}`}</DetailText>
  );
};

export const FollowingsCount: React.FC<Props> = ({ count }) => {
  const { t } = useTranslation('common');
  return (
    <DetailText>{`${typeof count === 'number' ? count : ' '} ${t(
      'followings'
    )}`}</DetailText>
  );
};

export const EndorsmentsCount: React.FC<Props> = ({ count }) => {
  const { t } = useTranslation('common');
  return (
    <DetailText>{`${typeof count === 'number' ? count : ' '} ${t(
      'endorsments'
    )}`}</DetailText>
  );
};

interface DetailsWrapperProps {
  isLoading?: boolean;
  children: React.ReactNode;
}

export const DetailsWrapper: React.FC<DetailsWrapperProps> = ({
  isLoading,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const renderLoader = () => {
    const baseWidth = 289;
    const baseHeight = 121;
    const currHeight = containerRef.current?.clientHeight || baseHeight;
    const currWidth = containerRef.current?.clientWidth || baseWidth;

    return (
      <ContentLoader
        viewBox={`0 0 ${currWidth} ${currHeight}`}
        style={{ width: '100%', height: '100%' }}
      >
        <rect x='0' y='0' ry='16' rx='16' width={currWidth} height={currHeight} />
      </ContentLoader>
    );
  };

  return (
    <DetailsWrapperEl ref={containerRef}>
      {isLoading ? renderLoader() : children}
    </DetailsWrapperEl>
  );
};

const DetailsWrapperEl = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  flex: 1,
}));

interface BtnProps {
  isLoading?: boolean;
  isContract?: boolean;
  onClick: () => void;
}

export const AddThisToMyProfileButton: React.FC<BtnProps> = ({
  isContract,
  isLoading,
  onClick,
}) => {
  const { t } = useTranslation('common');
  const width = useWindowWidth();
  const theme = useTheme();

  const btnWidth = width < theme.breakpoints.desktop ? 280 : theme.pxToVw(280);
  return (
    <ButtonPrimary
      width={btnWidth}
      variant={ButtonVariant.Outlined}
      size={ButtonSize.Sm}
      onClick={onClick}
      isLoading={isLoading}
      style={{ maxWidth: '64vw', padding: 0 }}
    >
      {t('addThisToMyProfile', { type: t(isContract ? 'contract' : 'address') })}
    </ButtonPrimary>
  );
};

export const ClaimAndCreateProfileButton: React.FC<BtnProps> = ({
  onClick,
  isLoading,
}) => {
  const { t } = useTranslation('projectPage');
  const width = useWindowWidth();
  const theme = useTheme();

  const btnWidth = width < theme.breakpoints.desktop ? 280 : theme.pxToVw(280);
  return (
    <ButtonPrimary
      width={btnWidth}
      size={ButtonSize.Sm}
      onClick={onClick}
      isLoading={isLoading}
      style={{ maxWidth: '64vw', padding: 0 }}
    >
      {t('claimProject')} <Icon />
    </ButtonPrimary>
  );
};

const Icon = styled(ClaimSvg)(({ theme }) => ({
  marginLeft: 8,
  height: 16.5,
  width: 'auto',
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    height: theme.pxToVw(16.5),
  },
}));
