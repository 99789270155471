import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useLoginForm } from './useLoginForm';
import { useRequest } from '../../../hooks/useRequest';
import { useAuth } from '../../../providers/AuthProvider';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';

import { routes } from '../../../router/routes';
import { AuthApi, LoginPayload, LoginResponse } from '../../../api/AuthApi';
import { StorageKeys, StorageService } from '../../../services/StorageService';

import { ButtonPrimary } from '../../../components/ui/buttons';
import { InputField } from '../../../components/ui/forms/Input/InputField';
import { ActionText } from '../../../components/ui/text/ActionText';
import { AuthLayout } from '../../../components/layouts/AuthLayout/AuthLayout';
import { TextWithActionConcated } from '../components/TextWithActionConcated';
import { PasswordField } from '../../../components/ui/forms/Input/PasswordField';
import { ErrorTextBase } from '../../../components/ui/forms/FormFieldWrapper/ErrorMessage';

export const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const { setIsAuthenticated } = useAuth();
  const navigate = useNavigate();
  useDocumentTitle(t('auth.login'));

  const [invalidCreds, setInvalidCreds] = useState(false);

  const {
    formState: { errors },
    register,
    handleSubmit,
    getValues,
  } = useLoginForm();

  const onSubmit = useCallback(async () => {
    setInvalidCreds(false);
    const { username, password } = getValues();

    const payload: LoginPayload = {
      username,
      password,
    };

    return await AuthApi.login(payload);
  }, [getValues]);

  const { call, isLoading } = useRequest<LoginResponse>(onSubmit, {
    onSuccess: ({ token }) => {
      StorageService.save(StorageKeys.Token, `Bearer ${token}`);
      setIsAuthenticated(true);
      navigate(routes.profile.root());
    },
    onError: err => {
      if (err?.response?.data?.message === 'INVALID_CREDENTIALS') {
        setInvalidCreds(true);
      }
    },
  });

  return (
    <AuthLayout
      title={t('auth.login')}
      onSubmit={handleSubmit(call)}
      bgLogoPosition='left'
    >
      <InputField
        required
        placeholder={t('auth.username') + '*'}
        label={t('auth.username')}
        error={errors.username || invalidCreds}
        {...register('username')}
      />
      <PasswordField
        required
        placeholder={t('auth.password') + '*'}
        label={t('auth.password')}
        error={errors.password || invalidCreds}
        {...register('password')}
      />
      <Wrapper>
        {invalidCreds && <ErrorMessage>{t('errors.invalidCredentials')}</ErrorMessage>}
        <ActionText
          onClick={() => navigate(routes.auth.resetPassword())}
          style={{ fontSize: 14, marginLeft: 'auto', padding: '5px 0' }}
        >
          {t('auth.forgotPassword')}?
        </ActionText>
      </Wrapper>
      <ButtonPrimary width='100%' isLoading={isLoading}>
        {t('auth.login')}
      </ButtonPrimary>
      <TextWithActionConcated
        underline
        text={t('auth.notAMemberYet')}
        align='center'
        actionText={t('auth.registerHere')}
        onClick={() => navigate(routes.auth.register())}
      />
    </AuthLayout>
  );
};

const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

const ErrorMessage = styled(ErrorTextBase)({
  marginRight: 'auto',
  padding: '4px 8px',
});
