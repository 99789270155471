import React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useDocumentTitle } from '../../hooks/useDocumentTitle';

import { routes } from '../../router/routes';

import { ButtonPrimary } from '../../components/ui/buttons';
import { BackgroundBlockemLogo } from '../../components/common/Backgrounds/BackgroundBlockemLogo';

interface Payload {
  title: string;
  subtitle?: string;
}
export const ErrorPage: React.FC = () => {
  const { status } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { error } = (useLocation().state as { error?: string }) || {};

  const { title, subtitle } = t(`errorPage.${error || status}`, {
    returnObjects: true,
  }) as Partial<Payload>;

  useDocumentTitle(`Error ${status}`);

  return (
    <Wrapper>
      <BackgroundBlockemLogo position='center' />
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <Button onClick={() => navigate(routes.home(), { state: null })} width={211}>
        {t('common.goBack')}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled('main')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  alignItems: 'center',
  paddingTop: '16vh',
  width: '100%',
  minHeight: '90vh',
}));

const Title = styled('h2')(({ theme }) => ({
  fontSize: 38,
  fontWeight: 700,
  lineHeight: 1.188,
  textAlign: 'center',
  [theme.min(theme.breakpoints.lg)]: {
    fontSize: theme.pxToVw(64),
  },
  [theme.min(1920)]: {
    fontSize: 64,
  },
}));

const Subtitle = styled('p')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  marginTop: 12,
  [theme.min(theme.breakpoints.lg)]: {
    marginTop: 16,
    fontSize: 20,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    marginTop: theme.pxToVw(16),
    fontSize: theme.pxToVw(24),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginTop: 16,
    fontSize: 24,
  },
}));

const Button = styled(ButtonPrimary)(({ theme }) => ({
  marginTop: 20,
  [theme.min(theme.breakpoints.lg)]: {
    marginTop: 24,
  },
}));
