import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useUser } from './useUser';

import { UserApi } from '../../api/UserApi/UserApi';
import { QueryKey } from '../query-keys';
import { ProjectAdditionalDetails } from '../../types';

export const useUserProjects = () => {
  const { data: user } = useUser();

  return useQuery(QueryKey.UserProjects(), UserApi.getProjects, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: !!user,
  });
};

export const useUserProject = (projectId?: string | null | undefined) => {
  const { data: user } = useUser();

  return useQuery(
    QueryKey.UserProject(user?.uid!, projectId!),
    () => UserApi.getProject(projectId!),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: !!user && !!projectId,
    }
  );
};

export const useUserProjectByParamId = () => {
  const { id } = useParams();
  return useUserProject(id);
};

export const useUserProjectEdit = (projectUid: string | null | undefined) => {
  const { data: user } = useUser();

  const queryClient = useQueryClient();

  return useMutation(
    QueryKey.UserProject(user?.uid!, projectUid!),
    (details: ProjectAdditionalDetails) =>
      UserApi.patchProjectAdditionalDetails(projectUid!, details),
    {
      onSuccess: data => {
        queryClient.invalidateQueries(QueryKey.UserProjects());
        queryClient.invalidateQueries(QueryKey.Project(data.contractAddress));
        queryClient.invalidateQueries(QueryKey.UserProject(user?.uid!, projectUid!));
      },
    }
  );
};
