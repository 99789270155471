import Joi from 'joi';
import i18n from '../i18n';
import { isValidEthAddress } from '../utils/address-utils';

const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,}$/;
const invalidPasswordMsg =
  'Password must contain at least 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 symbol.';

const joiLib = {
  ethAddress: Joi.custom((value, helpers) => {
    if (!isValidEthAddress(value)) {
      return helpers.message(i18n.t('errors.invalidEthAddress'));
    }
    return true;
  }),
  // contractAddress: Joi.custom((value, helpers) => {
  //   if (!value) return helpers.message(i18n.t('errors.contractAddressRequired'));
  //   if (!isValidEthAddress(value)) {
  //     return helpers.message(i18n.t('errors.invalidEthAddress'));
  //   }
  //   return true;
  // }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .messages({
      'any.required': i18n.t('errors.invalidEmail'),
      'string.required': i18n.t('errors.invalidEmail'),
      'string.email': i18n.t('errors.invalidEmail'),
    }),
  password: Joi.string()
    .pattern(pattern)
    .message(invalidPasswordMsg)
    .messages({
      'any.required': i18n.t('errors.fieldRequired'),
      'string.empty': i18n.t('errors.fieldRequired'),
    }),
  confirmPassword: (field = 'password') =>
    Joi.any()
      .equal(Joi.ref(field))
      .label('Confirm password')
      .options({
        messages: {
          'any.only': 'Incorrect Password',
          'any.required': i18n.t('errors.fieldRequired'),
          'string.empty': i18n.t('errors.fieldRequired'),
        },
      }),
  phone: Joi.string().min(8).max(20),
  accounts: Joi.array().items(Joi.string()),
  matchSocialAddress: (address: 'discord' | 'twitter' | 'opensea') => {
    const pattern =
      address === 'discord'
        ? /discord/
        : address === 'twitter'
        ? /twitter/
        : address === 'opensea'
        ? /opensea/
        : /twitter|discord|opensea/;

    return Joi.string()
      .uri({ allowRelative: true })
      .pattern(pattern)
      .messages({
        'string.uri': i18n.t('errors.invalidUrlMatch', { address }),
        'string.pattern.base': i18n.t('errors.invalidUrlMatch', { address }),
      });
  },
};

export { joiLib };
