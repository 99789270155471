import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useReputationWithAddress } from '../../../../providers/AddressProvider/useReputationWithAddress';
import { useTrustCategory } from '../TrustCategoryProvider';
import { IWhereContext, useWhere } from '../../../../providers/WhereProvider';

import { splitStringNewLineBreak } from '../../../../utils/string-utils';
import { LoadingBackground } from '../../../ui/loaders/LoadingBackground';

export const AnalysisSummaryText: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useReputationWithAddress();
  const { trust } = useTrustCategory();
  const where = useWhere();

  if (!data || !trust)
    return (
      <LoaderWrapper where={where}>
        <Loader where={where}>L</Loader>
        <Loader where={where}>L</Loader>
        <Loader where={where}>L</Loader>
      </LoaderWrapper>
    );

  return (
    <Text where={where}>
      {splitStringNewLineBreak(
        t(`analysisResult.summary.${trust}.text`, {
          type: t(`common.${data.isContract ? 'contract' : 'address'}`).toLowerCase(),
        })
      )}
    </Text>
  );
};

const Text = styled('p')<{ where: IWhereContext }>(({ theme, where }) => ({
  fontWeight: 600,
  linHeight: 1.6,
  color: theme.colors.text.secondary,
  maxWidth: where === 'main' ? 700 : 720,
  fontSize: 14,
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: where === 'list' ? 14 : 16,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    marginRight: where === 'list' ? theme.pxToVw(12) : theme.pxToVw(14),
    fontSize: where === 'list' ? theme.pxToVw(16) : theme.pxToVw(20),
    maxWidth: where === 'main' ? theme.pxToVw(700) : theme.pxToVw(720),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginRight: where === 'list' ? 12 : 14,
    fontSize: where === 'list' ? 16 : 20,
    maxWidth: where === 'main' ? 700 : 720,
  },
}));

const LoaderWrapper = styled('div')<{ where: IWhereContext }>(({ theme, where }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  [theme.min(theme.breakpoints.sm)]: {
    gap: where === 'list' ? 5 : 6,
  },
  [theme.min(theme.breakpoints.xl)]: {
    gap: where === 'list' ? 6 : 7,
  },
  [theme.min(theme.breakpoints.ul)]: {
    gap: where === 'list' ? 6 : 8,
  },
}));

const Loader = styled(LoadingBackground)<{ where: IWhereContext }>(
  ({ theme, where }) => ({
    display: 'flex',
    borderRadius: 10,
    color: 'transparent',
    width: '100%',
    maxWidth: where === 'main' ? 700 : 720,
    fontSize: 20,
    [theme.min(theme.breakpoints.sm)]: {
      fontSize: where === 'list' ? 14 : 16,
    },
    [theme.min(theme.breakpoints.desktop)]: {
      fontSize: where === 'list' ? theme.pxToVw(16) : theme.pxToVw(20),
      maxWidth: where === 'main' ? theme.pxToVw(700) : theme.pxToVw(720),
    },
    [theme.min(theme.breakpoints.max)]: {
      fontSize: where === 'list' ? 16 : 20,
      maxWidth: where === 'main' ? 700 : 720,
    },
    '&:nth-child(2)': {
      width: '80%',
      maxWidth: where === 'main' ? 540 : 590,
      [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
        maxWidth: where === 'main' ? theme.pxToVw(540) : theme.pxToVw(590),
      },
    },
    '&:nth-child(3)': {
      width: '90%',
      maxWidth: where === 'main' ? 620 : 660,
      [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
        maxWidth: where === 'main' ? theme.pxToVw(620) : theme.pxToVw(660),
      },
    },
  })
);
