import styled from 'styled-components';

const FormEl = styled('form')<{ isLoading?: boolean }>(({ theme, isLoading }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  gap: 8,
  filter: isLoading ? 'grayscale(1)' : 'none',
  pointerEvents: isLoading ? 'none' : 'auto',
}));

const LoadingOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  filter: 'grayscale(0)',
});

interface Props extends React.FormHTMLAttributes<HTMLFormElement> {
  children: React.ReactNode;
  isLoading?: boolean;
  as?: any;
}

export const Form: React.FC<Props> = ({ children, isLoading, ...rest }) => (
  <FormEl isLoading={isLoading} {...rest}>
    {children}
    {isLoading && <LoadingOverlay>{/* <Spinner size={22} /> */}</LoadingOverlay>}
  </FormEl>
);
