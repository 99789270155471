import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useDocumentTitle } from '../../hooks/useDocumentTitle';

import { envs } from '../../config/envs';

import { Container } from '../../components/layouts/Container';
import { SocialIcons } from '../../components/common/SocialIcons/SocialIcons';
import { ActionText } from '../../components/ui/text/ActionText';
import { ReactComponent as BackgroundSvg } from './Background.svg';

export const ContactPage: React.FC = () => {
  const { t } = useTranslation();

  useDocumentTitle(t('contactPage.pageTitle'));

  return (
    <Wrapper>
      <Cont>
        <BgSvg />
        <Heading>
          We&apos;d <i>love</i> <br />
          to hear from you
        </Heading>
        <InfoContent>
          <BrightText>Reach us at</BrightText>
          <Email
            underline={false}
            onClick={() => window.open(`mailto:${envs.contactEmail}`, '_blank')}
          >
            {envs.contactEmail}
          </Email>
          <BrightText>or join our Social Networks</BrightText>
          <SocialIconsEl />
        </InfoContent>
      </Cont>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
}));

const BgSvg = styled(BackgroundSvg)(({ theme }) => ({
  position: 'absolute',
  zIndex: -1,
  right: 0,
  width: '100vw',
  minWidth: 314,
  top: 40,
  maxWidth: 400,
  [theme.betweenDM]: {
    maxWidth: 'none',
    top: 120,
    width: theme.pxToVw(635),
    height: 'auto',
  },
  [theme.min(theme.breakpoints.max)]: {
    maxWidth: 'none',
    top: 120,
    width: 635,
    height: 'auto',
  },
}));

const Cont = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  paddingTop: 192,
  paddingBottom: 100,
  [theme.min(650)]: {
    flexDirection: 'row',
    gap: '10vw',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    paddingTop: theme.pxToVw(358),
    paddingBottom: theme.pxToVw(300),
  },
  [theme.min(theme.breakpoints.max)]: {
    paddingTop: 358,
    paddingBottom: 300,
  },
}));

const Heading = styled('h1')(({ theme }) => ({
  fontSize: 38,
  fontWeight: 700,
  lineHeight: 1.31,
  marginBottom: 45,
  '& i': {
    fontWeight: 700,
  },
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: theme.pxToVw(98),
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(80),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: '80px',
  },
}));

const InfoContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.min(650)]: {
    marginTop: 66,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    marginTop: theme.pxToVw(140),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginTop: '140px',
  },
}));

const SocialIconsEl = styled(SocialIcons)(({ theme }) => ({
  marginTop: 14,
  marginBottom: 44,
  gap: 30,
  width: 'max-content',
  '& svg': {
    height: 32,
    width: 'auto',
  },
  [theme.min(820)]: {
    marginTop: 20,
    marginBottom: 32,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    marginTop: theme.pxToVw(24),
    marginBottom: theme.pxToVw(32),
    gap: theme.pxToVw(32),
    '& svg': {
      height: theme.pxToVw(40),
      width: 'auto',
    },
  },
  [theme.min(theme.breakpoints.max)]: {
    marginTop: 24,
    marginBottom: 32,
    gap: 32,
    '& svg': {
      height: 40,
      width: 'auto',
    },
  },
}));

const Email = styled(ActionText)(({ theme }) => ({
  fontSize: 32,
  marginBottom: 24,
  fontWeight: 700,
  '&:hover': {
    color: 'rgba(255,255,255, 0.8)',
  },
  [theme.min(820)]: {
    fontSize: 40,
    marginBottom: 30,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(60),
    marginBottom: theme.pxToVw(30),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 60,
    marginBottom: 30,
  },
}));

const BrightText = styled('p')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  opacity: 0.6,
  [theme.min(820)]: {
    fontSize: 18,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(24),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 24,
  },
}));
