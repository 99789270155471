import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from '../../../../../router/routes';

import { FOOTER_MOBILE_BREAKPOINT_WIDTH } from '../../Footer';

export const FooterPrivacyAndTermsSection: React.FC = () => {
  const { t } = useTranslation('common');
  return (
    <Wrapper>
      <Link to={routes.privacyPolicy()}>
        <Text>{t('privacyPolicy')}</Text>
      </Link>
      <Link to={routes.termsOfService()}>
        <Text>{t('termsOfService')}</Text>
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '3.2rem',
  [theme.betweenDM]: {
    gap: theme.pxToVw(32),
  },
  [theme.max(FOOTER_MOBILE_BREAKPOINT_WIDTH)]: {
    gap: 32,
  },
}));

const Text = styled('span')(({ theme }) => ({
  fontSize: '1.6rem',
  color: 'rgba(255, 255, 255, 0.5)',
  cursor: 'pointer',
  transition: 'all .4s',
  display: 'block',
  opacity: 1,
  whiteSpace: 'nowrap',
  '&:hover': {
    opacity: 0.8,
  },
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(16),
  },
  [theme.max(FOOTER_MOBILE_BREAKPOINT_WIDTH)]: {
    fontSize: 16,
  },
}));
