import { useInfiniteQuery } from 'react-query';
import { UserApi } from '../../api/UserApi/UserApi';
import { useAuth } from '../../providers/AuthProvider';

import { QueryKey } from '../query-keys';

export const useRecommendedUsers = () => {
  const { isAuthenticated } = useAuth();
  const { data, ...rest } = useInfiniteQuery(
    QueryKey.RecommendedUsers(),
    ({ pageParam = 1 }) => UserApi.getRecommendedUsers({ page: pageParam }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      enabled: !!isAuthenticated,
      getNextPageParam: (lastPage, allPages) => {
        return undefined;
        return allPages.flat()?.length % 5 !== 0;
      },
    }
  );

  return {
    data,
    formattedData: data ? data.pages.flat() : undefined,
    ...rest,
  };
};
