import React from 'react';
import styled from 'styled-components';
import { Icon } from '../../../../assets';

interface Props {
  label?: string;
  isOpen: boolean;
}

export const DropdownMenuBaseDefaultTrigger: React.FC<Props> = ({
  isOpen,
  label,
  ...otherProps
}) => {
  return (
    <Wrapper isOpen={isOpen} {...otherProps}>
      <Text>{label || 'Select'}</Text>
      <Icon.ChevronDownWhite />
    </Wrapper>
  );
};

const Wrapper = styled('div')<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'all .3s',
  opacity: 1,
  '& svg': {
    height: 6,
    width: 'auto',
    transition: 'all .3s',
    transform: `rotate(${isOpen ? '180' : '0'}deg)`,
    [theme.min(theme.breakpoints.desktop)]: {
      height: theme.pxToVw(7.2),
    },
    [theme.min(theme.breakpoints.max)]: {
      height: 7.2,
    },
  },
  '&:hover': {
    opacity: 0.85,
  },
  [theme.min(theme.breakpoints.md)]: {
    gap: 12,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(14),
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 14,
  },
}));

const Text = styled('span')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  [theme.min(theme.breakpoints.md)]: {
    fontSize: 16,
  },
  [theme.min(theme.breakpoints.xl)]: {
    fontSize: 18,
  },
  [theme.min(theme.breakpoints.ul)]: {
    fontSize: 20,
  },
}));
