import styled from 'styled-components';
import default_bg from '../../assets/backgrounds/default-profile-cover.jpg';
import default_bg_mobile from '../../assets/backgrounds/default-profile-cover-mobile.jpg';
import { HEADER_HEIGHT } from '../../components/layouts/Layout.styles';

const bgGradient =
  'radial-gradient(365.87% 100% at 50% 0%, rgba(0, 0, 0, 0.76) 0%, rgba(0, 0, 0, 0.37) 18.23%, rgba(0, 0, 0, 0.48) 33.85%, rgba(0, 0, 0, 0.82) 65.1%, #000000 100%)';

export const ProfilePageMainSection = styled('main')<{ imgUrl?: string | null }>(
  ({ theme, imgUrl }) => ({
    display: 'block',
    width: '100vw',
    background: !imgUrl ? `url(${default_bg_mobile})` : `${bgGradient}, url('${imgUrl}')`,
    backgroundSize: '100vw auto',
    backgroundPosition: 'top',
    paddingTop: '25vh',
    marginTop: `-${HEADER_HEIGHT}`,
    position: 'relative',
    zIndex: theme.zIndex.sm,
    backgroundRepeat: 'no-repeat',
    [theme.min(theme.breakpoints.sm)]: {
      background: !imgUrl ? `url(${default_bg})` : `${bgGradient}, url('${imgUrl}')`,
      backgroundSize: '100vw auto',
      backgroundPosition: 'top',
      backgroundRepeat: 'no-repeat',
    },
  })
);
