import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { FOOTER_MOBILE_BREAKPOINT_WIDTH } from '../../Footer';
import { SocialIcons } from '../../../../common/SocialIcons/SocialIcons';

export const FooterSocialSection: React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <Wrapper>
      <Text>{t('followUs')}</Text>
      <SocialWrapper>
        <Icons />
      </SocialWrapper>
    </Wrapper>
  );
};

const FlexRow = styled('div')({ display: 'flex' });

const FlexCol = styled(FlexRow)({ flexDirection: 'column' });

const Icons = styled(SocialIcons)(({ theme }) => ({
  gap: 24,
  '& svg': {
    height: 26,
    width: 'auto',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    '& svg': {
      height: theme.pxToVw(32),
    },
    gap: theme.pxToVw(30),
  },
  [theme.min(theme.breakpoints.max)]: {
    '& svg': {
      height: 32,
    },
    gap: 30,
  },
}));

const Wrapper = styled(FlexCol)(({ theme }) => ({
  alignItems: 'center',
  gap: 14,
  [theme.betweenDM]: {
    gap: theme.pxToVw(14),
  },
  [theme.max(FOOTER_MOBILE_BREAKPOINT_WIDTH)]: {
    gap: 12,
    marginBottom: 32,
  },
}));

const SocialWrapper = styled(FlexRow)(({ theme }) => ({
  gap: '3.2rem',

  [theme.max(FOOTER_MOBILE_BREAKPOINT_WIDTH)]: {
    gap: 32,
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(32),
  },
}));

const Text = styled('span')<{ $action?: boolean }>(({ theme }) => ({
  fontSize: '1.6rem',
  color: 'rgba(255,255,255, 0.2)',
  cursor: 'pointer',
  textTransform: 'uppercase',
  [theme.max(FOOTER_MOBILE_BREAKPOINT_WIDTH)]: {
    fontSize: 16,
  },
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(16),
  },
}));
