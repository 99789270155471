import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { Theme } from '../../../../config/theme';
import { useWindowWidth } from '../../../../hooks/useWindowWidth';
import { ActionText } from '../../../ui/text/ActionText';
import { ChevronDownIcon } from './ChevronDown';
import NavMenuLogo from './NavMenuLogo';

const getStyle = (theme: Theme, isOpen: boolean) => {
  if (!isOpen)
    return {
      color: '#fff',
    };

  return {
    color: theme.colors.primary.main,
  };
};

const LinkText = styled(ActionText)<{ hasChildren: boolean; isOpen: boolean }>(
  ({ theme, hasChildren, isOpen }) => ({
    fontSize: 14,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    transform: `translateX(${hasChildren ? 'calc(8px)' : '0'})`,
    ...getStyle(theme, isOpen),
    [theme.min(theme.breakpoints.md)]: {
      transform: 'none',
    },
    [theme.min(theme.breakpoints.desktop)]: {
      fontSize: theme.pxToVw(16),
    },
    [theme.min(theme.breakpoints.max)]: {
      fontSize: 16,
    },
  })
);

LinkText.defaultProps = {
  underline: false,
};

const Wrapper = styled('div')<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  width: 'max-content',
  height: 'max-content',
  overflow: 'visible',
  zIndex: theme.zIndex.xs,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.min(theme.breakpoints.md)]: {
    display: 'block',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    '& svg': {
      width: theme.pxToVw(10),
      height: 'auto',
    },
  },
  [theme.min(theme.breakpoints.max)]: {
    '& svg': {
      width: 10,
    },
  },
}));

const ItemsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 20,
  zIndex: theme.zIndex.sm,
  padding: '20px 0 14px 0',
  [theme.min(theme.breakpoints.md)]: {
    position: 'fixed',
    top: 114,
    left: '5%',
    backgroundColor: '#000',
    borderRadius: 20,
    border: '1px solid #222222',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '80vw',
    maxWidth: 1041,
    zIndex: 5,
    padding: '20px 0px',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    width: theme.pxToVw(1040),
    padding: `${theme.pxToVw(20)} 0`,
    top: theme.pxToVw(114),
    left: `calc((100vw - ${theme.pxToVw(1520)}) / 2)`,
    borderRadius: theme.pxToVw(23),
  },
  [theme.min(theme.breakpoints.max)]: {
    width: '1041px',
    padding: '20px 0',
    top: '114px',
    left: 'calc((100vw - 1520px) / 2)',
    borderRadius: 23,
  },
}));

interface ChildItem {
  label: string;
  description?: string;
  url: string;
  onClick: () => void;
}

interface Props {
  navItem: {
    label: string;
    isOpen?: boolean;
    url?: string | null;
    onClick: () => void;
    children: null | ChildItem[];
  };
}

export const NavItem: React.FC<Props> = ({ navItem }) => {
  const { label, url, onClick, children, isOpen } = navItem;
  const width = useWindowWidth();
  const theme = useTheme();
  const navigate = useNavigate();

  const isMobile = width < theme.breakpoints.md;

  if (!children && url) {
    return (
      <LinkText
        isOpen={false}
        text={label}
        onClick={() => {
          navigate(url);
          onClick();
        }}
        hasChildren={false}
      />
    );
  }

  return (
    <Wrapper isOpen={!!isOpen}>
      <LinkText isOpen={!!isOpen} text={label} onClick={onClick} hasChildren={!!children}>
        <ChevronDownIcon $isopen={isOpen} />
      </LinkText>
      {children && isOpen && (
        <ItemsWrapper>
          {children.map(childLink =>
            isMobile ? (
              <LinkText
                isOpen={false}
                key={childLink.url}
                text={childLink.label}
                onClick={() => {
                  navigate(childLink.url);
                  typeof childLink.onClick === 'function' && childLink.onClick();
                }}
                hasChildren={false}
              />
            ) : (
              <DesktopChildLink key={childLink.url} {...childLink} />
            )
          )}
        </ItemsWrapper>
      )}
    </Wrapper>
  );
};

const DesktopChildLink = ({ url, label, description, onClick }: ChildItem) => {
  const navigate = useNavigate();

  return (
    <DesktopChildLinkWrapper
      onClick={() => {
        typeof onClick === 'function' && onClick();
        navigate(url);
      }}
    >
      <NavMenuLogo />
      <TextWrapper>
        <DesktopLinkText>
          <span className='desktop-nav-item_link-text'>{label}</span>
          <svg
            width='14'
            height='12'
            viewBox='0 0 14 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='desktop-nav-item_arrow'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M8.92201 0.266221L13.7337 5.27425C14.0888 5.62921 14.0888 6.20471 13.7337 6.55968L9.64929 11.7338C9.29426 12.0887 8.71866 12.0887 8.36364 11.7338C8.00861 11.3788 8.00861 10.8033 8.36364 10.4483L10.8962 6.8259H0V5.00803H10.8962L7.63636 1.55165C7.28134 1.19669 7.28134 0.621182 7.63636 0.266221C7.99139 -0.0887403 8.56699 -0.0887403 8.92201 0.266221Z'
              fill='#0065FC'
            />
          </svg>
        </DesktopLinkText>
        <p style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
          {description}
          {description && description.length < 34 && (
            <>
              <span style={{ display: 'block' }}>
                <br />
              </span>
            </>
          )}
        </p>
      </TextWrapper>
    </DesktopChildLinkWrapper>
  );
};
const DesktopChildLinkWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 16,
  fontSize: 16,
  fontWeight: 700,
  lineHeight: 1.33,
  cursor: 'pointer',
  width: '100%',
  padding: '20px 20px 20px 60px',
  '& span': {
    fontWeight: 700,
  },
  '& .nav-menu-logo circle': {
    transition: 'all .3s',
  },
  '&:hover': {
    '& .desktop-nav-item_arrow': {
      opacity: 1,
    },
    '& .desktop-nav-item_link-text': {
      color: theme.colors.primary.main,
    },
    '& .nav-menu-logo circle': {
      fill: theme.colors.primary.main,
    },
  },
  [theme.min(theme.breakpoints.desktop)]: {
    padding: `${theme.pxToVw(20)} ${theme.pxToVw(20)} ${theme.pxToVw(20)} ${theme.pxToVw(
      60
    )}`,
    '& p': {
      fontSize: `${theme.pxToVw(16)} !important`,
      maxWidth: theme.pxToVw(312),
    },
    '& .nav-menu-logo': {
      height: `${theme.pxToVw(38)} !important`,
      width: `${theme.pxToVw(38)} !important`,
    },
  },
  // [theme.min(theme.breakpoints.max)]: {
  //   padding: '20px 20px 20px 60px',
  //   '& p': {
  //     fontSize: '16px !important',
  //     maxWidth: '312px',
  //   },
  //   '& .nav-menu-logo': {
  //     height: '38px !important',
  //     width: '38px !important',
  //   },
  // },
}));

const TextWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  paddingTop: 8,
  flex: 1,
  [theme.min(theme.breakpoints.desktop)]: {
    paddingTop: theme.pxToVw(8),
    '& p': {
      fontSize: theme.pxToVw(16),
      maxWidth: theme.pxToVw(290),
    },
  },
  // [theme.min(theme.breakpoints.max)]: {
  //   paddingTop: 8,
  //   '& p': {
  //     fontSize: 16,
  //     maxWidth: 290,
  //   },
  // },
}));

const DesktopLinkText = styled('p')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxWidth: 300,
  color: '#fff',
  width: '65% !important',
  '& svg': {
    transition: 'all .3s',
    opacity: 0,
  },
  '&:hover': {
    '& svg': {
      opacity: 1,
    },
  },
}));
