import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

export interface IAddressContext {
  address: string | undefined;
  setAddress: Dispatch<SetStateAction<string | undefined>>;
}

const AddressContext = React.createContext<IAddressContext | undefined>(undefined);

export const useAddress = (): IAddressContext => {
  const context = useContext(AddressContext);

  if (!context) {
    throw new Error('useAddress cannot be used without AddressContext');
  }

  return context;
};

interface Props {
  children: React.ReactNode;
  initAddress?: string;
}

export const AddressProvider: React.FC<Props> = ({ children, initAddress }) => {
  const [address, setAddress] = useState(initAddress);

  return (
    <AddressContext.Provider value={{ address, setAddress }}>
      {children}
    </AddressContext.Provider>
  );
};
