import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ActionText } from '../../components/ui/text/ActionText';
import { ConfirmationModal } from '../../components/ui/modals/ConfirmationModal/ConfirmationModal';
import { useRequest } from '../../hooks/useRequest';
import { ErrorMessage } from '../../components/ui/forms/FormFieldWrapper/ErrorMessage';
import { UserApi } from '../../api/UserApi/UserApi';
import { useQueryClient } from 'react-query';

export const DeleteAccount = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const onConfirm = async () => {
    await UserApi.deleteProfile();
    localStorage.clear();
    queryClient.clear();
    window.location.href = '/';
  };

  const { call, isLoading, isError } = useRequest(onConfirm);

  return (
    <>
      <DeleteText onClick={() => setOpen(true)} disabled={isLoading}>
        {t('accountDetailsPage.deleteAccount')}
      </DeleteText>
      {isError && <ErrMsg error={isError} />}
      {open && (
        <ConfirmationModal
          title={t('confirm.removeAccount.title')}
          description={t('confirm.removeAccount.text')}
          onCancel={() => setOpen(false)}
          onConfirm={call}
        />
      )}
    </>
  );
};

const DeleteText = styled(ActionText)(({ theme }) => ({
  color: theme.colors.trust.Untrusted,
  opacity: 1,
  margin: '20px auto 10px auto',
  fontSize: 16,
  fontWeight: 700,
  transition: 'all .3s',
  '&:hover': {
    opacity: 0.75,
    color: theme.colors.trust.Untrusted,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    margin: `${theme.pxToVw(30)} auto 0 auto`,
    fontSize: theme.pxToVw(16),
  },
  [theme.min(theme.breakpoints.max)]: {
    margin: '30px auto 0 auto',
    fontSize: 16,
  },
}));

const ErrMsg = styled(ErrorMessage)({
  margin: '0 auto',
  textAlign: 'center',
});
