import styled from 'styled-components';
import MilestoneLabel from '../../../../../../../components/project/roadmap/MilestoneLabel';
import { Milestone } from '../../../../../../../types';
import MilestoneGoalComponent from './MilestoneGoal/MilestoneGoal';

interface Props {
  milestone: Milestone;
  isPerfect?: boolean;
}

export default function RoadmapMilestone({ milestone, isPerfect }: Props) {
  const isActive = milestone.goals.some(goal => goal.isFulfilled);
  const withLabelBg = milestone.goals.some(goal => !goal.isFulfilled);

  const totalGoals = milestone.goals.length;
  const doneGoals = milestone.goals.filter(g => g.isFulfilled).length;
  const donePercentage = (100 / totalGoals) * doneGoals;

  const isInProgress = donePercentage > 0 && donePercentage < 100;

  const circleBgColor = isInProgress ? '#287EFF' : !isActive ? '#000' : undefined;

  return (
    <Wrapper>
      <LabelWrapper bg={withLabelBg} isActive={isActive} donePercentage={donePercentage}>
        {isPerfect && <LabelOverlay donePercentage={donePercentage} />}
        {isInProgress && <InProgressLabelOverlay donePercentage={donePercentage} />}
        <div style={{ position: 'relative', zIndex: 2 }}>
          <MilestoneLabel
            labelProps={{ text: milestone.title }}
            circleProps={{
              text: milestone.orderNumber,
              isActive,
              bgColor: circleBgColor,
              size: {
                desktop: 48,
                mobile: 48,
              },
            }}
            fontSize={{
              desktop: 20,
              mobile: 16,
            }}
          />
        </div>
      </LabelWrapper>
      <GoalsWrapper>
        <Line inProgress={!!isInProgress} />
        {milestone.goals.map(goal => (
          <MilestoneGoalComponent
            key={goal.uid}
            goal={goal}
            isInProgress={isInProgress}
          />
        ))}
      </GoalsWrapper>
    </Wrapper>
  );
}

const Wrapper = styled('div')(({ theme }) => ({}));

const LabelWrapper = styled('div')<{
  bg: boolean;
  isActive: boolean;
  donePercentage: number;
}>(({ theme, bg, isActive, donePercentage }) => ({
  borderRadius: 91,
  backgroundColor: isActive ? 'rgba(0, 101, 252, 0.5)' : bg ? '#3D4859' : 'transparent',
  width: 335,
  marginRight: 10,
  marginBottom: 2,
  position: 'relative',
  zIndex: theme.zIndex.xs,
  [theme.min(theme.breakpoints.sm)]: {
    backgroundColor: isActive ? 'rgba(0, 101, 252, 0.5)' : bg ? '#3D4859' : 'transparent',
    padding: 4,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    width: theme.pxToVw(350),
    marginRight: theme.pxToVw(14),
  },
  [theme.min(theme.breakpoints.max)]: {
    width: 350,
    marginRight: 14,
  },
}));

const LabelOverlay = styled('div')<{ donePercentage: number }>(
  ({ theme, donePercentage }) => ({
    display: 'none',
    [theme.min(theme.breakpoints.sm)]: {
      position: 'absolute',
      content: '" "',
      display: 'block',
      top: 0,
      left: 0,
      right: -60,
      bottom: 0,
      zIndex: 0,
      background:
        donePercentage === 0
          ? 'transparent'
          : donePercentage === 100
          ? '#00337E'
          : `transparent`,
      borderRadius: 91,
    },
  })
);

const InProgressLabelOverlay = styled('div')<{ donePercentage: number }>(
  ({ theme, donePercentage }) => ({
    display: 'none',
    [theme.min(theme.breakpoints.sm)]: {
      position: 'absolute',
      content: '" "',
      display: 'block',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 0,
      background: `linear-gradient(90deg, #0065FC ${donePercentage * 0.75}%, #3D4859 ${
        donePercentage * 1.3
      }%)`,
      borderRadius: 91,
    },
  })
);

const GoalsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 22,
  marginLeft: 28,
  position: 'relative',
  paddingTop: 32,
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(14),
    marginLeft: theme.pxToVw(28),
    paddingTop: theme.pxToVw(30),
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 14,
    marginLeft: 28,
    paddingTop: 30,
  },
}));

const Line = styled('div')<{ inProgress?: boolean }>(({ theme, inProgress }) => ({
  position: 'absolute',
  left: 0,
  content: '" "',
  width: 2,
  backgroundColor: !inProgress ? '#3D4859' : theme.colors.primary.main,
  zIndex: 0,
  transform: 'translateX(-50%)',
  top: 0,
  bottom: 10,
}));
