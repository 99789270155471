import React from 'react';
import styled from 'styled-components';
import { Icon } from '../../../assets';

interface Props {
  size?: number | string;
  style?: React.CSSProperties;
}

const Wrapper = styled('span')<Props>(({ theme, size = 20 }) => ({
  display: 'block',
  '& svg': {
    fill: theme.colors.text.primary,
    color: theme.colors.text.primary,
    animation: 'spin 1.1s infinite ease',
    ...theme.keyframes.spin,
  },
}));

export const Spinner: React.FC<Props> = ({ size = 20, style, ...otherProps }) => {
  return (
    <Wrapper size={size} style={style} {...otherProps}>
      <Icon.Spinner className='spinner-x' style={{ width: size, height: size }} />
    </Wrapper>
  );
};
