import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  current: 'check' | 'report';
  onChange: (val: 'check' | 'report') => void;
}

export const InputNavigation: React.FC<Props> = ({ current, onChange }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Item onClick={() => onChange('check')}>
        <ItemText isActive={current === 'check'}>{t('homePage.checkAddress')}</ItemText>
        <Line isActive={current === 'check'}></Line>
      </Item>

      <Item onClick={() => onChange('report')}>
        <ItemText isActive={current === 'report'}>{t('homePage.reportAddress')}</ItemText>
        <Line isActive={current === 'report'}></Line>
      </Item>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 20,
  [theme.min(theme.breakpoints.sm)]: {
    gap: 24,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(32),
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 32,
  },
}));

const Item = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'all .3s',
  opacity: 1,
  gap: 8,
  '&:hover': {
    opacity: 0.7,
  },
}));

const ItemText = styled('span')<{ isActive: boolean }>(({ theme, isActive }) => ({
  color: isActive ? theme.colors.primary.main : '#fff',
  fontSize: 14,
  fontWeight: 700,
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: 16,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(20),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 20,
  },
}));

const Line = styled('div')<{ isActive: boolean }>(({ theme, isActive }) => ({
  display: 'flex',
  height: 4,
  backgroundColor: isActive ? theme.colors.primary.main : 'transparent',
  width: '50%',
  [theme.betweenDM]: {
    height: theme.pxToVw(4),
  },
}));
