import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { NotificationApi } from '../../../../api/UserApi/NotificationApi';

import { useRequest } from '../../../../hooks/useRequest';
import { QueryKey } from '../../../../react-query/query-keys';
import { useNotificationsCount } from '../../../../react-query/user/notifications/useNotifications';

import { TextBase } from '../shared-components';

export const MarkAllAsRead: React.FC = props => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { data } = useNotificationsCount();

  const onClick = async () => {
    if (!data?.undreadCount) return;
    await NotificationApi.markAllAsRead();
    await Promise.all([
      queryClient.invalidateQueries(QueryKey.NotificationsCount()),
      queryClient.invalidateQueries('Notifications'),
    ]);
  };

  const { call, isLoading } = useRequest(onClick);

  return (
    <TextBase
      disabled={!data?.undreadCount}
      isLoading={isLoading}
      onClick={call}
      {...props}
    >
      {t('notifications.markAllAsRead')}
    </TextBase>
  );
};
