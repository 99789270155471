import styled from 'styled-components';
import { Container as ContainerEl } from '../../../../components/layouts/Container';
import { Heading2 } from '../../../../components/ui/text/Heading';
import { Text as TextEl } from '../../../../components/ui/text/Text';
import { ReactComponent as Illustration } from './Illustration.svg';

const Wrapper = styled('div')(({ theme }) => ({
  background:
    'radial-gradient(114.94% 114.94% at 50% 114.94%, #14171C 54.69%, #0B0E11 100%)',
  paddingTop: 70,
  position: 'relative',
  overflow: 'visible',
  flex: 1,
  [theme.min(theme.breakpoints.desktop)]: {
    paddingTop: theme.pxToVw(118),
  },
  [theme.min(theme.breakpoints.max)]: {
    paddingTop: 118,
  },
}));

const OwnershipWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '& p': {
    maxWidth: 750,
    [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
      maxWidth: theme.pxToVw(750),
    },
  },
}));

const Container = styled(ContainerEl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  marginBottom: 48,
  '& p': {
    maxWidth: 750,
    [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
      maxWidth: theme.pxToVw(750),
    },
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    marginBottom: theme.pxToVw(48),
  },
}));

const Heading = styled(Heading2)(({ theme }) => ({
  marginBottom: 12,
  lineHeight: 1.2,
  position: 'relative',
  zIndex: 2,
  fontWeight: 600,
  [theme.min(theme.breakpoints.desktop)]: {
    marginBottom: theme.pxToVw(20),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginBottom: 20,
  },
}));

const Text = styled(TextEl.MD)(({ theme }) => ({
  marginBottom: 32,
  fontWeight: 600,
  position: 'relative',
  zIndex: 2,
  lineHeight: 1.6,
  [theme.min(theme.breakpoints.desktop)]: {
    marginBottom: theme.pxToVw(54),
  },
  [theme.min(1920)]: {
    marginBottom: 54,
  },
}));

const BlackDiv = styled('div')(({ theme }) => ({
  display: 'block',
  width: '100vw',
  content: '" "',
  background: '#000',
  height: 120,
  [theme.betweenDM]: {
    height: theme.pxToVw(120),
  },
  [theme.max(theme.breakpoints.md)]: {
    display: 'none',
  },
}));

const DigitalOwnershipsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  position: 'relative',
  zIndex: 2,
  [theme.min(theme.breakpoints.md)]: {
    flexDirection: 'row',
  },
}));

const IllustrationEl = styled(Illustration)(({ theme }) => ({
  width: 408,
  height: 'auto',
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    width: theme.pxToVw(408),
  },
}));

const IllustrationWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  maxWidth: '100%',
  overflow: 'hidden',
  zIndex: 1,
  [theme.min(theme.breakpoints.xs)]: {
    right: 0,
    left: 'auto',
  },
  [theme.min(theme.breakpoints.md)]: {
    right: '10%',
  },
  [theme.min(theme.breakpoints.ul)]: {
    right: '10%',
  },
}));

const Styled = {
  Wrapper,
  Container,
  Text,
  Heading,
  DigitalOwnershipsWrapper,
  BlackDiv,
  Illustration: IllustrationEl,
  IllustrationWrapper,
  OwnershipWrapper,
};

export default Styled;
