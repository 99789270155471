import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useLoadImage } from '../../../hooks/useLoadImage';
import { useProjectUtils } from '../../../pages/project/profile/hooks/useProjectUtils';

import { defaultNftImageUrl } from '../../../constants/external-urls';
import { limitLongString } from '../../../utils/string-utils';
import { routes } from '../../../router/routes';

import { ContentLoader } from '../../ui/loaders/ContentLoader';
import { ProjectListItem } from '../../../types';
import { ProjectCardMenu } from './ProjectCardMenu';
import { Spinner } from '../../ui/loaders/Spinner';

interface Props {
  project: ProjectListItem;
}

export const ProjectCard: React.FC<Props> = ({ project }) => {
  const { title, photoUrl, contractAddress } = project;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const [showOptions, setShowOptions] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { src, isError, isLoaded } = useLoadImage(
    photoUrl || defaultNftImageUrl,
    defaultNftImageUrl
  );

  const { isProjectOwner } = useProjectUtils();

  const onMouseOver = () => {
    if (!isProjectOwner(contractAddress)) return;
    if (!showOptions) setShowOptions(true);
  };

  const onMouseLeave = () => {
    if (!isProjectOwner(contractAddress)) return;
    if (!isOpen) setShowOptions(false);
  };

  return (
    <Wrapper ref={wrapperRef} isMenuOpen={isOpen}>
      <ImageWrapper onMouseOver={onMouseOver} onMouseOut={onMouseLeave}>
        <Image
          src={src}
          position={photoUrl && !isError ? 'top' : 'center'}
          onClick={() => navigate(routes.project.byId(contractAddress))}
        />
        <ProjectCardMenu
          key={project.uid}
          ref={popupRef}
          project={project}
          isOpen={isOpen}
          onToggle={() => setIsOpen(prev => !prev)}
          onClickOutside={e => {
            setIsOpen(false);
            if (!wrapperRef.current?.contains(e?.target as HTMLElement)) {
              setShowOptions(false);
            }
          }}
          setIsDeleting={setIsDeleting}
        />
        {isDeleting && (
          <DeleteOverlay>
            <Spinner size={14} />
          </DeleteOverlay>
        )}
      </ImageWrapper>
      {!isLoaded && (
        <ContentLoader
          viewBox='0 0 200 200'
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
          }}
        >
          <rect x='0' y='0' height={200} width={240} />
        </ContentLoader>
      )}

      <DetailsWrapper onClick={() => navigate(routes.project.byId(contractAddress))}>
        <TextBold>{limitLongString(title, 30)}</TextBold>
      </DetailsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled('div')<{ isMenuOpen: boolean }>(({ theme, isMenuOpen }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: '#000',
  cursor: 'pointer',
  position: 'relative',
  overflow: 'visible',
  '@media(hover: hover)': {
    '& .project-menu': {
      display: isMenuOpen ? 'block' : 'none',
    },
  },
  '@media(hover: none)': {
    '& .project-menu': {
      display: 'block',
    },
  },
  '&:hover': {
    '& .img-wrapper-xx': {
      background:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(image.png)',
    },
    '& .project-menu': {
      display: 'block',
    },
  },
}));

const DetailsWrapper = styled('div')(({ theme }) => ({
  paddingTop: 10,
  [theme.min(theme.breakpoints.sm)]: {
    paddingTop: 15,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    paddingTop: theme.pxToVw(20),
  },
  [theme.min(theme.breakpoints.max)]: {
    paddingTop: 20,
  },
}));

const TextBold = styled('p')(({ theme }) => ({
  fontSize: 14,
  lineHeight: 1.333,
  fontWeight: 700,
  wordBreak: 'break-word',
  [theme.min(theme.breakpoints.md)]: {
    fontSize: 16,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(16),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 16,
  },
}));

const Image = styled('img')<{ position: 'top' | 'center' }>(({ theme, position }) => ({
  aspectRatio: '1',
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
  objectPosition: position,
  borderRadius: 20,
  maxWidth: '100%',
  maxHeight: '100%',
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    borderRadius: theme.pxToVw(20),
  },
}));

const ImageWrapper = styled('div')(({ theme }) => ({
  borderRadius: 20,
  boxShadow: '0px 0px 14px #0065FC',
  aspectRatio: '1',
  width: '100%',
  height: 'auto',
  // overflow: 'hidden',
  transition: 'all .3s',
  position: 'relative',
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    borderRadius: theme.pxToVw(20),
  },
}));

const DeleteOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  borderRadius: 20,
  backgroundColor: 'rgba(0,0,0,0.43)',
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    borderRadius: theme.pxToVw(20),
  },
}));

ImageWrapper.defaultProps = { className: 'img-wrapper-xx' };
