import { useMemo } from 'react';
import { useAuth } from '../../../providers/AuthProvider';
import { Follower } from '../../../types';

const getPlaceholderFollowers = (totalCount: number | undefined = 0): Follower[] => {
  return [...new Array(totalCount).keys()].map((key, i) => ({
    userUid: `${i}`,
    photoUrl: null,
    username: 'xxx',
  }));
};

export const useFollowersBadges = (
  endorsersOrFollowers: Follower[] | undefined,
  totalCount: number | undefined,
  isLoading: boolean
) => {
  const { isAuthenticated } = useAuth();

  const theEndorsments =
    isAuthenticated && !isLoading
      ? endorsersOrFollowers
      : getPlaceholderFollowers(isLoading ? 22 : totalCount);

  const displayUsers = useMemo(() => {
    return theEndorsments
      ? [...theEndorsments]
          .sort((a, b) => {
            if (a.photoUrl && !b.photoUrl) return -1;
            if (a.photoUrl && b.photoUrl) return 0;
            if (b.photoUrl && !a.photoUrl) return 1;
            return 1;
          })
          .slice(0, theEndorsments.length >= 10 ? 3 : 1)
      : null;
  }, [theEndorsments]);

  const hasDisplayUsers = !!displayUsers;
  const displayUsersCount = displayUsers?.length || 0;

  const displayTotalCount = useMemo(
    () =>
      typeof totalCount === 'number' && hasDisplayUsers
        ? totalCount - displayUsersCount
        : totalCount,
    [totalCount, hasDisplayUsers, displayUsersCount]
  );

  return {
    displayTotalCount,
    displayUsers,
  };
};
