import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import { useOutsideClick } from '../../../../hooks/useOutsideClick';

import { InputBase } from './InputBase';

import { ReactComponent as SortSvg } from './Sort.svg';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  menu?: {
    Cta?: JSX.Element;
    items: {
      label: string;
      value: string | number;
      onClick: (val: string | number) => void;
    }[];
    selected?: string | number;
    menuWidth?: number;
  };
  debounceInterval?: number;
  onDebounced?: (val: string) => void;
}

export const SearchInput: React.FC<Props> = ({
  placeholder,
  debounceInterval = 200,
  menu,
  onDebounced,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(otherProps.value || '');
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const menuMenuRef = useRef<HTMLDivElement>(null);
  const [debouncedValue] = useDebounce(value, debounceInterval);
  useOutsideClick([menuRef, menuMenuRef], () => setMenuOpen(false));

  useEffect(() => {
    typeof onDebounced === 'function' &&
      typeof debouncedValue === 'string' &&
      onDebounced(debouncedValue);
  }, [debouncedValue, onDebounced]);

  return (
    <Wrapper>
      <SearchSvg />
      <Input
        hasMenu={!!menu}
        placeholder={placeholder || t('common.search')}
        onChange={e => {
          typeof otherProps.onChange === 'function' && otherProps.onChange(e);
          setValue(e.target.value);
        }}
        {...otherProps}
      />
      {menu && (
        <MenuWrapper ref={menuRef} open={menuOpen}>
          <span onClick={() => setMenuOpen(prev => !prev)}>
            {menu.Cta || <SortSvg />}
          </span>
          {menuOpen && (
            <MenuDropdown ref={menuMenuRef} width={menu.menuWidth || 160}>
              {menu.items.map((item, i) => (
                <MenuItem
                  key={i}
                  onClick={() => {
                    setMenuOpen(false);
                    item.onClick(item.value);
                  }}
                  selected={item.value === menu.selected}
                >
                  {item.label}
                </MenuItem>
              ))}
            </MenuDropdown>
          )}
        </MenuWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  overflow: 'visible',
  width: '100%',
  '& svg': {
    position: 'absolute',
    left: 16,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  [theme.betweenDM]: {
    '& svg': {
      left: theme.pxToVw(16),
    },
  },
}));

const Input = styled(InputBase)<{ hasMenu?: boolean }>(({ theme, hasMenu }) => ({
  height: 44,
  paddingLeft: 36,
  paddingRight: hasMenu ? 36 : 16,
  backgroundColor: '#161616',
  boxShadow: 'none',
  [theme.betweenDM]: {
    height: theme.pxToVw(44),
    paddingLeft: theme.pxToVw(36),
    paddingRight: hasMenu ? theme.pxToVw(36) : theme.pxToVw(16),
  },
}));

const MenuWrapper = styled('div')<{ open?: boolean }>(({ theme, open }) => ({
  width: 30,
  position: 'absolute',
  right: 16,
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: theme.zIndex.md,
  '& span': {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 22,
    width: 22,
    '&:hover': {
      '& svg': {
        opacity: 0.85,
        '& path': {
          opacity: 0.85,
        },
      },
    },
    '& svg': {
      opacity: 1,
      transition: 'all .3s',
      '& path': {
        opacity: 1,
        transition: 'all .3s',
      },
    },
  },
  '& path': {
    fill: open ? theme.colors.primary.main : '#fff',
  },
  [theme.betweenDM]: {
    width: theme.pxToVw(30),
    right: theme.pxToVw(16),
    '& span': {
      height: theme.pxToVw(22),
      width: theme.pxToVw(22),
    },
  },
}));

const MenuDropdown = styled('div')<{ width: number | string }>(({ theme, width }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  right: -16,
  bottom: -116,
  backgroundColor: theme.colors.primary.main,
  borderRadius: 10,
  width,
  [theme.betweenDM]: {
    right: theme.pxToVw(-16),
    bottom: theme.pxToVw(-116),
    borderRadius: theme.pxToVw(10),
    width: typeof width === 'number' ? theme.pxToVw(width) : width,
  },
}));

const MenuItem = styled('div')<{ selected: boolean }>(({ theme, selected }) => ({
  height: 48,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  fontSize: 14,
  cursor: 'pointer',
  transition: 'all .3s',
  backgroundColor: selected ? '#0055AE' : 'transparent',
  padding: '0 12px',
  position: 'relative',
  '& svg': {
    height: 15.5,
    width: 'auto',
    padding: 0,
    marginTop: 4,
  },
  '&:first-child': {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  '&:last-child': {
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  '&:hover': {
    backgroundColor: '#0046AE',
  },
  [theme.min(theme.breakpoints.xs)]: {
    fontSize: 16,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(20),
    height: theme.pxToVw(62),
    padding: `0 ${theme.pxToVw(16)}`,
    '& svg': {
      height: theme.pxToVw(15.5),
      marginTop: theme.pxToVw(4),
    },
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 20,
    height: 62,
    padding: '0 16px',
    '& svg': {
      height: 15.5,
      marginTop: 4,
    },
  },
}));

const SearchSvg: React.FC = () => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.8289 13.0041L9.84773 9.02291C10.6189 8.07035 11.0832 6.85993 11.0832 5.54162C11.0832 2.48615 8.59706 0 5.54159 0C2.48612 0 0 2.48612 0 5.54159C0 8.59706 2.48615 11.0832 5.54162 11.0832C6.85993 11.0832 8.07035 10.6189 9.02291 9.84773L13.0041 13.8289C13.1179 13.9427 13.2672 13.9999 13.4165 13.9999C13.5659 13.9999 13.7152 13.9427 13.829 13.8289C14.057 13.6009 14.057 13.2322 13.8289 13.0041ZM5.54162 9.91655C3.12897 9.91655 1.16666 7.95425 1.16666 5.54159C1.16666 3.12894 3.12897 1.16664 5.54162 1.16664C7.95427 1.16664 9.91658 3.12894 9.91658 5.54159C9.91658 7.95425 7.95425 9.91655 5.54162 9.91655Z'
      fill='white'
    />
  </svg>
);
