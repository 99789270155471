import { ReactComponent as TwitterSvg } from './Twitter.svg';
import { ReactComponent as OpenSeaSvg } from './OpenSea.svg';
import { ReactComponent as DiscordSvg } from './Discord.svg';
import { ReactComponent as WWWSvg } from './WWW.svg';

const ProfileInfoIcon = {
  TwitterSvg,
  OpenSeaSvg,
  DiscordSvg,
  WWWSvg,
};

export default ProfileInfoIcon;
