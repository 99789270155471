import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import {
  AddressProvider,
  useAddress,
} from '../../providers/AddressProvider/AddressProvider';

import { UrlService } from '../../services/UrlService';

import { MainSection } from './sections/Main/MainSection';
import { BottomSection } from './sections/BottomSection/BottomSection';

const GenerateProfilePageComponent: React.FC = () => {
  const { t } = useTranslation('generateProfile');
  const { setAddress } = useAddress();

  useDocumentTitle(t('pageTitle'));

  useEffect(() => {
    const address = UrlService.getSearchParam('address')?.trim()?.toLowerCase();
    if (address) setAddress(decodeURIComponent(address));
  }, [setAddress]);

  return (
    <>
      <MainSection />
      <BottomSection />
    </>
  );
};

export const GenerateProfilePage: React.FC = () => (
  <AddressProvider>
    <GenerateProfilePageComponent />
  </AddressProvider>
);
