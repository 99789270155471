import React from 'react';
import { useTranslation } from 'react-i18next';

import { useReputationWithAddress } from '../../../../../providers/AddressProvider/useReputationWithAddress';

import { TokensHeldSvg } from '../../../../../assets/svgs/reputation-svgs';
import { AnalysisResultSecondaryCardBase } from './AnalysisResultSecondaryCardBase';

export const NftCard: React.FC = () => {
  const { t } = useTranslation('analysisResult');
  const { data } = useReputationWithAddress();

  const { nftCount } = data || {};

  const nftsCount =
    typeof nftCount === 'number' && nftCount > -1 ? `${nftCount.toLocaleString()}` : '';

  return (
    <AnalysisResultSecondaryCardBase
      label={t('tokensHeld.title')}
      value={nftsCount}
      icon={<TokensHeldSvg />}
      valueSuffix
    />
  );
};
