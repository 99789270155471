import React from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useWindowWidth } from '../../../../hooks/useWindowWidth';
import { useMode } from '../../../../providers/ModeProvider';
import { useRequest } from '../../../../hooks/useRequest';
import { useUser } from '../../../../react-query/user/useUser';
import { useInvalidateCurrentUserProfile } from '../../../../pages/profile/hooks/useInvalidateCurrentUserProfile';

import { shortenEthAddress } from '../../../../utils/address-utils';

import { FieldWrapper } from '../FieldWrapper';
import { ReactComponent as WalletSvg } from './Wallet.svg';
import {
  ConnectWalletButton,
  WalletData,
} from '../../../common/ConnectWalletButton/ConnectWalletButton';
import { SelectAddressExpandible } from './SelectAddressExpandible/SelectAddressExpandible';

import {
  ConnectAddressResponse,
  PostProjectRequestPayload,
  UserApi,
} from '../../../../api/UserApi/UserApi';
import { useAddress } from '../../../../providers/AddressProvider/AddressProvider';

export const ProjectOwner: React.FC = () => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<PostProjectRequestPayload>();
  const invalidate = useInvalidateCurrentUserProfile();

  const { data: user } = useUser();

  const mode = useMode();

  const {
    call: connectAddress,
    isLoading: isConnecting,
    isError: isConnectingError,
  } = useRequest<ConnectAddressResponse, WalletData>(UserApi.connectAddress, {
    onSuccess: invalidate,
  });

  return (
    <>
      {mode !== 'edit' && (
        <OwnershipText>{t('projectPage.verifyOwnershipInfo')}</OwnershipText>
      )}
      <Field label={t('projectPage.projectOwner')}>
        {mode === 'edit' ? (
          <OwnerPlaceholder />
        ) : (
          <>
            <SelectAddressExpandible addresses={user?.addresses || []} />
            <Or>{t('projectPage.or')}</Or>
            <ConnectWalletButton
              addresses={[]}
              buttonText={t('common.connectNewWallet')}
              onWalletConnect={async walletData => {
                await connectAddress(walletData);
                setValue('signature', walletData.signature);
              }}
              isLoading={isConnecting}
              error={
                isConnectingError === 'ADDRESS_ALREADY_CONNECTED'
                  ? t(`errors.ADDRESS_ALREADY_CONNECTED`)
                  : isConnectingError
              }
            />
          </>
        )}
      </Field>
    </>
  );
};

const Or = styled('p')(({ theme }) => ({
  fontSize: 12,
  textAlign: 'center',
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(12),
  },
}));

const Field = styled(FieldWrapper)(({ theme }) => ({
  marginBottom: 4,
}));

const OwnershipText = styled('p')(({ theme }) => ({
  marginTop: 4,
  fontSize: 12,
  fontWeight: 700,
  paddingLeft: 18,
  paddingRight: '2%',
  [theme.betweenDM]: {
    marginTop: theme.pxToVw(4),
    fontSize: theme.pxToVw(12),
    paddingLeft: theme.pxToVw(18),
  },
}));

const OwnerPlaceholder: React.FC = () => {
  const { address } = useAddress();
  const width = useWindowWidth();

  const renderContent = () => {
    if (!address) return null;

    return width > 420
      ? address
      : shortenEthAddress(address, {
          startChars: width > 380 ? 31 : 24,
          placeholderCount: 3,
        });
  };

  return (
    <WalletPlaceholder>
      <WalletSvg />
      <span>{renderContent()}</span>
    </WalletPlaceholder>
  );
};

const WalletPlaceholder = styled('div')(({ theme }) => ({
  background: 'rgba(0, 101, 252, 0.1)',
  borderRadius: 10,
  display: 'flex',
  alignItems: 'center',
  gap: 11,
  padding: '0 20px',
  height: 56,
  '& span': {
    color: theme.colors.primary.main,
    fontSize: 14,
  },
  [theme.betweenDM]: {
    borderRadius: theme.pxToVw(10),
    gap: theme.pxToVw(11),
    height: theme.pxToVw(56),
    padding: `0 ${theme.pxToVw(20)}`,
    '& span': {
      fontSize: theme.pxToVw(14),
    },
  },
}));
