import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useRequest } from '../../../../hooks/useRequest';
import { useAddress } from '../../../../providers/AddressProvider/AddressProvider';

import { PostProjectRequestPayload, UserApi } from '../../../../api/UserApi/UserApi';

import { ButtonPrimary } from '../../../../components/ui/buttons';
import { InputField } from '../../../../components/ui/forms/Input/InputField';

interface Props {
  onNext: () => void;
}

export const EnterContractAddress: React.FC<Props> = ({ onNext }) => {
  const { t } = useTranslation();
  const { setAddress } = useAddress();

  const {
    register,
    setError,
    getValues,
    reset,
    formState: { errors },
  } = useFormContext<PostProjectRequestPayload>();

  const onSubmit = async () => {
    const { contractAddress } = getValues();
    if (!contractAddress)
      return setError('contractAddress', {
        message: t('errors.contractAddressRequired'),
      });

    setAddress(contractAddress);

    const metadata = await UserApi.getProjectMetadata(contractAddress);

    reset(metadata);

    onNext();
  };

  const { call, isLoading } = useRequest(onSubmit, {
    onError: err => {
      const msg = err?.response?.data?.message;

      const errTypes = [
        'INCORRECT_ADDRESS_LENGTH',
        'ADDRESS_IS_NOT_CONTRACT',
        'PROJECT_ALREADY_CONNECTED',
        'INTERNAL_SERVER_ERROR',
      ];

      const finalMsg = errTypes.includes(msg) ? msg : 'default';
      const errText = t(`errors.${finalMsg}`);

      setError('contractAddress', { message: errText });
    },
  });

  return (
    <>
      <InputField
        {...register('contractAddress')}
        label='Contract Address'
        placeholder={t('projectCreatePage.stepOnePlaceholder')}
        error={errors.contractAddress}
      />
      <ButtonPrimary type='button' width='100%' isLoading={isLoading} onClick={call}>
        {t('projectCreatePage.stepOneCta')}
      </ButtonPrimary>
    </>
  );
};
