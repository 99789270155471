import React from 'react';

import {
  ShieldHighTrust,
  ShieldMediumTrust,
  ShieldLowTrust,
  ShieldNewWallet,
  ShieldUntrusted,
  ShieldBackgroundDark,
  ClipPath,
} from './svgs';

import Styled from './Shields.styles';
import { Trust } from '../../../../../../types';

interface Props {
  trustPercentage: number | null | undefined;
  trust: Trust | null;
  isLoading?: boolean;
}

const ColoredTrustShield = {
  [Trust.High]: ShieldHighTrust,
  [Trust.Medium]: ShieldMediumTrust,
  [Trust.Low]: ShieldLowTrust,
  [Trust.NewWallet]: ShieldNewWallet,
  [Trust.Untrusted]: ShieldUntrusted,
};

export const Shields: React.FC<Props> = ({ trustPercentage, isLoading, trust }) => {
  const ColoredShield = trust ? ColoredTrustShield[trust] : null;

  const getShields = () => {
    if (!trust || typeof trustPercentage !== 'number') {
      return Array.from({ length: 4 }, () => 0);
    }

    if (trust === Trust.NewWallet) {
      return [100];
    }

    const shieldsSetup = {
      [Trust.High]: [4, 4, 100],
      [Trust.Medium]: [3, 4, 79],
      [Trust.Low]: [2, 4, 59],
      [Trust.Untrusted]: [1, 4, 39],
    };

    const [shieldsToFill, numOfBackgroundShields, upperPercentage] = shieldsSetup[trust];

    let currentTrustPercentage = trustPercentage;
    const percentages = [];

    const shieldFilledAtPercent = upperPercentage / shieldsToFill;

    while (percentages.length < shieldsToFill) {
      if (currentTrustPercentage >= shieldFilledAtPercent) {
        percentages.push(100);
      } else {
        const partialPercentage = (currentTrustPercentage / shieldFilledAtPercent) * 100;
        percentages.push(partialPercentage < 42 ? 42 : partialPercentage);
      }

      currentTrustPercentage = currentTrustPercentage - shieldFilledAtPercent;
    }

    while (percentages.length < numOfBackgroundShields) {
      percentages.push(0);
    }

    return percentages;
  };

  return (
    <Styled.Wrapper isLoading={!!isLoading}>
      <ClipPath style={{ position: 'absolute' }} />
      {getShields().map((heightPercentage, i) => (
        <Styled.ShieldWrapper key={i}>
          <Styled.ShieldBackground>
            <ShieldBackgroundDark />
          </Styled.ShieldBackground>
          {ColoredShield &&
            heightPercentage > 0 &&
            typeof trustPercentage === 'number' && (
              <Styled.ColoredShield
                key={`${i}-${100 - heightPercentage}-${trust}`}
                translateY={100 - heightPercentage}
                trustPercentage={trustPercentage}
                index={i}
                isNewWallet={trust === Trust.NewWallet}
              >
                <ColoredShield />
              </Styled.ColoredShield>
            )}
        </Styled.ShieldWrapper>
      ))}
    </Styled.Wrapper>
  );
};
