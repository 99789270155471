import styled from 'styled-components';

interface Props {
  name: string;
  onClick: () => void;
}

export default function DocItem({ name, onClick }: Props) {
  return (
    <Wrapper onClick={onClick}>
      <Svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M5.12325 0H11.8151L17.4797 5.90996V17.3982C17.4797 18.8353 16.315 20 14.8779 20H5.12325C3.6862 20 2.52148 18.8353 2.52148 17.3982V2.60177C2.52148 1.16474 3.6862 0 5.12325 0Z'
          fill='#0065FC'
        />
        <path
          opacity='0.302'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.8066 0V5.86141H17.4793L11.8066 0Z'
          fill='white'
        />
        <path
          d='M9.66759 14.7974C9.68431 14.8109 9.70392 14.8175 9.72218 14.8277C9.73736 14.8362 9.7508 14.8463 9.76718 14.8527C9.8104 14.8694 9.8554 14.8797 9.90112 14.8797C9.94684 14.8797 9.99189 14.8694 10.0352 14.8526C10.0517 14.8463 10.0651 14.836 10.0804 14.8275C10.0989 14.8172 10.1188 14.8103 10.1357 14.7966L11.9946 13.2923C12.1549 13.1626 12.1796 12.9277 12.0499 12.7676C11.9206 12.6079 11.6853 12.5824 11.5253 12.7123L10.2742 13.7248V10.3731C10.2742 10.167 10.1073 10 9.90112 10C9.69491 10 9.52806 10.167 9.52806 10.3731V13.7282L8.26274 12.7116C8.10206 12.5824 7.86742 12.6083 7.73848 12.7686C7.6095 12.9292 7.63499 13.164 7.79567 13.293L9.66759 14.7974Z'
          fill='white'
        />
        <path
          d='M13.2522 14.1211C13.046 14.1211 12.8792 14.2881 12.8792 14.4942V15.2926C12.8792 15.6819 12.5542 15.9987 12.1549 15.9987H7.62472C7.2254 15.9987 6.90042 15.6819 6.90042 15.2926V14.4942C6.90042 14.2881 6.73357 14.1211 6.52736 14.1211C6.32115 14.1211 6.1543 14.2881 6.1543 14.4942V15.2926C6.15425 16.0934 6.81408 16.7449 7.62472 16.7449H12.1549C12.9655 16.7449 13.6253 16.0934 13.6253 15.2926V14.4942C13.6253 14.2881 13.4584 14.1211 13.2522 14.1211Z'
          fill='white'
        />
      </Svg>
      {name}
    </Wrapper>
  );
}

const Svg = styled('svg')(({ theme }) => ({
  [theme.betweenDM]: {
    height: theme.pxToVw(20),
    width: 'auto',
  },
}));

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  fontSize: 12,
  cursor: 'pointer',
  transition: 'all .3s',
  opacity: 1,
  '&:hover': {
    opacity: 0.85,
  },
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(12),
  },
}));
