import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { NFT } from '../../../../../types';
import { getNftImageFromJson } from '../../../../../utils/getNftImageFromJson';

import { ContentLoader } from '../../../../../components/ui/loaders/ContentLoader';
import { ImageSquare } from '../../../../../components/ui/media/ImageSquare/ImageSquare';

interface Props {
  selected: boolean;
  nft: NFT;
  onClick: (nft: NFT) => void;
}

export const NftItem: React.FC<Props> = ({ onClick, nft, selected }) => {
  const { photoUrls } = nft;

  const [loaded, setLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(photoUrls[0]);
  const [currentPhotoIdx, setCurrentPhotoIdx] = useState(0);

  const loadImage = useCallback(async () => {
    if (loaded || currentPhotoIdx === 0) return;
    if (currentPhotoIdx === photoUrls.length) return setIsError(true);

    const nextUrl = photoUrls[currentPhotoIdx];
    if (nextUrl?.endsWith('.json')) {
      const nextPhotoUrl = await getNftImageFromJson(nextUrl);
      if (photoUrls.some(url => url === nextPhotoUrl)) {
        // this url is already present in photoUrls, go to next one to not load again
        setCurrentPhotoIdx(prev => prev + 1);
      } else {
        setPhotoUrl(nextPhotoUrl);
      }
    } else {
      setPhotoUrl(nextUrl);
    }
  }, [currentPhotoIdx, photoUrls, loaded]);

  useEffect(() => {
    loadImage();
  }, [loadImage]);

  const thePhoto =
    photoUrl && !isError
      ? photoUrl
      : 'https://blockem.s3.us-east-2.amazonaws.com/nft-default-photo.png';

  return (
    <Wrapper onClick={() => onClick(nft)}>
      <ImageWrapper>
        <Image url={thePhoto} selected={selected} position='top'>
          {!loaded && (
            <ContentLoader
              viewBox='0 0 200 200'
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
              }}
            >
              <rect x='0' y='0' height={200} width={200} />
            </ContentLoader>
          )}
        </Image>
        {selected && <Overlay />}
      </ImageWrapper>
      {thePhoto && !loaded && (
        <img
          src={thePhoto}
          alt='dsad'
          onLoad={e => {
            setLoaded(true);
          }}
          onError={e => {
            setCurrentPhotoIdx(prev => prev + 1);
          }}
          style={{ visibility: 'hidden', width: 1, height: 1, position: 'absolute' }}
        />
      )}
      <NftName>{nft.title || `#${nft.tokenId}`}</NftName>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  gap: 8,
  aspectRatio: '1',
  flex: '0 1 calc(16.666% - 11.8px)',
  cursor: 'pointer',
  [theme.max(680)]: {
    flex: '0 1 calc(25% - 10.7px)',
  },
  [theme.max(420)]: {
    flex: '0 1 calc(33.33% - 9.8px)',
  },
}));

const NftName = styled('p')(({ theme }) => ({
  fontSize: 12,
  lineHeight: 1.333,
  fontWeight: 700,
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(12),
  },
}));

const ImageWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  aspectRatio: '1',
  position: 'relative',
}));

const Overlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  content: '" "',
  background: 'linear-gradient(0deg, rgba(0, 101, 252, 0.35), rgba(0, 101, 252, 0.35))',
}));

const Image = styled(ImageSquare)(({ theme, selected, url }) => ({
  width: '100%',
  height: 'auto',
  aspectRatio: '1',
}));
