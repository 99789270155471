import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { envs } from '../../../config/envs';
import { splitStringNewLineBreak } from '../../../utils/string-utils';

import { AnalyticsEvent, AnalyticsService } from '../../../services/AnalyticsService';
import { Container } from '../../layouts/Container';
import { ButtonPrimary, ButtonSize, ButtonVariant } from '../../ui/buttons';
import { Heading2 } from '../../ui/text/Heading';

export const RealtimeFradulentCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Heading>
        {splitStringNewLineBreak(t('homePage.realTimeFraudulentHeading'))}
      </Heading>
      <ActionButtons>
        <ButtonPrimary
          size={ButtonSize.Lg}
          onClick={() => {
            AnalyticsService.userEvent({
              action: AnalyticsEvent.InstallExtensionButtonClick,
            });
            window.open(envs.extensionUrl, '_blank');
          }}
        >
          {t('common.installExtension')}
        </ButtonPrimary>
        <ButtonPrimary
          size={ButtonSize.Lg}
          variant={ButtonVariant.Outlined}
          onClick={() => {
            AnalyticsService.userEvent({
              action: AnalyticsEvent.FindOutMoreButtonClick,
            });

            window.open(envs.blockemWebsiteUrl, '_blank');
          }}
        >
          {t('common.moreAboutBlockem')}
        </ButtonPrimary>
      </ActionButtons>
    </Wrapper>
  );
};

const Wrapper = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '80px 0',
  borderRadius: 20,
  zIndex: theme.zIndex.md,
  [theme.min(theme.breakpoints.md)]: {
    background: '#0B0E11',
    padding: '60px 0',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    transform: 'translateY(50%)',
    marginTop: -60,
    marginBottom: 60,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    justifyContent: 'center',
    gap: theme.pxToVw(74),
    padding: `${theme.pxToVw(90)} 0`,
    marginTop: theme.pxToVw(-40),
    marginBottom: theme.pxToVw(60),
  },
  [theme.min(theme.breakpoints.max)]: {
    padding: '90px 0',
    marginTop: -40,
    marginBottom: 60,
    gap: 74,
  },
}));

const ActionButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 10,
  width: '100%',
  maxWidth: 295,
  '& button': {
    width: '100%',
    maxWidth: 295,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    flexDirection: 'row',
    maxWidth: theme.pxToVw(568),
    '& button': {
      maxWidth: theme.pxToVw(274),
    },
  },
  [theme.min(theme.breakpoints.max)]: {
    maxWidth: 568,
    '& button': {
      maxWidth: 274,
    },
  },
}));

const Heading = styled(Heading2)(({ theme }) => ({
  marginBottom: 12,
  lineHeight: 1.18,
  position: 'relative',
  zIndex: 2,
  textAlign: 'center',
  [theme.min(theme.breakpoints.md)]: {
    textAlign: 'left',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    marginBottom: theme.pxToVw(20),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginBottom: 20,
  },
}));
