import React from 'react';

interface Props {
  children: React.ReactNode;
}

export class ErrorBoundary extends React.Component<Props> {
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
  }

  render() {
    return this.props.children;
  }
}
