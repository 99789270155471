import { useCallback, useMemo } from 'react';
import { useAuth } from '../../../../providers/AuthProvider';
import { useProjectByParamId } from '../../../../react-query/project/useProject';
import { useCurrentUserFollowings } from '../../../../react-query/user/useFollowings';
import { useUser } from '../../../../react-query/user/useUser';
import { useUserProjects } from '../../../../react-query/user/useUserProjects';

export const useProjectUtils = () => {
  const { isAuthenticated } = useAuth();
  const { data: user } = useUser();
  const { data: userFollowings } = useCurrentUserFollowings();
  const { data: userProjects } = useUserProjects();
  const { data: project } = useProjectByParamId();

  const { contractAddress } = project || {};

  const isProjectOwner = useCallback(
    (contractAddress: string) =>
      user &&
      userProjects &&
      userProjects.some(
        proj => proj.contractAddress.toLowerCase() === contractAddress.toLowerCase()
      ),
    [userProjects, user]
  );

  const isOwner = useMemo(() => {
    return isAuthenticated && contractAddress && isProjectOwner(contractAddress);
  }, [isAuthenticated, contractAddress, isProjectOwner]);

  const isFollowedProject = useCallback(
    (contractAddress: string) =>
      userFollowings &&
      userFollowings.some(following => following.address === contractAddress),
    [userFollowings]
  );

  return {
    isOwner,
    isClaimedProject: !!project?.uid,
    followedProjects: [],
    isFollowedProject,
    isProjectOwner,
  };
};
