import React from 'react';
import styled, { CSSObject } from 'styled-components';
import { ButtonSize } from '.';
import { Icon } from '../../../assets';
import { Theme } from '../../../config/theme';

export interface ButtonBaseProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onMouseDown'> {
  size?: ButtonSize;
  isLoading?: boolean;
  width?: string | number;
  showSpinner?: boolean;
}

const buttonStyles = (theme: Theme) => ({
  [ButtonSize.Lg]: {
    fontSize: 16,
    height: 56,
    padding: '0 24px',
    [theme.min(theme.breakpoints.desktop)]: {
      fontSize: theme.pxToVw(20),
      height: theme.pxToVw(64),
      padding: `0 ${theme.pxToVw(28)}`,
    },
    [theme.min(theme.breakpoints.max)]: {
      fontSize: 20,
      height: 64,
      padding: '0 28px',
    },
  },
  [ButtonSize.Md]: {
    fontSize: 14,
    height: 48,
    padding: '0 20px',
    [theme.min(theme.breakpoints.desktop)]: {
      fontSize: theme.pxToVw(16),
      height: theme.pxToVw(56),
      padding: `0 ${theme.pxToVw(28)}`,
    },
    [theme.min(theme.breakpoints.max)]: {
      fontSize: 16,
      height: 56,
      padding: '0 28px',
    },
  },
  [ButtonSize.Sm]: {
    height: 40,
    padding: '0 24px',
    [theme.min(theme.breakpoints.desktop)]: {
      fontSize: theme.pxToVw(14),
      height: theme.pxToVw(48),
      padding: `0 ${theme.pxToVw(18)}`,
    },
    [theme.min(theme.breakpoints.max)]: {
      fontSize: 14,
      height: 48,
      padding: '0 18px',
    },
  },
  [ButtonSize.Xs]: {
    fontSize: 14,
    height: 36,
    padding: '0 24px',
    [theme.min(theme.breakpoints.desktop)]: {
      fontSize: theme.pxToVw(14),
      height: theme.pxToVw(40),
      padding: `0 ${theme.pxToVw(16)}`,
    },
    [theme.min(theme.breakpoints.max)]: {
      fontSize: 14,
      height: 40,
      padding: '0 16px',
    },
  },
});

const getSpinnerSizeAndPosition = (size: ButtonSize): CSSObject => {
  switch (size) {
    case ButtonSize.Lg:
      return {
        width: 22,
        height: 22,
        left: -40,
      };
    case ButtonSize.Md:
      return {
        width: 20,
        height: 20,
        left: -32,
      };
    case ButtonSize.Sm:
      return {
        width: 18,
        height: 18,
        left: -24,
      };
    case ButtonSize.Xs:
      return {
        width: 14,
        height: 14,
        left: -10,
      };
  }
};

const ButtonBaseEl = styled('button')<ButtonBaseProps>(
  ({ theme, isLoading, disabled, width, size = ButtonSize.Md }) => {
    const style: CSSObject = {
      textAlign: 'center',
      borderRadius: 10,
      fontWeight: 700,
      outline: 'none',
      opacity: isLoading || disabled ? 0.7 : 1,
      cursor: isLoading ? 'wait' : disabled ? 'not-allowed' : 'pointer', // check why this is not working properly
      transition: 'all 0.4s',
      flexShrink: 0,
      ...buttonStyles(theme)[size],
      '& span:not(.spinner)': {
        position: 'relative',
        overflow: 'visible',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'max-content',
        margin: '0 auto',
      },
      '& .spinner': {
        display: 'block',
        ...getSpinnerSizeAndPosition(size),
        position: 'absolute',
        zIndex: 5,
        fill: theme.colors.text.primary,
        color: theme.colors.text.primary,
        animation: 'spin 1.1s infinite ease',
        ...theme.keyframes.spin,
      },
      [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
        borderRadius: theme.pxToVw(10),
      },
    };

    if (width) style.width = width;

    return style;
  }
);

export const ButtonBase: React.FC<ButtonBaseProps & { children: React.ReactNode }> = ({
  children,
  showSpinner = true,
  ...props
}) => {
  return (
    <ButtonBaseEl {...props}>
      <span>
        {props.isLoading && showSpinner && <Icon.Spinner className='spinner' />}
        {children}
      </span>
    </ButtonBaseEl>
  );
};

interface Props {
  dimensions?: number | string;
}

export const ButtonSquareBase = styled(ButtonBase)<Props>(
  ({ dimensions = 20, theme }) => ({
    width: dimensions,
    height: dimensions,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.max(theme.breakpoints.md)]: {
      width: `calc(${
        typeof dimensions === 'number' ? dimensions / 10 + 'rem' : dimensions
      } * 0.85)`,
      height: `calc(${
        typeof dimensions === 'number' ? dimensions / 10 + 'rem' : dimensions
      } * 0.85)`,
    },
  })
);
