import ReactGA from 'react-ga4';
import { envs } from '../config/envs';
import { isDev } from '../utils/environment';

interface IUserEvent {
  action: string;
  label?: string;
  value?: number;
}

export enum AnalyticsEvent {
  ManualAnalysis = 'ManualAnalysis',
  RedirectAnalysis = 'RedirectAnalysis',
  HeaderButtonClick = 'HeaderButtonExploreBlockemClick',
  InstallExtensionButtonClick = 'InstallExtensionButtonClick',
  GetInTouchButtonClick = 'GetInTouchButtonClick',
  FindOutMoreButtonClick = 'FindOutMoreButtonClick',
  ShareTwitterClick = 'ShareTwitterClick',
  VisitViaRedirect = 'VisitViaRedirect',
  RegisterViaRedirect = 'RegisterViaRedirect',
  InstallExtensionNewUserModalClicked = 'InstallExtensionNewUserModalClicked',
}

export enum AnalyticsCategory {
  User = 'User',
  Redirect = 'Redirect',
}

export class AnalyticsService {
  static init() {
    if (isDev()) return;
    if (envs.google.analyticsId) ReactGA.initialize(envs.google.analyticsId);
  }

  static pageview(url: string) {
    if (isDev()) return;
    ReactGA.pageview(url);
  }

  static userEvent(payload: IUserEvent) {
    if (isDev()) return;
    ReactGA.event({
      category: AnalyticsCategory.User,
      ...payload,
    });
  }

  static trackEvent(payload: IUserEvent & { category: AnalyticsCategory }) {
    if (isDev()) return;
    ReactGA.event(payload);
  }

  static fbq(...args: any[]) {
    if (isDev()) return;
    // @ts-ignore
    typeof fbq === 'function' && fbq(...args);
  }

  static twq(...args: any[]) {
    if (isDev()) return;
    // @ts-ignore
    typeof twq === 'function' && twq(...args);
  }
}
