import React from 'react';
import { useTheme } from 'styled-components';

import { useTrustCategory } from '../../../../../../components/common/AnalysisResult/TrustCategoryProvider';
import { useAddress } from '../../../../../../providers/AddressProvider/AddressProvider';
import { useReputationWithAddress } from '../../../../../../providers/AddressProvider/useReputationWithAddress';
import { useWindowWidth } from '../../../../../../hooks/useWindowWidth';

import { Address } from '../../../../../../components/common/Address/Address';

export const ProjectReputationAddress: React.FC = () => {
  const { address } = useAddress();
  const { data } = useReputationWithAddress();
  const { trust } = useTrustCategory();
  const width = useWindowWidth();
  const theme = useTheme();

  const { isContract } = data || {};

  const shorten =
    width < 600 || (width > theme.breakpoints.md && width < theme.breakpoints.xl);

  return (
    <Address
      address={address || '0x0'}
      trust={trust}
      where='project'
      isContract={!!isContract}
      shorten={shorten}
      isLoading={!data}
    />
  );
};
