import { useState } from 'react';
import styled from 'styled-components';

import { useProfileByParamId } from '../../../../react-query/profile/useProfile';
import { useProfileUtils } from '../../hooks/useProfileUtils';

import { ProfileImageComponent } from '../../../../components/profile/ProfileImage';
import { AddNFTModal } from './AddNFTModal/AddNFTModal';

export const ProfileImage: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { isOwner } = useProfileUtils();
  const { data } = useProfileByParamId();

  const onClick = () => {
    if (!isOwner) return;
    setOpen(true);
  };

  return (
    <>
      <StyledProfileImage isOwner={isOwner} photoUrl={data?.photoUrl} onClick={onClick} />
      {isOwner && open && <AddNFTModal onClose={() => setOpen(false)} />}
    </>
  );
};

const StyledProfileImage = styled(ProfileImageComponent)(({ theme }) => ({
  margin: '0 auto 20px auto',
  [theme.min(theme.breakpoints.sm)]: {
    margin: '0 auto 10px 0',
  },
  [theme.betweenDM]: {
    marginBottom: theme.pxToVw(10),
  },
}));
