import React from 'react';
import styled from 'styled-components';

import { Container } from '../../../../../components/layouts/Container';
import { FrequencyOfTransactions } from './FrequencyOfTransactions/FrequencyOfTransactions';
import { SocialNetworksAnalysis } from './SocialNetworksAnalysis/SocialNetworksAnalysis';

export const ProjectCharts: React.FC = () => {
  return (
    <Wrapper>
      <FrequencyOfTransactions />
      <Container>
        <SocialNetworksAnalysis />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 48,
  marginBottom: 32,
  [theme.min(theme.breakpoints.md)]: {
    marginBottom: 48,
  },
  [theme.betweenDM]: {
    marginBottom: theme.pxToVw(64),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginBottom: 72,
  },
}));
