import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useUser } from '../../../react-query/user/useUser';

import { ButtonPrimary } from '../../../components/ui/buttons';
import { ErrorMessage } from '../../../components/ui/forms/FormFieldWrapper/ErrorMessage';
import { routes } from '../../../router/routes';

interface Props {
  message: string;
}

export const ProjectOnboardError: React.FC<Props> = ({ message }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: user } = useUser();

  return (
    <>
      <ErrMsg error={message} />
      <ButtonPrimary onClick={() => navigate(routes.profile.byId(user?.username!))}>
        {t('projectOnboard.goToYourProfile')}
      </ButtonPrimary>
    </>
  );
};

const ErrMsg = styled(ErrorMessage)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: 24,
  marginTop: 24,
  fontSize: 14,
  fontWeight: 700,
  lineHeight: 1.31,
  [theme.min(theme.breakpoints.md)]: {
    fontSize: 16,
  },
  [theme.betweenDM]: {
    marginBottom: theme.pxToVw(24),
    marginTop: theme.pxToVw(24),
    fontSize: theme.pxToVw(16),
  },
}));
