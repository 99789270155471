import React from 'react';

import { useProfileByParamId } from '../../react-query/profile/useProfile';
import { useProfileUtils } from './hooks/useProfileUtils';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';

import { LoadingProvider } from '../../providers/LoadingProvider';
import { ConnectedAddressesExpandable } from './components/ConnectedAddressesExpandable/ConnectedAddressesExpandable';
import { ProfileAssetsDetails } from './components/ProfileAssetsDetails/ProfileAssetsDetails';
import { ProfileData } from './components/ProfileData/ProfileData';
import { ProfileImage } from './components/ProfileImage/ProfileImage';
import { ProfileInfo } from './components/ProfileInfo/ProfileInfo';
import { FollowAllAndEditCover } from './components/FollowAllAndEditCover/FollowAllAndEditCover';
import { PageLoader } from '../../components/ui/loaders/PageLoader';
import { NewUserModal } from './components/NewUserModal/NewUserModal';
import { ProfilePageMainSection } from '../../components/profile/profile-elements';
import { GenerateProfileSection } from '../../components/common/GenerateProfile/GenerateProfileSection';

import Styled from './ProfilePage.styles';

export const ProfilePage: React.FC = () => {
  const { data } = useProfileByParamId();
  useDocumentTitle(data?.username);

  if (!data) return <PageLoader />;

  return (
    <>
      <LoadingProvider>
        <ProfilePageMainSection imgUrl={data?.coverPhoto}>
          <Styled.Container>
            <ProfileImage />
            <Styled.MainDetails>
              <ProfileInfo />
              <FollowAllAndEditCover />
              <DetailsAndConnectedAddresses />
            </Styled.MainDetails>
          </Styled.Container>
        </ProfilePageMainSection>
        <ProfileData />
      </LoadingProvider>
      <NewUserModal />
      <GenerateProfileSection />
    </>
  );
};

const DetailsAndConnectedAddresses: React.FC = () => {
  const width = useWindowWidth();
  const { isClaimedAddress } = useProfileUtils();

  return width > 1280 ? (
    <Styled.AssetDetailsWrapper>
      <ProfileAssetsDetails />
      {isClaimedAddress && <ConnectedAddressesExpandable />}
    </Styled.AssetDetailsWrapper>
  ) : (
    <>
      {isClaimedAddress && <ConnectedAddressesExpandable />}
      <ProfileAssetsDetails />
    </>
  );
};
