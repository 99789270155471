import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { usePlaceholderItems } from '../../../hooks/usePlaceholderItems';

import { useAuth } from '../../../providers/AuthProvider';
import { useAppMessage } from '../../../providers/AppMessageProvider/AppMessageProvider';

import { UrlService } from '../../../services/UrlService';
import { AppMessage } from '../../../types';

import { ShareProfileButton } from '../../common/ActionButtons/ShareProfileButton';
import { LoadingBackground } from '../../ui/loaders/LoadingBackground';

import ProfileInfoIcon from './svgs';

const { WWWSvg, TwitterSvg, OpenSeaSvg, DiscordSvg } = ProfileInfoIcon;

interface Props {
  twitterUrl?: string | null;
  discordUrl?: string | null;
  openseaUrl?: string | null;
  externalUrl?: string | null;
  isClaimedAddress?: boolean;
  shareUrl?: string;
  isLoading?: boolean;
}

export const SocialIcons: React.FC<Props> = ({
  twitterUrl,
  discordUrl,
  openseaUrl,
  externalUrl,
  isClaimedAddress,
  shareUrl,
  isLoading,
}) => {
  const { isAuthenticated } = useAuth();
  const { toast } = useAppMessage();
  const placeholderItems = usePlaceholderItems(isLoading ? 3 : 0, IconPlaceholder);

  const onUrlClick = useCallback(
    (url: string | null | undefined) => {
      if (!isAuthenticated && !isClaimedAddress)
        return toast(AppMessage.SocialNetworksNotAdded);

      if (url) window.open(UrlService.toHttpsUrl(url), '_blank');
    },
    [isClaimedAddress, isAuthenticated, toast]
  );

  const socialNetworks = useMemo(() => {
    if (isAuthenticated && !isClaimedAddress) return [];

    let items = [
      {
        Icon: <WWWSvg />,
        url: externalUrl,
        onClick: () => onUrlClick(externalUrl),
      },
      {
        Icon: <TwitterSvg />,
        url: twitterUrl,
        onClick: () => {
          if (!twitterUrl) return;
          if (UrlService.isUrl(twitterUrl)) {
            onUrlClick(UrlService.toHttpsUrl(twitterUrl));
          } else {
            onUrlClick(`https://twitter.com/${twitterUrl}`);
          }
        },
      },
      {
        Icon: <OpenSeaSvg />,
        url: openseaUrl,
        onClick: () => onUrlClick(openseaUrl),
      },
      {
        Icon: <DiscordSvg />,
        url: discordUrl,
        onClick: () => {
          if (!discordUrl) return;
          if (UrlService.isUrl(discordUrl)) {
            onUrlClick(UrlService.toHttpsUrl(discordUrl));
          } else {
            onUrlClick(`https://discord.com/users/${discordUrl}`);
          }
        },
      },
    ];

    if (isClaimedAddress) {
      items = items.filter(item => item.url);
    }

    return items;
  }, [
    isAuthenticated,
    isClaimedAddress,
    onUrlClick,
    discordUrl,
    twitterUrl,
    openseaUrl,
    externalUrl,
  ]);

  const renderShareButton = () => {
    if (!shareUrl) return null;
    if (isLoading)
      return (
        <>
          <Divider />
          <IconPlaceholder />
        </>
      );

    return <ShareProfileButton shareUrl={shareUrl} />;
  };

  return (
    <Wrapper>
      {!isLoading
        ? socialNetworks.map(({ url, Icon, onClick }, i) => (
            <IconWrapper
              key={i}
              onClick={e => {
                e.stopPropagation();
                onClick();
              }}
            >
              {Icon}
            </IconWrapper>
          ))
        : placeholderItems}
      {!!socialNetworks.length && <Divider />}
      {renderShareButton()}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 20,
  '& svg': {
    height: 24,
    width: 24,
    flexShrink: 0,
  },
  '& button': {
    height: 25,
    width: 25,
    '& svg': {
      height: 12,
      width: 'auto',
    },
  },
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(32),
    '& svg': { height: theme.pxToVw(36), width: theme.pxToVw(36) },
    '& button': {
      height: theme.pxToVw(36),
      width: theme.pxToVw(36),
      '& svg': {
        height: theme.pxToVw(18),
        width: 'auto',
      },
    },
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 32,
    '& svg': { height: 36, width: 36 },
    '& button': {
      height: 36,
      width: 36,
      '& svg': {
        height: 18,
        width: 'auto',
      },
    },
  },
}));

const IconPlaceholder = styled(LoadingBackground)(({ theme }) => ({
  height: 24,
  width: 24,
  borderRadius: '50%',
  [theme.min(theme.breakpoints.desktop)]: {
    height: theme.pxToVw(36),
    width: theme.pxToVw(36),
  },
  [theme.min(theme.breakpoints.max)]: {
    height: 36,
    width: 36,
  },
}));

const IconWrapper = styled('span')(({ theme }) => ({
  cursor: 'pointer',
  transition: 'opacity .3s',
  opacity: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  '&:hover': {
    opacity: 0.85,
  },
  [theme.betweenDM]: {
    '& svg': {
      height: theme.pxToVw(36),
      width: theme.pxToVw(36),
    },
  },
}));

const Divider = styled('span')(({ theme }) => ({
  display: 'block',
  content: '" "',
  width: 1,
  height: 15.3,
  backgroundColor: 'rgba(255, 255, 255, 0.28)',
  flexShrink: 0,
  [theme.betweenDM]: {
    height: theme.pxToVw(15.3),
  },
}));
