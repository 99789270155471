import React, { useEffect } from 'react';
import styled from 'styled-components';
import { AppMessage } from '../../../types';
import { useAppMessage } from '../AppMessageProvider';

import icons from './assets';
import { appMessagesContentMap } from './app-messages';

interface Props {
  status?: 'info';
  appMessage: AppMessage;
}

export const AppMessageComponent: React.FC<Props> = ({ appMessage, status = 'info' }) => {
  const { clear } = useAppMessage();
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (timeout) clearTimeout(timeout);
      clear(appMessage);
    }, 10000);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [clear, appMessage]);

  const Component = appMessagesContentMap[appMessage];

  return (
    <Wrapper>
      {icons[status]} <Component />
      <XMarkIcon onClick={() => clear(appMessage)} />
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  padding: '23px 14px 20px 16px',
  border: `2px solid ${theme.colors.primary.main}`,
  boxShadow: '0px 0px 3px 2px rgba(53, 133, 254, 0.39)',
  borderRadius: 15,
  backgroundColor: '#000',
  display: 'flex',
  gap: 12,
  width: '90vw',
  position: 'relative',
  '& .status-icon': {
    fill: theme.colors.primary.main,
    width: 35,
    height: 35,
    flexShrink: 0,
  },
  [theme.min(theme.breakpoints.xs)]: {
    width: 400,
    padding: 24,
  },
}));

const Svg = styled('svg')(({ theme }) => ({
  width: 10,
  height: 'auto',
  cursor: 'pointer',
  transition: 'all .3s',
  opacity: 1,
  '&:hover': {
    opacity: 0.85,
  },
}));

const CloseWrapper = styled('span')(({ theme }) => ({
  position: 'absolute',
  top: 12,
  right: 12,
  cursor: 'pointer',
  transition: 'all .3s',
  opacity: 1,
  '&:hover': {
    opacity: 0.85,
  },
}));

const XMarkIcon: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <CloseWrapper onClick={onClick}>
    <Svg
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_853_9459)'>
        <path
          d='M4.16826 4.99999L0.172495 1.00423C-0.0574989 0.774235 -0.0574989 0.402488 0.172495 0.172495C0.402488 -0.0574983 0.774236 -0.0574983 1.00423 0.172495L4.99999 4.16826L8.99577 0.172495C9.22576 -0.0574983 9.59751 -0.0574983 9.82751 0.172495C10.0575 0.402488 10.0575 0.774235 9.82751 1.00423L5.83174 4.99999L9.82751 8.99575C10.0575 9.22575 10.0575 9.59749 9.82751 9.82749C9.7128 9.94219 9.56222 9.99982 9.41164 9.99982C9.26106 9.99982 9.11048 9.94217 8.99577 9.82749L5.00001 5.83172L1.00425 9.82749C0.889544 9.94219 0.738962 9.99982 0.588381 9.99982C0.437799 9.99982 0.287218 9.94217 0.172514 9.82749C-0.0574789 9.59749 -0.0574789 9.22575 0.172514 8.99575L4.16826 4.99999Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_853_9459'>
          <rect width='10' height='10' fill='white' transform='matrix(-1 0 0 1 10 0)' />
        </clipPath>
      </defs>
    </Svg>
  </CloseWrapper>
);
