import { useQuery } from 'react-query';
import { ProfileApi } from '../../api/ProfileApi';
import { QueryKey } from '../query-keys';
import { useProfileByParamId } from './useProfile';

export const useFollowingsCount = (userUid: string | null | undefined) => {
  return useQuery(
    QueryKey.ProfileFollowingsCount(userUid!),
    () => ProfileApi.getFollowingsCount(userUid!),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: !!userUid,
    }
  );
};

export const useProfileFollowingsCount = () => {
  const { data } = useProfileByParamId();
  return useFollowingsCount(data?.uid);
};
