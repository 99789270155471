import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useFilePicker } from 'use-file-picker';
import { useTranslation } from 'react-i18next';
import { useInvalidateCurrentUserProfile } from '../../pages/profile/hooks/useInvalidateCurrentUserProfile';
import { useRequest } from '../../hooks/useRequest';

import { Icon } from '../../assets';
import { ButtonPrimary, ButtonSize, ButtonVariant } from '../ui/buttons';
import { FilePickerAccept } from '../ui/forms/FilePickerWrapper';
import { Spinner } from '../ui/loaders/Spinner';

interface Props {
  onChange: (formData: FormData) => Promise<void>;
}

export const EditCoverPhotoButton: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation();
  const invalidate = useInvalidateCurrentUserProfile();

  const [openFileSelector, { plainFiles, loading, clear }] = useFilePicker({
    accept: FilePickerAccept.Image,
  });

  const { call, isLoading } = useRequest(onChange, { onSuccess: invalidate });

  useEffect(() => {
    if (!plainFiles?.length) return;
    const formData = new FormData();
    formData.append('photo', plainFiles[0]);
    call(formData);
    clear();
  }, [plainFiles, call, clear, invalidate]);

  return (
    <CoverPhotoButton
      disabled={isLoading || loading}
      size={ButtonSize.Sm}
      variant={ButtonVariant.Outlined}
      onClick={openFileSelector}
    >
      {isLoading || loading ? <Spinner size={10} /> : <Icon.EditUnderlined />}
      {t('profilePage.editCoverPhoto')}
    </CoverPhotoButton>
  );
};

const CoverPhotoButton = styled(ButtonPrimary)(({ theme }) => ({
  '& svg': {
    height: 14,
    width: 14,
    marginRight: 10,
    [theme.betweenDM]: {
      height: theme.pxToVw(14),
      width: theme.pxToVw(14),
      marginRight: theme.pxToVw(10),
    },
  },
}));
