import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useRecommendedUsers } from '../../../../../react-query/user/useRecommendedUsers';

import { NoItemsText } from '../../../../profile/components/ProfileData/components';
import { NewsFeedGrid } from '../components/NewsFeedGrid';
import { NewsFeedProfileCard } from '../components/NewsFeedProfileCard';
import { NewsFeedCardBase, NewsFeedTitle } from '../components/shared';

import { routes } from '../../../../../router/routes';

export const RecommendedUsersSection: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { formattedData, isLoading, error } = useRecommendedUsers();

  const renderContent = () => {
    if (error) return <NoItemsText isError>{t('errors.default')}</NoItemsText>;

    if (!formattedData) return null;

    return formattedData.map(({ photoUrl, username, followersCount }) => (
      <NewsFeedProfileCard
        key={username}
        photoUrl={photoUrl}
        title={username}
        followersCount={followersCount}
        onClick={() => navigate(routes.profile.byId(username))}
      />
    ));
  };

  return (
    <NewsFeedCardBase>
      <NewsFeedTitle>{t('homePage.recomendedUsers')}</NewsFeedTitle>
      <NewsFeedGrid isLoading={isLoading}>{renderContent()}</NewsFeedGrid>
    </NewsFeedCardBase>
  );
};
