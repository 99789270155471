import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { ProjectAnalyticsApi } from '../../api/ProjectAnalyticsApi';
import { isValidEthAddress } from '../../utils/address-utils';
import { QueryKey } from '../query-keys';
import { useProject } from './useProject';

// Currently params.interval is ignored in query, its always "all"

export const useProjectFrequencyOfTransactions = (
  contractAddress: string | null | undefined,
  params: { interval: string }
) => {
  const { isLoading, data, error } = useProject(contractAddress);

  const query = useQuery(
    QueryKey.ProjectAnalyticsFrequencyOfTransactions(contractAddress!, 'all'),
    () => ProjectAnalyticsApi.getTransactionFrequency(contractAddress!, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled:
        !!data &&
        !error &&
        !!contractAddress &&
        isValidEthAddress(contractAddress) &&
        !!data.uid,
    }
  );

  return {
    ...query,
    isLoading: isLoading || query.isLoading,
  };
};

export const useProjectFrequencyOfTransactionsByParamId = (params: {
  interval: string;
}) => {
  const { project } = useParams();

  return useProjectFrequencyOfTransactions(project, params);
};
