import React from 'react';
import styled from 'styled-components';
import { usePlaceholderItems } from '../../../hooks/usePlaceholderItems';
import { Notification } from '../../../types';
import {
  NotificationItem,
  NotificationItemLoader,
} from './NotificationItem/NotificationItem';

interface Props {
  notifications: Notification[];
  isLoading: boolean;
}

export const NotificationList: React.FC<Props> = ({ notifications, isLoading }) => {
  const loadingItems = usePlaceholderItems(6, NotificationItemLoader);

  return (
    <Wrapper>
      {isLoading
        ? loadingItems
        : notifications.map(notification => (
            <NotificationItem key={notification.id} notification={notification} />
          ))}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
}));
