import qs from 'qs';

const routes = {
  home: () => '/',
  termsOfService: () => '/terms-of-service',
  privacyPolicy: () => '/privacy-policy',
  auth: {
    login: () => '/auth/login',
    register: (options?: any) =>
      `/auth/register${options ? '?' + qs.stringify(options) : ''}`,
    resetPassword: () => '/auth/reset-password',
  },
  accountDetails: {
    root: () => '/account-details',
  },
  profile: {
    root: () => '/profile',
    byId: (id: string) => `/profile/${id}`,
  },
  project: {
    root: () => '/project',
    create: () => '/project/create',
    editById: (id: string) => `/project/${id}/edit`,
    byId: (id: string) => `/project/${id}`,
    roadmap: {
      root: (projectId: string) => `/project/${projectId}/roadmap`,
    },
  },
  myProjects: {
    root: () => '/my-projects',
  },
  projectOnboard: {
    root: () => '/project-onboard',
  },
  generateWeb3Profile: {
    root: () => '/generate-web3-profile',
  },
  error: (status: number | string) => `/error/${status}`,
  notifications: {
    root: () => '/notifications',
  },
  contact: () => '/contact',
};

export { routes };
