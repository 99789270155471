import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Trust } from '../../../../../../types';

import { useTrustCategory } from '../../../../../../components/common/AnalysisResult/TrustCategoryProvider';
import { useReputationWithAddress } from '../../../../../../providers/AddressProvider/useReputationWithAddress';

import { Shields } from '../../../../../../components/common/AnalysisResult/AnalysisDetails/TrustPercentageCard/Shields/Shields';
import { LoadingBackground } from '../../../../../../components/ui/loaders/LoadingBackground';
import { Text } from '../../../../../../components/ui/text/Text';

export const ProjectReputationTrustPercentage: React.FC = () => {
  const { t } = useTranslation();
  const { data: reputation } = useReputationWithAddress();
  const { trust } = useTrustCategory();

  const renderPercentage = () => {
    if (!reputation)
      return (
        <LoadingBg>
          <Percentage isNewAddress={false}>L</Percentage>
        </LoadingBg>
      );

    return (
      <Percentage isNewAddress={reputation?.trustPercentage === -1}>
        {reputation.trustPercentage === -1
          ? t('analysisResult.trustPercentage.notEnoughInfo')
          : `${reputation.trustPercentage}%`}
      </Percentage>
    );
  };

  return (
    <Wrapper>
      <Title>{t('analysisResult.trustPercentage.title')}</Title>
      <Flex>
        {renderPercentage()}
        <ShieldsWrapper>
          <Shields
            trust={trust || Trust.High}
            trustPercentage={reputation?.trustPercentage}
            isLoading={!reputation}
          />
        </ShieldsWrapper>
      </Flex>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
}));

const Title = styled(Text.MD)(({ theme }) => ({
  marginBottom: 19,
  fontWeight: 700,
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(20),
    marginBottom: theme.pxToVw(15),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 20,
    marginBottom: 15,
  },
}));

const Percentage = styled('span')<{ isNewAddress: boolean }>(
  ({ theme, isNewAddress }) => ({
    marginBottom: 19,
    fontSize: isNewAddress ? 18 : 24,
    fontWeight: 900,
    [theme.betweenDM]: {
      fontSize: isNewAddress ? theme.pxToVw(18) : theme.pxToVw(24),
    },
  })
);

const Flex = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const ShieldsWrapper = styled('div')(({ theme }) => ({
  transform: 'scale(0.6) translate(60%, 0)',
  transformOrigin: '0 0',
}));

const LoadingBg = styled(LoadingBackground)(({ theme }) => ({
  width: 80,
  [theme.betweenDM]: {
    width: theme.pxToVw(80),
  },
}));
