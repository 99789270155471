import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useReputation } from '../reputation/useReputation';

import { ProfileApi } from '../../api/ProfileApi';
import { QueryKey } from '../query-keys';
import { isValidEthAddress } from '../../utils/address-utils';
import { routes } from '../../router/routes';

export const useProfile = (id?: string | null, disableRedirect?: boolean) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(!!id && !isValidEthAddress(id));

  const isAddress = !!id && !!isValidEthAddress(id);

  const { data: reputation, isLoading } = useReputation(isAddress ? id : undefined);

  const { isContract } = reputation || {};

  useEffect(() => {
    if (!id || !isAddress || !reputation || enabled) return;

    if (isContract) return navigate(routes.project.byId(id));
    setEnabled(true);
  }, [isAddress, reputation, enabled, id, isContract, navigate]);

  const query = useQuery(
    QueryKey.Profile(id!),
    () => ProfileApi.getProfileByUsername(id!),
    {
      onSuccess: data => {
        if (id && isValidEthAddress(id)) {
          queryClient.setQueryData(QueryKey.Profile(data.username), data);
          !disableRedirect &&
            navigate(routes.profile.byId(data.username), { replace: true });
        }
      },
      onError: (err: any) => {
        if (err.response.status === 404) {
          navigate(routes.error(404), { state: { error: 'profileNotFound' } });
        }
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled,
    }
  );

  return {
    ...query,
    isLoading: query.isLoading || isLoading,
  };
};

export const useProfileByParamId = () => {
  const { id } = useParams();
  return useProfile(id);
};
