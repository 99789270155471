import styled from 'styled-components';

const BaseCard = styled('div')(({ theme }) => ({
  backgroundColor: '#000',
  borderRadius: 15,
  border: '1px solid rgba(255, 255, 255, 0.15)',
  overflow: 'hidden',
  padding: 20,
  position: 'relative',
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    padding: theme.pxToVw(20),
    borderRadius: theme.pxToVw(15),
  },
}));

const TopContentWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
}));

const BottomContentWrapper = styled('div')(({ theme }) => ({
  width: '100%',
}));

export const AnalysisCardComponents = {
  BaseCard,
  TopContentWrapper,
  BottomContentWrapper,
};
