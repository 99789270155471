import React from 'react';
import styled from 'styled-components';

import { useTrustCategory } from '../../../../../../components/common/AnalysisResult/TrustCategoryProvider';

import { Trust } from '../../../../../../types';

import { ProjectReputationAddress } from './ProjectReputationAddress';
import { ProjectReputationText } from './ProjectReputationText';
import { ProjectReputationTrustTitle } from './ProjectReputationTrustTitle';
import { ProjectReputationTrustPercentage } from './TrustPercentage';

export const ProjectReputationSummary: React.FC = () => {
  const { trust } = useTrustCategory();

  return (
    <Wrapper>
      {trust && <TrustBackground trust={trust} />}
      <Content>
        <ProjectReputationTrustTitle />
        <ProjectReputationAddress />
        <ProjectReputationText />
        <ProjectReputationTrustPercentage />
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  borderRadius: 10,
  position: 'relative',
  overflow: 'hidden',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  padding: '34px 20px 24px 20px',
  [theme.min(theme.breakpoints.desktop)]: {
    borderRadius: theme.pxToVw(24),
    padding: `${theme.pxToVw(40)} ${theme.pxToVw(32)} ${theme.pxToVw(26)} ${theme.pxToVw(
      32
    )}`,
    flex: 1,
  },
  [theme.min(theme.breakpoints.max)]: {
    borderRadius: 24,
    padding: '40px 32px 26px 32px',
  },
}));

const trustBackgrounds = {
  [Trust.High]:
    'radial-gradient(100.58% 100% at 50% 0%, rgba(147, 173, 97, 0.76) 0%, rgba(0, 0, 0, 0.11) 30.21%)',
  [Trust.Medium]:
    'radial-gradient(100.58% 100% at 50% 0%, rgba(229, 188, 79, 0.76) 0%, rgba(0, 0, 0, 0.11) 30.21%)',
  [Trust.Low]:
    'radial-gradient(100.58% 100% at 50% 0%, rgba(255, 140, 75, 0.76) 0%, rgba(0, 0, 0, 0.11) 30.21%)',
  [Trust.Untrusted]:
    'radial-gradient(100.58% 100% at 50% 0%, rgba(252, 87, 87, 0.76) 0%, rgba(0, 0, 0, 0.11) 30.21%)',
  [Trust.NewWallet]:
    'radial-gradient(100.58% 100% at 50% 0%, rgba(0, 101, 252, 0.76) 0%, rgba(0, 0, 0, 0.11) 30.21%)',
};

const TrustBackground = styled('div')<{ trust: Trust }>(({ trust }) => ({
  backdropFilter: 'blur(7px)',
  borderRadius: '1.5rem',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: trustBackgrounds[trust],
  zIndex: 0,
}));

const Content = styled('div')(({ theme }) => ({
  position: 'relative',
  zIndex: theme.zIndex.sm,
  flexShrink: 0,
}));
