import React from 'react';
import styled from 'styled-components';

import i18n from '../../../../../../i18n';

import { TransactionType } from '../../../../../../types';

import { SelectBase } from '../../../../../../components/ui/forms/Select/SelectBase';
import {
  ButtonPrimary,
  ButtonSize,
  ButtonVariant,
} from '../../../../../../components/ui/buttons';

const options = [
  {
    label: i18n.t('common.general'),
    value: TransactionType.Normal,
  },
  {
    label: `ERC20 ${i18n.t('common.tokens')}`,
    value: TransactionType.Token,
  },
  {
    label: `ERC721 ${i18n.t('common.tokens')}`,
    value: TransactionType.ERC721,
  },
  {
    label: `ERC1155 ${i18n.t('common.tokens')}`,
    value: TransactionType.ERC1155,
  },
];

interface Props {
  onChange: (val: TransactionType) => void;
  selectedValue: TransactionType;
}

export const TransactionTypeSelect: React.FC<Props> = ({ onChange, selectedValue }) => {
  return (
    <>
      <Wrapper>
        <SelectBase
          isPrimaryColor
          value={selectedValue}
          options={options}
          showArrows={false}
          onChange={onChange}
        />
      </Wrapper>
      <ButtonsWrapper>
        {options.map(({ label, value }) => (
          <ButtonPrimary
            key={value}
            type='button'
            onClick={() => onChange(value)}
            variant={
              value === selectedValue ? ButtonVariant.Filled : ButtonVariant.Outlined
            }
            size={ButtonSize.Xs}
          >
            {label}
          </ButtonPrimary>
        ))}
      </ButtonsWrapper>
    </>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  flex: 1,
  flexShrink: 0,
  width: '50%',
  maxWidth: 180,
  [theme.min(theme.breakpoints.sm)]: {
    minWidth: 180,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    display: 'none',
  },
}));

const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.min(theme.breakpoints.desktop)]: {
    display: 'flex',
    gap: theme.pxToVw(14),
    marginLeft: theme.pxToVw(20),
    '& button': {
      padding: `0 ${theme.pxToVw(24)}`,
    },
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 14,
    marginLeft: 20,
    '& button': {
      padding: '0 24px',
    },
  },
}));
