import styled from 'styled-components';
import { FollowUserProfileButton } from '../../../../components/common/ActionButtons/FollowUserProfileButton';
import { Heading4 } from '../../../../components/ui/text/Heading';

export const ProfileDataCardBase = styled('div')(({ theme }) => ({
  borderRadius: 31,
  backgroundColor: 'rgba(22, 22, 22, 0.6)',
  padding: '40px',
  width: 'calc(100wv + 40px)',
  marginLeft: -20,
  maxWidth: 1520,
  position: 'relative',
  height: '100%',
  [theme.min(theme.breakpoints.sm)]: {
    width: '90%',
    margin: '0 auto',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    width: '79.16%',
    maxWidth: theme.pxToVw(1520),
    borderRadius: theme.pxToVw(31),
  },
  [theme.min(theme.breakpoints.max)]: {
    maxWidth: 1520,
    borderRadius: 31,
  },
}));

export const ProfileDataPageTitle = styled(Heading4)(({ theme }) => ({
  fontWeight: 700,
}));

export const ProfileDataFollowButton = styled(FollowUserProfileButton)(({ theme }) => ({
  position: 'absolute',
  top: 40,
  right: 20,
  [theme.min(theme.breakpoints.xs)]: {
    right: 40,
  },
  [theme.betweenDM]: {
    right: theme.pxToVw(40),
  },
}));

export const NoItemsText = styled('p')<{ isError?: boolean }>(({ theme, isError }) => ({
  fontSize: 20,
  fontWeight: 700,
  lineHeight: 1.32,
  gridColumn: '1 / -1',
  color: isError ? theme.colors.status.error : '#fff',
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: 24,
  },
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(24),
  },
}));
