import React from 'react';
import styled from 'styled-components';

import { useReputationWithAddress } from '../../../../../providers/AddressProvider/useReputationWithAddress';
import { Spinner } from '../../../../ui/loaders/Spinner';

import { AnalysisCardComponents } from '../card-components';

const { BaseCard, TopContentWrapper, BottomContentWrapper } = AnalysisCardComponents;

interface Props {
  label: string;
  value: string;
  additionalValue?: string;
}

export const AnalysisResultPrimaryCardBase: React.FC<Props> = ({
  label,
  value,
  additionalValue,
}) => {
  const { data } = useReputationWithAddress();

  const renderContent = () => {
    if (!data) return <Loader size={20} />;

    return (
      <>
        <span>{value}</span>
        {additionalValue && <span>{additionalValue}</span>}
      </>
    );
  };

  return (
    <Wrapper>
      <TopContentWrapper>
        <Title>{label}</Title>
      </TopContentWrapper>
      <BottomContent>
        <DetailText>{renderContent()}</DetailText>
      </BottomContent>
    </Wrapper>
  );
};

const Wrapper = styled(BaseCard)(({ theme }) => ({
  flex: 1,
  height: 135,
  [theme.min(theme.breakpoints.sm)]: {
    padding: 24,
  },
  [theme.min(theme.breakpoints.lg)]: {
    height: 'auto',
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    padding: theme.pxToVw(24),
  },
}));

const BottomContent = styled(BottomContentWrapper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flex: 1,
  paddingTop: 12,
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    paddingTop: theme.pxToVw(12),
  },
}));

const Title = styled('h5')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 20,
  textTransform: 'uppercase',
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: 16,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(20),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 20,
  },
}));

const DetailText = styled('p')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  display: 'flex',
  flexDirection: 'column',
  gap: 6,
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: 20,
    '& span:not(:first-child)': {
      fontSize: 18,
    },
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(24),
    '& span:not(:first-child)': {
      fontSize: theme.pxToVw(20),
    },
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 24,
    '& span:not(:first-child)': {
      fontSize: 20,
    },
  },
}));

const Loader = styled(Spinner)({
  margin: '0 auto',
});
