import React from 'react';
import styled from 'styled-components';
import { LoadingBackground } from '../../../../../../components/ui/loaders/LoadingBackground';
import { Text } from '../../../../../../components/ui/text/Text';

interface Props {
  label: string;
  value: string | number;
  isLoading: boolean;
}

export const DetailItem: React.FC<Props> = ({ label, value, isLoading }) => {
  return (
    <Wrapper>
      <TextEl>{label}</TextEl>
      {isLoading ? (
        <LoadingBg>
          <TextEl>L</TextEl>
        </LoadingBg>
      ) : (
        <TextEl>{String(value)?.trim().length ? value : <>&mdash;</>}</TextEl>
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const TextEl = styled(Text.SM)(({ theme }) => ({
  fontWeight: 700,
  textTransform: 'uppercase',
  '&:first-child': {
    opacity: 0.6,
  },
}));

const LoadingBg = styled(LoadingBackground)(({ theme }) => ({
  width: 100,
  [theme.betweenDM]: {
    width: theme.pxToVw(100),
  },
}));
