import React from 'react';
import styled from 'styled-components';

import { useAppMessage } from './AppMessageProvider';

import { AppMessageComponent } from './AppMessageComponent/AppMessageComponent';
import { useWindowHeight } from '../../hooks/useWindowHeight';

const AppMessagesListComponent: React.FC = props => {
  const { appMessages } = useAppMessage();

  return (
    <Wrapper {...props}>
      {appMessages.map((appMessage, i) => (
        <AppMessageComponent key={i} appMessage={appMessage} />
      ))}
    </Wrapper>
  );
};

export const AppMessagesList = React.memo(AppMessagesListComponent);

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  position: 'absolute',
  bottom: `calc(100vh - ${useWindowHeight() - 24}px)`,
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: theme.zIndex.ul,
  [theme.min(theme.breakpoints.sm)]: {
    bottom: 'auto',
    right: '5vw',
    top: 120,
    left: 'auto',
    transform: 'translateX(0)',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    top: theme.pxToVw(120),
    right: `calc(calc(100vw - ${theme.pxToVw(1520)}) / 2)`,
  },
  [theme.min(1920)]: {
    top: 120,
    right: 'calc(calc(100vw - 1520px) / 2)',
  },
}));
