import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import { useProfileUtils } from '../../../hooks/useProfileUtils';
import { useUser } from '../../../../../react-query/user/useUser';

import { Icon } from '../../../../../assets';
import {
  EditProfileModal,
  EditProfileModalOnSavePayload,
} from '../../../../../components/profile/EditProfileDetailsModal/EditProfileDetailsModal';

import { UserApi } from '../../../../../api/UserApi/UserApi';
import { QueryKey } from '../../../../../react-query/query-keys';

export const EditProfileButton: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { isOwner } = useProfileUtils();
  const { data: user } = useUser();
  const queryClient = useQueryClient();

  if (!isOwner) return null;

  const onSave = async (payload: EditProfileModalOnSavePayload) => {
    await UserApi.patchProfileDetails(payload);

    queryClient.invalidateQueries(QueryKey.User());
    queryClient.invalidateQueries(QueryKey.Profile(user!.username!));
    user!.addresses.forEach(add => {
      queryClient.invalidateQueries(QueryKey.Profile(add));
    });
  };

  return (
    <>
      <Wrapper
        onClick={e => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <Icon.EditUnderlined />
      </Wrapper>
      {open && (
        <EditProfileModal
          onClose={() => setOpen(false)}
          onSave={onSave}
          defaultValues={{
            twitterUrl: user?.twitterUrl || null,
            openseaUrl: user?.openseaUrl || null,
            discordUrl: user?.discordUrl || null,
            description: user?.description || null,
          }}
        />
      )}
    </>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  padding: 2,
  cursor: 'pointer',
  opacity: 1,
  transition: 'all .2s',
  position: 'absolute',
  top: 20,
  right: 20,
  '& svg': {
    pointerEvents: 'none',
    height: 16,
    width: 16,
  },
  '&:hover': {
    opacity: 0.85,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    top: theme.pxToVw(48),
    right: theme.pxToVw(32),
    '& svg': {
      height: theme.pxToVw(20),
      width: theme.pxToVw(20),
    },
  },
  [theme.min(theme.breakpoints.max)]: {
    top: 48,
    right: 32,
    '& svg': {
      height: 20,
      width: 20,
    },
  },
}));
