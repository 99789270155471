import axios from 'axios';
// @ts-ignore
import { createCipheriv } from 'browser-crypto';
// @ts-ignore
import randomBytes from 'randombytes';
import { StorageKeys, StorageService } from '../services/StorageService';

import { envs } from './envs';

const axiosInstance = axios.create({
  baseURL: envs.apiBaseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(async config => {
  const tkn = buildTkn();
  const userToken = StorageService.read(StorageKeys.Token);

  if (!config.headers) config.headers = {};
  if (tkn) config.headers.Authorization = tkn;
  if (userToken) config.headers['userauthorization'] = userToken;

  return config;
});

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      localStorage.clear();
      window.location.reload();

      return axiosInstance(originalRequest);
    }

    return Promise.reject(error);
  }
);

const http = axiosInstance;

export { axiosInstance, http };

function buildTkn() {
  // @ts-ignore
  const iv = randomBytes(16);
  // @ts-ignore
  const cipher = createCipheriv(envs.alg, envs.ck, iv);
  let cipherText;
  try {
    cipherText = cipher.update(`${envs.ktm};${new Date().getTime()}`, 'utf8', 'hex');
    cipherText += cipher.final('hex');
    cipherText = iv.toString('hex') + cipherText;
  } catch (e) {
    cipherText = null;
  }
  return `Bearer ${cipherText}}`;
}
