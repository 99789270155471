import styled from 'styled-components';
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDraggableProps,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import MilestoneLabel from '../../../../../components/project/roadmap/MilestoneLabel';
import { useProjectMilestonesByParamId } from '../../../../../react-query/user/project/useProjectMilestones';
import { useRoadmapState } from '../RoadmapStateProvider';
import AddNewMilestone from './AddNewMilestone';
import MilestoneMenu from './MilestoneMenu';
import { StrictModeDroppable } from '../../../../../components/dnd/StrictModeDroppable';
import { useProjectMilestoneReorder } from '../../../../../react-query/user/project/useProjectMilestoneReorder';
import { useProjectByParamId } from '../../../../../react-query/project/useProject';
import { useQueryClient } from 'react-query';
import { QueryKey } from '../../../../../react-query/query-keys';
import { Milestone } from '../../../../../types';

const grid = 2;

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'rgba(255,255,255, 0.1)' : 'transparent',
  padding: `${grid}px 0`,
});

const getItemStyle = (
  isDragging: boolean,
  draggableStyle?: DraggableProvidedDraggableProps['style']
): React.CSSProperties => ({
  userSelect: 'none',
  padding: `${grid * 8}px 8% ${grid * 8}px 10px`,
  margin: `0 0 ${grid}px 0`,
  ...draggableStyle,
});

export default function Milestones() {
  const queryClient = useQueryClient();
  const { data: milestones } = useProjectMilestonesByParamId();
  const { data: project } = useProjectByParamId();
  const { mode, setMilestoneEditing, setMode, milestoneEditing } = useRoadmapState();

  const { mutate: reorder, isLoading } = useProjectMilestoneReorder();

  const isCentered = mode === 'view';

  const onDragEnd: OnDragEndResponder = async ({ draggableId, destination, source }) => {
    if (!destination || !milestones || !project) return;

    const milestoneUid = draggableId;

    const filteredMilestones = structuredClone(milestones).filter(
      (item: Milestone) => item.uid !== milestoneUid
    );

    const { index } = destination;

    const prevOrderString =
      index === 0 ? '' : String(filteredMilestones[index - 1].orderString);
    const nextOrderString =
      index === milestones.length - 1
        ? ''
        : String(filteredMilestones[index].orderString);

    queryClient.setQueryData<Milestone[] | undefined>(
      QueryKey.ProjectMilestones(project.uid),
      data => {
        if (!data) return;
        const movedMilestone = data.splice(source.index, 1)[0];
        data.splice(index, 0, movedMilestone);

        for (let i = 0; i < data.length; i++) {
          data[i].orderNumber = i + 1;
        }

        return data;
      }
    );

    reorder({
      projectUid: project.uid,
      milestoneUid,
      payload: { prevOrderString, nextOrderString },
    });
  };

  return (
    <Wrapper isCentered={isCentered} isView={mode === 'view'}>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId='droppable'>
          {(droppableProvided, droppableSnapshot) => (
            <InnerWrapper
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              style={getListStyle(droppableSnapshot.isDraggingOver)}
            >
              {milestones?.map((milestone, i) => (
                <Draggable
                  key={milestone.uid}
                  draggableId={milestone.uid}
                  index={i}
                  isDragDisabled={isLoading}
                >
                  {(provided, snapshot) => (
                    <MilestoneWrapper
                      onClick={() => {
                        setMode('edit');
                        setMilestoneEditing(milestone);
                      }}
                      mode={mode}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <MilestoneLabel
                        fontSize={{ mobile: 16, desktop: 16 }}
                        circleProps={{
                          text: milestone.orderNumber,
                          isActive: milestoneEditing?.uid === milestone.uid,
                          bgColor:
                            milestone.goals.every(g => g.isFulfilled) &&
                            milestoneEditing?.uid !== milestone.uid
                              ? '#000'
                              : undefined,
                          boxShadow:
                            milestoneEditing?.uid !== milestone.uid &&
                            milestone.goals.every(g => g.isFulfilled),
                          size: {
                            mobile: 38,
                            desktop: mode === 'view' ? 44 : 34,
                          },
                        }}
                        labelProps={{
                          text: milestone.title,
                        }}
                      />
                      <MilestoneMenu milestone={milestone} />
                    </MilestoneWrapper>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </InnerWrapper>
          )}
        </StrictModeDroppable>
      </DragDropContext>
      <AddNewMilestone />
    </Wrapper>
  );
}

const Wrapper = styled('div')<{ isCentered: boolean; isView: boolean }>(
  ({ theme, isCentered, isView }) => ({
    position: isCentered ? 'relative' : 'absolute',
    left: isCentered ? 0 : 0,
    [theme.max(theme.breakpoints.desktop)]: {
      display: isView ? 'block' : 'none',
    },
  })
);

const InnerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 16,
  marginLeft: -4,
  borderRadius: 10,
  width: '90vw',
  maxWidth: 365,
  position: 'relative',
  [theme.betweenDM]: {
    marginBottom: theme.pxToVw(16),
    borderRadius: theme.pxToVw(10),
    maxWidth: theme.pxToVw(365),
  },
}));

const MilestoneWrapper = styled('div')<{ mode: 'view' | 'create' | 'edit' }>(
  ({ theme, mode }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: 4,
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all .3s',
    borderRadius: 10,
    position: 'relative',
    paddingRight: '8%',
    '&:not(:last-child)::after': {
      display: 'block',
      content: '" "',
      width: '1.5px',
      backgroundColor: '#fff',
      position: 'absolute',
      height: 28,
      bottom: 0,
      zIndex: 3,
      left: 29,
      transform: 'translateY(50%)',
      [theme.min(theme.breakpoints.desktop)]: {
        left: mode === 'view' ? '9.8%' : '9%',
      },
      [theme.min(1350)]: {
        left: theme.pxToVw(mode === 'view' ? 36 : 30),
      },
      [theme.min(1600)]: {
        left: theme.pxToVw(mode === 'view' ? 34 : 28),
      },
      [theme.min(theme.breakpoints.max)]: {
        left: mode === 'view' ? 32 : 27,
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(61, 72, 89, 0.2)',
    },
    [theme.min(theme.breakpoints.desktop)]: {
      '& .dots-icon:not(.dots-icon-open)': {
        display: 'none',
      },

      '&:hover': {
        '& .dots-icon': {
          display: 'block',
        },
      },
    },
  })
);
