import React from 'react';
import { ProjectContractAddress } from '../../../components/project/project-form-components/ProjectContractAddress';
import { ProjectOwner } from '../../../components/project/project-form-components/ProjectOwner/ProjectOwner';
import { ProjectTitle } from '../../../components/project/project-form-components/ProjectTitle';

export const NewProjectForm: React.FC = () => {
  return (
    <>
      <ProjectTitle />
      <ProjectContractAddress />
      <ProjectOwner />
    </>
  );
};
