import React from 'react';
import styled, { useTheme } from 'styled-components';

const Wrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  transition: 'all .3s',
  '&:hover': {
    opacity: 0.8,
  },
}));

interface Props {
  error?: boolean;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export const CheckboxItem: React.FC<Props> = ({ checked, error, onChange }) => {
  const theme = useTheme();

  return (
    <Wrapper
      onClick={e => {
        e.stopPropagation();
        onChange(!checked);
      }}
    >
      {checked ? (
        <svg
          width='18'
          height='18'
          viewBox='0 0 18 18'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M16 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.11 18 18 17.1 18 16V2C18 0.9 17.11 0 16 0ZM7 14L2 9L3.41 7.59L7 11.17L14.59 3.58L16 5L7 14Z'
            fill='#0065FC'
          />
        </svg>
      ) : (
        <svg
          width='18'
          height='18'
          viewBox='0 0 18 18'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <mask
            id='mask0_853_8443'
            style={{ maskType: 'alpha' }}
            maskUnits='userSpaceOnUse'
            x='0'
            y='0'
            width='18'
            height='18'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0ZM16 16V2H2V16H16Z'
              fill='white'
            />
          </mask>
          <g mask='url(#mask0_853_8443)'>
            <rect
              x='-3'
              y='-3'
              width='24'
              height='24'
              fill={error ? theme.colors.status.error : theme.colors.primary.main}
            />
          </g>
        </svg>
      )}
    </Wrapper>
  );
};
