import { useQuery } from 'react-query';
import { UserApi } from '../../api/UserApi/UserApi';

import { QueryKey } from '../query-keys';

export const usePopularUsers = () => {
  return useQuery(QueryKey.PopularUsers(), UserApi.getPopularUsers, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
};
