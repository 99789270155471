import styled from 'styled-components';

const Wrapper = styled('div')<{ isLoading: boolean }>(({ theme, isLoading }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: 16,
  position: 'relative',
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    gap: theme.pxToVw(16),
  },
}));

const ShieldWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  content: '" "',
  width: 29,
  height: 38,
  clipPath: 'url(#shieldClip)',
}));

const ShieldBackground = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,
}));

const ColoredShield = styled('div')<{
  translateY: number;
  index: number;
  isNewWallet: boolean;
  trustPercentage: number;
}>(({ theme, translateY, index, isNewWallet, trustPercentage }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  transform: `translateY(${isNewWallet ? '0' : '100%'})`,
  zIndex: 2,
  animation: isNewWallet
    ? 'none'
    : `translateUp-${index}-${trustPercentage} 0.9s ease-in-out`,
  animationFillMode: 'forwards',
  [`@keyframes translateUp-${index}-${trustPercentage}`]: {
    '0%': {
      transform: 'translateY(100%)',
    },
    '100%': {
      transform: `translateY(${translateY}%)`,
    },
  },
}));

const Styled = { Wrapper, ShieldWrapper, ShieldBackground, ColoredShield };

export default Styled;
