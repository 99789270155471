import styled from 'styled-components';

export const HEADER_HEIGHT = 100;

const HEADER_HEIGHT_MOBILE = 80;

const Container = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: `1fr`,
  height: '100vh',
  maxHeight: '100vh',
  overflow: 'hidden',
  color: theme.colors.text.primary,
  position: 'relative',
}));

const HeaderWrapper = styled('header')<{ activeHeader: boolean }>(
  ({ theme, activeHeader }) => ({
    backgroundColor: activeHeader ? 'rgba(0,0,0,0.8)' : 'transparent',
    position: 'fixed',
    top: 0,
    width: '100vw',
    transition: 'all .4s',
    height: activeHeader ? 76 : HEADER_HEIGHT_MOBILE,
    zIndex: theme.zIndex.xl,
    [theme.min(theme.breakpoints.desktop)]: {
      height: theme.pxToVw(activeHeader ? 92 : HEADER_HEIGHT),
    },
  })
);

const ContentWrapper = styled('div')(({ theme }) => ({
  gridColumn: '1 / -1',
  overflowY: 'auto',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr max-content',
  minHeight: `calc(100vh - ${HEADER_HEIGHT_MOBILE}px)`,
  '& > *': {
    flexShrink: 0,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    minHeight: `calc(100vh - ${theme.pxToVw(100)})`,
  },
}));

const Styled = {
  Container,
  HeaderWrapper,
  ContentWrapper,
};

export default Styled;
