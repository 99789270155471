import React, { useState } from 'react';
import styled, { CSSObject } from 'styled-components';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';

import { WhereProvider } from '../../providers/WhereProvider';

import {
  NOTIFICATIONS_OFFSET_PARAM_VALUE,
  useNotificationsWithPagination,
} from '../../react-query/user/notifications/useNotifications';
import { useUser } from '../../react-query/user/useUser';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';

import { NotificationList } from '../../components/common/Notifications/NotificationList';
import { NotificationsDisabledText } from '../../components/common/Notifications/NotificationsDisabledText';
import { NotificationsHeader } from '../../components/common/Notifications/NotificationsHeader';
import {
  NotificationErrMsg,
  TextBase,
} from '../../components/common/Notifications/shared-components';
import { Container } from '../../components/layouts/Container';
import { RealtimeFradulentCard } from '../../components/sections/RealtimeFradulent/RealtimeFradulentCard';
import { Icon } from '../../assets';
import { Theme } from '../../config/theme';

export const NotificationsPage: React.FC = () => {
  const { t } = useTranslation();
  const { data: user } = useUser();

  const [page, setPage] = useState(0);

  const {
    data: notifications,
    totalCount,
    error,
    isLoading,
  } = useNotificationsWithPagination({ page: page + 1 });

  useDocumentTitle(t('notifications.pageTitle'));

  const renderContent = () => {
    if (!user) return null;

    if (!user.notificationsEnabled) return <NotificationsDisabledText />;

    if (error) return <NotificationErrMsg>{t('errors.default')}</NotificationErrMsg>;

    if (totalCount === 0)
      return (
        <>
          <TextBase>{t('notifications.noNotifications')}</TextBase>{' '}
        </>
      );

    return (
      <>
        <NotificationsListWrapper>
          {user?.notificationsEnabled && !!totalCount && (
            <NotificationList notifications={notifications || []} isLoading={isLoading} />
          )}
        </NotificationsListWrapper>
        <PaginationWrapper>
          <ReactPaginate
            breakLabel='...'
            onPageActive={page => alert(JSON.stringify(page))}
            onPageChange={val => {
              setPage(val.selected);
            }}
            pageRangeDisplayed={5}
            pageCount={
              totalCount ? Math.ceil(totalCount / NOTIFICATIONS_OFFSET_PARAM_VALUE) : 0
            }
            renderOnZeroPageCount={() => null}
            previousLabel={
              <Icon.ChevronRightPrimary style={{ transform: 'rotate(180deg)' }} />
            }
            nextLabel={<Icon.ChevronRightPrimary />}
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='page-item-previous'
            previousLinkClassName='page-link'
            nextClassName='page-item-previous'
            nextLinkClassName='page-link'
            breakClassName='page-item-break'
            breakLinkClassName='page-link-break'
            containerClassName='pagination-container'
            activeClassName='page-item-active'
            activeLinkClassName='page-link-active'
          />
        </PaginationWrapper>
      </>
    );
  };

  return (
    <div style={{ paddingTop: '10vh' }}>
      <WhereProvider where='main'>
        {user?.notificationsEnabled && (
          <Wrapper>
            <NotificationsHeader />
          </Wrapper>
        )}
        <Wrapper>{renderContent()}</Wrapper>
        <FradulentWrapper>
          <RealtimeFradulentCard />
        </FradulentWrapper>
      </WhereProvider>
    </div>
  );
};

const Wrapper = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '20px 0',
  '&:nth-child(1)': {},
  '&:nth-child(2)': {},
  '&:nth-child(3)': {
    marginBottom: '15vh',
    marginTop: -60,
  },
}));

const FradulentWrapper = styled('div')(({ theme }) => ({
  marginBottom: '20vh',
  marginTop: -80,
}));

const getItemStyles = (theme: Theme): CSSObject => ({
  background: 'transparent',
  height: 56,
  aspectRatio: '1',
  borderRadius: 6,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  [theme.betweenDM]: {
    height: theme.pxToVw(56),
  },
});

const PaginationWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: '40px auto',
  '& .pagination-container': {
    display: 'flex',
    gap: 16,
    listStyle: 'none',
    [theme.betweenDM]: {
      gap: theme.pxToVw(16),
    },
  },
  '& .page-item': {
    ...getItemStyles(theme),
    border: `1px solid ${theme.colors.primary.main}`,
  },

  '& .page-item-previous': {
    ...getItemStyles(theme),
    '& svg path': {
      transition: 'all .3s',
      fill: 'rgba(255, 255, 255, 0.6)',
    },
    border: `1px solid rgba(255, 255, 255, 0.6)`,
    '&:hover': {
      '& svg path': {
        fill: theme.colors.primary.main,
      },
    },
  },

  '& .page-item-active': {
    backgroundColor: theme.colors.primary.main,
  },

  '& .page-link': {
    color: theme.colors.primary.main,
    fontSize: 16,
    fontWeight: 700,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.betweenDM]: {
      fontSize: theme.pxToVw(16),
    },
  },

  '& .page-link-active': {
    color: '#fff',
  },

  '& .page-item-break': {
    padding: '8px 5px 5px 5px',
    cursor: 'pointer',
    marginLeft: -10,
    marginRight: -10,
    display: 'flex',

    '&:hover .page-link-break': {
      color: theme.colors.primary.main,
    },

    [theme.betweenDM]: {
      marginLeft: theme.pxToVw(-10),
      marginRight: theme.pxToVw(-10),
    },
  },

  '& .page-link-break': {
    transition: 'all .3s',
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 16,
    transform: 'translateY(5px)',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.betweenDM]: {
      fontSize: theme.pxToVw(16),
    },
  },
}));

const NotificationsListWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: '30vh',
  maxHeight: '54vh',
  overflow: 'auto',
}));
