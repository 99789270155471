import React from 'react';
import styled from 'styled-components';

import { useWindowSizeUtils } from '../../../../hooks/useWindowSizeUtils';

import banner_desktop from './banner-desktop.png';
import banner_mobile from './banner-mobile.png';

export const BannerSection: React.FC = () => {
  const { isXs } = useWindowSizeUtils();
  return (
    <Wrapper>
      <Img src={isXs ? banner_mobile : banner_desktop} />
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  width: 'calc(100% + 2px)',
  margin: '32px auto 20px auto',
  [theme.min(theme.breakpoints.xs)]: { width: '90%' },
  [theme.min(theme.breakpoints.desktop)]: {
    width: '79.16%',
    maxWidth: theme.pxToVw(1520),
    marginBottom: theme.pxToVw(36),
    marginTop: theme.pxToVw(64),
  },
  [theme.min(theme.breakpoints.max)]: {
    maxWidth: 1520,
    marginBottom: 36,
    marginTop: 64,
  },
}));

const Img = styled('img')({
  width: '100%',
  height: 'auto',
});
