import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { BlurredPlaceholder, ChartTitle } from '../shared';

import mobile_img from './placeholder-mobile.png';
import desktop_img from './placeholder-desktop.png';

export const SocialNetworksAnalysis: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ChartTitle>{t('projectPage.socialNetworksAnalysis.title')}</ChartTitle>
      <BlurredPlaceholder mobileImg={mobile_img} desktopImg={desktop_img} />
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  [theme.betweenDM]: {
    gap: theme.pxToVw(24),
  },
}));
