import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useWindowWidth } from '../../../hooks/useWindowWidth';

import { Trust } from '../../../types';
import { ClipboardButton } from '../../ui/buttons/ClipboardButton/ClipboardButton';
import { IWhereContext } from '../../../providers/WhereProvider';

import { shortenEthAddress } from '../../../utils/address-utils';
import { Theme } from '../../../config/theme';
import { LoadingBackground } from '../../ui/loaders/LoadingBackground';

const TransactionAddress: React.FC<{
  address: string;
  shortenAtWidth?: number;
  shorten?: boolean;
}> = ({ address, shortenAtWidth = 525, shorten }) => {
  const width = useWindowWidth();

  const addressMemoized = React.useMemo(
    () =>
      (width < shortenAtWidth || shorten) && !address.endsWith('.eth') ? (
        <>{shortenEthAddress(address)}</>
      ) : (
        <>{address} </>
      ),
    [width, address, shortenAtWidth, shorten]
  );

  return <>{addressMemoized}</>;
};

interface Props {
  where: IWhereContext;
  address: string;
  trust?: Trust;
  isContract: boolean;
  shortenAtWidth?: number;
  shorten?: boolean;
  isLoading?: boolean;
}

export const Address: React.FC<Props> = ({
  where,
  address,
  trust,
  isContract,
  shortenAtWidth,
  shorten,
  isLoading,
}) => {
  const { t } = useTranslation();

  if (isLoading)
    return <AddressLoaderWrapper where={where}>Loading</AddressLoaderWrapper>;

  return (
    <AddressWrapper trust={trust} where={where}>
      <span>{t(`common.${isContract ? 'contract' : 'address'}`)}</span>
      <TransactionAddress
        address={address}
        shortenAtWidth={shortenAtWidth}
        shorten={shorten}
      />
      <Clip value={address} />
    </AddressWrapper>
  );
};

const getFontSize = (theme: Theme, where: IWhereContext) => {
  switch (where) {
    case 'list':
      return {
        [theme.min(theme.breakpoints.sm)]: {
          fontSize: 14,
        },
        [theme.min(theme.breakpoints.desktop)]: {
          fontSize: theme.pxToVw(16),
          padding: `${theme.pxToVw(6)} ${theme.pxToVw(12)}`,
        },
        [theme.min(theme.breakpoints.max)]: {
          fontSize: 14,
          padding: '6px 12px',
        },
      };
    case 'main':
      return {
        [theme.min(theme.breakpoints.sm)]: {
          fontSize: 16,
        },
        [theme.min(theme.breakpoints.desktop)]: {
          fontSize: theme.pxToVw(20),
          padding: `${theme.pxToVw(6)} ${theme.pxToVw(12)}`,
        },
        [theme.min(theme.breakpoints.max)]: {
          fontSize: 20,
          padding: '6px 12px',
        },
      };
    case 'project':
      return {
        [theme.min(theme.breakpoints.desktop)]: {
          fontSize: theme.pxToVw(16),
          padding: `${theme.pxToVw(6)} ${theme.pxToVw(12)}`,
        },
        [theme.min(theme.breakpoints.max)]: {
          fontSize: 16,
          padding: '6px 12px',
        },
      };
    default:
      return {};
  }
};

const AddressWrapper = styled('p')<{ trust?: Trust; where: IWhereContext }>(
  ({ theme, trust, where }) => ({
    display: 'flex',
    alignItems: 'center',
    color: trust ? theme.colors.trust[trust] : '#fff',
    fontWeight: 700,
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 6,
    padding: '6px 12px',
    width: 'max-content',
    fontSize: 14,
    position: 'relative',
    '& span:first-child': {
      color: '#fff',
      opacity: 0.5,
      marginRight: 5,
      userSelect: 'none',
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',
      fontWeight: 600,
    },
    ...getFontSize(theme, where),
  })
);

const Clip = styled(ClipboardButton)(({ theme }) => ({
  position: 'absolute',
  right: -4,
  transform: 'translateX(100%)',
}));

const AddressLoaderWrapper = styled(LoadingBackground)<{ where: IWhereContext }>(
  ({ theme, where }) => ({
    display: 'flex',
    borderRadius: 10,
    padding: '6px 12px',
    color: 'transparent',
    width: '100%',
    maxWidth: 540,
    fontSize: 14,
    ...getFontSize(theme, where),
    [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
      maxWidth: theme.pxToVw(540),
    },
  })
);
