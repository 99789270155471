import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useWindowWidth } from '../../../../../hooks/useWindowWidth';

import { splitStringNewLineBreak } from '../../../../../utils/string-utils';

import { FOOTER_MOBILE_BREAKPOINT_WIDTH } from '../../Footer';
import { envs } from '../../../../../config/envs';
import { ButtonPrimary } from '../../../../ui/buttons';

export const FooterGetInTouchSection: React.FC = () => {
  const { t } = useTranslation('common');
  const width = useWindowWidth();

  const isMobile = width <= FOOTER_MOBILE_BREAKPOINT_WIDTH;

  return (
    <Wrapper>
      <Text>{isMobile ? splitStringNewLineBreak(t('ctaCopy')) : t('ctaCopy')}</Text>
      <ButtonPrimary onClick={() => window.open(`mailto:${envs.contactEmail}`, '_blank')}>
        {t('getInTouch')}
      </ButtonPrimary>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: '1.6rem',
  '& button': {
    width: '23.6rem',
    height: '5.6rem',
    fontSize: '1.6rem',
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(16),
    '& button': {
      width: theme.pxToVw(236),
      height: theme.pxToVw(56),
      fontSize: theme.pxToVw(16),
    },
  },
  [theme.max(FOOTER_MOBILE_BREAKPOINT_WIDTH)]: {
    gap: 14,
    alignItems: 'center',
    marginBottom: 32,
    '& button': {
      height: 48,
      width: 211,
      fontSize: 14,
    },
  },
}));

const Text = styled('p')(({ theme }) => ({
  fontSize: '1.6rem',
  fontWeight: 700,
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(16),
  },
  [theme.max(FOOTER_MOBILE_BREAKPOINT_WIDTH)]: {
    fontSize: 16,
    textAlign: 'center',
  },
}));
