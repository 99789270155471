export const supportedWeb3Domains = ['eth', 'bit'];

export const isValidEthAddress = (address?: string) => {
  const [d, domain] = address?.trim().split('.') || [];

  return Boolean(
    (supportedWeb3Domains.includes(domain?.toLowerCase()) && d?.trim()?.length) ||
      (address
        ?.trim()
        .toLowerCase()
        .match(/0x[a-fA-F0-9]{40}/g) &&
        address?.length === 42)
  );
};

export const isEmptyAddress = (address: string) => /^0x0+$/.test(address);

interface Options {
  startChars?: number;
  endChars?: number;
  placeholderCount?: number;
  placeholderChar?: string;
}

export const shortenEthAddress = (
  address: string,
  options: Options = {
    startChars: 5,
    endChars: 4,
    placeholderCount: 3,
    placeholderChar: '.',
  }
) => {
  if (address.split('.').length > 1) return address;

  const {
    startChars = 5,
    endChars = 4,
    placeholderChar = '.',
    placeholderCount = 4,
  } = options;

  const beginning = address.slice(0, startChars + 1);
  const end = address.slice(address.length - endChars, address.length);

  let separator = '';

  let current = placeholderCount;

  while (true) {
    if (current === 0) break;

    separator += placeholderChar;

    current--;
  }

  return `${beginning}${separator}${end}`;
};
