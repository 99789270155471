import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { routes } from '../../../router/routes';

export const AddProjectCard: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Wrapper onClick={() => navigate(routes.project.root())}>
      <Cta>{t('myProjectsPage.addProject')}</Cta>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  aspectRatio: '1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 20,
  border: `6px solid ${theme.colors.primary.main}`,
  backgroundColor: 'rgba(0, 101, 252, 0.2)',
  transition: 'all .3s',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(0, 101, 252, 0.4)',
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    borderRadius: theme.pxToVw(20),
  },
}));

const Cta = styled('span')(({ theme }) => ({
  fontSize: 14,
  textDecoration: 'underline',
  cursor: 'pointer',
  fontWeight: 700,
  [theme.min(theme.breakpoints.md)]: {
    fontSize: 16,
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    fontSize: theme.pxToVw(16),
  },
}));
