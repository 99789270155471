import { useConnectWallet } from '@web3-onboard/react';
import { useState, useCallback } from 'react';
import { WalletData } from '../components/common/ConnectWalletButton/ConnectWalletButton';
import { SignMessage } from '../constants/sign-messages';
import { Web3Service } from '../services/Web3Service';

export const useWalletUtils = () => {
  const [wallets, setWallets] = useState<WalletData[]>([]);
  const [{ wallet }, connect] = useConnectWallet();

  const addWallet = useCallback((wallet: WalletData) => {
    setWallets(prev => {
      const newWallets = prev.filter(_wallet => _wallet.address !== wallet.address);
      return [...newWallets, wallet];
    });
  }, []);

  const removeWallet = useCallback((address: WalletData['address']) => {
    setWallets(prev => prev.filter(w => w.address !== address));
  }, []);

  const getCurrentWallet = useCallback(async () => {
    if (wallet) return wallet;
    const connectedWallet = await connect();
    const theWallet = connectedWallet[0];
    return theWallet;
  }, [connect, wallet]);

  const signMessage = useCallback(
    async (message: SignMessage) => {
      let theWallet = null;
      theWallet = await getCurrentWallet();
      const address = theWallet.accounts[0]?.address;
      if (!address) throw new Error('No address');
      const signature = await Web3Service.signMessage(theWallet, message);
      return { signature, address };
    },
    [getCurrentWallet]
  );

  const isCurrentActiveWallet = useCallback(
    async (address: string) => {
      return wallet && address && address === wallet.accounts[0].address;
    },
    [wallet]
  );

  return {
    addWallet,
    removeWallet,
    setWallets,
    signMessage,
    isCurrentActiveWallet,
    getCurrentWallet,
    wallets,
  };
};
