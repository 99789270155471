import React, { useEffect, useRef, useState } from 'react';

import { Icon } from '../../../../assets';

import Styled from './InfoPopup.styles';

interface Props {
  text: string;
  icon?: React.ReactNode;
  width?: number | string;
  primary?: boolean;
  isOpen?: boolean;
  menuOffsetLeft?: string | number;
  menuOffsetTop?: string | number;
}

export const InfoPopup: React.FC<Props> = ({
  text,
  icon,
  width,
  menuOffsetLeft,
  primary,
  isOpen = false,
  menuOffsetTop = 'bottom',
  ...otherProps
}) => {
  const [open, setOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Styled.OuterWrapper {...otherProps}>
      <Styled.Container>
        <Styled.IconWrapper
          open={open}
          onMouseEnter={e => {
            e.stopPropagation();
            if (open) return;
            setOpen(true);
          }}
          onMouseLeave={e => {
            e.stopPropagation();
            if (!open) return;
            setOpen(false);
          }}
          onClick={e => {
            e.stopPropagation();
            setOpen(prev => !prev);
          }}
        >
          {icon || <Icon.Info />}
        </Styled.IconWrapper>
        {open && (
          <Styled.Wrapper
            className='info-popup_content'
            width={width}
            primary={primary}
            ref={contentRef}
            menuOffsetLeft={menuOffsetLeft}
            menuOffsetTop={menuOffsetTop}
          >
            {text}
          </Styled.Wrapper>
        )}
      </Styled.Container>
    </Styled.OuterWrapper>
  );
};
