import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../router/routes';

export const useNavigationItems = () => {
  const { t } = useTranslation();
  // const [open, setOpen] = useState('');

  // const onClick = useCallback((route = '') => {
  //   setOpen(prev => {
  //     if (route && route === prev) return '';
  //     return route;
  //   });
  // }, []);

  const navItems = useMemo(
    () => [
      {
        label: t('common.home'),
        url: routes.home(),
        children: null,
        onClick: () => {},
      },
      {
        label: t('generateWeb3ProfilePage.pageTitle'),
        url: routes.generateWeb3Profile.root(),
        children: null,
        onClick: () => {},
      },
      {
        label: t('contactPage.pageTitle'),
        // onClick: () => onClick(t('contactPage.pageTitle')),
        // isOpen: t('contactPage.pageTitle') === open,
        url: routes.contact(),
        children: null,
        onClick: () => {},
      },
    ],
    [t]
  );

  return {
    navItems,
  };
};
