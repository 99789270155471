import styled, { CSSObject } from 'styled-components';
import { ButtonVariant } from '.';

import { Theme } from '../../../config/theme';
import { ButtonBase } from './ButtonBase';

const getButtonStyles = (
  theme: Theme,
  variant: ButtonVariant,
  disabled?: boolean
): CSSObject => ({
  position: 'relative',
  backgroundColor: variant === ButtonVariant.Outlined ? 'transparent' : '#fff',
  color: theme.colors.primary.main,
  border:
    variant === ButtonVariant.Filled ? 'none' : `2px solid ${theme.colors.primary.main}`,
  '&:hover': {
    background:
      variant === ButtonVariant.Filled
        ? 'rgba(230, 230, 230, 1)'
        : 'rgba(230, 230, 230, 0.1)',
    border:
      variant === ButtonVariant.Filled
        ? 'none'
        : `2px solid ${theme.colors.primary.main}`,
  },
});

const ButtonWhite = styled(ButtonBase)<{ variant?: ButtonVariant }>(
  ({ theme, disabled, variant = ButtonVariant.Filled }) => ({
    ...getButtonStyles(theme, variant, disabled),
  })
);

export { ButtonWhite };
