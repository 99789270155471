import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { useUserProjects } from '../../react-query/user/useUserProjects';

import { CenteredLayoutWithTitle } from '../../components/layouts/CenteredLayoutWithTitle/CenteredLayoutWithTitle';
import { ProjectCard } from '../../components/project/ProjectCard/ProjectCard';
import { AddProjectCard } from '../../components/project/ProjectCard/AddProjectCard';
import { ProjectCardsGrid } from '../../components/project/ProjectCard/ProjectCardsGrid';

export const MyProjectsPage: React.FC = () => {
  const { t } = useTranslation();

  const { data: projects, isLoading } = useUserProjects();

  useDocumentTitle(t('myProjectsPage.pageTitle'));

  return (
    <CenteredLayoutWithTitle title={t('myProjectsPage.title')} maxWidth={1520}>
      <Grid isLoading={isLoading}>
        <AddProjectCard />
        {projects &&
          projects.map(project => <ProjectCard key={project.uid} project={project} />)}
      </Grid>
    </CenteredLayoutWithTitle>
  );
};

const Grid = styled(ProjectCardsGrid)(({ theme }) => ({
  paddingTop: 24,
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    paddingTop: theme.pxToVw(24),
  },
}));
