import React from 'react';
import styled from 'styled-components';

import { useWindowWidth } from '../../../hooks/useWindowWidth';

import { MobileFooter } from './MobileFooter/MobileFooter';
import { DesktopFooter } from './DesktopFooter/DesktopFooter';

import { Container } from '../Container';

export const FOOTER_MOBILE_BREAKPOINT_WIDTH = 960;

export const Footer: React.FC = () => {
  const width = useWindowWidth();

  return (
    <ContainerEl>
      {width <= FOOTER_MOBILE_BREAKPOINT_WIDTH ? <MobileFooter /> : <DesktopFooter />}
    </ContainerEl>
  );
};

const ContainerEl = styled(Container)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  padding: '5rem 0 10rem 0',
  flexGrow: 0,
  position: 'relative',
  [theme.max(FOOTER_MOBILE_BREAKPOINT_WIDTH)]: {
    flexDirection: 'column',
    alignItems: 'center',
    borderTop: 'none',
  },
  [theme.betweenDM]: {
    padding: `${theme.pxToVw(64)} 0 ${theme.pxToVw(100)} 0`,
  },
  [theme.max(theme.breakpoints.xs)]: {
    '@supports (-webkit-touch-callout: none)': {
      paddingBottom: 200,
    },
  },
}));
