import React, { useCallback, useContext, useState } from 'react';
import { NotificationsCount } from '../api/UserApi/NotificationApi';

export type INotificationsContext = {
  currentCounts: NotificationsCount | undefined;
  setCurrentCounts: (nextCount: NotificationsCount) => void;
};

const NotificationsContext = React.createContext<INotificationsContext | undefined>(
  undefined
);

export const usePreviousNotificationsCount = (): INotificationsContext => {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error(
      'usePreviousNotificationsCount cannot be used without NotificationsContext'
    );
  }

  return context;
};

interface Props {
  children: React.ReactNode;
}

export const NotificationsProvider: React.FC<Props> = ({ children }) => {
  const [currentCounts, _setCurrentCounts] = useState<NotificationsCount>();

  const setCurrentCounts = useCallback((nextCounts: NotificationsCount) => {
    _setCurrentCounts(nextCounts);
  }, []);

  return (
    <NotificationsContext.Provider value={{ currentCounts, setCurrentCounts }}>
      {children}
    </NotificationsContext.Provider>
  );
};
