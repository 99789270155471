import styled from 'styled-components';
import { Container as ContainerEl } from '../../../../components/layouts/Container';
import { MainHeading } from '../../../../components/ui/text/Heading';
import { Text } from '../../../../components/ui/text/Text';

const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: '220px 0 64px 0',
  [theme.min(theme.breakpoints.desktop)]: {
    padding: `${theme.pxToVw(333)} 0 ${theme.pxToVw(90)} 0`,
  },
  [theme.min(theme.breakpoints.max)]: {
    padding: '333px 0 90px 0',
  },
}));

const Container = styled(ContainerEl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

const Heading = styled(MainHeading)(({ theme }) => ({
  position: 'relative',
  zIndex: theme.zIndex.sm,
  lineHeight: 1.2,
  marginBottom: 8,
  [theme.min(theme.breakpoints.desktop)]: {
    marginBottom: theme.pxToVw(18),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginBottom: 18,
  },
}));

const InputWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  marginBottom: 20,
  [theme.min(theme.breakpoints.desktop)]: {
    marginBottom: theme.pxToVw(20),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginBottom: 20,
  },
}));

const SearchInfo = styled(Text.MD)(({ theme }) => ({
  zIndex: theme.zIndex.sm,
  marginBottom: 24,
  [theme.min(theme.breakpoints.desktop)]: {
    marginBottom: theme.pxToVw(50),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginBottom: 50,
  },
}));

const Styled = {
  Wrapper,
  Container,
  Heading,
  SearchInfo,
  InputWrapper,
};

export default Styled;
