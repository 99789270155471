import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { useProjectUtils } from '../../../hooks/useProjectUtils';
import { useProjectByParamId } from '../../../../../../react-query/project/useProject';
import { useFollowProject } from '../../../../../../hooks/useFollowProject';

import { Spinner } from '../../../../../../components/ui/loaders/Spinner';
import { Icon } from '../../../../../../assets';
import {
  ButtonPrimary,
  ButtonSize,
  ButtonVariant,
} from '../../../../../../components/ui/buttons';

export const FollowProjectButton: React.FC = ({ ...otherProps }) => {
  const { t } = useTranslation('common');
  const { isOwner, isFollowedProject } = useProjectUtils();

  const { data: project } = useProjectByParamId();

  const isFollowed = !!project && isFollowedProject(project.contractAddress);

  const { call, isLoading } = useFollowProject(project);

  if (isOwner) return null;

  const icon = isLoading ? <Spinner size={12} /> : <Icon.Plus />;

  return (
    <Button
      onClick={call}
      disabled={isLoading}
      variant={isFollowed ? ButtonVariant.Outlined : ButtonVariant.Filled}
      size={ButtonSize.Sm}
      isLoading={!!isFollowed && !!isLoading}
      {...otherProps}
    >
      {isFollowed ? (
        <>{[t('unfollowProject')]}</>
      ) : (
        <>
          {icon}
          {[t('followProject')]}
        </>
      )}
    </Button>
  );
};

const Button = styled(ButtonPrimary)(({ theme }) => ({
  fontSize: 14,
  width: 174,
  '& svg': {
    marginRight: 8,
  },
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(14),
    width: theme.pxToVw(174),
    '& svg': {
      marginRight: theme.pxToVw(8),
    },
  },
}));
