import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useBlockemExtension } from '../../../../providers/BlockemExtensionProvider';

import { InstallExtensionButton } from '../../../../components/common/ActionButtons/InstallExtensionButton';
import { BaseModal } from '../../../../components/ui/modals/BaseModal/BaseModal';
import { ActionText } from '../../../../components/ui/text/ActionText';
import { ReactComponent as Image } from './Image.svg';

import { envs } from '../../../../config/envs';
import { AnalyticsEvent } from '../../../../services/AnalyticsService';
import { Text } from '../../../../components/ui/text/Text';

export const NewUserModal: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { newUser?: boolean };

  const { isInstalled } = useBlockemExtension();

  if (!state?.newUser || isInstalled) return null;

  const onClose = () => navigate(location.pathname, { state: null });

  return (
    <BaseModal
      isOpen
      onClose={onClose}
      containerProps={{
        style: {
          background: 'linear-gradient(180deg, #3D4859 0%, #14161A 96.87%)',
          boxShadow: 'none',
        },
      }}
    >
      <Wrapper>
        <Logo />
        <Title>{t('profilePage.newUserModal.title')}</Title>
        <Subtitle>{t('profilePage.newUserModal.subtitle')}</Subtitle>
        <InstallExtensionButton
          width={219}
          showArrow={false}
          onClick={onClose}
          analyticsEvent={AnalyticsEvent.InstallExtensionNewUserModalClicked}
        />
        <LearnMore
          onClick={() => {
            window.open(envs.blockemWebsiteUrl);
            onClose();
          }}
        >
          {t('common.learnMore')}
        </LearnMore>
      </Wrapper>
    </BaseModal>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 12,
  marginTop: -40,
  [theme.min(theme.breakpoints.lg)]: {
    gap: 14,
    padding: '0 20px 40px 20px',
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(14),
    padding: `0 ${theme.pxToVw(20)} ${theme.pxToVw(40)} ${theme.pxToVw(20)}`,
  },
}));

const Logo = styled(Image)(({ theme }) => ({
  height: 88,
  width: 88,
  marginBottom: 8,
  [theme.min(theme.breakpoints.lg)]: {
    marginBottom: 14,
    height: 124,
    width: 124,
  },
  [theme.betweenDM]: {
    marginBottom: theme.pxToVw(14),
    height: theme.pxToVw(124),
    width: theme.pxToVw(124),
  },
}));

const Title = styled('h3')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 900,
  [theme.min(theme.breakpoints.md)]: {
    fontSize: 24,
    fontWeight: 700,
  },
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(32),
  },
}));

const Subtitle = styled(Text.MD)(({ theme }) => ({
  fontWeight: 700,
  [theme.min(theme.breakpoints.lg)]: {
    marginBottom: 6,
  },
  [theme.betweenDM]: {
    marginBottom: theme.pxToVw(6),
  },
}));

const LearnMore = styled(ActionText)(({ theme }) => ({
  fontSize: 14,
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(16),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 16,
  },
}));
