import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';

import { Icon as IconComponent } from '../../../../assets';
import { Spinner } from '../../loaders/Spinner';
import { ErrorMessage } from '../FormFieldWrapper/ErrorMessage';

type Value = string | number | boolean | null | undefined;

interface Option {
  label: string | React.ReactNode;
  value: Value;
  onClick?: (val: Value) => void;
}

interface Props {
  value: Value;
  placeholder?: string;
  defaultValue?: Value;
  options: Option[];
  isLoading?: boolean;
  Icon?: React.ReactNode;
  error?: boolean | string | null;

  onChange?: (value: Value) => void;
  clearError?: () => void;
}

export const classNames = {
  topContent: 'select-expandible_top-content',
  chevronWrapper: 'select-expandible_chevron-wrapper',
  label: 'select-expandible_label',
  menu: 'select-expandible_menu',
  menuItem: 'select-expandible_menu-item',
};

export const SelectExpandible: React.FC<Props> = ({
  placeholder,
  defaultValue,
  options,
  Icon = null,
  isLoading,
  error,
  value,
  clearError,
  onChange,
  ...otherProps
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);

  const visibleOptions = useMemo(
    () => options.filter(opt => opt.value !== value),
    [options, value]
  );

  const selectedOption = useMemo(
    () =>
      ['string', 'boolean', 'number'].includes(typeof value)
        ? options.find(opt => opt.value === value)
        : null,
    [options, value]
  );

  useOutsideClick([ref], () => {
    setOpen(false);
    if (error && typeof clearError === 'function') clearError();
  });

  const onOpen = () => {
    if (!visibleOptions.length) return setOpen(false);
    setOpen(prev => !prev);
    if (open && error && typeof clearError === 'function') clearError();
  };

  return (
    <>
      <Wrapper
        ref={ref}
        open={open}
        hasSelectedVal={!!selectedOption}
        error={!!error}
        {...otherProps}
      >
        <TopContent onClick={onOpen} className={classNames.topContent}>
          {Icon}
          <span className={classNames.label}>
            {selectedOption ? selectedOption.label : placeholder}
          </span>
          {!!visibleOptions.length && !isLoading && (
            <ChevronWrapper open={open} className={classNames.chevronWrapper}>
              <IconComponent.ChevronDownWhiteFilled />
            </ChevronWrapper>
          )}
          {isLoading && <Spinner size={10} style={{ marginLeft: 'auto' }} />}
        </TopContent>
        {open && !!visibleOptions.length && (
          <Menu className={classNames.menu}>
            {visibleOptions.map(opt => (
              <MenuItem
                className={classNames.menuItem}
                key={`${opt.value}`}
                onClick={() => {
                  if (typeof onChange === 'function') onChange(opt.value);
                  if (typeof opt.onClick === 'function') opt.onClick(opt.value);
                  setOpen(false);
                }}
              >
                {opt.label}
              </MenuItem>
            ))}
          </Menu>
        )}
      </Wrapper>
      {!!error && typeof error === 'string' && <ErrorMessage error={error} />}
    </>
  );
};

const Wrapper = styled('div')<{ open: boolean; hasSelectedVal: boolean; error: boolean }>(
  ({ theme, open, error }) => ({
    backgroundColor: open ? '#001432' : 'rgba(8, 10, 19, 0.6)',
    boxShadow: `0px 0px 3px 2px ${
      !error ? 'rgba(53, 133, 254, 0.39)' : 'rgba(252, 87, 87, 0.25)'
    }`,
    border: `2px solid ${open && !error ? theme.colors.primary.main : 'transparent'}`,
    transition: 'all .3s',
    fontSize: 14,
    padding: '0 18px',
    borderRadius: 10,
    '&:hover': {
      backgroundColor: 'rgba(0, 101, 252, 0.1)',
    },
  })
);

const ChevronWrapper = styled('span')<{ open: boolean }>(({ theme, open }) => ({
  cursor: 'pointer',
  padding: '6px 0 6px 6px',
  opacity: 1,
  transition: 'all .3s',
  transform: `rotate(${open ? '-180' : '0'}deg)`,
  marginLeft: 'auto',
  '&:hover': {
    opacity: 0.85,
  },
  '& svg': {
    pointerEvents: 'none',
  },
}));

const TopContent = styled('div')(({ theme }) => ({
  height: 56,
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  gap: 12,
  cursor: 'pointer',
}));

const Menu = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px 0 20px 0',
}));

const MenuItem = styled('p')(({ theme }) => ({
  fontWeight: 700,
  cursor: 'pointer',
  transition: 'all .3s',
  color: '#fff',
  '&:hover': {
    color: theme.colors.primary.main,
  },
  '&:not(:last-child)': {
    marginBottom: 18,
  },
}));
