import React from 'react';
import { useTranslation } from 'react-i18next';

import { useReputationWithAddress } from '../../../../../providers/AddressProvider/useReputationWithAddress';

import { Erc20TokensSvg } from '../../../../../assets/svgs/reputation-svgs';
import { AnalysisResultSecondaryCardBase } from './AnalysisResultSecondaryCardBase';

export const ErcTokensCard: React.FC = () => {
  const { t } = useTranslation('analysisResult');
  const { data } = useReputationWithAddress();

  const { erc20Count } = data || {};

  const numTokens =
    typeof erc20Count === 'number' && erc20Count > -1
      ? `${erc20Count.toLocaleString()}`
      : '';

  return (
    <AnalysisResultSecondaryCardBase
      label={t('erc20Tokens.title')}
      value={numTokens}
      icon={<Erc20TokensSvg />}
      valueSuffix
    />
  );
};
