import React from 'react';
import styled from 'styled-components';

const ChevronDown: React.FC = props => {
  return (
    <svg
      width='11'
      height='6'
      viewBox='0 0 11 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5.00204 6C4.73739 5.99994 4.4836 5.89476 4.29649 5.7076L0.304664 1.71577C0.209349 1.62372 0.133323 1.5136 0.0810209 1.39184C0.028719 1.27009 0.00118914 1.13914 3.76799e-05 1.00663C-0.00111378 0.87412 0.024136 0.742709 0.0743141 0.620064C0.124492 0.497419 0.198594 0.385995 0.292294 0.292294C0.385995 0.198594 0.497419 0.124492 0.620064 0.0743141C0.742709 0.024136 0.87412 -0.00111378 1.00663 3.76799e-05C1.13914 0.00118914 1.27009 0.028719 1.39184 0.0810209C1.5136 0.133323 1.62372 0.209349 1.71577 0.304664L5.00204 3.59093L8.28831 0.304664C8.38037 0.209349 8.49049 0.133323 8.61225 0.0810209C8.734 0.028719 8.86495 0.00118914 8.99746 3.76799e-05C9.12997 -0.00111378 9.26138 0.024136 9.38402 0.0743141C9.50667 0.124492 9.61809 0.198594 9.71179 0.292294C9.80549 0.385995 9.8796 0.497419 9.92977 0.620064C9.97995 0.742709 10.0052 0.87412 10.0041 1.00663C10.0029 1.13914 9.97537 1.27009 9.92307 1.39184C9.87076 1.5136 9.79474 1.62372 9.69942 1.71577L5.7076 5.7076C5.52049 5.89476 5.2667 5.99994 5.00204 6Z'
        fill='white'
      />
    </svg>
  );
};

export const ChevronDownIcon = styled(ChevronDown)<{ $isopen?: boolean }>(
  ({ theme, $isopen }) => ({
    marginLeft: 10,
    transition: 'all .3s',
    transform: `rotate(${$isopen ? '180' : '0'}deg)`,
    [theme.min(theme.breakpoints.desktop)]: {
      marginLeft: theme.pxToVw(10),
    },
    [theme.min(theme.breakpoints.max)]: {
      marginLeft: 10,
    },
  })
);
