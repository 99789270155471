import React from 'react';
import styled from 'styled-components';

import { TransactionType } from '../../../../../../types';

import { useAddress } from '../../../../../../providers/AddressProvider/AddressProvider';
import { useReputation } from '../../../../../../react-query/reputation/useReputation';
import { useTransactions } from '../../../../../../react-query/transactions/useTransactions';

import { Address } from '../../../../../../components/common/Address/Address';
import { Pagination } from '../../../../../../components/common/Pagination/Pagination';
import { TransactionsList } from './TransactionsList/TransactionsList';

interface Props {
  search: string;
  sort: 'asc' | 'desc';
  txType: TransactionType;
}

export const ProfileDataHistoryCard: React.FC<Props> = props => {
  const { address } = useAddress();
  const { data: reputation } = useReputation(address);

  const {
    formattedData: transactions,
    isFetchingNextPage,
    totalCount,
    hasNextPage,
    fetchNextPage,
  } = useTransactions({
    params: props,
  });

  if (!reputation || !address) return null;

  return (
    <Wrapper>
      <Address
        address={address!}
        where='list'
        isContract={!!reputation?.isContract}
        shortenAtWidth={640}
      />
      <TransactionsList items={transactions} isFetchingNextPage={isFetchingNextPage} />
      {!!transactions?.length && typeof totalCount !== 'undefined' && (
        <Pagination
          hideCounters
          currentItemsCount={transactions?.length}
          totalCount={totalCount || 0}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={!!hasNextPage}
          onFetchNextPage={fetchNextPage}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}));
