import { axiosInstance } from '../config/axios';
import { NFT, PaginatedResponse } from '../types';
import { apiRoutes, IRequestParams } from './routes';
import { NFTResponseApi } from './types';

interface NFTSResponseApi {
  num_of_results: number;
  nfts: NFTResponseApi[];
  page_key?: string;
}

export type NFTSResponse = PaginatedResponse<NFT>;

class NftsApiConfig {
  private readonly apiMapper = {
    nfts(payload: NFTSResponseApi): NFTSResponse {
      const { num_of_results, nfts, page_key } = payload;
      return {
        totalCount: num_of_results,
        nextPage: page_key,
        results: nfts.map(
          ({
            contract_address,
            token_id,
            photo_url,
            is_profile,
            user_uid,
            user_address,
            price_currency,
            additional_urls,
            ...rest
          }) => ({
            contractAddress: contract_address,
            tokenId: token_id,
            photoUrls: [photo_url],
            // photoUrls: !additional_urls
            //   ? [photo_url]
            //   : [photo_url, ...additional_urls.filter(url => url !== photo_url)],
            isProfile: is_profile,
            userUid: user_uid,
            userAddress: user_address,
            priceCurrency: price_currency,
            ...rest,
          })
        ),
      };
    },
  };

  getByAddress = async (
    address: string,
    params: IRequestParams
  ): Promise<NFTSResponse> => {
    const { data } = await axiosInstance.get<NFTSResponseApi>(
      apiRoutes.nfts.byAddress(address, params)
    );
    return this.apiMapper.nfts(data);
  };
}

export const NftsApi = new NftsApiConfig();
