import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { NotificationApi } from '../../../../api/UserApi/NotificationApi';
import { QueryKey } from '../../../../react-query/query-keys';
import { User } from '../../../../types';

import { useUser } from '../../../../react-query/user/useUser';
import { useRequest } from '../../../../hooks/useRequest';

import { SettingItem } from './SettingItem';

export const AllowNotifications: React.FC = props => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { data: user } = useUser();

  const onClick = async (val: boolean) => {
    await NotificationApi.putEnabled(val);

    queryClient.setQueryData<User | undefined>(QueryKey.User(), data => {
      if (data) {
        return { ...data, notificationsEnabled: val };
      }
    });
  };

  const { call, isLoading } = useRequest(onClick);

  return (
    <SettingItem
      label={t(`notifications.allowNotifications`)}
      on={!!user?.notificationsEnabled}
      onChange={call}
      isLoading={isLoading}
      {...props}
    />
  );
};
