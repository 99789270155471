import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  ActionText,
  ActionTextVariant,
} from '../../../../../components/ui/text/ActionText';

import { routes } from '../../../../../router/routes';
import { shortenEthAddress } from '../../../../../utils/address-utils';

interface Props {
  address: string;
}

export const AddressReportedText: React.FC<Props> = ({ address }) => {
  const { t } = useTranslation('homePage');
  const navigate = useNavigate();

  return (
    <Text>
      {t('addressReported1')}
      <ActionText
        onClick={() => navigate(routes.profile.byId(address))}
        variant={ActionTextVariant.Inverted}
      >
        {shortenEthAddress(address)}
      </ActionText>
      {t('addressReported2')}
    </Text>
  );
};

const Text = styled('p')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: 16,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(24),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 24,
  },
}));
