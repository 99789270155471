import React from 'react';
import { useTranslation } from 'react-i18next';

import { splitStringNewLineBreak } from '../../../../utils/string-utils';

import { DigitalOwnershipBenefit } from '../../../../components/common/DigitalOwnershipBenefit/DigitalOwnershipBenefit';
import { RealtimeFradulentCard } from '../../../../components/sections/RealtimeFradulent/RealtimeFradulentCard';

import Styled from './DigitalOwnership.styles';

export const DigitalOwnershipSection: React.FC = () => {
  const { t } = useTranslation();

  const digitalOwnershipBenefits = t('homePage.digitalOwnershipBenefits', {
    returnObjects: true,
  }) as { title: string; text: string }[];

  return (
    <>
      <Styled.Wrapper id='ownership-section'>
        <Styled.Container>
          <Styled.OwnershipWrapper>
            <Styled.Heading>
              {splitStringNewLineBreak(t('homePage.digitalOwnershipHeading'))}
            </Styled.Heading>
            <Styled.Text>{t('homePage.digitalOwnershipText')}</Styled.Text>
          </Styled.OwnershipWrapper>
          <Styled.DigitalOwnershipsWrapper>
            {digitalOwnershipBenefits.map(({ text, title }, i) => (
              <DigitalOwnershipBenefit key={i} text={text} title={title} index={i} />
            ))}
          </Styled.DigitalOwnershipsWrapper>
          <Styled.IllustrationWrapper>
            <Styled.Illustration />
          </Styled.IllustrationWrapper>
        </Styled.Container>
        <RealtimeFradulentCard />
      </Styled.Wrapper>
      <Styled.BlackDiv />
    </>
  );
};
