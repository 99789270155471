import React from 'react';

import { useProfileTotalAssets } from '../../../../../react-query/profile/useProfileTotalAssets';
import { getDisplayValue } from '../../../../../utils/data-utils';

import { AssetDetailCardBase } from './AssetDetailCardBase';

export const AssetDetailNfts: React.FC = () => {
  const { data: totalAssets } = useProfileTotalAssets();

  return (
    <AssetDetailCardBase
      label={'NFTS'}
      value={
        totalAssets ? getDisplayValue(totalAssets.nftBalanceUsd, { prefix: '$' }) : ''
      }
      additionalValue={
        totalAssets ? getDisplayValue(totalAssets.nftBalanceEth, { suffix: 'ETH' }) : ''
      }
      badge={`${getDisplayValue(totalAssets?.nftCount)}` ?? ''}
    />
  );
};
