import { useQuery } from 'react-query';
import { ProfileApi } from '../../api/ProfileApi';
import { QueryKey } from '../query-keys';
import { useProfileByParamId } from './useProfile';

export const useFollowersCount = (uidOrAddress: string | null | undefined) => {
  return useQuery(
    QueryKey.ProfileFollowersCount(uidOrAddress!),
    () => ProfileApi.getFollowersCount(uidOrAddress!),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: !!uidOrAddress,
    }
  );
};

export const useProfileFollowersCount = () => {
  const { data } = useProfileByParamId();
  return useFollowersCount(data?.uid || data?.addresses?.[0]);
};
