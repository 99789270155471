class DB {
  save(key: string, value: any) {
    return localStorage.setItem(key, JSON.stringify(value));
  }

  read<T = any>(key: string): T | null {
    const serializedValue = localStorage.getItem(key);
    return serializedValue === null ? null : JSON.parse(serializedValue);
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  has(key: string) {
    return localStorage.getItem(key) !== null;
  }
}

export const StorageService = new DB();

export enum StorageKeys {
  Token = 'BlockemAuthToken',
  ProjectRedirect = 'ProjectRedirect',
  MuteNotifications = 'MuteNotifications',
}
