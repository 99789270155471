import styled from 'styled-components';
import { Container as ContainerEl } from '../../../../components/layouts/Container';
import { Heading2 } from '../../../../components/ui/text/Heading';
import { Text as TextEl } from '../../../../components/ui/text/Text';
import { ReactComponent as Illustration } from './Illustration.svg';

const Wrapper = styled('div')(({ theme }) => ({
  background:
    'radial-gradient(114.94% 114.94% at 50% 114.94%, #14171C 54.69%, #0B0E11 100%)',
  position: 'relative',
  overflow: 'visible',
  flex: 1,
  padding: '64px 0',
  [theme.min(theme.breakpoints.desktop)]: {
    padding: `${theme.pxToVw(120)} 0 ${theme.pxToVw(64)} 0`,
  },
  [theme.min(theme.breakpoints.max)]: {
    padding: '120px 0 64px 0',
  },
}));

const OwnershipWrapper = styled(ContainerEl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 32,
  [theme.betweenDM]: {
    marginBottom: theme.pxToVw(72),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginBottom: 72,
  },
}));

const Container = styled(ContainerEl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}));

const Text = styled(TextEl.MD)(({ theme }) => ({
  marginBottom: 32,
  fontWeight: 600,
  position: 'relative',
  zIndex: 2,
  lineHeight: 1.6,
  [theme.min(theme.breakpoints.desktop)]: {
    marginBottom: theme.pxToVw(54),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginBottom: 54,
  },
}));

const RealTimeContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '80px 20px',
  borderRadius: 20,
  zIndex: theme.zIndex.md,
  [theme.min(theme.breakpoints.md)]: {
    background: '#0B0E11',
    padding: '60px',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    justifyContent: 'center',
    gap: theme.pxToVw(74),
    padding: `${theme.pxToVw(90)} ${theme.pxToVw(128)} `,
  },
  [theme.min(theme.breakpoints.max)]: {
    padding: '90px 128px',
    gap: 74,
  },
}));

const ActionButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 10,
  width: '100%',
  maxWidth: 295,
  '& button': {
    width: '100%',
    maxWidth: 295,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    flexDirection: 'row',
    maxWidth: theme.pxToVw(568),
    '& button': {
      maxWidth: theme.pxToVw(274),
    },
  },
  [theme.min(theme.breakpoints.max)]: {
    maxWidth: 568,
    '& button': {
      maxWidth: 274,
    },
  },
}));

const Heading = styled(Heading2)(({ theme }) => ({
  marginBottom: 24,
  lineHeight: 1.18,
  position: 'relative',
  zIndex: 2,
  maxWidth: 420,
  fontWeight: 600,
  textAlign: 'center',
  [theme.min(theme.breakpoints.md)]: {
    textAlign: 'left',
    maxWidth: '85%',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    marginBottom: 0,
  },
}));

const DigitalOwnershipsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  position: 'relative',
  zIndex: 2,
  [theme.min(theme.breakpoints.md)]: {
    flexDirection: 'row',
  },
}));

const IllustrationEl = styled(Illustration)(({ theme }) => ({
  width: 408,
  height: 'auto',
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    width: theme.pxToVw(408),
  },
}));

const IllustrationWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  maxWidth: '100%',
  overflow: 'hidden',
  zIndex: 1,
  [theme.min(theme.breakpoints.xs)]: {
    right: 0,
    left: 'auto',
  },
  [theme.min(theme.breakpoints.md)]: {
    right: '10%',
  },
  [theme.min(theme.breakpoints.ul)]: {
    right: '10%',
  },
}));

const ImagesWrapper = styled(ContainerEl)(({ theme }) => ({
  display: 'flex',
  overflow: 'visible',
  marginBottom: 40,
  [theme.min(theme.breakpoints.sm)]: {
    marginBottom: 64,
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    marginBottom: theme.pxToVw(64),
  },
}));

const Image = styled('img')(({ theme }) => ({
  display: 'none',
  [theme.max(theme.breakpoints.sm)]: {
    '&.img_mobile': {
      display: 'block',
      width: '90vw',
      marginLeft: 'auto',
      height: 'auto',
    },
  },
  [theme.min(theme.breakpoints.sm)]: {
    '&.img_1': {
      width: 'auto',
      display: 'block',
      marginRight: theme.pxToVw(30),
      height: theme.pxToVw(650),
      marginLeft: 'auto',
      [theme.min(1650)]: {
        // height: theme.pxToVw(650, 1520),
      },
    },
    '&.img_2': {
      width: 'auto',
      display: 'block',
      height: theme.pxToVw(650),
      [theme.min(1650)]: {
        // height: theme.pxToVw(650, 1520),
      },
    },
  },
}));

const TellTheWorld = styled('div')(({ theme }) => ({
  backgroundColor: '#000',
  paddingTop: 64,
  [theme.betweenDM]: {
    paddingTop: theme.pxToVw(64),
  },
}));

const Styled = {
  Wrapper,
  Container,
  Text,
  RealTimeContainer,
  Heading,
  ActionButtons,
  DigitalOwnershipsWrapper,
  Illustration: IllustrationEl,
  IllustrationWrapper,
  OwnershipWrapper,
  ImagesWrapper,
  Image,
  TellTheWorld,
};

export default Styled;
