import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useWindowWidth } from '../../../../../../../hooks/useWindowWidth';

import { NFT } from '../../../../../../../types';

import { NftItem, NftItemLoader } from './NftItem';
import { NoItemsText } from '../../../components';

interface Props {
  items?: NFT[];
  isFetchingNextPage?: boolean;
}

const Loader: React.FC<{ count?: number }> = ({ count = 6 }) => {
  const width = useWindowWidth();

  return (
    <>
      {[...Array(width < 480 ? Math.round(count / 2) : count).keys()].map(item => (
        <NftItemLoader key={item} />
      ))}
    </>
  );
};

export const NftsList: React.FC<Props> = ({ items, isFetchingNextPage }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const { current: wrapper } = wrapperRef;

  useEffect(() => {
    if (isFetchingNextPage && wrapper) {
      wrapper?.scrollTo({ top: wrapper.scrollHeight - 500, behavior: 'smooth' });
    }
  }, [isFetchingNextPage, wrapper]);

  const renderContent = () => {
    if (!items) return <Loader />;
    if (!items.length)
      return <NoItemsText>{t('profilePage.nftsNotAvailable')}</NoItemsText>;
    return (
      <>
        {items.map((nft, i) => (
          <NftItem key={i} nft={nft} />
        ))}
        {isFetchingNextPage && <Loader count={8} />}
      </>
    );
  };

  return <Wrapper ref={wrapperRef}>{renderContent()}</Wrapper>;
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(100px, 1fr))',
  width: '110%',
  paddingRight: '5%',
  maxHeight: 480,
  overflowY: 'auto',
  gap: 20,
  marginTop: 24,
  marginBottom: 12,
  position: 'relative',
  [theme.min(theme.breakpoints.xs)]: {
    marginBottom: 16,
    gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))',
    paddingRight: '10%',
  },
  [theme.min(theme.breakpoints.md)]: {
    marginBottom: 20,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    gridTemplateColumns: 'repeat(6, 1fr)',
  },
  [theme.betweenDM]: {
    maxHeight: theme.pxToVw(480),
    gap: theme.pxToVw(20),
    marginTop: theme.pxToVw(24),
    marginBottom: theme.pxToVw(20),
  },
}));
