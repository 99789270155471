import { useEffect, useRef, useState } from 'react';

export const useLoadImage = (url: string, fallbackUrl: string) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [src, setSrc] = useState(url);

  const ref = useRef(document.createElement('img'));

  const { current: img } = ref;

  useEffect(() => {
    if (!src || isLoaded) return;
    img.setAttribute(
      'style',
      'visibility: hidden; width: 1px; height: 1px; position: absolute;'
    );

    img.src = src;

    img.onload = () => {
      setIsLoaded(true);
      setIsLoading(false);
      img.remove();
    };

    img.onerror = () => {
      if (fallbackUrl && img.src !== fallbackUrl) {
        img.src = fallbackUrl;
        setSrc(fallbackUrl);
      } else {
        setIsError(true);
        setIsLoading(false);
        img.remove();
      }
    };

    document.body.appendChild(img);
  }, [src, img, fallbackUrl, isLoaded]);

  return { src, isLoaded, isError, isLoading };
};
