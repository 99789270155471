import { TransactionType } from '../types';

export const QueryKey = {
  User: () => 'User',
  UserNfts: (userId: string, search: string, sortBy: string) => [
    'User-NFTS',
    userId,
    search,
    sortBy,
  ],
  UserProjects: () => ['UserProjects'],
  UserProject: (userId: string, uid: string) => ['UserProject', userId, uid],
  PopularUsers: () => 'PopularUsers',
  RecommendedUsers: () => 'RecommendedUsers',
  ProfileFollowers: (usernameOrAddress: string) => ['Followers', usernameOrAddress],
  ProfileFollowersCount: (usernameOrAddress: string) => [
    'FollowersCount',
    usernameOrAddress,
  ],
  ProfileFollowings: (usernameOrAddress: string) => ['Followings', usernameOrAddress],
  ProfileFollowingsCount: (usernameOrAddress: string) => [
    'FollowingsCount',
    usernameOrAddress,
  ],
  ProfileTotalAssets: (usernameOrAddress: string) => [
    'ProfileTotalAssets',
    usernameOrAddress,
  ],
  Profile: (id: string) => ['Profile', id],
  ProfileProjectes: (usernameOrAddress: string) => ['ProfileProjects', usernameOrAddress],
  Nfts: (address: string, search: string, sortBy: string) => [
    'NFTS',
    address,
    search,
    sortBy,
  ],
  Reputation: (address: string) => ['Reputation', address],

  Project: (address: string) => ['Project', address],
  ProjectDetails: (contractAddress: string) => ['ProjectDetails', contractAddress],
  ProjectEndorsments: (projectUid: string) => ['ProjectEndorsments', projectUid],
  ProjectEndorsmentsCount: (projectUid: string) => [
    'ProjectEndorsmentsCount',
    projectUid,
  ],
  ProjectFollowers: (projectUid: string) => ['ProjectFollowers', projectUid],
  ProjectFollowersCount: (projectUid: string) => ['ProjectFollowersCount', projectUid],
  ProjectTransactions: (
    address: string,
    txType: TransactionType[],
    sort: string,
    search: string
  ) => ['ProjectTransactions', address, ...txType.sort(), sort, search],
  ProjectAnalyticsFrequencyOfTransactions: (
    contractAddress: string,
    interval = 'all'
  ) => ['ProjectAnalyticsFrequencyOfTransactions', contractAddress],
  PopularProjects: () => 'PopularProjects',
  ProjectMilestones: (projectUid: string) => ['ProjectMilestones', projectUid],

  Notifications: (opts?: { page: number }) => ['Notifications', opts?.page],
  NotificationsCount: () => 'NotificationsCount',
};
