import { useMutation } from 'react-query';
import { ProjectMilestonesApi } from '../../../api/ProjectApi/ProjectMilestonesApi';
import { useInvalidateProjectMilestones } from './useProjectMilestones';

export const useProjectMilestoneDelete = () => {
  const invalidateMilestones = useInvalidateProjectMilestones();

  return useMutation(ProjectMilestonesApi.deleteProjectMilestone, {
    onSuccess: () => {
      invalidateMilestones();
    },
  });
};
