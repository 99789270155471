import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useWhere } from '../../../providers/WhereProvider';

import { AllowNotifications } from './SettingItems/AllowNotifications';
import { NotificationsHeader } from './NotificationsHeader';

export const NotificationsDisabledText: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <NotificationsHeader />
      <Wrapper>
        <Text>
          <span>{t('notifications.allNotificationsAreDisabled')}</span>
          <br />
          {t('notifications.enableNotificationsText')}
        </Text>
        <AllowNotifStyled />
      </Wrapper>
    </>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: useWhere() === 'popup' ? 22 : 24,
  padding: useWhere() === 'popup' ? '14px 20px' : '20px 0',
  [theme.betweenDM]: {
    padding:
      useWhere() === 'popup'
        ? `${theme.pxToVw(14)} ${theme.pxToVw(14)}`
        : `${theme.pxToVw(14)} 0`,
  },
}));

const Text = styled('p')(({ theme }) => ({
  fontSize: 14,
  lineHeight: 1.32,
  fontWeight: 700,
  '& span': {
    color: theme.colors.primary.main,
  },
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: useWhere() === 'popup' ? 14 : 20,
  },
  [theme.min(theme.breakpoints.lg)]: {
    fontSize: useWhere() === 'popup' ? 14 : 26,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: useWhere() === 'popup' ? theme.pxToVw(14) : theme.pxToVw(32),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: useWhere() === 'popup' ? 14 : 32,
  },
}));

const AllowNotifStyled = styled(AllowNotifications)({
  width: 'max-content',
});
