import styled from 'styled-components';
import { Container } from '../../../../../components/layouts/Container';

export const NewsFeedTitle = styled('h3')(({ theme }) => ({
  fontSize: 24,
  fontWeight: 700,
  marginBottom: 20,
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(32),
    marginBottom: theme.pxToVw(40),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 32,
    marginBottom: 40,
  },
}));

export const NewsFeedCardBase = styled(Container)(({ theme }) => ({
  backgroundColor: 'rgba(22, 22, 22, 0.6)',
  width: '100%',
  padding: '40px 20px',
  [theme.min(theme.breakpoints.xs)]: {
    width: '90%',
  },
  [theme.min(theme.breakpoints.sm)]: {
    borderRadius: 15,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    padding: `${theme.pxToVw(32)} ${theme.pxToVw(40)}`,
    borderRadius: theme.pxToVw(30),
  },
  [theme.min(theme.breakpoints.max)]: {
    padding: '32px 40px',
    borderRadius: 30,
  },
}));
