import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useFilePicker } from 'use-file-picker';
import { UserApi } from '../../../../../../../../api/UserApi/UserApi';
import { useRequest } from '../../../../../../../../hooks/useRequest';
import { InputAlikeButton } from '../../../../../../../../components/ui/buttons/InputAlikeButton';
import DocItem from './DocItem';

interface Props {
  onChange: (urls: string[]) => void;
  fileUrls: string[] | null;
}

export default function UploadDocument({ onChange, fileUrls }: Props) {
  const [openFileSelector, { plainFiles, loading, clear }] = useFilePicker({
    multiple: true,
  });

  const onSaveFiles = useCallback(async () => {
    if (!plainFiles?.length) return;

    const docUrls = await Promise.all(
      plainFiles.map(file => {
        const formData = new FormData();
        formData.append('document', file);
        return UserApi.uploadProjectDocument(formData);
      })
    );

    onChange(docUrls);
    clear();
  }, [plainFiles, onChange, clear]);

  const { call, isLoading } = useRequest(onSaveFiles);

  useEffect(() => {
    if (!plainFiles?.length || isLoading) return;
    call();
  }, [plainFiles, isLoading, call]);

  return (
    <Wrapper>
      <Label>Related Documents</Label>
      {!!fileUrls?.length && (
        <DocumentsWrapper>
          {fileUrls.map((fileUrl, i) => (
            <DocItem
              key={fileUrl}
              name={`Doc ${i + 1}`}
              onClick={() => window.open(fileUrl, '_blank')}
            />
          ))}
        </DocumentsWrapper>
      )}
      <Btn onClick={openFileSelector} isLoading={loading}>
        Upload Document
      </Btn>
    </Wrapper>
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
}));

const Label = styled('span')(({ theme }) => ({
  fontSize: 12,
  color: theme.colors.primary.main,
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(12),
  },
}));

const DocumentsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 12,
  flexWrap: 'wrap',
  marginBottom: 8,
}));

const Btn = styled(InputAlikeButton)({
  border: '2px dashed #0065FC',
});
