import React from 'react';
import styled from 'styled-components';

import { useAddress } from '../../../../../../providers/AddressProvider/AddressProvider';
import { useNftsByAddress } from '../../../../../../react-query/nfts/useNftsByAddress';
import { useReputation } from '../../../../../../react-query/reputation/useReputation';

import { NftsList } from './NftsList/NftsList';
import { Address } from '../../../../../../components/common/Address/Address';
import { Pagination } from '../../../../../../components/common/Pagination/Pagination';

interface Props {
  search: string;
  sortBy: string;
}

export const ProfileDataNftsCard: React.FC<Props> = ({ search, sortBy }) => {
  const { address } = useAddress();
  const { data: reputation } = useReputation(address);

  const {
    formattedData: nfts,
    isFetchingNextPage,
    totalCount,
    hasNextPage,
    fetchNextPage,
  } = useNftsByAddress({
    params: {
      search,
      sortBy,
    },
  });

  if (!reputation || !address) return null;

  return (
    <Wrapper>
      <Address
        address={address!}
        where='list'
        isContract={!!reputation?.isContract}
        shortenAtWidth={640}
      />
      <NftsList items={nfts} isFetchingNextPage={isFetchingNextPage} />
      {!!nfts?.length && typeof totalCount !== 'undefined' && (
        <Pagination
          currentItemsCount={nfts.length}
          totalCount={totalCount || 0}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={!!hasNextPage}
          onFetchNextPage={fetchNextPage}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}));
