import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Notification, NotificationType } from '../../../../types';
import { TextBase } from '../shared-components';
import { NotificationItemOptions } from './NotificationItemOptions';
import { LoadingBackground } from '../../../ui/loaders/LoadingBackground';

import { defaultProfileImage } from '../../../../assets';
import { toValidDate, timeSinceFromNow } from '../../../../utils/date-utils';
import { routes } from '../../../../router/routes';
import newTxDefaultImage from './newTxDefault.png';
import { shortenEthAddress } from '../../../../utils/address-utils';

interface Props {
  notification: Notification;
}

export const NotificationItem: React.FC<Props> = ({ notification }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isRead, content, createdAt, type, id } = notification;

  const middleText = {
    [NotificationType.Endorse]: t('notifications.endorsed'),
    [NotificationType.NewTx]: 'has made a',
  };

  const defaultImageSrc = {
    [NotificationType.Endorse]: defaultProfileImage,
    [NotificationType.NewTx]: content.username ? defaultProfileImage : newTxDefaultImage,
  };

  const renderTextOne = () => {
    switch (content.type) {
      case NotificationType.Endorse:
        return (
          <TextBase onClick={() => navigate(routes.profile.byId(content.username))}>
            {content.username + ' '}
          </TextBase>
        );
      case NotificationType.NewTx:
        const { username, address } = content;

        const displayText = username ? username : shortenEthAddress(address);

        return (
          <TextBase onClick={() => navigate(routes.profile.byId(username || address))}>
            {displayText + ' '}
          </TextBase>
        );
    }
  };

  const renderTextTwo = () => {
    switch (content.type) {
      case NotificationType.Endorse:
        return (
          <TextBase
            onClick={
              content.projectAddress
                ? () => navigate(routes.project.byId(content.projectAddress))
                : undefined
            }
          >
            {content.projectTitle}
          </TextBase>
        );
      case NotificationType.NewTx:
        return (
          <TextBase
            onClick={() =>
              navigate(
                routes.profile.byId(content.username || content.address) + '?view=history'
              )
            }
          >
            New Transaction.
          </TextBase>
        );
    }
  };

  return (
    <Wrapper isRead={isRead}>
      <ImageWrapper>
        <Dot isRead={isRead} />
        <Image src={content.userPhotoUrl || defaultImageSrc[type]} />
      </ImageWrapper>
      <TextWrapper>
        {renderTextOne()}
        <TextBase light>{middleText[type] + ' '}</TextBase>
        {renderTextTwo()}
      </TextWrapper>
      <Details>
        <span>{timeSinceFromNow(toValidDate(createdAt))}</span>
        <NotificationItemOptions notificationId={id} isRead={isRead} />
      </Details>
    </Wrapper>
  );
};

export const NotificationItemLoader = () => (
  <Wrapper isLoading isRead={false}>
    <ImageWrapper>
      <Dot isRead={false} isLoading />
      <LoadingBackground style={{ width: 36, height: 36, borderRadius: '50%' }} />
    </ImageWrapper>
    <TextWrapper>
      <LoadingBackground style={{ width: '60%' }}>
        <TextBase>L</TextBase>
      </LoadingBackground>
    </TextWrapper>
    <Details>
      <LoadingBackground style={{ height: 30, width: 25 }} />
    </Details>
  </Wrapper>
);

const Wrapper = styled('div')<{ isRead: boolean; isLoading?: boolean }>(
  ({ theme, isRead, isLoading }) => ({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    transition: 'all .3s',
    marginBottom: isLoading ? 2 : 0,
    backgroundColor: isLoading
      ? 'rgba(255, 255, 255, 0.1)'
      : `rgba(0, 101, 252, ${isRead ? '0' : '0.24'})`,
    padding: '12px 20px',
    gap: 8,
    [theme.betweenDM]: {
      padding: `${theme.pxToVw(12)} ${theme.pxToVw(20)}`,
    },
  })
);

const ImageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
}));

const Image = styled('img')(({ theme }) => ({
  width: 36,
  height: 36,
  borderRadius: '50%',
  objectFit: 'cover',
  objectPosition: 'top',
  [theme.betweenDM]: {
    width: theme.pxToVw(36),
    height: theme.pxToVw(36),
  },
}));

const Dot = styled('div')<{ isRead: boolean; isLoading?: boolean }>(
  ({ theme, isRead, isLoading }) => ({
    display: 'flex',
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: isLoading
      ? 'rgba(255, 255, 255, 0.6)'
      : isRead
      ? 'transparent'
      : theme.colors.primary.main,
    [theme.betweenDM]: {
      width: theme.pxToVw(6),
      height: theme.pxToVw(6),
    },
  })
);

const TextWrapper = styled('div')(({ theme }) => ({
  flex: 1,
}));

const Details = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 8,
  marginLeft: 'auto',
  position: 'relative',
  '& span': {
    fontSize: 12,
    fontWeight: 600,
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(8),
    '& span': {
      fontSize: theme.pxToVw(12),
    },
  },
}));
