import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useProfileByParamId } from '../../../../../../react-query/profile/useProfile';
import { useProfileUtils } from '../../../../hooks/useProfileUtils';

import { Container } from '../../../../../../components/layouts/Container';
import { AddressProvider } from '../../../../../../providers/AddressProvider/AddressProvider';
import {
  ProfileDataCardBase,
  ProfileDataFollowButton,
  ProfileDataPageTitle,
} from '../../components';
import { AddressesSelect } from '../../AddressesSelect/AddressesSelect';
import { ProfileDataNftsCard } from './ProfileDataNftsCard';
import { SearchAndSort } from '../../../../../../components/common/SearchAndSort/SearchAndSort';

export const NftsView: React.FC = () => {
  const { data: profile } = useProfileByParamId();
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [addresses, setAddreses] = useState<string[]>();
  const { isOwner, isClaimedAddress } = useProfileUtils();

  useEffect(() => {
    if (profile) setAddreses(profile.addresses);
  }, [profile]);

  if (!profile) return null;

  const onAddressChange = (address: string) => {
    setAddreses(address === 'all' ? profile.addresses : [address]);
  };

  return (
    <>
      <ActionsWrapper>
        <ProfileDataPageTitle>NFTs</ProfileDataPageTitle>
        {!!profile?.addresses.length && <AddressesSelect onChange={onAddressChange} />}
      </ActionsWrapper>
      {isClaimedAddress && (
        <ActionsWrapper>
          <SearchAndSort
            setSearch={setSearch}
            setSortBy={setSortBy}
            search={search}
            sortBy={sortBy}
          />
        </ActionsWrapper>
      )}
      <Wrapper>
        {addresses?.map(address => (
          <ProfileDataCardBase key={address}>
            <AddressProvider initAddress={address}>
              <ProfileDataNftsCard search={search} sortBy={sortBy} />
            </AddressProvider>
            {!isOwner && isClaimedAddress && (
              <ProfileDataFollowButton address={address} />
            )}
          </ProfileDataCardBase>
        ))}
      </Wrapper>
    </>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  width: '100%',
}));

const ActionsWrapper = styled(Container)(({ theme }) => ({
  marginBottom: 12,
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  [theme.min(theme.breakpoints.sm)]: {
    marginBottom: 16,
    gap: 16,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    marginBottom: theme.pxToVw(20),
    gap: theme.pxToVw(20),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginBottom: 20,
    gap: 20,
  },
}));
