import React from 'react';
import styled from 'styled-components';
import { Popover, PopoverProps } from 'react-tiny-popover';
import { DropdownMenuBaseDefaultTrigger } from './DropdownMenuBaseDefaultTrigger';

export enum DropdownMenuVariant {
  Primary,
  Secondary,
  Black,
}
export interface DropdownMenuBaseProps
  extends Omit<PopoverProps, 'children' | 'content'> {
  menuItems?: {
    label: string;
    value?: string | number;
    onClick: (value?: string) => void;
    active?: boolean;
  }[];
  menuComponentItems?: React.ReactNode[];
  Trigger?: React.ReactNode;
  label?: string;
  onToggle: () => void;
  menuVariant?: DropdownMenuVariant;
  menuStyle?: React.CSSProperties;
}

export const DropdownMenuBase = React.forwardRef<HTMLElement, DropdownMenuBaseProps>(
  (
    {
      menuItems,
      Trigger,
      onToggle,
      label,
      menuVariant = DropdownMenuVariant.Primary,
      menuComponentItems,
      menuStyle = {},
      ...otherProps
    },
    ref
  ) => {
    return (
      <>
        {otherProps.isOpen && (
          <Backdrop
            onClick={e => {
              e.stopPropagation();
              onToggle();
            }}
          />
        )}
        <Popover
          ref={ref}
          positions={['bottom']}
          padding={20}
          {...otherProps}
          containerStyle={{ zIndex: '200' }}
          content={popoverState => {
            return (
              <MenuItemsWrapper
                open={otherProps.isOpen}
                itemCount={menuItems?.length || menuComponentItems?.length || 0}
                variant={menuVariant}
                style={menuStyle}
              >
                {menuItems &&
                  menuItems.map((item, i) => (
                    <MenuItem
                      key={i}
                      onClick={e => {
                        e.stopPropagation();
                        item.onClick();
                        if (otherProps.isOpen) onToggle();
                      }}
                      $active={item.active}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                {menuComponentItems &&
                  menuComponentItems.map((item, i) => (
                    <MenuItem key={i} className='dropdown-menu-item'>
                      {item}
                    </MenuItem>
                  ))}
              </MenuItemsWrapper>
            );
          }}
        >
          <TriggerWrapper
            onClick={e => {
              e.stopPropagation();
              onToggle();
            }}
            open={otherProps.isOpen}
          >
            {Trigger || (
              <DropdownMenuBaseDefaultTrigger label={label} isOpen={otherProps.isOpen} />
            )}
          </TriggerWrapper>
        </Popover>
      </>
    );
  }
);

const Backdrop = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  height: '100vh',
  zIndex: 200,
});

const TriggerWrapper = styled('div')<{ open: boolean }>(({ open }) => ({
  cursor: 'pointer',
  opacity: 1,
  transition: 'all .3s',
  '&:hover': {
    opacity: 0.85,
  },
}));

const MenuItemsWrapper = styled('div')<{
  open: boolean;
  itemCount: number;
  variant: DropdownMenuVariant;
}>(({ open, itemCount, theme, variant }) => ({
  transition: 'all .3s',
  overflow: 'hidden',
  borderRadius: 10,
  backgroundColor:
    variant === DropdownMenuVariant.Black
      ? '#393939'
      : variant === DropdownMenuVariant.Primary
      ? theme.colors.primary.main
      : '#3D4859',
}));

const MenuItem = styled('div')<{ $active?: boolean }>(({ theme, $active, onClick }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: '#fff',
  transition: 'all .3s',
  padding: '10px 20px',
  minWidth: 190,
  cursor: onClick ? 'pointer' : 'auto',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: onClick ? 'rgba(0, 0, 0, 0.31)' : 'transparent',
  },
  [theme.betweenDM]: {
    minWidth: theme.pxToVw(190),
    fontSize: theme.pxToVw(16),
  },
}));
