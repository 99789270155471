import { useCallback, useMemo } from 'react';
import { useAuth } from '../../../providers/AuthProvider';
import { useProfileByParamId } from '../../../react-query/profile/useProfile';
import { useCurrentUserFollowings } from '../../../react-query/user/useFollowings';
import { useUser } from '../../../react-query/user/useUser';

export const useProfileUtils = () => {
  const { isAuthenticated } = useAuth();
  const { data: user } = useUser();
  const { data: profile } = useProfileByParamId();
  const { data: userFollowings } = useCurrentUserFollowings();

  const isOwner = useMemo(() => {
    return (
      isAuthenticated &&
      !!user &&
      !!profile &&
      !!profile?.addresses?.find(profileAddress =>
        user?.addresses?.find(userAddress => userAddress === profileAddress)
      )
    );
  }, [isAuthenticated, user, profile]);

  const isClaimedAddress = useMemo(() => !!profile && !!profile.uid, [profile]);

  // TODO: Add logic for this when be is ready
  const followedProfileAddresses = useMemo(
    () =>
      !userFollowings || !profile
        ? null
        : profile.addresses.filter(address =>
            userFollowings.find(following => following.address === address)
          ),
    [userFollowings, profile]
  );

  const isFollowedAddress = useCallback(
    (addressOrUid: string) =>
      userFollowings &&
      userFollowings.some(
        following =>
          following.address === addressOrUid ||
          following.title === addressOrUid ||
          following.entityId === addressOrUid
      ),
    [userFollowings]
  );

  return {
    isOwner,
    isClaimedAddress,
    followedProfileAddresses,
    isFollowedAddress,
  };
};
