import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Transaction } from '../../../../../../../types';

import { NoItemsText } from '../../../components';
import { LoadingBackground } from '../../../../../../../components/ui/loaders/LoadingBackground';
import { TransactionItem, TransactionItemLoader } from './TransactionItem';

import { formatDateToLocale } from '../../../../../../../utils/date-utils';

interface Props {
  items?: Transaction[];
  isFetchingNextPage?: boolean;
}

const Loader: React.FC = () => {
  return (
    <>
      {' '}
      <LoadingItemWrapper>
        <DateLoader />
        {[...Array(2).keys()].map(item => (
          <TransactionItemLoader key={item} />
        ))}
      </LoadingItemWrapper>{' '}
      <LoadingItemWrapper>
        <DateLoader />
        {[...Array(2).keys()].map(item => (
          <TransactionItemLoader key={item} />
        ))}
      </LoadingItemWrapper>
    </>
  );
};

export const TransactionsList: React.FC<Props> = ({ items, isFetchingNextPage }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const { current: wrapper } = wrapperRef;

  useEffect(() => {
    if (isFetchingNextPage && wrapper) {
      wrapper?.scrollTo({ top: wrapper.scrollHeight - 400, behavior: 'smooth' });
    }
  }, [isFetchingNextPage, wrapper]);

  const transactionsByDay = useMemo(
    () =>
      items?.reduce((groupedTransactions, transaction) => {
        const date = formatDateToLocale(transaction.timeStamp);
        const item = groupedTransactions.find(group => group.date === date);

        if (!item) {
          groupedTransactions.push({ date, items: [transaction] });
        } else {
          item.items.push(transaction);
        }

        return groupedTransactions;
      }, [] as { date: string; items: Transaction[] }[]),
    [items]
  );

  const renderContent = () => {
    if (!items) return <Loader />;
    if (!items!.length)
      return <NoItemsText>{t('profilePage.transactionsNotAvailable')}</NoItemsText>;
    return (
      <>
        {transactionsByDay?.map(group => (
          <div key={group.date}>
            <DateText>{group.date}</DateText>
            {group.items.map((transaction, i) => (
              <TransactionItem key={i} transaction={transaction} />
            ))}
          </div>
        ))}
        {isFetchingNextPage && <Loader />}
      </>
    );
  };

  return <Wrapper ref={wrapperRef}>{renderContent()}</Wrapper>;
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '110%',
  paddingRight: '5%',
  maxHeight: 640,
  overflowY: 'auto',
  gap: 12,
  marginTop: 24,
  marginBottom: 12,
  position: 'relative',
  [theme.min(theme.breakpoints.xs)]: {
    marginBottom: 16,
    paddingRight: '10%',
    gap: 17,
  },
  [theme.min(theme.breakpoints.md)]: {
    marginBottom: 20,
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(12),
    marginBottom: theme.pxToVw(20),
    marginTop: theme.pxToVw(24),
    maxHeight: theme.pxToVw(640),
  },
}));

const DateText = styled('p')(({ theme }) => ({
  marginBottom: 8,
  fontSize: 14,
  fontWeight: 600,
  [theme.min(theme.breakpoints.sm)]: {
    marginBottom: 10,
  },
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(14),
    marginBottom: theme.pxToVw(10),
  },
}));

const LoadingItemWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  marginTop: 8,
  [theme.betweenDM]: {
    gap: theme.pxToVw(8),
    marginTop: theme.pxToVw(8),
  },
}));

const DateLoader = styled(LoadingBackground)(({ theme }) => ({
  height: 18,
  width: 130,
  borderRadius: 8,
  marginBottom: 4,
  [theme.betweenDM]: {
    height: theme.pxToVw(18),
    width: theme.pxToVw(130),
    borderRadius: theme.pxToVw(8),
    marginTop: theme.pxToVw(4),
  },
}));
