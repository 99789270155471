import React from 'react';

import { envs } from '../../../config/envs';

import { DiscordIcon, LinkedinIcon, TelegramIcon, TwitterIcon } from './svgs';

import Styled from './SocialIcons.styles';

interface Props {
  iconSize?: number | string;
  iconGap?: number | string;
}

const socialIcons: {
  Icon: typeof TwitterIcon;
  url: string | undefined;
}[] = [
  {
    Icon: TwitterIcon,
    url: envs.social.twitter,
  },
  {
    Icon: DiscordIcon,
    url: envs.social.discord,
  },
  {
    Icon: LinkedinIcon,
    url: envs.social.linkedin,
  },
  {
    Icon: TelegramIcon,
    url: envs.social.telegram,
  },
];

export const SocialIcons: React.FC<Props> = ({
  iconSize = 32,
  iconGap = 40,
  ...otherProps
}) => {
  return (
    <Styled.Wrapper iconSize={iconSize} iconGap={iconGap} {...otherProps}>
      {socialIcons.map(({ Icon, url }) =>
        url ? <Icon key={url} onClick={() => window.open(url, '_blank')} /> : null
      )}
    </Styled.Wrapper>
  );
};
