import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useProjectByParamId } from '../../../../../../../react-query/project/useProject';
import { useProjectUtils } from '../../../../hooks/useProjectUtils';
import { useAuth } from '../../../../../../../providers/AuthProvider';

import { ButtonPrimary } from '../../../../../../../components/ui/buttons';
import { ReactComponent as ClaimSvg } from './Claim.svg';
import { routes } from '../../../../../../../router/routes';

export const ClaimProjectButton: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { isOwner, isClaimedProject } = useProjectUtils();
  const { data: project } = useProjectByParamId();
  const { isAuthenticated } = useAuth();

  if (isOwner || isClaimedProject || !project) return null;

  const text = t(`projectPage.${isAuthenticated ? 'addContract' : 'claimProject'}`);

  const onClick = () => {
    if (!isAuthenticated) {
      return navigate(
        routes.auth.register() + `?contractAddress=${project.contractAddress}`
      );
    }

    navigate(routes.project.root() + `?contractAddress=${project.contractAddress}`);
  };

  return (
    <ButtonWrapper>
      <ButtonPrimary onClick={onClick} width='auto !important'>
        {text} {!isAuthenticated && <Icon />}
      </ButtonPrimary>
    </ButtonWrapper>
  );
};

const Icon = styled(ClaimSvg)(({ theme }) => ({
  marginLeft: 12,
  [theme.betweenDM]: {
    height: theme.pxToVw(20),
    width: 'auto',
  },
}));

const ButtonWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
}));
