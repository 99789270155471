import React from 'react';
import styled from 'styled-components';

import { useProfileTotalAssets } from '../../../../../react-query/profile/useProfileTotalAssets';

import { Spinner } from '../../../../../components/ui/loaders/Spinner';

interface Props {
  label: string;
  value: string;
  badge?: string | number;
  additionalValue: string;
  icon?: JSX.Element;
}

export const AssetDetailCardBase: React.FC<Props> = ({
  label,
  badge,
  value,
  additionalValue,
  icon = null,
}) => {
  const { data: totalAssets } = useProfileTotalAssets();

  return (
    <Wrapper>
      <TopContent>
        <Title>{label}</Title>{' '}
        <Badge isLoading={!badge || !totalAssets}>{badge || 0}</Badge>
      </TopContent>
      <BottomContent>
        <DetailTextWrapper isLoading={!totalAssets}>
          <DetailText>{value || 'Loading'}</DetailText>
          <DetailText>{additionalValue || 'Loading'}</DetailText>
        </DetailTextWrapper>
        {icon && <IconWrapper isLoading={!totalAssets}>{icon}</IconWrapper>}
        {!totalAssets && <Loader size={14} />}
      </BottomContent>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: 'rgba(61, 72, 89, 0.4)',
  borderRadius: 15,
  border: '1px solid rgba(255, 255, 255, 0.1)',
  overflow: 'hidden',
  padding: 20,
  position: 'relative',
  flex: 1,
  minWidth: 202,
  [theme.betweenDM]: {
    borderRadius: theme.pxToVw(15),
    padding: theme.pxToVw(18),
    minWidth: theme.pxToVw(202),
  },
  [theme.min(theme.breakpoints.max)]: {
    padding: 18,
  },
}));

const TopContent = styled('div')(({ theme }) => ({
  width: '100%',
  borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
  display: 'flex',
  alignItems: 'center',
  gap: 6,
  paddingBottom: 8,
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(12),
    paddingBottom: theme.pxToVw(18),
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 12,
    paddingBottom: 18,
  },
}));

const Title = styled('h6')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  textTransform: 'uppercase',
  lineHeight: 0.96,
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(20),
  },
  [theme.min(1920)]: {
    fontSize: 20,
  },
}));

interface WithLoading {
  isLoading: boolean;
}

const Badge = styled('div')<WithLoading>(({ theme, isLoading }) => ({
  height: 24,
  width: 'auto',
  flexShrink: 0,
  opacity: isLoading ? 0 : 1,
  padding: '0 8px',
  backgroundColor: 'rgba(255, 255, 255, 0.08)',
  borderRadius: 10,
  fontSize: 14,
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.betweenDM]: {
    height: theme.pxToVw(32),
    fontSize: theme.pxToVw(14),
    borderRadius: theme.pxToVw(10),
    padding: `0 ${theme.pxToVw(8)}`,
    minWidth: theme.pxToVw(32),
  },
  [theme.min(theme.breakpoints.ul)]: {
    height: 32,
  },
}));

const BottomContent = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: 12,
  position: 'relative',
  width: '100%',
  [theme.betweenDM]: {
    paddingTop: theme.pxToVw(12),
  },
}));

const DetailTextWrapper = styled('div')<WithLoading>(({ theme, isLoading }) => ({
  display: 'flex',
  flexDirection: 'column',
  opacity: isLoading ? 0 : 1,
}));

const DetailText = styled('p')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  '&:first-child': {
    marginBottom: 6,
    fontWeight: 700,
    [theme.min(theme.breakpoints.desktop)]: {
      fontSize: theme.pxToVw(16),
      marginBottom: theme.pxToVw(9),
    },
    [theme.min(theme.breakpoints.max)]: {
      fontSize: 16,
      marginBottom: 9,
    },
  },
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(14),
  },
}));

const IconWrapper = styled('div')<WithLoading>(({ theme, isLoading }) => ({
  width: 30,
  height: 30,
  opacity: isLoading ? 0 : 1,
  backgroundColor: 'rgba(255, 255, 255, 0.08)',
  borderRadius: '50%',
  position: 'absolute',
  right: 0,
  bottom: 0,
  padding: 4,
  [theme.betweenDM]: {
    width: theme.pxToVw(30),
    height: theme.pxToVw(30),
    padding: theme.pxToVw(4),
    '& svg': {
      height: '90%',
      width: 'auto',
    },
  },
}));

const Loader = styled(Spinner)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}));
