import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useProfileByParamId } from '../../../../react-query/profile/useProfile';
import { useWindowWidth } from '../../../../hooks/useWindowWidth';
import { useProfileUtils } from '../../hooks/useProfileUtils';

import { shortenEthAddress } from '../../../../utils/address-utils';

import { ClipboardButton } from '../../../../components/ui/buttons/ClipboardButton/ClipboardButton';
import { FollowUserProfileButton } from '../../../../components/common/ActionButtons/FollowUserProfileButton';

export const ConnectedAddressesExpandable: React.FC = () => {
  const { data: profile } = useProfileByParamId();
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const windowWidth = useWindowWidth();
  const { isOwner } = useProfileUtils();

  const addresses = profile?.addresses || [];

  const getCtaText = () => {
    let ctaText: string = '';

    if (windowWidth < 1050) {
      ctaText = t(open ? 'hide' : 'showAll');
    }

    if (profile && addresses.length > 2 && windowWidth > 1050) {
      ctaText = t(open ? 'hide' : 'showAll');
    }

    return ctaText;
  };

  const ctaText = getCtaText();

  return (
    <Wrapper itemsCount={addresses.length} open={open}>
      <PaddingWrapper>
        <Row head open={open}>
          <span>{t('connectedAddresses')}</span>
          {ctaText && (
            <Hide
              onClick={() => setOpen(prev => !prev)}
              itemsCount={addresses.length ?? 2}
              open={open}
            >
              {ctaText}
            </Hide>
          )}
        </Row>
      </PaddingWrapper>
      <Dropdown itemsCount={addresses.length} open={open}>
        {addresses.map(address => (
          <Row key={address} itemsCount={addresses?.length}>
            <span>
              {shortenEthAddress(address, {
                placeholderChar: '.',
                placeholderCount: 2,
                startChars: 5,
              })}
              <Clipboard value={address} />
            </span>
            {!isOwner && <FollowUserProfileButton address={address} />}
          </Row>
        ))}
      </Dropdown>
    </Wrapper>
  );
};

const Wrapper = styled('div')<{ open?: boolean; itemsCount: number }>(
  ({ theme, itemsCount, open }) => ({
    backgroundColor: 'rgba(61, 72, 89, 0.4)',
    borderRadius: 15,
    border: '1px solid rgba(255, 255, 255, 0.1)',
    height: `${open ? (itemsCount + 1) * 52 + 10 : 52} !important`,
    maxHeight: open ? 6 * 52 + 10 : 48,
    transition: 'all .3s',
    flex: 1,
    overflowY: 'hidden',
    [theme.min(1050)]: {
      maxHeight: open ? 6 * 60 + 50 : itemsCount === 1 ? 110 : 168,
      height: open ? itemsCount * 60 + 50 : itemsCount === 1 ? 110 : 168,
      minWidth: 300,
    },
    [theme.betweenDM]: {
      borderRadius: theme.pxToVw(15),
      maxHeight: open
        ? theme.pxToVw(6 * 60 + 50)
        : theme.pxToVw(itemsCount === 1 ? 110 : 168),
      height: open
        ? theme.pxToVw(itemsCount * 60 + 50)
        : theme.pxToVw(itemsCount === 1 ? 110 : 168),
      minWidth: theme.pxToVw(300),
    },
  })
);

const PaddingWrapper = styled('div')(({ theme }) => ({
  padding: '0 20px',
  [theme.min(1280)]: {
    padding: '0 16px',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    padding: `0 ${theme.pxToVw(18)}`,
  },
  [theme.min(theme.breakpoints.max)]: {
    padding: '0 18px',
  },
}));

const Dropdown = styled(PaddingWrapper)<{ open?: boolean; itemsCount: number }>(
  ({ theme, itemsCount, open }) => ({
    maxHeight: 5 * 52,
    transition: 'all .3s',
    width: '100%',
    flex: 1,
    overflowY: open ? 'auto' : 'hidden',
    paddingBottom: 8,
    [theme.min(theme.breakpoints.md)]: {
      maxHeight: open ? 4 * 60 - 2 : itemsCount === 1 ? 60 : 120,
      paddingBottom: open ? 8 : 0,
    },
    [theme.betweenDM]: {
      maxHeight: open
        ? theme.pxToVw(4 * 60 - 2)
        : theme.pxToVw(itemsCount === 1 ? 60 : 120),
      paddingBottom: open ? theme.pxToVw(8) : 0,
    },
  })
);

const Row = styled('div')<{ open?: boolean; itemsCount?: number; head?: boolean }>(
  ({ theme, open, head }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: 52,
    fontSize: 14,
    fontWeight: 700,
    borderBottom: `1px solid ${
      open && head ? 'rgba(255, 255, 255, 0.5)' : 'transparent'
    }`,
    [theme.min(theme.breakpoints.md)]: {
      height: head ? 48 : 60,
      '&:first-child': {
        borderBottom: head ? '1px solid rgba(255, 255, 255, 0.5)' : 'none',
      },
      '&:not(:last-child)': {
        borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
      },
    },
    [theme.betweenDM]: {
      height: head ? theme.pxToVw(48) : theme.pxToVw(60),
      fontSize: theme.pxToVw(14),
    },
  })
);

const Clipboard = styled(ClipboardButton)(({ theme }) => ({
  marginLeft: 0,
  position: 'relative',
  top: 2,
}));

const Hide = styled('span')<{ open?: boolean; itemsCount: number }>(
  ({ theme, open, itemsCount }) => ({
    cursor: 'pointer',
    display: 'block',
    width: 'max-content',
    opacity: 1,
    transition: 'all .3s',
    borderBottom: '3px solid #fff',
    '&:hover': {
      opacity: 0.85,
    },
  })
);
