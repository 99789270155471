import { Trust } from '../types';

const common = {
  termsOfService: 'Terms Of Service',
  privacyPolicy: 'Privacy Policy',
  installExtension: 'Install Extension',
  getInTouch: 'Get in Touch',
  monthsAgo: 'months ago',
  daysAgo: 'days ago',
  comingSoon: 'Coming Soon',
  analyzing: 'Analysing...',
  download: 'Download {{format}}',
  address: 'Address',
  contract: 'Contract',
  tweetIt: 'Tweet it',
  submit: 'Submit',
  saveChanges: 'Save Changes',
  cancel: 'Cancel',
  welcome: 'Welcome',
  profile: 'Profile',
  goBack: 'Go back',
  sign: 'Sign',
  search: 'Search',
  of: 'of',
  showMore: 'Show More',
  showLess: 'Show Less',
  sortBy: 'Sort By',
  follow: 'Follow',
  unfollow: 'Unfollow',
  followers: 'Followers',
  following: 'Following',
  followAllAddresses: 'Follow All Addresses',
  unfollowAllAddresses: 'Unfollow All Addresses',
  connectedAddresses: 'Connected Addresses',
  copyLink: 'Copy Link',
  share: 'Share',
  shareOn: 'Share on {{network}}',
  history: 'History',
  estValue: 'Est. Value',
  allAddresses: 'All Addresses',
  hide: 'Hide',
  showAll: 'Show All',
  myWeb3Profile: 'My WEB3 Profile',
  send: 'Send',
  to: 'To',
  from: 'From',
  gasFee: 'Gas fee',
  received: 'Received',
  allTransactions: 'All Transactions',
  tokens: 'Tokens',
  transactionHistory: 'Transactions History',
  general: 'General',
  sort: 'Sort',
  tokenMint: 'Token Mint',
  tokenTransfer: 'Token Transfer',
  nftMint: 'NFT Mint',
  nftTransfer: 'NFT Transfer',
  latest: 'Latest',
  oldest: 'Oldest',
  learnMore: 'Learn More',
  created: 'Created',
  endorsed: 'Endorsed',
  endorse: 'Endorse',
  followProject: 'Follow Project',
  unfollowProject: 'Unfollow Project',
  by: 'By',
  title: 'Title',
  contractAddress: 'Contract Address',
  socialNetworks: 'Social Networks',
  connectNewWallet: 'Connect New Wallet',
  selectConnectedAddress: 'Select a connected address',
  open: 'Open',
  projectDetails: 'Project Details',
  deleteProject: 'Delete Project',
  projects: 'Projects',
  findOutMore: 'Find out more',
  ctaCopy: 'Love our mission? \nLet’s make it a reality together',
  moreAboutBlockem: 'More about Blockem',
  followUs: 'Follow Us',
  home: 'Home',
  endorsments: 'Endorsments',
  seeThisProjectProfilePreview: 'See this Project Profile preview',
  seeThisProfilePreview: 'See this Profile preview',
  addThisToMyProfile: 'Add this {{type}} to my Profile',
  generateProfile: 'Generate Profile',
  generateOtherProfiles: 'Generate other profiles.',
  searchPlaceholder: 'ETH address or Web3* domain',
  supportedDomains: '*Currently supported domains: .eth and .bit',
  enterEmailAddress: 'Enter Email Address',
  subscribe: 'Subscribe',
  viewAll: 'View All',
  subscribeSuccessMessage: 'You have been successfully subscribed.',
};

const confirm = {
  removeAddress: {
    title: 'Are you sure you want to remove address?',
    text: ['The Address', 'will be removed.'],
  },
  removeProject: {
    title: 'Are you sure you want to delete project?',
    text: 'The project {{name}} will be removed.',
  },
  removeAccount: {
    title: 'Are you sure you want to delete your account?',
    text: 'Your account will be permanently deleted.',
  },
  noCancel: 'No, cancel',
  yesDelete: 'Yes, delete',
};

const notifications = {
  pageTitle: 'Notifications',
  endorsed: 'endorsed',
  markAllAsRead: 'Mark all as read',
  allowNotifications: 'Allow notifications',
  muteNotifications: 'Mute all notifications',
  allNotificationsAreDisabled: 'All notifications are currently disabled.',
  enableNotificationsText: 'Enable notifications in order to get notified again.',
  markAsRead: 'Mark as read',
  markAsUndread: 'Mark as unread',
  noNotifications: `You don't have any notifications yet!`,
};

const analysisResult = {
  isVerified: 'This {{type}}address is verified to be associated with a given project.',
  verified: 'Verified',
  trustPercentage: {
    title: 'Trust Percentage',
    notEnoughInfo: 'New Address',
  },
  balance: 'Balance',
  firstTransaction: {
    title: 'First Transaction',
  },
  lastTransaction: {
    title: 'Last Transaction',
  },
  totalTransactions: {
    title: 'Transactions',
  },
  tokensHeld: {
    title: 'NON-FUNGIBLE TOKENS (NFT)',
  },
  erc20Tokens: {
    title: 'Erc20 Tokens',
  },
  totalValueReceived: {
    title: 'Total value received',
    info: 'The approximate sum of received ETH in the last 10.000 transactions.',
  },
  totalValueSent: {
    title: 'Total value sent',
    info: 'The approximate sum of sent ETH in the last 10.000 transactions.',
  },
  summary: {
    [Trust.High]: {
      title: 'All Good',
      text: 'Our algorithm has not detected any scam-related activities associated with this {{type}}. We consider this {{type}} safe to interact with.',
    },
    [Trust.Medium]: {
      title: 'Pay Attention',
      text: 'Our algorithm has detected a small probability of this {{type}} being scam-related. To be completely safe, please check the details and do your own research before proceeding.',
    },
    [Trust.Low]: {
      title: 'Take Extra Caution',
      text: 'Our algorithm has flagged this {{type}} for possible scam-related activity. Only interact with this {{type}} if you trust its owner.',
    },
    [Trust.Untrusted]: {
      title: 'Not Trusted',
      text: 'Our algorithm has detected high possibility of scam-related activity by this {{type}}. We recommend not to interact with it.',
    },
    [Trust.NewWallet]: {
      title: 'Be Careful',
      text: 'This {{type}} has not interacted enough with blockchain network. Please check the details and do your own research before interacting with it.',
    },
  },
  reportThisAddress: 'Report this Address',
  addressReported: 'Address reported',
};

const accountDetailsPage = {
  title: 'Account Details',
  addYourNft: 'Add your NFT',
  changePassword: 'Change Password',
  deleteAccount: 'Delete Account',
};

const profilePage = {
  folowers: common.followers,
  followThisAddress: 'Follow this Address',
  linkShare: `Check out {{username}}'s profile on Blockem`,
  noProfileImageNfts: 'No NFTs here, go and get some to show off.',
  setYourNft: 'Choose your NFT',
  newUserModal: {
    title: 'Want to stay safe in web3?',
    subtitle: 'Install Blockem Extension.',
  },
  claimCta: {
    long: 'Claim and Create Profile',
    short: 'Claim this Address',
  },
  editProfileModal: {
    title: 'Edit Profile',
    descriptionPlaceholder: 'Tell us something about yourself.',
    socialTitle: 'Add social networks',
  },
  nftsNotAvailable: 'There are no NFTs for the requested address.',
  projectsNotAvailable: 'This {{who}} has no projects.',
  transactionsNotAvailable: 'There are no transactions for the requested address.',
  editCoverPhoto: 'Edit Cover Photo',
};

const projectPage = {
  tokenSupply: 'Token Supply',
  holders: 'Holders',
  floorPrice: 'Floor Price',
  followers: common.followers,
  trustedBy: 'Trusted by',
  and: 'and',
  others: 'others',
  editProfile: 'Edit Profile',
  registerProjectToUnlock: 'Register Project to unlock.',
  frequencyOfTransactions: {
    title: 'Frequency of Transactions',
  },
  socialNetworksAnalysis: {
    title: 'Social Networks Analysis',
  },
  verifyOwnershipInfo:
    '*In order to verify a project ownership please connect a contract owner’s wallet.',
  or: 'Or',
  projectOwner: 'Project Owner',
  projectDescription: 'Project Description',
  endorsers: 'Endorsers',
  claimProject: 'Claim and Create Profile',
  addContract: 'Add this Contract to my Profile',
  transactionsNotAvailable: 'This project has no transactions.',
  nonNftProjectProfile: 'NonNFTProjectProfile',
  nonNftDetails: {
    title: 'Currently only NFT Projects are supported.',
    description:
      'Please subscribe to get notified when other types of projects are supported.',
  },
};

const projectCreatePage = {
  pageTitle: 'New Project',
  title: 'New Project',
  stepOnePlaceholder: 'Enter Project Contract Address',
  stepOneCta: 'Check Contract Address',
  addProject: 'Add New Project',
};

const generateWeb3ProfilePage = {
  pageTitle: 'Generate Web3 Profile',
};

const projectEditDetailsPage = {
  pageTitle: 'Edit Project',
  title: 'Project Details',
};

const myProjectsPage = {
  pageTitle: 'My Projects',
  title: 'My Projects',
  addProject: 'Add Project',
};

const projectOnboard = {
  pageTitle: 'Create New Project',
  descriptions: [
    'In order to create new Project Profile, please sign in or register new user account associated with the contract owner’s address.',
    'You have been successfully registered!',
    'Edit Project details.',
  ],
  step: 'Step {{step}}',
  cta0: 'Register and Create New Project',
  cta1: 'Next Step',
  cta2: 'Create new Project Profile',
  goToYourProfile: 'Go to Your Profile',
  newProject: 'New Project',
};

const errors = {
  invalidEmail: 'Must be a valid email',
  invalidEthAddress: 'Invalid address provided.',
  default: 'Something went wrong. Please try again later or contact support.',
  invalidCredentials: 'Invalid credentials.',
  fieldRequired: 'This field is required',
  usernameDot: 'Username cannot contain a dot(.).',
  usernameCharsCount: 'Username must contain at least 4 characters.',
  walletRequired: 'At least one connected address is required.',
  claimWrongWallet: `Addresses don't match`,
  terms: 'Please Agree to our Terms of Services.',
  invalidUrlMatch: 'Invalid {{address}} address provided.',
  contractAddressRequired: 'Enter the contract address to create a project profile.',
  minCharCount: 'At least {{requiredChars}} characters are required',
  walletMismatch: 'You must switch your wallet to match this address.',
  // API ERRORS
  INCORRECT_ADDRESS_LENGTH: 'Incorrect address length, please try again.',
  INTERNAL_SERVER_ERROR:
    'Something went wrong. Please try again later or contact support.',
  INCORRECT_ETH_NAME:
    'Incorrect {{type}} name. Please enter a valid {{type}} name or address and try again.',
  USERNAME_ALREADY_EXISTS: 'Username already exists.',
  ADDRESS_ALREADY_CONNECTED: 'Address already connected to an account.',
  INVALID_SIGNATURE: 'Invalid signature provided.',
  USER_NOT_FOUND: 'User not found.',
  LAST_CONNECTED_ADDRESS: 'Last connected address.',
  ADDRESS_NOT_FOUND: 'Address not found.',
  ADDRESS_IS_NOT_CONTRACT: 'The entered address is not a contract.',
  PROJECT_ALREADY_CONNECTED:
    'A project with the entered contract address already exists.',
  ALREADY_SUBSCRIBED: 'This email address is already subscribed.',
};

const auth = {
  register: 'Register',
  login: 'Login',
  signin: 'Sign In',
  signup: 'Sign Up',
  logout: 'Logout',
  changePassword: 'Change Password',
  alreadyAMember: 'Already a member?',
  username: 'Username',
  password: 'Password',
  forgotPassword: 'Forgot password',
  phoneNumber: 'Phone Number',
  repeatPassword: 'Repeat Password',
  connectedWallets: 'Connected Wallets',
  connectedWallet: 'Connected Wallet',
  connectWallet: 'Connect Wallet',
  emailAddress: 'Email Address',
  agreeToOur: 'Agree to our ',
  notAMemberYet: 'Not a member yet?',
  registerHere: 'Register Here',
  resetPassword: 'Reset Password',
  oldPassword: 'Old Password',
  enterOldPassword: 'Enter Old Password',
  newPassword: 'New Password',
  repeatNewPassword: 'Repeat New Password',
  connectWalletInfo:
    'If you connected multiple accounts in your wallet, please switch to each one you want connected to your profile so that it’s detected.',
};

const cta = {
  exploreBlockem: 'Explore  Blockem',
};

const homePage = {
  pageTitles: {
    root: 'Home',
  },
  heading: 'The Security Needed For\nA Decentralized World.',
  searchInfo: 'Check if an address is scam-related.',
  resultTitle: 'Analysis Results',
  results: {
    trustPercentage: {
      title: 'Trust Percentage',
      notEnoughInfo: 'New Address',
    },
    balance: 'Balance',
    firstTransaction: {
      title: 'First Transaction',
    },
    lastTransaction: {
      title: 'Last Transaction',
    },
    totalTransactions: {
      title: 'Transactions',
    },
    tokensHeld: {
      title: 'NON-FUNGIBLE TOKENS (NFT)',
    },
    erc20Tokens: {
      title: 'Erc20 Tokens',
    },
    totalValueReceived: {
      title: 'Total value received',
      info: 'The approximate sum of received ETH in the last 10.000 transactions.',
    },
    totalValueSent: {
      title: 'Total value sent',
      info: 'The approximate sum of sent ETH in the last 10.000 transactions.',
    },
    summary: {
      [Trust.High]: {
        title: 'All Good',
        text: 'Our algorithm has not detected any scam-related activities associated with this {{type}}. We consider this {{type}} safe to interact with.',
      },
      [Trust.Medium]: {
        title: 'Pay Attention',
        text: 'Our algorithm has detected a small probability of this {{type}} being scam-related. To be completely safe, please check the details and do your own research before proceeding.',
      },
      [Trust.Low]: {
        title: 'Take Extra Caution',
        text: 'Our algorithm has flagged this {{type}} for possible scam-related activity. Only interact with this {{type}} if you trust its owner.',
      },
      [Trust.Untrusted]: {
        title: 'Not Trusted',
        text: 'Our algorithm has detected high possibility of scam-related activity by this {{type}}. We recommend not to interact with it.',
      },
      [Trust.NewWallet]: {
        title: 'Be Careful',
        text: 'This {{type}} has not interacted enough with blockchain network. Please check the details and do your own research before interacting with it.',
      },
    },
  },
  digitalOwnershipHeading: 'Digital ownership.\nWithout the risks.',
  digitalOwnershipText:
    'Blockem is the most advanced Ethereum anti-scam detection software on the market, analysing more than a billion Ethereum transactions per second. Using Blockem you’ll be able to easily and reliably check if your recipient is related to any kind of scams or frauds. It’s the anti-scam software that performs a detailed analysis of entire Ethereum network in order to validate whether the  is trustworthy or not.',
  realTimeFraudulentHeading: 'Real Time Fraudulent\nTransaction Stopping',
  realTimeFraudulentText:
    'Blockem combines a cutting-edge AI model with sophisticated rule-based algorithms to provide high-accuracy scam detection and prevention. Blockem uses the latest in artificial intelligence to explore the blockchain network for signs of fraudulent activity. Over 1.5 TB worth of transactions were fed to Blockem to get to our high scam detection rate. When installed, Blockem extension is performing real-time analysis and detection of scam-related addresses, in order to prevent malicious transactions.',
  digitalOwnershipBenefits: [
    {
      title: 'Cutting-Edge AI model',
      text: 'Blockem combines a cutting-edge AI model with sophisticated rule-based algorithms to provide high-accuracy scam detection and prevention.',
    },
    {
      title: 'Fast and Easy',
      text: 'Fast and easy to use, no need for any configuration.',
    },
    {
      title: 'Real-Time Scam Prevention',
      text: 'When installed, Blockem extension performs real-time analysis and detection of malicious transactions in order to prevent them.',
    },
  ],
  shareText: 'Share the trust score & save a friend.',
  shareLinkText:
    '{{address}} has a trust score of {{trustPercentage}}%. Find out your trust score with the free Blockem.io Crypto Scam Blocker',
  shareLinkTextNewWallet:
    '{{address}} is a newly created address. Find out your trust score with the free Blockem.io Crypto Scam Blocker',
  popularProjects: 'Popular projects',
  mostFollowed: 'Most followed',
  recomendedUsers: 'Recomended profiles',
  exploreWeb3: {
    title: 'Explore \nWEB3 Profiles',
    text1:
      'Blockem profiles put the most important information about your project in one place and let your community engage with it.',
    text2: 'Bye official links, hello Blockem Profile.',
  },
  checkAddress: 'Check Address',
  reportAddress: 'Report Address',
  reportAddressPlaceholder: 'Report ETH address or Web3* domain',
  submitReport: 'Submit Report',
  reportAnotherAddress: 'Report another address',
  describeWhyReportPlaceholder: 'Describe why you are reporting this address',
  chooseScamType: 'Choose Address Type',
  scamOptions: ['Drainer', 'Rug Pull', 'Fake token', 'Airdrop scam', 'Other'],
  addressReported1: 'Thank you! The Address ',
  addressReported2: ' has been successfully reported!',
};

const generateProfile = {
  pageTitle: 'Generate Profile',
  heading: 'Express your WEB3 self.',
  searchInfo: 'Create a profile that wins trust.',
  bottomSectionHeading:
    'Blockem Project Profiles are the best way to show the world why your web3 project matters.',
  benefits: [
    {
      title: 'Engage your community & build trust.',
      text: 'Blockem profiles put the most important information about your project in one place and let your community engage with it. Bye official links, hello Blockem Profile.',
    },
    {
      title: 'Getting upvotes gives you exposure.',
      text: 'Floor price isn’t all that matters. Show the world your community’s strength by getting upvotes. More upvotes means more exposure.',
    },
    {
      title: 'Build trust in a decentralized web.',
      text: 'No need to explain over and over your progress. Instead, send them your profile!',
    },
  ],
  bottomTitle: 'Tell the world why your project matters.',
};

const errorPage = {
  '404': {
    title: 'Page not found.',
    subtitle: `We can't find the page you're looking for.`,
  },
  '500': {
    title: 'Something went wrong',
  },
  profileNotFound: {
    title: 'Profile not found',
    subtitle: `We can't find the profile you're looking for.`,
  },
  projectNotFound: {
    title: 'Project not found',
    subtitle: `We can't find the project you're looking for.`,
  },
};

const contactPage = {
  pageTitle: 'Contact',
  reachUsAt: 'Reach us at',
  orJoinUs: 'or join our Social Networks',
};

const en = {
  errors,
  cta,
  common,
  homePage,
  auth,
  accountDetailsPage,
  analysisResult,
  profilePage,
  errorPage,
  confirm,
  projectPage,
  projectCreatePage,
  projectEditDetailsPage,
  myProjectsPage,
  projectOnboard,
  generateWeb3ProfilePage,
  generateProfile,
  notifications,
  contactPage,
};

export default en;
