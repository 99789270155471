import React from 'react';

import { ProjectDescription } from '../../../components/project/project-form-components/ProjectDescription';
import { ProjectSocialNetworks } from '../../../components/project/project-form-components/ProjectSocialNetworks';

export const EditProjectDetailsForm: React.FC = () => {
  return (
    <>
      <ProjectDescription />
      <ProjectSocialNetworks />
    </>
  );
};
