import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useRoadmapState } from '../../RoadmapStateProvider';
import { Milestone } from '../../../../../../types';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

const goalSchema = Joi.object({
  title: Joi.string().required(),
  description: Joi.string().required(),
  documentUrls: Joi.array().items(Joi.string()).allow(null),
  uid: Joi.string(),
  isFulfilled: Joi.bool(),
  relatedUrl: Joi.string().allow('', null),
});

const schema = Joi.object({
  title: Joi.string().required(),
  goals: Joi.array().items(goalSchema).min(1),
});

export const useMilestoneForm = (milestone?: Milestone, orderNumber?: number) => {
  const { mode } = useRoadmapState();
  const form = useForm<Milestone>({
    defaultValues: {
      uid: milestone?.uid,
      title: milestone?.title || `Milestone ${orderNumber || 1}`,
      orderNumber: milestone?.orderNumber || orderNumber || 1,
      orderString: milestone?.orderString || '',
      goals: milestone?.goals || [],
    },
    resolver: joiResolver(schema),
  });

  const { reset, setValue } = form;

  useEffect(() => {
    if (typeof orderNumber === 'undefined' || milestone || mode !== 'create') return;
    setValue('title', `Milestone ${orderNumber}`);
  }, [orderNumber, milestone, mode, setValue]);

  useEffect(() => {
    if (!milestone) {
      reset();
    } else {
      reset(milestone);
    }
  }, [milestone, reset]);

  return form;
};
