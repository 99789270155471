import { useInfiniteQuery } from 'react-query';

import { UserApi } from '../../api/UserApi/UserApi';
import { getPaginatedDataResults, getPaginatedResultsTotalCount } from '../utils';
import { QueryKey } from '../query-keys';
import { useUser } from './useUser';

interface Props {
  params: {
    search: string;
    sortBy: string;
  };
}
export const useUserNfts = ({ params }: Props) => {
  const { data: user } = useUser();
  const { search, sortBy } = params;

  const query: Partial<Record<'search' | 'sortBy', string>> = {};
  if (search) query.search = search;
  if (sortBy) query.sortBy = sortBy;

  const { data, ...rest } = useInfiniteQuery(
    QueryKey.UserNfts(user?.uid!, search, sortBy),
    ({ pageParam = 1 }) => UserApi.getUserNfts({ page: pageParam, ...query }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      enabled: !!user,
      getNextPageParam: (lastPage, allPages) => {
        const totalResultsFetched = getPaginatedResultsTotalCount(allPages);
        if (totalResultsFetched === lastPage.totalCount) return undefined;
        return lastPage.nextPage;
      },
    }
  );

  return {
    data,
    formattedData: data ? getPaginatedDataResults(data) : undefined,
    totalCount: data?.pages[0]?.totalCount,
    ...rest,
  };
};
