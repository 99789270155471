import styled from 'styled-components';

export const BaseCard = styled('div')(({ theme }) => ({
  background: '#000',
  borderRadius: 10,
  boxShadow: '0px 0px 3px 2px rgba(53, 133, 254, 0.39)',
  [theme.betweenDM]: {
    borderRadius: theme.pxToVw(10),
  },
}));
