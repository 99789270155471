import { useQuery } from 'react-query';
import { ProjectApi } from '../../api/ProjectApi/ProjectApi';

import { QueryKey } from '../query-keys';

export const useEndorsmentsTotalCount = (projectId: string | null | undefined) => {
  return useQuery(
    QueryKey.ProjectEndorsmentsCount(projectId!),
    () => ProjectApi.getEndorsmentsCount(projectId!),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: !!projectId,
    }
  );
};
