import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Popover } from 'react-tiny-popover';

import { useUser } from '../../../react-query/user/useUser';
import {
  useInfiniteNotifications,
  useNotificationsCount,
} from '../../../react-query/user/notifications/useNotifications';

import { WhereProvider } from '../../../providers/WhereProvider';

import { NotificationsHeader } from './NotificationsHeader';
import { NotificationList } from './NotificationList';
import { NotificationsDisabledText } from './NotificationsDisabledText';
import { NotificationErrMsg, TextBase } from './shared-components';
import { Spinner } from '../../ui/loaders/Spinner';

import { ReactComponent as BellSvg } from './svgs/Bell.svg';

import { routes } from '../../../router/routes';
import { StorageKeys, StorageService } from '../../../services/StorageService';

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const NotificationIcon: React.FC<Props> = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { data: user } = useUser();
  const { data: notificationsCount } = useNotificationsCount();
  const {
    formattedData: notifications,
    error,
    isLoading,
    totalCount,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteNotifications();

  const renderContent = () => {
    if (!user) return null;

    if (!user.notificationsEnabled) return <NotificationsDisabledText />;

    if (error)
      return (
        <Content>
          <NotificationErrMsg>{t('errors.default')}</NotificationErrMsg>
        </Content>
      );

    if (totalCount === 0)
      return (
        <Content>
          <TextBase>{t('notifications.noNotifications')}</TextBase>
        </Content>
      );

    return (
      <>
        {user?.notificationsEnabled && notifications?.length && (
          <NotificatiosWrapper
            onScroll={e => {
              const { scrollTop, scrollHeight, offsetHeight } = e.currentTarget;

              const shouldFetch =
                Math.abs(scrollTop - (scrollHeight - offsetHeight)) >= 10;

              if (shouldFetch) {
                if (hasNextPage && !isFetchingNextPage) fetchNextPage();
                const timeout = setTimeout(() => {
                  if (e.currentTarget) {
                    e.currentTarget.scrollTop = e.currentTarget.scrollHeight;
                  }
                  clearTimeout(timeout);
                }, 2);
              }
            }}
          >
            <NotificationList
              notifications={notifications}
              isLoading={isLoading || !notificationsCount}
            />
            {isFetchingNextPage && (
              <Spinner style={{ margin: '10px auto', width: 'max-content' }} />
            )}
          </NotificatiosWrapper>
        )}
        <Content className='view-all-btn'>
          <TextBase
            onClick={() => {
              setOpen(false);
              navigate(routes.notifications.root());
            }}
            style={{ textDecoration: 'underline' }}
          >
            {t('common.viewAll')}
          </TextBase>
        </Content>
      </>
    );
  };

  if (!notificationsCount) return null;

  const showWhiteWithNotification =
    !StorageService.read(StorageKeys.MuteNotifications) &&
    !pathname.includes('notification') &&
    !!notificationsCount?.undreadCount;

  const TheIcon = Icon;

  return (
    <>
      {/* {open && <Backdrop onClick={() => setOpen(false)} />} */}
      <Popover
        isOpen={open}
        positions={['bottom']}
        padding={20}
        containerStyle={{ zIndex: '100' }}
        onClickOutside={() => setOpen(false)}
        content={popoverState => {
          return (
            <WhereProvider where='popup'>
              <NotificationPopup>
                {user?.notificationsEnabled && <NotificationsHeader />}
                {renderContent()}
              </NotificationPopup>
            </WhereProvider>
          );
        }}
      >
        <IconWrapper onClick={() => setOpen(prev => !prev)}>
          {showWhiteWithNotification && <Bell />}
          <TheIcon $white={!pathname.includes('notification')} />
        </IconWrapper>
      </Popover>
    </>
  );
};

const IconWrapper = styled('div')({
  position: 'relative',
  cursor: 'pointer',
  marginRight: 4,
});

const Bell = styled('div')(({ theme }) => ({
  display: 'flex',
  borderRadius: '50%',
  width: 8.5,
  height: 8.5,
  borderColor: '#000',
  borderWidth: 4,
  borderStyle: 'solid',
  boxSizing: 'content-box',
  backgroundColor: theme.colors.primary.main,
  position: 'absolute',
  transform: 'translate(70%, -30%)',

  [theme.betweenDM]: {
    width: theme.pxToVw(10),
    height: theme.pxToVw(10),
    borderWidth: theme.pxToVw(4),
    transform: 'translate(80%, -30%)',
  },
  [theme.min(theme.breakpoints.max)]: {
    width: 10,
    height: 10,
    transform: 'translate(80%, -30%)',
    borderWidth: 4,
  },
}));

const Icon = styled(BellSvg)<{ $white?: boolean }>(({ theme, $white }) => ({
  '& path': {
    fill: $white ? '#fff !important' : theme.colors.primary.main,
  },
  height: 31,
  [theme.min(theme.breakpoints.desktop)]: {
    height: theme.pxToVw(37),
    width: 'auto',
    marginRight: 0,
  },
  [theme.min(theme.breakpoints.max)]: {
    height: 37,
  },
}));

const Content = styled('div')(({ theme }) => ({
  padding: '14px 20px',
  '&.view-all-btn': {
    display: 'none',
    [theme.min(theme.breakpoints.xs)]: {
      display: 'block',
    },
  },
  [theme.betweenDM]: {
    padding: `${theme.pxToVw(14)} ${theme.pxToVw(20)} `,
  },
}));

const NotificationPopup = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100vw',
  height: 'calc(100vh - 80px)',
  minHeight: 'calc(100vh - 80px)',
  maxHeight: 'calc(100vh - 80px)',
  backgroundColor: '#000',
  overflow: 'hidden',
  '@supports (-webkit-touch-callout: none)': {
    paddingBottom: 200,
  },
  [theme.min(theme.breakpoints.xs)]: {
    height: 'auto',
    width: '80vw',
    border: '1px solid #2B2B2B',
    boxShadow: '0px 0px 10px 7px rgba(61, 61, 61, 0.39)',
    borderRadius: 5,
    minHeight: 'auto',
    maxHeight: 600,
    maxWidth: 502,
    transform: 'translateX(-18%)',
    '@supports (-webkit-touch-callout: none)': {
      paddingBottom: '60px',
    },
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    maxHeight: theme.pxToVw(600),
    maxWidth: theme.pxToVw(502),
  },
}));

const NotificatiosWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'calc(100vh - 80px)',
  backgroundColor: '#000',
  overflow: 'auto',
  maxHeight: 'calc(100vh - 80px)',
  scrollBehavior: 'smooth',
  [theme.min(theme.breakpoints.xs)]: {
    height: 'auto',
    borderRadius: 5,
    maxHeight: 560,
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    maxHeight: theme.pxToVw(560),
  },
}));
