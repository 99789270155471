import { useQuery } from 'react-query';
import { ProfileApi } from '../../api/ProfileApi';
import { QueryKey } from '../query-keys';
import { useProfileByParamId } from './useProfile';

export const useProfileTotalAssets = () => {
  const { data: profile } = useProfileByParamId();

  return useQuery(
    QueryKey.ProfileTotalAssets(profile?.username!),
    () => ProfileApi.getProfileTotalAssets(profile?.username!),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: !!profile?.username,
    }
  );
};
