import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { QueryKey } from '../../../react-query/query-keys';
import { useUser } from '../../../react-query/user/useUser';

export const useInvalidateCurrentUserProfile = () => {
  const queryClient = useQueryClient();
  const { data: user } = useUser();

  const invalidate = useCallback(async () => {
    if (!user) return;
    await Promise.all([
      queryClient.invalidateQueries(QueryKey.User()),
      queryClient.invalidateQueries(QueryKey.Profile(user.username)),
      user.addresses.forEach(address =>
        queryClient.invalidateQueries(QueryKey.Profile(address))
      ),
    ]);
  }, [queryClient, user]);

  return invalidate;
};
