import React from 'react';
import ContentLoaderLib, { IContentLoaderProps } from 'react-content-loader';

interface Props extends IContentLoaderProps {
  children: React.ReactNode;
}

export const ContentLoader: React.FC<Props> = ({ children, ...otherProps }) => {
  return (
    <ContentLoaderLib
      speed={2}
      backgroundColor='rgba(0,0,0, 1)'
      foregroundColor='rgba(30,30,30, 1)'
      {...otherProps}
    >
      {children}
    </ContentLoaderLib>
  );
};
