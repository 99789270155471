import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useConnectWallet } from '@web3-onboard/react';

import { useResetPasswordForm } from './useResetPasswordForm';
import { useRequest } from '../../../hooks/useRequest';

import { AuthApi, ResetPasswordPayload } from '../../../api/AuthApi';
import { SignMessage } from '../../../constants/sign-messages';
import { Web3Service } from '../../../services/Web3Service';

import { ButtonPrimary } from '../../ui/buttons';
import { PasswordField } from '../../ui/forms/Input/PasswordField';
import { ConnectWalletButton } from '../../common/ConnectWalletButton/ConnectWalletButton';
import { Form } from '../Form';
import { useWalletUtils } from '../../../hooks/useWalletUtils';

interface Props {
  onSuccess?: () => void;
}

export const ResetPasswordForm: React.FC<Props> = ({ onSuccess }) => {
  const { t } = useTranslation();
  const [{ wallet }] = useConnectWallet();
  const { wallets, setWallets } = useWalletUtils();

  const {
    formState: { errors, isValid, isDirty },
    register,
    handleSubmit,
    getValues,
    reset,
    setError,
  } = useResetPasswordForm();

  const onSubmit = useCallback(async () => {
    if (!wallet) return;

    const address = wallet.accounts[0].address;
    const signature = await Web3Service.signMessage(wallet, SignMessage.ResetPassword);
    const newPassword = getValues('password');

    const payload: ResetPasswordPayload = {
      newPassword,
      signature,
      address,
    };

    await AuthApi.resetPassword(payload);
  }, [wallet, getValues]);

  const { call, isLoading, isSuccess } = useRequest(onSubmit, {
    onSuccess: () => {
      reset();
      typeof onSuccess === 'function' && onSuccess();
    },
    onError: err => {
      const msg = err?.response?.data?.message;
      const messages = ['USER_NOT_FOUND', 'INVALID_SIGNATURE'];

      if (messages.includes(msg)) {
        const errText = t(`errors.${msg}`);
        setError('addresses', { message: errText });
      }
    },
  });

  return (
    <Form onSubmit={handleSubmit(call)}>
      <PasswordField
        required
        placeholder={t('auth.newPassword') + '*'}
        label={t('auth.newPassword')}
        error={errors.password}
        {...register('password')}
      />
      <PasswordField
        required
        placeholder={t('auth.repeatPassword') + '*'}
        label={t('auth.repeatPassword')}
        error={errors.confirmPassword}
        {...register('confirmPassword')}
      />
      <ConnectWalletButton
        error={errors.addresses?.message}
        addresses={wallets.map(w => w.address)}
        currentWallet={true}
        onWalletConnect={data => setWallets([data])}
        signMessage={SignMessage.ResetPassword}
      />

      <ButtonPrimary
        width='100%'
        disabled={!wallet || !isDirty || !isValid}
        isLoading={isLoading || isSuccess}
      >
        {t('common.sign')}
      </ButtonPrimary>
    </Form>
  );
};
