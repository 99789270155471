import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { ProfileDataNavbar } from './Navbar/ProfileDataNavbar';

import { AddressReputationsView } from './views/AddressReputationsView/AddressReputationsView';
import { NftsView } from './views/NftsView/NftsView';
import { HistoryView } from './views/HistoryView/HistoryView';

import { UrlService } from '../../../../services/UrlService';
import { ProjectsView } from './views/ProjectsView/ProjectsView';

export const ProfileData: React.FC = () => {
  const { search } = useLocation();

  const renderView = useCallback(() => {
    switch (UrlService.getSearchParam('view', search)) {
      case 'nfts':
        return <NftsView />;
      case 'history':
        return <HistoryView />;
      case 'projects':
        return <ProjectsView />;
      default:
        return <AddressReputationsView />;
    }
  }, [search]);

  return (
    <Wrapper>
      <ProfileDataNavbar />
      {renderView()}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  zIndex: theme.zIndex.xs,
  marginTop: 40,
  flex: 1,
  minHeight: 860,
  paddingBottom: '5vh',
  [theme.betweenDM]: {
    minHeight: theme.pxToVw(860),
    marginTop: theme.pxToVw(40),
  },
}));
