import React, { useState } from 'react';
import styled from 'styled-components';

import { ShowMoreButton } from '../common/ActionButtons/ShowMore';
import { LoadingBackground } from '../ui/loaders/LoadingBackground';

import { limitLongString } from '../../utils/string-utils';

interface Props {
  text: string;
  isLoading?: boolean;
}

const CHAR_LIMIT = 300;

export const ProfileDescription: React.FC<Props> = ({
  text,
  isLoading,
  ...otherProps
}) => {
  const isMax = text.length > CHAR_LIMIT;

  const [open, setOpen] = useState(!isMax);

  const renderContent = () => {
    if (isLoading)
      return (
        <>
          <LoadingBg>
            <Text>l</Text>
          </LoadingBg>
          <LoadingBg>
            <Text>l</Text>
          </LoadingBg>
          <LoadingBg>
            <Text>l</Text>
          </LoadingBg>
          <LoadingBg>
            <Text>l</Text>
          </LoadingBg>
        </>
      );

    return (
      <>
        <Text>
          {limitLongString(text, open ? text.length + 10 : CHAR_LIMIT)}
          {isMax && !open && '...'}
        </Text>
        {isMax && <ShowMore open={open} onClick={() => setOpen(prev => !prev)} />}
      </>
    );
  };

  return <Wrapper {...otherProps}>{renderContent()}</Wrapper>;
};

const Wrapper = styled('div')(({ theme }) => ({}));

const Text = styled('p')(({ theme }) => ({
  fontSize: 14,
  lineHeight: 1.6,
  fontWeight: 600,
  transition: 'all .3s',
  [theme.min(theme.breakpoints.md)]: {
    fontSize: 16,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(20),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 20,
  },
}));

const ShowMore = styled(ShowMoreButton)(({ theme }) => ({
  marginTop: 12,
  [theme.betweenDM]: {
    marginTop: theme.pxToVw(12),
  },
}));

const LoadingBg = styled(LoadingBackground)({
  '&:nth-child(1)': {
    width: '100%',
  },
  '&:nth-child(2)': {
    width: '80%',
  },
  '&:nth-child(3)': {
    width: '92%',
  },
  '&:nth-child(4)': {
    width: '30%',
  },
  '&:not(:last-child)': {
    marginBottom: 8,
  },
});
