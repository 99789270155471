import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { useProjectUtils } from '../../../hooks/useProjectUtils';
import { useEndorsmentsByProjectId } from '../../../../../../react-query/user/useEndorsments';
import { useUser } from '../../../../../../react-query/user/useUser';
import { useProjectByParamId } from '../../../../../../react-query/project/useProject';
import { useRequest } from '../../../../../../hooks/useRequest';
import { useAppMessage } from '../../../../../../providers/AppMessageProvider/AppMessageProvider';

import { ButtonPrimary, ButtonSize } from '../../../../../../components/ui/buttons';
import { ButtonGray } from '../../../../../../components/ui/buttons/ButtonGray';

import { ReactComponent as CheckIcon } from './svgs/Check.svg';
import { UserApi } from '../../../../../../api/UserApi/UserApi';
import { QueryKey } from '../../../../../../react-query/query-keys';

import { AppMessage } from '../../../../../../types';

export const EndorseButton: React.FC = () => {
  const queryClient = useQueryClient();

  const { isOwner } = useProjectUtils();
  const { data: endorsments, isLoading: endorsementsIsLoading } =
    useEndorsmentsByProjectId();
  const { data: user, isLoading: userIsLoading } = useUser();
  const { data: project, isLoading: projectIsLoading } = useProjectByParamId();
  const { toast } = useAppMessage();

  const { t } = useTranslation();

  const isEndorsed =
    user && endorsments && endorsments.some(endorse => endorse.userUid === user.uid);

  const dataLoading = endorsementsIsLoading || userIsLoading || projectIsLoading;

  const onEndorseChange = async () => {
    if (!project) return;
    if (!user) return toast(AppMessage.RegisterToEndorse);

    if (isEndorsed) {
      await UserApi.removeEndorsement(project.uid);
    } else {
      await UserApi.addEndorsement(project.uid);
    }

    await Promise.all([
      queryClient.invalidateQueries(QueryKey.Project(project.contractAddress)),
      queryClient.invalidateQueries(QueryKey.ProjectEndorsments(project.uid)),
      queryClient.invalidateQueries(QueryKey.ProjectEndorsmentsCount(project.uid)),
      queryClient.invalidateQueries(QueryKey.PopularProjects()),
    ]);
  };

  const { call, isLoading } = useRequest(onEndorseChange);

  if (isOwner) return null;

  return (
    <Wrapper>
      {isEndorsed ? (
        <ButtonGray
          onClick={call}
          width={169}
          size={ButtonSize.Sm}
          isLoading={isLoading}
          showSpinner={false}
          disabled={dataLoading}
        >
          {t('common.endorsed')}{' '}
          <CheckIcon className='check-icon' style={{ marginLeft: 8 }} />
        </ButtonGray>
      ) : (
        <ButtonPrimary
          onClick={call}
          size={ButtonSize.Sm}
          showSpinner={false}
          isLoading={isLoading}
          disabled={dataLoading}
        >
          {t('common.endorse')}
        </ButtonPrimary>
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  [theme.min(theme.breakpoints.xl)]: {
    '& button': {
      fontSize: 16,
    },
  },
  [theme.betweenDM]: {
    '& button': {
      fontSize: theme.pxToVw(16),
    },
    '& .check-icon': {
      height: theme.pxToVw(18),
      width: 'auto',
    },
  },
}));
