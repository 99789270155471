import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { routes } from '../../../router/routes';
import { AppMessage } from '../../../types';
import { useAppMessage } from '../AppMessageProvider';

const Text = styled('p')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  lineHeight: 1.32,
  [theme.min(theme.breakpoints.ul)]: {
    fontSize: 16,
  },
  '& .action-text': {
    color: theme.colors.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    transition: 'all .3s',
    opacity: 1,
    '&:hover': {
      opacity: 0.85,
    },
  },
}));

export const appMessagesContentMap = {
  [AppMessage.RegisterToSeeFollowers]: () => {
    const navigate = useNavigate();
    const { clear } = useAppMessage();

    const onClick = () => {
      navigate(routes.auth.register());
      clear(AppMessage.RegisterToSeeFollowers);
    };

    return (
      <Text>
        Please{' '}
        <span className='action-text' onClick={onClick}>
          Register
        </span>{' '}
        first, in order to see followers.
      </Text>
    );
  },
  [AppMessage.SocialNetworksNotAdded]: () => {
    const navigate = useNavigate();
    const { clear } = useAppMessage();

    const onClick = () => {
      navigate(routes.auth.register());
      clear(AppMessage.SocialNetworksNotAdded);
    };

    return (
      <Text>
        Socal Networks are not added for this profile. If you recognize your address,
        please{' '}
        <span className='action-text' onClick={onClick}>
          Register
        </span>{' '}
        in order to Edit profile.
      </Text>
    );
  },

  [AppMessage.RegisterToFollow]: () => {
    const navigate = useNavigate();
    const { clear } = useAppMessage();

    const onClick = () => {
      navigate(routes.auth.register());
      clear(AppMessage.RegisterToFollow);
    };

    return (
      <Text>
        Please{' '}
        <span className='action-text' onClick={onClick}>
          Register
        </span>{' '}
        first, in order to follow profiles.
      </Text>
    );
  },

  [AppMessage.RegisterToEndorse]: () => {
    const navigate = useNavigate();
    const { clear } = useAppMessage();

    const onClick = () => {
      navigate(routes.auth.register());
      clear(AppMessage.RegisterToFollow);
    };

    return (
      <Text>
        Please{' '}
        <span className='action-text' onClick={onClick}>
          Register
        </span>{' '}
        first, in order to endorse projects.
      </Text>
    );
  },
  [AppMessage.RegisterToSeeEndorsments]: () => {
    const navigate = useNavigate();
    const { clear } = useAppMessage();

    const onClick = () => {
      navigate(routes.auth.register());
      clear(AppMessage.RegisterToSeeFollowers);
    };

    return (
      <Text>
        Please{' '}
        <span className='action-text' onClick={onClick}>
          Register
        </span>{' '}
        first, in order to see endorsments.
      </Text>
    );
  },
  [AppMessage.RegisterOrSignInToReportAddress]: () => {
    const navigate = useNavigate();
    const { clear } = useAppMessage();

    const onClick = (register?: boolean) => {
      navigate(routes.auth[register ? 'register' : 'login']());
      clear(AppMessage.RegisterOrSignInToReportAddress);
    };

    return (
      <Text>
        Please{' '}
        <span className='action-text' onClick={() => onClick(true)}>
          Register
        </span>{' '}
        or{' '}
        <span className='action-text' onClick={() => onClick()}>
          Sign in
        </span>
        , in order to report an address.
      </Text>
    );
  },
};
