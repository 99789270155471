import React from 'react';
import styled, { CSSObject } from 'styled-components';
import { BackgroundImage } from '../../../assets';

interface Props {
  position: 'left' | 'right' | 'center';
  imgHeight?: string | number;
}

export const BackgroundBlockemLogo: React.FC<Props> = ({
  position = 'center',
  imgHeight,
  ...otherProps
}) => {
  return (
    <Wrapper {...otherProps}>
      <Image
        src={BackgroundImage.BlockemLogoBgPng}
        position={position}
        imgHeight={imgHeight}
      />
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  position: 'absolute',
  zIndex: -1,
}));

const getPosititionCss = (position: Props['position']): CSSObject => {
  switch (position) {
    case 'center':
      return {
        left: '50%',
        transform: 'translate(-50%,-50%)',
      };
    case 'left': {
      return {
        left: '10%',
        transform: 'translateY(-50%)',
      };
    }
    case 'right': {
      return {
        right: '10%',
        transform: 'translateY(-50%)',
      };
    }
    default:
      return {};
  }
};

const Image = styled('img')<Props>(({ theme, position, imgHeight }) => ({
  width: 'auto',
  maxWidth: 638,
  height: imgHeight || '70%',
  position: 'absolute',
  top: '55%',
  ...getPosititionCss(position),
  [theme.betweenDM]: {
    maxWidth: theme.pxToVw(638),
    height: 'auto',
  },
}));
