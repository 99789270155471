import React from 'react';

import { useProfileUtils } from '../../../pages/profile/hooks/useProfileUtils';
import { useRequest } from '../../../hooks/useRequest';
import { useFollowUtils } from '../../../react-query/user/useFollowUtils';
import { useAuth } from '../../../providers/AuthProvider';
import { useProfileByParamId } from '../../../react-query/profile/useProfile';
import { useAppMessage } from '../../../providers/AppMessageProvider/AppMessageProvider';
import { useUser } from '../../../react-query/user/useUser';

import { LoadingKey, useLoading } from '../../../providers/LoadingProvider';

import { UserApi } from '../../../api/UserApi/UserApi';

import { AppMessage } from '../../../types';
import { useQueryClient } from 'react-query';
import { QueryKey } from '../../../react-query/query-keys';
import { FollowButtonBase } from '../../ui/buttons/FollowButtonBase';

interface Props {
  address?: string;
  userUid?: string;
}

export const FollowUserProfileButton: React.FC<Props> = ({
  address,
  userUid,
  ...otherProps
}) => {
  const queryClient = useQueryClient();
  const { setQueriesOnUnfollow, setQueriesOnFollow } = useFollowUtils();
  const { isFollowedAddress } = useProfileUtils();
  const { isAuthenticated } = useAuth();
  const { data: profile } = useProfileByParamId();
  const { loading } = useLoading();
  const { toast } = useAppMessage();
  const { data: user } = useUser();

  const isFollowed = isFollowedAddress((address || userUid) as string);

  const onClick = async () => {
    if (!isAuthenticated) return toast(AppMessage.RegisterToFollow);

    if (!address && !userUid) return;

    if (isFollowed) {
      await UserApi.unfollow((address! || userUid!) as string);
      await setQueriesOnUnfollow({
        userUid: userUid || profile?.uid || undefined,
        address: !profile?.uid && !userUid ? profile?.addresses[0] : undefined,
      });
    } else {
      await UserApi.follow((address || userUid) as string);
      await setQueriesOnFollow({
        userUid: userUid || profile?.uid || undefined,
        address: !profile?.uid && !userUid ? profile?.addresses[0] : undefined,
      });
    }

    if (address && profile?.addresses.includes(address)) {
      await queryClient.invalidateQueries(QueryKey.ProfileFollowers(profile.uid), {
        refetchInactive: true,
      });
    }
  };

  const { call, isLoading: requestLoading } = useRequest(onClick);

  const isLoading =
    requestLoading ||
    (loading[LoadingKey.FollowAll] && profile?.addresses.some(addr => addr === address));

  if (
    user &&
    ((userUid && user.uid === userUid) ||
      (address && user.addresses.some(a => a === address)) ||
      (profile && profile.uid === user.uid))
  )
    return null;

  return (
    <FollowButtonBase
      onClick={() => call(true)}
      isLoading={isLoading}
      isFollowed={isFollowed}
      {...otherProps}
    />
  );
};
