import { useState } from 'react';
import styled from 'styled-components';
import { MilestoneGoal } from '../../../../../../../types';
import { BaseCard } from '../../../../../../../components/ui/cards/BaseCard';
import { CheckboxItem } from '../../../../../../../components/ui/forms/Checkbox/CheckboxItem';
import MilestoneGoalForm from './MilestoneGoalForm';
import { useFulfilledChange } from './useFulfilledChange';

interface Props {
  goal: MilestoneGoal;
  milestoneUid: string;
  onGoalEdit: () => void;
}

export default function MilestoneGoalItem({ goal, milestoneUid, onGoalEdit }: Props) {
  const [open, setOpen] = useState(false);

  const { onIsFulfilledChange } = useFulfilledChange(goal, false);

  const renderContent = () => {
    if (!open) {
      return (
        <>
          <CheckboxItem checked={goal.isFulfilled} onChange={onIsFulfilledChange} />
          <Text>{goal.title}</Text>
        </>
      );
    }

    return (
      <MilestoneGoalForm
        goal={goal}
        milestoneUid={milestoneUid}
        onClose={() => setOpen(false)}
        paddingBottom
        onGoalEdit={onGoalEdit}
      />
    );
  };

  return (
    <Wrapper
      open={open}
      isFulfilled={goal.isFulfilled}
      onClick={() => setOpen(true)}
      className='goal-item-wrapper'
    >
      <ChevronRight open={open} onClick={() => setOpen(prev => !prev)} />
      {renderContent()}
    </Wrapper>
  );
}

const Wrapper = styled(BaseCard)<{ open: boolean; isFulfilled: boolean }>(
  ({ theme, open, isFulfilled }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    cursor: open ? 'auto' : 'pointer',
    padding: open ? 0 : 20,
    backgroundColor: open ? '#000' : !isFulfilled ? 'rgba(0, 101, 252, 0.2)' : '#000',
    position: 'relative',
    [theme.betweenDM]: {
      padding: theme.pxToVw(open ? 0 : 20),
    },
  })
);

const Text = styled('p')(({ theme }) => ({
  fontSize: 14,
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(14),
    fontWeight: 700,
  },
}));

const ChevronRight = ({ open, onClick }: ChevronProps & { onClick: () => void }) => (
  <ChevronWrapper
    onClick={e => {
      e.stopPropagation();
      onClick();
    }}
    open={open}
  >
    <Svg
      width='6'
      height='11'
      viewBox='0 0 6 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      open={open}
    >
      <path
        d='M6 5.00186C5.99994 5.26652 5.89476 5.52031 5.7076 5.70742L1.71577 9.69924C1.62372 9.79456 1.5136 9.87058 1.39184 9.92289C1.27009 9.97519 1.13914 10.0027 1.00663 10.0039C0.87412 10.005 0.742709 9.97977 0.620064 9.92959C0.497419 9.87941 0.385995 9.80531 0.292294 9.71161C0.198594 9.61791 0.124492 9.50649 0.0743141 9.38384C0.024136 9.2612 -0.00111378 9.12979 3.76799e-05 8.99728C0.00118914 8.86477 0.028719 8.73382 0.0810209 8.61206C0.133323 8.49031 0.209349 8.38019 0.304664 8.28813L3.59093 5.00186L0.304664 1.71559C0.209349 1.62354 0.133323 1.51342 0.0810209 1.39166C0.028719 1.26991 0.00118914 1.13895 3.76799e-05 1.00645C-0.00111378 0.873939 0.024136 0.74253 0.0743141 0.619885C0.124492 0.497239 0.198594 0.385815 0.292294 0.292114C0.385995 0.198413 0.497419 0.124311 0.620064 0.0741331C0.742709 0.0239546 0.87412 -0.00129485 1.00663 -0.000143766C1.13914 0.00100827 1.27009 0.0285389 1.39184 0.0808403C1.5136 0.133143 1.62372 0.209168 1.71577 0.304483L5.7076 4.29631C5.89476 4.48342 5.99994 4.73721 6 5.00186Z'
        fill='white'
      />
    </Svg>
  </ChevronWrapper>
);

interface ChevronProps {
  open: boolean;
}

const Svg = styled('svg')<ChevronProps>(({ theme, open }) => ({
  transform: `rotate(${open ? '-90' : '0'}deg)`,
  width: 'auto',
  [theme.betweenDM]: {
    height: theme.pxToVw(10),
  },
}));

const ChevronWrapper = styled('span')<{ open: boolean }>(({ theme, open }) => ({
  position: 'absolute',
  padding: 10,
  right: open ? 14 : 10,
  bottom: open ? 0 : 'auto',
  cursor: 'pointer',
  transition: 'all .3s',
  opacity: 1,
  zIndex: 1,
  '&:hover': {
    opacity: 0.75,
  },
  [theme.betweenDM]: {
    padding: theme.pxToVw(10),
    right: theme.pxToVw(open ? 14 : 10),
    bottom: open ? 0 : 'auto',
  },
}));
