import styled from 'styled-components';
import { ButtonBase } from './ButtonBase';

export const ButtonTransparent = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: '#fff',
  border: 'none',
  '&:hover': {
    backgroundColor: 'rgba(255,255,255, 0.1)',
  },
}));
