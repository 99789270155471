import React, { useState } from 'react';
import styled from 'styled-components';
import { defaultProfileImage } from '../../../../../assets';
import { ContentLoader } from '../../../../ui/loaders/ContentLoader';

interface Props {
  isLoading: boolean;
  imageUrl: string | null | undefined;
  onClick: () => void;
}

export const ProfileBadgeImage: React.FC<Props> = ({ isLoading, imageUrl, onClick }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <ImageWrapper>
      <Image
        loaded={imageLoaded && !isLoading}
        src={imageUrl || defaultProfileImage}
        onLoad={() => setImageLoaded(true)}
        onClick={onClick}
      />
      {(!imageLoaded || isLoading) && (
        <ContentLoader viewBox='0 0 150 150' style={{ width: '100%', height: '100%' }}>
          <rect x='0' y='0' rx='16' ry='16' width={150} height={150} />
        </ContentLoader>
      )}
    </ImageWrapper>
  );
};

const Image = styled('img')<{ loaded: boolean }>(({ theme, loaded }) => ({
  height: loaded ? '100%' : 1,
  aspectRatio: '1',
  objectFit: 'cover',
  position: !loaded ? 'absolute' : 'initial',
  opacity: loaded ? 1 : 0,
  cursor: 'pointer',
}));

const ImageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 40,
  width: 40,
  aspectRatio: '1',
  borderRadius: 10,
  overflow: 'hidden',
  [theme.min(theme.breakpoints.xs)]: {
    height: 121,
    width: 121,
    borderRadius: 16,
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    height: theme.pxToVw(121),
    width: theme.pxToVw(121),
    borderRadius: theme.pxToVw(16),
  },
}));
