import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { joiLib } from '../../../joi';
import { PatchProfileDetailsPayload } from '../../../api/types';

import i18n from '../../../i18n';
import { UrlService } from '../../../services/UrlService';

export const profileDetailsSchema = Joi.object({
  description: Joi.string().allow(null, ''),
  twitterUrl: joiLib.matchSocialAddress('twitter').allow('', null),
  discordUrl: Joi.custom((value, helper) => {
    if (value === null || value === '') return true;
    if (UrlService.isUrl(value)) {
      const msg = i18n.t('errors.invalidUrlMatch', { address: 'discord' });
      const { error } = Joi.string()
        .uri()
        .pattern(/discord/)
        .messages({
          'string.uri': msg,
          'string.pattern.base': msg,
        })
        .validate(value);

      const err = error?.details?.[0]?.message;

      //@ts-ignore
      return err ? helper.message(err) : true;
    } else {
      const { error } = Joi.string()
        .min(2)
        .messages({
          'string.min': i18n.t('errors.minCharCount', { requiredChars: '2' }),
        })
        .validate(value);

      const err = error?.details?.[0]?.message;
      //@ts-ignore
      return err ? helper.message(err) : true;
    }
  }),
  openseaUrl: joiLib.matchSocialAddress('opensea').allow('', null),
  externalUrl: Joi.string().uri({ allowRelative: true }).allow('', null),
});

export const useEditProfileDetailsForm = () => {
  const form = useForm<PatchProfileDetailsPayload & { externalUrl?: string | null }>({
    defaultValues: {
      description: null,
      twitterUrl: null,
      openseaUrl: null,
      discordUrl: null,
    },
    resolver: joiResolver(profileDetailsSchema),
    reValidateMode: 'onChange',
  });

  return form;
};
