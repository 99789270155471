import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Icon } from '../../../assets';

interface Props {
  open: boolean;
  onClick: () => void;
}

export const ShowMoreButton: React.FC<Props> = ({ onClick, open, ...otherProps }) => {
  const { t } = useTranslation();

  return (
    <Wrapper open={open} onClick={onClick} {...otherProps}>
      {t(`common.${open ? 'showLess' : 'showMore'}`)} <Icon.ChevronDownWhite />
    </Wrapper>
  );
};

const Wrapper = styled('div')<{ open: boolean }>(({ theme, open }) => ({
  transition: 'all .3s',
  cursor: 'pointer',
  color: 'rgba(255,255,255, 0.6)',
  fontSize: 14,
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    transition: 'all .3s',
    pointerEvents: 'none',
    transform: `rotate(${open ? '180deg' : '0deg'})`,
    marginLeft: 8,
    [theme.min(theme.breakpoints.lg)]: {
      marginLeft: 12,
    },
  },
  '&:hover': {
    color: 'rgba(255,255,255, 1)',
  },
  [theme.min(theme.breakpoints.md)]: {
    fontSize: 16,
  },
  [theme.min(theme.breakpoints.ul)]: {
    fontSize: 20,
  },
}));
