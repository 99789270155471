import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { routes } from '../../../../../router/routes';

import { Container } from '../../../../../components/layouts/Container';
import { useProfileByParamId } from '../../../../../react-query/profile/useProfile';
import { isValidEthAddress } from '../../../../../utils/address-utils';
import { useReputation } from '../../../../../react-query/reputation/useReputation';

export const ProfileDataNavbar: React.FC = () => {
  const { pathname, search } = useLocation();
  const { id } = useParams();
  const { t } = useTranslation('common');
  const { data: profile } = useProfileByParamId();
  const { data: reputation } = useReputation(profile?.addresses?.[0]);

  const { username } = profile || {};
  const { isContract } = reputation || {};

  const navLinks = useMemo(() => {
    const arr = [
      {
        label: t('profile'),
        url: id ? routes.profile.byId(id) : routes.profile.root(),
      },
      {
        label: 'NFTs',
        url: pathname + '?view=nfts',
      },
      {
        label: t('history'),
        url: pathname + '?view=history',
      },
    ];

    if ((isContract && isValidEthAddress(username)) || !isValidEthAddress(username)) {
      arr.push({
        label: t('projects'),
        url: pathname + '?view=projects',
      });
    }

    return arr;
  }, [t, pathname, id, username, isContract]);

  return (
    <Container>
      <Nav>
        {navLinks.map(link => (
          <StyledLink
            to={link.url}
            key={link.url}
            $isActive={pathname + search === link.url}
            replace
          >
            {link.label}
          </StyledLink>
        ))}
      </Nav>
    </Container>
  );
};

const Nav = styled('nav')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 40,
  fontWeight: 700,
  fontSize: 14,
  marginTop: 54,
  marginBottom: 24,
  [theme.min(theme.breakpoints.xs)]: {
    fontSize: 16,
    gap: 48,
  },
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: 18,
    marginBottom: 32,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(20),
    marginTop: theme.pxToVw(40),
    marginBottom: theme.pxToVw(48),
    gap: theme.pxToVw(48),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginTop: 40,
    marginBottom: 48,
    fontSize: 20,
    gap: 48,
  },
}));

const StyledLink = styled(Link)<{ $isActive: boolean }>(({ theme, $isActive }) => ({
  paddingBottom: 4,
  borderBottom: `6px solid ${$isActive ? theme.colors.primary.main : 'transparent'}`,
  transition: 'all .3s',
  cursor: 'pointer',
  color: '#fff',
  width: 'max-content',
  [theme.min(theme.breakpoints.xs)]: {
    paddingBottom: 6,
  },
  [theme.min(theme.breakpoints.lg)]: {
    paddingBottom: 9,
  },
  [theme.betweenDM]: {
    paddingBottom: theme.pxToVw(9),
    borderBottomWidth: theme.pxToVw(6),
  },
  '&:hover': {
    color: theme.colors.text.whiteTransparent70,
  },
}));
