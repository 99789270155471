import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useProfileProjectsByUsername } from '../../../../../../react-query/project/useProjects';
import { useProfileUtils } from '../../../../hooks/useProfileUtils';

import { Container } from '../../../../../../components/layouts/Container';
import { NoItemsText, ProfileDataPageTitle } from '../../components';
import { ProjectCardsGrid } from '../../../../../../components/project/ProjectCard/ProjectCardsGrid';
import { ProjectCard } from '../../../../../../components/project/ProjectCard/ProjectCard';
import { AddProjectCard } from '../../../../../../components/project/ProjectCard/AddProjectCard';

export const ProjectsView: React.FC = () => {
  const { t } = useTranslation();
  const { data: projects, isLoading } = useProfileProjectsByUsername();
  const { isOwner } = useProfileUtils();

  const renderContent = () => {
    if (!projects) return null;
    if (!projects.length && !isOwner)
      return (
        <NoItemsText>
          {t('profilePage.projectsNotAvailable', { who: 'user' })}
        </NoItemsText>
      );

    return projects.map(project => <ProjectCard key={project.uid} project={project} />);
  };

  return (
    <Container>
      <Title>{t('common.projects')}</Title>
      <Grid isLoading={isLoading}>
        {isOwner && <AddProjectCard />}
        {renderContent()}
      </Grid>
    </Container>
  );
};

const Title = styled(ProfileDataPageTitle)(({ theme }) => ({
  marginBottom: 40,
  [theme.min(theme.breakpoints.sm)]: {
    marginBottom: 16,
  },
  [theme.betweenDM]: {
    marginBottom: theme.pxToVw(16),
  },
}));

const Grid = styled(ProjectCardsGrid)(({ theme }) => ({
  paddingTop: 24,
  [theme.betweenDM]: {
    paddingTop: theme.pxToVw(24),
  },
}));
