import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { FooterLogo } from './FooterLogo';
import { ActionText } from '../../../../ui/text/ActionText';

import { envs } from '../../../../../config/envs';
import { FOOTER_MOBILE_BREAKPOINT_WIDTH } from '../../Footer';

export const FooterLogoSection: React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <Wrapper>
      <FooterLogo />
      <Col>
        <label>{t('email')}</label>
        <ActionText
          underline={false}
          onClick={() => window.open(`mailto:${envs.contactEmail}`)}
        >
          {envs.contactEmail}
        </ActionText>
      </Col>
    </Wrapper>
  );
};

const FlexRow = styled('div')({ display: 'flex' });

const FlexCol = styled(FlexRow)({ flexDirection: 'column' });

const Wrapper = styled(FlexRow)(({ theme }) => ({
  gap: '4.8rem',
  alignItems: 'center',
  fontSize: '2rem',
  [theme.betweenDM]: {
    gap: theme.pxToVw(48),
    fontSize: theme.pxToVw(20),
  },
  [theme.max(FOOTER_MOBILE_BREAKPOINT_WIDTH)]: {
    flexDirection: 'column',
    gap: 12,
    marginBottom: 32,
    fontSize: 16,
  },
}));

const Col = styled(FlexCol)(({ theme }) => ({
  gap: '0.8rem',
  '& label': {
    textTransform: 'uppercase',
    fontSize: '1.6rem',
    opacity: 0.2,
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(8),
    fontSize: theme.pxToVw(16),
  },
  [theme.max(FOOTER_MOBILE_BREAKPOINT_WIDTH)]: {
    alignItems: 'center',
    gap: 8,
    '& label': {
      fontSize: 16,
    },
  },
}));
