import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ButtonPrimary, ButtonSize, ButtonVariant } from '.';
import { Spinner } from '../loaders/Spinner';
import { Icon } from '../../../assets';

interface Props {
  onClick: () => void;
  isFollowed: boolean | undefined;
  isLoading?: boolean;
  children?: React.ReactNode;
}

export const FollowButtonBase: React.FC<Props> = ({
  onClick,
  isFollowed,
  isLoading,
  ...otherProps
}) => {
  const { t } = useTranslation('common');

  return (
    <Button
      onClick={onClick}
      disabled={isLoading}
      variant={isFollowed ? ButtonVariant.Outlined : ButtonVariant.Filled}
      size={ButtonSize.Xs}
      {...otherProps}
    >
      {isLoading ? (
        <Spinner size={16} />
      ) : isFollowed ? (
        t('unfollow')
      ) : (
        <>
          <Icon.Plus />
          {t('follow')}
        </>
      )}
    </Button>
  );
};

const Button = styled(ButtonPrimary)(({ theme, variant }) => ({
  fontSize: 14,
  borderWidth: 1,
  minWidth: 111,
  '& svg:not(.spinner-x)': {
    marginRight: 8,
  },
  [theme.min(theme.breakpoints.sm)]: {
    padding: '0 24px',
  },
  '&:hover': {
    borderWidth: 1,
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    fontSize: theme.pxToVw(14),
    minWidth: theme.pxToVw(111),
    padding: `0 ${theme.pxToVw(24)}`,
  },
}));
