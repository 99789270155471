import React, { useCallback, useContext, useState } from 'react';
import { AppMessage } from '../../types';

export interface IAppMessageContext {
  clearAll: () => void;
  clear: (index: number) => void;
  toast: (appMessage: AppMessage) => void;
  appMessages: AppMessage[];
}

const AppMessageContext = React.createContext<IAppMessageContext | undefined>(
  undefined
);

export const useAppMessage = (): IAppMessageContext => {
  const context = useContext(AppMessageContext);

  if (!context) {
    throw new Error('useAppMessage cannot be used without AppMessageContext');
  }

  return context;
};

interface Props {
  children: React.ReactNode;
}

export const AppMessagesProvider: React.FC<Props> = ({ children }) => {
  const [appMessages, setAppMessages] = useState<AppMessage[]>([]);

  const toast = useCallback(
    (appMessage: AppMessage) =>
      setAppMessages(prev => [...new Set([appMessage, ...prev])]),
    []
  );

  const clearAll = useCallback(() => setAppMessages([]), []);

  const clear = useCallback(
    (appMessage: AppMessage) =>
      setAppMessages(prev => prev.filter(n => n !== appMessage)),
    []
  );

  return (
    <AppMessageContext.Provider value={{ toast, clearAll, clear, appMessages }}>
      {children}
    </AppMessageContext.Provider>
  );
};
