import React from 'react';
import styled from 'styled-components';
import { limitLongString } from '../../../utils/string-utils';

interface CircleElProps {
  isActive: boolean;
  text: string | number | React.ReactNode;
  bgColor?: string;
  boxShadow?: boolean;
  size: {
    desktop: number;
    mobile: number;
  };
}

interface LabelElProps {
  text: string;
  shortenAt?: {
    mobile?: number;
    desktop?: number;
  };
}

interface Props {
  circleProps: CircleElProps;
  labelProps: LabelElProps;
  onClick?: () => void;
  fontSize: {
    desktop: number;
    mobile: number;
  };
}

export default function MilestoneLabel({
  circleProps,
  labelProps,
  fontSize,
  onClick,
}: Props) {
  return (
    <Wrapper onClick={onClick}>
      <MileStoneCircleBase {...circleProps} fontSize={fontSize}>
        {circleProps.text}
      </MileStoneCircleBase>
      <Label {...labelProps} fontSize={fontSize}>
        {limitLongString(labelProps.text, 28, true)}
      </Label>
    </Wrapper>
  );
}

const Wrapper = styled('div')(({ theme, onClick }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  cursor: typeof onClick === 'function' ? 'pointer' : undefined,
  transition: 'all .3s',
  opacity: 1,
  '&:hover': {
    opacity: typeof onClick === 'function' ? 0.7 : 1,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(10),
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 10,
  },
}));

export const MileStoneCircleBase = styled('div')<
  CircleElProps & { fontSize: Props['fontSize'] }
>(({ theme, size, fontSize, isActive, bgColor, boxShadow }) => ({
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: size.mobile,
  fontSize: fontSize.mobile,
  backgroundColor: bgColor ? bgColor : isActive ? theme.colors.primary.main : '#3D4859',
  color: '#fff',
  aspectRatio: '1',
  fontWeight: 700,
  boxShadow: boxShadow ? '0px 0px 5px 2px rgba(0, 101, 252, 0.45)' : 'none',
  [theme.min(theme.breakpoints.desktop)]: {
    height: theme.pxToVw(size.desktop),
    fontSize: theme.pxToVw(fontSize.desktop),
  },
  [theme.min(theme.breakpoints.max)]: {
    height: size.desktop,
    fontSize: fontSize.desktop,
  },
}));

const Label = styled('span')<LabelElProps & { fontSize: Props['fontSize'] }>(
  ({ theme, fontSize }) => ({
    fontWeight: '900',
    color: '#fff',
    fontSize: fontSize.mobile,
    [theme.min(theme.breakpoints.desktop)]: {
      fontSize: theme.pxToVw(fontSize.desktop),
    },
    [theme.min(theme.breakpoints.max)]: {
      fontSize: fontSize.desktop,
    },
  })
);
