import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { PopoverProps } from 'react-tiny-popover';

import { useProjectUtils } from '../../../hooks/useProjectUtils';
import { useRequest } from '../../../../../../hooks/useRequest';
import { useUser } from '../../../../../../react-query/user/useUser';

import { routes } from '../../../../../../router/routes';
import { Project } from '../../../../../../types';
import { UserApi } from '../../../../../../api/UserApi/UserApi';

import { QueryKey } from '../../../../../../react-query/query-keys';
import { DotsDropdownMenu } from '../../../../../../components/ui/menus/DopdownMenu/DotsDropdownMenu/DotsDropdownMenu';
import { ConfirmationModal } from '../../../../../../components/ui/modals/ConfirmationModal/ConfirmationModal';

interface Props extends Omit<PopoverProps, 'children' | 'content'> {
  project: Project | undefined;
  children?: React.ReactNode;
  onToggle: () => void;
}

export const ProjectMenu = React.forwardRef<HTMLElement, Props>(
  ({ project, ...otherProps }, ref) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    const { data: user } = useUser();
    const { isProjectOwner } = useProjectUtils();

    const [confirmOpen, setConfirmOpen] = useState(false);

    const onDelete = async (projectUid: string) => {
      if (!project || !user) return;
      await UserApi.deleteProject(projectUid);
      await Promise.all([
        queryClient.invalidateQueries(QueryKey.UserProjects()),
        queryClient.invalidateQueries(QueryKey.Project(project.contractAddress)),
        queryClient.invalidateQueries(QueryKey.ProjectDetails(project.contractAddress)),
      ]);
      navigate(routes.profile.byId(user.username));
    };

    const { call } = useRequest(onDelete);

    if (!project || !isProjectOwner(project.contractAddress)) return null;

    return (
      <>
        <DotsDropdownMenu
          ref={ref}
          menuItems={[
            {
              label: t('common.projectDetails'),
              onClick: () => navigate(routes.project.editById(project!.uid)),
            },
            {
              label: t('common.deleteProject'),
              onClick: () => setConfirmOpen(true),
            },
          ]}
          {...otherProps}
        />
        {confirmOpen && (
          <ConfirmationModal
            onConfirm={() => call(project!.uid)}
            onCancel={() => {
              setConfirmOpen(false);
            }}
            title={t('confirm.removeProject.title')}
            description={t('confirm.removeProject.text', { name: `"${project!.title}"` })}
          />
        )}
      </>
    );
  }
);
