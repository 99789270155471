import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useDocumentTitle } from '../../../hooks/useDocumentTitle';

import { routes } from '../../../router/routes';

import { AuthLayout } from '../../../components/layouts/AuthLayout/AuthLayout';
import { ResetPasswordForm } from '../../../components/forms/reset-password/ResetPasswordForm';
import { TextWithActionConcated } from '../components/TextWithActionConcated';

export const ResetPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useDocumentTitle(t('auth.resetPassword'));

  return (
    <AuthLayout title={t('auth.resetPassword')} bgLogoPosition='left'>
      <ResetPasswordForm onSuccess={() => navigate(routes.auth.login())} />
      <TextWithActionConcated
        text={t('common.goBack')}
        align='center'
        actionText={t('auth.signin')}
        onClick={() => navigate(routes.auth.login())}
      />
    </AuthLayout>
  );
};
