import React, { useContext, useEffect, useState } from 'react';
import { envs } from '../config/envs';

export interface ExtensionContext {
  isInstalled: boolean;
}

const BlockemExtensionContext = React.createContext<ExtensionContext | undefined>(
  undefined
);

export const useBlockemExtension = (): ExtensionContext => {
  const context = useContext(BlockemExtensionContext);

  if (!context) {
    throw new Error('useBlockemExtension cannot be used without BlockemExtensionContext');
  }

  return context;
};

interface Props {
  children: React.ReactNode;
}

export const BlockemExtensionProvider: React.FC<Props> = ({ children }) => {
  const [isInstalled, setIsInstalled] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (window.chrome) {
      // @ts-ignore
      const chrome = window.chrome;
      if (typeof chrome?.runtime?.sendMessage !== 'function') return;
      chrome.runtime.sendMessage(
        envs.extensionId,
        'IsExtensionInstalled',
        (response: boolean) => {
          if (response) setIsInstalled(response);
        }
      );
    }
  }, []);

  return (
    <BlockemExtensionContext.Provider value={{ isInstalled }}>
      {children}
    </BlockemExtensionContext.Provider>
  );
};
