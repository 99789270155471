import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useWindowWidth } from '../../../hooks/useWindowWidth';

import { NotEditableInputPlaceholder } from '../../ui/forms/NotEditableInputPlaceholder/NotEditableInputPlaceholder';

import { shortenEthAddress } from '../../../utils/address-utils';

export const ProjectContractAddress: React.FC = () => {
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const width = useWindowWidth();

  return (
    <NotEditableInputPlaceholder
      label={t('common.contractAddress')}
      value={
        width > 400
          ? getValues('contractAddress')
          : shortenEthAddress(getValues('contractAddress'), {
              startChars: width > 360 ? 31 : 24,
              placeholderCount: 3,
            })
      }
    />
  );
};
