import React from 'react';
import styled from 'styled-components';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { envs } from '../../../../config/envs';

import { ActionText, ActionTextVariant } from '../../text/ActionText';
import { CheckboxItem } from '../Checkbox/CheckboxItem';
import { FormFieldWrapper } from '../FormFieldWrapper/FormFieldWrapper';

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 3,
  fontSize: 14,
  padding: '8px 0',
  [theme.betweenDM]: {
    gap: theme.pxToVw(3),
    fontSize: theme.pxToVw(14),
    padding: `${theme.pxToVw(8)} 0`,
  },
}));

interface Props {
  onChange: (val: boolean) => void;
  checked: boolean;
  error?: string | FieldError | Error;
}

export const AgreeToTerms: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { error } = props;

  return (
    <FormFieldWrapper
      error={
        typeof error === 'string' || typeof error === 'boolean' ? error : error?.message
      }
    >
      <Wrapper>
        <CheckboxItem {...props} error={!!props.error} /> &nbsp;
        <span>{t('auth.agreeToOur')}</span>
        <ActionText
          variant={ActionTextVariant.Inverted}
          onClick={() =>
            window.open(`${envs.blockemWebsiteUrl}/terms-of-service`, '_blank')
          }
        >
          {t('common.termsOfService')}
        </ActionText>
      </Wrapper>
    </FormFieldWrapper>
  );
};
