import React from 'react';

import { ProjectDescription } from './ProjectDescription';
import { ProjectOwner } from './ProjectOwner/ProjectOwner';
import { ProjectSocialNetworks } from './ProjectSocialNetworks';
import { ProjectTitle } from './ProjectTitle';
import { ProjectContractAddress } from './ProjectContractAddress';

export const ProjectDetailsForm: React.FC = () => {
  return (
    <>
      <ProjectTitle />
      <ProjectContractAddress />
      <ProjectOwner />
      <ProjectDescription />
      <ProjectSocialNetworks />
    </>
  );
};
