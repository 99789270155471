import React, { useCallback, useEffect } from 'react';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { useRequest } from '../../../hooks/useRequest';
import { useAuth } from '../../../providers/AuthProvider';
import { useRegisterForm } from './useRegisterForm';
import { useWalletUtils } from '../../../hooks/useWalletUtils';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';

import { AuthApi, RegisterPayload, RegisterResponse } from '../../../api/AuthApi';
import { StorageKeys, StorageService } from '../../../services/StorageService';
import { routes } from '../../../router/routes';

import { ConnectWalletButton } from '../../../components/common/ConnectWalletButton/ConnectWalletButton';
import { ButtonPrimary } from '../../../components/ui/buttons';
import { AgreeToTerms } from '../../../components/ui/forms/AgreeToTerms/AgreeToTerms';
import { InputField } from '../../../components/ui/forms/Input/InputField';
import { PasswordField } from '../../../components/ui/forms/Input/PasswordField';
import { AuthLayout } from '../../../components/layouts/AuthLayout/AuthLayout';
import { TextWithActionConcated } from '../components/TextWithActionConcated';
import { AnalyticsEvent, AnalyticsService } from '../../../services/AnalyticsService';

import { withProviders } from '../../../components/hoc/withProviders';
import { StepProvider } from '../../../providers/StepProvider';
import { ProjectOnboardWizzardProgress } from '../../../components/project/ProjectOnboardWizzardProgress/ProjectOnboardWizzardProgress';

export const RegisterPage: React.FC = withProviders(StepProvider)(() => {
  const { t } = useTranslation();
  const { setIsAuthenticated } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  useDocumentTitle(t('auth.register'));

  const {
    formState: { errors, isValid },
    watch,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    reset,
    clearErrors,
  } = useRegisterForm();

  const { wallets, addWallet, removeWallet, setWallets } = useWalletUtils();

  const parsed = qs.parse(window.location.search.replace('?', ''));
  const { address, signature, contractAddress } = parsed;

  useEffect(() => {
    if (typeof address === 'string' && typeof signature === 'string') {
      setWallets([{ address, signature }]);
    }
  }, [setWallets, setValue, address, signature]);

  useEffect(() => {
    // for validation only
    setValue('addresses', wallets, {
      shouldDirty: true,
      shouldValidate: !!wallets.length,
    });
  }, [wallets, setValue]);

  const onSubmit = useCallback(async (): Promise<any> => {
    if (Object.keys(errors).length || !isValid) return;
    const terms = getValues('terms');
    if (!terms || !wallets.length) return;
    const { username, password, phoneNumber, email } = getValues();
    clearErrors();

    const payload: RegisterPayload = {
      username,
      password,
      addresses: wallets,
    };

    if (phoneNumber) payload.phoneNumber = `+${phoneNumber.replace('+', '')}`;
    if (email) payload.email = email;

    return await AuthApi.register(payload);
  }, [getValues, wallets, errors, isValid, clearErrors]);

  const { call, isLoading } = useRequest<RegisterResponse>(onSubmit, {
    onSuccess: ({ token }) => {
      StorageService.save(StorageKeys.Token, `Bearer ${token}`);
      const proj = StorageService.read(StorageKeys.ProjectRedirect);
      if (proj) {
        AnalyticsService.userEvent({
          action: AnalyticsEvent.RegisterViaRedirect,
          label: proj,
        });
        StorageService.remove(StorageKeys.ProjectRedirect);
      }
      setIsAuthenticated(true);
      if (address && signature) {
        queryClient.resetQueries();
      }

      AnalyticsService.fbq('track', 'CompleteRegistration', {
        content_name: getValues('username'),
        proj: proj ? proj : '',
      });

      AnalyticsService.twq('track', 'Lead', {
        conversion_id: getValues('username'),
        description: proj ? proj : '',
      });

      reset();
      if (contractAddress) {
        navigate(routes.projectOnboard.root() + `?${contractAddress}`, {
          state: { newUser: true },
        });
      } else {
        navigate(routes.profile.root(), { state: { newUser: true } });
      }
    },
    onError: err => {
      const msg = err?.response?.data?.message || err?.message;

      const messages = [
        'USERNAME_ALREADY_EXISTS',
        'ADDRESS_ALREADY_CONNECTED',
        'INVALID_SIGNATURE',
      ];

      if (messages.includes(msg)) {
        const errText = t(`errors.${msg}`);
        if (msg === messages[0]) {
          return setError('username', { message: errText });
        }

        setError('addresses', { message: errText });
      }
    },
  });

  return (
    <AuthLayout
      title={t('auth.register')}
      onSubmit={handleSubmit(call)}
      bgLogoPosition='right'
      Wizzard={contractAddress ? <ProjectOnboardWizzardProgress /> : undefined}
    >
      <InputField
        required
        placeholder={t('auth.username') + '*'}
        label={t('auth.username')}
        error={errors.username?.message}
        {...register('username')}
      />
      <PasswordField
        required
        placeholder={t('auth.password') + '*'}
        label={t('auth.password')}
        error={errors.password}
        {...register('password')}
      />
      <PasswordField
        required
        placeholder={t('auth.repeatPassword') + '*'}
        label={t('auth.repeatPassword')}
        error={errors.confirmPassword}
        {...register('confirmPassword')}
      />
      <ConnectWalletButton
        addresses={wallets.map(w => w.address)}
        error={errors?.addresses?.message}
        disableRemoveLastWallet={false}
        onWalletConnect={addWallet}
        onRemove={removeWallet}
      />
      <AgreeToTerms
        checked={watch('terms')}
        onChange={val => setValue('terms', val, { shouldValidate: true })}
        error={errors.terms?.message}
      />
      <ButtonPrimary width='100%' onClick={call} isLoading={isLoading}>
        {t(contractAddress ? 'projectOnboard.cta0' : 'auth.register')}
      </ButtonPrimary>
      <TextWithActionConcated
        text={t('auth.alreadyAMember')}
        align='center'
        actionText={t('auth.signin')}
        disabled={isLoading}
        onClick={() => navigate(routes.auth.login())}
      />
    </AuthLayout>
  );
});
