import React, { useState } from 'react';
import styled from 'styled-components';

import {
  ButtonPrimary,
  ButtonSize,
  ButtonVariant,
} from '../../../../../components/ui/buttons';
import {
  DropdownMenuBase,
  DropdownMenuVariant,
} from '../../../../../components/ui/menus/DopdownMenu/DropdownMenuBase';

interface Props {
  interval: string;
  onChange: (value: string) => void;
}

const menuItems = [
  { label: 'All Time', value: 'all' },
  { label: 'Last Week', value: 'week' },
  { label: 'Last Month', value: 'month' },
  { label: 'Last Year', value: 'year' },
];

export const ChartFilters: React.FC<Props> = ({ interval, onChange }) => {
  const [open, setOpen] = useState(false);

  return (
    <Wrapper>
      <ButtonsWrapper>
        {menuItems.map(({ label, value }) => (
          <ButtonPrimary
            key={label}
            variant={ButtonVariant[interval === value ? 'Filled' : 'Outlined']}
            onClick={() => onChange(value)}
            size={ButtonSize.Xs}
            showSpinner={false}
          >
            {label}
          </ButtonPrimary>
        ))}
      </ButtonsWrapper>
      <DropdownMenuWrapper>
        <DropdownMenuBase
          menuItems={menuItems.map(item => ({
            ...item,
            onClick: () => onChange(item.value),
            active: interval === item.value,
          }))}
          label={menuItems.find(item => item.value === interval)?.label!}
          isOpen={open}
          onToggle={() => setOpen(prev => !prev)}
          menuVariant={DropdownMenuVariant.Secondary}
        />
      </DropdownMenuWrapper>
      <DatePickersWrapper></DatePickersWrapper>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  marginBottom: 30,
  [theme.min(theme.breakpoints.md)]: {
    marginBottom: 36,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    marginBottom: theme.pxToVw(42),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginBottom: 42,
  },
}));

const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'none',
  gap: 8,
  [theme.min(theme.breakpoints.md)]: {
    display: 'flex',
  },
}));

const DropdownMenuWrapper = styled('div')(({ theme }) => ({
  [theme.min(theme.breakpoints.md)]: {
    display: 'none',
  },
}));

const DatePickersWrapper = styled('div')(({ theme }) => ({}));
