import styled from 'styled-components';
import { MilestoneGoal } from '../../../../../../../../types';
import DocumentSvg from './DocumentSvg';
import { DoneIcon } from './DoneIcon';
import RelatedUrlsIcon from './RelatedUrlsIcon';

interface Props {
  goal: MilestoneGoal;
  onClose: () => void;
}

export default function GoalDetailsPopup({ goal, onClose }: Props) {
  return (
    <Wrapper>
      <Xmark onClick={onClose} />
      <Header>
        <Title>{goal.title}</Title>
        {goal.isFulfilled && <DoneIcon />}
      </Header>
      <Text>{goal.description}</Text>
      {goal.relatedUrl && (
        <div>
          <Label>Related URLs</Label>
          <RelatedUrlsIcon onClick={() => window.open(goal.relatedUrl!, '_blank')} />
        </div>
      )}
      {!!goal?.documentUrls?.length && (
        <div>
          <Label>Related Documents</Label>
          <DocsWrapper>
            {goal.documentUrls.map((url, i) => (
              <DocWrapper key={url} onClick={() => window.open(url, '_blank')}>
                <DocumentSvg />
                <SmallText>Doc {i + 1}</SmallText>
              </DocWrapper>
            ))}
          </DocsWrapper>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: '#171717',
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  position: 'relative',
  width: '90vw',
  maxWidth: 541,
  borderRadius: 12,
  padding: '20px 40px 20px 20px',
}));

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 6,
  marginBottom: 2,
}));

const DocsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: 12,
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(18),
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 18,
  },
}));

const DocWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  cursor: 'pointer',
  transition: 'all .3s',
  '&:hover': {
    opacity: 0.75,
  },
}));

const SmallText = styled('p')(({ theme }) => ({
  fontSize: 12,
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(12),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 12,
  },
}));

const Text = styled('p')(({ theme }) => ({
  fontSize: 14,
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(16),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 16,
  },
}));

const Title = styled(Text)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 700,
  color: theme.colors.primary.main,
}));

const Label = styled(SmallText)(({ theme }) => ({
  marginBottom: 8,
  color: theme.colors.primary.main,
}));

const Xmark = ({ onClick }: { onClick: () => void }) => {
  return (
    <XmarkWrapper onClick={onClick}>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{ pointerEvents: 'none' }}
      >
        <path
          d='M6.66921 8.00012L0.275992 1.60679C-0.0919971 1.2388 -0.0919971 0.643992 0.275992 0.275997C0.643981 -0.0919989 1.23878 -0.0919989 1.60677 0.275997L7.99998 6.66933L14.3932 0.275997C14.7612 -0.0919989 15.356 -0.0919989 15.724 0.275997C16.092 0.643992 16.092 1.2388 15.724 1.60679L9.33079 8.00012L15.724 14.3935C16.092 14.7615 16.092 15.3563 15.724 15.7243C15.5405 15.9078 15.2996 16 15.0586 16C14.8177 16 14.5768 15.9078 14.3932 15.7243L8.00002 9.33092L1.6068 15.7243C1.42327 15.9078 1.18234 16 0.94141 16C0.70048 16 0.459549 15.9078 0.276024 15.7243C-0.0919666 15.3563 -0.0919666 14.7615 0.276024 14.3935L6.66921 8.00012Z'
          fill='white'
        />
      </svg>
    </XmarkWrapper>
  );
};

const XmarkWrapper = styled('span')(({ theme }) => ({
  transition: 'all .3s',
  cursor: 'pointer',
  marginLeft: 'auto',
  position: 'absolute',
  top: 20,
  right: 20,
  padding: 4,
  opacity: 1,
  '&:hover': {
    opacity: 0.75,
  },
  [theme.betweenDM]: {
    '& svg': {
      height: theme.pxToVw(16),
      width: 'auto',
    },
  },
}));
