import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { joiLib } from '../../../joi';

const schema = Joi.object({
  password: Joi.string().required(),
  confirmPassword: joiLib.confirmPassword().required(),
  addresses: Joi.any(),
});

export const useResetPasswordForm = () => {
  const form = useForm<{
    password: string;
    confirmPassword: string;
    addresses: { address: string; signature: string }[];
  }>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: joiResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  return form;
};
