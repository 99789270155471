import React from 'react';

import { useProfileUtils } from '../../hooks/useProfileUtils';

import { EditProfileButton } from './EditProfileButton/EditProfileButton';
import { ProfileInfoSocialIcons } from './SocialIcons/ProfileInfoSocialIcons';
import { ProfileInfoUsername } from './UserName/ProfileInfoUsername';
import { ConnectedAddresses } from './ConnectedAddresses/ConnectedAddresses';
import { ProfileInfoDescription } from './Description/ProfileInfoDescription';
import { ProfileInfoFollowers } from './Followers/ProfileInfoFollowers';

import Styled from './ProfileInfo.styles';

export const ProfileInfo: React.FC = () => {
  const { isClaimedAddress } = useProfileUtils();

  return (
    <Styled.Card>
      <ProfileInfoUsername />
      {isClaimedAddress && <ConnectedAddresses />}
      <ProfileInfoFollowers />
      <ProfileInfoDescription />
      <Styled.SocialIconsWrapper>
        <ProfileInfoSocialIcons />
      </Styled.SocialIconsWrapper>
      <Styled.FollowersWrapper></Styled.FollowersWrapper>
      <EditProfileButton />
    </Styled.Card>
  );
};
