import { FormProvider } from 'react-hook-form';
import styled from 'styled-components';
import { useRoadmapState } from '../../RoadmapStateProvider';
import { useProjectMilestonesByParamId } from '../../../../../../react-query/user/project/useProjectMilestones';
import { Milestone } from '../../../../../../types';

import MilestoneForm from './MilestoneForm';
import MilestoneLabel from '../../../../../../components/project/roadmap/MilestoneLabel';
import { useMilestoneForm } from './useMilestoneForm';

interface Props {
  milestone?: Milestone | null;
}

export default function MilestoneFormItem({ milestone }: Props) {
  const { mode } = useRoadmapState();
  const { data: milestones } = useProjectMilestonesByParamId();

  const methods = useMilestoneForm(
    milestone || undefined,
    mode === 'create' && milestones ? milestones.length + 1 : undefined
  );

  return (
    <Wrapper>
      <div style={{ zIndex: -1, position: 'relative' }}>
        <MilestoneLabel
          fontSize={{ mobile: 16, desktop: 20 }}
          circleProps={{
            text: methods.watch('orderNumber'),
            isActive: true,
            size: {
              mobile: 38,
              desktop: 47,
            },
          }}
          labelProps={{
            text: methods.watch('title'),
          }}
        />
      </div>
      <FormProvider {...methods}>
        <FormContent>
          <MilestoneForm />
        </FormContent>
      </FormProvider>
    </Wrapper>
  );
}

const Wrapper = styled('div')(({ theme }) => ({}));

const FormContent = styled('div')(({ theme }) => ({
  paddingTop: 14,
  [theme.min(theme.breakpoints.sm)]: {
    paddingLeft: 60,
  },
  [theme.betweenDM]: {
    paddingLeft: theme.pxToVw(60),
    paddingTop: theme.pxToVw(14),
  },
}));
