import styled, { CSSObject } from 'styled-components';
import { ButtonVariant } from '.';
import { Theme } from '../../../config/theme';
import { ButtonBase } from './ButtonBase';

const getButtonStyles = (
  theme: Theme,
  variant: ButtonVariant,
  disabled?: boolean
): CSSObject => ({
  backgroundColor: disabled
    ? 'transparent'
    : variant === ButtonVariant.Outlined
    ? 'transparent'
    : 'rgba(255, 255, 255, 0.1)',
  color: disabled ? 'rgba(255, 255, 255, 0.5)' : theme.colors.text.primary,
  border:
    variant === ButtonVariant.Filled ? 'none' : `2px solid rgba(255, 255, 255, 0.1)`,
  '&:hover': {
    background:
      variant === ButtonVariant.Filled
        ? 'rgba(255, 255, 255, 0.25)'
        : 'rgba(255, 255, 255, 0.25)',
    border: variant === ButtonVariant.Filled ? 'none' : `rgba(255, 255, 255, 0.5)`,
  },
});

export const ButtonGray = styled(ButtonBase)<{ variant?: ButtonVariant }>(
  ({ theme, disabled, variant = ButtonVariant.Filled }) => ({
    ...getButtonStyles(theme, variant, disabled),
  })
);
