import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { envs } from '../../../../config/envs';

import { useAddress } from '../../../../providers/AddressProvider/AddressProvider';
import { useReputationWithAddress } from '../../../../providers/AddressProvider/useReputationWithAddress';

import { LoadingProvider } from '../../../../providers/LoadingProvider';
import { AnalysisResult } from '../../../../components/common/AnalysisResult/AnalysisResult';
import { TwitterButton } from '../../../../components/ui/buttons/TwitterButton/TwitterButton';

import Styled from './ReputationResultSection.styles';
import { LogoSpinner } from '../../../../components/ui/loaders/LogoSpinner';
import { useLocation } from 'react-router-dom';
import { UrlService } from '../../../../services/UrlService';

export const ReputationResultSection: React.FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const scrollToRef = useRef<HTMLDivElement>(null);

  const { data, isLoading } = useReputationWithAddress();
  const { address, setAddress } = useAddress();

  useEffect(() => {
    if ((isLoading && !data) || (!isLoading && data)) {
      scrollToRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isLoading, scrollToRef, data]);

  useEffect(() => {
    if (address && !UrlService.getSearchParam('address', search)) {
      setAddress(undefined);
    }
  }, [address, search, setAddress]);

  return (
    <>
      <div
        className='scroll-to'
        ref={scrollToRef}
        style={{ transform: `translateY(-${data ? 100 : 400}px)` }}
      />
      {isLoading ? (
        <LogoSpinner
          text={t('common.analyzing')}
          containerStyles={{ marginBottom: '10vw' }}
        />
      ) : (
        data && (
          <Styled.Container>
            <Styled.Heading>{t('homePage.resultTitle')}</Styled.Heading>
            <LoadingProvider>
              <AnalysisResult where='main' />
            </LoadingProvider>
            {data && (
              <Styled.ShareResultWrapper>
                <Styled.ShareText>{t('homePage.shareText')}</Styled.ShareText>
                <TwitterButton
                  transactionAddress={address}
                  trustPercentage={data.trustPercentage}
                  url={
                    envs.baseUrl +
                    `?from=${encodeURIComponent(
                      'twitter-share-link'
                    )}&address=${encodeURIComponent(address || '')}`
                  }
                />
              </Styled.ShareResultWrapper>
            )}
          </Styled.Container>
        )
      )}
    </>
  );
};
