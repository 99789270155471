import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DotSvg } from './Dot.svg';
import { ReactComponent as CheckSvg } from './Check.svg';

interface Props {
  isCompleted: boolean;
}

export const StepCircle: React.FC<Props> = ({ isCompleted }) => {
  const Icon = isCompleted ? CheckSvg : DotSvg;
  return (
    <CircleEl isCompleted={isCompleted}>
      <Icon />
    </CircleEl>
  );
};

const CircleEl = styled('div')<{ isCompleted: boolean }>(({ theme, isCompleted }) => ({
  height: 27,
  width: 27,
  borderRadius: '50%',
  backgroundColor: isCompleted ? theme.colors.primary.main : 'transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all .3s',
  position: 'relative',
  zIndex: theme.zIndex.sm,
  [theme.min(theme.breakpoints.md)]: {
    height: 36,
    width: 36,
    '& svg': {
      width: isCompleted ? 18 : 10,
      height: 'auto',
    },
  },
}));

export const StepLabel: React.FC<{ step: number; isCompleted: boolean }> = ({
  step,
  isCompleted,
}) => {
  const { t } = useTranslation();
  return (
    <Label isCompleted={isCompleted}>
      {t('projectOnboard.step', { step: step + 1 })}
    </Label>
  );
};

const Label = styled('span')<{ isCompleted: boolean }>(({ theme, isCompleted }) => ({
  fontSize: 12,
  textTransform: 'uppercase',
  color: isCompleted ? theme.colors.primary.main : '#fff',
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: 14,
  },
  [theme.min(theme.breakpoints.md)]: {
    fontSize: 16,
  },
}));

export const DottedLine = styled('div')<{ isCompleted: boolean }>(
  ({ theme, isCompleted }) => ({
    flex: 1,
    display: 'flex',
    height: 1,
    backgroundColor: isCompleted ? theme.colors.primary.main : '#fff',
  })
);
