import React from 'react';
import { useTranslation } from 'react-i18next';

import { useReputationWithAddress } from '../../../../../providers/AddressProvider/useReputationWithAddress';

import { AnalysisResultPrimaryCardBase } from './AnalysisResultPrimaryCardBase';

export const AnalysisResultBalanceCard: React.FC = () => {
  const { t } = useTranslation('analysisResult');
  const { data } = useReputationWithAddress();

  const { balanceEth, balanceUsd } = data || {};

  const value =
    typeof balanceEth === 'number' && balanceEth > -1
      ? `${parseFloat(balanceEth.toFixed(4)).toLocaleString()} ETH`
      : '';

  const additionalValue =
    typeof balanceUsd === 'number' && balanceUsd > -1
      ? `$${Math.round(balanceUsd).toLocaleString()}`
      : '';

  return (
    <AnalysisResultPrimaryCardBase
      label={t('balance')}
      value={value}
      additionalValue={additionalValue}
    />
  );
};
