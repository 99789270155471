import React from 'react';
import { useTranslation } from 'react-i18next';

import { useReputationWithAddress } from '../../../../../providers/AddressProvider/useReputationWithAddress';

import { AnalysisResultSecondaryCardBase } from './AnalysisResultSecondaryCardBase';

export const TotalValueReceived: React.FC = () => {
  const { t } = useTranslation('analysisResult');
  const { data } = useReputationWithAddress();

  const { totalValueReceivedEth, totalValueReceivedUsd, numberOfTransactions } =
    data || {};

  const valueEth =
    typeof totalValueReceivedEth === 'number' && totalValueReceivedEth > -1
      ? `${totalValueReceivedEth.toLocaleString()} ETH`
      : '';

  const valueUsd =
    typeof totalValueReceivedUsd === 'number' && totalValueReceivedUsd > -1
      ? `$${Math.round(totalValueReceivedUsd).toLocaleString()}`
      : '';

  const info =
    typeof numberOfTransactions === 'number' && numberOfTransactions > 1999
      ? t('totalValueReceived.info')
      : undefined;

  return (
    <AnalysisResultSecondaryCardBase
      label={t('totalValueReceived.title')}
      value={valueEth}
      additionalValue={valueUsd}
      info={info}
    />
  );
};
