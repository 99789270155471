import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserNfts } from '../../../../../react-query/user/useUserNfts';
import { useRequest } from '../../../../../hooks/useRequest';
import { useInvalidateCurrentUserProfile } from '../../../hooks/useInvalidateCurrentUserProfile';

import { NFT } from '../../../../../types';
import { UserApi } from '../../../../../api/UserApi/UserApi';

import { ButtonPrimary, ButtonSize } from '../../../../../components/ui/buttons';
import { BaseModal } from '../../../../../components/ui/modals/BaseModal/BaseModal';
import { NftItem } from './NftItem';
import { LogoSpinner } from '../../../../../components/ui/loaders/LogoSpinner';
import { Spinner } from '../../../../../components/ui/loaders/Spinner';
import { SearchAndSort } from '../../../../../components/common/SearchAndSort/SearchAndSort';
import { CancelButton } from '../../../../../components/common/ActionButtons/CancelButton';

import Styled from './AddNFTModal.styles';

interface Props {
  onClose: () => void;
}

export const AddNFTModal: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const invalidate = useInvalidateCurrentUserProfile();

  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [selectedNFT, setSelectedNFT] = useState<NFT | null>(null);

  const scrollEl = useRef<HTMLDivElement>(null);
  const scrollToEl = useRef<HTMLSpanElement>(null);

  const {
    formattedData,
    totalCount,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useUserNfts({
    params: { search, sortBy },
  });

  const { current: scrollElCurrent } = scrollEl;
  const { current: scrollToElCurrent } = scrollToEl;

  const onFetchNextPage = () => {
    fetchNextPage();
    const topPos = scrollToElCurrent?.offsetTop;
    typeof topPos === 'number' &&
      scrollElCurrent?.scrollTo({ top: topPos + 40, behavior: 'smooth' });
  };

  const onSave = async () => {
    if (!selectedNFT) return;
    await UserApi.patchProfilePhoto({
      tokenId: selectedNFT.tokenId,
      contractAddress: selectedNFT.contractAddress,
    });
    await invalidate();
  };

  const { call: updateProfilePhoto, isLoading: isUpdating } = useRequest(onSave, {
    onSuccess: onClose,
  });

  return (
    <BaseModal title={t('accountDetailsPage.addYourNft')} isOpen={true} onClose={onClose}>
      <Styled.Content>
        {!!totalCount && (
          <Styled.SearchAndSortWrapper>
            <SearchAndSort
              search={search}
              sortBy={sortBy}
              setSearch={setSearch}
              setSortBy={setSortBy}
              breakpointWidth={540}
            />
          </Styled.SearchAndSortWrapper>
        )}
        {formattedData && !formattedData.length ? (
          <Styled.NoNfts>{t('profilePage.noProfileImageNfts')}</Styled.NoNfts>
        ) : (
          <Styled.ScrollableWrapper ref={scrollEl} isLoading={isFetchingNextPage}>
            {isLoading || !formattedData ? (
              <LogoSpinner size={40} containerStyles={{ marginTop: '10vh' }} />
            ) : (
              <Styled.Grid>
                {formattedData.map((nft, i) => (
                  <NftItem
                    key={i}
                    nft={nft}
                    onClick={nft => setSelectedNFT(nft)}
                    selected={
                      `${selectedNFT?.contractAddress}${selectedNFT?.tokenId}` ===
                      `${nft.contractAddress}${nft.tokenId}`
                    }
                  />
                ))}
              </Styled.Grid>
            )}
            <span ref={scrollToEl}>{isFetchingNextPage && <Spinner />}</span>
          </Styled.ScrollableWrapper>
        )}

        {!isLoading && !!formattedData?.length && (
          <Styled.Footer>
            <Styled.Pagination
              currentItemsCount={formattedData.length}
              totalCount={totalCount ?? 0}
              hasNextPage={!!hasNextPage}
              onFetchNextPage={onFetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
            />
            <Styled.ButtonsWrapper>
              <ButtonPrimary
                size={ButtonSize.Sm}
                disabled={!selectedNFT}
                isLoading={isUpdating}
                onClick={updateProfilePhoto}
              >
                {t('common.saveChanges')}
              </ButtonPrimary>
              <CancelButton size={ButtonSize.Sm} onClick={onClose} />
            </Styled.ButtonsWrapper>
          </Styled.Footer>
        )}
      </Styled.Content>
    </BaseModal>
  );
};
