import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useReputationWithAddress } from '../../../../providers/AddressProvider/useReputationWithAddress';
import { useTrustCategory } from '../TrustCategoryProvider';
import { IWhereContext, useWhere } from '../../../../providers/WhereProvider';

import { Trust } from '../../../../types';

import { IsVerified } from '../../IsVerified/IsVerified';
import { LoadingBackground } from '../../../ui/loaders/LoadingBackground';

export const AnalysisSummaryTrustTitle: React.FC = () => {
  const { t } = useTranslation('analysisResult');
  const { data } = useReputationWithAddress();
  const { trust } = useTrustCategory();
  const where = useWhere();

  if (!data || !trust) return <Loader where={where}>Loading</Loader>;

  const { isProjectVerified } = data;

  return (
    <Title trust={trust} where={where}>
      {t(`summary.${trust}.title`)}
      {isProjectVerified && <IsVerified />}
    </Title>
  );
};

const Title = styled('h4')<{ trust: Trust; where: IWhereContext }>(
  ({ theme, trust, where }) => ({
    fontWeight: 900,
    fontSize: 20,
    marginRight: 9,
    color: theme.colors.trust[trust],
    lineHeight: 1.322,
    position: 'relative',
    width: 'max-content',
    [theme.min(theme.breakpoints.sm)]: {
      fontSize: where === 'list' ? 20 : 24,
    },
    [theme.min(theme.breakpoints.desktop)]: {
      fontSize: where === 'list' ? theme.pxToVw(20) : theme.pxToVw(32),
      marginRight: where === 'list' ? theme.pxToVw(10) : theme.pxToVw(12),
    },
    [theme.min(theme.breakpoints.max)]: {
      fontSize: where === 'list' ? 20 : 32,
      marginRight: where === 'list' ? 10 : 12,
    },
  })
);

const Loader = styled(LoadingBackground)<{ where: IWhereContext }>(
  ({ theme, where }) => ({
    display: 'flex',
    borderRadius: 10,
    color: 'transparent',
    width: '100%',
    maxWidth: 240,
    fontSize: 20,
    [theme.min(theme.breakpoints.sm)]: {
      fontSize: where === 'list' ? 20 : 24,
    },
    [theme.min(theme.breakpoints.desktop)]: {
      fontSize: where === 'list' ? theme.pxToVw(20) : theme.pxToVw(32),
      maxWidth: theme.pxToVw(240),
    },
    [theme.min(theme.breakpoints.max)]: {
      fontSize: where === 'list' ? 20 : 32,
      maxWidth: 240,
    },
  })
);
