import { axiosInstance } from '../config/axios';
import { PaginatedResponse, Transaction } from '../types';
import { apiRoutes, IRequestParams } from './routes';

export type TransactionsResponse = PaginatedResponse<Transaction>;

class TransactionsApiConfig {
  private readonly apiMapper = {};

  getByAddress = async (
    address: string,
    params: IRequestParams
  ): Promise<Transaction[]> => {
    const { data } = await axiosInstance.get<Transaction[]>(
      apiRoutes.transactions.byAddress(address, params)
    );
    return data.map(item => ({
      ...item,
      timeStamp: +item.timeStamp,
      txType: params.txType!,
    }));
  };
}

export const TransactionsApi = new TransactionsApiConfig();
