import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useLoadImage } from '../../../../../hooks/useLoadImage';

import { LoadingBackground } from '../../../../../components/ui/loaders/LoadingBackground';

import { defaultProfileImage } from '../../../../../assets';
import { Text } from '../../../../../components/ui/text/Text';

interface Props {
  photoUrl: string | null;
  title: string;
  followersCount: number;
  onClick: () => void;
}

export const NewsFeedProfileCard: React.FC<Props> = ({
  photoUrl,
  title,
  followersCount,
  onClick,
}) => {
  const { t } = useTranslation();

  const { src, isError, isLoaded } = useLoadImage(
    photoUrl || defaultProfileImage,
    defaultProfileImage
  );

  return (
    <Wrapper onClick={onClick}>
      <ImageWrapper>
        <Image src={src} position={photoUrl && !isError ? 'top' : 'center'} />
        {!isLoaded && <ImageLoader />}
      </ImageWrapper>
      <DetailsWrapper>
        <TextBold>{title}</TextBold>
        <TextBase>
          {t('common.followers')} <span>{followersCount}</span>
        </TextBase>
      </DetailsWrapper>
    </Wrapper>
  );
};

export const NewsFeedProfileCardLoader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ImageWrapper>
        <ImageLoader />
      </ImageWrapper>

      <DetailsWrapper>
        <LoadingBackground style={{ width: '70%' }}>
          <TextBold>L</TextBold>
        </LoadingBackground>
        <TextBase>
          {t('common.followers')}{' '}
          <LoadingBackground style={{ width: 50 }} as='span'>
            L
          </LoadingBackground>
        </TextBase>
      </DetailsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  position: 'relative',
  overflow: 'visible',
  '&:hover': {
    '& .img-wrapper-xx': {
      background:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(image.png)',
    },
    '& .project-menu': {
      display: 'block',
    },
  },
}));

const DetailsWrapper = styled('div')(({ theme }) => ({
  diplay: 'flex',
  flexDirection: 'column',
  padding: '12px 14px',
  gap: 6,
  cursor: 'pointer',
  [theme.min(theme.breakpoints.desktop)]: {
    padding: `${theme.pxToVw(18)} ${theme.pxToVw(12)}`,
  },
  [theme.min(theme.breakpoints.max)]: {
    padding: '18px 12px',
  },
}));

const TextBase = styled(Text.SM)(({ theme }) => ({
  lineHeight: 1.333,
  fontWeight: 600,
  wordBreak: 'break-word',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
}));

const TextBold = styled(TextBase)(({ theme }) => ({
  fontWeight: 900,
}));

const Image = styled('img')<{ position: 'top' | 'center' }>(({ theme, position }) => ({
  pointerEvents: 'none',
  aspectRatio: '1',
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
  objectPosition: position,
  borderRadius: 20,
  maxWidth: '100%',
  maxHeight: '100%',
  cursor: 'pointer',
  [theme.betweenDM]: {
    borderRadius: theme.pxToVw(20),
  },
}));

const ImageWrapper = styled('div')(({ theme }) => ({
  borderRadius: 20,
  aspectRatio: '1',
  width: '100%',
  height: 'auto',
  transition: 'all .3s',
  position: 'relative',
  overflow: 'hidden',
  background: 'transparent',
  boxShadow: '0px 0px 10px #0065FC',
  cursor: 'pointer',
  [theme.betweenDM]: {
    borderRadius: theme.pxToVw(20),
  },
}));

const ImageLoader = styled(LoadingBackground)(({ theme }) => ({
  display: 'flex',
  borderRadius: 20,
  aspectRatio: '1',
  width: '100%',
  height: '100%',
  position: 'absolute',
  [theme.betweenDM]: {
    borderRadius: theme.pxToVw(20),
  },
}));

ImageWrapper.defaultProps = { className: 'img-wrapper-xx' };
