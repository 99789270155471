import styled from 'styled-components';
import { SocialIcons } from '../../../../components/common/SocialIcons/SocialIcons';
import { Container as ContainerEl } from '../../../../components/layouts/Container';
import { MainHeading } from '../../../../components/ui/text/Heading';
import { Text } from '../../../../components/ui/text/Text';
import { useAuth } from '../../../../providers/AuthProvider';

const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: useAuth().isAuthenticated ? '100px 0 20px 0' : '220px 0 10vh 0',
  [theme.min(theme.breakpoints.sm)]: {
    padding: useAuth().isAuthenticated ? '120px 0 20px 0' : '260px 0 10vh 0',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    padding: useAuth().isAuthenticated
      ? `${theme.pxToVw(140)} 0 0 0`
      : `${theme.pxToVw(240)} 0 ${theme.pxToVw(80)} 0`,
  },
  [theme.min(theme.breakpoints.max)]: {
    padding: useAuth().isAuthenticated ? `140px 0 0 0` : `240px 0 80px 0`,
  },
}));

const Container = styled(ContainerEl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

const Heading = styled(MainHeading)(({ theme }) => ({
  display: useAuth().isAuthenticated ? 'none' : 'block',
  position: 'relative',
  zIndex: theme.zIndex.sm,
  lineHeight: 1.2,
  marginBottom: 8,
  [theme.min(theme.breakpoints.desktop)]: {
    marginBottom: theme.pxToVw(18),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginBottom: 18,
  },
}));

const SearchInfo = styled(Text.MD)(({ theme }) => ({
  display: useAuth().isAuthenticated ? 'none' : 'flex',
  zIndex: theme.zIndex.sm,
  marginBottom: 24,
  [theme.min(theme.breakpoints.desktop)]: {
    marginBottom: theme.pxToVw(50),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginBottom: 50,
  },
}));

const SocialWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  [theme.min(theme.breakpoints.sm)]: {
    flexDirection: 'row',
    justifyContent: useAuth().isAuthenticated ? 'flex-end' : 'space-between',
    alignItems: 'center',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(20),
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 20,
  },
}));

const Social = styled(SocialIcons)(({ theme }) => ({
  display: useAuth().isAuthenticated ? 'none' : 'flex',
  width: 'max-content',
  gap: 26,
  '& svg': {
    height: 28,
    width: 'auto',
  },
  [theme.min(theme.breakpoints.sm)]: {
    marginLeft: '1.5vw',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(40),
    '& svg': {
      height: theme.pxToVw(42),
    },
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 40,
    '& svg': {
      height: 42,
    },
  },
}));

const InputWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  [theme.min(theme.breakpoints.sm)]: {
    gap: 16,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(32),
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 32,
  },
}));

const Styled = {
  Wrapper,
  Container,
  Heading,
  SearchInfo,
  SocialIcons: Social,
  SocialWrapper,
  InputWrapper,
};

export default Styled;
