import { Trust } from '../types';

export const getTrustPercentageCategory = (trustPercentage: number): Trust => {
  switch (true) {
    case trustPercentage >= 80:
      return Trust.High;
    case trustPercentage >= 60 && trustPercentage < 80:
      return Trust.Medium;
    case trustPercentage >= 40 && trustPercentage < 60:
      return Trust.Low;
    case trustPercentage < 40 && trustPercentage > -1:
      return Trust.Untrusted;
    default:
      return Trust.NewWallet;
  }
};
