import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useProfileByParamId } from '../../../../../../react-query/profile/useProfile';
import { useProfileUtils } from '../../../../hooks/useProfileUtils';

import { TransactionType } from '../../../../../../types';

import { AddressProvider } from '../../../../../../providers/AddressProvider/AddressProvider';
import { Container } from '../../../../../../components/layouts/Container';
import {
  ProfileDataCardBase,
  ProfileDataFollowButton,
  ProfileDataPageTitle,
} from '../../components';
import { AddressesSelect } from '../../AddressesSelect/AddressesSelect';
import { ProfileDataHistoryCard } from './ProfileDataHistoryCard';
import { TransactionTypeSelect } from './TransactionTypeSelect';
import { SelectBase } from '../../../../../../components/ui/forms/Select/SelectBase';

export const HistoryView: React.FC = () => {
  const { t } = useTranslation();
  const { data: profile } = useProfileByParamId();
  const [sortBy, setSortBy] = useState<'asc' | 'desc'>('desc');
  const [txType, setTxType] = useState(TransactionType.Normal);

  const [addresses, setAddreses] = useState<string[]>();
  const { isOwner, isClaimedAddress } = useProfileUtils();

  const sortOptions = [
    { label: t('common.latest'), value: 'desc' },
    {
      label: t('common.oldest'),
      value: 'asc',
    },
  ];

  useEffect(() => {
    if (profile) setAddreses(profile.addresses);
  }, [profile]);

  if (!profile) return null;

  const onAddressChange = (address: string) => {
    setAddreses(address === 'all' ? profile.addresses : [address]);
  };

  const addressPickerShown = profile?.addresses.length > 1;

  return (
    <>
      <ActionsWrapper addressPickerShown={addressPickerShown}>
        <ProfileDataPageTitle>{t('common.transactionHistory')}</ProfileDataPageTitle>
        <SelectsWrapper>
          <TransactionTypeSelect onChange={setTxType} selectedValue={txType} />
          {addressPickerShown ? (
            <AddressesSelect onChange={onAddressChange} />
          ) : (
            <div className='sort-wrapper'>
              <StyledSelect
                label={t('common.sortBy')}
                name='sort'
                onChange={setSortBy}
                placeholder={t('common.sortBy')}
                showArrows
                value={null}
                options={sortOptions}
              />
            </div>
          )}
        </SelectsWrapper>
      </ActionsWrapper>
      {addressPickerShown && (
        <ActionsWrapper addressPickerShown={addressPickerShown}>
          <div className='sort-wrapper'>
            <StyledSelect
              label='Sort'
              name='sort'
              onChange={setSortBy}
              placeholder={t('common.sort')}
              showArrows
              value={'asc'}
              options={sortOptions}
              keepLabelWithSelectedValue
            />
          </div>
        </ActionsWrapper>
      )}

      <Wrapper>
        {addresses?.map(address => (
          <ProfileDataCardBase key={address}>
            <AddressProvider initAddress={address}>
              <ProfileDataHistoryCard search={''} sort={sortBy} txType={txType} />
            </AddressProvider>
            {!isOwner && isClaimedAddress && (
              <ProfileDataFollowButton address={address} />
            )}
          </ProfileDataCardBase>
        ))}
      </Wrapper>
    </>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  width: '100%',
}));

const ActionsWrapper = styled(Container)<{ addressPickerShown: boolean }>(
  ({ theme, addressPickerShown }) => ({
    marginBottom: 12,
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    position: 'relative',
    '& .sort-wrapper': {
      width: addressPickerShown ? 'calc(50% + 16px)' : '50%',
      maxWidth: 200,
      marginLeft: 'auto',
    },
    [theme.min(theme.breakpoints.sm)]: {
      marginBottom: 16,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 16,
      '& h3': {
        width: 'max-content',
        flexShrink: 0,
      },
    },
    [theme.min(theme.breakpoints.md)]: {
      marginBottom: 20,
      gap: 20,
    },
    [theme.betweenDM]: {
      marginBottom: theme.pxToVw(20),
      gap: theme.pxToVw(20),
      '& .sort-wrapper': {
        width: addressPickerShown ? `calc(50% + ${theme.pxToVw(16)})` : '50%',
        maxWidth: theme.pxToVw(200),
      },
    },
  })
);

const SelectsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  flex: 1,
  width: '100%',
  gap: 8,
  '& < *': {
    flex: 1,
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(8),
  },
}));

const StyledSelect = styled(SelectBase)(({ theme }) => ({
  width: 170,
  marginLeft: 'auto',
  [theme.betweenDM]: {
    width: theme.pxToVw(170),
  },
}));
