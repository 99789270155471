import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useOutsideClick } from '../../../hooks/useOutsideClick';

import { Icon } from '../../../assets';
import { ButtonPrimary } from '../../ui/buttons';

interface Props {
  shareUrl: string;
  menuXPosition?: 'left' | 'center' | 'right';
}

export const ShareProfileButton: React.FC<Props> = ({
  shareUrl,
  menuXPosition = 'right',
}) => {
  const { t } = useTranslation('common');

  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  useOutsideClick([ref, menuRef], () => setOpen(false));

  const hashtags = [''];
  const text = '';

  const items = [
    {
      label: t('copyLink'),
      onClick: () => navigator.clipboard.writeText(String(shareUrl)),
    },
    {
      label: t('shareOn', { network: 'Twitter' }),
      onClick: () =>
        window.open(
          `https://twitter.com/intent/tweet?text=${encodeURIComponent(
            text
          )}&url=${encodeURIComponent(shareUrl)}&hashtags=${encodeURIComponent(
            hashtags.join(',')
          )}`
        ),
    },
    // { label: t('shareOn', { network: 'Discord' }), onClick: () => {} },
  ];

  return (
    <Wrapper ref={ref}>
      <IconWrapper
        onClick={e => {
          e.stopPropagation();
          setOpen(prev => !prev);
        }}
      >
        <Icon.Share />
      </IconWrapper>
      {open && (
        <Menu ref={menuRef} menuXPosition={menuXPosition}>
          {items.map(action => (
            <MenuItem
              key={action.label}
              onClick={() => {
                action.onClick();
                setOpen(false);
              }}
            >
              {action.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  overflow: 'visible',
  zIndex: theme.zIndex.sm,
  '& svg': {
    cursor: 'pointer',
    transition: 'all .3s',
    opacity: 1,
    '&:hover': {
      opacity: 0.85,
    },
  },
}));

const Menu = styled('div')<{ menuXPosition: Props['menuXPosition'] }>(
  ({ theme, menuXPosition }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    minWidth: 236,
    position: 'absolute',
    zIndex: theme.zIndex.ul,
    backgroundColor: theme.colors.primary.main,
    bottom: -12,
    transform:
      menuXPosition === 'left'
        ? 'translate(-80%, 100%)'
        : menuXPosition === 'center'
        ? 'translate(-40%, 100%)'
        : 'translateY(100%)',
    [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
      minWidth: theme.pxToVw(236),
      bottom: theme.pxToVw(-12),
    },
  })
);

const MenuItem = styled('div')(({ theme }) => ({
  height: 48,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  fontSize: 14,
  cursor: 'pointer',
  transition: 'all .3s',
  backgroundColor: theme.colors.primary.main,
  padding: '0 12px',
  position: 'relative',
  zIndex: theme.zIndex.ul,
  '&:first-child': {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  '&:last-child': {
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  '&:hover': {
    backgroundColor: '#0046AE',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(20),
    height: theme.pxToVw(62),
    padding: `0 ${theme.pxToVw(16)}`,
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 20,
    height: 62,
    padding: '0 16px',
  },
}));

const IconWrapper = styled(ButtonPrimary)(({ theme }) => ({
  height: 36,
  width: 36,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: 36,
  padding: 0,
  borderRadius: '50%',
  '& svg': {
    marginLeft: '0 !important',
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    height: theme.pxToVw(36),
    width: theme.pxToVw(36),
    maxWidth: theme.pxToVw(36),
    '& svg': {
      width: theme.pxToVw(16),
      height: 'auto',
    },
  },
}));
