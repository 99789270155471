import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useWhere } from '../../../providers/WhereProvider';
import { useUser } from '../../../react-query/user/useUser';
import { useNotificationsCount } from '../../../react-query/user/notifications/useNotifications';

import {
  DropdownMenuBase,
  DropdownMenuVariant,
} from '../../ui/menus/DopdownMenu/DropdownMenuBase';
import { AllowNotifications } from './SettingItems/AllowNotifications';
import { MarkAllAsRead } from './SettingItems/MarkAllAsRead';
import { MuteNotifications } from './SettingItems/MuteNotifications';

import { ReactComponent as DotsSvg } from './svgs/Dots.svg';

export const NotificationsHeader: React.FC = () => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const { data: user } = useUser();
  const { data: notificationCount } = useNotificationsCount();

  const { undreadCount } = notificationCount || {};

  const menuComponentItems = React.useMemo(() => {
    const components = [<AllowNotifications />, <MuteNotifications />];
    if (typeof undreadCount === 'number' && undreadCount > 0) {
      components.unshift(<MarkAllAsRead />);
    }
    return components;
  }, [undreadCount]);

  return (
    <Wrapper>
      <Title>{t('notifications.pageTitle')}</Title>
      {user?.notificationsEnabled && (
        <SmallViewItems>
          <DropdownMenuBase
            isOpen={menuOpen}
            onToggle={() => setMenuOpen(prev => !prev)}
            menuVariant={DropdownMenuVariant.Black}
            Trigger={<DotsSvg />}
            menuComponentItems={menuComponentItems}
            menuStyle={{ borderRadius: 5, transform: 'translateX(-40px)' }}
          />
        </SmallViewItems>
      )}
      {user?.notificationsEnabled && (
        <LargeScreenItems>
          <AllowNotifications />
          <MuteNotifications />
          {!!notificationCount?.undreadCount && <MarkAllAsReadLargeScreen />}
        </LargeScreenItems>
      )}
    </Wrapper>
  );
};

const Title = styled('h3')(({ theme }) => ({
  fontSize: 20,
  marginRight: 'auto',
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: useWhere() === 'popup' ? 20 : 26,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: useWhere() === 'popup' ? theme.pxToVw(20) : theme.pxToVw(32),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: useWhere() === 'popup' ? 20 : 32,
  },
}));

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 8,
  width: '100%',
  padding: useWhere() === 'popup' ? '14px 20px' : 'none',
  [theme.min(theme.breakpoints.sm)]: {
    justifyContent: useWhere() === 'popup' ? 'space-between' : 'flex-start',
    flexDirection: useWhere() === 'popup' ? 'row' : 'column',
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(8),
    padding: useWhere() === 'popup' ? `${theme.pxToVw(14)} ${theme.pxToVw(20)}` : 'none',
  },
}));

const SmallViewItems = styled('div')(({ theme }) => ({
  marginLeft: 'auto',
  [theme.min(theme.breakpoints.sm)]: {
    display: useWhere() === 'popup' ? 'block' : 'none',
  },
}));

const LargeScreenItems = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.min(theme.breakpoints.sm)]: {
    width: '100%',
    display: useWhere() === 'popup' ? 'none' : 'flex',
    alignItems: 'center',
    gap: 60,
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(60),
  },
}));

const MarkAllAsReadLargeScreen = styled(MarkAllAsRead)({
  marginLeft: 'auto',
});
