import { useEffect } from 'react';
import styled from 'styled-components';
import { Navigate } from 'react-router-dom';
import { Container } from '../../../../components/layouts/Container';
import MilestoneFormItem from './Milestones/MilestoneForm/MilestoneFormItem';
import { Heading3 } from '../../../../components/ui/text/Heading';
import { Text } from '../../../../components/ui/text/Text';
import { useProjectMilestonesByParamId } from '../../../../react-query/user/project/useProjectMilestones';
import Milestones from './Milestones/Milestones';
import { RoadmapStateProvider, useRoadmapState } from './RoadmapStateProvider';
import { LogoSpinner } from '../../../../components/ui/loaders/LogoSpinner';
import { useProjectUtils } from '../../profile/hooks/useProjectUtils';

export default function ProjectRoadmapPageWrapper() {
  const { isOwner } = useProjectUtils();
  const { data } = useProjectMilestonesByParamId();

  if (data && !isOwner) return <Navigate to='/' replace />;

  return (
    <RoadmapStateProvider>
      <ProjectRoadmapPage />
    </RoadmapStateProvider>
  );
}

function ProjectRoadmapPage() {
  const { data: milestones, isLoading } = useProjectMilestonesByParamId();
  const { mode, milestoneEditing, isGoalFormOpen, setMode } = useRoadmapState();

  useEffect(() => {
    if (!milestones) return;
    if (milestones.length === 0) setMode('create');
  }, [milestones, setMode]);

  return (
    <Wrapper>
      <Heading3>Roadmap</Heading3>
      {!isLoading && milestones?.length === 0 && (
        <Text.Default>Create your first milestone</Text.Default>
      )}
      {isLoading || milestones === undefined ? (
        <LogoSpinner containerStyles={{ paddingTop: '10vh' }} />
      ) : (
        <Flex>
          {milestones?.length > 0 && <Milestones />}
          {(mode === 'create' || milestoneEditing) && (
            <FormWrapper>
              {isGoalFormOpen && <Overlay />}
              <MilestoneFormItem
                key={`${!!milestoneEditing}-${milestoneEditing?.uid}`}
                milestone={milestoneEditing}
              />
            </FormWrapper>
          )}
        </Flex>
      )}
    </Wrapper>
  );
}

const Overlay = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.min(600)]: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'rgba(61, 72, 89, 0.2)',
    content: '" "',
    display: 'block',
    borderRadius: 10,
    zIndex: 5,
  },
}));

const Wrapper = styled(Container)(({ theme }) => ({
  marginTop: 94,
  marginBottom: 200,
  '& h3, & p': {
    textAlign: 'center',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    marginTop: theme.pxToVw(131),
    marginBottom: theme.pxToVw(260),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginTop: 131,
    marginBottom: 260,
  },
}));

const FormWrapper = styled('div')(({ theme }) => ({
  borderRadius: 10,
  marginTop: 12,
  maxWidth: 751,
  flex: 1,
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'relative',
  [theme.min(600)]: {
    backgroundColor: 'rgba(61, 72, 89, 0.2)',
    padding: 16,
  },
  [theme.min(theme.breakpoints.md)]: {
    padding: '24px 32px',
  },
  [theme.betweenDM]: {
    padding: `${theme.pxToVw(24)} ${theme.pxToVw(32)}`,
    maxWidth: theme.pxToVw(751),
  },
}));

const Flex = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  marginTop: 25,
  [theme.min(theme.breakpoints.desktop)]: {
    marginTop: theme.pxToVw(45),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginTop: 45,
  },
}));
