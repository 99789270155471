import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useReputationWithAddress } from '../../../../providers/AddressProvider/useReputationWithAddress';
import { useFollowersCount } from '../../../../react-query/profile/useFollowersTotalCount';
import { useFollowingsCount } from '../../../../react-query/profile/useFollowingsTotalCount';
import { useProfile } from '../../../../react-query/profile/useProfile';
import { useCurrentUserFollowings } from '../../../../react-query/user/useFollowings';
import { useUser } from '../../../../react-query/user/useUser';
import { useAuth } from '../../../../providers/AuthProvider';
import { useClaimProfile } from '../../../../hooks/useClaimProfile';
import { useWindowWidth } from '../../../../hooks/useWindowWidth';
import { useTrustCategory } from '../TrustCategoryProvider';

import { routes } from '../../../../router/routes';
import { envs } from '../../../../config/envs';

import { FollowUserProfileButton } from '../../ActionButtons/FollowUserProfileButton';
import { ShareProfileButton } from '../../ActionButtons/ShareProfileButton';
import { ProfileBadgeImage } from './components/ProfileBadgeImage';
import {
  AddThisToMyProfileButton,
  ClaimAndCreateProfileButton,
  DetailsWrapper,
  FlexWrapper,
  FollowersCount,
  FollowingsCount,
  ProfileBadgeUsername,
  ReportProfile,
  SeeThisProject,
} from './components/shared';
import { ActionTextVariant } from '../../../ui/text/ActionText';
import { ErrorMessage } from '../../../ui/forms/FormFieldWrapper/ErrorMessage';
import { isValidEthAddress, shortenEthAddress } from '../../../../utils/address-utils';
import { Trust } from '../../../../types';

export const ProfileBadgeAddress: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: reputation } = useReputationWithAddress();
  const { isLoading: useFollowingsIsLoading } = useCurrentUserFollowings();
  const { isAuthenticated } = useAuth();
  const { isLoading: userIsLoading, data: user } = useUser();
  const { trust } = useTrustCategory();
  const width = useWindowWidth();

  const { data: profile, isLoading: profileIsLoading } = useProfile(
    reputation?.address,
    true
  );

  const { call, isLoading: isClaimingProfile, isError } = useClaimProfile(profile);

  const { data: followersCount, isLoading: followersIsLoading } = useFollowersCount(
    profile?.uid || reputation?.address
  );
  const { data: followingCount, isLoading: followingIsLoading } = useFollowingsCount(
    profile?.uid
  );

  const theIsLoading =
    profileIsLoading ||
    followersIsLoading ||
    followingIsLoading ||
    useFollowingsIsLoading;

  const renderClaimButton = () => {
    if (profile?.uid) return null;
    if (isAuthenticated)
      return (
        <AddThisToMyProfileButton
          onClick={() => call(true)}
          isContract={false}
          isLoading={isClaimingProfile}
        />
      );
    return (
      <ClaimAndCreateProfileButton
        onClick={() => call(true)}
        isContract={false}
        isLoading={isClaimingProfile}
      />
    );
  };

  const renderReportProfile1 = () => {
    if (
      trust !== Trust.Untrusted ||
      !reputation ||
      width < 580 ||
      (width < 1300 && width >= 580 && !reputation.ownerUsername)
    ) {
      return null;
    }

    return <ReportProfile address={reputation.address} />;
  };

  const renderReportProfile2 = () => {
    if (
      trust !== Trust.Untrusted ||
      !reputation ||
      width > 1300 ||
      (width >= 580 && reputation.ownerUsername)
    ) {
      return null;
    }

    return <ReportProfile address={reputation.address} style={{ marginTop: 12 }} />;
  };

  const renderContent = () => {
    if (userIsLoading || !profile || !reputation) return null;

    let displayValue = profile?.username || String(profile.addresses);

    displayValue =
      width < 400 && isValidEthAddress(displayValue)
        ? shortenEthAddress(displayValue, { startChars: 30 })
        : displayValue;

    return (
      <>
        <ProfileBadgeUsername
          onClick={() =>
            navigate(routes.profile.byId(profile.username || reputation.address))
          }
          underline={false}
          $disabled={!profile.uid}
        >
          {displayValue}
        </ProfileBadgeUsername>
        <FlexWrapper>
          <FollowersCount count={followersCount} />
          {profile.uid ? (
            <FollowingsCount count={followingCount} />
          ) : (
            <SeeThisProject
              onClick={() => navigate(routes.profile.byId(profile?.username!))}
              style={{ marginRight: 'auto', marginLeft: 0 }}
              variant={ActionTextVariant.Inverted}
            >
              {t('common.seeThisProfilePreview')}
            </SeeThisProject>
          )}
        </FlexWrapper>
        <FlexWrapper>
          {renderClaimButton()}
          {(user && user.uid === profile.uid) || !profile.uid ? null : (
            <FollowUserProfileButton userUid={profile.uid} />
          )}
          <ShareProfileButton
            shareUrl={`${envs.blockemWebsiteUrl}/u/${profile.username}`}
            menuXPosition='left'
          />
          {renderReportProfile1()}
        </FlexWrapper>
        {renderReportProfile2()}
        {isError && <ErrMsg error={isError} />}
      </>
    );
  };

  return (
    <>
      {profile && !profile.uid ? null : (
        <ProfileBadgeImage
          imageUrl={profile?.photoUrl}
          isLoading={theIsLoading}
          onClick={() => profile && navigate(routes.profile.byId(profile.username))}
        />
      )}
      <DetailsWrapper isLoading={theIsLoading}>{renderContent()}</DetailsWrapper>
    </>
  );
};

const ErrMsg = styled(ErrorMessage)({
  transform: 'translateY(14px)',
  fontSize: 12,
  marginTop: -10,
});
