import React from 'react';
import { useTranslation } from 'react-i18next';

import { useProfileFollowings } from '../../../../../react-query/user/useFollowings';
import { groupFollowingsByUsername } from './groupFollowingsByUsername';

import { BaseModal } from '../../../../../components/ui/modals/BaseModal/BaseModal';
import { FollowerItem } from './FollowerItem';
import { LogoSpinner } from '../../../../../components/ui/loaders/LogoSpinner';

import { routes } from '../../../../../router/routes';
import Styled from './components.styles';

interface Props {
  onClose: () => void;
}

export const FollowingModal: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation('common');
  const { data: followings } = useProfileFollowings();

  const items = followings ? groupFollowingsByUsername(followings) : null;

  return (
    <BaseModal title={t('following')} onClose={onClose} isOpen>
      <Styled.Content>
        {!followings && (
          <Styled.SpinnerWrapper>
            <LogoSpinner size={68} />
          </Styled.SpinnerWrapper>
        )}
        {items?.map((item, i) => (
          <FollowerItem
            key={i}
            {...item}
            onClick={addressOrUsername => {
              onClose();
              const route = item.isContract
                ? routes.project.byId(item.address!)
                : routes.profile.byId(addressOrUsername);
              window.open(route, '_blank');
            }}
          />
        ))}
      </Styled.Content>
    </BaseModal>
  );
};
