import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useProjectByParamId } from '../../../../../../react-query/project/useProject';
import { useProjectMilestoneCreate } from '../../../../../../react-query/user/project/useProjectMilestoneCreate';
import { ButtonPrimary, ButtonVariant } from '../../../../../../components/ui/buttons';
import { ButtonSecondary } from '../../../../../../components/ui/buttons/ButtonSecondary';
import { InputField } from '../../../../../../components/ui/forms/Input/InputField';
import MilestoneGoalForm from './MilestoneGoal/MilestoneGoalForm';
import { Milestone } from '../../../../../../types';
import { useProjectMilestonesByParamId } from '../../../../../../react-query/user/project/useProjectMilestones';
import { useProjectMilestoneEdit } from '../../../../../../react-query/user/project/useProjectMilestoneEdit';
import { useEffect, useState } from 'react';
import MilestoneGoalItem from './MilestoneGoal/MilestoneGoalItem';
import { useRoadmapState } from '../../RoadmapStateProvider';
import { ErrorText } from '../../../../../../components/ui/forms/FormFieldWrapper/ErrorMessage';

export default function MilestoneForm() {
  const { t } = useTranslation();
  const { data: milestones } = useProjectMilestonesByParamId();
  const { data: project } = useProjectByParamId();
  const { mode, setMode, setMilestoneEditing, milestoneEditing, setIsGoalFormOpen } =
    useRoadmapState();

  const {
    mutateAsync: createMilestone,
    isLoading: isCreating,
    isSuccess: isCreated,
    error: createError,
  } = useProjectMilestoneCreate();
  const {
    mutateAsync: editMilestone,
    isLoading: isEditing,
    error: editError,
  } = useProjectMilestoneEdit();

  const {
    getValues,
    watch,
    register,
    reset,
    formState: { isDirty, errors },
  } = useFormContext<Milestone>();
  const [showNewGoalForm, setShowNewGoalForm] = useState(false);

  useEffect(() => {
    if (mode === 'create' && isCreated && !milestoneEditing) {
    }
  }, [mode, isCreated, setMode, setMilestoneEditing, milestoneEditing, milestones]);

  const onSubmit = async () => {
    if (!project) return;

    const { goals, title, uid } = getValues();
    const lastMilestone = milestones?.slice(-1)[0];
    if (!uid) {
      const totalMilestones = milestones?.length || 0;
      const payload = { title, goals, prevOrderString: lastMilestone?.orderString || '' };
      const createdMilestone = await createMilestone({
        projectUid: project.uid,
        payload,
      });
      createdMilestone.orderNumber = totalMilestones + 1;
      reset(createdMilestone);
      setMilestoneEditing(createdMilestone);
      setMode('edit');
    } else {
      await editMilestone({
        projectUid: project.uid,
        milestoneUid: uid,
        payload: { title },
      });
      setMode('view');
      setMilestoneEditing(null);
    }
  };

  const goals = watch('goals');

  return (
    // @ts-ignore  -> some strange bug O.o
    <Wrapper showNewGoalForm={showNewGoalForm}>
      <InputField
        label='Milestone Title'
        placeholder='Enter Milestone Title'
        editable={true}
        activeBackground={!showNewGoalForm}
        value={watch('title')}
        {...register('title')}
      />
      {goals.map(goal => (
        <MilestoneGoalItem
          key={goal.uid || goal.title}
          goal={goal}
          milestoneUid={watch('uid')}
          onGoalEdit={() => setShowNewGoalForm(false)}
        />
      ))}
      {showNewGoalForm && (
        <MilestoneGoalForm
          milestoneUid={watch('uid')}
          onClose={() => setShowNewGoalForm(false)}
          onGoalEdit={() => {}}
        />
      )}
      <ButtonPrimary
        disabled={showNewGoalForm}
        variant={ButtonVariant.Outlined}
        onClick={() => {
          setShowNewGoalForm(true);
          setIsGoalFormOpen(true);
        }}
      >
        Add Goal
      </ButtonPrimary>
      <ButtonsWrapper>
        <ButtonPrimary
          onClick={onSubmit}
          disabled={
            showNewGoalForm || !watch('title') || !watch('goals').length || !isDirty
          }
          isLoading={isCreating || isEditing}
        >
          Save Milestone
        </ButtonPrimary>
        <ButtonSecondary
          disabled={showNewGoalForm || isCreating || isEditing}
          onClick={() => {
            setMode('view');
            setMilestoneEditing(null);
          }}
        >
          Cancel
        </ButtonSecondary>
      </ButtonsWrapper>
      {(editError || createError || errors.goals) && (
        <ErrorText style={{ textAlign: 'center' }}>
          {errors.goals ? errors.goals.message : t('errors.default')}
        </ErrorText>
      )}
    </Wrapper>
  );
}

const Wrapper = styled('div')<{ showNewGoalForm: boolean }>(
  ({ theme, showNewGoalForm }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    [theme.betweenDM]: {},
    '& .goal-item-wrapper': {
      zIndex: showNewGoalForm ? 0 : 5,
    },
  })
);

const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 8,
  marginTop: 6,
  '& button': {
    flex: 1,
    '&:first-child': {
      maxWidth: 292,
      [theme.betweenDM]: {
        maxWidth: theme.pxToVw(292),
      },
    },
    '&:last-child': {
      maxWidth: 150,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'rgba(255,255,255, 0.05)',
      },
    },
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(8),
    marginTop: theme.pxToVw(6),
  },
}));
