import { useMutation } from 'react-query';
import { ProjectMilestonesApi } from '../../../api/ProjectApi/ProjectMilestonesApi';
import { useInvalidateProjectMilestones } from './useProjectMilestones';

export const useMilestoneGoalCreate = () => {
  const invalidateMilestones = useInvalidateProjectMilestones();
  return useMutation(ProjectMilestonesApi.postMilestoneGoal, {
    onSuccess: () => {
      invalidateMilestones();
    },
  });
};
