import React from 'react';
import styled from 'styled-components';
import { ProfileDescription } from '../../../../../components/profile/ProfileDescription';

import { useProfileByParamId } from '../../../../../react-query/profile/useProfile';
import { useProfileUtils } from '../../../hooks/useProfileUtils';

export const ProfileInfoDescription: React.FC = () => {
  const { data: profile } = useProfileByParamId();
  const { isClaimedAddress } = useProfileUtils();

  if (!profile?.description || !isClaimedAddress) return null;

  return <Description text={profile.description} />;
};

const Description = styled(ProfileDescription)(({ theme }) => ({
  marginBottom: 20,
  [theme.betweenDM]: {
    marginBottom: theme.pxToVw(20),
  },
}));
