import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Milestone } from '../../../../types';

type TMode = 'view' | 'create' | 'edit';

export interface IRoadmapStateProvider {
  mode: TMode;
  setMode: Dispatch<SetStateAction<TMode>>;
  milestoneEditing: Milestone | null;
  setMilestoneEditing: Dispatch<SetStateAction<Milestone | null>>;
  isGoalFormOpen: boolean;
  setIsGoalFormOpen: Dispatch<SetStateAction<boolean>>;
}

const RoadmapStateContext = React.createContext<IRoadmapStateProvider | undefined>(
  undefined
);

export const useRoadmapState = (): IRoadmapStateProvider => {
  const context = useContext(RoadmapStateContext);

  if (!context) {
    throw new Error('useRoadmapState cannot be used without RoadmapStateContext');
  }

  return context;
};

interface Props {
  children: React.ReactNode;
}

export const RoadmapStateProvider: React.FC<Props> = ({ children }) => {
  const [mode, setMode] = useState<TMode>('view');
  const [isGoalFormOpen, setIsGoalFormOpen] = useState(false);

  const [milestoneEditing, setMilestoneEditing] = useState<Milestone | null>(null);

  return (
    <RoadmapStateContext.Provider
      value={{
        mode,
        milestoneEditing,
        isGoalFormOpen,
        setMilestoneEditing,
        setMode,
        setIsGoalFormOpen,
      }}
    >
      {children}
    </RoadmapStateContext.Provider>
  );
};
