import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../../../providers/AuthProvider';
import { useAppMessage } from '../../../../providers/AppMessageProvider/AppMessageProvider';

import { splitStringNewLineBreak } from '../../../../utils/string-utils';

import { SupportedDomains } from '../../../../components/common/Address/SupportedDomains';
import { AddressCheckInput } from './AddressCheckInput';
import { AddressInputBackgroundLogo } from '../../../../components/common/AddressInput/AddressInputBackgroundLogo';
import { InputNavigation } from './InputNavigation';
import { ReportAddress } from './ReportAddress/ReportAddress';
import { AppMessage } from '../../../../types';

import Styled from './MainSection.styles';

export const MainSection: React.FC = () => {
  const { t } = useTranslation();
  const { toast } = useAppMessage();
  const [mode, setMode] = useState<'check' | 'report'>('check');
  const { isAuthenticated } = useAuth();

  const elId = useMemo(
    () => (mode === 'check' ? 'homepage_address-input' : 'homepage_address-report'),
    [mode]
  );

  const onModeChange = (nextMode: 'check' | 'report') => {
    if (!isAuthenticated && nextMode === 'report') {
      return toast(AppMessage.RegisterOrSignInToReportAddress);
    }
    setMode(nextMode);
  };

  return (
    <Styled.Wrapper>
      <AddressInputBackgroundLogo elId={elId} />
      <Styled.Container>
        <Styled.Heading>{splitStringNewLineBreak(t('homePage.heading'))}</Styled.Heading>
        <Styled.SearchInfo>{t('homePage.searchInfo')}</Styled.SearchInfo>
        <Styled.InputWrapper>
          <InputNavigation current={mode} onChange={onModeChange} />
          {mode === 'check' ? (
            <>
              <AddressCheckInput />
              <Styled.SocialWrapper>
                <Styled.SocialIcons />
                <SupportedDomains />
              </Styled.SocialWrapper>
            </>
          ) : (
            <ReportAddress />
          )}
        </Styled.InputWrapper>
      </Styled.Container>
    </Styled.Wrapper>
  );
};
