export * from './ButtonBase';
export * from './ButtonPrimary';

export enum ButtonVariant {
  Filled = 'Filled',
  Outlined = 'Outlined',
}

export enum ButtonSize {
  Lg = 'Lg',
  Md = 'Md',
  Sm = 'Sm',
  Xs = 'Xs',
}
