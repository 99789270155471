import styled from 'styled-components';

const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'relative',
}));

const Styled = { Wrapper };

export default Styled;
