import styled from 'styled-components';
import { Heading3 } from '../../ui/text/Heading';
import { Container } from '../Container';

const ContainerEl = styled(Container)(({ theme }) => ({
  position: 'relative',
  minHeight: '60vh',
  maxWidth: '100vw',
  overflow: 'hidden',
  padding: '0 4px',
  [theme.betweenDM]: {
    padding: `0`,
  },
  [theme.min(theme.breakpoints.xs)]: {
    padding: '0',
    minHeight: '80vh',
  },
}));

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  width: '100%',
  height: '100%',
  background: '#000',
  maxWidth: 600,
  padding: '94px 0',
  [theme.betweenDM]: {
    maxWidth: theme.pxToVw(600),
    paddingTop: theme.pxToVw(120),
  },
  [theme.min(theme.breakpoints.xs)]: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    padding: '120px 68px',
    minHeight: '80vh',
  },
}));

const Title = styled(Heading3)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: 20,
  fontWeight: 900,
  [theme.betweenDM]: {
    marginBottom: theme.pxToVw(20),
  },
  [theme.max(theme.breakpoints.sm)]: {
    marginBottom: 12,
  },
}));

const Styled = { Wrapper, Title, ContainerEl };

export default Styled;
