import { useQuery } from 'react-query';
import { UserApi } from '../../api/UserApi/UserApi';
import { useAuth } from '../../providers/AuthProvider';

import { QueryKey } from '../query-keys';

export const useProjectFollowers = (projectId: string | null | undefined) => {
  const { isAuthenticated } = useAuth();

  return useQuery(
    QueryKey.ProjectFollowers(projectId!),
    () => UserApi.getProjectFollowers(projectId!),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      // staleTime: Infinity,
      enabled: !!projectId && isAuthenticated,
    }
  );
};
