import React, { useState } from 'react';
import styled from 'styled-components';

import { useProjectUtils } from '../../../hooks/useProjectUtils';
import { useProjectByParamId } from '../../../../../../react-query/project/useProject';

import { EditCoverPhotoButton } from '../../../../../../components/profile/EditCoverPhotoButton';

import { FollowProjectButton } from './FollowProjectButton';

import { ProjectMenu } from './ProjectMenu';
import { ClaimProjectButton } from './ClaimProjectButton/ClaimProjectButton';

import { UserApi } from '../../../../../../api/UserApi/UserApi';

export const EditProjectActions: React.FC = () => {
  const { isOwner, isClaimedProject } = useProjectUtils();
  const { data: project } = useProjectByParamId();
  const [open, setOpen] = useState(false);

  return (
    <Wrapper>
      {isOwner ? (
        <>
          <EditCoverPhotoButton
            onChange={async formData => {
              await UserApi.setProjectCoverPhoto(project?.uid!, formData);
            }}
          />
          <ProjectMenu
            onToggle={() => !!project && setOpen(prev => !prev)}
            isOpen={open}
            project={project}
          />
        </>
      ) : (
        <>{isClaimedProject ? <FollowProjectButton /> : <ClaimProjectButton />}</>
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  gap: 10,
  '& button': {
    '&:first-of-type': {
      width: 140,
    },
    '&:last-of-type': {
      width: 180,
    },
  },
  [theme.min(theme.breakpoints.xs)]: {},
  [theme.min(theme.breakpoints.sm)]: {
    position: 'absolute',
    right: 0,
    top: 320,
  },
  [theme.min(theme.breakpoints.md)]: {
    position: 'initial',
    right: 'auto',
    top: 'auto',
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(10),
    '& button': {
      '&:first-of-type': {
        width: theme.pxToVw(140),
      },
      '&:last-of-type': {
        width: theme.pxToVw(180),
      },
    },
  },
}));
