import React from 'react';
import styled from 'styled-components';

interface Props {
  label?: string;
  value?: string | number;
  centered?: boolean;
  children?: React.ReactNode;
}

export const NotEditableInputPlaceholder: React.FC<Props> = ({
  label,
  value,
  children = null,
  ...otherProps
}) => {
  return (
    <Wrapper {...otherProps}>
      {label && <Label>{label}</Label>}
      {value && <Value>{value}</Value>}
      {children}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  background: 'rgba(0, 101, 252, 0.1)',
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  padding: '9px 20px 0 20px',
  height: 56,
  [theme.betweenDM]: {
    borderRadius: theme.pxToVw(10),
    padding: `${theme.pxToVw(9)} ${theme.pxToVw(20)} 0 ${theme.pxToVw(20)}`,
    height: theme.pxToVw(56),
  },
}));

const Label = styled('label')(({ theme }) => ({
  color: theme.colors.text.whiteTransparent50,
  fontSize: 11,
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(11),
  },
}));

const Value = styled('span')(({ theme }) => ({
  color: theme.colors.primary.main,
  fontSize: 14,
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(14),
  },
}));
