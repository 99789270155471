import styled from 'styled-components';
import MilestoneLabel from '../../../../../components/project/roadmap/MilestoneLabel';
import { useRoadmapState } from '../RoadmapStateProvider';

export default function AddNewMilestone() {
  const { setMode, setMilestoneEditing, milestoneEditing } = useRoadmapState();
  return (
    <MilestoneLabel
      onClick={() => {
        setMode('create');
        setMilestoneEditing(null);
      }}
      fontSize={{ mobile: 20, desktop: 20 }}
      circleProps={{
        text: <PlusIcon />,
        isActive: !milestoneEditing,
        size: {
          mobile: 55.5,
          desktop: 55.5,
        },
      }}
      labelProps={{
        text: 'Add New Milestone',
      }}
    />
  );
}

const PlusIcon = () => (
  <Svg
    width='19'
    height='19'
    viewBox='0 0 19 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.30177 18.5713V10.6365H0.5V8.00612H8.30177V0.0712891H11.1982V8.00612H19V10.6365H11.1982V18.5713H8.30177Z'
      fill='white'
    />
  </Svg>
);

const Svg = styled('svg')(({ theme }) => ({
  [theme.betweenDM]: {
    height: theme.pxToVw(18.5),
    width: 'auto',
  },
}));
