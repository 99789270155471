import React from 'react';
import styled from 'styled-components';
import { usePlaceholderItems } from '../../../../../hooks/usePlaceholderItems';
import { useWindowSizeUtils } from '../../../../../hooks/useWindowSizeUtils';

import { NewsFeedProfileCardLoader } from './NewsFeedProfileCard';

interface Props {
  children: React.ReactNode;
  isLoading?: boolean;
}

export const NewsFeedGrid: React.FC<Props> = ({ children, isLoading, ...otherProps }) => {
  const { isXs, isMd } = useWindowSizeUtils();

  const loadingItems = usePlaceholderItems(
    isXs ? 4 : isMd ? 5 : 6,
    NewsFeedProfileCardLoader
  );

  return (
    <Wrapper {...otherProps}>
      {children}
      {isLoading && loadingItems}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(100px, 1fr))',
  overflowY: 'visible',
  rowGap: 20,
  columnGap: 16,
  position: 'relative',
  padding: 2,
  [theme.min(theme.breakpoints.xs)]: {
    gridTemplateColumns: 'repeat(auto-fit, minmax(160px, 1fr))',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    gridTemplateColumns: 'repeat(6, 1fr)',
    rowGap: theme.pxToVw(20),
    columnGap: theme.pxToVw(20),
  },
  [theme.min(theme.breakpoints.max)]: {
    rowGap: 20,
    columnGap: 20,
  },
}));
