import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Logo } from '../../../assets/svgs/LogoWhite.svg';

const Spinner = styled(Logo)(({ theme }) => ({
  animation: 'spin 2s infinite linear',
  ...theme.keyframes.spin,
  // height: '12rem !important',
}));

const Wrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Text = styled('span')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  color: theme.colors.text.primary,
  fontSize: '1.4rem',
  fontWeight: 600,
}));

interface Props {
  text?: string;
  size?: number;
  containerStyles?: React.CSSProperties;
}

export const LogoSpinner: React.FC<Props> = ({
  size = 54,
  text,
  containerStyles = {},
  ...otherProps
}) => {
  return (
    <Wrapper style={{ height: size + 30, minHeight: size + 30, ...containerStyles }}>
      <Spinner {...otherProps} style={{ height: size, fontSize: size, width: 'auto' }} />
      {text && <Text>{text}</Text>}
    </Wrapper>
  );
};
