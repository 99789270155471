import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Navigate, useParams } from 'react-router-dom';

import { LoadingProvider } from '../../../providers/LoadingProvider';
import {
  AddressProvider,
  useAddress,
} from '../../../providers/AddressProvider/AddressProvider';

import {
  TrustCategoryProvider,
  useTrustCategory,
} from '../../../components/common/AnalysisResult/TrustCategoryProvider';

import {
  isNonNftProjectError,
  useProjectByParamId,
} from '../../../react-query/project/useProject';
import { useReputationWithAddress } from '../../../providers/AddressProvider/useReputationWithAddress';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import { useWindowSizeUtils } from '../../../hooks/useWindowSizeUtils';

import { ProfilePageMainSection } from '../../../components/profile/profile-elements';
import { ProjectInfo } from './components/ProjectInfo/ProjectInfo';
import { ProjectReputation } from './components/ProjectReputation/ProjectReputation';

import { Container } from '../../../components/layouts/Container';
import { ProjectCharts } from './components/ProjectCharts/ProjectCharts';
import { GenerateProfileSection } from '../../../components/common/GenerateProfile/GenerateProfileSection';
import { NonNftProjectContent } from './components/ProjectInfo/NonNftProjectContent/NonNftProjectContent';

import { routes } from '../../../router/routes';

import { isValidEthAddress } from '../../../utils/address-utils';
import ProjectRoadmap from './components/ProjectRoadmap/ProjectRoadmap';

export const ProjectProfilePage: React.FC = () => {
  const { data, error } = useProjectByParamId();

  useDocumentTitle(data?.title);

  const isNonNft = isNonNftProjectError(error);

  if (error && !isNonNft)
    return <Navigate to={routes.error(error?.response?.status || 500)} />;

  return (
    <>
      <LoadingProvider>
        <AddressProvider>
          <TrustCategoryProvider>
            <Content />
          </TrustCategoryProvider>
        </AddressProvider>
      </LoadingProvider>
      <GenerateProfileSection />
    </>
  );
};

const Content: React.FC = () => {
  const { project: projectParam } = useParams();
  const { data: project, error } = useProjectByParamId();
  const { address, setAddress } = useAddress();
  const { data } = useReputationWithAddress();
  const { isXs, isSm, isMd } = useWindowSizeUtils();
  const { setTrustByTrustPercentage } = useTrustCategory();

  const isNonNft = isNonNftProjectError(error);

  useEffect(() => {
    if (!data) return;
    setTrustByTrustPercentage(data?.trustPercentage);
  }, [data, setTrustByTrustPercentage]);

  const { contractAddress } = project || {};

  useEffect(() => {
    if (
      !isNonNft ||
      !isValidEthAddress(projectParam) ||
      (address && address.toLowerCase() === projectParam?.toLowerCase())
    )
      return;

    setAddress(projectParam);
  }, [isNonNft, projectParam, address, setAddress]);

  useEffect(() => {
    if (
      !contractAddress ||
      (contractAddress &&
        address &&
        contractAddress.toLowerCase() === address.toLowerCase())
    )
      return;
    setAddress(contractAddress);
  }, [address, setAddress, contractAddress]);

  return (
    <ProfilePageMainSection imgUrl={project?.coverPhotoUrl}>
      <TopContentWrapper isNonNft={isNonNft}>
        <ProjectInfo />
        <ProjectReputation />
        {isNonNft && (isXs || isSm || isMd) && <NonNftProjectContent />}
      </TopContentWrapper>
      {/* <Spacer /> */}
      <Spacer />
      <ProjectRoadmap />
      {!isNonNft && <ProjectCharts />}
    </ProfilePageMainSection>
  );
};

const TopContentWrapper = styled(Container)<{ isNonNft: boolean }>(
  ({ theme, isNonNft }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 56,
    position: 'relative',
    [theme.min(theme.breakpoints.md)]: {
      marginBottom: isNonNft ? 200 : 0,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    [theme.betweenDM]: {
      gap: theme.pxToVw(56),
      marginBottom: isNonNft ? theme.pxToVw(200) : 0,
    },
  })
);

const Spacer = styled('div')(({ theme }) => ({
  content: '" "',
  display: 'block',
  height: 120,
  [theme.min(theme.breakpoints.md)]: {
    height: 180,
  },
  [theme.betweenDM]: {
    height: theme.pxToVw(220),
  },
}));
