import React, { useContext } from 'react';

export type IModeContext = string;

const ModeContext = React.createContext<IModeContext | undefined>(undefined);

export const useMode = (): IModeContext => {
  const context = useContext(ModeContext);

  if (!context) {
    throw new Error('useMode cannot be used without ModeContext');
  }

  return context;
};

interface Props {
  children: React.ReactNode;
  mode: string;
}

export const ModeProvider: React.FC<Props> = ({ children, mode }) => {
  return <ModeContext.Provider value={mode}>{children}</ModeContext.Provider>;
};
