import { useQuery, useQueryClient } from 'react-query';
import { UserApi } from '../../api/UserApi/UserApi';
import { useAuth } from '../../providers/AuthProvider';
import { QueryKey } from '../query-keys';

export const useUser = () => {
  const { isAuthenticated } = useAuth();
  const queryClient = useQueryClient();

  return useQuery(QueryKey.User(), UserApi.getProfile, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: isAuthenticated,
    keepPreviousData: true,
    onSuccess: data => {
      queryClient.setQueryData(QueryKey.Profile(data.username), data);
    },
  });
};
