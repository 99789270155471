import React from 'react';

import { useFollowProject } from '../../../hooks/useFollowProject';
import { useProjectUtils } from '../../../pages/project/profile/hooks/useProjectUtils';

import { FollowButtonBase } from '../../ui/buttons/FollowButtonBase';

interface Props {
  contractAddress: string;
  projectUid: string;
}

export const FollowProjectProfileButton: React.FC<Props> = ({
  contractAddress,
  projectUid,
  ...otherProps
}) => {
  const { call, isLoading: requestLoading } = useFollowProject({
    contractAddress,
    uid: projectUid,
  });
  const { isFollowedProject, isProjectOwner } = useProjectUtils();

  const isFollowed = isFollowedProject(contractAddress);
  const isOwner = isProjectOwner(contractAddress);

  const isLoading = requestLoading;

  if (isOwner) return null;

  return (
    <FollowButtonBase
      onClick={() => call(true)}
      isLoading={isLoading}
      isFollowed={isFollowed}
      {...otherProps}
    />
  );
};
