import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { joiLib } from '../../../joi';
import { PostProjectRequestPayload } from '../../../api/UserApi/UserApi';
import { profileDetailsSchema } from '../../profile/EditProfileDetailsModal/useEditProfileDetailsForm';

const createSchema = profileDetailsSchema.keys({
  contractAddress: joiLib.ethAddress,
  title: Joi.string().min(2),
  signature: Joi.string(),
  externalUrl: Joi.string().uri({ allowRelative: true }).allow('', null),
  twitterUrl: joiLib.matchSocialAddress('twitter').allow('', null),
  discordUrl: joiLib.matchSocialAddress('discord').allow('', null),
  openseaUrl: joiLib.matchSocialAddress('opensea').allow('', null),
  description: Joi.string().allow('', null),
  photoUrl: Joi.string().allow('', null),
});

const editSchema = profileDetailsSchema.keys({
  contractAddress: joiLib.ethAddress,
  title: Joi.string().min(2),
  signature: Joi.string().allow('', null),
  externalUrl: Joi.string().uri({ allowRelative: true }).allow('', null),
  twitterUrl: joiLib.matchSocialAddress('twitter').allow('', null),
  discordUrl: joiLib.matchSocialAddress('discord').allow('', null),
  openseaUrl: joiLib.matchSocialAddress('opensea').allow('', null),
  description: Joi.string().allow('', null),
  photoUrl: Joi.string().allow('', null),
});

const defaultValues = {
  contractAddress: '',
  title: '',
  signature: '',
  externalUrl: null,
  twitterUrl: null,
  discordUrl: null,
  openseaUrl: null,
  description: null,
  photoUrl: null,
};

export const useProjectDetailsForm = (
  initValue?: Omit<PostProjectRequestPayload, 'signature'> & { signature?: string },
  editForm?: boolean
) => {
  const form = useForm<PostProjectRequestPayload>({
    defaultValues: initValue ?? defaultValues,
    resolver: joiResolver(editForm ? editSchema : createSchema),
    reValidateMode: 'onChange',
    mode: 'onBlur',
  });

  return form;
};
