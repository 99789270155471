import { useInfiniteQuery } from 'react-query';
import { TransactionsApi } from '../../api/TransactionsApi';

import { useAddress } from '../../providers/AddressProvider/AddressProvider';

import { QueryKey } from '../query-keys';
import { TransactionType } from '../../types';
import { isValidEthAddress } from '../../utils/address-utils';
// import { getPaginatedDataResults, getPaginatedResultsTotalCount } from '../utils';

interface Props {
  params: {
    search: string;
    sort: 'asc' | 'desc';
    txType: TransactionType;
  };
}

export const useTransactions = ({ params }: Props) => {
  const { search, sort, txType } = params;
  const { address } = useAddress();

  const query: Partial<Record<'search' | 'sort' | 'pageKey', string>> & {
    txType?: TransactionType;
  } = {};
  if (search) query.search = search;
  if (sort) query.sort = sort;
  if (txType.length) query.txType = txType;

  const { data, ...rest } = useInfiniteQuery(
    QueryKey.ProjectTransactions(address!, [txType], sort, search),
    ({ pageParam = 1 }) =>
      TransactionsApi.getByAddress(address!, {
        page: pageParam,
        ...query,
      }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: !!address && isValidEthAddress(address),
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < 20) return undefined;
        return allPages.length + 1;
        // const totalResultsFetched = getPaginatedResultsTotalCount(allPages);
        // if (totalResultsFetched === lastPage.totalCount) return undefined;
        // return lastPage.nextPage;
      },
    }
  );

  return {
    data,
    formattedData: data ? data.pages.flat() : undefined,
    // totalCount: data?.pages[0]?.totalCount,
    totalCount: 0,
    ...rest,
  };
};
