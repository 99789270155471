import React from 'react';
import styled from 'styled-components';

import { ProjectInfoSocialIcons } from './ProjectInfoSocialIcons';
import { ProjectOwner } from './ProjectOwner';
import { ProjectName } from './ProjectName';

import { ProjectCreatedAtAndContractAddress } from './ProjectCreatedAtAndContractAddress';

export const ProjectNameAndDetails: React.FC = () => {
  return (
    <Col>
      <ProjectName />
      <ProjectCreatedAtAndContractAddress />
      <ProjectInfoSocialIcons />
      <ProjectOwner />
    </Col>
  );
};

export const NonNftProjectNameAndDetails: React.FC = () => {
  return (
    <Col>
      <ProjectName />
      <ProjectCreatedAtAndContractAddress />
    </Col>
  );
};

const Col = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 14,
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(12),
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 12,
  },
}));
