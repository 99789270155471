import { useQuery } from 'react-query';
import { ProjectApi } from '../../api/ProjectApi/ProjectApi';

import { QueryKey } from '../query-keys';

export const usePopularProjects = () => {
  return useQuery(QueryKey.PopularProjects(), ProjectApi.getPopularProjects, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: false,
    onError: console.log,
  });
};
