import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PostProjectRequestPayload } from '../../../api/UserApi/UserApi';

import { TextareaBase } from '../../ui/forms/Textarea/TextareaBase';
import { FieldWrapper } from './FieldWrapper';

export const ProjectDescription: React.FC = () => {
  const { t } = useTranslation();
  const { register } = useFormContext<PostProjectRequestPayload>();
  return (
    <FieldWrapper label={t('projectPage.projectDescription')}>
      <TextareaBase
        {...register('description')}
        rows={4}
        style={{ minHeight: 72, maxHeight: 100 }}
      />
    </FieldWrapper>
  );
};
