import { useState } from 'react';
import styled from 'styled-components';
import {
  DropdownMenuBase,
  DropdownMenuVariant,
} from '../../../../../components/ui/menus/DopdownMenu/DropdownMenuBase';
import { ConfirmationModal } from '../../../../../components/ui/modals/ConfirmationModal/ConfirmationModal';
import { useProjectByParamId } from '../../../../../react-query/project/useProject';
import { useProjectMilestoneDelete } from '../../../../../react-query/user/project/useProjectMilestoneDelete';
import { Milestone } from '../../../../../types';
import { useRoadmapState } from '../RoadmapStateProvider';

interface Props {
  milestone: Milestone;
}

export default function MilestoneMenu({ milestone }: Props) {
  const { data: project } = useProjectByParamId();
  const { setMilestoneEditing, setMode, milestoneEditing } = useRoadmapState();
  const { mutate: deleteMilestone } = useProjectMilestoneDelete();

  const [menuOpen, setMenuOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const menuItems = [
    {
      label: 'Edit Milestone',
      onClick: () => {
        setMode('edit');
        setMilestoneEditing(milestone);
      },
    },
    {
      label: 'Delete Milestone',
      onClick: () => {
        setShowConfirm(true);
      },
    },
  ];

  return (
    <div style={{ position: 'absolute', right: '5%' }}>
      <DropdownMenuBase
        isOpen={menuOpen}
        Trigger={<Dots isOpen={menuOpen} />}
        align='start'
        onToggle={() => setMenuOpen(prev => !prev)}
        menuItems={menuItems}
        onClickOutside={() => setMenuOpen(false)}
        menuVariant={DropdownMenuVariant.Secondary}
      />
      {showConfirm && (
        <ConfirmationModal
          title='Are you sure you want to remove this milestone?'
          description={`Milestone ${milestone.title} will be removed.`}
          onConfirm={() => {
            deleteMilestone({ projectUid: project!.uid, milestoneUid: milestone.uid });
            setShowConfirm(false);
            if (milestone.uid === milestoneEditing?.uid) {
              setMilestoneEditing(null);
              setMode('view');
            }
          }}
          onCancel={() => setShowConfirm(false)}
        />
      )}
    </div>
  );
}

const Dots = ({ isOpen }: { isOpen: boolean }) => (
  <DotsWrapper className={`dots-icon ${isOpen ? 'dots-icon-open' : ''}`}>
    <svg
      width='20'
      height='4'
      viewBox='0 0 20 4'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='2' cy='2' r='2' fill={isOpen ? '#0065FC' : '#fff'} />
      <circle cx='10' cy='2' r='2' fill={isOpen ? '#0065FC' : '#fff'} />
      <circle cx='18' cy='2' r='2' fill={isOpen ? '#0065FC' : '#fff'} />
    </svg>
  </DotsWrapper>
);

const DotsWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  transition: 'all .3s',
  padding: 4,
  paddingRight: 0,
  '&:hover': {
    opacity: 0.75,
  },
  [theme.betweenDM]: {
    '& svg': {
      width: theme.pxToVw(20),
      height: 'auto',
    },
  },
}));
