import React from 'react';

export const XMarkIcon = () => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_856_10163)'>
        <path
          d='M9.17017 11L0.379488 2.2093C-0.126497 1.70332 -0.126497 0.885474 0.379488 0.379489C0.885473 -0.126496 1.70332 -0.126496 2.2093 0.379489L11 9.17016L19.7907 0.379489C20.2967 -0.126496 21.1145 -0.126496 21.6205 0.379489C22.1265 0.885474 22.1265 1.70332 21.6205 2.2093L12.8298 11L21.6205 19.7907C22.1265 20.2966 22.1265 21.1145 21.6205 21.6205C21.3682 21.8728 21.0369 21.9996 20.7056 21.9996C20.3743 21.9996 20.043 21.8728 19.7907 21.6205L11 12.8298L2.20934 21.6205C1.957 21.8728 1.62572 21.9996 1.29444 21.9996C0.96316 21.9996 0.63188 21.8728 0.379532 21.6205C-0.126453 21.1145 -0.126453 20.2966 0.379532 19.7907L9.17017 11Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_856_10163'>
          <rect width='22' height='22' fill='white' transform='matrix(-1 0 0 1 22 0)' />
        </clipPath>
      </defs>
    </svg>
  );
};
