import { useState } from 'react';
import { Popover } from 'react-tiny-popover';
import styled from 'styled-components';
import {
  ActionText,
  ActionTextVariant,
} from '../../../../../../../../components/ui/text/ActionText';
import { MilestoneGoal } from '../../../../../../../../types';
import { DoneIcon } from './DoneIcon';
import GoalDetailsPopup from './GoalDetailsPopup';

interface Props {
  goal: MilestoneGoal;
  isInProgress: boolean;
}

const MilestoneGoalComponent = ({ goal, isInProgress }: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  return (
    <OutsideWrapper style={{ position: 'relative' }} isHovered={isHovered}>
      <Popover
        isOpen={showDetails}
        positions={['bottom']}
        containerStyle={{ zIndex: '100' }}
        onClickOutside={() => setShowDetails(false)}
        content={popoverState => {
          return <GoalDetailsPopup goal={goal} onClose={() => setShowDetails(false)} />;
        }}
      >
        <Wrapper
          onMouseEnter={() => setIsHovered(true)} // edit if needed
          onMouseLeave={() => setIsHovered(false)}
        >
          <Cirlce
            className='small-circle'
            isHovered={isHovered}
            isDone={goal.isFulfilled}
            isInProgress={isInProgress}
          />

          <ActText
            onClick={() => setShowDetails(true)}
            variant={
              goal.isFulfilled ? ActionTextVariant.Inverted : ActionTextVariant.Regular
            }
            underline={false}
          >
            {goal.title}
          </ActText>
          {goal.isFulfilled && <DoneIcon />}
        </Wrapper>
      </Popover>
    </OutsideWrapper>
  );
};

const OutsideWrapper = styled('div')<{ isHovered: boolean }>(({ theme, isHovered }) => ({
  position: 'relative',
}));

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  cursor: 'pointer',
  zIndex: theme.zIndex.sm,
}));

const ActText = styled(ActionText)(({ theme }) => ({
  fontSize: 14,
  textTransform: 'uppercase',
  fontWeight: 700,
  marginLeft: 22,
  '&:hover': {
    textDecoration: 'underline !important',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(16),
    marginLeft: theme.pxToVw(22),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 16,
    marginLeft: 22,
  },
}));

const Cirlce = styled('div')<{
  isHovered: boolean;
  isDone: boolean;
  isInProgress: boolean;
}>(({ theme, isHovered, isDone, isInProgress }) => ({
  width: isHovered ? 12 : 8,
  height: isHovered ? 12 : 8,
  display: 'flex',
  transition: 'all .1s',
  position: 'absolute',
  left: 0,
  top: '50%',
  bottom: isHovered ? 6 : 6,
  zIndex: 1,
  backgroundColor:
    isInProgress && !isDone ? '#fff' : isDone ? theme.colors.primary.main : '#3D4859',
  borderRadius: '50%',
  transform: `translateX(-50%) translateY(-50%)`,
}));

export default MilestoneGoalComponent;
