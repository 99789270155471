import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useWindowWidth } from '../../../hooks/useWindowWidth';

import { ButtonPrimary, ButtonSquarePrimary } from '../../ui/buttons';
import { ErrorMessage } from '../../ui/forms/FormFieldWrapper/ErrorMessage';
import { InputBase } from '../../ui/forms/Input/InputBase';
import { Spinner } from '../../ui/loaders/Spinner';

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onSubmit'> {
  initValue?: string;
  error?: string | null;
  isLoading?: boolean;
  withTextButton?: boolean;
  isSuccess?: boolean;
  onSubmit?: (val: string) => void;
}

export const AddressInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      initValue,
      error,
      isLoading,
      withTextButton = false,
      placeholder,
      isSuccess,
      onSubmit,
      onChange,
      ...otherProps
    },
    ref
  ) => {
    const [search, setSearch] = useState(initValue || '');

    useEffect(() => {
      if (isSuccess) setSearch('');
    }, [isSuccess]);

    const handleSubmit = () => {
      if (typeof onSubmit !== 'function') return;
      onSubmit(search);
    };

    return (
      <Wrapper>
        <InputWrapper>
          <AddressInputEl
            id={id}
            ref={ref}
            value={search}
            error={!!error}
            onChange={e => {
              const value = e.target.value.trim().toLowerCase();
              setSearch(value);
              if (typeof onChange === 'function') onChange(e);
            }}
            placeholder={placeholder}
            onKeyDown={e => {
              if (isLoading || e.key !== 'Enter') return;
              handleSubmit();
            }}
            hasButton={typeof onSubmit === 'function'}
            {...otherProps}
          />
          {typeof onSubmit === 'function' && (
            <Button
              onClick={handleSubmit}
              isLoading={!!isLoading}
              withTextButton={withTextButton}
            />
          )}
        </InputWrapper>
        {error && <ErrMsg error={error} />}
      </Wrapper>
    );
  }
);

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  overflow: 'visible',
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(30),
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 30,
  },
}));

const InputWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  overflow: 'visible',
}));

const ErrMsg = styled(ErrorMessage)(({ theme }) => ({
  fontSize: 14,
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(16),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 16,
  },
}));

const AddressInputEl = styled(InputBase)<{ hasButton: boolean }>(
  ({ theme, hasButton }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100% !important',
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: hasButton ? 64 : 16,
    paddingLeft: 16,
    fontSize: 16,
    height: 56,
    WebkitAppearance: 'none',
    '&::placeholder': {
      color: theme.colors.primary.main,
      fontSize: 16,
    },
    [theme.min(theme.breakpoints.sm)]: {
      fontSize: 20,
      height: 72,
      paddingLeft: 24,
      paddingRight: hasButton ? 24 : 16,
      '&::placeholder': {
        fontSize: 20,
      },
    },
    [theme.min(theme.breakpoints.md)]: {
      paddingRight: hasButton ? 300 : 16,
      borderRadius: 20,
      fontSize: 22,
      height: 86,

      '&::placeholder': {
        fontSize: 22,
      },
    },
    [theme.min(theme.breakpoints.desktop)]: {
      height: theme.pxToVw(122),
      paddingRight: theme.pxToVw(340),
      fontSize: theme.pxToVw(32),
      paddingLeft: theme.pxToVw(38),
      '&::placeholder': {
        fontSize: theme.pxToVw(32),
      },
    },
    // [theme.min(theme.breakpoints.max)]: {
    //   height: 122,
    //   paddingRight: 340,
    //   fontSize: 32,
    //   paddingLeft: 38,
    //   '&::placeholder': {
    //     fontSize: 32,
    //   },
    // },
  })
);

const Button: React.FC<{
  onClick: () => void;
  isLoading: boolean;
  withTextButton?: boolean;
}> = ({ onClick, isLoading, withTextButton = false }) => {
  const width = useWindowWidth();
  const theme = useTheme();
  const { t } = useTranslation('common');

  return width < theme.breakpoints.md || !withTextButton ? (
    // @ts-ignore
    <ButtonSmallScreen onClick={onClick}>
      {isLoading ? (
        <Spinner />
      ) : (
        <svg
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M18.7497 17.723L18.9269 17.5432L18.7452 17.3677L14.3831 13.1556C15.3464 11.8718 15.8744 10.3039 15.8849 8.68708C15.8959 6.99375 15.3386 5.34662 14.3041 4.01679C13.2695 2.68689 11.8191 1.75341 10.1912 1.37088C8.56316 0.98833 6.85541 1.17977 5.34919 1.91342C3.84303 2.64703 2.62863 3.8788 1.90508 5.40525C1.18155 6.93163 0.991654 8.66224 1.36639 10.3125C1.74113 11.9627 2.65839 13.4351 3.9681 14.4868C5.27761 15.5383 6.90131 16.1066 8.5721 16.0973C10.2005 16.1015 11.7805 15.551 13.0605 14.5382L17.3886 18.7505L17.5666 18.9238L17.741 18.7468L18.7497 17.723ZM8.57171 14.1554C7.49809 14.1554 6.44827 13.8323 5.55499 13.2265C4.66166 12.6206 3.96483 11.7591 3.55321 10.7505C3.14156 9.74185 3.0338 8.63173 3.2437 7.56071C3.4536 6.48969 3.9716 5.50641 4.73164 4.73499C5.49164 3.96362 6.45949 3.43874 7.51258 3.22613C8.56565 3.01353 9.65722 3.12261 10.6494 3.53974C11.6417 3.9569 12.4904 4.66358 13.0877 5.571C13.6852 6.47846 14.0042 7.54573 14.0042 8.63782C14.0042 10.1024 13.431 11.5062 12.4118 12.5407C11.3927 13.575 10.0113 14.1554 8.57171 14.1554Z'
            fill='white'
            stroke='white'
            strokeWidth='0.5'
          />
        </svg>
      )}
    </ButtonSmallScreen>
  ) : (
    <ButtonDesktop onClick={onClick}>
      {isLoading ? <Spinner /> : t('generateProfile')}
    </ButtonDesktop>
  );
};

const ButtonSmallScreen = styled(ButtonSquarePrimary)(({ theme }) => ({
  position: 'absolute',
  right: 4,
  width: 48,
  height: 48,
  '& .spinner': {
    ...theme.keyframes.spin,
  },
  [theme.min(theme.breakpoints.sm)]: {
    height: 54,
    width: 54,
    right: 8,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    height: theme.pxToVw(77),
    width: theme.pxToVw(77),
    fontSize: theme.pxToVw(24),
    right: theme.pxToVw(32),
  },
  [theme.min(theme.breakpoints.max)]: {
    height: 77,
    width: 77,
    fontSize: 24,
    right: 32,
  },
}));

const ButtonDesktop = styled(ButtonPrimary)(({ theme }) => ({
  position: 'absolute',
  right: 10,
  height: 66,
  width: 272,
  fontSize: 18,
  '& .spinner': {
    position: 'absolute',
    left: '50%',
    marginLeft: -10,
    ...theme.keyframes.spin,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    height: theme.pxToVw(77),
    width: theme.pxToVw(317),
    fontSize: theme.pxToVw(24),
    right: theme.pxToVw(32),
  },
  [theme.min(theme.breakpoints.max)]: {
    height: 77,
    width: 317,
    fontSize: 24,
    right: 32,
  },
}));
