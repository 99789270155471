import React, { useCallback, useContext, useState } from 'react';
import { Trust } from '../../../types';

import { getTrustPercentageCategory } from '../../../utils/getTrustPercentageCategory';

export interface ITrustCategoryContext {
  trust: Trust | undefined;
  setTrustByTrustPercentage: (trustPercentage: number) => void;
}

const TrustCategoryContext = React.createContext<ITrustCategoryContext | undefined>(
  undefined
);

export const useTrustCategory = (): ITrustCategoryContext => {
  const context = useContext(TrustCategoryContext);

  if (!context) {
    throw new Error('useTrustCategory cannot be used without TrustCategoryContext');
  }

  return context;
};

interface Props {
  children: React.ReactNode;
  initTrust?: Trust;
}

export const TrustCategoryProvider: React.FC<Props> = ({ children, initTrust }) => {
  const [trust, setTrust] = useState(initTrust);

  const setTrustByTrustPercentage = useCallback((trustPercentage: number) => {
    setTrust(getTrustPercentageCategory(trustPercentage));
  }, []);

  return (
    <TrustCategoryContext.Provider value={{ trust, setTrustByTrustPercentage }}>
      {children}
    </TrustCategoryContext.Provider>
  );
};
