import React, { useState, Fragment } from 'react';
import Select, { GroupBase, StylesConfig } from 'react-select';
import styled, { useTheme } from 'styled-components';

import { Theme } from '../../../../config/theme';

export interface SelectOption<V = any> {
  label: string | number;
  value: V;
}

export interface SelectBaseComponentProps {
  options: SelectOption[];
  error?: string | boolean;
  label?: string;
  isLoading?: boolean;
  width?: number | string;
  name?: string;
  isMulti?: boolean;
  required?: boolean;
  value?: any;
  placeholder?: string;
  isSearchable?: boolean;
  showArrows?: boolean;
  isPrimaryColor?: boolean;
  keepLabelWithSelectedValue?: boolean;
  onChange: (val: any) => void;
}

type TStyles = StylesConfig<
  SelectOption<string | number | boolean | null>,
  false,
  GroupBase<SelectOption<string | number | boolean | null>>
>;

export const getSelectStyles = (
  theme: Theme,
  width: number | string = '100%',
  error: boolean | string,
  isLoading: boolean,
  isPrimaryColor: boolean
): TStyles => {
  return {
    container: (provided, state) => ({
      ...provided,
      fontSize: 14,
      fontWeight: 700,
      fontFamily: theme.fontFamily,
      position: 'relative',
      width,
      minHeight: '40px !!!important',
      maxHeight: '40px !!!important',
      color: '#fff',
      backgroundColor: 'transparent',
      [theme.min(theme.breakpoints.xs)]: {
        minHeight: '48px !!!important',
        maxHeight: '48px !!!important',
        fontSize: 16,
      },
      [theme.betweenDM]: {
        minHeight: `${theme.pxToVw(48)} !!!important`,
        maxHeight: `${theme.pxToVw(48)} !!!important`,
        fontSize: theme.pxToVw(16),
      },
    }),
    control: (provided, state) => ({
      ...provided,
      outline: 'none',
      boxShadow: 'none',
      border: `2px solid ${
        error
          ? theme.colors.status.error
          : state.menuIsOpen
          ? theme.colors.primary.main
          : isPrimaryColor
          ? theme.colors.primary.main
          : '#fff'
      }`,
      maxHeight: 40,
      minHeight: 40,

      maxWidth: width,
      overflow: 'hidden',
      width,
      backgroundColor: isPrimaryColor ? theme.colors.primary.main : 'transparent',
      borderRadius: 10,
      cursor: 'pointer',
      transition: 'all .3s',
      '&:hover': {
        border: `2px solid ${theme.colors.primary.main}`,
      },
      [theme.min(theme.breakpoints.xs)]: {
        maxHeight: 48,
        minHeight: 48,
      },
      [theme.betweenDM]: {
        minHeight: `${theme.pxToVw(48)}`,
        maxHeight: `${theme.pxToVw(48)}`,
      },
    }),
    input: provided => ({
      ...provided,
    }),
    placeholder: provided => ({
      ...provided,
      color: '#fff',
      fontSize: 14,
      fontWeight: 700,
      margin: 0,
      [theme.min(theme.breakpoints.xs)]: {
        fontSize: 16,
      },
      [theme.betweenDM]: {
        fontSize: theme.pxToVw(16),
      },
    }),
    valueContainer: provided => ({
      ...provided,
      padding: '2px 20px',
      [theme.max(theme.breakpoints.xs)]: {
        padding: '2px 16px',
      },
      [theme.betweenDM]: {
        padding: `${theme.pxToVw(2)} ${theme.pxToVw(16)}`,
      },
    }),
    multiValue: provided => ({
      ...provided,
    }),
    indicatorsContainer: (provided, state) => ({
      padding: '0 !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      height: '100%',
      width: '20px',
      cursor: 'pointer',
      maxHeight: 24,
      minHeight: 24,
      marginLeft: 'auto',
      position: 'absolute',
      right: isLoading ? -36 : 8,
      [theme.min(theme.breakpoints.xs)]: {
        maxHeight: 30,
        minHeight: 30,
      },
      [theme.betweenDM]: {
        minHeight: `${theme.pxToVw(30)}`,
        maxHeight: `${theme.pxToVw(30)}`,
      },
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#fff',
      transition: 'all .3s',
      '&:hover': {
        color: isPrimaryColor ? '#fff' : theme.colors.primary.main,
      },
    }),
    menuList: provided => ({
      ...provided,
      padding: 0,
      fontSize: 14,
      fontWeight: 700,
      background: theme.colors.primary.main,
      [theme.min(theme.breakpoints.xs)]: {
        fontSize: 16,
      },
      [theme.betweenDM]: {
        fontSize: theme.pxToVw(16),
      },
    }),
    menu: provided => ({
      ...provided,
      padding: 0,
      overflow: 'hidden',
      borderRadius: 10,
      marginTop: 4,
      border: 'none',
      boxShadow: 'none',
      [theme.betweenDM]: {
        borderRadius: theme.pxToVw(10),
        marginTop: theme.pxToVw(4),
      },
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      color: '#fff',
      backgroundColor: theme.colors.primary.main,
      height: 36,
      transition: 'all .3s',
      '&:hover': {
        backgroundColor: '#0046ae',
      },
      [theme.min(theme.breakpoints.xs)]: {
        height: 42,
      },
      [theme.betweenDM]: {
        height: theme.pxToVw(42),
      },
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: 14,
      fontWeight: 700,
      color: '#fff',
      margin: 0,
      [theme.min(theme.breakpoints.xs)]: {
        fontSize: 16,
      },
      [theme.betweenDM]: {
        fontSize: theme.pxToVw(16),
      },
    }),
    clearIndicator: () => ({ display: 'none' }),
  };
};

export const SelectBase = React.forwardRef<any, SelectBaseComponentProps>(
  (
    {
      options,
      error,
      isLoading = false,
      width,
      placeholder,
      name,
      isMulti = false,
      value,
      required,
      isSearchable = false,
      onChange,
      showArrows,
      isPrimaryColor = false,
      keepLabelWithSelectedValue = false,
    },
    ref
  ) => {
    const theme = useTheme();
    const [currentValue, setValue] = useState(value);
    const [menuOpen, setMenuOpen] = useState(false);

    const selectedValues = isMulti
      ? options.filter(opt => currentValue?.some((val: string) => val === opt.value))
      : options.find(opt => opt.value === currentValue);

    const theSelectedValue = !selectedValues
      ? null
      : Array.isArray(selectedValues)
      ? [...selectedValues]
      : { ...selectedValues };

    if (keepLabelWithSelectedValue && theSelectedValue) {
      // @ts-ignore
      theSelectedValue.label = `${placeholder}:  ${theSelectedValue.label}`;
    }

    const handleChange = (val: any) => {
      const emptyValue = isMulti ? [] : null;
      const value = val
        ? isMulti
          ? val.map((item: SelectOption<string>) => item.value)
          : val.value
        : emptyValue;
      setValue(value);
      onChange(value);
    };

    return (
      <SelectWrapper showArrows={!!showArrows} menuOpen={menuOpen}>
        <Select
          options={options}
          styles={getSelectStyles(theme, width, !!error, isLoading, isPrimaryColor)}
          isLoading={isLoading}
          placeholder={placeholder}
          // @ts-ignore
          isMulti={isMulti}
          onChange={handleChange}
          isSearchable={isSearchable}
          value={theSelectedValue as any}
          menuPlacement='auto'
          menuPosition='fixed'
          onMenuOpen={() => setMenuOpen(true)}
          onMenuClose={() => setMenuOpen(false)}
        />
      </SelectWrapper>
    );
  }
);

const Wrapper = styled('div')<{ open: boolean }>(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: 8,
  '& svg': { flexShrink: 0 },
  '& .select-arrow-1': {
    fill: !open ? '#fff' : theme.colors.primary.main,
  },
  '& .select-arrow-2': {
    fill: open ? '#fff' : theme.colors.primary.main,
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(8),
  },
}));

const SelectWrapper: React.FC<{
  children: React.ReactNode;
  showArrows: boolean;
  menuOpen: boolean;
}> = ({ children, showArrows, menuOpen }) =>
  showArrows ? (
    <Wrapper open={menuOpen}>
      <svg
        width='11'
        height='14'
        viewBox='0 0 11 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <svg
          width='11'
          height='15'
          viewBox='0 0 11 15'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M8.99652 5.98938C8.73179 5.98933 8.47792 5.88412 8.29076 5.6969L5.00352 2.40965L1.71628 5.6969C1.62419 5.79224 1.51404 5.86829 1.39225 5.9206C1.27046 5.97292 1.13947 6.00046 1.00692 6.00161C0.874378 6.00276 0.742929 5.97751 0.620247 5.92731C0.497566 5.87712 0.386109 5.803 0.292381 5.70927C0.198652 5.61554 0.124529 5.50408 0.0743361 5.3814C0.0241431 5.25872 -0.00111411 5.12727 3.7691e-05 4.99472C0.00118949 4.86218 0.0287275 4.73119 0.0810448 4.6094C0.133362 4.48761 0.209411 4.37745 0.304754 4.28537L4.29776 0.292366C4.48496 0.105223 4.73882 9.15527e-05 5.00352 9.15527e-05C5.26822 9.15527e-05 5.52208 0.105223 5.70928 0.292366L9.70229 4.28537C9.84185 4.42497 9.93689 4.60283 9.97539 4.79644C10.0139 4.99005 9.99412 5.19074 9.91858 5.37312C9.84305 5.5555 9.71513 5.71139 9.55101 5.82108C9.38689 5.93077 9.19393 5.98934 8.99652 5.98938Z'
            className='select-arrow-2'
          />
          <path
            d='M5.00352 14.0033C4.73879 14.0033 4.48492 13.8981 4.29776 13.7108L0.304754 9.71784C0.209411 9.62575 0.133362 9.5156 0.0810448 9.39381C0.0287275 9.27202 0.00118949 9.14103 3.7691e-05 9.00848C-0.00111411 8.87593 0.0241431 8.74448 0.0743361 8.6218C0.124529 8.49912 0.198652 8.38767 0.292381 8.29394C0.386109 8.20021 0.497566 8.12609 0.620247 8.07589C0.742929 8.0257 0.874378 8.00044 1.00692 8.00159C1.13947 8.00275 1.27046 8.03028 1.39225 8.0826C1.51404 8.13492 1.62419 8.21097 1.71628 8.30631L5.00352 11.5936L8.29076 8.30631C8.38284 8.21097 8.493 8.13492 8.61479 8.0826C8.73658 8.03028 8.86757 8.00275 9.00012 8.00159C9.13266 8.00044 9.26411 8.0257 9.38679 8.07589C9.50947 8.12609 9.62093 8.20021 9.71466 8.29394C9.80839 8.38767 9.88251 8.49912 9.9327 8.6218C9.9829 8.74448 10.0082 8.87593 10.007 9.00848C10.0059 9.14103 9.97831 9.27202 9.92599 9.39381C9.87368 9.5156 9.79763 9.62575 9.70229 9.71784L5.70928 13.7108C5.52212 13.8981 5.26825 14.0033 5.00352 14.0033Z'
            className='select-arrow-1'
          />
        </svg>
      </svg>
      {children}
    </Wrapper>
  ) : (
    <>{children}</>
  );
