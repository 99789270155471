import styled from 'styled-components';
import { Pagination as PaginationComponent } from '../../../../../components/common/Pagination/Pagination';

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 32,
  position: 'relative',
  maxWidth: 720,
  '& input': {
    width: '100%',
  },
  '& button': {
    width: '100%',
  },
  [theme.max(theme.breakpoints.max)]: {
    gap: theme.pxToVw(32),
    maxWidth: theme.pxToVw(720),
  },
  [theme.max(theme.breakpoints.desktop)]: {
    gap: 32,
    maxWidth: 720,
  },
  [theme.max(680)]: {
    gap: 11,
  },
  [theme.max(theme.breakpoints.xs)]: {
    gap: 12,
  },
}));

const Grid = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 14,
  flexWrap: 'wrap',
  padding: 4,
  width: '100%',
  [theme.betweenDM]: {
    gap: theme.pxToVw(14),
  },
}));

const ScrollableWrapper = styled('div')<{ isLoading?: boolean }>(
  ({ theme, isLoading }) => ({
    maxHeight: '40vh',
    minHeight: '20vh',
    overflowY: 'auto',
    maxWidth: 'calc(100% + 22px)',
    width: 'calc(90vw - 58px)',
    marginLeft: 14,
    paddingRight: 14,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 12,
    transition: 'all .3s',
    paddingBottom: isLoading ? 36 : 24,
    marginTop: -20,
    [theme.betweenDM]: {
      maxWidth: `calc(100% + ${theme.pxToVw(22)})`,
      width: `calc(90vw - ${theme.pxToVw(58)})`,
      marginLeft: theme.pxToVw(14),
      paddingRight: theme.pxToVw(14),
      gap: theme.pxToVw(12),
      paddingBottom: isLoading ? theme.pxToVw(36) : theme.pxToVw(24),
    },
    [theme.max(680)]: {
      marginTop: 0,
    },
    [theme.max(480)]: {
      marginLeft: 0,
      paddingRight: 0,
    },
    [theme.max(420)]: {
      maxHeight: '35vh',
    },
  })
);

const Footer = styled('footer')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  position: 'relative',
  width: '100%',
  '& button': {
    width: 164,
  },
  [theme.betweenDM]: {
    '& button': {
      width: theme.pxToVw(164),
    },
  },
  [theme.max(608)]: {
    flexDirection: 'column',
    '& button': {
      width: '100%',
    },
  },
}));

const SearchAndSortWrapper = styled('div')(({ theme }) => ({
  width: '100%',
}));

const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 12,
  marginLeft: 'auto',
  [theme.betweenDM]: {
    gap: theme.pxToVw(12),
  },
}));

const Pagination = styled(PaginationComponent)(({ theme }) => ({
  '& span:first-of-type': {
    color: 'rgba(255,255,255,0.35)',
  },
}));

const NoNfts = styled('p')(({ theme }) => ({
  fontWeight: 700,
  fontSize: 14,
  textAlign: 'left',
  maxWidth: 'calc(100% + 22px)',
  width: 'calc(90vw - 58px)',
  marginTop: 10,
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: 18,
  },
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(24),
    maxWidth: `calc(100% + ${theme.pxToVw(22)})`,
    width: `calc(90vw - ${theme.pxToVw(58)})`,
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 24,
    maxWidth: 'calc(100% + 22px)',
    width: 'calc(90vw - 58px)',
  },
}));

const Styled = {
  Content,
  Grid,
  Footer,
  ScrollableWrapper,
  SearchAndSortWrapper,
  ButtonsWrapper,
  Pagination,
  NoNfts,
};

export default Styled;
