import React from 'react';
import styled from 'styled-components';
import { Text as TextEl } from '../../ui/text/Text';
import digitalOwnershipSvgs from './icons';

interface Props {
  index: number;
  title: string;
  text: string;
}

export const DigitalOwnershipBenefit: React.FC<Props> = ({ text, title, index }) => {
  return (
    <Wrapper>
      <img src={digitalOwnershipSvgs[index]} alt={`${index}`} />
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.03)',
  display: 'flex',
  flexDirection: 'column',
  padding: 16,
  borderRadius: 10,
  border: '1px solid rgba(255,255,255, 0.1)',
  flex: 1,
  '& img': {
    height: 64,
    width: 'auto',
    alignSelf: 'flex-start',
    marginBottom: 34,
  },
  [theme.min(theme.breakpoints.lg)]: {
    '& img': {
      marginBottom: 14,
    },
  },
  [theme.min(theme.breakpoints.desktop)]: {
    '& img': {
      height: theme.pxToVw(64),
      marginBottom: theme.pxToVw(15),
    },
    padding: `${theme.pxToVw(48)} ${theme.pxToVw(40)} ${theme.pxToVw(48)} ${theme.pxToVw(
      38
    )}`,
  },
  [theme.min(theme.breakpoints.max)]: {
    '& img': {
      height: 64,
      marginBottom: 15,
    },
    padding: '48px 40px 48px 38px',
  },
}));

const Title = styled(TextEl.MD)(({ theme }) => ({
  fontWeight: 600,
  lineHeight: 1.6,
  fontSize: 20,
  marginBottom: 12,
  [theme.min(theme.breakpoints.desktop)]: {
    fontSize: theme.pxToVw(28),
    marginBottom: theme.pxToVw(20),
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 28,
    marginBottom: 20,
  },
}));

const Text = styled(TextEl.MD)(({ theme }) => ({
  fontWeight: 600,
  lineHeight: 1.6,
  maxWidth: '420px !important',
  [theme.betweenDM]: {
    maxWidth: `${theme.pxToVw(420)} !important`,
  },
}));
