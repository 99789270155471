import React, { useEffect } from 'react';

export const useOutsideClick = (
  refs: (React.RefObject<HTMLElement> | null)[],
  callback: (event?: globalThis.MouseEvent) => void
) => {
  useEffect(() => {
    if (!refs.length) return;

    const handleClickOutside = (event: globalThis.MouseEvent) => {
      const callIt = refs
        .filter(Boolean)
        .every(ref => ref?.current && !ref.current.contains(event.target as HTMLElement));

      if (callIt) callback(event);
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, callback]);
};
