import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StorageKeys, StorageService } from '../../../../services/StorageService';

import { SettingItem } from './SettingItem';

export const MuteNotifications: React.FC = () => {
  const { t } = useTranslation();

  const [muted, setMuted] = useState(StorageService.has(StorageKeys.MuteNotifications));

  const onChange = (val: boolean) => {
    if (val) {
      setMuted(true);
      StorageService.save(StorageKeys.MuteNotifications, 'true');
    } else {
      setMuted(false);
      StorageService.remove(StorageKeys.MuteNotifications);
    }
  };

  return (
    <SettingItem
      label={t('notifications.muteNotifications')}
      on={muted}
      onChange={onChange}
    />
  );
};
