import React from 'react';
import { useNavigate } from 'react-router-dom';

import { MobileNavigation } from './MobileNavigation';
import { DesktopNavigation } from './DesktopNavigation';

import Styled from './Header.styles';

import { routes } from '../../../router/routes';

export const Header: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Styled.Header>
      <Styled.Container>
        <Styled.Logo onClick={() => navigate(routes.home())} />
        <MobileNavigation />
        <DesktopNavigation />
      </Styled.Container>
    </Styled.Header>
  );
};
