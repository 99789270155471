import { useEffect, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { useProjectByParamId } from '../../../../../../../react-query/project/useProject';
import { useMilestoneGoalCreate } from '../../../../../../../react-query/user/project/useMilestoneGoalCreate';
import { useMilestoneGoalDelete } from '../../../../../../../react-query/user/project/useMilestoneGoalDelete';
import { useMilestoneGoalEdit } from '../../../../../../../react-query/user/project/useMilestoneGoalEdit';
import { Milestone, MilestoneGoal } from '../../../../../../../types';
import { ButtonPrimary } from '../../../../../../../components/ui/buttons';
import { ButtonSecondary } from '../../../../../../../components/ui/buttons/ButtonSecondary';
import { CheckboxItem } from '../../../../../../../components/ui/forms/Checkbox/CheckboxItem';
import { InputField } from '../../../../../../../components/ui/forms/Input/InputField';
import { Text } from '../../../../../../../components/ui/text/Text';
import ActionIcons from './ActionIcons';
import UploadDocument from './UploadDocument/UploadDocument';
import { useFulfilledChange } from './useFulfilledChange';
import { useRoadmapState } from '../../../RoadmapStateProvider';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { InputWithIcons } from '../../../../../../../components/ui/forms/Input/InputWithIcons';
import { UrlService } from '../../../../../../../services/UrlService';

interface Props {
  milestoneUid: string;
  goal?: MilestoneGoal;
  paddingBottom?: boolean;
  onClose: () => void;
  onGoalEdit: () => void;
}

const schema = Joi.object({
  title: Joi.string().required(),
  description: Joi.string().required(),
  documentUrls: Joi.array().items(Joi.string()).allow(null),
  uid: Joi.string(),
  isFulfilled: Joi.bool(),
  relatedUrl: Joi.custom((value, helper) => {
    if ((value && UrlService.isUrl(value)) || !value) return true;
    // @ts-ignore
    return helper.message('Related Url must be a valid url');
  }),
});

export default function MilestoneGoalForm({
  goal,
  milestoneUid,
  paddingBottom,
  onClose,
  onGoalEdit,
}: Props) {
  const { data: project } = useProjectByParamId();
  const { setIsGoalFormOpen } = useRoadmapState();
  const { title, isFulfilled, uid, description, relatedUrl, documentUrls } = goal || {};
  const [isEditable, setIsEditable] = useState(!uid);

  const {
    getValues,
    reset,
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<MilestoneGoal>({
    defaultValues: {
      uid: uid,
      title: title || '',
      description: description || '',
      isFulfilled: isFulfilled || false,
      relatedUrl: relatedUrl || null,
      documentUrls: documentUrls || null,
    },
    resolver: joiResolver(schema),
  });

  console.log(errors);

  const { setValue: milestoneSetValue, watch: milestoneWatch } =
    useFormContext<Milestone>();

  const { mutateAsync: createGoal, isLoading: isGoalCreating } = useMilestoneGoalCreate();
  const { mutateAsync: editGoal, isLoading: isGoalEditing } = useMilestoneGoalEdit();
  const { mutate: deleteGoal, isLoading: isGoalDeleting } = useMilestoneGoalDelete();
  const { onIsFulfilledChange } = useFulfilledChange(watch(), isEditable, setValue);

  useEffect(() => {
    if (!goal) return;
    reset(goal);
  }, [goal, reset]);

  const onSubmit = async () => {
    if (!project) return;
    const payload = getValues();
    const milestoneFormGoals = milestoneWatch('goals');

    if (!milestoneUid) {
      const newMilestoneGoals = uid
        ? milestoneFormGoals.map(g => (g.uid === uid ? payload : g))
        : [...milestoneFormGoals, { ...payload, uid: new Date().getTime().toString() }];

      milestoneSetValue('goals', newMilestoneGoals, { shouldDirty: true });
      reset();
    } else if (!uid) {
      const goal = await createGoal({ milestoneUid, payload });
      milestoneSetValue('goals', [...milestoneFormGoals, goal]);
      reset(payload);
    } else {
      milestoneSetValue(
        'goals',
        milestoneFormGoals.map(goalItem =>
          goalItem.uid === payload.uid ? payload : goalItem
        )
      );
      await editGoal({
        projectUid: project.uid,
        milestoneUid,
        goalUid: uid,
        payload,
      });
      reset(payload);
    }
    setIsGoalFormOpen(false);
    onClose();
  };

  const goalUid = watch('uid');

  return (
    <>
      <Wrapper isFulfilled={watch('isFulfilled')} paddingBottom={paddingBottom}>
        {goalUid && (
          <ActionIcons
            isEditable={isEditable}
            isLoading={isGoalDeleting || isGoalEditing}
            onDelete={() => {
              deleteGoal({
                goalUid,
                milestoneUid,
                projectUid: project?.uid!,
              });
              milestoneSetValue(
                'goals',
                milestoneWatch('goals').filter(g => g.uid !== goalUid)
              );
              setIsGoalFormOpen(false);
            }}
            onEdit={() => {
              setIsEditable(true);
              onGoalEdit();
              setIsGoalFormOpen(true);
            }}
          />
        )}
        <Name isFulfilled={watch('isFulfilled')} style={{ textAlign: 'left' }}>
          {watch('title') || 'Title'}
        </Name>
        <CheckboxWrapper>
          <CheckboxItem checked={!!watch('isFulfilled')} onChange={onIsFulfilledChange} />
          Goal fulfilled
        </CheckboxWrapper>
        <FormWrapper>
          <InputField
            label='Goal Title'
            placeholder='Enter Goal Title'
            editable={isEditable}
            activeBackground={!isEditable}
            error={errors.title}
            {...register('title')}
          />
          <InputField
            label='Goal Description'
            placeholder='Add description'
            editable={isEditable}
            error={errors.description}
            {...register('description')}
          />
          {watch('isFulfilled') && isEditable && (
            <>
              <div>
                <Label>Related URLs</Label>
                <InputWithIcons
                  label='Related URLs'
                  placeholder='Add URL'
                  onClear={() => {
                    setValue('relatedUrl', null, { shouldDirty: true });
                  }}
                  hasValue={!!watch('relatedUrl')}
                  error={errors.relatedUrl?.message}
                  {...register('relatedUrl')}
                />
              </div>

              <UploadDocument
                onChange={docUrls => setValue('documentUrls', docUrls)}
                fileUrls={watch('documentUrls')}
              />
            </>
          )}
          {isEditable && (
            <ButtonsWrapper>
              <ButtonPrimary
                onClick={handleSubmit(onSubmit)}
                isLoading={isGoalCreating || isGoalEditing || isGoalDeleting}
                disabled={!watch('title')}
              >
                Save Goal
              </ButtonPrimary>
              <ButtonSecondary
                onClick={() => {
                  setIsEditable(false);
                  reset(goal);
                  setIsGoalFormOpen(false);
                }}
              >
                Cancel
              </ButtonSecondary>
            </ButtonsWrapper>
          )}
        </FormWrapper>
      </Wrapper>
    </>
  );
}

const Wrapper = styled('div')<{ isFulfilled: boolean; paddingBottom?: boolean }>(
  ({ theme, isFulfilled, paddingBottom }) => ({
    borderRadius: 10,
    backgroundColor: !isFulfilled ? 'rgba(37, 43, 55, 0.6)' : '#000',
    padding: `14px 20px ${paddingBottom ? 40 : 14}px 20px`,
    position: 'relative',
    flex: 1,
    zIndex: 10,
    [theme.min(theme.breakpoints.desktop)]: {
      padding: theme.pxToVw(20),
      paddingBottom: theme.pxToVw(paddingBottom ? 44 : 20),
      borderRadius: theme.pxToVw(10),
    },
    [theme.min(theme.breakpoints.max)]: {
      padding: 20,
      paddingBottom: paddingBottom ? 44 : 20,
      borderRadius: 10,
    },
  })
);

const Name = styled(Text.MD)<{ isFulfilled: boolean }>(({ theme, isFulfilled }) => ({
  textAlign: 'left',
  fontWeight: 900,
  marginBottom: 7,
  color: !isFulfilled ? '#fff' : theme.colors.primary.main,
  [theme.min(theme.breakpoints.desktop)]: {
    marginBottom: theme.pxToVw(9),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginBottom: 9,
  },
}));

const FormWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  [theme.betweenDM]: {},
}));

const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 8,
  marginTop: 4,
  '& button': {
    flex: 1,
    '&:first-child': {
      maxWidth: 292,
      [theme.betweenDM]: {
        maxWidth: theme.pxToVw(292),
      },
    },
    '&:last-child': {
      maxWidth: 150,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'rgba(255,255,255, 0.05)',
      },
    },
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(8),
    marginTop: theme.pxToVw(4),
  },
}));

const CheckboxWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 4,
  fontWeight: 700,
  fontSize: 12,
  marginBottom: 6,
  alignItems: 'center',
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(12),
    marginBottom: theme.pxToVw(6),
  },
}));

const Label = styled('span')(({ theme }) => ({
  fontSize: 12,
  color: theme.colors.primary.main,
  marginBottom: 8,
  display: 'block',
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(12),
  },
}));
