import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { UserApi } from '../../api/UserApi/UserApi';
import { useProfileByParamId } from '../profile/useProfile';
import { QueryKey } from '../query-keys';

export const useFollowers = (uidOrAddress: string | null | undefined) => {
  return useQuery(
    QueryKey.ProfileFollowers(uidOrAddress!),
    () => UserApi.getFollowers(uidOrAddress!),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: !!uidOrAddress,
    }
  );
};

export const useProfileFollowers = () => {
  const { data: profile } = useProfileByParamId();

  const param = useMemo(
    () => (profile?.uid ? profile.uid : profile?.addresses?.[0]),
    [profile]
  );

  return useFollowers(param);
};
