import React from 'react';
import styled from 'styled-components';

import { BaseModal } from '../BaseModal/BaseModal';
import { ConfirmButtons } from '../../../common/ActionButtons/ConfirmButtons';
import { Heading5 } from '../../text/Heading';
import { Text } from '../../text/Text';

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  description: string | React.ReactNode;
}

export const ConfirmationModal: React.FC<Props> = ({
  onConfirm,
  onCancel,
  title,
  description,
}) => {
  return (
    <BaseModal isOpen onClose={onCancel} showX={false}>
      <Content>
        <Title>{title}</Title>
        <TextEl>{description}</TextEl>
        <Buttons onConfirm={onConfirm} onCancel={onCancel} />
      </Content>
    </BaseModal>
  );
};

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const Title = styled(Heading5)(({ theme }) => ({
  fontWeight: 900,
  textAlign: 'center',
  marginBottom: 15,
  marginTop: -10,
  [theme.betweenDM]: {
    marginBottom: theme.pxToVw(15),
    marginTop: theme.pxToVw(-10),
  },
}));

const TextEl = styled(Text.SM)(({ theme }) => ({
  marginBottom: 20,
  fontSize: 14,
  textAlign: 'center',
  fontWeight: 700,
  '& span': {
    color: theme.colors.primary.main,
    textDecoration: 'underline',
  },
  [theme.min(theme.breakpoints.lg)]: {
    fontSize: 16,
  },
}));

const Buttons = styled(ConfirmButtons)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 8,
  '& button': {
    width: 219,
  },
  [theme.min(600)]: {
    flexDirection: 'row',
    gap: 12,
    '& button': {
      width: 193,
    },
  },
  [theme.betweenDM]: {
    gap: theme.pxToVw(12),
    '& button': {
      width: theme.pxToVw(193),
    },
  },
}));
