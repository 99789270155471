import React from 'react';
import { FieldError } from 'react-hook-form';
import styled from 'styled-components';

import { ErrorMessage } from '../../ui/forms/FormFieldWrapper/ErrorMessage';

interface Props {
  label: string;
  error?: null | string | FieldError;
  children: React.ReactNode;
}

export const FieldWrapper: React.FC<Props> = ({
  label,
  error,
  children,
  ...otherProps
}) => {
  return (
    <Wrapper {...otherProps}>
      <Label>{label}</Label>
      {children}
      {error && <ErrorMessage></ErrorMessage>}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 6,
  width: '100%',
  [theme.betweenDM]: {
    gap: theme.pxToVw(6),
  },
}));

const Label = styled('label')(({ theme }) => ({
  paddingLeft: 18,
  fontSize: 11,
  [theme.betweenDM]: {
    paddingLeft: theme.pxToVw(18),
    fontSize: theme.pxToVw(11),
  },
}));
