import React from 'react';
import { useTranslation } from 'react-i18next';

import { useReputationWithAddress } from '../../../../../providers/AddressProvider/useReputationWithAddress';

import { FirstTransactionSvg } from '../../../../../assets/svgs/reputation-svgs';
import { AnalysisResultSecondaryCardBase } from './AnalysisResultSecondaryCardBase';

export const FirstTransaction: React.FC = () => {
  const { t } = useTranslation('analysisResult');
  const { data } = useReputationWithAddress();

  const { firstTransactionTime } = data || {};

  const timeCount =
    !firstTransactionTime || firstTransactionTime === 'missing'
      ? ''
      : firstTransactionTime.split(' ')[0];

  const suffix =
    firstTransactionTime === 'today' || firstTransactionTime === 'missing' || !timeCount
      ? ''
      : `${String(firstTransactionTime).split(' ').slice(1, -1).join(' ')} ago`;

  return (
    <AnalysisResultSecondaryCardBase
      label={t('firstTransaction.title')}
      value={timeCount}
      valueSuffix={suffix}
      icon={<FirstTransactionSvg />}
    />
  );
};
