import React from 'react';
import styled from 'styled-components';

import { TrustPercentageCard } from './TrustPercentageCard/TrustPercentageCard';

import { AnalysisResultBalanceCard } from './PrimaryCards/AnalysisResultBalanceCard';
import { AnalysisResultTransactionsCard } from './PrimaryCards/AnalysisResultTransactionsCard';

import { FirstTransaction } from './SecondaryCards/FirstTransaction';
import { LastTransaction } from './SecondaryCards/LastTransaction';
import { NftCard } from './SecondaryCards/NftCard';
import { TotalValueReceived } from './SecondaryCards/TotalValueReceived';
import { TotalValueSent } from './SecondaryCards/TotalValueSent';
import { ErcTokensCard } from './SecondaryCards/ErcTokensCard';

interface Props {
  where?: 'main' | 'list';
}
export const AnalysisDetails: React.FC<Props> = ({ where }) => {
  return (
    <Wrapper>
      <PrimaryCardsWrapper>
        <TrustPercentageCard />
        <AnalysisResultBalanceCard />
        <AnalysisResultTransactionsCard />
      </PrimaryCardsWrapper>
      <SecondaryCardsWrapper where={where}>
        <TotalValueReceived />
        <TotalValueSent />
        <FirstTransaction />
        <LastTransaction />
        <NftCard />
        <ErcTokensCard />
      </SecondaryCardsWrapper>
    </Wrapper>
  );
};

const Col = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
}));

const Wrapper = styled(Col)(({ theme }) => ({}));

const PrimaryCardsWrapper = styled(Col)(({ theme }) => ({
  [theme.min(theme.breakpoints.sm)]: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: 12,
    '& > *': {
      '&:nth-child(1)': {
        flex: '1 0 60%',
        maxWidth: 478,
      },
      '&:nth-child(2)': {
        flex: `1 0 calc(50% - 6px)`,
      },
      '&:nth-child(3)': {
        flex: `1 0 calc(50% - 6px)`,
      },
    },
  },
  [theme.min(theme.breakpoints.desktop)]: {
    gap: 12,
    '& > *': {
      '&:nth-child(1)': {
        flex: 1,
        maxWidth: 'auto',
      },
      '&:nth-child(2)': {
        flex: 1,
      },
      '&:nth-child(3)': {
        flex: 1,
      },
    },
  },
}));

const SecondaryCardsWrapper = styled(Col)<Props>(({ theme, where }) => ({
  [theme.min(theme.breakpoints.sm)]: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: 12,
    '& > *': {
      flex: '1 0 calc(33% - 6px)',
    },
  },
  [theme.min(theme.breakpoints.desktop)]: {
    '& > *': {
      flex: `1 0 calc(16.2% - 6px)`,
    },
  },
}));
