import React, { useState } from 'react';

import { FormFieldWrapper } from '../FormFieldWrapper/FormFieldWrapper';

import { InputBase } from './InputBase';
import { ReactComponent as EyeSvg } from './Eye.svg';
import { FieldError } from 'react-hook-form';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: boolean | string | Error | FieldError;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  width?: string | number;
}

export const PasswordField = React.forwardRef<HTMLInputElement, Props>(
  (
    { label, labelProps, error, width = '100%', required, onChange, ...inputProps },
    ref
  ) => {
    const [type, setType] = useState<'password' | 'text'>('password');
    const [val, setVal] = useState(inputProps.value);

    const onIconClick = () => {
      setType(prev => {
        if (prev === 'password') {
          return 'text';
        } else {
          return 'password';
        }
      });
    };

    return (
      <FormFieldWrapper
        key={type}
        error={
          typeof error === 'string' || typeof error === 'boolean' ? error : error?.message
        }
        label={label}
        required={required}
        fieldHasValue={!!inputProps.value || !!val}
        {...labelProps}
      >
        <InputBase
          ref={ref}
          error={!!error}
          type={type}
          autoComplete='new-password'
          spellCheck={false}
          width={width}
          style={{ paddingRight: 50 }}
          hasValue={!!val}
          onChange={e => {
            setVal(e.target.value);
            typeof onChange === 'function' && onChange(e);
          }}
          {...inputProps}
        />
        <span
          onClick={onIconClick}
          style={{
            position: 'absolute',
            right: 20,
            top: '50%',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
          }}
        >
          <EyeSvg />
        </span>
      </FormFieldWrapper>
    );
  }
);
