import styled from 'styled-components';

export const Container = styled('div')(({ theme }) => ({
  margin: '0 auto',
  width: 'calc(100% - 20px)',
  [theme.min(theme.breakpoints.xs)]: {
    width: '90%',
    maxWidth: 'auto',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    width: '79.16%',
    maxWidth: theme.pxToVw(1520),
  },
  [theme.min(theme.breakpoints.max)]: {
    maxWidth: 1520,
  },
}));
