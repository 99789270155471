import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useProfileByParamId } from '../profile/useProfile';
import { QueryKey } from '../query-keys';
import { useUser } from './useUser';

const useSetQueriesOnFollow = () => {
  const queryClient = useQueryClient();
  const { data: user } = useUser();
  const { data: profile } = useProfileByParamId();

  return useCallback(
    async ({ userUid, address }: { userUid?: string; address?: string }) => {
      if (!profile || !user) return;

      // current user followings
      const keysToInvalidate: any[] = [
        QueryKey.ProfileFollowings(user.uid),
        QueryKey.ProfileFollowingsCount(user.uid),
      ];

      if (userUid) {
        keysToInvalidate.push(
          QueryKey.ProfileFollowersCount(userUid),
          QueryKey.ProfileFollowers(userUid)
        );
      }

      if (address) {
        keysToInvalidate.push(QueryKey.ProfileFollowersCount(address));
      }

      await Promise.all(keysToInvalidate.map(key => queryClient.invalidateQueries(key)));
    },
    [queryClient, user, profile]
  );
};

const useSetQueriesOnUnfollow = () => {
  const queryClient = useQueryClient();
  const { data: user } = useUser();
  const { data: profile } = useProfileByParamId();

  return useCallback(
    async ({ userUid, address }: { userUid?: string; address?: string }) => {
      if (!profile || !user) return;

      // current user followings
      const keysToInvalidate: any[] = [
        QueryKey.ProfileFollowings(user.uid),
        QueryKey.ProfileFollowingsCount(user.uid),
      ];

      if (userUid) {
        keysToInvalidate.push(
          QueryKey.ProfileFollowersCount(userUid),
          QueryKey.ProfileFollowers(userUid)
        );
      }

      if (address) {
        keysToInvalidate.push(QueryKey.ProfileFollowersCount(address));
      }

      await Promise.all(keysToInvalidate.map(key => queryClient.invalidateQueries(key)));
    },
    [queryClient, user, profile]
  );
};

export const useFollowUtils = () => {
  const setQueriesOnFollow = useSetQueriesOnFollow();
  const setQueriesOnUnfollow = useSetQueriesOnUnfollow();

  return { setQueriesOnFollow, setQueriesOnUnfollow };
};
