import styled, { CSSObject } from 'styled-components';
import { ButtonVariant } from '.';
import { Theme } from '../../../config/theme';
import { ButtonBase } from './ButtonBase';

const getButtonStyles = (
  theme: Theme,
  variant: ButtonVariant,
  disabled?: boolean
): CSSObject => ({
  backgroundColor: disabled
    ? 'rgba(5, 6, 11, 0.2)'
    : variant === ButtonVariant.Outlined
    ? 'transparent'
    : '#05060B',
  color: disabled ? 'rgba(255, 255, 255, 0.2)' : theme.colors.text.primary,
  border:
    variant === ButtonVariant.Filled ? 'none' : `2px solid ${theme.colors.text.primary}`,
  '&:hover': {
    background:
      variant === ButtonVariant.Filled
        ? 'rgba(15, 20, 33, 0.6)'
        : 'rgba(15, 20, 33, 0.6)',
    border: variant === ButtonVariant.Filled ? 'none' : `2px solid #05060B`,
  },
});

const ButtonSecondary = styled(ButtonBase)<{ variant?: ButtonVariant }>(
  ({ theme, disabled, variant = ButtonVariant.Filled }) => ({
    ...getButtonStyles(theme, variant, disabled),
  })
);

export { ButtonSecondary };
