import styled from 'styled-components';
import { ButtonBase } from './ButtonBase';

export const InputAlikeButton = styled(ButtonBase)(({ theme }) => ({
  outline: 'none',
  background: 'rgba(8, 10, 19, 0.6)',
  borderRadius: 10,
  boxShadow: '0px 0px 3px 2px rgba(53, 133, 254, 0.39)',
  padding: '6px 20px 6px 20px',
  transition: 'all .4s',
  border: '2px solid transparent',
  color: '#fff',
  fontSize: 14,
  height: 56,
  fontWeight: 700,
  WebkitAppearance: 'none',
  marginTop: 4,
  '&:hover': {
    backgroundColor: 'rgba(0, 101, 252, 0.1)',
  },
  [theme.min(theme.breakpoints.sm)]: {
    fontSize: 16,
    height: 60,
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    fontSize: theme.pxToVw(16),
    height: theme.pxToVw(60),
    padding: `${theme.pxToVw(6)} ${theme.pxToVw(20)}`,
  },
}));

InputAlikeButton.defaultProps = { type: 'button' };
