import React from 'react';
import { useTranslation } from 'react-i18next';

import { useEndorsments } from '../../react-query/user/useEndorsments';

import { BaseModal } from '../ui/modals/BaseModal/BaseModal';
import { FollowerItem } from '../../pages/profile/components/ProfileInfo/Followers/FollowerItem';
import { LogoSpinner } from '../ui/loaders/LogoSpinner';

import { routes } from '../../router/routes';

import Styled from '../../pages/profile/components/ProfileInfo/Followers/components.styles';

interface Props {
  onClose: () => void;
  projectId: string | undefined;
}

export const EndorsmentsModal: React.FC<Props> = ({ onClose, projectId }) => {
  const { t } = useTranslation('projectPage');
  const { data: followers } = useEndorsments(projectId);

  return (
    <BaseModal title={t('endorsers')} onClose={onClose} isOpen>
      <Styled.Content>
        {!followers && (
          <Styled.SpinnerWrapper>
            <LogoSpinner size={68} />
          </Styled.SpinnerWrapper>
        )}
        {followers?.map(follower => (
          <FollowerItem
            key={follower.userUid}
            onClick={addressOrUsername => {
              onClose();
              window.open(routes.profile.byId(addressOrUsername), '_blank');
            }}
            {...follower}
            type='follower'
          />
        ))}
      </Styled.Content>
    </BaseModal>
  );
};
