import qs from 'qs';
import { TransactionType } from '../types';

export const apiRoutes = {
  auth: {
    register: () => '/register',
    login: () => '/login',
    resetPassword: () => '/password',
  },
  user: {
    profile: {
      root: () => '/user/profile',
      basic: () => '/user/profile/basic',
      additional: () => '/user/profile/additional',
    },
    coverPhoto: () => '/user/cover_photo',
    profilePhoto: () => '/user/profile_photo',
    connectAddress: () => '/user/connect_addresses',
    disconnectAddress: () => '/user/disconnect_address',
    nfts: (options?: IRequestParams) =>
      `/user/nfts${options ? '?' + qs.stringify(options) : ''}`,
    follow: (addressOrUsername: string) => `/user/follow/${addressOrUsername}`,
    unfollow: (addressOrUsername: string) => `/user/unfollow/${addressOrUsername}`,
    following: (uid: string) => `/user/profile/following/${uid}`,
    followers: (uidOrAddress: string) => `/user/profile/followers/${uidOrAddress}`,
    projects: {
      root: () => '/user/projects',
    },
    project: {
      root: () => '/user/project',
      byId: (projectUid: string) => `/user/project/${projectUid}`,
      details: (address: string) => `/user/project/${address}/details`,
      metadata: (address: string) => `/user/project/metadata/${address}`,
      additionalDetails: (projectUid: string) => `/user/project/${projectUid}/additional`,
      coverPhoto: (projectUid: string) => `/user/project/${projectUid}/cover_photo`,
      endorsments: (projectUid: string) => `/user/project/endorsements/${projectUid}`,
      addEndorsement: (projectUid: string) =>
        `/user/project/add_endorsement/${projectUid}`,
      removeEndorsement: (projectUid: string) =>
        `/user/project/remove_endorsement/${projectUid}`,
      follow: (projectUid: string) => `/user/project/follow/${projectUid}`,
      unfollow: (projectUid: string) => `/user/project/unfollow/${projectUid}`,
      document: () => '/user/project/document',
      followers: (projectUid: string) => `/user/project/followers/${projectUid}`,
      milestone: (projectUid: string) => `/project/${projectUid}/milestone`,
      milestoneGoal: (milestoneUid: string) =>
        `/user/project/milestone/${milestoneUid}/goal`,
      projectMilestoneById: (projectUid: string, milestoneUid: string) =>
        `/user/project/${projectUid}/milestone/${milestoneUid}`,
      milestoneReorder: (projectUid: string, milestoneUid: string) =>
        `/user/project/${projectUid}/milestone/${milestoneUid}/reorder`,
      projectMilestoneGoalById: (
        projectUid: string,
        milestoneUid: string,
        goalId: string
      ) => `/user/project/${projectUid}/milestone/${milestoneUid}/goal/${goalId}`,
    },
    popular: () => '/user/popular',
    recommended: (options?: IRequestParams) =>
      `/user/recommended${options ? '?' + qs.stringify(options) : ''}`,
    notifications: {
      root: (options?: IRequestParams) =>
        `/user/notifications${options ? '?' + qs.stringify(options) : ''}`,
      count: () => '/user/notifications/count',
      markAllAsRead: () => '/user/notifications/read',
      markAsRead: (notificationId: number) =>
        `/user/notifications/read/${notificationId}`,
    },
    report: () => '/user/report',
  },
  profile: {
    byId: (id: string) => `/user_profile/${id}`,
    followersCount: (userUidOrAddress: string) =>
      `/profile/followers/${userUidOrAddress}/count`,
    followingCount: (userUid: string) => `/profile/following/${userUid}/count`,
    values: (usernameOrAddress: string) => `/profile/values/${usernameOrAddress}`,
  },
  reputation: {
    byAddress: (address: string) => `/reputation/${address}`,
  },
  trust: {
    byAddress: (address: string) => `/trust/${address}`,
  },
  nfts: {
    byAddress: (address: string, options?: IRequestParams) =>
      `/nfts/${address}${options ? '?' + qs.stringify(options) : ''}`,
  },
  transactions: {
    byAddress: (address: string, options?: IRequestParams) =>
      `/transactions/${address}${options ? '?' + qs.stringify(options) : ''}`,
  },
  project: {
    profile: {
      byAddress: (address: string) => `/project_profile/${address}`,
    },
    details: {
      byAddress: (address: string) => `/project/details/${address}`,
    },
    endorsments: {
      totalCount: (projectUid: string) => `/project/endorsements/${projectUid}/count`,
    },
    followers: {
      totalCount: (projectUid: string) => `/project/followers/${projectUid}/count`,
    },
    popular: () => '/project/popular',
    subscribe: () => '/project/subscribe',
  },
  projects: {
    byAddressOrUsername: (addressOrUsername: string) => `/projects/${addressOrUsername}`,
  },
  analytics: {
    transactionsByAddress: (address: string, options: { interval: string }) =>
      `/analytics/transaction/${address}?${qs.stringify(options)}`,
  },
};

export interface IRequestParams {
  search?: string;
  sortBy?: string;
  sort?: string;
  page?: number;
  txType?: TransactionType;
  interval?: string;
  offset?: number;
}
