import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { useOutsideClick } from '../../../../hooks/useOutsideClick';

import { BaseCard } from '../../../ui/cards/BaseCard';
import { ReactComponent as ArrowSvg } from './ArrowDown.svg';

interface Props {
  title: string;
  imageUrl?: string | null;
  menuItems: { text: string; onClick: () => void; active?: boolean }[];
  absolutePositionMenu?: boolean;
  centered?: boolean;
  transparentMenu?: boolean;
  activeOnOpen?: boolean;
}

export const ExpandableMenu: React.FC<Props> = ({
  title,
  menuItems,
  absolutePositionMenu = true,
  centered = false,
  transparentMenu = false,
  activeOnOpen = false,
  imageUrl,
  ...otherProps
}) => {
  const [open, setOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useOutsideClick([menuRef], () => setOpen(false));

  return (
    <Card {...otherProps} ref={menuRef} open={open} numItems={menuItems.length}>
      <TopContent
        onClick={() => setOpen(prev => !prev)}
        open={open}
        transparentMenu={transparentMenu}
      >
        {imageUrl ? (
          <Image src={imageUrl} />
        ) : (
          <Title activeOnOpen={activeOnOpen} open={open}>
            {title}
          </Title>
        )}
        <ArrowSvg />
      </TopContent>

      <MenuItemsWrapper
        open={open}
        itemCount={menuItems.length}
        absolutePositionMenu={absolutePositionMenu}
        transparentMenu={transparentMenu}
      >
        {menuItems.map((item, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              setOpen(false);
              item.onClick();
            }}
            $active={item.active}
            centered={centered}
            absolutePositionMenu={absolutePositionMenu}
          >
            {item.text}
          </MenuItem>
        ))}
      </MenuItemsWrapper>
    </Card>
  );
};

const Card = styled('div')<{ numItems: number; open: boolean }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}));

const Image = styled('img')(({ theme }) => ({
  height: 48,
  width: 48,
  borderRadius: 8,
  border: '2px solid #FFFFFF',
  cursor: 'pointer',
  objectFit: 'cover',
  flexShrink: 0,
  [theme.min(theme.breakpoints.desktop)]: {
    height: theme.pxToVw(56),
    width: theme.pxToVw(56),
  },
  [theme.min(theme.breakpoints.max)]: {
    height: 56,
    width: 56,
    borderRadius: 10,
  },
}));

const TopContent = styled('div')<{ open: boolean; transparentMenu: boolean }>(
  ({ theme, open, transparentMenu }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    height: transparentMenu ? 'auto' : 56,
    width: '100%',
    gap: 13,
    flexShirnk: 0,
    '& svg': {
      transition: 'all .3s',
      transform: `rotate(${!open ? '0' : '180'}deg)`,
      flexShrink: 0,
      [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
        width: theme.pxToVw(12),
        height: 'auto',
      },
    },
    [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
      width: 'max-content',
      gap: theme.pxToVw(13),
      height: transparentMenu ? 'auto' : theme.pxToVw(56),
    },
  })
);

const Title = styled('div')<{ activeOnOpen: boolean; open: boolean }>(
  ({ theme, activeOnOpen, open }) => ({
    fontSize: 16,
    fontWeight: 700,
    color: activeOnOpen && open ? theme.colors.primary.main : '#fff',
    transition: 'all .3s',
    // wordBreak: 'no-break',
    whiteSpace: 'nowrap',
    [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
      fontSize: theme.pxToVw(16),
    },
  })
);

const MenuItemsWrapper = styled(BaseCard)<{
  open: boolean;
  itemCount: number;
  absolutePositionMenu: boolean;
  transparentMenu: boolean;
}>(({ theme, open, itemCount, absolutePositionMenu, transparentMenu }) => ({
  height: open ? itemCount * 41 + 21 : 0,
  transition: 'all .3s',
  overflow: 'hidden',
  boxShadow:
    open && !transparentMenu ? '0px 0px 3px 2px rgba(53, 133, 254, 0.39)' : 'none',
  position: absolutePositionMenu ? 'absolute' : 'initial',
  top: absolutePositionMenu ? 66 : 'auto',
  width: absolutePositionMenu ? 214 : 'auto',
  left: absolutePositionMenu ? '-180%' : 'auto',
  background: transparentMenu ? 'transparent' : '#000',
  paddingTop: 15,
  [theme.min(theme.breakpoints.md)]: {
    paddingTop: 0,
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    height: open ? theme.pxToVw(itemCount * 41 + 21) : 0,
    top: absolutePositionMenu ? theme.pxToVw(66) : 'auto',
    width: absolutePositionMenu ? theme.pxToVw(214) : 'auto',
  },
}));

const MenuItem = styled('div')<{
  $active?: boolean;
  centered?: boolean;
  absolutePositionMenu: boolean;
}>(({ theme, $active, centered, absolutePositionMenu }) => ({
  fontSize: 14,
  fontWeight: 700,
  color: $active ? theme.colors.primary.main : '#fff',
  transition: 'all .3s',
  padding: '10px 24px',
  cursor: 'pointer',
  textAlign: centered ? 'center' : 'left',
  '&:hover': {
    color: theme.colors.primary.main,
  },
  '&:first-child': {
    marginTop: !absolutePositionMenu ? 0 : 14,
  },
  '&:last-child': {
    marginBottom: !absolutePositionMenu ? 0 : 7,
  },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    fontSize: theme.pxToVw(16),
    padding: `${theme.pxToVw(10)} ${theme.pxToVw(24)}`,
    '&:first-child': {
      marginTop: !absolutePositionMenu ? 0 : theme.pxToVw(14),
    },
    '&:last-child': {
      marginBottom: !absolutePositionMenu ? 0 : theme.pxToVw(7),
    },
  },
  [theme.min(theme.breakpoints.max)]: {
    fontSize: 16,
  },
}));
