import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { usePopularProjects } from '../../../../../react-query/project/usePopularProjects';
import { usePlaceholderItems } from '../../../../../hooks/usePlaceholderItems';

import { NoItemsText } from '../../../../profile/components/ProfileData/components';
import { NewsFeedCardBase, NewsFeedTitle } from '../components/shared';
import { PopularProjectCard, PopularProjectCardLoader } from './PopularProjectCard';
import { SelectBase } from '../../../../../components/ui/forms/Select/SelectBase';

export const PopularProjectsSection: React.FC = () => {
  const { t } = useTranslation();
  const { data, error, isLoading } = usePopularProjects();
  const LoadingCards = usePlaceholderItems(3, PopularProjectCardLoader);
  const [view, setView] = useState<'byEndorsments' | 'byFollowers'>('byEndorsments');

  const options = [
    {
      label: 'By Endorsments',
      value: 'byEndorsments',
    },
    {
      label: 'By Followers',
      value: 'byFollowers',
    },
  ];

  const renderContent = () => {
    if (error) return <NoItemsText isError>{t('errors.default')}</NoItemsText>;

    if (isLoading) return LoadingCards;

    if (!data) return null;

    return data[view].map(project => (
      <PopularProjectCard
        key={project.uid}
        project={project}
        isFollow={view === 'byFollowers'}
      />
    ));
  };

  if (data && !data.byEndorsments?.length && !data.byFollowers?.length) return null;

  return (
    <NewsFeedCardBase>
      <TitleWrapper>
        <Title>{t('homePage.popularProjects')}</Title>
        <SelectWrapper>
          <SelectBase
            value={view}
            options={options}
            showArrows={false}
            onChange={val => setView(val)}
          />
        </SelectWrapper>
      </TitleWrapper>
      <CardsWrapper>{renderContent()}</CardsWrapper>
    </NewsFeedCardBase>
  );
};

const Title = styled(NewsFeedTitle)(({ theme }) => ({
  marginBottom: 0,
}));

const TitleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  marginBottom: 20,
  [theme.min(theme.breakpoints.xs)]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  [theme.min(theme.breakpoints.desktop)]: {
    marginBottom: theme.pxToVw(40),
  },
  [theme.min(theme.breakpoints.max)]: {
    marginBottom: 40,
  },
}));

const CardsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  [theme.min(theme.breakpoints.md)]: {
    flexDirection: 'row',
  },
}));

const SelectWrapper = styled('div')(({ theme }) => ({
  width: 170,
  [theme.min(theme.breakpoints.xs)]: { width: 192 },
  [theme.between(theme.breakpoints.desktop, theme.breakpoints.max)]: {
    width: theme.pxToVw(192),
  },
}));
