import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { NFT } from '../../../../../../../types';
import { limitLongString } from '../../../../../../../utils/string-utils';
import { getNftImageFromJson } from '../../../../../../../utils/getNftImageFromJson';

import { ImageSquare } from '../../../../../../../components/ui/media/ImageSquare/ImageSquare';
import { ContentLoader } from '../../../../../../../components/ui/loaders/ContentLoader';

interface Props {
  nft: NFT;
}

export const NftItem: React.FC<Props> = ({
  nft: { photoUrls, title, price, priceCurrency, tokenId },
}) => {
  const { t } = useTranslation('common');
  const [loaded, setLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(photoUrls[0]);
  const [currentPhotoIdx, setCurrentPhotoIdx] = useState(0);

  const loadImage = useCallback(async () => {
    if (loaded) return;
    if (currentPhotoIdx === photoUrls.length) return setIsError(true);
    const nextUrl = photoUrls[currentPhotoIdx];
    if (nextUrl?.endsWith('.json')) {
      const nextPhotoUrl = await getNftImageFromJson(nextUrl);
      if (photoUrls.some(url => url === nextPhotoUrl)) {
        // this url is already present in photoUrls, go to next one to not load again
        setCurrentPhotoIdx(prev => prev + 1);
      } else {
        setPhotoUrl(nextPhotoUrl);
      }
    } else {
      setPhotoUrl(nextUrl);
    }
  }, [currentPhotoIdx, photoUrls, loaded]);

  useEffect(() => {
    loadImage();
  }, [loadImage]);

  const thePhoto =
    photoUrl && !isError
      ? photoUrl
      : 'https://blockem.s3.us-east-2.amazonaws.com/nft-default-photo.png';

  return (
    <Wrapper>
      <Image url={thePhoto} position={photoUrl && !isError ? 'top' : 'center'}>
        {!loaded && (
          <ContentLoader
            viewBox='0 0 240 200'
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
            }}
          >
            <rect x='0' y='0' height={200} width={240} />
          </ContentLoader>
        )}
      </Image>
      <DetailsWrapper>
        <TextBold>{limitLongString(title, 30) || `#${Number(tokenId)}`}</TextBold>
        <Small>{t('estValue')}</Small>
        <TextBold>{`${price < 0.00001 ? '< 0.00001' : price} ${priceCurrency}`}</TextBold>
      </DetailsWrapper>
      {thePhoto && !loaded && (
        <img
          src={thePhoto}
          alt='dsad'
          onLoad={e => {
            setLoaded(true);
          }}
          onError={e => {
            setCurrentPhotoIdx(prev => prev + 1);
          }}
          style={{ visibility: 'hidden', width: 1, height: 1, position: 'absolute' }}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  background: '#000',
  borderRadius: 15,
  [theme.betweenDM]: {
    borderRadius: theme.pxToVw(15),
  },
}));

const DetailsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  padding: '12px 20px',
  [theme.min(theme.breakpoints.xs)]: {
    padding: '14px 20px',
  },
  [theme.betweenDM]: {
    padding: `${theme.pxToVw(14)} ${theme.pxToVw(20)}`,
  },
}));

const TextBold = styled('p')(({ theme }) => ({
  fontSize: 14,
  lineHeight: 1.333,
  fontWeight: 700,
  wordBreak: 'break-word',
  '&:first-child': {
    marginBottom: 2,
  },
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(14),
  },
}));

const Small = styled('small')(({ theme }) => ({
  fontSize: 10,
  lineHeight: 1.333,
  fontWeight: 600,
  [theme.betweenDM]: {
    fontSize: theme.pxToVw(10),
  },
}));

const Image = styled(ImageSquare)(({ theme }) => ({
  aspectRatio: '6 / 5',
  boxShadow: 'none',
  pointerEvents: 'none',
  width: '100%',
  height: 'auto',
}));

export const NftItemLoader: React.FC = () => {
  return (
    <ContentLoader viewBox='0 0 87 116' style={{ width: '100%' }}>
      <rect x='0' y='0' rx='6' ry='10' width={87} height={72} />
      <rect x='0' y='75' rx='4' ry='4' width={87} height={12} />
      <rect x='0' y='89' rx='4' ry='4' width={87} height={12} />
      <rect x='0' y='104' rx='4' ry='4' width={87} height={12} />
    </ContentLoader>
  );
};
