import { UseInfiniteQueryResult } from 'react-query';
import { PaginatedResponse } from '../types';

export const getPaginatedDataResults = <T>(
  data: UseInfiniteQueryResult<PaginatedResponse<T>>['data']
) => {
  return data?.pages.reduce((results, currPage) => {
    return [...results, ...currPage.results];
  }, [] as T[]);
};

export const getPaginatedResultsTotalCount = <T>(
  data: PaginatedResponse<T>[]
) => data.reduce((curr, page) => curr + page.results.length, 0);
