import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { Layout } from '../components/layouts/Layout';

import { LoginPage } from '../pages/auth/login/LoginPage';
import { RegisterPage } from '../pages/auth/register/RegisterPage';
import { ResetPasswordPage } from '../pages/auth/reset-password/ResetPasswordPage';
import { ContactPage } from '../pages/contact/ContactPage';
import { ErrorPage } from '../pages/error/ErrorPage';
import { GenerateProfilePage } from '../pages/generate-profile/GenerateProfilePage';
import { HomePage } from '../pages/home/HomePage';
import { PrivacyPolicyPage } from '../pages/privacy-policy/PrivacyPolicyPage';
import { ProfilePage } from '../pages/profile/ProfilePage';
import { ProjectProfilePage } from '../pages/project/profile/ProjectProfilePage';
import { TermsOfServicePage } from '../pages/terms-of-service/TermsOfServicePage';

import { routes } from './routes';

export const PublicRouter: React.FC = () => {
  return (
    <Layout>
      <Routes>
        <Route path={routes.home()} element={<HomePage />} />

        <Route path={routes.privacyPolicy()} element={<PrivacyPolicyPage />} />
        <Route path={routes.termsOfService()} element={<TermsOfServicePage />} />
        <Route path={routes.auth.register()} element={<RegisterPage />} />
        <Route path={routes.auth.login()} element={<LoginPage />} />
        <Route path={routes.auth.resetPassword()} element={<ResetPasswordPage />} />
        <Route path={routes.profile.byId(':id')} element={<ProfilePage />} />
        <Route path={routes.project.byId(':project')} element={<ProjectProfilePage />} />
        <Route
          path={routes.generateWeb3Profile.root()}
          element={<GenerateProfilePage />}
        />
        <Route path={routes.contact()} element={<ContactPage />} />

        <Route path={routes.error(':status')} element={<ErrorPage />} />
        <Route path='*' element={<Navigate to={routes.error(404)} />} />
      </Routes>
    </Layout>
  );
};
