import { useQueryClient } from 'react-query';

import { useProjectUtils } from '../pages/project/profile/hooks/useProjectUtils';
import { useUser } from '../react-query/user/useUser';
import { useAuth } from '../providers/AuthProvider';
import { useAppMessage } from '../providers/AppMessageProvider/AppMessageProvider';
import { useRequest } from './useRequest';

import { AppMessage } from '../types';
import { UserApi } from '../api/UserApi/UserApi';
import { QueryKey } from '../react-query/query-keys';

export const useFollowProject = (
  project: { contractAddress: string; uid: string; [key: string]: any } | undefined
) => {
  const queryClient = useQueryClient();

  const { isFollowedProject } = useProjectUtils();
  const { data: user } = useUser();

  const { isAuthenticated } = useAuth();
  const { toast } = useAppMessage();

  const onFollowChange = async () => {
    if (!project?.uid) return;

    if (!isAuthenticated || !user) return toast(AppMessage.RegisterToFollow);

    const isFollowed = !!project && isFollowedProject(project.contractAddress);

    if (isFollowed) {
      await UserApi.unfollowProject(project.uid);
    } else {
      await UserApi.followProject(project.uid);
    }

    await Promise.all([
      queryClient.invalidateQueries(QueryKey.ProfileFollowingsCount(user.uid)),
      queryClient.invalidateQueries(QueryKey.ProfileFollowings(user.uid)),
      queryClient.invalidateQueries(
        QueryKey.ProfileFollowersCount(project.contractAddress)
      ),
      queryClient.invalidateQueries(QueryKey.ProjectFollowersCount(project.uid)),
      queryClient.invalidateQueries(QueryKey.ProjectFollowers(project.uid)),
      queryClient.invalidateQueries(QueryKey.PopularProjects()),
    ]);
  };

  return useRequest(onFollowChange);
};
