import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { envs } from '../../../config/envs';
import { useWindowSizeUtils } from '../../../hooks/useWindowSizeUtils';

import { useUser } from '../../../react-query/user/useUser';
import { routes } from '../../../router/routes';
import { InstallExtensionButton } from '../../common/ActionButtons/InstallExtensionButton';
import { NotificationIcon } from '../../common/Notifications/NotificationIcon';
import { SocialIcons } from '../../common/SocialIcons/SocialIcons';
import { ButtonPrimary, ButtonVariant } from '../../ui/buttons';

import { ActionText } from '../../ui/text/ActionText';
import { UserMenu } from './UserMenu/UserMenu';
import { NavItem } from './NavItem/NavItem';
import { HamburgerIcon } from './svgs/HamburgerIcon';
import { XMarkIcon } from './svgs/XMarkIcon';
import { useNavigationItems } from './useNavigationItems';
import { useBlockemExtension } from '../../../providers/BlockemExtensionProvider';

export const MobileNavigation: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { navItems } = useNavigationItems();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { data: user } = useUser();
  const { isXs, width } = useWindowSizeUtils();

  const [open, setOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const { isInstalled } = useBlockemExtension();

  const isMobile = width <= theme.breakpoints.md;

  useEffect(() => {
    if (open && !isMobile) setOpen(false);
  }, [open, isMobile]);

  useEffect(() => {
    setOpen(false);
    setNotificationOpen(false);
  }, [pathname]);

  if (!isMobile) return null;

  const onRoute = (url: string) => {
    navigate(url);
    setOpen(false);
  };

  return (
    <>
      <NavItemsMobile open={open}>
        {navItems.map(item => (
          <NavItem key={`${item.label}-${String(item.url)}`} navItem={item} />
        ))}
        {(!user || !isInstalled) && (
          <ButtonsWrapper>
            <InstallExtensionButton width={201} />
            {!user && (
              <ButtonPrimary
                onClick={() => onRoute(routes.auth.login())}
                variant={ButtonVariant.Outlined}
                width={211}
                style={{ marginBottom: 40 }}
              >
                {t('auth.signin')}
              </ButtonPrimary>
            )}
          </ButtonsWrapper>
        )}
        {user && (
          <UserMenu absolutePositionMenu={false} centered transparentMenu activeOnOpen />
        )}

        <Icons iconSize={32} />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <ActionText
            style={{ fontSize: 14, marginBottom: 14 }}
            onClick={() => onRoute(routes.privacyPolicy())}
          >
            Privacy Policy
          </ActionText>
          <ActionText
            style={{ fontSize: 14, marginBottom: 28 }}
            onClick={() => onRoute(routes.termsOfService())}
          >
            Terms of Service
          </ActionText>
          <Email onClick={() => window.open(`mailto:${envs.contactEmail}`, '_blank')}>
            {envs.contactEmail}
          </Email>
        </div>
      </NavItemsMobile>

      {!!user && (
        <NotificationIconWrapper>
          <NotificationIcon open={notificationOpen} setOpen={setNotificationOpen} />
        </NotificationIconWrapper>
      )}

      <HamburgerWrapper
        onClick={() => {
          if (notificationOpen) {
            setNotificationOpen(false);
            if (isXs) {
              setOpen(false);
            } else {
              setOpen(prev => !prev);
            }
          } else {
            setOpen(prev => !prev);
          }
        }}
      >
        {open || (notificationOpen && isXs) ? <XMarkIcon /> : <HamburgerIcon />}
      </HamburgerWrapper>
    </>
  );
};

const NavItemsMobile = styled('nav')<{ open: boolean }>(({ theme, open }) => ({
  display: open ? 'flex' : 'none',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: 114,
  gap: 20,
  marginLeft: 'auto',
  transition: !open ? 'none' : 'height .1s, opacity 0.3s',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  opacity: open ? 1 : 0,
  backgroundColor: '#0B0E11',
  zIndex: theme.zIndex.md,
  height: '100vh',
  paddingBottom: 40,
  maxHeight: '100vh',
  overflowY: 'auto',
  background: '#0B0E11',
}));

const NotificationIconWrapper = styled('div')({
  marginLeft: 'auto',
});

const HamburgerWrapper = styled('div')(({ theme }) => ({
  display: 'none',
  padding: 8,
  cursor: 'pointer',
  opacity: 1,
  transition: 'all .2s',
  '&:hover': { opacity: 0.8 },
  [theme.max(theme.breakpoints.md)]: {
    display: 'flex',
  },
  zIndex: theme.zIndex.ul,
}));

const Icons = styled(SocialIcons)({
  marginTop: 12,
  cursor: 'pointer',
  marginBottom: 12,
});

const ButtonsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  marginTop: 4,
  marginBottom: 4,
  '& button': {
    width: 211,
  },
});

const Email = styled('span')(({ theme }) => ({
  color: '#fff',
  opacity: 0.3,
  fontSize: 20,
  fontWeight: 700,
}));
