import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useReputationWithAddress } from '../../../../../../providers/AddressProvider/useReputationWithAddress';

import { DetailItem } from './DetailItem';

export const ProjectReputationDetails: React.FC = () => {
  const { t } = useTranslation();
  const { data: reputation } = useReputationWithAddress();

  const { numberOfTransactions, firstTransactionTime, lastTransactionTime } =
    reputation || {};

  const totalTransactions =
    typeof numberOfTransactions === 'number' && numberOfTransactions > -1
      ? `${numberOfTransactions.toLocaleString()}${
          numberOfTransactions > 1999 ? '+' : ''
        }`
      : '';

  const firstTransactionText =
    !firstTransactionTime || firstTransactionTime === 'missing'
      ? ''
      : firstTransactionTime.split(' ')[0];

  const firstTransactionSuffix =
    firstTransactionTime === 'today' ||
    firstTransactionTime === 'missing' ||
    !firstTransactionText
      ? ''
      : `${firstTransactionTime?.split(' ').slice(1, -1).join(' ')} ago`;

  const lastTransactionText =
    !lastTransactionTime || lastTransactionTime === 'missing'
      ? ''
      : lastTransactionTime.split(' ')[0];

  const lastTransactionSuffix =
    lastTransactionTime === 'today' ||
    lastTransactionTime === 'missing' ||
    !lastTransactionText
      ? ''
      : `${lastTransactionTime?.split(' ').slice(1, -1).join(' ')} ago`;

  return (
    <Wrapper>
      <DetailItem
        label={t('analysisResult.totalTransactions.title')}
        value={totalTransactions}
        isLoading={!reputation}
      />
      <DetailItem
        label={t('analysisResult.firstTransaction.title')}
        value={`${firstTransactionText} ${firstTransactionSuffix}`}
        isLoading={!reputation}
      />
      <DetailItem
        label={t('analysisResult.lastTransaction.title')}
        value={`${lastTransactionText} ${lastTransactionSuffix}`}
        isLoading={!reputation}
      />
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  borderRadius: 10,
  border: '1px solid rgba(255, 255, 255, 0.1)',
  backgroundColor: '#1B1B1B',
  padding: '24px 20px',
  display: 'flex',
  flexDirection: 'column',
  gap: 14,
  [theme.min(theme.breakpoints.desktop)]: {
    gap: theme.pxToVw(18),
    borderRadius: theme.pxToVw(24),
    padding: `${theme.pxToVw(32)} ${theme.pxToVw(40)}`,
  },
  [theme.min(theme.breakpoints.max)]: {
    gap: 18,
    borderRadius: 24,
    padding: '32px 40px',
  },
}));
