import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { isValidEthAddress } from '../../../../utils/address-utils';
import { UrlService } from '../../../../services/UrlService';

import { useReputationWithAddress } from '../../../../providers/AddressProvider/useReputationWithAddress';
import { useAddress } from '../../../../providers/AddressProvider/AddressProvider';

import { AddressInput } from '../../../../components/common/AddressInput/AddressInput';

import { routes } from '../../../../router/routes';

export const AddressCheckInput: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading, error, isSuccess } = useReputationWithAddress();
  const { setAddress, address } = useAddress();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { t } = useTranslation();

  const onSubmit = async (value: string) => {
    if (!isValidEthAddress(value)) {
      return setErrorMessage(t('errors.invalidEthAddress'));
    }
    setErrorMessage(null);
    const addressParam = encodeURIComponent(value.trim()?.toLowerCase() || '');
    navigate(`${routes.home()}?address=${addressParam}`);
    setAddress(value);
  };

  useEffect(() => {
    if (!error || !address) return;
    const msg = error?.response?.data?.message;
    const apiErrors = [
      'INCORRECT_ADDRESS_LENGTH',
      'INTERNAL_SERVER_ERROR',
      'INCORRECT_ETH_NAME',
    ];

    const errorMessage =
      msg && apiErrors.includes(msg)
        ? t(`errors.${msg}`, {
            type: address.toLowerCase().endsWith('.bit') ? 'BIT' : 'ETH',
          })
        : t('errors.default');
    setErrorMessage(errorMessage);
  }, [error, address, setErrorMessage, t]);

  return (
    <AddressInput
      id='homepage_address-input'
      initValue={encodeURIComponent(
        UrlService.getSearchParam('address')?.trim()?.toLowerCase() || ''
      )}
      onSubmit={onSubmit}
      isLoading={isLoading}
      error={errorMessage}
      placeholder={t('common.searchPlaceholder')}
      isSuccess={isSuccess}
    />
  );
};
