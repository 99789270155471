import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useStep } from '../../../providers/StepProvider';

import { Steps } from './Steps/Steps';

export const ProjectOnboardWizzardProgress: React.FC = () => {
  const { t } = useTranslation();
  const { step } = useStep();

  const descriptions = t('projectOnboard.descriptions', {
    returnObjects: true,
  }) as string[];

  const description = descriptions[step] || '';

  return (
    <>
      <DescriptionText>{description}</DescriptionText>
      <Steps />
    </>
  );
};

const DescriptionText = styled('p')(({ theme }) => ({
  fontSize: 14,
  lineHeight: 1.31,
  textAlign: 'center',
  marginBottom: 20,
  [theme.min(theme.breakpoints.md)]: {
    fontSize: 16,
  },
}));
